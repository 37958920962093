import React, {useState} from 'react';
import Dialog from "../UI/Burnblade/Dialog";
import Avatar from "../UI/Burnblade/Avatar";
import TabBookVertical from "../UI/Burnblade/TabBookVertical";
import Label from "../UI/Burnblade/Label";
import styled from "styled-components";

const CountByPath = {
	demon:54,
	fairy:49,
	fantasy:49,
	halfling:47,
	village:40,
	village2:40
};

const PageWrapper = styled.div`
	width: 500px;
	height: 600px;
`;

function AvatarPage({path, onSelect})
{
	// build avatars
	const avatars = [];
	for(let avatarIndex=0; avatarIndex<CountByPath[path]; avatarIndex++)
	{
		const url = `/assets/avatars/${path}/${avatarIndex+1}.png`;
		avatars.push(<Avatar key={avatarIndex} url={url} onClick={()=>{onSelect(url);}} />);
	}

	// done
	return (<PageWrapper className="ScrollVertical">{avatars}</PageWrapper>);
}

function AvatarSelect({label, url, onSelect}) {

	// input
	const [showDialog, setShowDialog] = useState(false);

	// display
	return (
		<>
			<Label text={label} stacked={true} style={{width:"125px"}}>
				<Avatar url={url} onClick={onSelect ? ()=>{setShowDialog(true);} : undefined} />
			</Label>

			{
				!showDialog ? false :
				<Dialog
					title={label}
					onClose={() => {console.log("close"); setShowDialog(false);}}
				>
					<TabBookVertical
						initialTabIndex={0}
						tabLabels={Object.keys(CountByPath).map(path => <div style={{textTransform:"capitalize"}}>{path}</div>)}
						tabPages={
							Object.keys(CountByPath).map(path =>
								<AvatarPage
									path={path}
									onSelect={url => {
										onSelect(url);
										setShowDialog(false);
									}}
								/>
							)
						}
					/>
				</Dialog>
			}
		</>
	);
}

export default AvatarSelect;