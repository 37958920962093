import React from 'react';
import Language from "../Language/Language";
import Label from "../UI/Burnblade/Label";
import ComboWrapper from "../UI/Burnblade/ComboWrapper";
import TextInput from "../UI/Burnblade/TextInput";
import RandomSelect from "./RandomSelect";
import TextArea from "../UI/Burnblade/TextArea";
import {CheckboxLabel} from "../UI/Burnblade/Checkbox";
import CharacterData from "./CharacterData";
import AvatarSelect from "./AvatarSelect";
import Spinner from "../UI/Burnblade/Spinner";
import SideBySide from "../UI/Burnblade/SideBySide";
import HorizontalRule from "../UI/Burnblade/HorizontalRule";
import CharacterStatusData from "./CharacterStatusData";

function CharacterModuleDescription({character, name, setName, headshotUrl, setHeadshotUrl, size, setSize, adjective, setAdjective, noun, setNoun, readOnly}) {

	// input
	const language = Language.getCurrent();

	// display
	return (
		<div>
			<div style={{display:"flex", flexFlow:"row nowrap"}}>

				{/* Avatar */}
				<AvatarSelect
					url={headshotUrl}
					label={language.Headshot}
					onSelect={setHeadshotUrl}
				/>

				{/* Name */}
				<Label text={language.Name} stacked={1} style={{flex:1}}>
					<ComboWrapper>
						<TextInput
							name="name"
							value={name}
							readOnly={readOnly}
							onChange={event => {setName(event.target.value);}}
						/>
						{
							readOnly ? false :
							<RandomSelect
								onSelect={setName}
								titleText={language.Name}
								optionsByLabel={language.CharacterNamesByLabel}
							/>
						}
					</ComboWrapper>
				</Label>

				{/* Size */}
				<Spinner text={language.Size} min={-4} max={20} initialValue={size} step={1} name="size" onChange={setSize} readOnly={readOnly} />
			</div>

			<div style={{display:"flex", flexFlow:"row nowrap", justifyContent:"space-between", alignItems:"end", marginTop:"-2em"}}>

				{/* Player */}
				<CheckboxLabel
					name="isPlayer"
					disabled={readOnly}
					defaultChecked={CharacterData.getCharacterIsPlayer(character)}
					style={readOnly&&!CharacterData.getCharacterIsPlayer(character)?{textDecoration:"line-through",opacity:0.5}:undefined}
				>
					{language.Player}
				</CheckboxLabel>

				{/* Wild Card */}
				<CheckboxLabel
					name="isWildCard"
					disabled={readOnly}
					defaultChecked={CharacterData.getCharacterIsWildCard(character)}
					style={readOnly&&!CharacterData.getCharacterIsWildCard(character)?{textDecoration:"line-through",opacity:0.5}:undefined}
				>
					{language.Wild_Card}
				</CheckboxLabel>

				{/* Pace */}
				<Spinner text={language.Pace} min={0} max={20} initialValue={CharacterData.getCharacterPace(character)} step={1} name="pace" readOnly={readOnly} />
			</div>

			<HorizontalRule />

			{/* Description */}
			<SideBySide style={{display:readOnly && !adjective && !noun ? "none" : undefined}}>

				{/* Adjective */}
				<Label text={language.Character_Adjective} stacked={true}>
					<ComboWrapper>
						<TextInput
							name="adjective"
							value={adjective}
							readOnly={readOnly}
							onChange={event => {setAdjective(event.target.value);}}
						/>
						{
							readOnly ? false :
							<RandomSelect
								onSelect={setAdjective}
								titleText={language.Character_Adjective}
								optionsNoLabel={language.CharacterAdjectives}
							/>
						}
					</ComboWrapper>
				</Label>

				{/* Noun */}
				<Label text={language.Character_Noun} stacked={true}>
					<ComboWrapper>
						<TextInput
							name="noun"
							value={noun}
							readOnly={readOnly}
							onChange={event => {setNoun(event.target.value);}}
						/>
						{
							readOnly ? false :
							<RandomSelect
								onSelect={setNoun}
								titleText={language.Character_Noun}
								optionsNoLabel={language.CharacterNouns}
							/>
						}
					</ComboWrapper>
				</Label>

			</SideBySide>

			{/* Notes */}
			<Label text={language.Notes} stacked={1} style={{paddingTop:0}}>
				<TextArea name="notes" defaultValue={CharacterStatusData.getCharacterNotes(CharacterData.getCharacterStatus(character))} />
			</Label>

		</div>
	);
}

export default CharacterModuleDescription;