import React, {useState} from 'react';
import Language from "../Language/Language";
import Edges from "../Savaged/Edges";
import HorizontalRule from "../UI/Burnblade/HorizontalRule";
import ListItemGroupModule from "./ListItemGroupModule";
import Hindrances from "../Savaged/Hindrances";
import styled from "styled-components";

const Wrapper = styled.div`
	padding:1em;
	max-height: 50vh;
	max-width: 50vw;
`;

function CharacterModuleEdges({edgeIds, setEdgeIds, hindranceIds, setHindranceIds, readOnly, abilities}) {

	// input
	const language = Language.getCurrent();
	const sections = [];
	function addSection(section)
	{
		if (sections.length) sections.push(<HorizontalRule key={sections.length} style={{marginTop:"1em",marginBottom:"1em"}} />);
		sections.push(section);
	}

	// Hindrances
	if (!readOnly || (hindranceIds && hindranceIds.length)) addSection(
		<ListItemGroupModule
			key="hindranceIds"
			readOnly={readOnly}
			allItems={Hindrances}
			title={language.Hindrances}
			currentItemIds={hindranceIds}
			setCurrentItemIds={setHindranceIds}
			getItemId={item => item.id}
			getItemName={item => `${item.name}${item.major ? ` (${language.Major})` : ""}`}
		/>
	);

	// Edges
	if (!readOnly || (edgeIds && edgeIds.length)) addSection(
		<ListItemGroupModule
			key="edgeIds"
			readOnly={readOnly}
			allItems={Edges}
			title={language.Edges}
			currentItemIds={edgeIds}
			setCurrentItemIds={setEdgeIds}
			getItemId={item => item.id}
			getItemName={item => `${item.name}${item.major ? ` (${language.Major})` : ""}`}
		/>
	);

	// abilities
	if (abilities && abilities.length)
	{
		abilities.forEach((ability, abilityIndex) => ability.id = abilityIndex);
		addSection(
			<ListItemGroupModule
				key="abilities"
				readOnly={true}
				title={language.Abilities}
				allItems={abilities}
				getItemId={item => item.id}
				getItemName={item => item.Name}
				getItemSummary={item => item.Notes}
				currentItemIds={abilities.map(ability => ability.id)}
			/>
		);
	}

	// display
	return (
		<Wrapper className="ScrollVertical">
			<br />
			{sections}
		</Wrapper>
	);
}

export default CharacterModuleEdges;