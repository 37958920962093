const Weapons = [
	{
		"id": 1127,
		"cost": 800000,
		"name": "120mm Tank Gun",
		"type": "Vehicular Weapons",
		"uuid": "6471aa5b-c206-4c59-2936-045ba5b9276f",
		"active": true,
		"weight": 0,
		"book_id": 9,
		"effects": [],
		"summary": "MBT for HE Rounds. AP Rounds (5d10, AP 31, 6d10 at Range 50 or less)",
		"tw_cost": 0,
		"bookPage": "80",
		"contains": [],
		"profiles": [
			{
				"ap": 17,
				"rof": 1,
				"name": "",
				"notes": "",
				"range": "100/200/400",
				"reach": 0,
				"shots": 0,
				"damage": "4d8",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "4d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 0
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 1128,
		"cost": 1000000,
		"name": "125mm Tank Gun",
		"type": "Vehicular Weapons",
		"uuid": "6f396d8e-5898-4194-ac20-4160f890c7ef",
		"active": true,
		"weight": 0,
		"book_id": 9,
		"effects": [],
		"summary": "MBT for HE Rounds. AP Rounds (4d10, AP 30, 6d10 at Range 50 or less)",
		"tw_cost": 0,
		"bookPage": "80",
		"contains": [],
		"profiles": [
			{
				"ap": 15,
				"rof": 1,
				"name": "",
				"notes": "",
				"range": "100/200/400",
				"reach": 0,
				"shots": 0,
				"damage": "4d8",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "4d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 0
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 134,
		"cost": 5000,
		"name": "14.5mm MG",
		"type": "Machine Guns",
		"uuid": "171199ce-a091-4754-3063-559ed4e2302f",
		"active": true,
		"weight": 30,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "63",
		"contains": [],
		"profiles": [
			{
				"ap": 2,
				"rof": 3,
				"name": "",
				"notes": " Auto, May not move",
				"range": "50/100/200",
				"reach": 0,
				"shots": 100,
				"damage": "3d6",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "3d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "14.5mm MGs",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 1121,
		"cost": 75000,
		"name": "2 pd AT Gun",
		"type": "Vehicular Weapons",
		"uuid": "2c30c625-7914-4724-a700-8f1280c0681e",
		"active": true,
		"weight": 0,
		"book_id": 9,
		"effects": [],
		"summary": "MBT for HE Rounds. AP Rounds (4d8, AP 5, 5d8 at Range 37 or less)",
		"tw_cost": 0,
		"bookPage": "80",
		"contains": [],
		"profiles": [
			{
				"ap": 2,
				"rof": 1,
				"name": "",
				"notes": "",
				"range": "75/150/300",
				"reach": 0,
				"shots": 0,
				"damage": "3d6",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "3d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 0
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 1016,
		"cost": 50000,
		"name": "20m Cannon",
		"type": "Vehicular Weapons",
		"uuid": "68df6126-1ef3-440b-061c-77d5298fa2ff",
		"active": true,
		"weight": 0,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "80",
		"contains": [],
		"profiles": [
			{
				"ap": 4,
				"rof": 4,
				"name": "",
				"notes": "",
				"range": "50/100/200",
				"reach": 0,
				"shots": 0,
				"damage": "2d12",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d12",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 0
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 1017,
		"cost": 75000,
		"name": "25mm Cannon",
		"type": "Vehicular Weapons",
		"uuid": "d99bf355-5d11-4c0a-85e8-9f7af8e4f556",
		"active": true,
		"weight": 0,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "80",
		"contains": [],
		"profiles": [
			{
				"ap": 4,
				"rof": 3,
				"name": "",
				"notes": "",
				"range": "50/100/200",
				"reach": 0,
				"shots": 0,
				"damage": "3d8",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "3d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 0
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 1018,
		"cost": 200000,
		"name": "30mm Cannon",
		"type": "Vehicular Weapons",
		"uuid": "3fd48a41-bdf0-4fe0-90ae-9e700cedd2b2",
		"active": true,
		"weight": 0,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "80",
		"contains": [],
		"profiles": [
			{
				"ap": 6,
				"rof": 3,
				"name": "",
				"notes": "",
				"range": "50/100/200",
				"reach": 0,
				"shots": 0,
				"damage": "3d8",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "3d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 0
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 1122,
		"cost": 100000,
		"name": "37mm AT Gun",
		"type": "Vehicular Weapons",
		"uuid": "07c06a0f-1463-44ce-b9e3-6f85c324b280",
		"active": true,
		"weight": 0,
		"book_id": 9,
		"effects": [],
		"summary": "MBT for HE Rounds. AP Rounds (4d8, AP 3, 5d8 at Range 25 or less)",
		"tw_cost": 0,
		"bookPage": "80",
		"contains": [],
		"profiles": [
			{
				"ap": 3,
				"rof": 1,
				"name": "",
				"notes": "",
				"range": "50/100/200",
				"reach": 0,
				"shots": 0,
				"damage": "4d6",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "4d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 0
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 1019,
		"cost": 200000,
		"name": "40mm Cannon",
		"type": "Vehicular Weapons",
		"uuid": "1ff1c4c9-6326-4a1d-ad80-bcb8103c5696",
		"active": true,
		"weight": 0,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "80",
		"contains": [],
		"profiles": [
			{
				"ap": 5,
				"rof": 4,
				"name": "",
				"notes": "HE Round: 3d8 AP2 MBT",
				"range": "50/100/200",
				"reach": 0,
				"shots": 0,
				"damage": "4d8",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "4d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 0
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 1123,
		"cost": 150000,
		"name": "57mm AT Gun",
		"type": "Vehicular Weapons",
		"uuid": "777ec63b-bfae-432d-8435-3ad1cc4377c3",
		"active": true,
		"weight": 0,
		"book_id": 9,
		"effects": [],
		"summary": "MBT for HE Rounds. AP Rounds (4d8, AP 5, 5d8 at Range 37 or less)",
		"tw_cost": 0,
		"bookPage": "80",
		"contains": [],
		"profiles": [
			{
				"ap": 3,
				"rof": 1,
				"name": "",
				"notes": "",
				"range": "75/150/300",
				"reach": 0,
				"shots": 0,
				"damage": "3d8",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "3d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 0
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 128,
		"cost": 1000,
		"name": "7.7 MG (Japanese)",
		"type": "Machine Guns",
		"uuid": "8122ded7-65b8-456d-8c9d-1c39f7282143",
		"active": true,
		"weight": 30,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "63",
		"contains": [],
		"profiles": [
			{
				"ap": 2,
				"rof": 3,
				"name": "",
				"notes": " Auto, Snapfire",
				"range": "30/60/120",
				"reach": 0,
				"shots": 250,
				"damage": "2d8",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "7.7 MGs (Japanese)",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 1124,
		"cost": 250000,
		"name": "75mm Tank Gun",
		"type": "Vehicular Weapons",
		"uuid": "5963a56c-00a4-4cab-1feb-d646e5cd4c71",
		"active": true,
		"weight": 0,
		"book_id": 9,
		"effects": [],
		"summary": "MBT for HE Rounds. AP Rounds (4d10, AP 6, 5d10 at Range 37 or less)",
		"tw_cost": 0,
		"bookPage": "80",
		"contains": [],
		"profiles": [
			{
				"ap": 3,
				"rof": 1,
				"name": "",
				"notes": "",
				"range": "75/150/300",
				"reach": 0,
				"shots": 0,
				"damage": "3d8",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "3d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 0
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 1125,
		"cost": 300000,
		"name": "76mm Tank Gun",
		"type": "Vehicular Weapons",
		"uuid": "0b60c880-f2bf-494f-149d-0ceb98f8006b",
		"active": true,
		"weight": 0,
		"book_id": 9,
		"effects": [],
		"summary": "MBT for HE Rounds. AP Rounds (4d10, AP 10, 5d10 at Range 37 or less)",
		"tw_cost": 0,
		"bookPage": "80",
		"contains": [],
		"profiles": [
			{
				"ap": 5,
				"rof": 1,
				"name": "",
				"notes": "",
				"range": "75/150/300",
				"reach": 0,
				"shots": 0,
				"damage": "3d8",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "3d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 0
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 1126,
		"cost": 500000,
		"name": "88mm Tank Gun",
		"type": "Vehicular Weapons",
		"uuid": "10f8d30d-37dd-477b-096d-06790d40ac4c",
		"active": true,
		"weight": 0,
		"book_id": 9,
		"effects": [],
		"summary": "MBT for HE Rounds. AP Rounds (4d10+1, AP 16, 5d10+1 at Range 50 or less)",
		"tw_cost": 0,
		"bookPage": "80",
		"contains": [],
		"profiles": [
			{
				"ap": 8,
				"rof": 1,
				"name": "",
				"notes": "",
				"range": "100/200/400",
				"reach": 0,
				"shots": 0,
				"damage": "4d8",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "4d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 0
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 120,
		"cost": 450,
		"name": "AK47 (7.62)",
		"type": "Assault Rifles",
		"uuid": "6335c7d6-bcdb-451c-a2fd-7bf015782f77",
		"active": true,
		"weight": 10,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "63",
		"contains": [],
		"profiles": [
			{
				"ap": 2,
				"rof": 3,
				"name": "",
				"notes": " Auto",
				"range": "24/48/96",
				"reach": 0,
				"shots": 30,
				"damage": "2d8+1",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d8+1",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "AK47s (7.62)",
		"vehicle_mods": 0,
		"minimum_strength": "d6",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 250,
		"cost": 450,
		"name": "AK47 (7.62mm)",
		"type": "Rifles (Assault)",
		"uuid": "47723049-c26c-4e96-b652-211e28dc8edf",
		"active": true,
		"weight": 10,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "75",
		"contains": [],
		"profiles": [
			{
				"ap": 2,
				"rof": 3,
				"name": "",
				"notes": "",
				"range": "24/48/96",
				"reach": 0,
				"shots": 30,
				"damage": "2d8+1",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d8+1",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "AK47s (7.62mm)",
		"vehicle_mods": 0,
		"minimum_strength": "d6",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 1113,
		"cost": 100,
		"name": "Anti-Personnel Mine",
		"type": "Mines",
		"uuid": "2d59f070-170a-4c53-27ba-a3a483c481cb",
		"active": true,
		"weight": 10,
		"book_id": 9,
		"effects": [],
		"summary": "SBT. Notice check before stepping off and detonating it, Repair roll -4 to disarm, but failure detonates immediately.",
		"tw_cost": 0,
		"bookPage": "79",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 0,
				"name": "",
				"notes": "",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "2d6+2",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d6+2",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 0
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 1114,
		"cost": 200,
		"name": "Anti-Tank Mine",
		"type": "Mines",
		"uuid": "cd4af814-cdec-4830-b5df-a50cc926a5cc",
		"active": true,
		"weight": 20,
		"book_id": 9,
		"effects": [],
		"summary": "Apply only 1/2 vehicle's Armor value. Notice check before driving off and detonating it, Repair roll -4 to disarm, but failure detonates immediately.",
		"tw_cost": 0,
		"bookPage": "79",
		"contains": [],
		"profiles": [
			{
				"ap": 5,
				"rof": 0,
				"name": "",
				"notes": "",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "4d6",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "4d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 0
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 393,
		"cost": 1500,
		"name": "AT-4",
		"type": "Modern Firearms",
		"uuid": "f1eb5e67-8d05-49d6-2d25-c661a61e860c",
		"active": true,
		"weight": 15,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "80",
		"contains": [],
		"profiles": [
			{
				"ap": 24,
				"rof": 1,
				"name": "",
				"notes": "MBT",
				"range": "24/48/96",
				"reach": 0,
				"shots": 1,
				"damage": "4d8+2",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "4d8+2",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "AT-4s",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 12,
		"cost": 200,
		"name": "Axe",
		"type": "Axes and Mauls",
		"uuid": "7312a1b7-1bb6-4a81-816b-9f8f62cd4feb",
		"active": true,
		"weight": 2,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "60",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 0,
				"name": "",
				"notes": "",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "d6",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": true,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Axes",
		"vehicle_mods": 0,
		"minimum_strength": "d6",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 185,
		"cost": 300,
		"name": "Axe, Battle",
		"type": "Medieval Hand Weapons",
		"uuid": "2b2c1f45-cdf3-4816-a467-1352ad4fd5fa",
		"active": true,
		"weight": 4,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "72",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 0,
				"name": "",
				"notes": "",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "d8",
				"toHitMod": 0,
				"is_shield": false,
				"melee_only": true,
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Battle Axes",
		"vehicle_mods": 0,
		"minimum_strength": "d8",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 188,
		"cost": 400,
		"name": "Axe, Great",
		"type": "Medieval Hand Weapons",
		"uuid": "c8c3fa3b-1763-4fe2-100a-857550fa11dc",
		"active": true,
		"weight": 7,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "72",
		"contains": [],
		"profiles": [
			{
				"ap": 2,
				"rof": 0,
				"name": "",
				"notes": "",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "d10",
				"toHitMod": 0,
				"is_shield": false,
				"melee_only": true,
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": -1,
				"damage_original": "d10",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Great Axes",
		"vehicle_mods": 0,
		"minimum_strength": "d10",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 184,
		"cost": 100,
		"name": "Axe, Hand",
		"type": "Medieval Hand Weapons",
		"uuid": "3c61a7ea-5ed4-4e88-b3fb-da8d2b554648",
		"active": true,
		"weight": 2,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "72",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 0,
				"name": "",
				"notes": "",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "d6",
				"toHitMod": 0,
				"is_shield": false,
				"melee_only": true,
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Axes",
		"vehicle_mods": 0,
		"minimum_strength": "d6",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 8,
		"cost": 75,
		"name": "Axe, Throwing",
		"type": "Thrown Weapon",
		"uuid": "72c44c7a-4ef5-4ed5-00fa-1391530faf00",
		"active": true,
		"weight": 1,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "80",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 0,
				"name": "",
				"notes": "",
				"range": "3/6/12",
				"reach": 0,
				"shots": 0,
				"damage": "d6",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Axes, Throwing",
		"vehicle_mods": 0,
		"minimum_strength": "d6",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 211,
		"cost": 100,
		"name": "Axe, Throwing",
		"type": "Ranged Weapons",
		"uuid": "ad89990d-a41d-4092-adef-9f6510c0b5e8",
		"active": true,
		"weight": 3,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "73",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 1,
				"name": "",
				"notes": "",
				"range": "3/6/12",
				"reach": 0,
				"shots": 1,
				"damage": "d6",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Axes, Throwing",
		"vehicle_mods": 0,
		"minimum_strength": "d6",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 21,
		"cost": 5,
		"name": "Bangstick",
		"type": "Modern",
		"uuid": "1814f87e-eb09-46cd-3ca6-e46b49537827",
		"active": true,
		"weight": 2,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "60",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 0,
				"name": "",
				"notes": "",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "3d6",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": true,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "3d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 0
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Bangsticks",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 201,
		"cost": 5,
		"name": "Bangstick",
		"type": "Modern Hand Weapons",
		"uuid": "0bbc13fe-98fb-4fa2-0fb4-befc727eb048",
		"active": true,
		"weight": 2,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "72",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 0,
				"name": "",
				"notes": "A shotgun shell on a stick used in melee; must be reloaded with a fresh shell (one action)",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "3d6",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": true,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "3d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Bangsticks",
		"vehicle_mods": 0,
		"minimum_strength": "d6",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 114,
		"cost": 750,
		"name": "Barrett (.50)",
		"type": "Rifles",
		"uuid": "b146f4c5-1c87-4978-2fcb-ec4eaf1e7005",
		"active": true,
		"weight": 35,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "63",
		"contains": [],
		"profiles": [
			{
				"ap": 4,
				"rof": 1,
				"name": "",
				"notes": " Snapfire",
				"range": "50/100/200",
				"reach": 0,
				"shots": 11,
				"damage": "2d10",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d10",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Barretts (.50)",
		"vehicle_mods": 0,
		"minimum_strength": "d8",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 244,
		"cost": 750,
		"name": "Barrett (.50)",
		"type": "Rifles (Lever and Bolt-Action)",
		"uuid": "5fef80af-e7be-40e3-0829-5a47ba920c6c",
		"active": true,
		"weight": 35,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "75",
		"contains": [],
		"profiles": [
			{
				"ap": 4,
				"rof": 1,
				"name": "",
				"notes": "Heavy weapon",
				"range": "50/100/200",
				"reach": 0,
				"shots": 10,
				"damage": "2d10",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d10",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Barretts (.50)",
		"vehicle_mods": 0,
		"minimum_strength": "d8",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 13,
		"cost": 300,
		"name": "Battle Axe",
		"type": "Axes and Mauls",
		"uuid": "8c6d9f85-95ad-4fd5-985c-569723ec12e9",
		"active": true,
		"weight": 10,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "60",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 0,
				"name": "",
				"notes": "",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "d8",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": true,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Battle Axes",
		"vehicle_mods": 0,
		"minimum_strength": "d8",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 22,
		"cost": 25,
		"name": "Bayonet",
		"type": "Modern",
		"uuid": "25f9afde-5839-4289-2498-c45fdf9a8bd6",
		"active": true,
		"weight": 1,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "60",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 0,
				"name": "",
				"notes": "",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "d4",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": true,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "d4",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 0
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Bayonets",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 202,
		"cost": 25,
		"name": "Bayonet",
		"type": "Modern Hand Weapons",
		"uuid": "edb41152-4485-4255-b413-0d057cee545a",
		"active": true,
		"weight": 1,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "72",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 0,
				"name": "",
				"notes": "Str +d6 and Parry +1 attached to rifle, Reach 1, two hands",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "d4",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": true,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "d4",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Bayonets",
		"vehicle_mods": 0,
		"minimum_strength": "d4",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 394,
		"cost": 500,
		"name": "Bazooka",
		"type": "Modern Firearms",
		"uuid": "c39401b6-419c-444c-99a4-af0c9bb1e85c",
		"active": true,
		"weight": 12,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "80",
		"contains": [],
		"profiles": [
			{
				"ap": 8,
				"rof": 1,
				"name": "",
				"notes": "Snapfire, MBT",
				"range": "24/48/96",
				"reach": 0,
				"shots": 1,
				"damage": "4d8",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "4d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Bazookas",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 132,
		"cost": 1000,
		"name": "Besa MG (7.92)",
		"type": "Machine Guns",
		"uuid": "eaa74bd6-c8b6-4914-a6e0-e9c2fca6483f",
		"active": true,
		"weight": 54,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "63",
		"contains": [],
		"profiles": [
			{
				"ap": 2,
				"rof": 3,
				"name": "",
				"notes": " Auto, May not move",
				"range": "40/80/160",
				"reach": 0,
				"shots": 50,
				"damage": "2d8",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Besa MGs (7.92)",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 23,
		"cost": 10,
		"name": "Billy Club/Baton",
		"type": "Modern",
		"uuid": "7400d996-77e2-49b2-19ed-cb06733e49ae",
		"active": true,
		"weight": 1,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "60",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 0,
				"name": "",
				"notes": "",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "d4",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": true,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "d4",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 0
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Billy Clubs/Batons",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 203,
		"cost": 10,
		"name": "Billy Club/Baton",
		"type": "Modern Hand Weapons",
		"uuid": "9ff764dc-893c-4922-b8b5-770c4d698d9c",
		"active": true,
		"weight": 1,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "72",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 0,
				"name": "",
				"notes": "Carried by many law enforcement officials",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "d4",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": true,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "d4",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Billy Clubs/Batons",
		"vehicle_mods": 0,
		"minimum_strength": "d4",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 222,
		"cost": 300,
		"name": "Blunderbuss",
		"type": "Black Powder Weapons",
		"uuid": "0b8751e4-7fb5-4af4-b8b4-69da4654fb5b",
		"active": true,
		"weight": 8,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "73",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 1,
				"name": "",
				"notes": "Reload 2, Treat as Shotgun, see page 7page 105.",
				"range": "10/20/40",
				"reach": 0,
				"shots": 1,
				"damage": "1-3d6*",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "1-3d6*",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Blunderbusses",
		"vehicle_mods": 0,
		"minimum_strength": "d6",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 91,
		"cost": 300,
		"name": "Blunderbuss (8G)",
		"type": "Black Powder",
		"uuid": "8d07f8fa-72ab-470c-17aa-67fa3ecd2e37",
		"active": true,
		"weight": 12,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "62",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 1,
				"name": "",
				"notes": "2 actions to reload",
				"range": "10/20/40",
				"reach": 0,
				"shots": 1,
				"damage": "1-3d6",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "1-3d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "",
		"vehicle_mods": 0,
		"minimum_strength": "d6",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 1115,
		"cost": 125,
		"name": "Bouncing Betty",
		"type": "Mines",
		"uuid": "e5621fe3-b1d4-4c97-88cc-44adbc826bf0",
		"active": true,
		"weight": 9,
		"book_id": 9,
		"effects": [],
		"summary": "SBT. Notice check before stepping off and detonating it, Repair roll -4 to disarm, but failure detonates immediately. No protection from Prone.",
		"tw_cost": 0,
		"bookPage": "79",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 0,
				"name": "",
				"notes": "",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "3d6",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "3d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 0
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 31,
		"cost": 250,
		"name": "Bow",
		"type": "weapon",
		"uuid": "d07dfcf8-d370-4b7e-955d-8e57b4004fe6",
		"active": true,
		"weight": 3,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "62",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 1,
				"name": "",
				"notes": "",
				"range": "12/24/48",
				"reach": 0,
				"shots": 0,
				"damage": "2d6",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Bows",
		"vehicle_mods": 0,
		"minimum_strength": "d6",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 212,
		"cost": 250,
		"name": "Bow",
		"type": "Ranged Weapons",
		"uuid": "3f76cc73-423f-45e4-a28b-a6e8d31470bf",
		"active": true,
		"weight": 3,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "73",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 1,
				"name": "",
				"notes": "",
				"range": "12/24/48",
				"reach": 0,
				"shots": 1,
				"damage": "2d6",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Bows",
		"vehicle_mods": 0,
		"minimum_strength": "d6",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 24,
		"cost": 20,
		"name": "Brass Knuckles",
		"type": "Modern",
		"uuid": "5137e461-45a1-4d42-27ba-45daf10287b3",
		"active": true,
		"weight": 1,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "60",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 0,
				"name": "",
				"notes": "",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "d4",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": true,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "d4",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 0
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Brass Knuckles",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 2797,
		"cost": 20,
		"name": "Brass Knuckles",
		"type": "Modern Hand Weapons",
		"uuid": "ce79e870-da48-47e5-b712-028efc73d82d",
		"active": true,
		"weight": 1,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "72",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 0,
				"name": "",
				"notes": "A hero wearing brass knuckles is considered to be an Unarmed Defender (see page 108)",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "d4",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": true,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "d4",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Brass Knuckles",
		"vehicle_mods": 0,
		"minimum_strength": "d4",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 90,
		"cost": 300,
		"name": "Brown Bess (.75)",
		"type": "Black Powder",
		"uuid": "b7f2e48c-a64d-49ec-062f-ff3aaf901796",
		"active": true,
		"weight": 15,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "27",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 1,
				"name": "",
				"notes": "2 actions to reload",
				"range": "",
				"reach": 0,
				"shots": 1,
				"damage": "2d8",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Brown Besses (.75)",
		"vehicle_mods": 0,
		"minimum_strength": "d6",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 221,
		"cost": 300,
		"name": "Brown Bess (or Similar Muskets)",
		"type": "Black Powder Weapons",
		"uuid": "5d76029f-c265-4bb2-34c8-e0b2337652b1",
		"active": true,
		"weight": 15,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "73",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 1,
				"name": "",
				"notes": "Reload 2",
				"range": "10/20/40",
				"reach": 0,
				"shots": 1,
				"damage": "2d8",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Brown Bess (or Similar Muskets)",
		"vehicle_mods": 0,
		"minimum_strength": "d6",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 253,
		"cost": 300,
		"name": "Browning Automatic Rifle (BAR) (.30-06)",
		"type": "Machine Guns",
		"uuid": "ac54e7ea-99ed-4ab2-0573-17c795973e52",
		"active": true,
		"weight": 17,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "75",
		"contains": [],
		"profiles": [
			{
				"ap": 2,
				"rof": 3,
				"name": "",
				"notes": "ROF 1-3. Uses magazines (one action to Reload instead of the usual 2).",
				"range": "20/40/60",
				"reach": 0,
				"shots": 20,
				"damage": "2d8",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Browning Automatic Rifles (BAR) (.30-06)",
		"vehicle_mods": 0,
		"minimum_strength": "d8",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 1110,
		"cost": 10000,
		"name": "Cannon (12 lb)",
		"type": "Cannons",
		"uuid": "df4065ef-1629-4452-a237-0eaf2422a520",
		"active": true,
		"weight": 1200,
		"book_id": 9,
		"effects": [],
		"summary": "Can shoot Bombard, Area of Effect with three different ammo types (Canister, Solid Shot, Shrapnel)",
		"tw_cost": 0,
		"bookPage": "77",
		"contains": [],
		"profiles": [
			{
				"ap": 4,
				"rof": 1,
				"name": "",
				"notes": "",
				"range": "50/100/200",
				"reach": 0,
				"shots": 1,
				"damage": "3d6+1 (solid shot)",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "3d6+1 (solid shot)",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 0
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "",
		"vehicle_mods": 4,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 1111,
		"cost": 10000,
		"name": "Catapult",
		"type": "Catapults",
		"uuid": "229e810a-465e-41af-09ed-ad883e31cdb0",
		"active": true,
		"weight": 0,
		"book_id": 9,
		"effects": [],
		"summary": "Reload time is every 5 minutes with a crew of 4.",
		"tw_cost": 0,
		"bookPage": "77",
		"contains": [],
		"profiles": [
			{
				"ap": 4,
				"rof": 0,
				"name": "",
				"notes": "",
				"range": "24/48/96",
				"reach": 0,
				"shots": 0,
				"damage": "3d6",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "3d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 0
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 25,
		"cost": 200,
		"name": "Chainsaw",
		"type": "Modern",
		"uuid": "1e4456ab-1d3c-4cc5-9e06-1d62440d7333",
		"active": true,
		"weight": 20,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "60",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 0,
				"name": "",
				"notes": "",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "2d6+4",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": true,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d6+4",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 0
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Chainsaws",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 205,
		"cost": 200,
		"name": "Chainsaw",
		"type": "Modern Hand Weapons",
		"uuid": "13b26301-f720-4dd5-a20f-425489a0afd2",
		"active": true,
		"weight": 20,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "72",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 0,
				"name": "",
				"notes": "A natural 1 on the Fighting die hits the user instead",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "2d6+4",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": true,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d6+4",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Chainsaws",
		"vehicle_mods": 0,
		"minimum_strength": "d6",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 1116,
		"cost": 75,
		"name": "Claymore Mine",
		"type": "Mines",
		"uuid": "0dadf89f-97d4-4d8e-00f1-79bee6d6e8a9",
		"active": true,
		"weight": 4,
		"book_id": 9,
		"effects": [],
		"summary": "Notice check before stepping off and detonating it, Repair roll -4 to disarm, but failure detonates immediately. 60° frontal arc, 12\" all hit, 50\" odd result on die roll hit.",
		"tw_cost": 0,
		"bookPage": "79",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 0,
				"name": "",
				"notes": "",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "3d6",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "3d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 0
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 269,
		"cost": 50,
		"name": "Club, Heavy",
		"type": "Medieval Weapons",
		"uuid": "79783177-a1ab-4678-1809-2c35f1ac66e9",
		"active": true,
		"weight": 5,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "72",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 0,
				"name": "",
				"notes": "A sign of low status or thuggery",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "d6",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": true,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Clubs, Heavy",
		"vehicle_mods": 0,
		"minimum_strength": "d6",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 268,
		"cost": 25,
		"name": "Club, Light",
		"type": "Medieval Weapons",
		"uuid": "96059b42-a886-4e0d-8a48-6f44d0a838b9",
		"active": true,
		"weight": 2,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "72",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 0,
				"name": "",
				"notes": "A sign of low status or thuggery",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "d4",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": true,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "d4",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Clubs, Light",
		"vehicle_mods": 0,
		"minimum_strength": "d4",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 97,
		"cost": 200,
		"name": "Colt 1911 (.45)",
		"type": "Black Powder",
		"uuid": "52855223-d7c0-4d46-00f9-f835626d6066",
		"active": true,
		"weight": 4,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "62",
		"contains": [],
		"profiles": [
			{
				"ap": 1,
				"rof": 1,
				"name": "",
				"notes": " Semi-Auto",
				"range": "12/24/48",
				"reach": 0,
				"shots": 7,
				"damage": "2d6+1",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d6+1",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Colt 1911s (.45)",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 229,
		"cost": 200,
		"name": "Colt 1911 (.45)",
		"type": "Modern Pistols (Semi-Auto)",
		"uuid": "357b7232-b023-4371-31b7-67c909cac5e8",
		"active": true,
		"weight": 4,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "74",
		"contains": [],
		"profiles": [
			{
				"ap": 1,
				"rof": 1,
				"name": "",
				"notes": "",
				"range": "12/24/48",
				"reach": 0,
				"shots": 7,
				"damage": "2d6+1",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d6+1",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Colt 1911s (.45)",
		"vehicle_mods": 0,
		"minimum_strength": "d4",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 96,
		"cost": 200,
		"name": "Colt Dragoon (.44)",
		"type": "Black Powder",
		"uuid": "9fb7169a-c031-40af-22c7-d08bdf42da11",
		"active": true,
		"weight": 4,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "62",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 1,
				"name": "",
				"notes": "Revolver",
				"range": "12/24/48",
				"reach": 0,
				"shots": 6,
				"damage": "2d6+1",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d6+1",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Colt Dragoons (.44)",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 226,
		"cost": 200,
		"name": "Colt Peacemaker (.45)",
		"type": "Modern Pistols (Revolvers)",
		"uuid": "b8eb8ee6-91ad-4a34-277d-53b7e19a0d59",
		"active": true,
		"weight": 4,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "74",
		"contains": [],
		"profiles": [
			{
				"ap": 1,
				"rof": 1,
				"name": "",
				"notes": "",
				"range": "12/24/48",
				"reach": 0,
				"shots": 6,
				"damage": "2d6+1",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d6+1",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Colt Peacemakers (.45)",
		"vehicle_mods": 0,
		"minimum_strength": "d4",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 217,
		"cost": 200,
		"name": "Compound Bow",
		"type": "Modern Ranged Weapons",
		"uuid": "e0be19b1-f7f2-4d35-a158-a18860c66bf2",
		"active": true,
		"weight": 3,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "73",
		"contains": [],
		"profiles": [
			{
				"ap": 1,
				"rof": 1,
				"name": "",
				"notes": "",
				"range": "12/24/48",
				"reach": 0,
				"shots": 1,
				"damage": "d6",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Compound Bows",
		"vehicle_mods": 0,
		"minimum_strength": "d6",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 32,
		"cost": 500,
		"name": "Crossbow",
		"type": "Medieval",
		"uuid": "35dd26ff-5362-46f3-9954-b7914185a73d",
		"active": true,
		"weight": 10,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "62",
		"contains": [],
		"profiles": [
			{
				"ap": 2,
				"rof": 1,
				"name": "",
				"notes": "",
				"range": "15/30/60",
				"reach": 0,
				"shots": 0,
				"damage": "2d6",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Crossbows",
		"vehicle_mods": 0,
		"minimum_strength": "d6",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 218,
		"cost": 300,
		"name": "Crossbow",
		"type": "Modern Ranged Weapons",
		"uuid": "81e6c0ec-c234-47d0-0277-2b56a9107f10",
		"active": true,
		"weight": 7,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "73",
		"contains": [],
		"profiles": [
			{
				"ap": 2,
				"rof": 1,
				"name": "",
				"notes": "",
				"range": "15/30/60",
				"reach": 0,
				"shots": 1,
				"damage": "2d6",
				"toHitMod": 0,
				"is_shield": false,
				"melee_only": false,
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Crossbows, Modern",
		"vehicle_mods": 0,
		"minimum_strength": "d6",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 213,
		"cost": 250,
		"name": "Crossbow, Hand Drawn",
		"type": "Ranged Weapons",
		"uuid": "13170d89-24ee-4ede-bd08-c284bc5d25ff",
		"active": true,
		"weight": 5,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "73",
		"contains": [],
		"profiles": [
			{
				"ap": 2,
				"rof": 1,
				"name": "",
				"notes": "Reload 1, Hand-drawn",
				"range": "10/20/40",
				"reach": 0,
				"shots": 1,
				"damage": "2d6",
				"toHitMod": 0,
				"is_shield": false,
				"melee_only": false,
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Crossbows",
		"vehicle_mods": 0,
		"minimum_strength": "d6",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 266,
		"cost": 400,
		"name": "Crossbow, Heavy",
		"type": "Ranged Weapons",
		"uuid": "e58be811-88d8-4a17-39a2-3404aabc2261",
		"active": true,
		"weight": 8,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "73",
		"contains": [],
		"profiles": [
			{
				"ap": 2,
				"rof": 1,
				"name": "",
				"notes": "Reload 2, requires a windlass to load.",
				"range": "15/30/60",
				"reach": 0,
				"shots": 1,
				"damage": "2d8",
				"toHitMod": 0,
				"is_shield": false,
				"melee_only": false,
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Crossbows, Heavy",
		"vehicle_mods": 0,
		"minimum_strength": "d6",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 186,
		"cost": 25,
		"name": "Dagger/Knife",
		"type": "Medieval Hand Weapons",
		"uuid": "962b4724-361f-4e2d-bf6b-17f6886308b7",
		"active": true,
		"weight": 1,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "72",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 0,
				"name": "",
				"notes": "",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "d4",
				"toHitMod": 0,
				"is_shield": false,
				"melee_only": true,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "d4",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 1
			},
			{
				"ap": 0,
				"rof": 1,
				"name": "Thrown",
				"notes": "",
				"range": "3/6/12",
				"reach": 0,
				"shots": 1,
				"damage": "d4",
				"toHitMod": 0,
				"is_shield": false,
				"melee_only": false,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "d4",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 0
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Knives/Daggers",
		"vehicle_mods": 0,
		"minimum_strength": "d4",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 227,
		"cost": 100,
		"name": "Derringer (.41)",
		"type": "Modern Pistols (Revolvers)",
		"uuid": "4b480c0a-42b4-469f-0be5-607bd282f15b",
		"active": true,
		"weight": 1,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "74",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 1,
				"name": "",
				"notes": "",
				"range": "5/10/20",
				"reach": 0,
				"shots": 2,
				"damage": "2d4",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d4",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Derringers (.41)",
		"vehicle_mods": 0,
		"minimum_strength": "d4",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 95,
		"cost": 150,
		"name": "Derringer (.44)",
		"type": "Black Powder",
		"uuid": "473a8782-9cf5-44f7-25d4-10387139b4a0",
		"active": true,
		"weight": 2,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "62",
		"contains": [],
		"profiles": [
			{
				"ap": 1,
				"rof": 1,
				"name": "",
				"notes": "",
				"range": "5/10/20",
				"reach": 0,
				"shots": 2,
				"damage": "2d6+1",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d6+1",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Derringers (.44)",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 98,
		"cost": 300,
		"name": "Desert Eagle (.50)",
		"type": "Black Powder",
		"uuid": "f9d12f5a-65e7-4450-1da8-8c4ca96b6a29",
		"active": true,
		"weight": 8,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "62",
		"contains": [],
		"profiles": [
			{
				"ap": 2,
				"rof": 1,
				"name": "",
				"notes": " Semi-Auto",
				"range": "15/30/60",
				"reach": 0,
				"shots": 7,
				"damage": "2d8",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Desert Eagles (.50)",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 230,
		"cost": 300,
		"name": "Desert Eagle (.50)",
		"type": "Modern Pistols (Semi-Auto)",
		"uuid": "e63801a2-fcda-47ef-0589-b6aba28a4d1e",
		"active": true,
		"weight": 8,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "74",
		"contains": [],
		"profiles": [
			{
				"ap": 2,
				"rof": 1,
				"name": "",
				"notes": "",
				"range": "15/30/60",
				"reach": 0,
				"shots": 7,
				"damage": "2d8",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Desert Eagles (.50)",
		"vehicle_mods": 0,
		"minimum_strength": "d4",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 110,
		"cost": 150,
		"name": "Double-Barrel (12g)",
		"type": "Shotguns",
		"uuid": "b6e97a98-8294-4efe-846b-5aa6e64da37c",
		"active": true,
		"weight": 11,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "63",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 2,
				"name": "",
				"notes": "See Text",
				"range": "12/24/48",
				"reach": 0,
				"shots": 2,
				"damage": "1-3d6",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "1-3d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Double-Barrels (12g)",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 236,
		"cost": 150,
		"name": "Double-Barrel Shotgun",
		"type": "Shotguns",
		"uuid": "c744679f-9d56-4756-090a-f99b6628eda8",
		"active": true,
		"weight": 11,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "74",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 1,
				"name": "",
				"notes": "+2 Shooting, spread",
				"range": "12/24/48",
				"reach": 0,
				"shots": 2,
				"damage": "1-3d6",
				"toHitMod": 0,
				"is_shield": false,
				"melee_only": false,
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "1-3d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			},
			{
				"ap": 0,
				"rof": 1,
				"name": "Slugs",
				"notes": "No spread",
				"range": "12/24/48",
				"reach": 0,
				"shots": 2,
				"damage": "2d10",
				"toHitMod": 0,
				"is_shield": false,
				"melee_only": false,
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d10",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 0
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Double-Barrel Shotguns",
		"vehicle_mods": 0,
		"minimum_strength": "d4",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 133,
		"cost": 1000,
		"name": "DTMG (7.62)",
		"type": "Machine Guns",
		"uuid": "134086fb-23d2-4bce-3b92-d7ff550e9066",
		"active": true,
		"weight": 26,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "63",
		"contains": [],
		"profiles": [
			{
				"ap": 2,
				"rof": 3,
				"name": "",
				"notes": " Auto, May not move",
				"range": "30/60/120",
				"reach": 0,
				"shots": 60,
				"damage": "2d8+1",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d8+1",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "DTMGs (7.62)",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 33,
		"cost": 200,
		"name": "English Long Bow",
		"type": "Medieval",
		"uuid": "f25a1ae4-7ddb-4adf-13f8-2ad54107f39d",
		"active": true,
		"weight": 5,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "62",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 0,
				"name": "",
				"notes": "",
				"range": "15/30/60",
				"reach": 0,
				"shots": 0,
				"damage": "2d6",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "English Long Bows",
		"vehicle_mods": 0,
		"minimum_strength": "d8",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 2,
		"cost": 200,
		"name": "Flail",
		"type": "Blades",
		"uuid": "edb5d4bd-2f41-4e84-9d06-0fd08b935374",
		"active": true,
		"weight": 8,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "60",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 0,
				"name": "",
				"notes": "",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "d6",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": true,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Flails",
		"vehicle_mods": 0,
		"minimum_strength": "d6",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 187,
		"cost": 200,
		"name": "Flail",
		"type": "Medieval Hand Weapons",
		"uuid": "2b55b53f-708d-4d68-80d8-f0b7accd6b89",
		"active": true,
		"weight": 3,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "72",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 0,
				"name": "",
				"notes": "Ignores shield bonus",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "d6",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": true,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Flails",
		"vehicle_mods": 0,
		"minimum_strength": "d6",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 260,
		"cost": 300,
		"name": "Flamethrower",
		"type": "Flamethrowers",
		"uuid": "c1727c1f-3e25-497e-afb6-e12ce8b6ca03",
		"active": true,
		"weight": 70,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "78",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 1,
				"name": "",
				"notes": "",
				"range": "Cone Template",
				"reach": 0,
				"shots": 10,
				"damage": "3d6",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "3d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Flamethrowers",
		"vehicle_mods": 0,
		"minimum_strength": "d8",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 220,
		"cost": 150,
		"name": "Flintlock Pistol",
		"type": "Black Powder Weapons",
		"uuid": "fc84f2b8-79da-4606-95fa-6f134a05fe66",
		"active": true,
		"weight": 3,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "73",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 1,
				"name": "",
				"notes": "Reload 2",
				"range": "5/10/20",
				"reach": 0,
				"shots": 0,
				"damage": "2d6+1",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d6+1",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Flintlock Pistols",
		"vehicle_mods": 0,
		"minimum_strength": "d4",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 92,
		"cost": 150,
		"name": "Flintlock Pistol (.60)",
		"type": "Black Powder",
		"uuid": "7dc0254d-b9d6-4679-178f-cb91eb44de8d",
		"active": true,
		"weight": 3,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "62",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 1,
				"name": "",
				"notes": "2 actions to reload",
				"range": "5/10/20",
				"reach": 0,
				"shots": 1,
				"damage": "2d6+1",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d6+1",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Flintlock Pistols (.60)",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 124,
		"cost": 500,
		"name": "Gatling (.45)",
		"type": "Machine Guns",
		"uuid": "b2fbcf86-90cd-4a54-3ecf-65bacc5a4f6c",
		"active": true,
		"weight": 40,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "63",
		"contains": [],
		"profiles": [
			{
				"ap": 2,
				"rof": 3,
				"name": "",
				"notes": " May not move",
				"range": "24/48/96",
				"reach": 0,
				"shots": 100,
				"damage": "2d8",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Gatlings (.45)",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 254,
		"cost": 500,
		"name": "Gatling (.45)",
		"type": "Machine Guns",
		"uuid": "0cffef90-4bbe-4e69-3496-a4c2788be9fc",
		"active": true,
		"weight": 170,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "75",
		"contains": [],
		"profiles": [
			{
				"ap": 2,
				"rof": 3,
				"name": "",
				"notes": "Min ROF 2",
				"range": "24/48/96",
				"reach": 0,
				"shots": 100,
				"damage": "2d8",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Gatlings (.45)",
		"vehicle_mods": 0,
		"minimum_strength": "d4",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 243,
		"cost": 1000,
		"name": "Gatling Laser",
		"type": "Lasers (Futuristic)",
		"uuid": "315ec81e-504a-4be7-ac36-e340d6529b84",
		"active": true,
		"weight": 20,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "76",
		"contains": [],
		"profiles": [
			{
				"ap": 2,
				"rof": 4,
				"name": "",
				"notes": "No Recoil, Overcharge, Cauterize",
				"range": "50/100/200",
				"reach": 0,
				"shots": 800,
				"damage": "3d6+4",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "3d6+4",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Gatling Lasers",
		"vehicle_mods": 0,
		"minimum_strength": "d8",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 99,
		"cost": 200,
		"name": "Glock (9mm)",
		"type": "Black Powder",
		"uuid": "d7b24526-4f25-478d-20aa-9b9ab1fcedfb",
		"active": true,
		"weight": 3,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "62",
		"contains": [],
		"profiles": [
			{
				"ap": 1,
				"rof": 1,
				"name": "",
				"notes": " Semi-Auto",
				"range": "12/24/48",
				"reach": 0,
				"shots": 17,
				"damage": "2d6",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Glock (9mm)",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 231,
		"cost": 200,
		"name": "Glock (9mm)",
		"type": "Modern Pistols (Semi-Auto)",
		"uuid": "f5ff2a6f-43b5-4e5b-2230-6ae394b226b5",
		"active": true,
		"weight": 3,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "74",
		"contains": [],
		"profiles": [
			{
				"ap": 1,
				"rof": 1,
				"name": "",
				"notes": "",
				"range": "12/24/48",
				"reach": 0,
				"shots": 17,
				"damage": "2d6",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Glocks (9mm)",
		"vehicle_mods": 0,
		"minimum_strength": "d4",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 14,
		"cost": 500,
		"name": "Great Axe",
		"type": "Axes and Mauls",
		"uuid": "ff1a8858-a294-4fc4-002c-6f57d02f5091",
		"active": true,
		"weight": 15,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "60",
		"contains": [],
		"profiles": [
			{
				"ap": -1,
				"rof": 0,
				"name": "",
				"notes": "",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "d10",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": true,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "d10",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Great Axes",
		"vehicle_mods": 0,
		"minimum_strength": "d10",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 1,
		"cost": 400,
		"name": "Great Sword",
		"type": "Blades",
		"uuid": "a0e7bc27-dbb2-4e86-096f-8f6e3f50d899",
		"active": true,
		"weight": 12,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "60",
		"contains": [],
		"profiles": [
			{
				"ap": -1,
				"rof": 0,
				"name": "",
				"notes": "",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "d10",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": true,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "d10",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Great Swords",
		"vehicle_mods": 0,
		"minimum_strength": "d10",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 121,
		"cost": 400,
		"name": "H&K G3 (.308)",
		"type": "Assault Rifles",
		"uuid": "06d8886c-5c5e-4472-9853-59068787d7a8",
		"active": true,
		"weight": 10,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "63",
		"contains": [],
		"profiles": [
			{
				"ap": 2,
				"rof": 3,
				"name": "",
				"notes": " Auto",
				"range": "24/48/96",
				"reach": 0,
				"shots": 20,
				"damage": "2d8",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "H&K G3s (.308)",
		"vehicle_mods": 0,
		"minimum_strength": "d6",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 103,
		"cost": 300,
		"name": "H&K MP5 (9mm)",
		"type": "Submachine Guns",
		"uuid": "733a5cf5-5d02-4b57-3189-f81b77956eca",
		"active": true,
		"weight": 10,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "62",
		"contains": [],
		"profiles": [
			{
				"ap": 1,
				"rof": 3,
				"name": "",
				"notes": " Auto",
				"range": "12/24/48",
				"reach": 0,
				"shots": 30,
				"damage": "2d6",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 233,
		"cost": 300,
		"name": "H&K MP5 (9mm)",
		"type": "Submachine Guns",
		"uuid": "2d0365cc-8e66-4b72-a364-9647d0ebb6cd",
		"active": true,
		"weight": 10,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "74",
		"contains": [],
		"profiles": [
			{
				"ap": 1,
				"rof": 3,
				"name": "",
				"notes": "",
				"range": "12/24/48",
				"reach": 0,
				"shots": 30,
				"damage": "2d6",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "H&K MP5s (9mm)",
		"vehicle_mods": 0,
		"minimum_strength": "d4",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 17,
		"cost": 250,
		"name": "Halberd",
		"type": "Pole Arms",
		"uuid": "03e90bfc-39f5-4618-02e1-780db71a64e0",
		"active": true,
		"weight": 15,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "60",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 0,
				"name": "",
				"notes": "",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "d8",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": true,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Halberds",
		"vehicle_mods": 0,
		"minimum_strength": "d8",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 190,
		"cost": 250,
		"name": "Halberd",
		"type": "Medieval Hand Weapons",
		"uuid": "f632595e-9f9c-43a1-055c-22db3a46c988",
		"active": true,
		"weight": 6,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "72",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 0,
				"name": "",
				"notes": "",
				"range": "",
				"reach": 1,
				"shots": 0,
				"damage": "d8",
				"toHitMod": 0,
				"is_shield": false,
				"melee_only": true,
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Halberds",
		"vehicle_mods": 0,
		"minimum_strength": "d8",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 1015,
		"cost": 1000,
		"name": "Heavy Flamethrower",
		"type": "Vehicular Weapons",
		"uuid": "2a245e32-de90-4c92-b159-1b439c040bbd",
		"active": true,
		"weight": 0,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "80",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 1,
				"name": "",
				"notes": "",
				"range": "Cone or MBT",
				"reach": 0,
				"shots": 0,
				"damage": "3d8",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "3d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 0
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 1129,
		"cost": 1000000,
		"name": "Heavy Laser",
		"type": "Vehicular Weapons",
		"uuid": "66fc03b8-54f8-444b-86a6-c7b9f07e81f8",
		"active": true,
		"weight": 0,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "80",
		"contains": [],
		"profiles": [
			{
				"ap": 30,
				"rof": 1,
				"name": "",
				"notes": "",
				"range": "150/300/600",
				"reach": 0,
				"shots": 0,
				"damage": "4d10",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "4d10",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 0
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 1014,
		"cost": 1000,
		"name": "Heavy Machine Gun",
		"type": "Vehicular Weapons",
		"uuid": "e4f04461-4ec5-4dff-be7d-b3c53035d762",
		"active": true,
		"weight": 0,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "80",
		"contains": [],
		"profiles": [
			{
				"ap": 4,
				"rof": 3,
				"name": "",
				"notes": "",
				"range": "50/100/200",
				"reach": 0,
				"shots": 0,
				"damage": "2d10",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d10",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 0
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 1117,
		"cost": 115000,
		"name": "Hellfire",
		"type": "Missiles",
		"uuid": "ffaac337-07b1-4792-1a5e-9b2c4ff6b12a",
		"active": true,
		"weight": 100,
		"book_id": 9,
		"effects": [],
		"summary": "MBT. A laser-guided missile fired from a vehicle-mounted launcher.",
		"tw_cost": 0,
		"bookPage": "79",
		"contains": [],
		"profiles": [
			{
				"ap": 40,
				"rof": 0,
				"name": "",
				"notes": "",
				"range": "150/300/600",
				"reach": 0,
				"shots": 0,
				"damage": "5d10",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "5d10",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 0
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 246,
		"cost": 350,
		"name": "Hunting Rifle (.308)",
		"type": "Rifles (Lever and Bolt-Action)",
		"uuid": "758fde3a-ab30-416b-1767-afa48a7bc5dd",
		"active": true,
		"weight": 8,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "75",
		"contains": [],
		"profiles": [
			{
				"ap": 2,
				"rof": 1,
				"name": "",
				"notes": "Snapfire, ",
				"range": "24/48/96",
				"reach": 0,
				"shots": 5,
				"damage": "2d8",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Hunting Rifles (.308)",
		"vehicle_mods": 0,
		"minimum_strength": "d6",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 116,
		"cost": 300,
		"name": "Kar98 (7.92)",
		"type": "Rifles",
		"uuid": "a4016bdd-7293-4ff6-98cc-d9a1e15da379",
		"active": true,
		"weight": 9,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "63",
		"contains": [],
		"profiles": [
			{
				"ap": 2,
				"rof": 1,
				"name": "",
				"notes": "",
				"range": "24/48/96",
				"reach": 0,
				"shots": 5,
				"damage": "2d8",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Kar98s (7.92)",
		"vehicle_mods": 0,
		"minimum_strength": "d6",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 3,
		"cost": 1000,
		"name": "Katana",
		"type": "Blades",
		"uuid": "0a7ce7ff-2425-4632-8a9d-6f09050a0c39",
		"active": true,
		"weight": 6,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "60",
		"contains": [],
		"profiles": [
			{
				"ap": 2,
				"rof": 0,
				"name": "",
				"notes": "",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "d6+2",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": true,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "d6+2",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Katanas",
		"vehicle_mods": 0,
		"minimum_strength": "d6",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 191,
		"cost": 1000,
		"name": "Katana",
		"type": "Medieval Hand Weapons",
		"uuid": "9b4286da-88d3-439c-ae55-349157ec79a5",
		"active": true,
		"weight": 3,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "72",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 0,
				"name": "",
				"notes": "",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "d6+1",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": true,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "d6+1",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "",
		"vehicle_mods": 0,
		"minimum_strength": "d6",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 223,
		"cost": 300,
		"name": "Kentucky Rifle",
		"type": "Black Powder Weapons",
		"uuid": "12096ffb-e5fc-4ca4-0be3-f0fa1ab8c8f3",
		"active": true,
		"weight": 8,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "73",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 1,
				"name": "",
				"notes": "Reload 3, The tight rifling requires four actions to reload instead of the usual two.",
				"range": "15/30/60",
				"reach": 0,
				"shots": 1,
				"damage": "2d8",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Kentucky Rifles",
		"vehicle_mods": 0,
		"minimum_strength": "d6",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 93,
		"cost": 300,
		"name": "Kentucky Rifle (.45)",
		"type": "Black Powder",
		"uuid": "7130ebaa-e6dd-43c1-1a28-298ef24588b4",
		"active": true,
		"weight": 8,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "62",
		"contains": [],
		"profiles": [
			{
				"ap": 2,
				"rof": 1,
				"name": "",
				"notes": " 3 actions to reload",
				"range": "15/30/60",
				"reach": 0,
				"shots": 1,
				"damage": "2d8",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Kentucky Rifles (.45)",
		"vehicle_mods": 0,
		"minimum_strength": "d6",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 7,
		"cost": 25,
		"name": "Knife/Dagger",
		"type": "Thrown Weapon",
		"uuid": "f662a0e6-b7af-4be8-a9c4-24737019d6d2",
		"active": true,
		"weight": 1,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "80",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 1,
				"name": "",
				"notes": "",
				"range": "3/6/12",
				"reach": 0,
				"shots": 0,
				"damage": "d4",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "d4",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 0
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Knives/Daggers",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 18,
		"cost": 300,
		"name": "Lance",
		"type": "Pole Arms",
		"uuid": "bd474fde-b3c4-4a9a-870f-4a0812aeab2c",
		"active": true,
		"weight": 10,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "60",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 0,
				"name": "",
				"notes": "",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "d8",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": true,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Lances",
		"vehicle_mods": 0,
		"minimum_strength": "d8",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 192,
		"cost": 300,
		"name": "Lance",
		"type": "Medieval Hand Weapons",
		"uuid": "24f1342f-1158-449d-82c1-f5dfaad9a302",
		"active": true,
		"weight": 6,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "72",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 0,
				"name": "",
				"notes": " when charging, only usable in mounted combat",
				"range": "",
				"reach": 2,
				"shots": 0,
				"damage": "d8",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": true,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Lances",
		"vehicle_mods": 0,
		"minimum_strength": "d8",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 109,
		"cost": 500,
		"name": "Laser MG",
		"type": "Futuristic",
		"uuid": "7a743784-d8e9-4acc-0da2-33e63456f476",
		"active": true,
		"weight": 15,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "64",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 5,
				"name": "",
				"notes": "Auto",
				"range": "50/100/200",
				"reach": 0,
				"shots": 200,
				"damage": "1-3d6",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "1-3d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Laser MGs",
		"vehicle_mods": 0,
		"minimum_strength": "d8",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 107,
		"cost": 200,
		"name": "Laser Pistol",
		"type": "Futuristic",
		"uuid": "b9b80105-bf98-47d4-290f-96f7d121ab04",
		"active": true,
		"weight": 4,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "64",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 1,
				"name": "",
				"notes": "Semi-Auto",
				"range": "15/30/60",
				"reach": 0,
				"shots": 24,
				"damage": "1-3d6",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "1-3d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Laser Pistols",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 240,
		"cost": 250,
		"name": "Laser Pistol",
		"type": "Lasers (Futuristic)",
		"uuid": "cbe1b05f-8528-4fb1-136f-dafd74b7f19f",
		"active": true,
		"weight": 2,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "76",
		"contains": [],
		"profiles": [
			{
				"ap": 2,
				"rof": 1,
				"name": "",
				"notes": " No Recoil, Overcharge, Cauterize",
				"range": "15/30/60",
				"reach": 0,
				"shots": 50,
				"damage": "2d6",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Laser Pistols",
		"vehicle_mods": 0,
		"minimum_strength": "d4",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 108,
		"cost": 300,
		"name": "Laser Rifle",
		"type": "Futuristic",
		"uuid": "b0925322-6fb9-4a26-21eb-867e53c6176d",
		"active": true,
		"weight": 8,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "64",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 3,
				"name": "",
				"notes": "Auto, 3RB",
				"range": "30/60/120",
				"reach": 0,
				"shots": 48,
				"damage": "1-3d6",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "1-3d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Laser Rifles",
		"vehicle_mods": 0,
		"minimum_strength": "d6",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 242,
		"cost": 700,
		"name": "Laser Rifle",
		"type": "Lasers (Futuristic)",
		"uuid": "25c91fd2-4f7c-4a51-b891-89a0ac4439dd",
		"active": true,
		"weight": 8,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "76",
		"contains": [],
		"profiles": [
			{
				"ap": 2,
				"rof": 3,
				"name": "",
				"notes": "No Recoil, Overcharge, Cauterize",
				"range": "30/60/120",
				"reach": 0,
				"shots": 100,
				"damage": "3d6",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "3d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Laser Rifles",
		"vehicle_mods": 0,
		"minimum_strength": "d6",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 241,
		"cost": 500,
		"name": "Laser SMG",
		"type": "Lasers (Futuristic)",
		"uuid": "ce504a90-c290-4998-1837-158669917bb3",
		"active": true,
		"weight": 4,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "76",
		"contains": [],
		"profiles": [
			{
				"ap": 2,
				"rof": 4,
				"name": "",
				"notes": "No Recoil, Overcharge, Cauterize",
				"range": "15/30/60",
				"reach": 0,
				"shots": 100,
				"damage": "2d6",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Laser SMGs",
		"vehicle_mods": 0,
		"minimum_strength": "d4",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 30,
		"cost": 1000,
		"name": "Laser Sword",
		"type": "Futuristic",
		"uuid": "7839c9db-e8d2-42cd-baee-0e5b0b0064b6",
		"active": true,
		"weight": 5,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "60",
		"contains": [],
		"profiles": [
			{
				"ap": 12,
				"rof": 0,
				"name": "",
				"notes": "",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "d6+8",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": true,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "d6+8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Laser Swords",
		"vehicle_mods": 0,
		"minimum_strength": "d6",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 210,
		"cost": 1000,
		"name": "Laser Sword",
		"type": "Futuristic Hand Weapons",
		"uuid": "f8406503-fcd1-4940-85ca-de6b2b6be9aa",
		"active": true,
		"weight": 2,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "72",
		"contains": [],
		"profiles": [
			{
				"ap": 12,
				"rof": 0,
				"name": "",
				"notes": "2",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "d6+8",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": true,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "d6+8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Laser Swords",
		"vehicle_mods": 0,
		"minimum_strength": "d4",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 214,
		"cost": 300,
		"name": "Long Bow",
		"type": "Ranged Weapons",
		"uuid": "1f525be6-fcc2-44ca-8db0-ebc9e92d34e2",
		"active": true,
		"weight": 3,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "73",
		"contains": [],
		"profiles": [
			{
				"ap": 1,
				"rof": 1,
				"name": "",
				"notes": "",
				"range": "15/30/60",
				"reach": 0,
				"shots": 1,
				"damage": "2d6",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Long Bows",
		"vehicle_mods": 0,
		"minimum_strength": "d8",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 4,
		"cost": 300,
		"name": "Long Sword",
		"type": "Blades",
		"uuid": "9c8050e3-cf1a-4714-9d23-ba518e0ee9f3",
		"active": true,
		"weight": 8,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "60",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 0,
				"name": "",
				"notes": "",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "d8",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": true,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Long Swords",
		"vehicle_mods": 0,
		"minimum_strength": "d8",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 122,
		"cost": 400,
		"name": "M-16 (5.56)",
		"type": "Assault Rifles",
		"uuid": "e69f98f8-d744-486e-04aa-0d0c41e274d3",
		"active": true,
		"weight": 8,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "63",
		"contains": [],
		"profiles": [
			{
				"ap": 2,
				"rof": 3,
				"name": "",
				"notes": " Auto, 3RB",
				"range": "24/48/96",
				"reach": 0,
				"shots": 30,
				"damage": "2d8",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "M-16s (5.56)",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 251,
		"cost": 400,
		"name": "M-16 (5.56mm)",
		"type": "Rifles (Assault)",
		"uuid": "2d52c312-7b9d-4643-8a44-1f26405a4c63",
		"active": true,
		"weight": 8,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "75",
		"contains": [],
		"profiles": [
			{
				"ap": 2,
				"rof": 3,
				"name": "",
				"notes": "The A-2 version can also fire a Three-Round Burst (see page 66).",
				"range": "24/48/96",
				"reach": 0,
				"shots": 20,
				"damage": "2d8",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "M-16s (5.56mm)",
		"vehicle_mods": 0,
		"minimum_strength": "d4",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 115,
		"cost": 300,
		"name": "M1 (.30)",
		"type": "Rifles",
		"uuid": "5664fd0e-7bbd-4bbc-b3cb-14162e1eb281",
		"active": true,
		"weight": 10,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "63",
		"contains": [],
		"profiles": [
			{
				"ap": 2,
				"rof": 1,
				"name": "",
				"notes": " Semi-Auto",
				"range": "24/48/96",
				"reach": 0,
				"shots": 8,
				"damage": "2d8",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "M1s (.30)",
		"vehicle_mods": 0,
		"minimum_strength": "d6",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 245,
		"cost": 300,
		"name": "M1 Garand (.30-06)",
		"type": "Rifles (Lever and Bolt-Action)",
		"uuid": "69b68ccf-1d7f-4844-a76e-344488701e17",
		"active": true,
		"weight": 10,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "75",
		"contains": [],
		"profiles": [
			{
				"ap": 2,
				"rof": 1,
				"name": "",
				"notes": "",
				"range": "24/48/96",
				"reach": 0,
				"shots": 8,
				"damage": "2d8",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "M1 Garands (.30-06)",
		"vehicle_mods": 0,
		"minimum_strength": "d6",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 126,
		"cost": 750,
		"name": "M1919 (.30)",
		"type": "Machine Guns",
		"uuid": "f0a5f8f9-4800-42fb-1d26-9b942016f73d",
		"active": true,
		"weight": 32,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "63",
		"contains": [],
		"profiles": [
			{
				"ap": 2,
				"rof": 3,
				"name": "",
				"notes": " May not move",
				"range": "24/48/96",
				"reach": 0,
				"shots": 250,
				"damage": "2d8",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "M1919s (.30)",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 125,
		"cost": 1000,
		"name": "M2 Browning (.50 Cal)",
		"type": "Machine Guns",
		"uuid": "605a0674-8105-400b-a1c7-4666bd39b6eb",
		"active": true,
		"weight": 84,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "63",
		"contains": [],
		"profiles": [
			{
				"ap": 4,
				"rof": 3,
				"name": "",
				"notes": " Auto, May not move",
				"range": "50/100/200",
				"reach": 0,
				"shots": 200,
				"damage": "2d10",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d10",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "M2 Brownings (.50 Cal)",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 255,
		"cost": 1500,
		"name": "M2 Browning (.50 Cal)",
		"type": "Machine Guns",
		"uuid": "9a00ccec-4848-415a-2fd4-e5cde3098357",
		"active": true,
		"weight": 84,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "75",
		"contains": [],
		"profiles": [
			{
				"ap": 4,
				"rof": 3,
				"name": "",
				"notes": "Min ROF 2",
				"range": "50/100/200",
				"reach": 0,
				"shots": 200,
				"damage": "2d10",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d10",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "M2 Brownings (.50 Cal)",
		"vehicle_mods": 0,
		"minimum_strength": "d4",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 395,
		"cost": 1500,
		"name": "M203 40MM",
		"type": "Modern Firearms",
		"uuid": "edb86f2f-c5c0-46b0-b0ef-6af7baa923e9",
		"active": true,
		"weight": 3,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "80",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 1,
				"name": "",
				"notes": "Snapfire, MBT",
				"range": "24/48/96",
				"reach": 0,
				"shots": 1,
				"damage": "4d8",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "4d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "M203 40MMs",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 127,
		"cost": 1000,
		"name": "M60 (7.62)",
		"type": "Machine Guns",
		"uuid": "84475be7-2246-43e3-b651-2edbf013480c",
		"active": true,
		"weight": 33,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "63",
		"contains": [],
		"profiles": [
			{
				"ap": 2,
				"rof": 3,
				"name": "",
				"notes": " Snapfire",
				"range": "30/60/120",
				"reach": 0,
				"shots": 250,
				"damage": "2d8+1",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d8+1",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "M60s (7.62)",
		"vehicle_mods": 0,
		"minimum_strength": "d8",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 256,
		"cost": 6000,
		"name": "M60 (7.62)",
		"type": "Machine Guns",
		"uuid": "9daee12a-ed29-4b1a-aa02-6b8c474e82ac",
		"active": true,
		"weight": 33,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "75",
		"contains": [],
		"profiles": [
			{
				"ap": 2,
				"rof": 3,
				"name": "",
				"notes": " Min ROF 2",
				"range": "30/60/120",
				"reach": 0,
				"shots": 100,
				"damage": "2d8+1",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d8+1",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "M60s (7.62)",
		"vehicle_mods": 0,
		"minimum_strength": "d8",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 396,
		"cost": 750,
		"name": "M72 Law",
		"type": "Modern Firearms",
		"uuid": "10662c72-eef2-4e0a-3ceb-5ba570e1c49c",
		"active": true,
		"weight": 5,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "80",
		"contains": [],
		"profiles": [
			{
				"ap": 22,
				"rof": 1,
				"name": "",
				"notes": "Snapfire, MBT",
				"range": "24/48/96",
				"reach": 0,
				"shots": 1,
				"damage": "4d8+2",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "4d8+2",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "M72 Laws",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 267,
		"cost": 100,
		"name": "Mace",
		"type": "Medieval Weapons",
		"uuid": "0cb5026c-5133-4adc-3953-d759fee061a4",
		"active": true,
		"weight": 4,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "72",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 0,
				"name": "",
				"notes": "",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "d6",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": true,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Maces",
		"vehicle_mods": 0,
		"minimum_strength": "d6",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 15,
		"cost": 400,
		"name": "Maul",
		"type": "Axes and Mauls",
		"uuid": "d104f93f-97f3-4f61-0a3d-64e4db16a4d8",
		"active": true,
		"weight": 20,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "60",
		"contains": [],
		"profiles": [
			{
				"ap": -1,
				"rof": 0,
				"name": "",
				"notes": "",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "d8",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": true,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Mauls",
		"vehicle_mods": 0,
		"minimum_strength": "d8",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 194,
		"cost": 400,
		"name": "Maul",
		"type": "Medieval Hand Weapons",
		"uuid": "20ceb18b-5408-4b1c-316f-d18257edd474",
		"active": true,
		"weight": 10,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "72",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 0,
				"name": "",
				"notes": "+2 damage to break objects (page 98)",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "10",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": true,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "10",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Mauls",
		"vehicle_mods": 0,
		"minimum_strength": "d10",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 1013,
		"cost": 750,
		"name": "Medium Machine Gun",
		"type": "Vehicular Weapons",
		"uuid": "ca06fe86-7df6-4a6b-ba83-3b0457777f3d",
		"active": true,
		"weight": 0,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "80",
		"contains": [],
		"profiles": [
			{
				"ap": 2,
				"rof": 3,
				"name": "",
				"notes": "",
				"range": "30/60/120",
				"reach": 0,
				"shots": 0,
				"damage": "2d8+1",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d8+1",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 0
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 129,
		"cost": 500,
		"name": "MG34 (7.92)",
		"type": "Machine Guns",
		"uuid": "d7f8bb86-9c52-4e4b-0c78-9c283b6e4e98",
		"active": true,
		"weight": 26,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "63",
		"contains": [],
		"profiles": [
			{
				"ap": 2,
				"rof": 3,
				"name": "",
				"notes": " Snapfire",
				"range": "30/60/120",
				"reach": 0,
				"shots": 200,
				"damage": "2d8+1",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d8+1",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "MG34s (7.92)",
		"vehicle_mods": 0,
		"minimum_strength": "d8",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 130,
		"cost": 500,
		"name": "MG42 (7.92)",
		"type": "Machine Guns",
		"uuid": "644038ea-bd68-4d26-0edb-d6be00bbc07c",
		"active": true,
		"weight": 26,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "63",
		"contains": [],
		"profiles": [
			{
				"ap": 2,
				"rof": 4,
				"name": "",
				"notes": " Snapfire",
				"range": "30/60/120",
				"reach": 0,
				"shots": 200,
				"damage": "2d8+1",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d8+1",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "MG42s (7.92)",
		"vehicle_mods": 0,
		"minimum_strength": "d8",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 257,
		"cost": 750,
		"name": "MG42 (7.92)",
		"type": "Machine Guns",
		"uuid": "362c9e4a-7a70-43bb-0fdb-2b809bce8be6",
		"active": true,
		"weight": 26,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "75",
		"contains": [],
		"profiles": [
			{
				"ap": 2,
				"rof": 4,
				"name": "",
				"notes": " Min ROF 2",
				"range": "30/60/120",
				"reach": 0,
				"shots": 200,
				"damage": "2d8+1",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d8+1",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "MG42s (7.92)",
		"vehicle_mods": 0,
		"minimum_strength": "d8",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 259,
		"cost": 100000,
		"name": "Minigun (7.62)",
		"type": "Machine Guns",
		"uuid": "eba99ec8-ca57-4308-19b8-024228ad8e66",
		"active": true,
		"weight": 85,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "75",
		"contains": [],
		"profiles": [
			{
				"ap": 2,
				"rof": 5,
				"name": "",
				"notes": " Min ROF 3",
				"range": "30/60/120",
				"reach": 0,
				"shots": 500,
				"damage": "2d8",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Miniguns (7.62)",
		"vehicle_mods": 0,
		"minimum_strength": "d10",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 261,
		"cost": 40,
		"name": "Mk II (WW2 Pineapple)",
		"type": "Grenades",
		"uuid": "0eac3044-3848-4b8f-b497-727588694172",
		"active": true,
		"weight": 1,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "78",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 1,
				"name": "",
				"notes": "MBT",
				"range": "4/8/16",
				"reach": 0,
				"shots": 1,
				"damage": "3d6",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "3d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Mk IIs (WW2 Pineapple)",
		"vehicle_mods": 0,
		"minimum_strength": "d4",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 263,
		"cost": 50,
		"name": "Mk67 (Modern)",
		"type": "Grenades",
		"uuid": "97e4dd96-1056-40b8-10ed-d817236195b9",
		"active": true,
		"weight": 1,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "78",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 1,
				"name": "",
				"notes": "MBT",
				"range": "",
				"reach": 0,
				"shots": 1,
				"damage": "3d6",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "3d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Mk67s (Modern)",
		"vehicle_mods": 0,
		"minimum_strength": "d4",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 28,
		"cost": 250,
		"name": "Molecular Knife",
		"type": "Futuristic",
		"uuid": "29b4c0bc-cec1-4371-93f9-97edae95d13c",
		"active": true,
		"weight": 1,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "60",
		"contains": [],
		"profiles": [
			{
				"ap": 2,
				"rof": 0,
				"name": "",
				"notes": "",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "d4+2",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": true,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "d4+2",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 0
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Molecular Knives",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 208,
		"cost": 250,
		"name": "Molecular Knife",
		"type": "Futuristic Hand Weapons",
		"uuid": "ed7785c0-1ceb-426c-b474-1dfa7018e133",
		"active": true,
		"weight": 0.5,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "72",
		"contains": [],
		"profiles": [
			{
				"ap": 2,
				"rof": 0,
				"name": "",
				"notes": " Cannot be thrown",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "d4+2",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": true,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "d4+2",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Molecular Knives",
		"vehicle_mods": 0,
		"minimum_strength": "d4",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 29,
		"cost": 500,
		"name": "Molecular Sword",
		"type": "Futuristic",
		"uuid": "d823a56c-eb8b-4d34-b0b9-8c7670eed2ab",
		"active": true,
		"weight": 8,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "60",
		"contains": [],
		"profiles": [
			{
				"ap": 4,
				"rof": 0,
				"name": "",
				"notes": "",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "d8+2",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": true,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "d8+2",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Molecular Swords",
		"vehicle_mods": 0,
		"minimum_strength": "d8",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 209,
		"cost": 500,
		"name": "Molecular Sword",
		"type": "Futuristic Hand Weapons",
		"uuid": "9cf2f057-96b7-4c1c-30ba-0121674b54c5",
		"active": true,
		"weight": 2,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "72",
		"contains": [],
		"profiles": [
			{
				"ap": 4,
				"rof": 0,
				"name": "",
				"notes": "",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "d8+2",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": true,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "d8+2",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Molecular Swords",
		"vehicle_mods": 0,
		"minimum_strength": "d6",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 104,
		"cost": 300,
		"name": "MP40 (9mm)",
		"type": "Submachine Guns",
		"uuid": "2ace08f6-b8b0-4dda-a96c-5034af38dd05",
		"active": true,
		"weight": 11,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "62",
		"contains": [],
		"profiles": [
			{
				"ap": 1,
				"rof": 3,
				"name": "",
				"notes": " Auto",
				"range": "12/24/48",
				"reach": 0,
				"shots": 32,
				"damage": "2d6",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "MP40 (9mm)",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 219,
		"cost": 50,
		"name": "Net (Weighted)",
		"type": "Ranged Weapons",
		"uuid": "5d24ff2e-9005-44cb-b29d-f412c7fe407b",
		"active": true,
		"weight": 8,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "72",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 1,
				"name": "",
				"notes": "A successful hit means the target is Entangled (see page 98). The net is Hardness 10 and vulnerable only to cutting attacks.",
				"range": "3/6/12",
				"reach": 0,
				"shots": 1,
				"damage": "-",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "-",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Nets (Weighted)",
		"vehicle_mods": 0,
		"minimum_strength": "d4",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 397,
		"cost": 1000,
		"name": "Panzerschreck",
		"type": "Modern Firearms",
		"uuid": "e9b8e5b7-3b54-4cbf-2f4b-fe2cf19a7520",
		"active": true,
		"weight": 20,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "80",
		"contains": [],
		"profiles": [
			{
				"ap": 12,
				"rof": 1,
				"name": "",
				"notes": "MBT, Snapfire",
				"range": "15/30/60",
				"reach": 0,
				"shots": 1,
				"damage": "4d8",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "4d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Panzerschrecks",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 100,
		"cost": 200,
		"name": "Peacemaker (.45)",
		"type": "Black Powder",
		"uuid": "1ca8beff-7c3b-485c-291d-8a7709c3a74a",
		"active": true,
		"weight": 3,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "62",
		"contains": [],
		"profiles": [
			{
				"ap": 1,
				"rof": 1,
				"name": "",
				"notes": " Revolver",
				"range": "12/24/48",
				"reach": 0,
				"shots": 6,
				"damage": "2d6+1",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d6+1",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Peacemakers (.45)",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 195,
		"cost": 400,
		"name": "Pike",
		"type": "Medieval Hand Weapons",
		"uuid": "19f05f18-cfb1-4421-3a3d-dcbeb7789e18",
		"active": true,
		"weight": 18,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "73",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 0,
				"name": "",
				"notes": "Reach 2, two hands",
				"range": "",
				"reach": 2,
				"shots": 0,
				"damage": "d8",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": true,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Pikes",
		"vehicle_mods": 0,
		"minimum_strength": "d8",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 883,
		"cost": 150,
		"name": "Police Revolver (.38)",
		"type": "Pistols",
		"uuid": "31b72d44-c29c-42a2-38a6-2b9c4cd07f31",
		"active": true,
		"weight": 2,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "74",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 1,
				"name": "",
				"notes": "",
				"range": "10/20/40",
				"reach": 0,
				"shots": 6,
				"damage": "2d6",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 0
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "",
		"vehicle_mods": 0,
		"minimum_strength": "d4",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 262,
		"cost": 50,
		"name": "Potato Masher (WW2)",
		"type": "Grenades",
		"uuid": "8de878e6-eab2-41bc-3da3-7e361eaad02b",
		"active": true,
		"weight": 2,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "78",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 1,
				"name": "",
				"notes": "MBT",
				"range": "5/10/20",
				"reach": 0,
				"shots": 1,
				"damage": "3d6–2",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "3d6–2",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Potato Mashers (WW2)",
		"vehicle_mods": 0,
		"minimum_strength": "d4",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 111,
		"cost": 150,
		"name": "Pump Action (12g)",
		"type": "Shotguns",
		"uuid": "676c6724-a273-4f59-88db-88787e31bb9f",
		"active": true,
		"weight": 8,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "63",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 1,
				"name": "",
				"notes": "See text",
				"range": "12/24/48",
				"reach": 0,
				"shots": 6,
				"damage": "1-3d6",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "1-3d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Pump Actions (12g)",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 237,
		"cost": 150,
		"name": "Pump Action Shotgun",
		"type": "Shotguns",
		"uuid": "2d86a6d5-d9f9-45a2-1215-b0cda5c07556",
		"active": true,
		"weight": 8,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "74",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 1,
				"name": "",
				"notes": "+2 Shooting, spread",
				"range": "12/24/48",
				"reach": 0,
				"shots": 6,
				"damage": "1-3d6",
				"toHitMod": 0,
				"is_shield": false,
				"melee_only": false,
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "1-3d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			},
			{
				"ap": 0,
				"rof": 1,
				"name": "Slugs",
				"notes": "No spread",
				"range": "12/24/48",
				"reach": 0,
				"shots": 6,
				"damage": "2d10",
				"toHitMod": 0,
				"is_shield": false,
				"melee_only": false,
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d10",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 0
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Pump Action Shotguns",
		"vehicle_mods": 0,
		"minimum_strength": "d4",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 5,
		"cost": 150,
		"name": "Rapier",
		"type": "Blades",
		"uuid": "14458b7f-dc6a-44cb-1bd3-f5c60b846c02",
		"active": true,
		"weight": 3,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "60",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 0,
				"name": "",
				"notes": "",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "d4",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": true,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 1,
				"damage_original": "d4",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Rapiers",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 196,
		"cost": 150,
		"name": "Rapier",
		"type": "Medieval Hand Weapons",
		"uuid": "38f67d8e-857e-407b-bb68-d31b26bda459",
		"active": true,
		"weight": 2,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "72",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 0,
				"name": "",
				"notes": "",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "d4",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": true,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 1,
				"damage_original": "d4",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Rapiers",
		"vehicle_mods": 0,
		"minimum_strength": "d4",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 101,
		"cost": 100,
		"name": "Ruger (.22)",
		"type": "Black Powder",
		"uuid": "e67d8207-3d72-4774-80c8-128a1d9b61a6",
		"active": true,
		"weight": 2,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "62",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 1,
				"name": "",
				"notes": "Semi-Auto",
				"range": "10/20/40",
				"reach": 0,
				"shots": 9,
				"damage": "2d6-1",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d6-1",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Rugers (.22)",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 232,
		"cost": 100,
		"name": "Ruger (.22)",
		"type": "Modern Pistols (Semi-Auto)",
		"uuid": "fea28882-04b0-4ba6-bf2c-f6d6db34c877",
		"active": true,
		"weight": 2,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "74",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 1,
				"name": "",
				"notes": "",
				"range": "10/20/40",
				"reach": 0,
				"shots": 9,
				"damage": "2d6-2",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d6-2",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Rugers (.22)",
		"vehicle_mods": 0,
		"minimum_strength": "d4",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 102,
		"cost": 250,
		"name": "S&W (.357)",
		"type": "Black Powder",
		"uuid": "44270b78-63db-4d5a-18f7-81ceecb434a2",
		"active": true,
		"weight": 4,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "62",
		"contains": [],
		"profiles": [
			{
				"ap": 1,
				"rof": 1,
				"name": "",
				"notes": " Revolver",
				"range": "12/24/48",
				"reach": 0,
				"shots": 6,
				"damage": "2d6+1",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d6+1",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "S&W (.357)",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 131,
		"cost": 750,
		"name": "SAW (5.56)",
		"type": "Machine Guns",
		"uuid": "eec14ab2-148c-4216-986e-2085ae3a9e4a",
		"active": true,
		"weight": 20,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "63",
		"contains": [],
		"profiles": [
			{
				"ap": 2,
				"rof": 4,
				"name": "",
				"notes": " Snapfire",
				"range": "30/60/120",
				"reach": 0,
				"shots": 200,
				"damage": "2d8",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "SAWs (5.56)",
		"vehicle_mods": 0,
		"minimum_strength": "d8",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 258,
		"cost": 4000,
		"name": "SAW (5.56)",
		"type": "Machine Guns",
		"uuid": "df3b812a-a85c-4513-a8e0-fb6cb97c6421",
		"active": true,
		"weight": 20,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "75",
		"contains": [],
		"profiles": [
			{
				"ap": 2,
				"rof": 4,
				"name": "",
				"notes": " Min ROF 2",
				"range": "30/60/120",
				"reach": 0,
				"shots": 200,
				"damage": "2d8",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "SAWs (5.56)",
		"vehicle_mods": 0,
		"minimum_strength": "d8",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 112,
		"cost": 150,
		"name": "Sawed-Off DB (12g)",
		"type": "Shotguns",
		"uuid": "1367de18-b3c8-44f1-afe7-445fac1dbc1f",
		"active": true,
		"weight": 6,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "63",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 2,
				"name": "",
				"notes": "See Text",
				"range": "5/10/20",
				"reach": 0,
				"shots": 2,
				"damage": "1-3d6",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "1-3d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Sawed-Off DBs (12g)",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 238,
		"cost": 150,
		"name": "Sawed-Off Double-Barrel Shotgun",
		"type": "Shotguns",
		"uuid": "cbefc044-6f2f-4ed5-9bcf-b044ca2dc445",
		"active": true,
		"weight": 6,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "74",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 1,
				"name": "",
				"notes": "+2 Shooting, Spread",
				"range": "5/10/20",
				"reach": 0,
				"shots": 2,
				"damage": "1-3d6",
				"toHitMod": 0,
				"is_shield": false,
				"melee_only": false,
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "1-3d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			},
			{
				"ap": 0,
				"rof": 1,
				"name": "Slugs",
				"notes": "No Spread",
				"range": "5/10/20",
				"reach": 0,
				"shots": 2,
				"damage": "2d10",
				"toHitMod": 0,
				"is_shield": false,
				"melee_only": false,
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d10",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 0
			},
			{
				"ap": 0,
				"rof": 0,
				"name": "Short Range",
				"notes": "",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "3d6",
				"toHitMod": 0,
				"vtt_only": true,
				"is_shield": false,
				"melee_only": false,
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "3d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 0
			},
			{
				"ap": 0,
				"rof": 0,
				"name": "Medium Range",
				"notes": "",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "2d6",
				"toHitMod": 0,
				"vtt_only": true,
				"is_shield": false,
				"melee_only": false,
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 0
			},
			{
				"ap": 0,
				"rof": 0,
				"name": "Long Range",
				"notes": "",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "1d6",
				"toHitMod": 0,
				"vtt_only": true,
				"is_shield": false,
				"melee_only": false,
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "1d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 0
			},
			{
				"ap": 0,
				"rof": 0,
				"name": "Double-Barrel Short Range",
				"notes": "",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "3d6+4",
				"toHitMod": 0,
				"vtt_only": true,
				"is_shield": false,
				"melee_only": false,
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "3d6+4",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 0
			},
			{
				"ap": 0,
				"rof": 0,
				"name": "Double-Barrel Medium Range",
				"notes": "",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "2d6+4",
				"toHitMod": 0,
				"vtt_only": true,
				"is_shield": false,
				"melee_only": false,
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d6+4",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 0
			},
			{
				"ap": 0,
				"rof": 0,
				"name": "Double-Barrel Long Range",
				"notes": "",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "1d6+4",
				"toHitMod": 0,
				"vtt_only": true,
				"is_shield": false,
				"melee_only": false,
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "1d6+4",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 0
			},
			{
				"ap": 0,
				"rof": 1,
				"name": "Double Barrel Slugs",
				"notes": "No Spread",
				"range": "5/10/20",
				"reach": 0,
				"shots": 2,
				"damage": "2d10+4",
				"toHitMod": 0,
				"vtt_only": true,
				"is_shield": false,
				"melee_only": false,
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d10+4",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 0
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Sawed-Off Double-Barrel Shotguns",
		"vehicle_mods": 0,
		"minimum_strength": "d4",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 117,
		"cost": 400,
		"name": "Sharps Big 50 (.50)",
		"type": "Rifles",
		"uuid": "176c3a2e-c8ae-4618-1fc8-fa13eb582be0",
		"active": true,
		"weight": 11,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "63",
		"contains": [],
		"profiles": [
			{
				"ap": 2,
				"rof": 1,
				"name": "",
				"notes": " Snapfire Penalty",
				"range": "",
				"reach": 0,
				"shots": 1,
				"damage": "2d10",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d10",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Sharps Big 50s (.50)",
		"vehicle_mods": 0,
		"minimum_strength": "d8",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 247,
		"cost": 400,
		"name": "Sharps Big 50 (.50)",
		"type": "Rifles (Lever and Bolt-Action)",
		"uuid": "21b072b0-9594-4542-0aca-684f443bad5c",
		"active": true,
		"weight": 11,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "75",
		"contains": [],
		"profiles": [
			{
				"ap": 2,
				"rof": 1,
				"name": "",
				"notes": " Snapfire",
				"range": "30/60/120",
				"reach": 0,
				"shots": 7,
				"damage": "2d10",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d10",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Sharps Big 50s (.50)",
		"vehicle_mods": 0,
		"minimum_strength": "d8",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 6,
		"cost": 200,
		"name": "Short Sword",
		"type": "Blades",
		"uuid": "3afdd905-b51a-4f18-3912-3385b09f578c",
		"active": true,
		"weight": 4,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "60",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 0,
				"name": "",
				"notes": "",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "d6",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": true,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Short Swords",
		"vehicle_mods": 0,
		"minimum_strength": "d6",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 1118,
		"cost": 600000,
		"name": "Sidewinder",
		"type": "Missiles",
		"uuid": "fc96eac1-325b-4f2d-8343-49264c43f74c",
		"active": true,
		"weight": 188,
		"book_id": 9,
		"effects": [],
		"summary": "SBT. A short range, heat-seeking missile fired from an aircraft.",
		"tw_cost": 0,
		"bookPage": "79",
		"contains": [],
		"profiles": [
			{
				"ap": 6,
				"rof": 0,
				"name": "",
				"notes": "",
				"range": "100/200/400",
				"reach": 0,
				"shots": 0,
				"damage": "4d8",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "4d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 0
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 34,
		"cost": 10,
		"name": "Sling",
		"type": "Medieval",
		"uuid": "39910b40-a371-492e-be85-dd65dace0593",
		"active": true,
		"weight": 1,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "62",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 1,
				"name": "",
				"notes": "",
				"range": "4/8/16",
				"reach": 0,
				"shots": 0,
				"damage": "d4",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "d4",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 0
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Slings",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 215,
		"cost": 10,
		"name": "Sling",
		"type": "Medieval Hand Weapons",
		"uuid": "39e0d3a0-b2a1-4b1c-330e-78a6129f48db",
		"active": true,
		"weight": 1,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "72",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 1,
				"name": "",
				"notes": "",
				"range": "4/8/16",
				"reach": 0,
				"shots": 1,
				"damage": "d4",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "d4",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Slings",
		"vehicle_mods": 0,
		"minimum_strength": "d4",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 228,
		"cost": 250,
		"name": "Smith & Wesson (.357)",
		"type": "Modern Pistols (Revolvers)",
		"uuid": "f53a58fb-01d0-4c50-2d77-a5ec43b503de",
		"active": true,
		"weight": 5,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "74",
		"contains": [],
		"profiles": [
			{
				"ap": 1,
				"rof": 1,
				"name": "",
				"notes": "",
				"range": "12/24/48",
				"reach": 0,
				"shots": 6,
				"damage": "2d6+1",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d6+1",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Smith & Wesson (.357)",
		"vehicle_mods": 0,
		"minimum_strength": "d4",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 264,
		"cost": 50,
		"name": "Smoke Grenade",
		"type": "Grenades",
		"uuid": "c9d44843-bd0b-4ee8-a413-a5d229a50486",
		"active": true,
		"weight": 1,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "78",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 1,
				"name": "",
				"notes": "Creates an area of smoke in a LBT that obscures vision (–4).",
				"range": "5/1-/2-",
				"reach": 0,
				"shots": 1,
				"damage": "-",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "-",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Smoke Grenades",
		"vehicle_mods": 0,
		"minimum_strength": "d4",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 1119,
		"cost": 125000,
		"name": "Sparrow",
		"type": "Missiles",
		"uuid": "480f211b-0cd3-4c22-911f-b9fc7cc823a5",
		"active": true,
		"weight": 617,
		"book_id": 9,
		"effects": [],
		"summary": "SBT. A medium range, radar-guided missile fired from an aircraft.",
		"tw_cost": 0,
		"bookPage": "79",
		"contains": [],
		"profiles": [
			{
				"ap": 6,
				"rof": 0,
				"name": "",
				"notes": "",
				"range": "150/300/600",
				"reach": 0,
				"shots": 0,
				"damage": "5d8",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "5d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 0
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 20,
		"cost": 100,
		"name": "Spear",
		"type": "Pole Arms",
		"uuid": "3bebc400-9bb7-4af3-922d-4f2de7fb743d",
		"active": true,
		"weight": 5,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "60",
		"contains": [],
		"profiles": [
			{
				"ap": 1,
				"rof": 1,
				"name": "",
				"notes": "",
				"range": "3/6/12",
				"reach": 1,
				"shots": 0,
				"damage": "d6",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 1,
				"damage_original": "d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Spear",
		"vehicle_mods": 0,
		"minimum_strength": "d6",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 198,
		"cost": 100,
		"name": "Spear/Javelin",
		"type": "Medieval Hand Weapons",
		"uuid": "df61e3ab-f4be-4025-9e6d-663d2e1ecd3b",
		"active": true,
		"weight": 3,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "73",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 0,
				"name": "",
				"notes": "+1 Parry if used two-handed",
				"range": "",
				"reach": 1,
				"shots": 0,
				"damage": "d6",
				"toHitMod": 0,
				"is_shield": false,
				"melee_only": true,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 1
			},
			{
				"ap": 0,
				"rof": 0,
				"name": "Two Handed",
				"notes": "",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "d6",
				"toHitMod": 0,
				"is_shield": false,
				"melee_only": true,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 1,
				"damage_original": "d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 0
			},
			{
				"ap": 0,
				"rof": 1,
				"name": "Thrown",
				"notes": "",
				"range": "3/6/12",
				"reach": 0,
				"shots": 1,
				"damage": "d6",
				"toHitMod": 0,
				"is_shield": false,
				"melee_only": false,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 0
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Spears",
		"vehicle_mods": 0,
		"minimum_strength": "d6",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 118,
		"cost": 250,
		"name": "Spencer Carbine (.52)",
		"type": "Rifles",
		"uuid": "4948e9a5-2c14-4f95-a24e-b8d7b6ee3bfb",
		"active": true,
		"weight": 8,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "63",
		"contains": [],
		"profiles": [
			{
				"ap": 2,
				"rof": 1,
				"name": "",
				"notes": "",
				"range": "20/40/80",
				"reach": 0,
				"shots": 7,
				"damage": "2d8",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Spencer Carbines (.52)",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 248,
		"cost": 250,
		"name": "Spencer Carbine (.52)",
		"type": "Rifles (Lever and Bolt-Action)",
		"uuid": "2620bdc3-cdc9-4dda-12d0-bfb652ab1ddf",
		"active": true,
		"weight": 8,
		"book_id": 9,
		"effects": [],
		"save_id": 0,
		"summary": "",
		"tw_cost": 0,
		"bookPage": "75",
		"contains": [],
		"profiles": [
			{
				"ap": 2,
				"rof": 1,
				"name": "",
				"notes": "",
				"range": "20/40/80",
				"reach": 0,
				"shots": 7,
				"damage": "2d8",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"book_page": "",
		"container": false,
		"image_url": "",
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Spencer Carbines (.52)",
		"parent_type": 0,
		"parent_uuid": "",
		"setting_tem": false,
		"setting_item": false,
		"vehicle_mods": 0,
		"image_updated": "2023-04-22T19:33:43.487Z",
		"initial_contents": [],
		"minimum_strength": "d4",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 94,
		"cost": 250,
		"name": "Springfield (.52)",
		"type": "Black Powder",
		"uuid": "6308ee7e-a3a0-4ee5-31b2-6296cf158f3b",
		"active": true,
		"weight": 11,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "62",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 1,
				"name": "",
				"notes": "2 actions to reload",
				"range": "15/30/60",
				"reach": 0,
				"shots": 1,
				"damage": "2d8",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Springfields (.52)",
		"vehicle_mods": 0,
		"minimum_strength": "d6",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 224,
		"cost": 250,
		"name": "Springfield Model 1861",
		"type": "Black Powder Weapons",
		"uuid": "1337f8ad-76e6-430a-8cbf-017043c996d8",
		"active": true,
		"weight": 11,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "73",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 1,
				"name": "",
				"notes": "Reload 2",
				"range": "15/30/60",
				"reach": 0,
				"shots": 1,
				"damage": "2d8",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Springfield Models 1861",
		"vehicle_mods": 0,
		"minimum_strength": "d6",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 19,
		"cost": 10,
		"name": "Staff",
		"type": "Pole Arms",
		"uuid": "b5886934-dc9a-431f-af8f-a809949c2921",
		"active": true,
		"weight": 8,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "60",
		"contains": [],
		"profiles": [
			{
				"ap": 1,
				"rof": 0,
				"name": "",
				"notes": "",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "d4",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": true,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "d4",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Staves",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 199,
		"cost": 10,
		"name": "Staff",
		"type": "Medieval Hand Weapons",
		"uuid": "b7cda441-eaaa-422d-a62c-305a08f29b0e",
		"active": true,
		"weight": 4,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "72",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 0,
				"name": "",
				"notes": "",
				"range": "",
				"reach": 1,
				"shots": 0,
				"damage": "d4",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": true,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 1,
				"damage_original": "d4",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Staves",
		"vehicle_mods": 0,
		"minimum_strength": "d4",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 123,
		"cost": 400,
		"name": "Steyr AUG (5.56)",
		"type": "Assault Rifles",
		"uuid": "53488f8b-305d-40e6-98df-0577252c0b87",
		"active": true,
		"weight": 8,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "63",
		"contains": [],
		"profiles": [
			{
				"ap": 2,
				"rof": 3,
				"name": "",
				"notes": " Auto, 3RB",
				"range": "24/48/96",
				"reach": 0,
				"shots": 30,
				"damage": "2d8",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Steyr AUGs (5.56)",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 252,
		"cost": 400,
		"name": "Steyr AUG (5.56mm)",
		"type": "Rifles (Assault)",
		"uuid": "b0d481c2-a426-42bc-b3d7-73da1406b388",
		"active": true,
		"weight": 8,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "75",
		"contains": [],
		"profiles": [
			{
				"ap": 2,
				"rof": 3,
				"name": "",
				"notes": "",
				"range": "24/48/96",
				"reach": 0,
				"shots": 30,
				"damage": "2d8",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Steyr AUGs (5.56mm)",
		"vehicle_mods": 0,
		"minimum_strength": "d4",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 113,
		"cost": 450,
		"name": "Streetsweeper (12g)",
		"type": "Shotguns",
		"uuid": "f3c8aad7-e6ca-499f-28c9-44ddf3f650c5",
		"active": true,
		"weight": 10,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "63",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 1,
				"name": "",
				"notes": "See text",
				"range": "12/24/48",
				"reach": 0,
				"shots": 12,
				"damage": "1-3d6",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "1-3d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Streetsweepers (12g)",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 239,
		"cost": 450,
		"name": "Streetsweeper Shotgun",
		"type": "Shotguns",
		"uuid": "718e41d5-7e43-4708-8062-ec1315e5db31",
		"active": true,
		"weight": 10,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "74",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 1,
				"name": "",
				"notes": "+2 Shooting, Spread",
				"range": "12/24/48",
				"reach": 0,
				"shots": 12,
				"damage": "1-3d6",
				"toHitMod": 0,
				"is_shield": false,
				"melee_only": false,
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "1-3d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			},
			{
				"ap": 0,
				"rof": 1,
				"name": "Slugs",
				"notes": "No Spread",
				"range": "12/24/48",
				"reach": 0,
				"shots": 12,
				"damage": "2d10",
				"toHitMod": 0,
				"is_shield": false,
				"melee_only": false,
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d10",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 0
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Streetsweeper Shotguns",
		"vehicle_mods": 0,
		"minimum_strength": "d4",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 265,
		"cost": 50,
		"name": "Stun Grenade",
		"type": "Grenades",
		"uuid": "1ab983f2-8e6a-41ec-0c10-2d93f79736ad",
		"active": true,
		"weight": 1,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "78",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 1,
				"name": "",
				"notes": "Targets must make a Vigor roll (at –2 with a raise) or be Stunned (see page 106).",
				"range": "5/10/20",
				"reach": 0,
				"shots": 1,
				"damage": "-",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "-",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Stun Grenades",
		"vehicle_mods": 0,
		"minimum_strength": "d4",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 27,
		"cost": 50,
		"name": "Survival knife",
		"type": "Modern",
		"uuid": "b62c0bd0-bc07-489c-8f2b-5a977ac806b3",
		"active": true,
		"weight": 3,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "60",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 0,
				"name": "",
				"notes": "",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "d4",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": true,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "d4",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 0
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Survival knives",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 207,
		"cost": 50,
		"name": "Survival Knife",
		"type": "Modern Hand Weapons",
		"uuid": "47200021-0ce3-44a3-3a64-906c5c70476f",
		"active": true,
		"weight": 1,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "72",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 0,
				"name": "",
				"notes": "Contains supplies that add +1 to Survival rolls",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "d4",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": true,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "d4",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Survival Knives",
		"vehicle_mods": 0,
		"minimum_strength": "d4",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 26,
		"cost": 10,
		"name": "Switchblade",
		"type": "Modern",
		"uuid": "97501a1b-7ae6-4801-9784-8ee3beb15552",
		"active": true,
		"weight": 1,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "60",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 0,
				"name": "",
				"notes": "",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "d4",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": true,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "d4",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 0
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Switchblades",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 206,
		"cost": 10,
		"name": "Switchblade",
		"type": "Modern Hand Weapons",
		"uuid": "5b37ada8-9b15-4200-afc1-9a0a1a343636",
		"active": true,
		"weight": 0.5,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "72",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 0,
				"name": "",
				"notes": "−2 to be Noticed if hidden",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "d4",
				"toHitMod": 0,
				"is_shield": false,
				"melee_only": true,
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "d4",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Switchblades",
		"vehicle_mods": 0,
		"minimum_strength": "d4",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 189,
		"cost": 400,
		"name": "Sword, Great",
		"type": "Medieval Hand Weapons",
		"uuid": "2b1a2d0e-1cd6-4635-9096-13d751552081",
		"active": true,
		"weight": 6,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "72",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 0,
				"name": "",
				"notes": "",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "d10",
				"toHitMod": 0,
				"is_shield": false,
				"melee_only": true,
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "d10",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Great Swords",
		"vehicle_mods": 0,
		"minimum_strength": "d10",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 193,
		"cost": 300,
		"name": "Sword, Long",
		"type": "Medieval Hand Weapons",
		"uuid": "6805e88a-2f10-41e9-9749-3c4e14dcc358",
		"active": true,
		"weight": 3,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "72",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 0,
				"name": "",
				"notes": "Includes scimitars",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "d8",
				"toHitMod": 0,
				"is_shield": false,
				"melee_only": true,
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Long Swords",
		"vehicle_mods": 0,
		"minimum_strength": "d8",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 197,
		"cost": 100,
		"name": "Sword, Short",
		"type": "Medieval Hand Weapons",
		"uuid": "d8a12b24-8741-4afd-8df6-a0531a8f659a",
		"active": true,
		"weight": 2,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "72",
		"contains": [],
		"profiles": [
			{
				"ap": 0,
				"rof": 0,
				"name": "",
				"notes": "Includes cavalry sabers",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "d6",
				"toHitMod": 0,
				"is_shield": false,
				"melee_only": true,
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Short Swords",
		"vehicle_mods": 0,
		"minimum_strength": "d6",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 105,
		"cost": 350,
		"name": "Tommy Gun (.45)",
		"type": "Submachine Guns",
		"uuid": "e6fefcaf-a7dd-4d7b-a8d9-52894e64188e",
		"active": true,
		"weight": 13,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "62",
		"contains": [],
		"profiles": [
			{
				"ap": 1,
				"rof": 3,
				"name": "",
				"notes": " Auto",
				"range": "12/24/48",
				"reach": 0,
				"shots": 50,
				"damage": "2d6+1",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d6+1",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Tommy Guns (.45)",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 234,
		"cost": 350,
		"name": "Tommy Gun (.45)",
		"type": "Submachine Guns",
		"uuid": "780fb640-9b19-40a8-89f9-596bb00afca5",
		"active": true,
		"weight": 13,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "74",
		"contains": [],
		"profiles": [
			{
				"ap": 1,
				"rof": 3,
				"name": "",
				"notes": "",
				"range": "12/24/48",
				"reach": 0,
				"shots": 20,
				"damage": "2d6+1",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d6+1",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Tommy Guns (.45)",
		"vehicle_mods": 0,
		"minimum_strength": "d4",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 1120,
		"cost": 500000,
		"name": "Torpedo",
		"type": "Torpedoes",
		"uuid": "c693bfb3-610d-456e-945f-5c8bac56caec",
		"active": true,
		"weight": 3000,
		"book_id": 9,
		"effects": [],
		"summary": "LBT",
		"tw_cost": 0,
		"bookPage": "80",
		"contains": [],
		"profiles": [
			{
				"ap": 22,
				"rof": 1,
				"name": "",
				"notes": "",
				"range": "300/600/1200",
				"reach": 0,
				"shots": 0,
				"damage": "8d10",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "8d10",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 0
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 1020,
		"cost": 60000,
		"name": "TOW Missile Launcher",
		"type": "Missiles",
		"uuid": "506ceef5-78ec-4691-93e9-2462a614931d",
		"active": true,
		"weight": 207,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "79",
		"contains": [],
		"profiles": [
			{
				"ap": 34,
				"rof": 1,
				"name": "",
				"notes": "A wire-guided missile fired from a portable or vehicle-mounted launcher. Doesn’t require a lock—just a Shooting roll, and can’t be jammed.",
				"range": "75/150/300",
				"reach": 0,
				"shots": 0,
				"damage": "5d10",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "5d10",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 0
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 1112,
		"cost": 50000,
		"name": "Trebuchet",
		"type": "Catapults",
		"uuid": "cc7cb343-2acb-4c69-a3aa-80c6097a3217",
		"active": true,
		"weight": 0,
		"book_id": 9,
		"effects": [],
		"summary": "Reload time is every 5 minutes with a crew of 4.",
		"tw_cost": 0,
		"bookPage": "77",
		"contains": [],
		"profiles": [
			{
				"ap": 4,
				"rof": 0,
				"name": "",
				"notes": "",
				"range": "30/60/120",
				"reach": 0,
				"shots": 0,
				"damage": "3d8",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "3d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 0
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 106,
		"cost": 300,
		"name": "Uzi (9mm)",
		"type": "Submachine Guns",
		"uuid": "bb9e2ee7-eac4-45f7-9346-a29233068389",
		"active": true,
		"weight": 9,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "62",
		"contains": [],
		"profiles": [
			{
				"ap": 1,
				"rof": 3,
				"name": "",
				"notes": " Auto",
				"range": "12/24/48",
				"reach": 0,
				"shots": 32,
				"damage": "2d6",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Uzis (9mm)",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 235,
		"cost": 300,
		"name": "Uzi (9mm)",
		"type": "Submachine Guns",
		"uuid": "b27c47c2-9fc6-4b4d-3409-8acc4b52da9f",
		"active": true,
		"weight": 9,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "74",
		"contains": [],
		"profiles": [
			{
				"ap": 1,
				"rof": 3,
				"name": "",
				"notes": "",
				"range": "12/24/48",
				"reach": 0,
				"shots": 32,
				"damage": "2d6",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Uzis (9mm)",
		"vehicle_mods": 0,
		"minimum_strength": "d4",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 16,
		"cost": 250,
		"name": "Warhammer",
		"type": "Axes and Mauls",
		"uuid": "5c211772-bbab-419b-2b76-f9249feba653",
		"active": true,
		"weight": 8,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "60",
		"contains": [],
		"profiles": [
			{
				"ap": 1,
				"rof": 0,
				"name": "",
				"notes": "",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "d6",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": true,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Warhammers",
		"vehicle_mods": 0,
		"minimum_strength": "d6",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 200,
		"cost": 250,
		"name": "Warhammer",
		"type": "Medieval Hand Weapons",
		"uuid": "551f379a-e43e-47e8-aebb-73d50d760177",
		"active": true,
		"weight": 2,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "72",
		"contains": [],
		"profiles": [
			{
				"ap": 1,
				"rof": 0,
				"name": "",
				"notes": "",
				"range": "",
				"reach": 0,
				"shots": 0,
				"damage": "d6",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": true,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "d6",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": true,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Warhammers",
		"vehicle_mods": 0,
		"minimum_strength": "d6",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 249,
		"cost": 300,
		"name": "Winchester ‘73 (.45)",
		"type": "Rifles (Lever and Bolt-Action)",
		"uuid": "a085f045-b5c7-4104-2fd3-2a865e45e50d",
		"active": true,
		"weight": 10,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "75",
		"contains": [],
		"profiles": [
			{
				"ap": 2,
				"rof": 1,
				"name": "",
				"notes": "",
				"range": "24/48/96",
				"reach": 0,
				"shots": 15,
				"damage": "2d8-1",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d8-1",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Winchester ‘73s (.45)",
		"vehicle_mods": 0,
		"minimum_strength": "d6",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 119,
		"cost": 300,
		"name": "Winchester ‘76 (.45-.47)",
		"type": "Rifles",
		"uuid": "4550ab51-714f-40f3-896b-e5b963f46ccc",
		"active": true,
		"weight": 10,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"tw_cost": 0,
		"bookPage": "63",
		"contains": [],
		"profiles": [
			{
				"ap": 2,
				"rof": 1,
				"name": "",
				"notes": "",
				"range": "24/48/96",
				"reach": 0,
				"shots": 15,
				"damage": "2d8",
				"toHitMod": 0,
				"vtt_only": false,
				"is_shield": false,
				"skillName": "",
				"melee_only": false,
				"skillValue": "",
				"currentShots": 0,
				"heavy_weapon": false,
				"thrown_weapon": false,
				"damageDiceBase": "",
				"parry_modifier": 0,
				"damage_original": "2d8",
				"usable_in_melee": false,
				"counts_as_innate": false,
				"requires_2_hands": false,
				"damageDiceBasePlus": 0,
				"damageWithBrackets": "",
				"add_strength_to_damage": false,
				"ap_vs_rigid_armor_only": 1
			}
		],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"tw_effects": "",
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"extra_notes": "",
		"name_plural": "Winchester ‘76s (.45-.47)",
		"vehicle_mods": 0,
		"minimum_strength": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	}
];

export default Weapons;