import React from 'react';
import Language from "../Language/Language";
import Spinner from "../UI/Burnblade/Spinner";
import CharacterData from "./CharacterData";
import {CheckboxLabel} from "../UI/Burnblade/Checkbox";
import HorizontalRule from "../UI/Burnblade/HorizontalRule";
import ListItemGroupModule from "./ListItemGroupModule";
import TabBookHorizontal from "../UI/Burnblade/TabBookHorizontal";
import Armor from "../Savaged/Armor";
import Weapons from "../Savaged/Weapons";
import Gear from "../Savaged/Gear";
import CharacterStatusData from "./CharacterStatusData";

function CharacterModuleStatus({character, armorItemIds, setArmorItemIds, weaponItemIds, setWeaponItemIds, gearItemIds, setGearItemIds}) {

	// input
	const language = Language.getCurrent();
	const characterStatus = CharacterData.getCharacterStatus(character);

	// display
	return (
		<div style={{textAlign:"center", marginTop:"1em"}}>

			{/* Bennies */}
			<Spinner
				step={1}
				min={0}
				max={10}
				initialValue={CharacterStatusData.getCharacterBennies(characterStatus)}
				text={language.Bennies}
				name="bennies"
			/>

			{/* Wounds */}
			<Spinner
				step={1}
				min={0}
				max={5}
				initialValue={CharacterStatusData.getCharacterWounds(characterStatus)}
				text={language.Wounds}
				name="wounds"
			/>

			{/* Fatigue */}
			<Spinner
				step={1}
				min={0}
				max={5}
				initialValue={CharacterStatusData.getCharacterFatigue(characterStatus)}
				text={language.Fatigue}
				name="fatigue"
			/>
			<br />

			{/* Status */}
			<CheckboxLabel name="isShaken" defaultChecked={CharacterStatusData.getCharacterIsShaken(characterStatus)}>{language.Shaken}</CheckboxLabel>
			<CheckboxLabel name="isIncapacitated" defaultChecked={CharacterStatusData.getCharacterIsIncapacitated(characterStatus)}>{language.Incapacitated}</CheckboxLabel>
			<CheckboxLabel name="isStunned" defaultChecked={CharacterStatusData.getCharacterIsStunned(characterStatus)}>{language.Stunned}</CheckboxLabel>
			<CheckboxLabel name="isBound" defaultChecked={CharacterStatusData.getCharacterIsBound(characterStatus)}>{language.Bound}</CheckboxLabel>
			<br />

			<HorizontalRule />

			{/* Inventory */}
			<TabBookHorizontal
				initialTabIndex={0}
				hiddenPages={true}
				tabLabels={[
					language.Weapons,
					language.Armor,
					language.Gear
				]}
				tabPages={[

					// Weapons
					<ListItemGroupModule
						allItems={Weapons}
						title={language.Weapons}
						getItemId={item => item.id}
						getItemName={item => item.name}
						getItemSummary={item => {
							const stats = [];
							const profile = item.profiles[0];
							if (profile)
							{
								if (profile.range) stats.push(`${language.Range} ${profile.range}`);
								if (profile.damage) stats.push(`${language.Damage} ${profile.add_strength_to_damage?`${language.StrengthAbbr}+`:""}${profile.damage}`);
								if (profile.rof) stats.push(`${language.RateOfFireAbbr} ${profile.rof}`);
								if (profile.ap) stats.push(`${language.ArmorPiercingAbbr} ${profile.ap}`);
								if (profile.reach) stats.push(`${language.Reach} ${profile.reach}`);
								if (profile.notes) stats.push(profile.notes);
							}
							return stats.length ? stats.join(", ") : item.summary;
						}}
						currentItemIds={weaponItemIds}
						setCurrentItemIds={setWeaponItemIds}
					/>,

					// Armor
					<ListItemGroupModule
						allItems={Armor}
						title={language.Armor}
						getItemId={item => item.id}
						getItemName={item => {
							const stats = [];
							if (item.armor_value) stats.push(`+${item.armor_value}`);
							if (item.shield_parry_bonus) stats.push(`+${item.shield_parry_bonus} ${language.to_Parry}`);
							return `${item.name}${stats.length?` (${stats.join(", ")})` : ""}`;
						}}
						currentItemIds={armorItemIds}
						setCurrentItemIds={setArmorItemIds}
					/>,

					// Gear
					<ListItemGroupModule
						allItems={Gear}
						title={language.Gear}
						getItemId={item => item.id}
						getItemName={item => item.name}
						currentItemIds={gearItemIds}
						setCurrentItemIds={setGearItemIds}
					/>
				]}
			/>
		</div>
	);
}

export default CharacterModuleStatus;