const Hindrances = [
	{
		"id": 1,
		"name": "All Thumbs",
		"type": "(unknown)",
		"uuid": "d61df000-4f69-4c2a-2b02-e91174042e91",
		"major": false,
		"active": true,
		"book_id": 2,
		"effects": [
			"skillbonus: Repair -2"
		],
		"removed": false,
		"specify": "",
		"summary": "–2 Repair; Roll of 1 causes malfunction",
		"bookPage": "p29",
		"base_name": "All Thumbs",
		"conflicts": [],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 103,
		"name": "All Thumbs",
		"type": "(unknown)",
		"uuid": "3fa08a17-880c-4eb0-3a26-04933779083c",
		"major": false,
		"active": true,
		"book_id": 9,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "–2 to use mechanical or electrical devices",
		"bookPage": "p22",
		"base_name": "All Thumbs",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 2,
		"name": "Anemic",
		"type": "(unknown)",
		"uuid": "ffcb9543-10e3-4539-24c8-e5d4e0f966be",
		"major": false,
		"active": true,
		"book_id": 2,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "–2 to Fatigue tests",
		"bookPage": "p29",
		"base_name": "Anemic",
		"conflicts": [],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 762,
		"name": "Anemic",
		"type": "(unknown)",
		"uuid": "92cd5bed-0c81-4250-b607-89082b553095",
		"major": false,
		"active": true,
		"book_id": 9,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "–2 Vigor when resisting Fatigue",
		"bookPage": "p22",
		"base_name": "Anemic",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 3,
		"name": "Arrogant",
		"type": "(unknown)",
		"uuid": "12ed6421-3c60-469b-9f93-dbf9c14255e8",
		"major": true,
		"active": true,
		"book_id": 2,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "Must humiliate opponent, challenge the ‘leader’",
		"bookPage": "p29",
		"base_name": "Arrogant",
		"conflicts": [],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 105,
		"name": "Arrogant",
		"type": "(unknown)",
		"uuid": "31569fd1-239e-442c-2b86-966e21ee77f0",
		"major": true,
		"active": true,
		"book_id": 9,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "Likes to dominate his opponent, challenge the most powerful foe in combat.",
		"bookPage": "p22",
		"base_name": "Arrogant",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 764,
		"name": "Bad Eyes",
		"type": "(unknown)",
		"uuid": "8b8c585e-e763-4867-38bf-646807f3fcde",
		"major": false,
		"active": true,
		"book_id": 9,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "Without glasses, suffers a −2 penalty to any Trait roll dependent on vision",
		"bookPage": "p22",
		"base_name": "Bad Eyes",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "Without glasses, suffers a -1 penalty to any Trait roll dependent on vision",
		"minor_or_major": true,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": true,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 4,
		"name": "Bad Eyes (major)",
		"type": "(unknown)",
		"uuid": "3d1bc167-f522-4bcb-926f-36312d6655d4",
		"major": true,
		"active": true,
		"book_id": 2,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "–2 to attack or notice something more than 5” distant",
		"bookPage": "p29",
		"base_name": "Bad Eyes",
		"conflicts": [],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": true,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 5,
		"name": "Bad Eyes (minor)",
		"type": "(unknown)",
		"uuid": "29f5c8cf-5679-4d85-88f8-018bd99a1b2d",
		"major": false,
		"active": true,
		"book_id": 2,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "–2 to attack or notice something more than 5” distant",
		"bookPage": "p29",
		"base_name": "Bad Eyes",
		"conflicts": [],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": true,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 6,
		"name": "Bad Luck",
		"type": "(unknown)",
		"uuid": "8f168a99-327f-4e92-851f-120c660bcb37",
		"major": true,
		"active": true,
		"book_id": 2,
		"effects": [
			"-1 benny"
		],
		"removed": false,
		"specify": "",
		"summary": "One less Benny per session",
		"bookPage": "p29",
		"base_name": "Bad Luck",
		"conflicts": [
			"edge:luck",
			"edge:great luck"
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 106,
		"name": "Bad Luck",
		"type": "(unknown)",
		"uuid": "2b74404f-ddab-4ebb-340a-9a441bbd89b4",
		"major": true,
		"active": true,
		"book_id": 9,
		"effects": [
			"-1 Benny"
		],
		"removed": false,
		"specify": "",
		"summary": "The characters starts with one less Benny per session.",
		"bookPage": "p22",
		"base_name": "Bad Luck",
		"conflicts": [
			"edge:Luck"
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 7,
		"name": "Big Mouth",
		"type": "(unknown)",
		"uuid": "200d8dab-f6c4-4e27-98ef-506601138bb0",
		"major": false,
		"active": true,
		"book_id": 2,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "Unable to keep a secret, blabs at the worst time",
		"bookPage": "p30",
		"base_name": "Big Mouth",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 766,
		"name": "Big Mouth",
		"type": "(unknown)",
		"uuid": "de401717-cb44-439d-821a-0aec5542ae79",
		"major": false,
		"active": true,
		"book_id": 9,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "Unable to keep secrets and constantly gives away private information",
		"bookPage": "p23",
		"base_name": "Big Mouth",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 9,
		"name": "Blind",
		"type": "(unknown)",
		"uuid": "506846a9-a888-43f5-9618-8b53cd6da4ba",
		"major": true,
		"active": true,
		"book_id": 2,
		"effects": [
			"+1 edge"
		],
		"removed": false,
		"specify": "",
		"summary": "–6 to all actions that rely on vision; –2 on social rolls, gain additional Edge",
		"bookPage": "p30",
		"base_name": "Blind",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 108,
		"name": "Blind",
		"type": "(unknown)",
		"uuid": "a71ecafe-6dc9-45ca-1e7c-2193949d60f1",
		"major": true,
		"active": true,
		"book_id": 9,
		"effects": [
			"+1 Edge"
		],
		"removed": false,
		"specify": "",
		"summary": "–6 to all tasks that require vision (but choice of a free Edge to offset)",
		"bookPage": "p23",
		"base_name": "Blind",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 10,
		"name": "Bloodthirsty",
		"type": "(unknown)",
		"uuid": "37962150-82a2-4890-baeb-492b08130a53",
		"major": true,
		"active": true,
		"book_id": 2,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "Never takes prisoners",
		"bookPage": "p30",
		"base_name": "Bloodthirsty",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 109,
		"name": "Bloodthirsty",
		"type": "(unknown)",
		"uuid": "cac36c15-138b-4c1a-88a4-0d2e520c78f8",
		"major": true,
		"active": true,
		"book_id": 9,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "Never takes prisoners",
		"bookPage": "p23",
		"base_name": "Bloodthirsty",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 110,
		"name": "Can’t Swim",
		"type": "(unknown)",
		"uuid": "99e05452-1eca-4acb-3d08-3747cae2aa2c",
		"major": false,
		"active": true,
		"book_id": 9,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "-2 to swimming (Athletics) rolls; Each inch moved in water costs 3” of Pace",
		"bookPage": "p23",
		"base_name": "Can’t Swim",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 8,
		"name": "Cautious",
		"type": "(unknown)",
		"uuid": "b62a8ed8-7dff-41c5-889a-f84069fed0b0",
		"major": false,
		"active": true,
		"book_id": 2,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "Character is overly careful",
		"bookPage": "p30",
		"base_name": "Cautious",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 111,
		"name": "Cautious",
		"type": "(unknown)",
		"uuid": "575a6119-d71f-4cf8-8e2c-d7ed29ac1e38",
		"major": false,
		"active": true,
		"book_id": 9,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "The character plans extensively and/or is overly careful",
		"bookPage": "p23",
		"base_name": "Cautious",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 11,
		"name": "Clueless",
		"type": "(unknown)",
		"uuid": "161ae5b2-f6ed-4f9c-b99b-f898a746fff5",
		"major": true,
		"active": true,
		"book_id": 2,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "–2 to most Common Knowledge rolls",
		"bookPage": "p30",
		"base_name": "Clueless",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 771,
		"name": "Clueless",
		"type": "(unknown)",
		"uuid": "c8295cd0-6f8b-4a1d-0a65-08186df779d5",
		"major": true,
		"active": true,
		"book_id": 9,
		"effects": [
			"skillbonus:Common Knowledge -1",
			"skillbonus:Notice -1"
		],
		"removed": false,
		"specify": "",
		"summary": "-1 to Common Knowledge and Notice rolls",
		"bookPage": "p23",
		"base_name": "Clueless",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 113,
		"name": "Clumsy",
		"type": "(unknown)",
		"uuid": "7e71bb62-5284-4e73-b6f2-e99ae824f5b0",
		"major": true,
		"active": true,
		"book_id": 9,
		"effects": [
			"skillbonus:Athletics -2",
			"skillbonus:Stealth -2"
		],
		"removed": false,
		"specify": "",
		"summary": "–2 to Athletics and Stealth rolls",
		"bookPage": "p23",
		"base_name": "Clumsy",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 12,
		"name": "Code of Honor",
		"type": "(unknown)",
		"uuid": "77219272-6656-48b1-34ef-de8a3ed4ff7b",
		"major": true,
		"active": true,
		"book_id": 2,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "Character keeps his word and acts like a gentleman",
		"bookPage": "p30",
		"base_name": "Code of Honor",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 114,
		"name": "Code of Honor",
		"type": "(unknown)",
		"uuid": "753ba483-47fc-4f76-36b8-c39fa3756559",
		"major": true,
		"active": true,
		"book_id": 9,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "The character keeps his word and acts like a gentleman",
		"bookPage": "p23",
		"base_name": "Code of Honor",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 13,
		"name": "Curious",
		"type": "(unknown)",
		"uuid": "62576fba-939c-4a05-247d-e86bd86547f9",
		"major": true,
		"active": true,
		"book_id": 2,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "Character wants to know about everything",
		"bookPage": "p30",
		"base_name": "Curious",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 115,
		"name": "Curious",
		"type": "(unknown)",
		"uuid": "a8c64240-19b1-4657-a21d-f8a2e7cfde40",
		"major": true,
		"active": true,
		"book_id": 9,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "The character wants to know about everything",
		"bookPage": "p23",
		"base_name": "Curious",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 14,
		"name": "Death Wish",
		"type": "(unknown)",
		"uuid": "26efcc48-d97b-44e0-367b-be242459af05",
		"major": false,
		"active": true,
		"book_id": 2,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "Hero wants to die after completing some task",
		"bookPage": "p30",
		"base_name": "Death Wish",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": true,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 116,
		"name": "Death Wish",
		"type": "(unknown)",
		"uuid": "647f83ad-f002-4525-b5aa-e891abab0d7d",
		"major": false,
		"active": true,
		"book_id": 9,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "The hero wants to die after or while completing some epic task",
		"bookPage": "p23",
		"base_name": "Death Wish",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 117,
		"name": "Delusional",
		"type": "(unknown)",
		"uuid": "d70aef1c-5f00-473c-2363-1bc39a1cfb1a",
		"major": false,
		"active": true,
		"book_id": 9,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "The individual believes something strange that causes him occasional or frequent trouble",
		"bookPage": "p23",
		"base_name": "Delusional",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "The individual believes something strange that causes him occasional or frequent trouble",
		"minor_or_major": true,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": true,
		"always_show_long_name": true,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 15,
		"name": "Delusional (major)",
		"type": "(unknown)",
		"uuid": "570da73b-3acd-47b6-2e75-479fe87e1504",
		"major": true,
		"active": true,
		"book_id": 2,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "Character suffers from grave delusions",
		"bookPage": "p30",
		"base_name": "Delusional",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": true,
		"always_show_long_name": true,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 16,
		"name": "Delusional (minor)",
		"type": "(unknown)",
		"uuid": "0b97deb8-80a5-475f-9f09-4674c7650cab",
		"major": false,
		"active": true,
		"book_id": 2,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "Character suffers from grave delusions",
		"bookPage": "p30",
		"base_name": "Delusional",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": true,
		"always_show_long_name": true,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 17,
		"name": "Doubting Thomas",
		"type": "(unknown)",
		"uuid": "7c3c6edf-4e77-4acb-2b44-5e67980d19c6",
		"major": false,
		"active": true,
		"book_id": 2,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "Character doesn’t believe in the supernatural",
		"bookPage": "p31",
		"base_name": "Doubting Thomas",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 118,
		"name": "Doubting Thomas",
		"type": "(unknown)",
		"uuid": "a3a44e49-01d0-49b1-3df9-8d861fa727aa",
		"major": false,
		"active": true,
		"book_id": 9,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "The character doesn’t believe in the supernatural, often exposing him to unnecessary risks.",
		"bookPage": "p24",
		"base_name": "Doubting Thomas",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 119,
		"name": "Driven",
		"type": "(unknown)",
		"uuid": "4c78bfbe-0d1f-4714-33ed-a9f5107b95de",
		"major": false,
		"active": true,
		"book_id": 9,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "The hero’s actions are driven by some important goal or belief",
		"bookPage": "p24",
		"base_name": "Driven",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "The hero’s actions are driven by some important goal or belief",
		"minor_or_major": true,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": true,
		"always_show_long_name": true,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 18,
		"name": "Elderly",
		"type": "(unknown)",
		"uuid": "f0e1c248-8fcf-42e9-a9e0-a37021f9f8f7",
		"major": true,
		"active": true,
		"book_id": 2,
		"effects": [
			"pace -1",
			"-1 strength",
			"-1 vigor",
			"noraise: strength",
			"noraise: vigor",
			"+5 smartsskillpoints"
		],
		"removed": false,
		"specify": "",
		"summary": "Pace –1, –1 to Strength and Vigor die types; +5 skill points for any skill linked to Smarts",
		"bookPage": "p31",
		"base_name": "Elderly",
		"conflicts": [
			"young"
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 120,
		"name": "Elderly",
		"type": "(unknown)",
		"uuid": "a127c345-484e-468c-3a35-e3aca3b77ef6",
		"major": true,
		"active": true,
		"book_id": 9,
		"effects": [
			"-1 pace",
			"attribute_bonus:strength -1",
			"attribute_bonus:vigor -1",
			"attribute_bonus:agility -1",
			"+5 smartsskillpoints"
		],
		"removed": false,
		"specify": "",
		"summary": "Pace –1, Agility, Strength, and Vigor rolls –1, but hero gets 5 extra skill points.",
		"bookPage": "p24",
		"base_name": "Elderly",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 121,
		"name": "Enemy",
		"type": "(unknown)",
		"uuid": "93fe39bc-7d80-4d86-a246-16aec62deaee",
		"major": false,
		"active": true,
		"book_id": 9,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "The character has a recurring nemesis",
		"bookPage": "p24",
		"base_name": "Enemy",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "The character has a recurring nemesis",
		"minor_or_major": true,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": true,
		"always_show_long_name": true,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 19,
		"name": "Enemy (major)",
		"type": "(unknown)",
		"uuid": "db093b2c-7bb1-48b2-0f94-9157f9bf6913",
		"major": true,
		"active": true,
		"book_id": 2,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "Character has a recurring nemesis of some sort",
		"bookPage": "p31",
		"base_name": "Enemy",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": true,
		"always_show_long_name": true,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 20,
		"name": "Enemy (minor)",
		"type": "(unknown)",
		"uuid": "2af28262-f675-41da-908c-d4afe04562dd",
		"major": false,
		"active": true,
		"book_id": 2,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "Character has a recurring nemesis of some sort",
		"bookPage": "p31",
		"base_name": "Enemy",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": true,
		"always_show_long_name": true,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 122,
		"name": "Greedy",
		"type": "(unknown)",
		"uuid": "e831700e-2bc6-4976-3d23-d1dd9d89798a",
		"major": false,
		"active": true,
		"book_id": 9,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "The individual is obsessed with wealth and material possessions",
		"bookPage": "p24",
		"base_name": "Greedy",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "The individual is obsessed with wealth and material possessions",
		"minor_or_major": true,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": true,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 123,
		"name": "Habit",
		"type": "(unknown)",
		"uuid": "de5db544-db39-4432-10ce-28489ed6cc9f",
		"major": false,
		"active": true,
		"book_id": 9,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "Addicted to something, suffers Fatigue if deprived",
		"bookPage": "p24",
		"base_name": "Habit",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "Addicted to something, suffers Fatigue if deprived",
		"minor_or_major": true,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": true,
		"always_show_long_name": true,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 22,
		"name": "Habit (major)",
		"type": "(unknown)",
		"uuid": "47ac3b78-fc00-434c-2d88-f2fac7e4187e",
		"major": true,
		"active": true,
		"book_id": 2,
		"effects": [
			"charisma -1"
		],
		"removed": false,
		"specify": "",
		"summary": "Charisma –1; Fatigue rolls when deprived of Major Habits",
		"bookPage": "p31",
		"base_name": "Habit",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": true,
		"always_show_long_name": true,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 21,
		"name": "Habit (minor)",
		"type": "(unknown)",
		"uuid": "14328576-deda-413e-2cda-2c834612bb0b",
		"major": false,
		"active": true,
		"book_id": 2,
		"effects": [
			"-1 charisma"
		],
		"removed": false,
		"specify": "",
		"summary": "Charisma –1;",
		"bookPage": "p31",
		"base_name": "Habit",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": true,
		"always_show_long_name": true,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 124,
		"name": "Hard of Hearing",
		"type": "(unknown)",
		"uuid": "7c5c26e2-b8e8-4b92-bc74-dec0419c0d38",
		"major": false,
		"active": true,
		"book_id": 9,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "Automatic failure to notice sounds",
		"bookPage": "p24",
		"base_name": "Hard of Hearing",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "–2 to Notice sounds",
		"minor_or_major": true,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": true,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 23,
		"name": "Hard of Hearing (major)",
		"type": "(unknown)",
		"uuid": "b7c2a8e4-d001-45ec-9b87-2930cad6b615",
		"major": true,
		"active": true,
		"book_id": 2,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "automatic failure to notice sounds",
		"bookPage": "p31",
		"base_name": "Hard of Hearing",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": true,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 24,
		"name": "Hard of Hearing (minor)",
		"type": "(unknown)",
		"uuid": "8659950b-d22d-4bc0-2f84-e6b597b4f532",
		"major": false,
		"active": true,
		"book_id": 2,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "–2 to Notice sounds",
		"bookPage": "p31",
		"base_name": "Hard of Hearing",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": true,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 25,
		"name": "Heroic",
		"type": "(unknown)",
		"uuid": "44550f29-39e5-45ff-9973-c19387c686be",
		"major": true,
		"active": true,
		"book_id": 2,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "Character always helps those in need",
		"bookPage": "p31",
		"base_name": "Heroic",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 125,
		"name": "Heroic",
		"type": "(unknown)",
		"uuid": "e9ef2b57-2b6e-40bc-9768-2d637ae4d252",
		"major": true,
		"active": true,
		"book_id": 9,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "The character always helps those in need",
		"bookPage": "p24",
		"base_name": "Heroic",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 126,
		"name": "Hesitant",
		"type": "(unknown)",
		"uuid": "611b9ac7-2354-48b0-9556-bd73111cafcb",
		"major": false,
		"active": true,
		"book_id": 9,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "Draw two Action Cards and take the lowest (except Jokers, which may be kept)",
		"bookPage": "p25",
		"base_name": "Hesitant",
		"conflicts": [
			"edge: Level Headed"
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 26,
		"name": "Illiterate",
		"type": "(unknown)",
		"uuid": "3003118d-6d69-4836-33b9-9a5b519c551b",
		"major": false,
		"active": true,
		"book_id": 2,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "Hero is unable to read or write",
		"bookPage": "p31",
		"base_name": "Illiterate",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 127,
		"name": "Illiterate",
		"type": "(unknown)",
		"uuid": "f9d3f329-ffa7-47af-8fa5-5bd3d5d371d9",
		"major": false,
		"active": true,
		"book_id": 9,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "The character cannot read or write",
		"bookPage": "p25",
		"base_name": "Illiterate",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 128,
		"name": "Impulsive",
		"type": "(unknown)",
		"uuid": "6be7b156-72bf-49c5-a32a-045890a8c1be",
		"major": true,
		"active": true,
		"book_id": 9,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "The hero leaps before he looks",
		"bookPage": "p25",
		"base_name": "Impulsive",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 129,
		"name": "Jealous",
		"type": "(unknown)",
		"uuid": "4ce64731-48f0-416e-39ee-c7de0425369e",
		"major": false,
		"active": true,
		"book_id": 9,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "The individual covets what others have",
		"bookPage": "p25",
		"base_name": "Jealous",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "The individual covets what others have",
		"minor_or_major": true,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": true,
		"always_show_long_name": true,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 27,
		"name": "Lame",
		"type": "(unknown)",
		"uuid": "f3956fa2-16d2-495b-af6f-8e1ef176ebda",
		"major": true,
		"active": true,
		"book_id": 2,
		"effects": [
			"-2 pace",
			"runningdie: d4"
		],
		"removed": false,
		"specify": "",
		"summary": "–2 Pace and running die is a d4",
		"bookPage": "p31",
		"base_name": "Lame",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 28,
		"name": "Loyal",
		"type": "(unknown)",
		"uuid": "d380df03-73a2-4e1a-16ac-37ba2fa18203",
		"major": false,
		"active": true,
		"book_id": 2,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "The hero tries to never betray or disappoint his friends",
		"bookPage": "p31",
		"base_name": "Loyal",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 130,
		"name": "Loyal",
		"type": "(unknown)",
		"uuid": "cb085262-1e0d-4926-2171-07ba32e3e9a1",
		"major": false,
		"active": true,
		"book_id": 9,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "The hero is loyal to his friends and allies",
		"bookPage": "p25",
		"base_name": "Loyal",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 29,
		"name": "Mean",
		"type": "(unknown)",
		"uuid": "2ca6761e-df98-417b-8746-bb37b0b6d7c4",
		"major": false,
		"active": true,
		"book_id": 2,
		"effects": [
			"-2 charisma"
		],
		"removed": false,
		"specify": "",
		"summary": "–2 to his Charisma for ill-temper and surliness",
		"bookPage": "p31",
		"base_name": "Mean",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 131,
		"name": "Mean",
		"type": "(unknown)",
		"uuid": "699ad183-12a3-40fd-0c4e-e956ec2bfbc3",
		"major": false,
		"active": true,
		"book_id": 9,
		"effects": [
			"skillbonus:Persuasion -1"
		],
		"removed": false,
		"specify": "",
		"summary": "–1 to Persuasion rolls",
		"bookPage": "p25",
		"base_name": "Mean",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 132,
		"name": "Mild Mannered",
		"type": "(unknown)",
		"uuid": "e2d25152-2fec-4d2c-a463-789e615b8957",
		"major": false,
		"active": true,
		"book_id": 9,
		"effects": [
			"skill_bonus:Intimidation -2"
		],
		"removed": false,
		"specify": "",
		"summary": "-2 to Intimidation rolls",
		"bookPage": "p25",
		"base_name": "Mild Mannered",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 133,
		"name": "Mute",
		"type": "(unknown)",
		"uuid": "2995a019-db1f-47c7-0e2a-e3441ff91611",
		"major": true,
		"active": true,
		"book_id": 9,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "The hero cannot speak and subtracts 2 from rolls that require audible communication",
		"bookPage": "p25",
		"base_name": "Mute",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 30,
		"name": "Obese",
		"type": "(unknown)",
		"uuid": "214385cd-13c8-4659-91a0-7e92bfdb7db4",
		"major": false,
		"active": true,
		"book_id": 2,
		"effects": [
			"+1 toughness",
			"-1 pace",
			"runningdie: d4"
		],
		"removed": false,
		"specify": "",
		"summary": "+1 Toughness, –1 Pace, d4 running die",
		"bookPage": "p32",
		"base_name": "Obese",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 134,
		"name": "Obese",
		"type": "(unknown)",
		"uuid": "5852e902-3e80-4349-0746-833f65ec8ff1",
		"major": false,
		"active": true,
		"book_id": 9,
		"effects": [
			"size +1",
			"pace -1",
			"running_die: d4",
			"armor_strength -1"
		],
		"removed": false,
		"specify": "",
		"summary": "Size +1, Pace –1 and running die of d4.",
		"bookPage": "p25",
		"base_name": "Obese",
		"conflicts": [
			"edge:Brawny"
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 135,
		"name": "Obligation",
		"type": "(unknown)",
		"uuid": "8bb44a12-cdbe-4f93-04c6-01ddca6a7abe",
		"major": false,
		"active": true,
		"book_id": 9,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "The character has a weekly obligation of 40+ hours",
		"bookPage": "p25",
		"base_name": "Obligation",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "The character has a weekly obligation of 20 hours",
		"minor_or_major": true,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": true,
		"always_show_long_name": true,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 31,
		"name": "One Arm",
		"type": "(unknown)",
		"uuid": "611c8e81-dd8b-49c2-192e-7fa41b02dfcf",
		"major": true,
		"active": true,
		"book_id": 2,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "–4 to tasks requiring two arms",
		"bookPage": "p32",
		"base_name": "One Arm",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 848,
		"name": "One Arm",
		"type": "(unknown)",
		"uuid": "5a277774-9203-4d10-082b-a94edd3a7fba",
		"major": true,
		"active": true,
		"book_id": 9,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "-4 to tasks (such as Athletics) that require two hands.",
		"bookPage": "p26",
		"base_name": "One Arm",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": true,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 32,
		"name": "One Eye",
		"type": "(unknown)",
		"uuid": "73bb717a-e379-4e77-bb97-db8e22cbca0f",
		"major": true,
		"active": true,
		"book_id": 2,
		"effects": [
			"-1 charisma"
		],
		"removed": false,
		"specify": "",
		"summary": "–1 Charisma, –2 to rolls requiring depth perception",
		"bookPage": "p32",
		"base_name": "One Eye",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 795,
		"name": "One Eye",
		"type": "(unknown)",
		"uuid": "1d6cc322-935f-490a-92f7-26b9d3114cec",
		"major": true,
		"active": true,
		"book_id": 9,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "-2 to actions at 5\" (10 yards) or more distance",
		"bookPage": "p26",
		"base_name": "One Eye",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 33,
		"name": "One Leg",
		"type": "(unknown)",
		"uuid": "069830fd-037d-4265-8a52-cbfe43630b40",
		"major": true,
		"active": true,
		"book_id": 2,
		"effects": [
			"pace -2",
			"runningdie: d4",
			"skillbonus: swimming -2"
		],
		"removed": false,
		"specify": "",
		"summary": "Pace –2, d4 running die, –2 to rolls requiring mobility, –2 to Swimming skill",
		"bookPage": "p32",
		"base_name": "One Leg",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 34,
		"name": "Outsider",
		"type": "(unknown)",
		"uuid": "36413d5c-3f34-4f0c-8c7b-c6f7bbc86da1",
		"major": false,
		"active": true,
		"book_id": 2,
		"effects": [
			"charisma -2"
		],
		"removed": false,
		"specify": "",
		"summary": "–2 Charisma, treated badly by those of dominant society",
		"bookPage": "p32",
		"base_name": "Outsider",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 138,
		"name": "Outsider",
		"type": "(unknown)",
		"uuid": "6ee50535-28c9-4ee3-0149-fda19e694eb5",
		"major": false,
		"active": true,
		"book_id": 9,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "The character doesn’t fit in to the local environment and subtracts 2 from Persuasion rolls. As a Major Hindrance she has no legal rights or other serious consequences.",
		"bookPage": "p26",
		"base_name": "Outsider",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "The character doesn’t fit in to the local environment and subtracts 2 from Persuasion rolls.",
		"minor_or_major": true,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": true,
		"always_show_long_name": true,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 35,
		"name": "Overconfident",
		"type": "(unknown)",
		"uuid": "cae9a6cb-05c8-477f-a417-413ee8ed64be",
		"major": true,
		"active": true,
		"book_id": 2,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "The hero believes he can do anything",
		"bookPage": "p32",
		"base_name": "Overconfident",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 139,
		"name": "Overconfident",
		"type": "(unknown)",
		"uuid": "ab4f028f-51ce-4c8f-bb9b-77e20e276f36",
		"major": true,
		"active": true,
		"book_id": 9,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "The hero believes she can do anything.",
		"bookPage": "p26",
		"base_name": "Overconfident",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 140,
		"name": "Pacifist",
		"type": "(unknown)",
		"uuid": "e53ac3f9-b098-4ef2-9c30-1dae58dbc06b",
		"major": false,
		"active": true,
		"book_id": 9,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "Won't fight living characters under any circumstances, but may use nonlethal methods in self-defense.",
		"bookPage": "p26",
		"base_name": "Pacifist",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "Fights only in self-defense and never allows the killing of prisoners or defenseless victims.",
		"minor_or_major": true,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": true,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 37,
		"name": "Pacifist (major)",
		"type": "(unknown)",
		"uuid": "d01e2542-b578-4637-2450-3fbc62972c80",
		"major": true,
		"active": true,
		"book_id": 2,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "won’t harm living creatures",
		"bookPage": "p32",
		"base_name": "Pacifist",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": true,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 36,
		"name": "Pacifist (minor)",
		"type": "(unknown)",
		"uuid": "7cc87819-2db0-43dd-99c5-79d869f54163",
		"major": false,
		"active": true,
		"book_id": 2,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "Character fights only in self-defense as a Minor Hindrance",
		"bookPage": "p32",
		"base_name": "Pacifist",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": true,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 141,
		"name": "Phobia",
		"type": "(unknown)",
		"uuid": "356b4ee2-e13d-427b-bae5-06c4612e6b15",
		"major": false,
		"active": true,
		"book_id": 9,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "The character is afraid of something, and subtracts –2 from all Trait rolls in its presence.",
		"bookPage": "p26",
		"base_name": "Phobia",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "The character is afraid of something, and subtracts –1 from all Trait rolls in its presence.",
		"minor_or_major": true,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": true,
		"always_show_long_name": true,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 39,
		"name": "Phobia (major)",
		"type": "(unknown)",
		"uuid": "4bdd22c0-3c59-4087-3658-d2b5e990ecb7",
		"major": true,
		"active": true,
		"book_id": 2,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "-4 to Trait tests when near the phobia",
		"bookPage": "p32",
		"base_name": "Phobia",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": true,
		"always_show_long_name": true,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 38,
		"name": "Phobia (minor)",
		"type": "(unknown)",
		"uuid": "6fb54fc9-c1e3-461c-2a41-7fbd3ffc91ff",
		"major": false,
		"active": true,
		"book_id": 2,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "–2 to Trait tests when near the phobia",
		"bookPage": "p32",
		"base_name": "Phobia",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": true,
		"always_show_long_name": true,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 40,
		"name": "Poverty",
		"type": "(unknown)",
		"uuid": "65275b6d-50bb-41fc-107f-aa06c95c129f",
		"major": false,
		"active": true,
		"book_id": 2,
		"effects": [
			"starting_funds_multiplier: .5"
		],
		"removed": false,
		"specify": "",
		"summary": "Half starting funds, inability to hang onto future income",
		"bookPage": "p32",
		"base_name": "Poverty",
		"conflicts": [
			"rich",
			"filthy rich",
			"noble"
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 142,
		"name": "Poverty",
		"type": "(unknown)",
		"uuid": "a7e046d3-977f-48b9-b5b2-d299b0b15d36",
		"major": false,
		"active": true,
		"book_id": 9,
		"effects": [
			"starting_funds_multiplier: .5",
			"wealth_die: d4"
		],
		"removed": false,
		"specify": "",
		"summary": "Half starting funds and the character is always broke.",
		"bookPage": "p26",
		"base_name": "Poverty",
		"conflicts": [
			"edge:rich"
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 41,
		"name": "Quirk",
		"type": "(unknown)",
		"uuid": "96863ac4-a833-40eb-1eae-0ce30346755d",
		"major": false,
		"active": true,
		"book_id": 2,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "Character has some minor but persistent foible",
		"bookPage": "p32",
		"base_name": "Quirk",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": true,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 143,
		"name": "Quirk",
		"type": "(unknown)",
		"uuid": "0461c5f6-498f-431a-1692-cc9d13d095e5",
		"major": false,
		"active": true,
		"book_id": 9,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "The individual has some minor but persistent foible that often annoys others.",
		"bookPage": "p26",
		"base_name": "Quirk",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": true,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 144,
		"name": "Ruthless",
		"type": "(unknown)",
		"uuid": "e0ca8de6-f9dd-4c2b-1cf8-babcaf26c2da",
		"major": false,
		"active": true,
		"book_id": 9,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "The character does what it takes to get her way.",
		"bookPage": "p26",
		"base_name": "Ruthless",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "The character does what it takes to get her way.",
		"minor_or_major": true,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": true,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 145,
		"name": "Secret",
		"type": "(unknown)",
		"uuid": "3886d10e-4ebd-499c-a9c3-2dee881a9844",
		"major": false,
		"active": true,
		"book_id": 9,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "The hero has a dark secret of some kind.",
		"bookPage": "p26",
		"base_name": "Secret",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "The hero has a dark secret of some kind.",
		"minor_or_major": true,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": true,
		"always_show_long_name": true,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 146,
		"name": "Shamed",
		"type": "(unknown)",
		"uuid": "d95d7716-e6d5-472c-24e0-80fa4bf94fec",
		"major": false,
		"active": true,
		"book_id": 9,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "The individual is haunted by some tragic event from her past.",
		"bookPage": "p27",
		"base_name": "Shamed",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "The individual is haunted by some tragic event from her past.",
		"minor_or_major": true,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": true,
		"always_show_long_name": true,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 147,
		"name": "Slow",
		"type": "(unknown)",
		"uuid": "f0109986-014c-4324-9c75-1e31b428917a",
		"major": false,
		"active": true,
		"book_id": 9,
		"effects": [
			"pace -2",
			"running_die: d4",
			"skill_bonus:Athletics -2"
		],
		"removed": false,
		"specify": "",
		"summary": "Pace reduced by 2, running die is d4–1 (minimum 1), subtract 2 from Athletics and rolls to resist Athletics.",
		"bookPage": "p27",
		"base_name": "Slow",
		"conflicts": [
			"edge:Fleet-footed"
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [
			"pace -1",
			"running_die: d4"
		],
		"summary_minor": "As Minor, Pace reduced by 1 and running die is a d4. ",
		"minor_or_major": true,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": true,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 42,
		"name": "Small",
		"type": "(unknown)",
		"uuid": "727f2000-9b55-49b6-bff7-2ee527146180",
		"major": true,
		"active": true,
		"book_id": 2,
		"effects": [
			"-1 Toughness"
		],
		"removed": false,
		"specify": "",
		"summary": "–1 Toughness",
		"bookPage": "p32",
		"base_name": "Small",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 148,
		"name": "Small",
		"type": "(unknown)",
		"uuid": "199831c1-22f0-4c5f-1641-c90009ceb72f",
		"major": false,
		"active": true,
		"book_id": 9,
		"effects": [
			"-1 size"
		],
		"removed": false,
		"specify": "",
		"summary": "Size and Toughness are reduced by 1",
		"bookPage": "p27",
		"base_name": "Small",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 43,
		"name": "Stubborn",
		"type": "(unknown)",
		"uuid": "18e4c4f9-82d9-487e-3ad4-1cfdc67f18dc",
		"major": false,
		"active": true,
		"book_id": 2,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "Hero always wants his way",
		"bookPage": "p32",
		"base_name": "Stubborn",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 149,
		"name": "Stubborn",
		"type": "(unknown)",
		"uuid": "53a1c5d2-c67a-4f69-3051-8b8f4ea84f44",
		"major": false,
		"active": true,
		"book_id": 9,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "The character wants his way and rarely admits his mistakes",
		"bookPage": "p27",
		"base_name": "Stubborn",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 150,
		"name": "Suspicious",
		"type": "(unknown)",
		"uuid": "955ef660-93af-42f7-bb93-50211e558087",
		"major": false,
		"active": true,
		"book_id": 9,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "The individual is paranoid. Allies subtract 2 when rolling to Support him.",
		"bookPage": "p28",
		"base_name": "Suspicious",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "The individual is paranoid",
		"minor_or_major": true,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": true,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 194,
		"name": "Thin Skinned",
		"type": "(unknown)",
		"uuid": "d04e87b5-158b-4ee9-a009-593e61bf3492",
		"major": false,
		"active": true,
		"book_id": 9,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "The character is particularly susceptible to personal attacks. As a Major, he subtracts four when resisting Taunt attacks.",
		"bookPage": "p28",
		"base_name": "Thin Skinned",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "The character is particularly susceptible to personal attacks. As a Minor Hindrance, he subtracts two when resisting Taunt attacks.",
		"minor_or_major": true,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": true,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 151,
		"name": "Tongue-Tied",
		"type": "(unknown)",
		"uuid": "9b1b5fd2-0b85-4285-0fc0-05977c5dc547",
		"major": true,
		"active": true,
		"book_id": 9,
		"effects": [
			"skill_bonus:Intimidation -1",
			"skill_bonus:Taunt -1",
			"skill_bonus:Persuasion -1"
		],
		"removed": false,
		"specify": "",
		"summary": "The character often misspeaks or can’t get her words out. –1 to Intimidation, Persuasion, and Taunt rolls.",
		"bookPage": "p28",
		"base_name": "Tongue-Tied",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 44,
		"name": "Ugly",
		"type": "(unknown)",
		"uuid": "e8d2566d-55cf-4a8e-82d6-586d08a18a23",
		"major": false,
		"active": true,
		"book_id": 2,
		"effects": [
			"-2 Charisma"
		],
		"removed": false,
		"specify": "",
		"summary": "-2 Charisma due to appearance",
		"bookPage": "p33",
		"base_name": "Ugly",
		"conflicts": [
			"attractive"
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 152,
		"name": "Ugly",
		"type": "(unknown)",
		"uuid": "c453d47e-34bf-4b8e-330f-942b188ee31e",
		"major": false,
		"active": true,
		"book_id": 9,
		"effects": [
			"skill_bonus:Persuasion -2"
		],
		"removed": false,
		"specify": "",
		"summary": "The character is physically unattractive and subtracts 2 from Persuasion rolls",
		"bookPage": "p28",
		"base_name": "Ugly",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [
			"skill_bonus:Persuasion -1"
		],
		"summary_minor": "The character is physically unattractive and subtracts 1 from Persuasion rolls",
		"minor_or_major": true,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": true,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 153,
		"name": "Vengeful",
		"type": "(unknown)",
		"uuid": "80a66129-879b-49a8-0aed-97ea84bb653e",
		"major": false,
		"active": true,
		"book_id": 9,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "The adventurer seeks payback for slights against her and will cause physical harm to get it.",
		"bookPage": "p28",
		"base_name": "Vengeful",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "The adventurer seeks payback for slights against her. ",
		"minor_or_major": true,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": true,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 45,
		"name": "Vengeful (major)",
		"type": "(unknown)",
		"uuid": "9364b635-1a87-4eed-8e3d-1e8d055c8026",
		"major": true,
		"active": true,
		"book_id": 2,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "Character holds a grudge; will kill as a Major Hindrance",
		"bookPage": "p33",
		"base_name": "Vengeful",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": true,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 46,
		"name": "Vengeful (minor)",
		"type": "(unknown)",
		"uuid": "9bf5e055-3788-48dc-14fb-ebb18149ea6e",
		"major": false,
		"active": true,
		"book_id": 2,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "Character holds a grudge",
		"bookPage": "p33",
		"base_name": "Vengeful",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": true,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 154,
		"name": "Vow",
		"type": "(unknown)",
		"uuid": "9d40fc66-b149-4f41-82b6-46c5a3d0fc97",
		"major": false,
		"active": true,
		"book_id": 9,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "The individual has pledged himself to some cause",
		"bookPage": "p28",
		"base_name": "Vow",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "The individual has pledged himself to some cause",
		"minor_or_major": true,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": true,
		"always_show_long_name": true,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 47,
		"name": "Vow (major)",
		"type": "(unknown)",
		"uuid": "cc3f8e27-c3d1-4934-b5e2-97afaa2c987f",
		"major": true,
		"active": true,
		"book_id": 2,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "A pledge to a group, deity, or religion",
		"bookPage": "p33",
		"base_name": "Vow",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": true,
		"always_show_long_name": true,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 48,
		"name": "Vow (minor)",
		"type": "(unknown)",
		"uuid": "9db5531b-d9cb-48dc-8ec3-273f717bb2c4",
		"major": false,
		"active": true,
		"book_id": 2,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "A pledge to a group, deity, or religion",
		"bookPage": "p33",
		"base_name": "Vow",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": true,
		"always_show_long_name": true,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 813,
		"name": "Wanted",
		"type": "(unknown)",
		"uuid": "2917fbb2-9809-409f-2f2e-254fcae6ce67",
		"major": false,
		"active": true,
		"book_id": 9,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "The character is wanted by the authorities.",
		"bookPage": "p28",
		"base_name": "Wanted",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "The character is wanted by the authorities.",
		"minor_or_major": true,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": true,
		"always_show_long_name": true,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 49,
		"name": "Wanted (major)",
		"type": "(unknown)",
		"uuid": "73748ea2-d7d0-4fa8-09b6-ce89d1a53242",
		"major": true,
		"active": true,
		"book_id": 2,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "The character is a criminal of some sort",
		"bookPage": "p33",
		"base_name": "Wanted",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": true,
		"always_show_long_name": true,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 50,
		"name": "Wanted (minor)",
		"type": "(unknown)",
		"uuid": "c9aeae27-da94-4ecb-9747-7240cff74a94",
		"major": false,
		"active": true,
		"book_id": 2,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "The character is a criminal of some sort",
		"bookPage": "p33",
		"base_name": "Wanted",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": true,
		"always_show_long_name": true,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 51,
		"name": "Yellow",
		"type": "(unknown)",
		"uuid": "69933156-fe03-4973-9c7d-19ece164ea76",
		"major": false,
		"active": true,
		"book_id": 2,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "The character is cowardly and suffers –2 to Fear checks",
		"bookPage": "p33",
		"base_name": "Yellow",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 156,
		"name": "Yellow",
		"type": "(unknown)",
		"uuid": "a1398ae0-c8eb-45fb-1aa5-b9abd6bc9989",
		"major": true,
		"active": true,
		"book_id": 9,
		"effects": [],
		"removed": false,
		"specify": "",
		"summary": "–2 to Fear tests and resisting Intimidation",
		"bookPage": "p28",
		"base_name": "Yellow",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 52,
		"name": "Young",
		"type": "(unknown)",
		"uuid": "f9e36d87-bd5d-463e-9c5c-162c409702f4",
		"major": true,
		"active": true,
		"book_id": 2,
		"effects": [
			"setattributepoints: 3",
			"setskillpoints: 10",
			"+1 benny"
		],
		"removed": false,
		"specify": "",
		"summary": "",
		"bookPage": "p33",
		"base_name": "Young",
		"conflicts": [
			"elderly"
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [],
		"summary_minor": "",
		"minor_or_major": false,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": false,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	},
	{
		"id": 157,
		"name": "Young",
		"type": "(unknown)",
		"uuid": "200e4c4e-79a5-482c-bb20-748923e0fbdd",
		"major": false,
		"active": true,
		"book_id": 9,
		"effects": [
			"+2 benny",
			"-2 skill_points",
			"-2 attribute_points"
		],
		"removed": false,
		"specify": "",
		"summary": "Major has 3 attribute points, 10 skill points, and 2 extra Bennies per session.",
		"bookPage": "p28",
		"base_name": "Young",
		"conflicts": [
			""
		],
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"setting_item": false,
		"effects_minor": [
			"+1 benny",
			"-2 skill_points",
			"-1 attribute_points"
		],
		"summary_minor": "Minor has 4 attribute points and 10 skill points, extra Benny per session.",
		"minor_or_major": true,
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"always_show_long_name": true,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false
	}
];

export default Hindrances;