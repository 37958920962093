import React, {useState} from 'react';
import Language from "../Language/Language";
import ButtonRegular, {ButtonRegularColor} from "../UI/Burnblade/ButtonRegular";
import HorizontalRule from "../UI/Burnblade/HorizontalRule";
import CharacterData from "./CharacterData";
import TabBookHorizontal from "../UI/Burnblade/TabBookHorizontal";
import TraitsData from "./TraitsData";
import CharacterModuleMotivations from "./CharacterModuleMotivations";
import CharacterModuleDescription from "./CharacterModuleDescription";
import CharacterModuleTraits from "./CharacterModuleTraits";
import CharacterModuleEdges from "./CharacterModuleEdges";
import CharacterModuleStatus from "./CharacterModuleStatus";
import CharacterStatusData from "./CharacterStatusData";


function CharacterModule({character, onSaved, readOnly}) {

	// input
	const language = Language.getCurrent();
	const status = CharacterData.getCharacterStatus(character);
	const [headshotUrl, setHeadshotUrl] = useState(CharacterStatusData.getCharacterHeadshotUrl(status));
	const [vigor, setVigor] = useState(CharacterData.getCharacterTrait(character, TraitsData.Attributes.Vigor));
	const [fighting, setFighting] = useState(CharacterData.getCharacterTrait(character, TraitsData.Skills.Fighting));
	const [name, setName] = useState(CharacterData.getCharacterName(character));
	const [adjective, setAdjective] = useState(CharacterData.getCharacterAdjective(character));
	const [noun, setNoun] = useState(CharacterData.getCharacterNoun(character));
	const [motivations, setMotivations] = useState(CharacterData.getMotivations(character).map(motivation => [motivation[0],motivation[1]]));
	const [size, setSize] = useState(CharacterData.getCharacterSize(character));
	const [edgeIds, setEdgeIds] = useState(CharacterData.getCharacterEdgeIds(character).slice());
	const [hindranceIds, setHindranceIds] = useState(CharacterData.getCharacterHindranceIds(character).slice());
	const [armorItemIds, setArmorItemIds] = useState(CharacterStatusData.getCharacterArmorItemIds(status).slice());
	const [weaponItemIds, setWeaponItemIds] = useState(CharacterStatusData.getCharacterWeaponItemIds(status).slice());
	const [gearItemIds, setGearItemIds] = useState(CharacterStatusData.getCharacterGearItemIds(status).slice());
	const abilities = CharacterData.getCharacterAbilities(character);

	// display
	return (
		<form
			onSubmit={event => {
				event.preventDefault();

				if (onSaved)
				{
					// Character
					CharacterData.setCharacterName(character, event.target.name.value);
					CharacterData.setCharacterIsPlayer(character, event.target.isPlayer.checked?1:0);
					CharacterData.setCharacterIsWildCard(character, event.target.isWildCard.checked?1:0);
					CharacterData.setCharacterAdjective(character, event.target.adjective.value);
					CharacterData.setCharacterNoun(character, event.target.noun.value);
					CharacterData.setMotivations(character, motivations.filter(motivation => motivation[0] || motivation[1]).map(motivation => [motivation[0],motivation[1]]));
					CharacterData.setCharacterSize(character, event.target.size.value-0);
					CharacterData.setCharacterPace(character, event.target.pace.value-0);
					CharacterData.setCharacterEdgeIds(character, edgeIds.slice());
					CharacterData.setCharacterHindranceIds(character, hindranceIds.slice());

					// Status
					CharacterStatusData.setCharacterNotes(status, event.target.notes.value);
					CharacterStatusData.setCharacterBennies(status, event.target.bennies.value-0);
					CharacterStatusData.setCharacterWounds(status, event.target.wounds.value-0);
					CharacterStatusData.setCharacterFatigue(status, event.target.fatigue.value-0);
					CharacterStatusData.setCharacterIsIncapacitated(status, event.target.isIncapacitated.checked?1:0);
					CharacterStatusData.setCharacterIsStunned(status, event.target.isStunned.checked?1:0);
					CharacterStatusData.setCharacterIsBound(status, event.target.isBound.checked?1:0);
					CharacterStatusData.setCharacterIsShaken(status, event.target.isShaken.checked?1:0);
					CharacterStatusData.setCharacterArmorItemIds(status, armorItemIds.slice());
					CharacterStatusData.setCharacterWeaponItemIds(status, weaponItemIds.slice());
					CharacterStatusData.setCharacterGearItemIds(status, gearItemIds.slice());
					CharacterStatusData.setCharacterHeadshotUrl(status, headshotUrl);

					// Traits
					Object.values(TraitsData).forEach(traits => {
						Object.values(traits).forEach(trait => {
							if (event.target[`traits[${trait}]`])
								CharacterData.setCharacterTrait(character, trait, event.target[`traits[${trait}]`].value);
						})
					});

					// done
					onSaved();
				}
				return false;
			}}
		>

			<TabBookHorizontal
				initialTabIndex={0}
				hiddenPages={true}
				tabLabels={[
					language.Description,
					readOnly && (!edgeIds || !edgeIds.length) && (!hindranceIds || !hindranceIds.length) && (!abilities || !abilities.length) ? false : language.Edges_Hindrances,
					language.Traits,
					readOnly && (!motivations || !motivations.length) ? false : language.Motivation,
					//language.Powers
					language.Status, // Bennies, Wounds, Shaken/Recovering, Incapacitated, Stunned, Bound, Fatigue, etc
				]}
				tabPages={[

					// Character
					<CharacterModuleDescription
						readOnly={readOnly}
						character={character}
						name={name}
						size={size}
						noun={noun}
						adjective={adjective}
						headshotUrl={headshotUrl}
						setName={setName}
						setSize={setSize}
						setNoun={setNoun}
						setAdjective={setAdjective}
						setHeadshotUrl={setHeadshotUrl}
					/>,

					// Edges
					<CharacterModuleEdges
						readOnly={readOnly}
						edgeIds={edgeIds}
						setEdgeIds={setEdgeIds}
						hindranceIds={hindranceIds}
						setHindranceIds={setHindranceIds}
						abilities={abilities}
					/>,

					// Traits,
					<CharacterModuleTraits
						readOnly={readOnly}
						character={character}
						vigor={vigor}
						setVigor={setVigor}
						fighting={fighting}
						setFighting={setFighting}
						size={size}
					/>,

					// Motivations
					<CharacterModuleMotivations
						readOnly={readOnly}
						motivations={motivations}
						setMotivations={setMotivations}
					/>,

					// Status
					<CharacterModuleStatus
						character={character}
						gearItemIds={gearItemIds}
						armorItemIds={armorItemIds}
						weaponItemIds={weaponItemIds}
						setGearItemIds={setGearItemIds}
						setArmorItemIds={setArmorItemIds}
						setWeaponItemIds={setWeaponItemIds}
					/>
				]}
			/>

			<HorizontalRule />

			{/* Save (even readOnly can save Notes, status, etc) */}
			<ButtonRegular
				type="submit"
				style={{margin:"auto",display:"block"}}
				buttonRegularColor={ButtonRegularColor.Green}
			>{language.Save}</ButtonRegular>

		</form>
	);
}

export default CharacterModule;