import React from 'react';
import styled from "styled-components";

const ButtonRegularColor = {
	Gray:0,
	Green:1,
	Orange:2
};

const Wrapper = styled.button`
	border:none;
	padding:0;
	background: url(/assets/burnblade/button_border.png);
	background-size:100%;
	width:173px;
	height:65px;
	color:inherit;
	--bgLeft:0;
	--bgTop:0;
	
	&[disabled] { cursor:default; }
	&:not([disabled]) {
		&:hover { --bgLeft:-158px; }
		&:active { --bgLeft:-316px; }
	}

	&[data-color='${ButtonRegularColor.Green}'] { --bgTop:-100px; }
	&[data-color='${ButtonRegularColor.Orange}'] { --bgTop:-200px; }

	& > span {
		display:block;
		margin:auto;
		background:url(/assets/burnblade/buttons.jpg);
		background-size:300% 300%;
		background-position:var(--bgLeft) var(--bgTop);
		width:158px;
		height:50px;
		line-height: 50px;
		font-size:20px;
		font-weight: bold;
		text-transform: uppercase;
		text-shadow: 1px 1px 2px black;
	}
`;

function ButtonRegular({children, onClick, buttonRegularColor, style, type, disabled}) {

	// input

	// display
	return (
		<Wrapper
			type={type}
			style={style}
			onClick={onClick}
			disabled={disabled}
			data-color={buttonRegularColor}
		><span>{children}</span></Wrapper>
	);
}

export default ButtonRegular;
export {ButtonRegularColor};