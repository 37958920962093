import React from 'react';
import styled from "styled-components";

const Wrapper = styled.label`
	display: inline-flex;
	flex-flow: row nowrap;
	white-space: nowrap;
	align-items: center;
	padding:1em;

	&:not([data-stacked]) > span:first-child {
		& + select,
		& + textarea,
		& + input[type='text']
		{ margin-left:1em; }
	}
	
	&[data-left-input] {
		& > span:first-child {
			order:2;
			margin-left:0.5em;
		}
	}
	
	&[data-stacked] {
		display: inline-flex;
		flex-flow:column nowrap;
		align-items: flex-start;
		width: 100%;
		box-sizing: border-box;
		& > span:first-child { margin-bottom: 0.5em; }
	}
	
	&:not([data-stacked]):not([data-left-input]) {
		display: flex;
		flex-flow: row nowrap;
		& > span:first-child { 
			flex:1;
			margin-right:1em;
		}
	}
`;

function Label({text, children, inputOnLeft, stacked, style}) {

	// input

	// display
	return (
		<Wrapper data-left-input={inputOnLeft?1:undefined} data-stacked={stacked?1:undefined} style={style}>
			<span>{text}</span>
			{children}
		</Wrapper>
	);
}

export default Label;