import React, {useState} from 'react';
import styled from "styled-components";
import MapData from "./MapData";
import DragManager from "../UI/DragManager";
import MapTokenData from "./MapTokenData";
import CharacterData from "./CharacterData";
import Dialog from "../UI/Burnblade/Dialog";
import CharacterModule from "./CharacterModule";
import Bestiary from "../Savaged/Bestiary";
import BestiaryCharacterModule from "./BestiaryCharacterModule";
import CharacterStatusData from "./CharacterStatusData";
import {isDefined} from "../Helpers";

const Wrapper = styled.div`
	width:100%;
	height:100%;
	overflow: auto;
	position: relative;
	justify-content: center;
	//display:flex; // only when smaller than screen
	flex-flow: row nowrap;
	
	& > div:first-child {
		background:no-repeat center center;
		background-size:cover;
	}
`;

const Token = styled.div`
	transform-origin: 50% 50%;
	background:no-repeat center center #005;
	background-size:cover;
	border-radius: 50%;
	position: absolute;
	border:solid 3px;
	box-shadow:3px 3px 6px Black;
`;

function MapModule({mapData, onSaved, characters, bestiaryStatuses}) {

	// input
	const [renderKey, setRenderKey] = useState(0);

	// display
	return (
		<Wrapper style={{
			display:
				MapData.getMapWidth(mapData)<window.innerWidth ||
				MapData.getMapHeight(mapData)<window.innerHeight ?
					"flex" : undefined
		}}>

			{/* Background */}
			<div style={{
				width:`${MapData.getMapWidth(mapData)}px`,
				height:`${MapData.getMapHeight(mapData)}px`,
				backgroundImage:`url('${MapData.getMapImageUrl(mapData)}')`,
			}}>

				<div key={renderKey}>
					{
						// Tokens
						[
							MapData.getMapCharacterTokens(mapData),
							MapData.getMapBestiaryTokens(mapData)
						]
						.map(tokens => tokens.map((token, tokenIndex) =>
							<TokenModule
								token={token}
								onSaved={onSaved}
								tokenIndex={tokenIndex}
								characters={characters}
								bestiaryStatus={bestiaryStatuses[tokenIndex]}
								onMoved={()=>{setRenderKey(renderKey+1);}}
								key={`${tokenIndex}-${MapTokenData.getMapTokenPosX(token)},${MapTokenData.getMapTokenPosY(token)}`}
							/>
						))
					}
				</div>
			</div>
		</Wrapper>
	);
}

function TokenModule({token, onMoved, onSaved, characters, bestiaryStatus})
{
	// input
	const width = 50;
	const height = 50;
	const initialPosX = MapTokenData.getMapTokenPosX(token);
	const initialPosY = MapTokenData.getMapTokenPosY(token);
	const characterId = MapTokenData.getMapTokenCharacterId(token);
	const bestiaryId = MapTokenData.getMapTokenBestiaryId(token);
	const character =
		isDefined(characterId) ? characters["find"](checkCharacter => CharacterData.getCharacterId(checkCharacter) === characterId) :
		bestiaryId ? Bestiary.find(checkBeast => checkBeast.id === bestiaryId) :
		false
	;
	const [posX, setPosX] = useState(initialPosX);
	const [posY, setPosY] = useState(initialPosY);
	const [showDialog, setShowDialog] = useState(false);

	// display
	return (
		<>
			<Token
				style={{
					width:`${width}px`,
					height:`${height}px`,
					marginTop:`calc(${width}px * -0.5)`,
					marginLeft:`calc(${height}px * -0.5)`,
					transform:`translate(${posX}px, ${posY}px`,
					backgroundImage:`url(${CharacterStatusData.getCharacterHeadshotUrl(bestiaryId ? bestiaryStatus : CharacterData.getCharacterStatus(character))})`
				}}
				onMouseDown={event => {
					DragManager.dragStart(event, {
						onDrag:(changeX, changeY) => {
							setPosX(changeX + initialPosX);
							setPosY(changeY + initialPosY);
						},
						onDragStop:(changeX, changeY) => {
							if (changeX || changeY)
							{
								MapTokenData.setMapTokenPosX(token, changeX + initialPosX);
								MapTokenData.setMapTokenPosY(token, changeY + initialPosY);
								requestAnimationFrame(() => {onMoved();});
							}
						},
						onClick:() => { setShowDialog(true); }
					});
				}}
			/>
			{
				// Character Dialog
				!showDialog ? false :
				<Dialog
					onClose={()=>{setShowDialog(false);}}
					title={bestiaryId ? character.name : CharacterData.getCharacterNameExtended(character)}
					>
					{
						bestiaryId ?
						<BestiaryCharacterModule
							onSaved={onSaved}
							bestiaryCharacter={character}
							bestiaryStatus={bestiaryStatus}
						/> :
						<CharacterModule
							readOnly={true}
							onSaved={onSaved}
							character={character}
						/>
					}
				</Dialog>
			}
		</>
	);
}

export default MapModule;