import React from 'react';
import styled from "styled-components";
import Widget9 from "../Widget9";
import WidgetH from "../WidgetH";

const Wrapper = styled.div`
	
	& > *:first-child > *:nth-child(2) > *:nth-child(2) > *.DialogTitle > * {
		&:nth-child(1) { background-size:calc(200% + 4px) 100%; }
		&:nth-child(2) { background-size: auto 100%; }
		&:nth-child(3) { background-size:calc(200% + 4px) 100%; }
	}
`;

const TitleWrapperWithButton = styled.div`
	display:flex;
	flex-flow:row nowrap;
	align-items:center;
	
	// Title Text
	& > div:first-child { flex:1; }
`;

const TitleWrapper = styled.div`
	font-size:24px;
	text-transform: uppercase;
	font-weight: bold;
	box-sizing: border-box;
	
	display:flex;
	flex-flow:row nowrap;
	align-items: center;
	
	height:100%;
	padding-top: 0.25em;

	& > * { margin-top:-12px; }
	
	& > div { 
		flex:1;
		padding-left:0.5em;
	}
	
	& > button {
		background: url(/assets/burnblade/header_close.png);
		background-size:300% 100%;
		width:46.5px;
		height:42.5px;
		border:none;
		padding:0;
		&:hover { background-position: -46.5px 0; }
		&:active { background-position: -93px 0; }
	} 
`;

function Dialog({children, title, onClose}) {

	// input

	// display
	return (
		<Wrapper className="Dialog" style={{fontSize:"16px", textTransform:"none", fontWeight:"normal"}}>

			{/* Outer */}
			<Widget9
				widthByCol={["30px",undefined,"30px"]}
				heightByRow={["30px",undefined,"30px"]}
				backgroundImagesByRow={[
					["/assets/burnblade/outer_c.png","/assets/burnblade/outer_h.png","/assets/burnblade/outer_c.png"],
					["/assets/burnblade/outer_v.png",undefined,"/assets/burnblade/outer_v.png"],
					["/assets/burnblade/outer_c.png","/assets/burnblade/outer_h.png","/assets/burnblade/outer_c.png"],
				]}
				backgroundPositionsByRow={[
					["-1px -1px","0 -1px","-33px -1px"],
					["-1px 0",undefined,"-33px 0"],
					["-1px -33px","0 -33px","-33px -33px"],
				]}
			>
				{
					// Header
					!title && !onClose ? false :
					<WidgetH
						className="DialogTitle"
						height="65.5px"
						widths={["20px", undefined, "20px"]}
						backgroundImages={[
							"/assets/burnblade/header_s.jpg",
							"/assets/burnblade/header_m.jpg",
							"/assets/burnblade/header_s.jpg"
						]}
						backgroundPositions={["-1px 0","left center","-23px -1px"]}
					>
						<TitleWrapper>
							<div>{title}</div>
							{!onClose?false:<button type="button" onClick={onClose} />}
						</TitleWrapper>
					</WidgetH>
				}

				{/* Inner */}
				<Widget9
					widthByCol={["20px",undefined,"20px"]}
					heightByRow={["20px",undefined,"20px"]}
					backgroundImagesByRow={[
						["/assets/burnblade/inner_c.png","/assets/burnblade/inner_h.png","/assets/burnblade/inner_c.png"],
						["/assets/burnblade/inner_v.png","/assets/burnblade/inner.png","/assets/burnblade/inner_v.png"],
						["/assets/burnblade/inner_c.png","/assets/burnblade/inner_h.png","/assets/burnblade/inner_c.png"],
					]}
					backgroundPositionsByRow={[
						["-1px -1px","0 -1px","-23px -1px"],
						["-1px 0",undefined,"-23px 0"],
						["-1px -23px","0 -23px","-23px -23px"],
					]}
				>
					{
						// Content
						children
					}
				</Widget9>
			</Widget9>
		</Wrapper>
	);
}

export default Dialog;
export {TitleWrapperWithButton};