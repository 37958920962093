import React from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
	display:flex;
	flex-flow:row nowrap;

	& > div {

		// Left
		&:first-child {
			background-repeat: no-repeat;
		}

		// Middle
		&:nth-child(2) {
			flex:1;
			background-repeat: repeat-x;
		}

		// Right
		&:last-child {
			background-repeat: no-repeat;
		}
	}
`;

function WidgetH({className, children, widths, height, backgroundImages, backgroundPositions}) {

	// input

	// display
	return (
		<Wrapper className={className}>
			<div style={{width:widths[0], height: height, backgroundImage:`url(${backgroundImages[0]})`, backgroundPosition:backgroundPositions[0]}}></div>
			<div style={{width:widths[1], height: height, backgroundImage:`url(${backgroundImages[1]})`, backgroundPosition:backgroundPositions[1]}}>{children}</div>
			<div style={{width:widths[2], height: height, backgroundImage:`url(${backgroundImages[2]})`, backgroundPosition:backgroundPositions[2]}}></div>
		</Wrapper>
	);
}

export default WidgetH;