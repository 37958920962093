import React from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
	border:none;
	display: inline-block;
	text-align: left;
	background:url(/assets/burnblade/character.png);
	width:724px;
	height:246px;
	box-sizing: border-box;
	padding:49px;

	& > span {
		display: block;

		// avatar
		&:first-child {
			width:144px;
			height:144px;
			float:left;
			border-radius: 50%;
			margin-right:40px;
			background-size:cover;
			background-position: center center;
			image-rendering: pixelated;

			// overlay
			&:after {
				content:"";
				display: block;
				background: url(/assets/burnblade/character_overlay.png);
				width:100%;
				height:100%;
			}
		}

		// Name
		&:nth-child(2) {
			font-size:48px;
			color:#eedbc4;
			margin-top:24px;
			margin-bottom: 4px;
		}

		// Details
		&:nth-child(3) {
			color:#5e5854;
		}
	}
	
	// clickable
	&[data-interactive] {
		cursor: pointer;
		&:hover {
			background:
				url(/assets/burnblade/character.png) 0 -246px,
				url(/assets/burnblade/character.png) 0 0;
		}
	}
`;

function Character({avatarUrl, characterName, characterDetails, onClick}) {

	// input

	// display
	return (
		<Wrapper onClick={onClick} data-interactive={onClick?1:undefined}>
			<span style={{backgroundImage:`url(${avatarUrl})`}}></span>
			<span>{characterName}</span>
			<span>{characterDetails}</span>
		</Wrapper>
	);
}

export default Character;