import React, {useState} from 'react';
import Language from "../Language/Language";
import Dialog, {TitleWrapperWithButton} from "../UI/Burnblade/Dialog";
import Label from "../UI/Burnblade/Label";
import Spinner from "../UI/Burnblade/Spinner";
import SelectBox from "../UI/Burnblade/SelectBox";
import styled from "styled-components";
import HorizontalRule from "../UI/Burnblade/HorizontalRule";
import ButtonRegular, {ButtonRegularColor} from "../UI/Burnblade/ButtonRegular";
import {hideDice, rollDice} from "../UI/Dice";
import ImportantMessage from "../UI/Burnblade/ImportantMessage";
import RandomEventModule from "./RandomEventModule";

const OddsByProbabilityByChaosRank = [
	[[0,-20,77],[0,0,81],[1,5,82],[1,5,82],[2,10,83],[4,20,85],[5,25,86],[9,45,90],[10,50,91],[11,55,92],[16,80,97]],
	[[0,0,81],[1,5,82],[1,5,82],[2,10,83],[3,15,84],[5,25,86],[7,35,88],[10,50,91],[11,55,92],[13,65,94],[16,85,97]],
	[[0,0,81],[1,5,82],[2,10,83],[3,15,84],[5,25,86],[9,45,90],[10,50,91],[13,65,94],[15,75,96],[16,80,97],[18,90,99]],
	[[1,5,82],[2,10,83],[3,15,84],[4,20,85],[7,35,88],[10,50,91],[11,55,92],[15,75,96],[16,80,97],[16,85,97],[19,95,100]],
	[[1,5,82],[3,15,84],[5,25,86],[7,35,88],[10,50,91],[13,65,94],[15,75,96],[16,85,97],[18,90,99],[18,90,99],[19,95,100]],
	[[2,10,83],[5,25,86],[9,45,90],[10,50,91],[13,65,94],[16,80,97],[16,85,97],[18,90,99],[19,95,100],[19,95,100],[20,100,0]],
	[[3,15,84],[7,35,88],[10,50,91],[11,55,92],[15,75,96],[16,85,97],[18,90,99],[19,95,100],[19,95,100],[19,95,100],[20,100,0]],
	[[5,25,86],[10,50,91],[13,65,94],[15,75,96],[16,85,97],[18,90,99],[19,95,100],[19,95,100],[20,100,0],[22,110,0],[26,130,0]],
	[[10,50,91],[15,75,96],[16,85,97],[18,90,99],[19,95,100],[19,95,100],[20,100,0],[21,105,0],[23,115,0],[25,125,0],[26,145,0]]
];

const OddsWrapper = styled.div`
	display:flex;
	flex-flow:row nowrap;
	align-items: center;
	& > span {
		padding:0.5em;
		&:nth-child(2) { font-size:1.5em; }
	}
`;

const AnswerWrapper = styled.div`
	font-size: 2em;
	font-weight: bold;
	
	& > * {
		margin: auto;
		display: flex;
		flex-flow:row wrap;
		align-items: center;
		justify-content: center;
	}
	
	&:not([data-answer]) { visibility: hidden; }
	&[data-answer='1'] { filter:hue-rotate(70deg); }
`;

function FateModule({}) {

	// input
	const language = Language.getCurrent();
	const [showDialog, setShowDialog] = useState(false);
	const [chaosFactor, setChaosFactor] = useState(5);
	const [total, setTotal] = useState();
	const [highlightRandomEvent, setHighlightRandomEvent] = useState(false);
	const [selectedProbability, setSelectedProbability] = useState(Math.floor(language.Probabilities.length*0.5)-1);
	const odds = OddsByProbabilityByChaosRank[chaosFactor-1][selectedProbability];
	const answer =
		undefined === total ? "" :
		total <= odds[0] ? language.Exceptional_Yes :
		total <= odds[1] ? language.Yes :
		total <= odds[2] ? language.No :
		language.Exceptional_No;

	// display
	return (
		<>
			<button onClick={()=>{setShowDialog(true);}}>{language.Fate}</button>

			{
				// Adventures Dialog
				!showDialog ? false :
				<Dialog
					title={
						<TitleWrapperWithButton>

							{/* Title Text */}
							<div>{language.Fate}</div>

							{/* Random Event Module */}
							<RandomEventModule
								buttonRegularColor={highlightRandomEvent ? ButtonRegularColor.Green : undefined}
							/>

						</TitleWrapperWithButton>
					}
					onClose={()=>{setShowDialog(false); hideDice(); }}
				>

					{/* Chaos Factor */}
					<Spinner
						min={1}
						max={9}
						step={1}
						inline={true}
						onChange={setChaosFactor}
						initialValue={chaosFactor}
						text={language.Chaos_Factor}
					/>

					{/* Probability */}
					<Label text={language.Probability}>
						<span>
							<SelectBox value={selectedProbability} onChange={event => {setSelectedProbability(event.target.value-0);}}>
								{
									language.Probabilities.map((probability, probabilityIndex) =>
										<option key={probabilityIndex} value={probabilityIndex}>{probability}</option>
									)
								}
							</SelectBox>
						</span>
					</Label>
					<br />

					{/* Cutoffs */}
					<Label text={language.Odds} style={{paddingTop:0, paddingBottom:0}}>
						<OddsWrapper>{odds.map((odd, oddIndex) => <span key={oddIndex}>{odd}</span>)}</OddsWrapper>
					</Label>

					<HorizontalRule />

					{/* Answer */}
					<AnswerWrapper data-answer={!answer?undefined:language.Yes===answer || language.Exceptional_Yes === answer ? 1 : 0}>
						<ImportantMessage>{answer}</ImportantMessage>
					</AnswerWrapper>

					{/* Roll */}
					<div style={{textAlign:"center"}}>
						<ButtonRegular
							buttonRegularColor={ButtonRegularColor.Green}
							onClick={()=>{
								setTotal();
								setHighlightRandomEvent("");
								rollDice("1d10+1d100", false, results => {
									const total = results[0].value + results[1].value;
									if (results[0].value === results[1].value && results[0].value <= chaosFactor) setHighlightRandomEvent(true);
									setTotal(total);
								});
							}}
						>{language.Roll}</ButtonRegular>

						{/* Clear */}
						<ButtonRegular
							onClick={() => {
								setTotal();
								hideDice();
							}}
						>{language.Clear}</ButtonRegular>
					</div>

				</Dialog>
			}
		</>
	);
}

export default FateModule;