import React, {useState} from 'react';
import Dialog, {TitleWrapperWithButton} from "../UI/Burnblade/Dialog";
import Language from "../Language/Language";
import TabBookVertical from "../UI/Burnblade/TabBookVertical";
import CharacterModule from "./CharacterModule";
import CharacterData from "./CharacterData";
import Avatar from "../UI/Burnblade/Avatar";
import styled from "styled-components";
import PowerLevelModule from "./PowerLevelModule";
import CharacterStatusData from "./CharacterStatusData";

const CharacterTabWrapper = styled.div`
	& > * {
		
		// avatar
		&:first-child {
			float:left;
			margin-top: -8px;
			margin-left: -40px;
			margin-right: 24px;
		}
		
		// Name, Description
		&:nth-child(2) {
			line-height: initial;
			height:64px;
			display:flex;
			flex-flow:column nowrap;
			justify-content: center;
			
			// Description
			& > div:nth-child(2) {
				color:#746c65;
				font-size:0.8em;
				margin-top:0.25em;
				text-transform: capitalize;
			}
		}
	}
`;

function CharactersModule({characters, onSaved}) {

	// input
	const language = Language.getCurrent();
	const [showDialog, setShowDialog] = useState(false);
	const [renderKey, setRenderKey] = useState(0);
	const [initialTabIndex, setInitialTabIndex] = useState(0);

	// display
	return (
		<>
			<button onClick={()=>{setShowDialog(true);}}>{language.Characters}</button>

			{
				// Characters Dialog
				!showDialog ? false :
				<Dialog
					key={renderKey}
					title={
						<TitleWrapperWithButton>

							{/* Title Text */}
							<div>{language.Characters}</div>

							{/* Random Event Module */}
							<PowerLevelModule />

						</TitleWrapperWithButton>
					}
					onClose={()=>{setShowDialog(false);}}
				>
					<TabBookVertical
						initialTabIndex={initialTabIndex}
						tabLabels={

							// Character Labels
							characters.map((character, characterIndex) =>
								<CharacterTabWrapper key={characterIndex}>
									<Avatar url={CharacterStatusData.getCharacterHeadshotUrl(CharacterData.getCharacterStatus(character))} />
									<div>
										<div>{CharacterData.getCharacterNameExtended(character)}</div>
										<div>{CharacterData.getCharacterSummary(character)}</div>
									</div>
								</CharacterTabWrapper>
							)
						}
						tabPages={

							// Character Details
							characters.map((character, characterIndex) =>
								<CharacterModule
									key={characterIndex}
									character={character}
									onSaved={() => {
										setRenderKey(renderKey+1);
										setInitialTabIndex(characterIndex);
										onSaved();
									}}
								/>
							)
						}
						tabLabelsFooter={

							// New Character
							<button
								className="Create"
								onClick={()=>{
									characters["push"](CharacterData.createCharacter({
										id:characters["length"],
										prefillCoreSkills:true
									}));
									setRenderKey(renderKey+1);
									setInitialTabIndex(characters.length-1)
								}}
							>{language.Create_Character}</button>

						}
					/>
				</Dialog>
			}
		</>
	);
}

export default CharactersModule;