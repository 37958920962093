const Edges = [
	{
		"id": 1,
		"name": "Ace",
		"type": "(unknown)",
		"uuid": "4c31f173-4c53-4887-24b7-72358ab1c591",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [
			"skillbonus: +2 Boating",
			"skillbonus: +2 Driving",
			"skillbonus: +2 Piloting"
		],
		"specify": "",
		"summary": "+2 to Boating, Driving, Piloting; may make Soak rolls for vehicle at –2",
		"bookPage": "p42",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Agility d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 293,
		"name": "Ace",
		"type": "(unknown)",
		"uuid": "7ea7223b-8561-461e-356d-17d4facec325",
		"group": "Professional Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Ignore two points of penalties to any Boating, Driving, or Piloting roll. Spend Bennies to Soak damage for any vehicle they control",
		"bookPage": "p48",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Agility d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 2,
		"name": "Acrobat",
		"type": "(unknown)",
		"uuid": "33e59550-993b-4cb9-0cef-5c4b87b8581f",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [
			"acrobat_parry_bonus"
		],
		"specify": "",
		"summary": "+2 to nimbleness-based Agility rolls; +1 Parry if unencumbered",
		"bookPage": "p42",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Agility D8",
			"Strength d6"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 294,
		"name": "Acrobat",
		"type": "(unknown)",
		"uuid": "760f9684-d738-4903-232a-5eb053d8ea58",
		"group": "Professional Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "May Reroll most Athletics.",
		"bookPage": "p48",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Agility d8",
			"skill:Athletics d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": null,
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 3,
		"name": "Adept",
		"type": "(unknown)",
		"uuid": "155f4550-2054-473f-96e8-893c87534c14",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [
			"naturalweapon: Unarmed Str+d4"
		],
		"specify": "",
		"summary": "Str+d4 unarmed attacks; always considered armed; may choose certain powers to active as a free action (see text)",
		"bookPage": "p42",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"edge:Martial Artist",
			"Arcane Background (Miracles)",
			"skill:Faith D8",
			"skill:Fighting D8",
			""
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": null,
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 41,
		"name": "Alertness",
		"type": "(unknown)",
		"uuid": "719de941-e5ec-489d-1bf5-5e753caaed0f",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [
			"skillbonus: +2 Notice"
		],
		"specify": "",
		"summary": "+2 Notice",
		"bookPage": "p35",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 171,
		"name": "Alertness",
		"type": "(unknown)",
		"uuid": "5da35bea-4f91-4070-b3e8-29e67e28ba7e",
		"group": "Background Edges",
		"active": true,
		"book_id": 9,
		"effects": [
			"skillbonus:Notice +2"
		],
		"specify": "",
		"summary": "+2 to Notice tests",
		"bookPage": "p37",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 98,
		"name": "Ambidextrous",
		"type": "(unknown)",
		"uuid": "c00dad74-a075-4c1c-18d9-95afe442c717",
		"group": "Background",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "",
		"bookPage": "p35",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Agility D8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 172,
		"name": "Ambidextrous",
		"type": "(unknown)",
		"uuid": "fc364fdd-990d-445e-21ee-6829b9902491",
		"group": "Background Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Ignore –2 penalty when making Trait rolls with off-hand",
		"bookPage": "p37",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Agility d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": null,
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 5,
		"name": "Arcane Background",
		"type": "(unknown)",
		"uuid": "8cd7ccf2-fd2f-4ed5-850f-e301be255a4e",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "Allows access to supernatural powers",
		"bookPage": "p35",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": true,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 173,
		"name": "Arcane Background",
		"type": "(unknown)",
		"uuid": "812152c4-1f42-4b5d-b66c-58613c8daede",
		"group": "Background Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Allows access to the Arcane Backgrounds listed in Chapter Five.",
		"bookPage": "p37",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": true,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": true,
		"provides_swade_super_powers": false
	},
	{
		"id": 6,
		"name": "Arcane Resistance",
		"type": "(unknown)",
		"uuid": "d2baa083-6240-4fda-abe0-deb25cb185f9",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "Armor 2 vs. magic, +2 to resist powers",
		"bookPage": "p35",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Spirit d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 174,
		"name": "Arcane Resistance",
		"type": "(unknown)",
		"uuid": "4d1e7358-597c-415c-31c4-9bfdf7e4c970",
		"group": "Background Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "+2 to Trait rolls to resist magical effects; magical damage is reduced by 2.",
		"bookPage": "p37",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Spirit d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 176,
		"name": "Aristocrat",
		"type": "(unknown)",
		"uuid": "f41bf5e9-46b7-4346-3848-148632c48f4b",
		"group": "Background Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "+2 to Common Knowledge and networking with upper class.",
		"bookPage": "p37",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 250,
		"name": "Artificer",
		"type": "(unknown)",
		"uuid": "9d0187a4-bd64-4364-1b95-4f79cb9c98d5",
		"group": "Power Edges",
		"active": true,
		"book_id": 9,
		"effects": [
			"artificer"
		],
		"specify": "",
		"summary": "Allows user to create Arcane Devices.",
		"bookPage": "p46",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Seasoned",
			"Arcane Background"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 4,
		"name": "Assassin",
		"type": "(unknown)",
		"uuid": "1f7016fa-f240-493b-342a-5f21e83d3bf1",
		"group": "Professional",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "+2 to damage when striking a foe unawares",
		"bookPage": "p42",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Agility d8",
			"skill:Climbing d6",
			"skill:Fighting d6",
			"skill:Stealth d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 296,
		"name": "Assassin",
		"type": "(unknown)",
		"uuid": "603e1b32-779b-4d2c-15e8-dbf8f5089a46",
		"group": "Professional Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "+2 to damage foes when Vulnerable or assassin has The Drop.",
		"bookPage": "p48",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Agility d8",
			"skill:Fighting d6",
			"skill:Stealth d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 9,
		"name": "Attractive",
		"type": "(unknown)",
		"uuid": "5ea24350-6b10-4b8d-a55d-c56bf8fac093",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [
			"+2 charisma"
		],
		"specify": "",
		"summary": "+2 Charisma",
		"bookPage": "p35",
		"conflicts": [
			"hindrance:Ugly"
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"vigor d6"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 177,
		"name": "Attractive",
		"type": "(unknown)",
		"uuid": "9f4a1829-8215-48d2-3221-07addf9aa221",
		"group": "Background Edges",
		"active": true,
		"book_id": 9,
		"effects": [
			"skillbonus:Persuasion +1",
			"skillbonus:Performance +1"
		],
		"specify": "",
		"summary": "+1 to Persuasion rolls.",
		"bookPage": "p38",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Vigor d6"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 16,
		"name": "Beast Bond",
		"type": "(unknown)",
		"uuid": "cd2ce926-9841-4e0f-1cca-2820896cecaf",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "Character may spend Bennies for his animals",
		"bookPage": "p45",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 276,
		"name": "Beast Bond",
		"type": "(unknown)",
		"uuid": "fe74bae7-b5e3-4e5b-b1f8-a366f6fc5421",
		"group": "Weird Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "The hero may spend Bennies for animals under her control.",
		"bookPage": "p51",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 17,
		"name": "Beast Master",
		"type": "(unknown)",
		"uuid": "f8943391-436f-40dc-a86c-7ea48f833674",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "The hero gains an animal companion",
		"bookPage": "p45",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Spirit d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 277,
		"name": "Beast Master",
		"type": "(unknown)",
		"uuid": "7963a32f-6e5a-426d-02bc-65c8aa0ef31f",
		"group": "Weird Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Animals like your hero and he has a pet of some sort. See text.",
		"bookPage": "p51",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Spirit d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": true,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": null,
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": true,
		"provides_swade_super_powers": false
	},
	{
		"id": 11,
		"name": "Berserk",
		"type": "(unknown)",
		"uuid": "f769763b-a43b-4f84-8fdc-044899d4a5dc",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "See text",
		"bookPage": "p35",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 179,
		"name": "Berserk",
		"type": "(unknown)",
		"uuid": "2cf7cd51-12af-46ee-344a-0e986ce51eca",
		"group": "Background Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "After being Shaken or Wounded, melee attacks must be Wild Attacks, +2 to Toughness, ignore one level of Wound penalties, Critical Failure on Fighting roll hits random target. Lasts until there are no foes in sight or hero makes Smarts roll at –2.",
		"bookPage": "p38",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 12,
		"name": "Block",
		"type": "(unknown)",
		"uuid": "366e82d3-1e2b-4eee-b825-4454d86ae3df",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [
			"+1 parry"
		],
		"specify": "",
		"summary": "+1 Parry",
		"bookPage": "p37",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Seasoned",
			"skill:Fighting d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 195,
		"name": "Block",
		"type": "(unknown)",
		"uuid": "28a5d684-312d-4a38-0bae-9af18009d00f",
		"group": "Combat Edges",
		"active": true,
		"book_id": 9,
		"effects": [
			"+1 parry"
		],
		"specify": "",
		"summary": "+1 Parry, ignore 1 point of Gang Up bonus.",
		"bookPage": "p40",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"skill:Fighting d8",
			"Seasoned"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 264,
		"name": "Bolster",
		"type": "(unknown)",
		"uuid": "c8cf3f6e-1432-4434-12ef-eb2aa1c6ba1d",
		"group": "Social Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "May remove Distracted or Vulnerable state after a Test.",
		"bookPage": "p50",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Spirit d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 14,
		"name": "Brave",
		"type": "(unknown)",
		"uuid": "f26f63d9-2c57-4cf6-0b68-9f94c55e7334",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "+2 to Fear tests",
		"bookPage": "p35",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 180,
		"name": "Brave",
		"type": "(unknown)",
		"uuid": "175481f6-aa09-4ac7-0d0b-b733418c67e0",
		"group": "Background Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "+2 to Fear tests and –2 to rolls on the Fear Table.",
		"bookPage": "p38",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Spirit d6"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 43,
		"name": "Brawler",
		"type": "(unknown)",
		"uuid": "b797e02a-f98a-4793-19ba-0d371c8be5db",
		"group": "Combat",
		"active": true,
		"book_id": 2,
		"effects": [
			"+2 unarmeddamage"
		],
		"specify": "",
		"summary": "+2 to unarmed damage rolls",
		"bookPage": "p37",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"strength d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 197,
		"name": "Brawler",
		"type": "(unknown)",
		"uuid": "041fc5a9-f8e9-45df-baa8-035c0d4f9321",
		"group": "Combat Edges",
		"active": true,
		"book_id": 9,
		"effects": [
			"+1 toughness",
			"step_unarmed_damage"
		],
		"specify": "",
		"summary": "Toughness +1, add d4 to unarmed damage or increase it a step if combined with Martial Artist, Claws, etc.",
		"bookPage": "p40",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Strength d8",
			"Vigor d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 15,
		"name": "Brawny",
		"type": "(unknown)",
		"uuid": "6adab35e-b86d-4c34-8c4d-23015b87997d",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [
			"LoadLimitMultiplier: 8",
			"toughness +1"
		],
		"specify": "",
		"summary": "Toughness +1; load limit is 8 x Str",
		"bookPage": "p36",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Strength d6",
			"Vigor d6"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 181,
		"name": "Brawny",
		"type": "(unknown)",
		"uuid": "b8288dcb-d6dc-4bed-bffe-bf9a755b5181",
		"group": "Background Edges",
		"active": true,
		"book_id": 9,
		"effects": [
			"size +1",
			"min_str_mod +1",
			"enc_str_mod +1"
		],
		"specify": "",
		"summary": "Size (and therefore Toughness) +1. Treat Minimum Strength requirements as one die type less; treat Strength as one die type higher for Encumbrance.",
		"bookPage": "p38",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Strength d6",
			"Vigor d6"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 44,
		"name": "Bruiser",
		"type": "(unknown)",
		"uuid": "1dd23584-7c51-4b46-2ca4-06978a07c479",
		"group": "Combat",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "Bonus die to unarmed damage is d8 instead of d6",
		"bookPage": "p37",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Seasoned",
			"edge:Brawler"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 198,
		"name": "Bruiser",
		"type": "(unknown)",
		"uuid": "e19c5d5c-9b7b-4403-284e-26f7ee47c6b0",
		"group": "Combat Edges",
		"active": true,
		"book_id": 9,
		"effects": [
			"+1 toughness",
			"step_unarmed_damage"
		],
		"specify": "",
		"summary": "Increase unarmed Strength damage a die type and Toughness another +1.",
		"bookPage": "p40",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Seasoned",
			"edge:Brawler"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 182,
		"name": "Brute",
		"type": "(unknown)",
		"uuid": "f07adc9e-f254-4a33-2a4e-0c40ededa81e",
		"group": "Background Edges",
		"active": true,
		"book_id": 9,
		"effects": [
			"switch_skill_attribute:athletics strength",
			"+1 thrown_weapon_range"
		],
		"specify": "",
		"summary": "Link Athletics to Strength instead of Agility. This includes resisting Athletics Tests as well.",
		"bookPage": "p38",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Strength d6",
			"Vigor d6"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 199,
		"name": "Calculating",
		"type": "(unknown)",
		"uuid": "98ff0aa0-e4aa-4fa0-257a-5cf01a36e333",
		"group": "Combat Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Ignore up to 2 points of penalties on one action with an Action Card of Five or less.",
		"bookPage": "p41",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Smarts d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 20,
		"name": "Champion",
		"type": "(unknown)",
		"uuid": "ed355d57-e268-41b9-8cbb-f8e87e0c33bb",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "+2 damage / Toughness vs. supernatural evil",
		"bookPage": "p42",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"arcanebackground:Miracles",
			"Spirit d8",
			"Strength d6",
			"Vigor d8",
			"skill:Faith d6",
			"skill:Fighting d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 278,
		"name": "Champion",
		"type": "(unknown)",
		"uuid": "eca6c7e0-1f30-4ff9-b4cc-df9503d18e5b",
		"group": "Weird Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "+2 damage vs. supernaturally evil creatures.",
		"bookPage": "p52",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Spirit d8",
			"skill:Fighting d6"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 251,
		"name": "Channeling",
		"type": "(unknown)",
		"uuid": "e5da61b5-a59e-45ed-35ae-b9a7547ec717",
		"group": "Power Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Reduce Power Point cost by 1 with a raise on the activation roll.",
		"bookPage": "p46",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Seasoned",
			"Arcane Background"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 45,
		"name": "Charismatic",
		"type": "(unknown)",
		"uuid": "991f5ca8-77e0-4b13-3b75-cab70c39765d",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [
			"Charisma +2"
		],
		"specify": "",
		"summary": "Charisma +2",
		"bookPage": "p44",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Spirit d6"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 183,
		"name": "Charismatic",
		"type": "(unknown)",
		"uuid": "ef72a41c-96df-4f4f-0c44-c6a31349797e",
		"group": "Background Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Reroll a Persuasion test as long as it’s not a Critical Failure.",
		"bookPage": "p38",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Spirit d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 279,
		"name": "Chi",
		"type": "(unknown)",
		"uuid": "a71ee502-337f-41f6-284f-387efcf3b6ce",
		"group": "Weird Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Once per combat, reroll failed attack, make enemy reroll successful attack, or add +d6 to unarmed Fighting attack.",
		"bookPage": "p52",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Veteran",
			"edge:Martial Warrior"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 305,
		"name": "Combat Acrobat",
		"type": "(unknown)",
		"uuid": "fef626e3-00f5-491e-3933-8fa97712bf87",
		"group": "Professional Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "−1 to hit with ranged and melee attacks.",
		"bookPage": "p48",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Seasoned",
			"edge:Acrobat"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 58,
		"name": "Combat Reflexes",
		"type": "(unknown)",
		"uuid": "a1c333dc-5993-4ce7-31d6-ab944b3408f6",
		"group": "Combat",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "+2 to recover from being Shaken",
		"bookPage": "p37",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Seasoned"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 200,
		"name": "Combat Reflexes",
		"type": "(unknown)",
		"uuid": "a2484f23-34c9-4268-3b48-3d059a6c6159",
		"group": "Combat Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "+2 Spirit to recover from being Shaken.",
		"bookPage": "p41",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Seasoned"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 21,
		"name": "Command",
		"type": "(unknown)",
		"uuid": "269494dd-bfa9-44cc-014c-e96eefe14987",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "+1 to troops recovering from being Shaken",
		"bookPage": "p40",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Smart d6"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 242,
		"name": "Command",
		"type": "(unknown)",
		"uuid": "7931dff4-03bb-40da-8dfe-72e80bbd010a",
		"group": "Leadership Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "+1 to Extras’ Spirit rolls and +1 to Vigor rolls to recover from Shaken or Stunned in Command Range.",
		"bookPage": "p45",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Smarts d6"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": null,
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 22,
		"name": "Command Presence",
		"type": "(unknown)",
		"uuid": "22d85f91-f209-483e-3dca-12ad6692bd84",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "Increase command radius to 10”",
		"bookPage": "p40",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"edge:Command"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 243,
		"name": "Command Presence",
		"type": "(unknown)",
		"uuid": "e9c4680f-6b8d-43a4-2c76-99ab1610b4e8",
		"group": "Leadership Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Increase Command Range to 10\" (20 yards)",
		"bookPage": "p45",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Seasoned",
			"edge:Command"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 47,
		"name": "Common Bond",
		"type": "(unknown)",
		"uuid": "f308bd1e-9774-40d7-3a13-08d767864132",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "May give Bennies to companions",
		"bookPage": "p44",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Wild Card",
			"Spirit d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 265,
		"name": "Common Bond",
		"type": "(unknown)",
		"uuid": "6fcf348a-2229-4ad4-95cc-fec50c46347e",
		"group": "Social Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "The hero may freely give her Bennies to others.",
		"bookPage": "p50",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Wild Card",
			"Novice",
			"Spirit d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 252,
		"name": "Concentration",
		"type": "(unknown)",
		"uuid": "a32fb549-5fe5-4d4c-0639-5d4472c09363",
		"group": "Power Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Double Duration of non-Instant powers.",
		"bookPage": "p46",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Seasoned"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 46,
		"name": "Connections",
		"type": "(unknown)",
		"uuid": "ebfbad9c-43bc-4261-a706-9b40b8169d6a",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "Call upon powerful friends",
		"bookPage": "p44",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 266,
		"name": "Connections",
		"type": "(unknown)",
		"uuid": "fb9fd864-003c-469b-0ded-57bbb4334a8f",
		"group": "Social Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Contacts provide aid or other favors once per session.",
		"bookPage": "p50",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": true,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": true,
		"provides_swade_super_powers": false
	},
	{
		"id": 59,
		"name": "Counterattack",
		"type": "(unknown)",
		"uuid": "3b2d0305-cf29-467b-0aa7-28cba0bd0371",
		"group": "Combat",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "Receive free Fighting attack at –2 once per round when a foe fails a Fighting attack",
		"bookPage": "p37",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Seasoned",
			"skill:Fighting d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 201,
		"name": "Counterattack",
		"type": "(unknown)",
		"uuid": "cf258bd3-f6a5-43db-a8d9-f539051ac17b",
		"group": "Combat Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Free attack against one foe per round who failed a Fighting roll.",
		"bookPage": "p41",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Seasoned",
			"Fighting d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": null,
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 18,
		"name": "Danger Sense",
		"type": "(unknown)",
		"uuid": "03320ca4-29d2-478d-b789-96de660cad1f",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "Notice at –2 to detect surprise attacks/danger",
		"bookPage": "p45",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 280,
		"name": "Danger Sense",
		"type": "(unknown)",
		"uuid": "caa65a78-4726-41ab-10a4-9f4198308e96",
		"group": "Weird Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Notice roll at +2 to sense ambushes or similar events.",
		"bookPage": "p52",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 95,
		"name": "Dead Shot",
		"type": "(unknown)",
		"uuid": "3b27489f-4fac-4e40-a001-12460b4d1294",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "Double ranged damage when dealt Joker",
		"bookPage": "p45",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Wild Card",
			"Seasoned",
			"skill:Throwing|Shooting d10"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 203,
		"name": "Dead Shot",
		"type": "(unknown)",
		"uuid": "6aba339a-56f2-40d2-abb8-8064028e6b20",
		"group": "Combat Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Once per turn, double damage from Athletics (throwing) or Shooting roll when dealt a Joker.",
		"bookPage": "p41",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Wild Card",
			"Novice",
			"skill:Athletics|Shooting d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 60,
		"name": "Dodge",
		"type": "(unknown)",
		"uuid": "e31647dc-4e21-4d52-855e-89a354c02556",
		"group": "Combat",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "–1 to be hit with ranged attacks",
		"bookPage": "p37",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Seasoned",
			"Agility d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 204,
		"name": "Dodge",
		"type": "(unknown)",
		"uuid": "bb3b4816-6138-4f31-ae4e-e45cc72f80d0",
		"group": "Combat Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "−2 to be hit by ranged attacks.",
		"bookPage": "p41",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Seasoned",
			"Agility d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 206,
		"name": "Double Tap",
		"type": "(unknown)",
		"uuid": "d4e3ed4c-f73d-422f-a3fe-d2b269eaf224",
		"group": "Combat Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "+1 to hit and damage when firing no more than RoF 1 per action.",
		"bookPage": "p41",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Seasoned",
			"Shooting d6"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 62,
		"name": "Elan",
		"type": "(unknown)",
		"uuid": "96efd4db-b613-4611-077c-9563ecc84301",
		"group": "Combat",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "+2 when spending a Benny on a Trait roll (including Soak rolls)",
		"bookPage": "p37",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Spirit d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 184,
		"name": "Elan",
		"type": "(unknown)",
		"uuid": "758f354b-3c93-40b4-96ea-d1e410d983d0",
		"group": "Background Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "+2 when spending a Benny to reroll a Trait roll.",
		"bookPage": "p39",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Spirit d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 91,
		"name": "Expert",
		"type": "(unknown)",
		"uuid": "e040e6fe-8be2-4fc9-9ee0-ad622c6417ca",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [
			"[selecttrait]: d12+2"
		],
		"specify": "",
		"summary": "Trait becomes d12+2",
		"bookPage": "p46",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Legendary",
			"[selecttrait] d12+1",
			"edge:Professional"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 286,
		"name": "Expert",
		"type": "(unknown)",
		"uuid": "22c410c5-325f-4874-37e7-0d0f1f82bf9a",
		"group": "Legendary Edges",
		"active": true,
		"book_id": 9,
		"effects": [
			"boost_trait:[selected_trait] 1"
		],
		"specify": "",
		"summary": "The character’s Trait limit is d12+2.",
		"bookPage": "p53",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"edge:Professional",
			"max_level:[selected_trait]"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": null,
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": true,
		"provides_swade_super_powers": false
	},
	{
		"id": 253,
		"name": "Extra Effort",
		"type": "(unknown)",
		"uuid": "447ce76f-6e77-49a2-bb6f-5543aa05335e",
		"group": "Power Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Increase Focus total by +1 for 1 Power Point or +3 for 3 Power Points.",
		"bookPage": "p46",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Focus d6",
			"Arcane Background",
			"Seasoned"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 63,
		"name": "Extraction",
		"type": "(unknown)",
		"uuid": "81933a8e-7372-4892-2720-f05408175336",
		"group": "Combat",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "Ignore one foe’s free attack when withdrawing from melee with an Agility roll",
		"bookPage": "p38",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Agility d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 207,
		"name": "Extraction",
		"type": "(unknown)",
		"uuid": "fbe42917-44e5-4403-3416-a3c752553c4e",
		"group": "Combat Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "One adjacent foe doesn’t get a free attack when you withdraw from close combat.",
		"bookPage": "p41",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Agility d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 185,
		"name": "Fame",
		"type": "(unknown)",
		"uuid": "908cfb21-01f7-4b67-881f-1cabd6132be9",
		"group": "Background Edges",
		"active": true,
		"book_id": 9,
		"effects": [
			"+1 wealth_die_bonus"
		],
		"specify": "",
		"summary": "+1 Persuasion rolls when recognized (Common Knowledge), double usual fee for Performance.",
		"bookPage": "p39",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"customDescription": [
				""
			]
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"customDescription": [
				""
			]
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"customDescription": []
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": null,
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 186,
		"name": "Famous",
		"type": "(unknown)",
		"uuid": "4a282d33-562c-4dcf-8aaf-62a96c94589c",
		"group": "Background Edges",
		"active": true,
		"book_id": 9,
		"effects": [
			"+2 wealth_die_bonus"
		],
		"specify": "",
		"summary": "+1 Persuasion when recognized, 5× or more usual fee for Performance.",
		"bookPage": "p39",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Seasoned",
			"edge:Fame"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"customDescription": [
				""
			]
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"customDescription": [
				""
			]
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"customDescription": []
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": null,
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 29,
		"name": "Fast Healer",
		"type": "(unknown)",
		"uuid": "134b6fe3-f2cf-4381-00ff-03ba56b1b61e",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "+2 to Vigor rolls for natural healing",
		"bookPage": "p36",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Vigor d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 194,
		"name": "Fast Healer",
		"type": "(unknown)",
		"uuid": "e2cdf42a-da36-4bc3-bc61-b0767407e835",
		"group": "Background Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "+2 Vigor when rolling for natural healing.",
		"bookPage": "p39",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Vigor d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 209,
		"name": "Feint",
		"type": "(unknown)",
		"uuid": "8446614d-2e7c-474a-17a1-3036d7e6a494",
		"group": "Combat Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "You may choose to make foe resist with Smarts instead of Agility during a Fighting Test.",
		"bookPage": "p42",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Fighting d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 23,
		"name": "Fervor",
		"type": "(unknown)",
		"uuid": "db7ab389-487d-4de8-a8e7-39926ab3ee3a",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "+1 melee damage to troops in command",
		"bookPage": "p40",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Veteran",
			"edge:Command",
			"Spirit d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 244,
		"name": "Fervor",
		"type": "(unknown)",
		"uuid": "9a587f9a-11b4-4762-2d78-90a9bb638644",
		"group": "Leadership Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "+1 to Extras’ Fighting rolls in Command Range.",
		"bookPage": "p45",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Veteran",
			"Spirit d8",
			"edge:Command"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 101,
		"name": "Filthy Rich",
		"type": "(unknown)",
		"uuid": "53fedab5-768c-4139-2a24-b73d6c334403",
		"group": "Background",
		"active": true,
		"book_id": 2,
		"effects": [
			"starting_funds_multiplier: 5"
		],
		"specify": "",
		"summary": "",
		"bookPage": "p36",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"edge:rich"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 193,
		"name": "Filthy Rich",
		"type": "(unknown)",
		"uuid": "f83e383a-6702-4a0b-32a8-7cfd3ab05fdf",
		"group": "Background Edges",
		"active": true,
		"book_id": 9,
		"effects": [
			"starting_funds_multiplier: 5",
			"wealth_die: d10"
		],
		"specify": "",
		"summary": "Character starts with five times the starting funds and a $500K annual salary.",
		"bookPage": "p40",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"novice",
			"edge:rich"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"customDescription": [
				""
			]
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"customDescription": [
				""
			]
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"customDescription": [
				""
			]
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": null,
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 65,
		"name": "First Strike",
		"type": "(unknown)",
		"uuid": "f84e44fe-674c-4844-0815-2e3ddacc570c",
		"group": "Combat",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "May attack one foe who moves adjacent",
		"bookPage": "p38",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Agility d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 210,
		"name": "First Strike",
		"type": "(unknown)",
		"uuid": "1db3c683-de30-44b4-33fb-ce04861c632f",
		"group": "Combat Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Free Fighting attack once per round when foe moves adjacent to you.",
		"bookPage": "p42",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Agility d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 42,
		"name": "Fleet-Footed",
		"type": "(unknown)",
		"uuid": "30af236e-0ac5-4375-373e-4c9fde775f27",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [
			"Pace +2",
			"RunningDie: d10"
		],
		"specify": "",
		"summary": "+2 pace, d10 running die",
		"bookPage": "p36",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Agility d6"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 187,
		"name": "Fleet-Footed",
		"type": "(unknown)",
		"uuid": "201a174f-04d6-403e-a1cf-459611aefeac",
		"group": "Background Edges",
		"active": true,
		"book_id": 9,
		"effects": [
			"Pace +2",
			"running_die +1"
		],
		"specify": "",
		"summary": "Pace +2, increase running die one step",
		"bookPage": "p39",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Agility d6"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": null,
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 67,
		"name": "Florentine",
		"type": "(unknown)",
		"uuid": "56e56ad2-e524-4c0c-24af-5f86b9239ce7",
		"group": "Combat",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "+1 vs. foes with single weapon and no shield; ignore 1 point of gang up bonus",
		"bookPage": "p38",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Agility d8",
			"skill:Fighting d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 89,
		"name": "Followers",
		"type": "(unknown)",
		"uuid": "de8c7fa9-6f48-4cd9-2930-260f44777a9d",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "Attract 5 henchmen",
		"bookPage": "p46",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Wild Card",
			"Legendary"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 284,
		"name": "Followers",
		"type": "(unknown)",
		"uuid": "7e6a3673-cf79-44ac-2402-ce34f34f1958",
		"group": "Legendary Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "The hero has five followers.",
		"bookPage": "p52",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Wild Card",
			"Legendary"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 212,
		"name": "Free Runner",
		"type": "(unknown)",
		"uuid": "5c044987-a95d-42db-11f9-16119db0564a",
		"group": "Combat Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Ignore Difficult Ground and add +2 to Athletics in foot chases.",
		"bookPage": "p42",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Agility d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 68,
		"name": "Frenzy",
		"type": "(unknown)",
		"uuid": "693eb4cc-39e5-4384-b106-87dca64ab261",
		"group": "Combat",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "1 extra Fighting attack at –2",
		"bookPage": "p38",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Seasoned",
			"skill:Fighting d10"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 213,
		"name": "Frenzy",
		"type": "(unknown)",
		"uuid": "dff6a708-c744-47b2-2550-e489d9deeb01",
		"group": "Combat Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Roll a second Fighting die with one melee attack per turn.",
		"bookPage": "p42",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Seasoned",
			"Fighting d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 167,
		"name": "Gadgeteer",
		"type": "(unknown)",
		"uuid": "aa44967a-fcc6-4a64-2fbd-8256256cad89",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "May “jury-rig” a device once per game session",
		"bookPage": "p42",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Smarts d8",
			"Arcane Background (Weird Science)",
			"skill:Repair d8",
			""
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": null,
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 304,
		"name": "Gadgeteer",
		"type": "(unknown)",
		"uuid": "c3d7d47c-d3f8-4506-3961-e96bdc83a6a1",
		"group": "Power Edges",
		"active": true,
		"book_id": 9,
		"effects": [
			"artificer"
		],
		"specify": "",
		"summary": "Spend 3 Power Points to create a device that replicates another power.",
		"bookPage": "p46",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Seasoned",
			"Arcane Background (Weird Science)",
			"skill:Weird Science d6"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": null,
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 69,
		"name": "Giant Killer",
		"type": "(unknown)",
		"uuid": "16ce1538-45bb-4665-2f8a-2ee0d774490f",
		"group": "Combat",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "+1d6 damage when attacking large creatures",
		"bookPage": "p38",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Veteran"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 215,
		"name": "Giant Killer",
		"type": "(unknown)",
		"uuid": "1cc9b6f3-a579-42eb-314b-fd58f9ba6e0a",
		"group": "Combat Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "+1d6 damage vs. creatures three Sizes larger or more.",
		"bookPage": "p42",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Veteran"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 32,
		"name": "Great Luck",
		"type": "(unknown)",
		"uuid": "7e21d0f6-e4a9-4bda-802c-ac8c6a4711ae",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [
			"+1 bennie"
		],
		"specify": "",
		"summary": "+1 additional bennie",
		"bookPage": "p36",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"edge:Luck"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 190,
		"name": "Great Luck",
		"type": "(unknown)",
		"uuid": "7a88dbdb-7dcb-4ccf-978f-96159692e4db",
		"group": "Background Edges",
		"active": true,
		"book_id": 9,
		"effects": [
			"+1 Benny"
		],
		"specify": "",
		"summary": "+2 Bennies at the start of each session.",
		"bookPage": "p40",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"edge:Luck"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 70,
		"name": "Hard to Kill",
		"type": "(unknown)",
		"uuid": "889e39a5-a2ab-4dba-39b4-825ca008c0cd",
		"group": "Combat",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "Ignore wound penalties for Vigor rolls made on the Injury Table",
		"bookPage": "p38",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Wild Card",
			"Spirit d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 216,
		"name": "Hard to Kill",
		"type": "(unknown)",
		"uuid": "82ff01a7-9c78-4af7-1471-c771d73ddad4",
		"group": "Combat Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Ignore Wound penalties when making Vigor rolls to avoid Bleeding Out.",
		"bookPage": "p42",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Spirit d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 71,
		"name": "Harder to Kill",
		"type": "(unknown)",
		"uuid": "95b8eef2-afa0-49f8-2792-84c410408c5a",
		"group": "Combat",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "50% chance of surviving “death”",
		"bookPage": "p39",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Veteran",
			"edge:Hard to Kill"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 217,
		"name": "Harder to Kill",
		"type": "(unknown)",
		"uuid": "c818fc7f-e48b-4683-b784-322a4f46d476",
		"group": "Combat Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Roll a die if the character perishes. Even if he’s Incapacitated, he survives somehow.",
		"bookPage": "p42",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Veteran",
			"edge:Hard to Kill"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 19,
		"name": "Healer",
		"type": "(unknown)",
		"uuid": "6bfb5d18-7a78-48ea-936c-2da0d5d7331d",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [
			"skillbonus: +2 healing"
		],
		"specify": "",
		"summary": "+2 Healing",
		"bookPage": "p45",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Spirit d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 281,
		"name": "Healer",
		"type": "(unknown)",
		"uuid": "eca4e54d-bf59-4d0b-05a6-507cb9fde93f",
		"group": "Weird Edges",
		"active": true,
		"book_id": 9,
		"effects": [
			"skill_bonus:Healing 2"
		],
		"specify": "",
		"summary": "+2 to Healing rolls, magical or otherwise.",
		"bookPage": "p52",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Spirit d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 245,
		"name": "Hold the Line",
		"type": "(unknown)",
		"uuid": "140574f0-93e1-4589-be80-19b84c1bd314",
		"group": "Leadership Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "+1 to Extras’ Toughness in Command Range.",
		"bookPage": "p45",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Seasoned",
			"Smarts d8",
			"edge:Command"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 24,
		"name": "Hold the Line!",
		"type": "(unknown)",
		"uuid": "f565bd03-b141-4561-9e68-18b77d010252",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "troops under command add +1 to their Toughness",
		"bookPage": "p40",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Seasoned",
			"edge:Command",
			"Smarts d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 49,
		"name": "Holy/Unholy Warrior",
		"type": "(unknown)",
		"uuid": "6b7a43d0-0c55-40cc-887d-9edcbb19488b",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "See text p42",
		"bookPage": "p42",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"arcane_background:Miracles",
			"Spirit d8",
			"skill:Faith d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": true,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": null,
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 255,
		"name": "Holy/Unholy Warrior",
		"type": "(unknown)",
		"uuid": "6fdb5190-524b-44d6-377e-60fbd4a7516c",
		"group": "Power Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Add +1 to +4 to Soak rolls for each Power Point spent.",
		"bookPage": "p46",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Seasoned",
			"Arcane Background (Miracles)",
			"skill:Faith d6"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 267,
		"name": "Humiliate",
		"type": "(unknown)",
		"uuid": "1fd5fd02-d0bb-4b6f-2911-0a1319d6dfb9",
		"group": "Social Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Free reroll when making Taunt rolls.",
		"bookPage": "p50",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"skill:Taunt d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 8,
		"name": "Improved Arcane Resistance",
		"type": "(unknown)",
		"uuid": "7515d4c7-5da2-4a1f-b482-1233277d88ca",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "Armor 4 vs. magic, +4 to resist magic effects",
		"bookPage": "p35",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"edge:Arcane Resistance"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 175,
		"name": "Improved Arcane Resistance",
		"type": "(unknown)",
		"uuid": "fadcb99b-c292-44b8-0943-4f82e404c977",
		"group": "Background Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "+4 to Trait rolls to resist magical effects; magical damage is reduced by 4.",
		"bookPage": "p37",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"edge:Arcane Resistance"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 13,
		"name": "Improved Block",
		"type": "(unknown)",
		"uuid": "6c7c009b-84ed-4053-0a12-f40fb534d5ef",
		"group": "Combat",
		"active": true,
		"book_id": 2,
		"effects": [
			"+1 Parry"
		],
		"specify": "",
		"summary": "Additional +1 Parry",
		"bookPage": "p37",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"edge:block"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 196,
		"name": "Improved Block",
		"type": "(unknown)",
		"uuid": "c2d77cca-e6f3-4909-bf35-eadbf13f203a",
		"group": "Combat Edges",
		"active": true,
		"book_id": 9,
		"effects": [
			"+1 parry"
		],
		"specify": "",
		"summary": "+2 Parry, ignore 2 points of Gang Up bonus.",
		"bookPage": "p40",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"veteran",
			"edge:Block"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": null,
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 40,
		"name": "Improved Counterattack",
		"type": "(unknown)",
		"uuid": "c02332ce-2651-45e4-b721-1ffb8e6ea65c",
		"group": "Combat",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "As above but ignore the –2 penalty",
		"bookPage": "p37",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Veteran",
			"edge:Counterattack"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 202,
		"name": "Improved Counterattack",
		"type": "(unknown)",
		"uuid": "f2ac619e-c73a-423e-bb81-b1fcf84db50d",
		"group": "Combat Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "As Counterattack, but against three foes per round.",
		"bookPage": "p41",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Veteran",
			"edge:Counterattack"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": null,
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 61,
		"name": "Improved Dodge",
		"type": "(unknown)",
		"uuid": "6cdb2fb5-b549-44f2-9129-b6846d2613c5",
		"group": "Combat",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "additional –1 to be hit with ranged attacks",
		"bookPage": "p37",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Veteran",
			"edge:Dodge"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 205,
		"name": "Improved Dodge",
		"type": "(unknown)",
		"uuid": "c9c30ecd-46e8-47f3-1db8-d4c437598323",
		"group": "Combat Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "+2 to Evasion totals",
		"bookPage": "p41",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Seasoned",
			"edge:Dodge"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": null,
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 64,
		"name": "Improved Extraction",
		"type": "(unknown)",
		"uuid": "95878790-fd07-4f1b-1c60-c57a6a819ad8",
		"group": "Combat",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "Improved Extraction",
		"bookPage": "p38",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"edge:Extraction"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 208,
		"name": "Improved Extraction",
		"type": "(unknown)",
		"uuid": "69efc36a-a8db-4f68-8e23-5a8664a51900",
		"group": "Combat Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Three adjacent foes don’t get free attacks when you withdraw from combat",
		"bookPage": "p41",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Seasoned",
			"edge:Extraction"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 66,
		"name": "Improved First Strike",
		"type": "(unknown)",
		"uuid": "1837deb4-f4c6-46b8-8f51-7d0ded22a968",
		"group": "Combat",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "May attack every foe who moves adjacent",
		"bookPage": "p38",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"edge:First Strike"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 211,
		"name": "Improved First Strike",
		"type": "(unknown)",
		"uuid": "2786aae8-29da-4eae-0b44-4d166159317b",
		"group": "Combat Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Free Fighting attack against up to three foes when they move adjacent.",
		"bookPage": "p42",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Heroic",
			"edge:First Strike"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 7,
		"name": "Improved Frenzy",
		"type": "(unknown)",
		"uuid": "db2f885c-21ca-4d62-26cf-3cf55fe51858",
		"group": "Combat",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "As above but ignore the –2 Frenzy penalty.",
		"bookPage": "p38",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Veteran",
			"edge:Frenzy"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 214,
		"name": "Improved Frenzy",
		"type": "(unknown)",
		"uuid": "3b874ddd-b035-441a-98ed-3bb52a3d3bdc",
		"group": "Combat Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Roll a second Fighting die with up to two melee attacks per turn.",
		"bookPage": "p42",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Veteran",
			"edge:Frenzy"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 75,
		"name": "Improved Level Headed",
		"type": "(unknown)",
		"uuid": "e8f731cd-9b54-48b4-3b3c-d86a54c8b297",
		"group": "Combat",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "As above but the hero draws 3 cards.",
		"bookPage": "p39",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"edge:Level Headed"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 222,
		"name": "Improved Level Headed",
		"type": "(unknown)",
		"uuid": "4918f8ce-4090-4ccc-3367-4c3b1c2652ad",
		"group": "Combat Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Draw three Action Cards each round and choose which one to use.",
		"bookPage": "p42",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Seasoned",
			"edge:Level Headed"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 78,
		"name": "Improved Martial Artist",
		"type": "(unknown)",
		"uuid": "06b4da3d-be5f-4d99-8314-40823ba6753a",
		"group": "Combat",
		"active": true,
		"book_id": 2,
		"effects": [
			"naturalweapon: Unarmed;Melee;Str+d6"
		],
		"specify": "",
		"summary": "+d6 to unarmed damage rolls",
		"bookPage": "p39",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Veteran",
			"edge:Martial Artist",
			"skill:Fighting d10"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": null,
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 81,
		"name": "Improved Nerves of Steel",
		"type": "(unknown)",
		"uuid": "f7c71d8a-2d73-4405-198d-fe5dfd2cf735",
		"group": "Combat",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "Ignore 2 points of wound penalties",
		"bookPage": "p39",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"edge:Nerves of Steel"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 228,
		"name": "Improved Nerves of Steel",
		"type": "(unknown)",
		"uuid": "98301e53-564a-4fbd-2a32-29ae865921b7",
		"group": "Combat Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Ignore up to two levels of Wound penalties.",
		"bookPage": "p43",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"edge:Nerves of Steel"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 233,
		"name": "Improved Rapid Fire",
		"type": "(unknown)",
		"uuid": "34030865-48f5-4650-a90c-9db42899687b",
		"group": "Combat Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Increase RoF by 1 for up to two Shooting attacks per turn.",
		"bookPage": "p43",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"edge:Rapid Fire",
			"Veteran"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 104,
		"name": "Improved Rapid Recharge",
		"type": "(unknown)",
		"uuid": "c66eb9eb-702b-48f2-2cc6-d48f8f2f5706",
		"group": "Power",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "Regain 1 Power Point every 15 minutes",
		"bookPage": "p41",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"edge:Rapid Recharge",
			"edge:Arcane Background"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 260,
		"name": "Improved Rapid Recharge",
		"type": "(unknown)",
		"uuid": "47ca59b2-8cc6-4841-9ff6-43ebcedd0539",
		"group": "Power Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Recover 20 Power Points per hour.",
		"bookPage": "p47",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Veteran",
			"edge:Rapid Recharge"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": true,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": null,
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 38,
		"name": "Improved Sweep",
		"type": "(unknown)",
		"uuid": "d35602c4-ca22-4536-ade1-954d4748327b",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "As sweep but with no penalty",
		"bookPage": "p39",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Veteran",
			"edge:Sweep"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 237,
		"name": "Improved Sweep",
		"type": "(unknown)",
		"uuid": "d3467a40-c194-4282-9cd3-93809869e592",
		"group": "Combat Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "As above, but ignore the –2 penalty",
		"bookPage": "p44",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Veteran",
			"edge:Sweep"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 165,
		"name": "Improved Tough as Nails",
		"type": "(unknown)",
		"uuid": "e9351c68-8439-404b-9e77-32d85c63f60d",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [
			"+1 toughness"
		],
		"specify": "",
		"summary": "+2 total toughness",
		"bookPage": "p46",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Legendary",
			"edge:Tough as Nails"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 84,
		"name": "Improved Trademark Weapon",
		"type": "(unknown)",
		"uuid": "79dac71d-43fa-48fc-06d4-93156500c2cf",
		"group": "Combat",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "additional +1 (+2 total) Fighting or Shooting with particular weapon",
		"bookPage": "p39",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Veteran",
			"edge:Trademark Weapon"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 239,
		"name": "Improved Trademark Weapon",
		"type": "(unknown)",
		"uuid": "4d8f8e62-ef7e-42f1-bc73-1aaecf5835c3",
		"group": "Combat Edges",
		"active": true,
		"book_id": 9,
		"effects": [
			"selected_weapon_parry:1",
			"selected_weapon_to_hit:1"
		],
		"specify": "",
		"summary": "The attack and Parry bonus increases to +2.",
		"bookPage": "p44",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Seasoned",
			"edge:Trademark Weapon"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": true,
		"selected_ability_item": null,
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": true,
		"provides_swade_super_powers": false
	},
	{
		"id": 72,
		"name": "Improvisational Fighter",
		"type": "(unknown)",
		"uuid": "4f0439ab-ffff-4687-8160-404220693a38",
		"group": "Combat",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "Ignores the usual –1 penalty to attack and Parry for improvised weapons",
		"bookPage": "p39",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Seasoned",
			"Smarts d6"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 218,
		"name": "Improvisational Fighter",
		"type": "(unknown)",
		"uuid": "cbfc8fc4-9777-415d-a426-74d1f87bb9e3",
		"group": "Combat Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Ignore penalties to attack and Parry when using Improvised weapons.",
		"bookPage": "p42",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Seasoned",
			"Smarts d6"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 25,
		"name": "Inspire",
		"type": "(unknown)",
		"uuid": "9b59218f-bab6-43e3-106e-9f82aa18cfbe",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "+1 to Spirit rolls of all troops in command",
		"bookPage": "p40",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Seasoned",
			"edge:Command"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 246,
		"name": "Inspire",
		"type": "(unknown)",
		"uuid": "d4d46c62-6d2d-4980-15b3-1dab052d719d",
		"group": "Leadership Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Once per turn, the hero may roll his Battle skill to Support one type of Trait roll, and apply it to everyone in Command Range",
		"bookPage": "p45",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Seasoned",
			"edge:Command"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": null,
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 50,
		"name": "Investigator",
		"type": "(unknown)",
		"uuid": "ad4cb224-ec7c-4d63-8f54-0095fd032243",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [
			"skillbonus: +2 Investigation",
			"skillbonus: +2 Streetwise"
		],
		"specify": "",
		"summary": "+2 Investigation and Streetwise",
		"bookPage": "p43",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Smarts d8",
			"skill:Investigation d8",
			"skill:Streetwise d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 295,
		"name": "Investigator",
		"type": "(unknown)",
		"uuid": "3558e92c-2c7a-442f-0c66-b212b91945dd",
		"group": "Professional Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "+2 to Research and certain types of Notice rolls.",
		"bookPage": "p48",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Smarts d8",
			"Research d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 219,
		"name": "Iron Jaw",
		"type": "(unknown)",
		"uuid": "c48c51ce-1301-4400-abcc-7cb50c2ce2c1",
		"group": "Combat Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "+2 to Soak and Vigor rolls to avoid Knockout Blows.",
		"bookPage": "p42",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Vigor d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 273,
		"name": "Iron Will",
		"type": "(unknown)",
		"uuid": "c606690a-4bf9-428e-b445-ff36a6787d1a",
		"group": "Social Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "+4 to resist Smarts or Spirit-based Tests.",
		"bookPage": "p51",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"edge:Strong Willed",
			"Novice"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 51,
		"name": "Jack-of-All-Trades",
		"type": "(unknown)",
		"uuid": "7e1a0288-548f-4562-a0f0-323f9a68e459",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "No –2 for unskilled Smarts-based tests",
		"bookPage": "p43",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Smarts d10"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 297,
		"name": "Jack-of-all-Trades",
		"type": "(unknown)",
		"uuid": "c5e04176-25b8-430e-1872-0c53d7af104f",
		"group": "Professional Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Gain d4 in a skill (or d6 with a raise) until replaced.",
		"bookPage": "p49",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Smarts d10"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 73,
		"name": "Killer Instinct",
		"type": "(unknown)",
		"uuid": "58c09215-fde3-4340-01ab-ea6c041ab1c4",
		"group": "Combat",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "Wins tied opposed rolls, may reroll opposed skill die if it comes up a “1”",
		"bookPage": "p39",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Heroic"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 220,
		"name": "Killer Instinct",
		"type": "(unknown)",
		"uuid": "a8e174ba-bdbe-40bd-91ca-5df62dee8ef3",
		"group": "Combat Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "The hero gets a free reroll in any opposed Test he initiates.",
		"bookPage": "p42",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Seasoned"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 26,
		"name": "Leader of Men",
		"type": "(unknown)",
		"uuid": "da0882c0-9238-4c64-3f0b-c360cc39d65b",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "Roll a d10 as the Wild Die for subordinates’ group rolls",
		"bookPage": "p40",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Veteran",
			"edge:Command"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 74,
		"name": "Level Headed",
		"type": "(unknown)",
		"uuid": "893902e7-d5dd-4f68-120d-b5fc53f9c545",
		"group": "Combat",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "Act on best of two cards in combat",
		"bookPage": "p39",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Seasoned",
			"Smarts d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 221,
		"name": "Level Headed",
		"type": "(unknown)",
		"uuid": "b0e71334-73ec-4ccc-21c2-86e7ed689621",
		"group": "Combat Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Draw an additional Action Card each round and choose which one to use.",
		"bookPage": "p42",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Seasoned",
			"Smarts d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 30,
		"name": "Linguist",
		"type": "(unknown)",
		"uuid": "b7e36ad5-b4de-4c39-23dd-a004aa2365f4",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "Begin play with a number of languages equal to Smarts; Smarts –2 to be understood in any language heard for a week",
		"bookPage": "p36",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": true,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Smarts d6"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": null,
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 188,
		"name": "Linguist",
		"type": "(unknown)",
		"uuid": "43d8cdc2-84d8-4e25-89a5-dd171e8a4396",
		"group": "Background Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Character has d6 in languages equal to half her Smarts die.",
		"bookPage": "p40",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": true,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Smarts d6"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": null,
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 93,
		"name": "Liquid Courage",
		"type": "(unknown)",
		"uuid": "0539be86-6ff0-4558-809f-d11f5aee73d0",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "Gain Vigor die type after imbibing at least 8oz of alcohol",
		"bookPage": "p45",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Vigor d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 282,
		"name": "Liquid Courage",
		"type": "(unknown)",
		"uuid": "62fdc8ad-be15-443a-1d6b-55c27ffa8dd4",
		"group": "Weird Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Alcohol increases Vigor a die type and ignores one level of Wound penalty; –1 to Agility, Smarts, and related skills.",
		"bookPage": "p52",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Vigor d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 31,
		"name": "Luck",
		"type": "(unknown)",
		"uuid": "d7e65be3-bad4-4386-878f-7fdf2d52a46e",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [
			"+1 bennie"
		],
		"specify": "",
		"summary": "+1 bennie",
		"bookPage": "p36",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 189,
		"name": "Luck",
		"type": "(unknown)",
		"uuid": "5ca34b26-dd3e-4687-2c01-12f7dea02dce",
		"group": "Background Edges",
		"active": true,
		"book_id": 9,
		"effects": [
			"+1 benny"
		],
		"specify": "",
		"summary": "+1 Benny at the start of each session.",
		"bookPage": "p40",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 76,
		"name": "Marksman",
		"type": "(unknown)",
		"uuid": "8dd5db97-76d1-4c43-8bf8-e6ec55ca270d",
		"group": "Combat",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "Free Aim maneuver (+2 Shooting) if hero does not move",
		"bookPage": "p39",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Seasoned"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 223,
		"name": "Marksman",
		"type": "(unknown)",
		"uuid": "4c8db3ca-79f2-47b4-0120-f90ff8a10735",
		"group": "Combat Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "If firing a weapon with no more than a Rate of Fire of 1 as her first action, she may add +1 to an Athletics (throwing) or Shooting roll, or ignore up to 2 points of penalties from Called Shots, Cover, Range, Scale, or Speed.",
		"bookPage": "p43",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Seasoned",
			"skill:Athletics|Shooting d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": null,
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 77,
		"name": "Martial Artist",
		"type": "(unknown)",
		"uuid": "bb2c32bd-fa74-4b03-1d4d-b03d3818f59e",
		"group": "Combat",
		"active": true,
		"book_id": 2,
		"effects": [
			"naturalweapon: Unarmed;Melee;Str+d4"
		],
		"specify": "",
		"summary": "Never considered unarmed, +d4 to unarmed damage rolls",
		"bookPage": "p39",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"skill:Fighting d6"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 224,
		"name": "Martial Artist",
		"type": "(unknown)",
		"uuid": "8dcf5f73-12cf-44ac-0dc8-aec6831a8efe",
		"group": "Combat Edges",
		"active": true,
		"book_id": 9,
		"effects": [
			"step_unarmed_damage",
			"step_unarmed_tohit"
		],
		"specify": "",
		"summary": "Unarmed Fighting +1, ignore Unarmed Defender rule, add d4 damage die to unarmed Fighting attacks (or increase die a step if you already have it).",
		"bookPage": "p43",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Fighting d6"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": null,
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 79,
		"name": "Martial Arts Master",
		"type": "(unknown)",
		"uuid": "f7a8879d-d322-4a25-9df3-748aba04d986",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "+2 to unarmed damage rolls; may take this edge up to 5 times",
		"bookPage": "p39",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Legendary",
			"edge:Improved Martial Artist",
			"skill:Fighting d12"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 225,
		"name": "Martial Warrior",
		"type": "(unknown)",
		"uuid": "55450b45-9586-4f80-107b-d4730a746e69",
		"group": "Combat Edges",
		"active": true,
		"book_id": 9,
		"effects": [
			"step_unarmed_damage",
			"step_unarmed_tohit"
		],
		"specify": "",
		"summary": "Unarmed Fighting +2, increase damage die type a step.",
		"bookPage": "p43",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Seasoned",
			"edge:Martial Artist"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": null,
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 92,
		"name": "Master",
		"type": "(unknown)",
		"uuid": "998c9798-6d02-4353-bf73-366a2d96c188",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [
			"wilddied10: [selecttrait]"
		],
		"specify": "",
		"summary": "Wild Die is d10 for one Trait",
		"bookPage": "p46",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"[selecttrait]: d12+2",
			"Legendary",
			"Wildcard"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 287,
		"name": "Master",
		"type": "(unknown)",
		"uuid": "2a6db62a-0ccb-466d-1ca9-3a6b7dbdcb87",
		"group": "Legendary Edges",
		"active": true,
		"book_id": 9,
		"effects": [
			"boost_trait:[selected_trait] 1"
		],
		"specify": "",
		"summary": "The character’s Wild Die is a d10 with this Trait.",
		"bookPage": "p53",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"edge:Expert",
			"max_level:[selected_trait]"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": null,
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": true,
		"provides_swade_super_powers": false
	},
	{
		"id": 87,
		"name": "Master of Arms",
		"type": "(unknown)",
		"uuid": "9cb17044-1f31-44ec-bccd-8b1c08dc4837",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [
			"+1 parry"
		],
		"specify": "",
		"summary": "another +1 parry",
		"bookPage": "p46",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Weapon Master"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 292,
		"name": "Master of Arms",
		"type": "(unknown)",
		"uuid": "f08c4db6-7d98-4367-a17e-2ab8e12d801b",
		"group": "Legendary Edges",
		"active": true,
		"book_id": 9,
		"effects": [
			"+1 parry"
		],
		"specify": "",
		"summary": "Parry increases another +1 and Fighting bonus damage die is d10.",
		"bookPage": "p53",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"edge:Weapon Master"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 249,
		"name": "Master Tactician",
		"type": "(unknown)",
		"uuid": "f21a7ce1-122c-4eff-2534-cdebcebeb6b9",
		"group": "Leadership Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Draw and distribute two extra Action Cards instead of one.",
		"bookPage": "p45",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Veteran",
			"edge:Tactician"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 52,
		"name": "McGyver",
		"type": "(unknown)",
		"uuid": "1a25d4df-2523-4c35-b0a2-37dc31ac930c",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "May improvise temporary gadgets",
		"bookPage": "p43",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Smarts d6",
			"skill:Repair d6",
			"skill:Notice d6"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 298,
		"name": "McGyver",
		"type": "(unknown)",
		"uuid": "edbedf7a-a69d-4c37-9678-3a4aed1f52f3",
		"group": "Professional Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Quickly create improvised devices from scraps.",
		"bookPage": "p49",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Smarts d6",
			"skill:Repair d6",
			"skill:Notice d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 268,
		"name": "Menacing",
		"type": "(unknown)",
		"uuid": "57662305-2622-4c64-005d-3b899d22c5eb",
		"group": "Social Edges",
		"active": true,
		"book_id": 9,
		"effects": [
			"skill_bonus:Intimidation 2"
		],
		"specify": "",
		"summary": "+2 to Intimidation.",
		"bookPage": "p50",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"hindrance:Mean|Ugly|Ruthless|Bloodthirsty||edge:harrowed"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"customDescription": [
				""
			]
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"customDescription": [
				""
			]
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"customDescription": []
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": null,
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 166,
		"name": "Mentalist",
		"type": "(unknown)",
		"uuid": "70b5c8ca-63da-4b85-2dc3-661db7d8f8a5",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "+2 to any opposed Psionics roll",
		"bookPage": "p43",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Arcane Background (Psionics)",
			"Smarts d8",
			"Psionics d6"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 256,
		"name": "Mentalist",
		"type": "(unknown)",
		"uuid": "c778d182-95ae-4921-85a7-b1970d688451",
		"group": "Power Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "+2 to opposed Psionics rolls.",
		"bookPage": "p47",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Seasoned",
			"Arcane Background (Psionics)",
			"skill:Psionics d6"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 96,
		"name": "Mighty Blow",
		"type": "(unknown)",
		"uuid": "04ff678b-6ffe-4e26-b590-33ace68e6814",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "Double melee damage when dealt Joker",
		"bookPage": "p45",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Wild Card",
			"Seasoned",
			"skill:Fighting d10"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 226,
		"name": "Mighty Blow",
		"type": "(unknown)",
		"uuid": "01399d33-49cb-48cb-beb1-a7a111a09a43",
		"group": "Combat Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Once per turn, double Fighting damage when dealt a Joker.",
		"bookPage": "p43",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Wild Card",
			"Novice",
			"Fighting d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 53,
		"name": "Mr. Fix It",
		"type": "(unknown)",
		"uuid": "491b82a4-3e42-43dc-97db-b7a646fa8699",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [
			"skillbonus: +2 Repair"
		],
		"specify": "",
		"summary": "+2 to Repair rolls, 1/2 Repair time with raise",
		"bookPage": "p43",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"arcanebackground:Weird Science",
			"skill:Repair d8",
			"skill:Weird Science d8",
			"skill:Knowledge (*) d8",
			"skill:Knowledge (*) d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 299,
		"name": "Mr. Fix It",
		"type": "(unknown)",
		"uuid": "a6b4d28c-fea6-43b5-9978-83e951f12f8b",
		"group": "Professional Edges",
		"active": true,
		"book_id": 9,
		"effects": [
			"skill_bonus:Repair 2"
		],
		"specify": "",
		"summary": "+2 to Repair rolls, half the time required with a raise.",
		"bookPage": "p49",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"skill:Repair d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 27,
		"name": "Natural Leader",
		"type": "(unknown)",
		"uuid": "2b1e8904-4205-4ad7-3215-45ec71192515",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "May share bennies with men under command",
		"bookPage": "p40",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"edge:Command",
			"Spirit d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 247,
		"name": "Natural Leader",
		"type": "(unknown)",
		"uuid": "c67c479f-0a5a-4f90-9516-4d00ce198291",
		"group": "Leadership Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Leadership Edges that apply to Extras now apply to Wild Cards as well.",
		"bookPage": "p45",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Seasoned",
			"Spirit d8",
			"edge:Command"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 80,
		"name": "Nerves of Steel",
		"type": "(unknown)",
		"uuid": "35014c8d-7e1e-4c2b-2c2a-e36bf7907c19",
		"group": "Combat",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "Ignore 1 point of wound penalties",
		"bookPage": "p39",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Wild Card",
			"Novice",
			"Vigor d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 227,
		"name": "Nerves of Steel",
		"type": "(unknown)",
		"uuid": "54006f35-fe8a-485c-20fa-0e4588d4edbb",
		"group": "Combat Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Ignore one level of Wound penalties.",
		"bookPage": "p43",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Vigor d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 102,
		"name": "New Power",
		"type": "(unknown)",
		"uuid": "24486c7c-2fef-410d-39d9-ccc77e0cb10e",
		"group": "Power",
		"active": true,
		"book_id": 2,
		"effects": [
			"new_power"
		],
		"specify": "",
		"summary": "Character gains one new power",
		"bookPage": "p41",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"edge:Arcane Background"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": true,
		"provides_swade_super_powers": false
	},
	{
		"id": 257,
		"name": "New Powers",
		"type": "(unknown)",
		"uuid": "837b77ba-fc4b-4f41-9d09-2a7cb4394f7d",
		"group": "Power Edges",
		"active": true,
		"book_id": 9,
		"effects": [
			"new_powers"
		],
		"specify": "",
		"summary": "Your character learns a new power. Wizards must have access to an Arcane Laboratory and Clerics must complete a quest.",
		"bookPage": "p47",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Arcane Background"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": null,
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": true,
		"provides_swade_super_powers": false
	},
	{
		"id": 35,
		"name": "No Mercy",
		"type": "(unknown)",
		"uuid": "33b5e92b-d91c-4bfa-1698-fcef164eaa1e",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "may spend a Benny to reroll any one damage roll",
		"bookPage": "p39",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Seasoned"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 229,
		"name": "No Mercy",
		"type": "(unknown)",
		"uuid": "dc5fb2c5-f00d-4f74-8b00-685086c957bf",
		"group": "Combat Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "+2 damage when spending a Benny to reroll damage.",
		"bookPage": "p43",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Seasoned"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 99,
		"name": "Noble",
		"type": "(unknown)",
		"uuid": "98f351ff-966c-4aea-be40-a657e68cc25e",
		"group": "Background",
		"active": true,
		"book_id": 2,
		"effects": [
			"+2 Charisma",
			"add_edge: rich"
		],
		"specify": "",
		"summary": "",
		"bookPage": "p36",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 106,
		"name": "Power Points",
		"type": "(unknown)",
		"uuid": "25b44923-54f6-4865-9057-6217c6915878",
		"group": "Power",
		"active": true,
		"book_id": 2,
		"effects": [
			"+5 power_points"
		],
		"specify": "",
		"summary": "+5 Power Points, once per rank only",
		"bookPage": "p41",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"edge:Arcane Background"
		],
		"once_per_rank": true,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": true,
		"provides_swade_super_powers": false
	},
	{
		"id": 258,
		"name": "Power Points",
		"type": "(unknown)",
		"uuid": "ad21843f-a058-4802-0b12-67bb1a9f50fb",
		"group": "Power Edges",
		"active": true,
		"book_id": 9,
		"effects": [
			"+5 power_points"
		],
		"specify": "",
		"summary": "Gain 5 additional Power Points, no more than once per Rank.",
		"bookPage": "p47",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Arcane Background"
		],
		"once_per_rank": true,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": true,
		"provides_swade_super_powers": false
	},
	{
		"id": 97,
		"name": "Power Surge",
		"type": "(unknown)",
		"uuid": "6e1ad826-4d95-4f40-186e-d301d93b9497",
		"group": "Wild Card",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "+2d6 Power Points when dealt a Joker",
		"bookPage": "p45",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Wild Card",
			"Seasoned",
			"skill:[arcane] d10"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 262,
		"name": "Power Surge",
		"type": "(unknown)",
		"uuid": "70ff253c-7611-47a8-2fae-226b9aea9f47",
		"group": "Power Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Recover 10 Power Points when dealt a Joker in combat.",
		"bookPage": "p47",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"skill:Faith|Weird Science|Psionics|Focus|Spellcasting d8",
			"Wild Card",
			"Novice",
			"Arcane Background"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": true,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": null,
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 90,
		"name": "Professional",
		"type": "(unknown)",
		"uuid": "fa2b4be6-c318-4e51-82b9-2c662d3ef19b",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [
			"[selecttrait]: d12+1"
		],
		"specify": "",
		"summary": "Trait becomes d12+1",
		"bookPage": "p46",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Legendary",
			"[selecttrait] d12"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 285,
		"name": "Professional",
		"type": "(unknown)",
		"uuid": "41b81505-345e-4839-3708-f9a284066c6f",
		"group": "Legendary Edges",
		"active": true,
		"book_id": 9,
		"effects": [
			"boost_trait:[selected_trait] 1"
		],
		"specify": "",
		"summary": "The character’s Trait limit is d12+1.",
		"bookPage": "p53",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Legendary",
			"max_level:[selected_trait]"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": null,
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": true,
		"provides_swade_super_powers": false
	},
	{
		"id": 269,
		"name": "Provoke",
		"type": "(unknown)",
		"uuid": "77c8068a-e217-477a-3b9c-aa703f31eedb",
		"group": "Social Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "May “provoke” foes with a raise on a Taunt roll. See text.",
		"bookPage": "p50",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"skill:Taunt d6"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 33,
		"name": "Quick",
		"type": "(unknown)",
		"uuid": "e69bef4e-0428-4b45-0e0a-af6eb0cfe722",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "Discard draw of 5 or less for new card",
		"bookPage": "p36",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Agility D8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 191,
		"name": "Quick",
		"type": "(unknown)",
		"uuid": "0aaf122b-229b-4ae8-317f-bfe39dbf6557",
		"group": "Background Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Action Card must be higher than a Five.",
		"bookPage": "p40",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Agility d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 34,
		"name": "Quick Draw",
		"type": "(unknown)",
		"uuid": "04aaac6f-0696-4808-372b-be2884468f7f",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "May draw weapon as a free action",
		"bookPage": "p39",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Agility D8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 342,
		"name": "Rabble-Rouser",
		"type": "(unknown)",
		"uuid": "c9326994-ded0-4f98-1204-f3ea3f64e627",
		"group": "Social Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Once per turn, affect all foes in a Medium Blast Template with an Intimidation or Taunt Test.",
		"bookPage": "p50",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Seasoned",
			"Spirit d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 232,
		"name": "Rapid Fire",
		"type": "(unknown)",
		"uuid": "f87d8462-5f0e-4d62-8994-c727b36f4192",
		"group": "Combat Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Increase RoF by 1 for one Shooting attack per turn.",
		"bookPage": "p43",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Seasoned",
			"Shooting d6"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": null,
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": true,
		"provides_swade_super_powers": false
	},
	{
		"id": 103,
		"name": "Rapid Recharge",
		"type": "(unknown)",
		"uuid": "be5a6938-e6ac-4518-b415-afe891d9bbc2",
		"group": "Power",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "Regain 1 Power Point every 30 minutes",
		"bookPage": "p41",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"edge:Arcane Background"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": true,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 259,
		"name": "Rapid Recharge",
		"type": "(unknown)",
		"uuid": "999a1706-5b14-4be3-3892-618339bc6f88",
		"group": "Power Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Recover 10 Power Points per hour.",
		"bookPage": "p47",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Seasoned",
			"Spirit d6",
			"Arcane Background"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": true,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": null,
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 378,
		"name": "Reliable",
		"type": "(unknown)",
		"uuid": "72b5f5dc-3b31-4ea2-ac28-5c119a4792dd",
		"group": "Professional Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Free reroll when making Support rolls.",
		"bookPage": "p51",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Spirit d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 270,
		"name": "Retort",
		"type": "(unknown)",
		"uuid": "37de032a-715d-4514-03f7-6b913de817ef",
		"group": "Social Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "A raise when resisting a Taunt or Intimidation attack makes the foe Distracted.",
		"bookPage": "p50",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"skill:Taunt d6"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 100,
		"name": "Rich",
		"type": "(unknown)",
		"uuid": "6496577b-550e-4e69-980a-9b4787a9dd12",
		"group": "Background",
		"active": true,
		"book_id": 2,
		"effects": [
			"starting_funds_multiplier: 3"
		],
		"specify": "",
		"summary": "",
		"bookPage": "p36",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			""
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 192,
		"name": "Rich",
		"type": "(unknown)",
		"uuid": "7605ee71-c517-4f9a-30f7-6685a463531d",
		"group": "Background Edges",
		"active": true,
		"book_id": 9,
		"effects": [
			"starting_funds_multiplier: 3",
			"wealth_die: d8"
		],
		"specify": "",
		"summary": "Character starts with three times the starting funds and a $150K annual salary.",
		"bookPage": "p40",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"customDescription": [
				""
			]
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"customDescription": [
				""
			]
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"customDescription": [
				""
			]
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": null,
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 82,
		"name": "Rock and Roll!",
		"type": "(unknown)",
		"uuid": "45b4d23b-94c4-40b7-0b1e-6abebd3b9b61",
		"group": "Combat",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "Ignore full-auto penalty if shooter doesn’t move",
		"bookPage": "p39",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Seasoned ",
			"skill:Shooting d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 234,
		"name": "Rock and Roll!",
		"type": "(unknown)",
		"uuid": "4221e3b2-737f-40d1-1cf3-6a99820326df",
		"group": "Combat Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Ignore the Recoil penalty when firing weapons with a RoF of 2 or more.",
		"bookPage": "p44",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Seasoned",
			"Shooting d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 94,
		"name": "Scavenger",
		"type": "(unknown)",
		"uuid": "9a4359aa-35e2-4fdd-af42-bb6085cb08d0",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "Find an essential piece of equipment once per session",
		"bookPage": "p45",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"edge:Luck"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": null,
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 283,
		"name": "Scavenger",
		"type": "(unknown)",
		"uuid": "540f0d9a-a6a1-4b2e-1657-d094fc9a5f2d",
		"group": "Weird Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Once per session, the hero may find a piece of necessary equipment.",
		"bookPage": "p52",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"edge:Luck"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 39,
		"name": "Scholar",
		"type": "(unknown)",
		"uuid": "f321fdad-fc64-4e58-2a15-fae9d63e25d2",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "",
		"bookPage": "p44",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			""
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": true,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": null,
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 300,
		"name": "Scholar",
		"type": "(unknown)",
		"uuid": "4a0e8188-2c3c-4c39-3fb2-178f1d729a9d",
		"group": "Professional Edges",
		"active": true,
		"book_id": 9,
		"effects": [
			"bonusskill: [pick1:smarts-skill] 2"
		],
		"specify": "",
		"summary": "+2 to any one “knowledge” skill.",
		"bookPage": "p49",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"skill:Research d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": null,
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": true,
		"provides_swade_super_powers": false
	},
	{
		"id": 88,
		"name": "Sidekick",
		"type": "(unknown)",
		"uuid": "75cd4ca9-0c68-4ddf-3312-7adf7f57eb0d",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "Character gains a Novice WC sidekick",
		"bookPage": "p46",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Legendary",
			"Wild Card"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 288,
		"name": "Sidekick",
		"type": "(unknown)",
		"uuid": "67e4ac36-26c4-446d-ac24-af869ce56eae",
		"group": "Legendary Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "The character gains a Wild Card sidekick.",
		"bookPage": "p53",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Wild Card",
			"Legendary"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 303,
		"name": "Soldier",
		"type": "(unknown)",
		"uuid": "af2fd9a0-0ea3-4e91-0a3e-96b94cb541e9",
		"group": "Professional Edges",
		"active": true,
		"book_id": 9,
		"effects": [
			"min_str_mod +1",
			"enc_str_mod +1"
		],
		"specify": "",
		"summary": "Treat Minimum Strength as one die type higher for Encumbrance and Min Str. May Retest Vigor rolls when resisting environmental Hazards.",
		"bookPage": "p49",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice ",
			"Strength d6",
			"Vigor d6"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 105,
		"name": "Soul Drain",
		"type": "(unknown)",
		"uuid": "98d6b246-09e8-4a3d-18bd-407142a648a7",
		"group": "Power",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "Special, see text",
		"bookPage": "p41",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"seasoned",
			"skill:Knowledge (Arcana) d10",
			"edge:Arcane Background"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 261,
		"name": "Soul Drain",
		"type": "(unknown)",
		"uuid": "f7cf1bef-b900-4014-a4b5-ba5c39924f42",
		"group": "Power Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Recover 5 Power Points for a level of Fatigue.",
		"bookPage": "p47",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Seasoned",
			"Arcane Background",
			"skill:Faith|Weird Science|Psionics|Focus|Spellcasting d10"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 36,
		"name": "Steady Hands",
		"type": "(unknown)",
		"uuid": "5d4ca119-c6af-43af-25ca-bc0649ae7e81",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "ignores the “unstable platform”, -1 instead of -2 when running",
		"bookPage": "p39",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Agility d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 235,
		"name": "Steady Hands",
		"type": "(unknown)",
		"uuid": "c2f872d1-941b-4269-a825-029c8c228f88",
		"group": "Combat Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Ignore the Unstable Platform penalty when firing from a moving vehicle, horse, etc.",
		"bookPage": "p44",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Agility d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 271,
		"name": "Streetwise",
		"type": "(unknown)",
		"uuid": "b08546b9-cd68-42f7-2f7d-87d410d30895",
		"group": "Social Edges",
		"active": true,
		"book_id": 9,
		"effects": [
			"skill_bonus:Common Knowledge"
		],
		"specify": "",
		"summary": "+2 to Common Knowledge and criminal networking.",
		"bookPage": "p50",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Smarts d6"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 48,
		"name": "Strong Willed",
		"type": "(unknown)",
		"uuid": "f22e3b9d-9090-42db-3389-37859fd2cfec",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [
			"skillbonus: +2 Intimidation",
			"skillbonus: +2 Taunt"
		],
		"specify": "",
		"summary": "+2 Intimidation and Taunt, +2 to resist Tests of Will",
		"bookPage": "p45",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"skill:Intimidation d6",
			"skill:Taunt d6"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 272,
		"name": "Strong Willed",
		"type": "(unknown)",
		"uuid": "41652d84-ec0f-4173-b6b2-38474e2eb709",
		"group": "Social Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "+2 to resist Smarts or Spirit-based Tests.",
		"bookPage": "p51",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Spirit d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 37,
		"name": "Sweep",
		"type": "(unknown)",
		"uuid": "359d970d-ff11-48ba-8832-47c02cc0068f",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "Attack all adjacent foes at –2",
		"bookPage": "p39",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"skill:Fighting d8",
			"Strength d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 236,
		"name": "Sweep",
		"type": "(unknown)",
		"uuid": "123aea03-666a-4a5e-1ecf-781dd627248a",
		"group": "Combat Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Fighting roll at –2 to hit all adjacent targets in weapon’s Reach, no more than once per turn.",
		"bookPage": "p44",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Strength d8",
			"Fighting d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 28,
		"name": "Tactician",
		"type": "(unknown)",
		"uuid": "cbce945b-7881-4e86-b3c1-d31d494c8750",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "Make a Knowledge (Battle) roll at the beginning of a fight to get an Knowledge (Battle) d6, Action Card per success and raise; these may be given to any allies throughout the course of the battle",
		"bookPage": "p40",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Seasoned ",
			"edge:Command",
			"WildCard",
			"Smarts d8",
			"skill:Knowledge (Battle) d6"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 248,
		"name": "Tactician",
		"type": "(unknown)",
		"uuid": "ddd4ebc3-4cc7-478a-19f8-8bd27d9e1747",
		"group": "Leadership Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Draw an extra Action Card each turn that may be assigned to any ally in Command Range.",
		"bookPage": "p45",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Seasoned",
			"Smarts d8",
			"edge:Command",
			"skill:Battle d6"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": null,
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 55,
		"name": "Thief",
		"type": "(unknown)",
		"uuid": "5b10852d-f9dd-403d-1f52-426db548688f",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [
			"skillbonus: +2 Climbing",
			"skillbonus: +2 Lockpicking",
			"skillbonus: +2 Stealth"
		],
		"specify": "",
		"summary": "+2 Climb, Lockpick, Stealth, or to disarm traps",
		"bookPage": "p44",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Agility d8",
			"skill:Climbing d6",
			"skill:Lockpicking d6",
			"skill:Stealth d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 301,
		"name": "Thief",
		"type": "(unknown)",
		"uuid": "1a39dcdb-cacb-43ec-36ec-4372b27031a5",
		"group": "Professional Edges",
		"active": true,
		"book_id": 9,
		"effects": [
			"skill_bonus:Thievery 1"
		],
		"specify": "",
		"summary": "+1 Thievery, Athletics rolls made to climb, Stealth in urban environments.",
		"bookPage": "p49",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Agility d8",
			"skill:Stealth d6",
			"skill:Thievery d6"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 164,
		"name": "Tough as Nails",
		"type": "(unknown)",
		"uuid": "5656334f-b0c1-45a9-8fe2-9b1d48d31f66",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [
			"+1 toughness"
		],
		"specify": "",
		"summary": "+1 toughness",
		"bookPage": "p46",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Legendary"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 289,
		"name": "Tough as Nails",
		"type": "(unknown)",
		"uuid": "eaa4e58c-7d8e-4afc-186b-cfa39b6f4177",
		"group": "Legendary Edges",
		"active": true,
		"book_id": 9,
		"effects": [
			"+1 wound"
		],
		"specify": "",
		"summary": "The hero can take four Wounds before being Incapacitated.",
		"bookPage": "p53",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Legendary"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 290,
		"name": "Tougher than Nails",
		"type": "(unknown)",
		"uuid": "b4d3be27-c0b1-46d8-ad81-2692ce36f5ba",
		"group": "Legendary Edges",
		"active": true,
		"book_id": 9,
		"effects": [
			"+1 wound"
		],
		"specify": "",
		"summary": "The hero can take five Wounds before being Incapacitated.",
		"bookPage": "p53",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"edge:Tough as Nails"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 83,
		"name": "Trademark Weapon",
		"type": "(unknown)",
		"uuid": "584f0070-3158-47ad-1363-d95ff649bb7d",
		"group": "Combat",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "+1 Fighting or Shooting with particular weapon",
		"bookPage": "p39",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"skill:Fighting|Shooting d10"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 238,
		"name": "Trademark Weapon",
		"type": "(unknown)",
		"uuid": "e3dffbd9-f460-4896-a786-1421bc3719a7",
		"group": "Combat Edges",
		"active": true,
		"book_id": 9,
		"effects": [
			"selected_weapon_parry:1",
			"selected_weapon_to_hit:1"
		],
		"specify": "",
		"summary": "+1 to Athletics (throwing), Fighting, or Shooting total with a specific weapon; +1 Parry while weapon is readied.",
		"bookPage": "p44",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"skill:Athletics|Shooting|Fighting d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": true,
		"selected_ability_item": null,
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": true,
		"provides_swade_super_powers": false
	},
	{
		"id": 85,
		"name": "Two-Fisted",
		"type": "(unknown)",
		"uuid": "ab7494e0-db4e-4e34-2380-27feb8286774",
		"group": "Combat",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "May attack with a weapon in each hand without multi-action penalty",
		"bookPage": "p39",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Agility d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 240,
		"name": "Two-Fisted",
		"type": "(unknown)",
		"uuid": "2089b600-cc86-432d-3525-5c14fabe2940",
		"group": "Combat Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "May make an additional attack at no Multi-Action penalty when equipped with a weapon in each hand",
		"bookPage": "p44",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Agility d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 241,
		"name": "Two-Gun Kid",
		"type": "(unknown)",
		"uuid": "ce73457f-64a4-4ba6-2f55-328001efed6d",
		"group": "Combat Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Wielding two ranged weapons, make one extra Shooting roll with second weapon at no Multi-Action penalty.",
		"bookPage": "p44",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Agility d8",
			""
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": null,
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 10,
		"name": "Very Attractive",
		"type": "(unknown)",
		"uuid": "c3a4f513-8688-4968-152b-c4a7c965ea0c",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [
			"charisma +2"
		],
		"specify": "",
		"summary": "Additional Charisma +2 over Attractive (+4 total)",
		"bookPage": "p35",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"edge:Attractive"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 178,
		"name": "Very Attractive",
		"type": "(unknown)",
		"uuid": "863ec32f-5c95-4f06-9941-d8498ba33216",
		"group": "Background Edges",
		"active": true,
		"book_id": 9,
		"effects": [
			"skillbonus:Persuasion +1",
			"skillbonus:Performance +1"
		],
		"specify": "",
		"summary": "+2 to Persuasion rolls.",
		"bookPage": "p38",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"edge:Attractive"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"customDescription": [
				""
			]
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"customDescription": [
				""
			]
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"customDescription": []
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": null,
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 86,
		"name": "Weapon Master",
		"type": "(unknown)",
		"uuid": "a3bf9f86-3040-4a04-a6ae-6dd862fbfbab",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [
			"+1 parry"
		],
		"specify": "",
		"summary": "",
		"bookPage": "p46",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Legendary",
			"skill:Fighting d12"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 291,
		"name": "Weapon Master",
		"type": "(unknown)",
		"uuid": "4c5e11af-d16a-43b5-0c2f-a0c3da8c8644",
		"group": "Legendary Edges",
		"active": true,
		"book_id": 9,
		"effects": [
			"parry +1"
		],
		"specify": "",
		"summary": "Parry increases by +1 and Fighting bonus damage die is d8.",
		"bookPage": "p53",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Legendary",
			"Fighting d12"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": null,
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 56,
		"name": "Wizard",
		"type": "(unknown)",
		"uuid": "125f7c4e-da23-43c3-8d56-a519d2d7871c",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [],
		"specify": "",
		"summary": "Each raise reduces cost of spell by 1 point",
		"bookPage": "p44",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Spellcasting",
			"skill:Knowledge (Arcana) d8",
			"Smarts d8",
			"arcanebackground:Magic"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 263,
		"name": "Wizard",
		"type": "(unknown)",
		"uuid": "e2ea215c-a6b7-4632-9b09-ba3d6a798ab6",
		"group": "Power Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Spend 1 extra Power Point to change a spell’s Trapping.",
		"bookPage": "p47",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Seasoned",
			"Arcane Background (Magic)",
			"skill:Spellcasting d6"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 57,
		"name": "Woodsman",
		"type": "(unknown)",
		"uuid": "19855199-dacb-42b3-8138-1dd695aef0ea",
		"group": "",
		"active": true,
		"book_id": 2,
		"effects": [
			"skillbonus: +2 Tracking",
			"skillbonus: +2 Survival",
			"skillbonus: +2 Stealth"
		],
		"specify": "",
		"summary": "+2 Tracking Survival, and Stealth",
		"bookPage": "p44",
		"conflicts": [],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Spirit d6",
			"skill:Survival d6",
			"skill:Tracking d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 0,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 302,
		"name": "Woodsman",
		"type": "(unknown)",
		"uuid": "9f0a6dcf-fa17-456d-255e-416d735854b0",
		"group": "Professional Edges",
		"active": true,
		"book_id": 9,
		"effects": [
			"skill_bonus:Survival +2"
		],
		"specify": "",
		"summary": "+2 to Survival and Stealth in the wilds.",
		"bookPage": "p50",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Spirit d6",
			"skill:Survival d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 275,
		"name": "Work the Crowd",
		"type": "(unknown)",
		"uuid": "4b8cc9d5-3b36-4e3d-3b5f-396f1a8045f0",
		"group": "Social Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "As Work the Room but affects everyone in a Large Burst Template.",
		"bookPage": "p51",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Seasoned",
			"edge:Work the Room"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	},
	{
		"id": 274,
		"name": "Work the Room",
		"type": "(unknown)",
		"uuid": "77989f98-a2f6-44a6-b1ed-6aae2bb8254b",
		"group": "Social Edges",
		"active": true,
		"book_id": 9,
		"effects": [],
		"specify": "",
		"summary": "Once per turn, roll a second die when Supporting via Performance or Persuasion and apply result to additional ally.",
		"bookPage": "p51",
		"conflicts": [
			""
		],
		"free_edge": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"can_toggle": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"is_linguist": false,
		"name_plural": "",
		"instructions": "",
		"requirements": [
			"Novice",
			"Spirit d8"
		],
		"once_per_rank": false,
		"selected_edge": "",
		"selected_trait": "",
		"counts_as_other": [],
		"selectedOptions": null,
		"needs_to_specify": false,
		"selected_ab_uuid": "",
		"selected_skill_1": "",
		"selected_skill_2": "",
		"arcane_background": false,
		"powerEdgeOptions1": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions2": {
			"abID": -2,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [
				""
			],
			"limitationPersonal": ""
		},
		"powerEdgeOptions3": {
			"abID": -1,
			"uuid": "",
			"trappings": "",
			"customName": "",
			"innatePower": false,
			"settingItem": false,
			"effectSpecify": "",
			"limitationRange": "",
			"limitationAspect": "",
			"neg2SkillPenalty": false,
			"customDescription": [],
			"limitationPersonal": ""
		},
		"number_times_taken": 1,
		"selected_attribute": "",
		"pathfinderClassEdge": false,
		"selected_power_ab_1": 0,
		"selected_power_ab_2": 0,
		"selected_power_ab_3": 0,
		"selected_power_id_1": "0",
		"selected_power_id_2": "0",
		"selected_power_id_3": "0",
		"is_arcane_background": false,
		"selected_combat_edge": "",
		"arcane_background_def": null,
		"needs_selected_weapon": false,
		"selected_ability_item": "",
		"selected_power_custom_1": null,
		"selected_power_custom_2": null,
		"selected_power_custom_3": null,
		"hidden_on_character_sheet": false,
		"can_be_taken_more_than_once": false,
		"provides_swade_super_powers": false
	}
];

export default Edges;