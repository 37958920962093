import React, {useState} from 'react';
import Language from "../Language/Language";
import Dialog from "../UI/Burnblade/Dialog";
import Spinner from "../UI/Burnblade/Spinner";
import styled from "styled-components";
import HorizontalRule from "../UI/Burnblade/HorizontalRule";
import ButtonRegular, {ButtonRegularColor} from "../UI/Burnblade/ButtonRegular";
import {hideDice, rollDice} from "../UI/Dice";

const RangesByRLevel = [
	[[1,2], [3,10], [11,90], [89,98], [99,100]],
	[[1,4], [5,15], [16,85], [86,96], [97,100]],
	[[1,5], [6,20], [21,80], [81,95], [96,100]],
	[[1,8], [9,25], [26,75], [76,92], [93,100]],
	[[1,12], [13,30], [31,70], [71,88], [89,100]]
];

const PowerLevelTableRow = styled.div`
	display:flex;
	flex-flow:row nowrap;
	padding:0.5em 1em;
	& > span:first-child { flex:1; }
	&[data-selected] { background:rgba(255,255,0,0.25); }
`;

function PowerLevelModule({}) {

	// input
	const language = Language.getCurrent();
	const [showDialog, setShowDialog] = useState(false);
	const [chaosFactor, setChaosFactor] = useState(5);
	const [total, setTotal] = useState(undefined);
	const ranges = RangesByRLevel[Math.floor((chaosFactor-1)*0.5)];
	const selectedPowerLevelIndex = undefined === total ? false : ranges.findIndex(range => total <= range[1]);

	// display
	return (
		<>
			<ButtonRegular onClick={()=>{setShowDialog(true);}}>{language.P_Level}</ButtonRegular>

			{
				// Power Level Dialog
				!showDialog ? false :
				<Dialog
					title={language.Power_Level}
					onClose={()=>{setShowDialog(false); hideDice(); }}
				>

					{/* Chaos Factor */}
					<Spinner
						min={1}
						max={10}
						step={1}
						inline={true}
						onChange={setChaosFactor}
						initialValue={chaosFactor}
						text={language.Chaos_Factor}
					/>

					{/* Ranges */}
					{
						language.PowerLevels.map((powerLevel, powerLevelIndex) =>
							<PowerLevelTableRow
								key={powerLevelIndex}
								data-selected={selectedPowerLevelIndex === powerLevelIndex ? 1 : undefined}
							>
								<span>{ranges[powerLevelIndex][0]}-{ranges[powerLevelIndex][1]}</span>
								<span>{powerLevel}</span>
							</PowerLevelTableRow>
						)
					}

					<HorizontalRule />

					{/* Roll */}
					<div style={{textAlign:"center"}}>
						<ButtonRegular
							buttonRegularColor={ButtonRegularColor.Green}
							onClick={()=>{
								setTotal();
								rollDice("1d10+1d100", false, results => {
									setTotal(results[0].value + results[1].value);
								});
							}}
						>{language.Roll}</ButtonRegular>

						{/* Clear */}
						<ButtonRegular
							onClick={() => {
								setTotal();
								hideDice();
							}}
						>{language.Clear}</ButtonRegular>
					</div>

				</Dialog>
			}
		</>
	);
}

export default PowerLevelModule;