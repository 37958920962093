import React from "react";
import CharacterModule from "./CharacterModule";
import CharacterData from "./CharacterData";
import TraitsData from "./TraitsData";

const BestiarySkillByName = {
	"Athletics": TraitsData.Skills.Athletics,
	"Common Knowledge": TraitsData.Skills.Common_Knowledge,
	"Notice": TraitsData.Skills.Notice,
	"Persuasion": TraitsData.Skills.Persuasion,
	"Stealth": TraitsData.Skills.Stealth,
	"Academics": TraitsData.Skills.Academics,
	"Battle": TraitsData.Skills.Battle,
	"Boating": TraitsData.Skills.Boating,
	"Driving": TraitsData.Skills.Driving,
	"Electronics": TraitsData.Skills.Electronics,
	"Faith": TraitsData.Skills.Faith,
	"Fighting": TraitsData.Skills.Fighting,
	"Focus": TraitsData.Skills.Focus,
	"Gambling": TraitsData.Skills.Gambling,
	"Hacking": TraitsData.Skills.Hacking,
	"Healing": TraitsData.Skills.Healing,
	"Intimidation": TraitsData.Skills.Intimidation,
	"Language": TraitsData.Skills.Language,
	"Occult": TraitsData.Skills.Occult,
	"Performance": TraitsData.Skills.Performance,
	"Piloting": TraitsData.Skills.Piloting,
	"Psionics": TraitsData.Skills.Psionics,
	"Repair": TraitsData.Skills.Repair,
	"Research": TraitsData.Skills.Research,
	"Riding": TraitsData.Skills.Riding,
	"Science": TraitsData.Skills.Science,
	"Shooting": TraitsData.Skills.Shooting,
	"Spellcasting": TraitsData.Skills.Spellcasting,
	"Survival": TraitsData.Skills.Survival,
	"Taunt": TraitsData.Skills.Taunt,
	"Thievery": TraitsData.Skills.Thievery,
	"Weird Science": TraitsData.Skills.Weird_Science,
	"Swimming": TraitsData.Skills.Swimming,
	"Climbing": TraitsData.Skills.Climbing,
	"Throwing": TraitsData.Skills.Throwing,
	"Knowledge (Occult)": TraitsData.Skills.Knowledge_Occult,
	"Tracking": TraitsData.Skills.Tracking
}

function BestiaryCharacterModule({bestiaryCharacter, bestiaryStatus, onSaved})
{
	// create traits from bestiary
	const traits = {};
	traits[TraitsData.Attributes.Agility] = bestiaryCharacter.attributes.Agility.replace("d", "")-0;
	traits[TraitsData.Attributes.Smarts] = bestiaryCharacter.attributes.Smarts.replace("d", "").replace(" (A)","")-0;
	traits[TraitsData.Attributes.Spirit] = bestiaryCharacter.attributes.Spirit.replace("d", "")-0;
	traits[TraitsData.Attributes.Strength] = bestiaryCharacter.attributes.Strength.replace("d", "")-0;
	traits[TraitsData.Attributes.Vigor] = bestiaryCharacter.attributes.Vigor.replace("d", "")-0;
	bestiaryCharacter.export_generic_json.skills.forEach(({name, dieValue}) => {
		const traitId = BestiarySkillByName[name];
		if (!traitId) console.log("Missing Skill: ", name);
		else traits[traitId] = dieValue;
	});

	// create character
	const character = CharacterData.createCharacter({
		traits:traits,
		name:bestiaryCharacter.name,
		pace:bestiaryCharacter.pace-0,
		size:bestiaryCharacter.export_generic_json.size-0,
		edgeIds:bestiaryCharacter.export_generic_json.edges.map(edge => edge.id).filter(id => id),
		hindranceIds:bestiaryCharacter.export_generic_json.hindrances.map(hindrance => hindrance.id).filter(id => id),
		weaponItemIds:bestiaryCharacter.export_generic_json.weapons.map(({id}) => id).filter(id => id),
		abilities:bestiaryCharacter.abilities.slice(),
		naturalArmor:bestiaryCharacter.armor-0,
		characterStatus:bestiaryStatus
	});

	// display
	return (
		<CharacterModule
			readOnly={true}
			onSaved={onSaved}
			character={character}
		/>
	);
}

export default BestiaryCharacterModule;