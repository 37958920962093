const ThreadData = new function() {
	const this_ = this;

	// create
	this_.createThread = ({name, notes, isClosed}) => {
		return {
			name_:name,
			notes_:notes,
			isClosed_:isClosed
		};
	};

	// accessors
	this_.getThreadName = thread => thread.name_;
	this_.getThreadNotes = thread => thread.notes_;
	this_.getThreadIsClosed = thread => thread.isClosed_;

	// modifiers
	this_.setThreadName = (thread, value) => thread.name_ = value;
	this_.setThreadNotes = (thread, value) => thread.notes_ = value;
	this_.setThreadIsClosed = (thread, value) => thread.isClosed_ = value;

	// save data
	this_.getSaveData = thread => [
		this_.getThreadName(thread),
		this_.getThreadNotes(thread),
		this_.getThreadIsClosed(thread)?1:0,
	];

	// load data
	this_.loadData = data => this_.createThread({
		name:data[0],
		notes:data[1],
		isClosed:data[2]
	});
};

export default ThreadData;