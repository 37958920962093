import React from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
	height:100%;
	font-family: Arial, sans-serif;
	background:url(/assets/burnblade/bg.jpg) no-repeat center center Black;
	background-size:cover;
	color:#c1ae99;
	text-shadow: 1px 1px 4px black;
	zoom: 0.5;
	font-size: 32px;
	
	zoom:1;
	font-size:16px;
	
	.ScrollVertical {
		overflow: auto;
		
		&::-webkit-scrollbar { 
			width: 28px;
			box-sizing: border-box;
		}
		&::-webkit-scrollbar-track {
			background: #171310;
			border:solid 1px #39302c;
		}
		&::-webkit-scrollbar-thumb {
			height:73px;
			border:solid 1px #1b1613;
			background: url(/assets/burnblade/scrollbar_vertical.png?v=1) no-repeat 0 0 #342a26;//#453832;
			background-size:200% 73px;
			&:hover {
				background-position: right 0;
				background-color:#4c3e38;//#65524a;
			}
		}
	}
	
	.ScrollHorizontal {
		overflow: auto;
		
		&::-webkit-scrollbar { 
			height: 28px;
			box-sizing: border-box;
		}
		&::-webkit-scrollbar-track {
			background: #171310;
			border:solid 1px #39302c;
		}
		&::-webkit-scrollbar-thumb {
			border:solid 1px #1b1613;
			background: url(/assets/burnblade/scrollbar_horizontal.png?v=1) no-repeat center 0 #342a26;
			background-size:73px 200%;
			&:hover {
				background-position: center bottom;
				background-color:#4c3e38;//#65524a;
			}
		}
	}
`;

function AppWrapper({children}) {

	// input

	// display
	return (<Wrapper>{children}</Wrapper>);
}

export default AppWrapper;