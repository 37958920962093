import styled from "styled-components";

const ComboWrapper = styled.span`
	display:flex;
	flex-flow:row nowrap;
	width:100%;
	align-items: center;
	
	& > input { 
		flex:1;
		
		// Small Button
		& + button {
			margin-top: -25px;
			margin-bottom: -12.5px;
		}
	}
`;

export default ComboWrapper;