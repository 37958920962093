import React from 'react';
import styled from "styled-components";

const Wrapper = styled.textarea`
	background: #191411;
	border: solid 2px #453c36;
	border-radius: 2px;
	height: 120px;
	line-height: 21px;
	padding: 0.5em;
	outline: none;
	width:100%;
	box-sizing: border-box;
`;

function TextArea({...props}) {

	// input

	// display
	return (
		<Wrapper {...props} />
	);
}

export default TextArea;