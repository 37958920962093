const MapTokenData = new function() {
	const this_ = this;

	// create
	this_.createMapToken = ({posX, posY, bestiaryId, characterId}) => {
		return {
			posX_:posX,
			posY_:posY,
			bestiaryId_:bestiaryId,
			characterId_:characterId
		};
	}

	// accessors
	this_.getMapTokenPosX = token => token.posX_;
	this_.getMapTokenPosY = token => token.posY_;
	this_.getMapTokenBestiaryId = token => token.bestiaryId_;
	this_.getMapTokenCharacterId = token => token.characterId_;

	// modifiers
	this_.setMapTokenPosX = (token, value) => token.posX_ = value;
	this_.setMapTokenPosY = (token, value) => token.posY_ = value;
	this_.setMapTokenBestiaryId = (token, value) => token.bestiaryId_ = value;
	this_.setMapTokenCharacterId = (token, value) => token.characterId_ = value;

	// save data
	this_.getSaveData = token => [
		this_.getMapTokenPosX(token),
		this_.getMapTokenPosY(token),
		this_.getMapTokenBestiaryId(token),
		this_.getMapTokenCharacterId(token)
	];

	// load data
	this_.loadData = data => this_.createMapToken({
		posX:data[0],
		posY:data[1],
		bestiaryId:data[2],
		characterId:data[3]||undefined,
	});
};

export default MapTokenData;