import styled from "styled-components";

const HorizontalRule = styled.hr`
	background: url(/assets/burnblade/hr.png) center center;
	background-size:100%;
	width: 422px;
	height: 13px;
	border: none;
	padding: 0;
`;

export default HorizontalRule;