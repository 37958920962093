import React from 'react';
import styled from "styled-components";
import Label from "./Label";

const Wrapper = styled.input`
	display: none;
	& + span {
		zoom:0.5;
		background: url(/assets/burnblade/checkbox.png?v=3);
		width:58px;
		height:58px;
		display: inline-block;
		vertical-align: middle;
	}
	&:checked + span { background-position: -58px 0; }

	&.Checkmark:checked + span {
		background:
			url(/assets/burnblade/checkbox.png?v=3),
			url(/assets/burnblade/checkbox.png?v=3);
		background-position:-118px 0, 0 0;
	}
`;

function CheckboxInput({useCheckmark, ...props}) {

	// display
	return (
		<>
			<Wrapper
				{...props}
				type="checkbox"
				className={useCheckmark?"Checkmark":undefined}
			/>
			<span></span>
		</>
	);
}

function CheckboxLabel({children, useCheckmark, style, ...props})
{
	return (
		<Label text={children} inputOnLeft={true} style={style}>
			<CheckboxInput
				{...props}
				useCheckmark={useCheckmark}
			/>
		</Label>
	)
}

export {CheckboxInput, CheckboxLabel};