import React, {useState} from 'react';
import styled from "styled-components";
import NumberInput from "./NumberInput";
import TextInput from "./TextInput";

const Wrapper = styled.div`
	display: inline-flex;
	flex-flow:column nowrap;
	padding:0.5em;

	& > div {

		// Label
		&:first-child {
			text-align: center;
			margin-bottom: 12px;
		}

		// Input/Buttons
		&:nth-child(2) {
			display: flex;
			flex-flow:row nowrap;
			align-items: center;

			& > button {
				border:none;
				background:url(/assets/burnblade/arrow_button.png);
				background-size:300% 100%;
				width:30px;
				height:30px;

				&:hover { background-position: -30px 0; }
				&:active { background-position: -60px 0; }

				&:first-child { transform:rotate(90deg); }
				&:last-child { transform:rotate(-90deg); }
			}

			& > input[type='number'] {
				height:30px;
				text-align: center;
				box-sizing: border-box;
			}
		}
	}

	// Inline
	&[data-inline] {
		display: flex;
		flex-flow:row nowrap;
		align-items: center;

		// Label
		& > div:first-child {
			text-align: left;
			margin-bottom: 0;
			margin-right:6px;
			flex:1;
		}
	}
	
	// Hide Buttons
	&[data-readonly] > div:nth-child(2) > button { visibility: hidden; }
`;

function Spinner({text, initialValue, step, min, max, name, inline, readOnly, onChange, skip2, isText}) {
	const [value, setValue] = useState(initialValue||0);

	// display
	return (
		<Wrapper data-inline={inline?1:undefined} data-readonly={readOnly?1:undefined}>
			<div>{text}</div>
			<div>
				<button
					type="button"
					onClick={() => {
						let newValue = Math.max(min, value-step);
						if (skip2 && 2 === newValue) newValue = 0;
						setValue(newValue);
						if (onChange) onChange(newValue);
					}}
				></button>
				{
					isText ? <TextInput readOnly={true} value={value} /> :
					<NumberInput
						readOnly={true}
						value={value}
						min={min}
						max={max}
						name={name}
					/>
				}
				<button
					type="button"
					onClick={() => {
						let newValue = Math.min(max, value- -step);
						if (skip2 && 2 === newValue) newValue = 4;
						setValue(newValue);
						if (onChange) onChange(newValue);
					}}
				></button>
			</div>
		</Wrapper>
	);
}

export default Spinner;