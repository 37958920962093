import React, {useState} from 'react';
import Dialog from "../UI/Burnblade/Dialog";
import {hideDice} from "../UI/Dice";
import Label from "../UI/Burnblade/Label";
import ComboWrapper from "../UI/Burnblade/ComboWrapper";
import TextInput from "../UI/Burnblade/TextInput";
import RandomSelect from "./RandomSelect";
import Language from "../Language/Language";
import ButtonRegular from "../UI/Burnblade/ButtonRegular";

function RandomEventModule({buttonRegularColor}) {

	// input
	const language = Language.getCurrent();
	const [showDialog, setShowDialog] = useState(false);
	const [focus, setFocus] = useState("");
	const [action, setAction] = useState("");
	const [subject, setSubject] = useState("");

	// display
	return (
		<>
			<ButtonRegular buttonRegularColor={buttonRegularColor} onClick={()=>{setShowDialog(true);}}>{language.Event}</ButtonRegular>

			{
				// Adventures Dialog
				!showDialog ? false :
				<Dialog
					title={language.Random_Event}
					onClose={()=>{setShowDialog(false); hideDice(); }}
				>

					<div style={{width:"500px"}}>

						{/* Focus */}
						<Label text={language.Event_Focus} stacked={true}>
							<ComboWrapper>
								<TextInput
									name="focus"
									value={focus}
									onChange={event => {setFocus(event.target.value);}}
								/>
								<RandomSelect
									onSelect={setFocus}
									titleText={language.Event_Focus}
									optionsNoLabel={language.EventFocuses}
									ranges={[
										[1,7],
										[8,28],
										[29,35],
										[36,45],
										[46,52],
										[53,55],
										[56,67],
										[68,75],
										[76,83],
										[84,92],
										[93,100]
									]}
								/>
							</ComboWrapper>
						</Label>

						{/* Action */}
						<Label text={language.Event_Action} stacked={true}>
							<ComboWrapper>
								<TextInput
									name="action"
									value={action}
									onChange={event => {setAction(event.target.value);}}
								/>
								<RandomSelect
									onSelect={setAction}
									titleText={language.Event_Action}
									optionsNoLabel={language.EventActions}
								/>
							</ComboWrapper>
						</Label>

						{/* Subject */}
						<Label text={language.Event_Subject} stacked={true}>
							<ComboWrapper>
								<TextInput
									name="subject"
									value={subject}
									onChange={event => {setSubject(event.target.value);}}
								/>
								<RandomSelect
									onSelect={setSubject}
									titleText={language.Event_Subject}
									optionsNoLabel={language.EventSubjects}
								/>
							</ComboWrapper>
						</Label>

					</div>

				</Dialog>
			}
		</>
	);
}

export default RandomEventModule;