import AdventureData from "./AdventureData";

const SaveLoad = new function() {
	const this_ = this;
	const adventuresKey_ = "TTPG.Adventures1";
	const adventureIndexKey_ = "TTPG.AdventureIndex";
	const diceKey_ = "TTPG.Dice";

	// adventures
	const adventures_ = (JSON.parse(window.localStorage.getItem(adventuresKey_))||[]).map(AdventureData.loadData);
	this_.getAdventures = () => adventures_;
	this_.saveAdventures = () => {
		//console.log(adventures_.map(AdventureData.getSaveData));
		window.localStorage.setItem(adventuresKey_, JSON.stringify(adventures_.map(AdventureData.getSaveData)));
	};

	// selected adventure index
	this_.getSelectedAdventureIndex = () => window.localStorage.getItem(adventureIndexKey_)||-1;
	this_.saveSelectedAdventureIndex = (selectedAdventureIndex) => {
		window.localStorage.setItem(adventureIndexKey_, selectedAdventureIndex);
	};

	// dice
	this_.saveDice = (theme, color) => { window.localStorage.setItem(diceKey_, JSON.stringify([theme, color])); };
	this_.loadDiceThemeColor = () => JSON.parse(window.localStorage.getItem(diceKey_)) || [];

};

export default SaveLoad;