import React, {useState} from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
	display:flex;
	flex-flow:column nowrap;
	& > div {
		
		// Tabs
		&:first-child {
			display:flex;
			flex-flow:row nowrap;
			
			& > button {
				background: url(/assets/burnblade/tab_btn.png);
				zoom:0.5;
				font-size:2em;
				width:234px;
				height:94px;
				box-sizing: border-box;
				padding:0;
				border:none;
				position: relative;
				&:hover { background-position: 0 -94px; }
				&[data-selected]:after { 
					content:"";
					display:block;
					background:url(/assets/burnblade/tab_btn.png);
					background-position: 0 -188px;
					width:234px;
					height:28px;
					position: absolute;
				}
			}
		}
		
		// Pages
		&:nth-child(n+2) {
			max-height: 80vh;
		}
	}
`;

function TabBookHorizontal({tabLabels, tabPages, initialTabIndex, hiddenPages}) {

	// input
	const [selectedTabIndex, setSelectedTabIndex] = useState(initialTabIndex);

	// display
	return (
		<Wrapper>
			<div>
				{
					tabLabels.map((tabLabel, tabIndex) =>
						<button
							key={tabIndex}
							type="button"
							onClick={() => setSelectedTabIndex(tabIndex)}
							style={{display:!tabLabel ? "none" : undefined}}
							data-selected={selectedTabIndex === tabIndex ? 1 : undefined}
						>
							{tabLabel}
						</button>
					)
				}
			</div>
			{
				!hiddenPages ? <div>{tabPages[selectedTabIndex]}</div> :
				tabPages.map((tabPage, tabPageIndex) =>
					<div
						key={tabPageIndex}
						className="ScrollVertical"
						style={{display:tabPageIndex===selectedTabIndex?undefined:"none"}}>
							{tabPage}
					</div>
				)
			}
		</Wrapper>
	);
}

export default TabBookHorizontal;