import ThreadData from "./ThreadData";
import CharacterData from "./CharacterData";
import SceneData from "./SceneData";

const AdventureData = new function() {
	const this_ = this;

	// create
	this_.createAdventure = ({name, notes, threads, characters, scenes}) => {
		return {
			name_:name,
			notes_:notes,
			threads_:threads||[],
			characters_:characters||[],
			scenes_:scenes||[]
		};
	};

	// accessors
	this_.getName = adventure => adventure.name_;
	this_.getNotes = adventure => adventure.notes_;
	this_.getThreads = adventure => adventure.threads_;
	this_.getCharacters = adventure => adventure.characters_;
	this_.getScenes = adventure => adventure.scenes_;

	// modifiers
	this_.setName = (adventure, value) => adventure.name_ = value;
	this_.setNotes = (adventure, value) => adventure.notes_ = value;

	// save data
	this_.getSaveData = adventure => [
		this_.getName(adventure),
		this_.getNotes(adventure),
		this_.getThreads(adventure).map(ThreadData.getSaveData),
		this_.getCharacters(adventure).map(CharacterData.getSaveData),
		this_.getScenes(adventure).map(SceneData.getSaveData),
	];

	// load data
	this_.loadData = data => this_.createAdventure({
		name:data[0],
		notes:data[1],
		threads:data[2].map(ThreadData.loadData),
		characters:data[3].map(CharacterData.loadData),
		scenes:data[4].map(SceneData.loadData)
	});

};

export default AdventureData;