import React from "react";
import Language from "../Language/Language";
import ListItemGroupModule from "./ListItemGroupModule";
import CharacterData from "./CharacterData";
import Bestiary from "../Savaged/Bestiary";
import HorizontalRule from "../UI/Burnblade/HorizontalRule";

function SceneModuleCharacters({characters, characterIds, setCharacterIds, bestiaryIds, setBestiaryIds})
{
	// input
	const language = Language.getCurrent();

	// display
	return (
		<div style={{maxWidth:"35vw", paddingTop:"1em", maxHeight:"450px"}}>
			<ListItemGroupModule
				allItems={characters}
				title={language.Characters}
				getItemId={CharacterData.getCharacterId}
				getItemName={CharacterData.getCharacterNameExtended}
				getItemSummary={CharacterData.getCharacterSummary}
				currentItemIds={characterIds}
				setCurrentItemIds={setCharacterIds}
			/>

			<HorizontalRule />

			<ListItemGroupModule
				allItems={Bestiary}
				title={language.Bestiary}
				getItemId={item => item.id}
				getItemName={item => item.name}
				getItemSummary={item => item.description}
				currentItemIds={bestiaryIds}
				setCurrentItemIds={setBestiaryIds}
			/>
		</div>
	)

}

export default SceneModuleCharacters;