import React from "react";
import TabBookVertical from "../UI/Burnblade/TabBookVertical";
import styled from "styled-components";
import MapUrlSizeByPath from "./MapUrlSizeByPath";

const g_basePath = "/assets/maps/tomcartos/";
const g_thumbPath = "/assets/maps/tomcartos.thumbs/";

const Wrapper = styled.div`
	max-width: 50vw;
	max-height: 600px;
	
	& > button {
		margin:0.25em;
		width:190px;
		height:190px;
		border:none;
		padding:0;
		box-sizing: border-box;
		background:no-repeat center center;
		background-size:cover;
	}
`;

function MapSelectModule({onSelect})
{
	// input

	// display
	return (
		<TabBookVertical
			tabLabels={Object.keys(MapUrlSizeByPath).map(path => path.replace(/_/g, ' '))}
			tabPages={
				Object.keys(MapUrlSizeByPath).map(groupName =>
					<Wrapper className="ScrollVertical">
						{
							MapUrlSizeByPath[groupName].map(([url, width, height]) =>
								<button
									key={url}
									type="button"
									style={{backgroundImage:`url('${`${g_thumbPath}${groupName}/${url}`}')`}}
									onClick={() => { onSelect(`${g_basePath}${groupName}/${url}`, width, height); }}
								/>
							)
						}
					</Wrapper>
				)
			}
		/>
	)
}

export default MapSelectModule;