const DragManager = new function() {
	const this_ = this;
	let onDrag_, onClick_, onDragStop_, startX_, startY_, zoom_=1;

	function getEventXY(event) { return [event["pageX"]*zoom_, event["pageY"]*zoom_]; }

	// start dragging
	this_.dragStart = (event, {onDrag, onDragStop, onClick}) => {
		[startX_, startY_] = getEventXY(event);
		onDrag_ = onDrag;
		onClick_ = onClick;
		onDragStop_ = onDragStop;
	};

	// drag
	document["addEventListener"]("mousemove", event => {
		if (onDrag_)
		{
			const [newX, newY] = getEventXY(event);
			onDrag_(newX - startX_, newY - startY_);
			onClick_ = false;
		}
	});

	// stop dragging
	document["addEventListener"]("mouseup", event => {
		if (onClick_) onClick_();
		if (onDragStop_)
		{
			const [newX, newY] = getEventXY(event);
			onDragStop_(newX - startX_, newY - startY_);
		}
		onDrag_ = undefined;
		onClick_ = undefined;
		onDragStop_ = undefined;
	});
};

export default DragManager;