import React, {useState} from 'react';
import Dialog from "../UI/Burnblade/Dialog";
import Language from "../Language/Language";
import ThreadData from "./ThreadData";
import {CheckboxLabel} from "../UI/Burnblade/Checkbox";
import TabBookVertical from "../UI/Burnblade/TabBookVertical";
import ThreadModule from "./ThreadModule";

function ThreadsModule({threads, onSaved}) {

	// input
	const language = Language.getCurrent();
	const [showDialog, setShowDialog] = useState(false);
	const [renderKey, setRenderKey] = useState(0);
	const [initialTabIndex, setInitialTabIndex] = useState(0);

	// display
	return (
		<>
			<button onClick={()=>{setShowDialog(true);}}>{language.Threads}</button>

			{
				// Threads Dialog
				!showDialog ? false :
				<Dialog
					key={renderKey}
					title={language.Threads}
					onClose={()=>{setShowDialog(false);}}
				>
					<TabBookVertical
						initialTabIndex={initialTabIndex}
						tabLabels={

							// Thread Labels
							threads.map((thread, threadIndex) =>
								<CheckboxLabel
									disabled={true}
									key={threadIndex}
									useCheckmark={true}
									checked={ThreadData.getThreadIsClosed(thread)}
									style={{whiteSpace:"break-spaces", lineHeight: "initial", height:"100%"}}
								>{ThreadData.getThreadName(thread)||<span>&nbsp;</span>}</CheckboxLabel>
							)
						}
						tabPages={

							// Thread Details
							threads.map((thread, threadIndex) =>
								<ThreadModule
									key={threadIndex}
									thread={thread}
									onSaved={() => {
										setRenderKey(renderKey+1);
										setInitialTabIndex(threadIndex);
										onSaved();
									}}
								/>
							)
						}
						tabLabelsFooter={

							// New Thread
							<button
								className="Create"
								onClick={()=>{
									threads["push"](ThreadData.createThread({}));
									setRenderKey(renderKey+1);
									setInitialTabIndex(threads.length-1)
								}}
							>{language.Create_Thread}</button>

						}
					/>
				</Dialog>
			}
		</>
	);
}

export default ThreadsModule;