import React from 'react';
import Language from "../Language/Language";
import ThreadData from "./ThreadData";
import ButtonRegular, {ButtonRegularColor} from "../UI/Burnblade/ButtonRegular";
import TextInput from "../UI/Burnblade/TextInput";
import Label from "../UI/Burnblade/Label";
import {CheckboxLabel} from "../UI/Burnblade/Checkbox";
import TextArea from "../UI/Burnblade/TextArea";
import HorizontalRule from "../UI/Burnblade/HorizontalRule";

function ThreadModule({thread, onSaved}) {

	// input
	const language = Language.getCurrent();

	// display
	return (
		<form
			onSubmit={event => {
				event.preventDefault();
				ThreadData.setThreadName(thread, event.target.name.value);
				ThreadData.setThreadNotes(thread, event.target.notes.value);
				ThreadData.setThreadIsClosed(thread, event.target.isClosed.checked);
				if (onSaved) onSaved();
				return false;
			}}
		>

			{/* Name */}
			<Label text={language.Name} stacked={1}>
				<TextInput name="name" defaultValue={ThreadData.getThreadName(thread)} />
			</Label>
			<br />

			{/* Notes */}
			<Label text={language.Notes} stacked={1}>
				<TextArea name="notes" defaultValue={ThreadData.getThreadNotes(thread)} />
			</Label>
			<br />

			{/* Closed */}
			<CheckboxLabel useCheckmark={true} name="isClosed" defaultChecked={ThreadData.getThreadIsClosed(thread)}>
				{language.Closed}
			</CheckboxLabel>

			<HorizontalRule />

			{/* Save */}
			<ButtonRegular
				type="submit"
				style={{margin:"auto",display:"block"}}
				buttonRegularColor={ButtonRegularColor.Green}
			>{language.Save}</ButtonRegular>

		</form>
	);
}

export default ThreadModule;