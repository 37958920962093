import React from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
	background:url(/assets/burnblade/sidemenu_bg.png);
	background-size:100%;
	height:660px;
	padding-top: 6px;
	box-sizing: border-box;
	overflow: auto;
	
	&::-webkit-scrollbar {
		display: none;
	}

	& > div {

		// Separator
		&:after {
			content:"";
			display:block;
			background:url(/assets/burnblade/sidemenu_tab_bottom.png);
			background-size:100%;
			width:272.5px;
			height:40px;
			margin-top: -18px;
			margin-bottom: -20px;
		}

		// Tab
		& > * {
			width:265px;
			height:66px;
			line-height: 66px;
			background:none;
			border:none;
			text-align: left;
			margin-left:6px;
			box-sizing: border-box;
			padding:0 12px;
			position: relative;
			display: inline-block;
			&:hover { background: url(/assets/burnblade/sidemenu_hover.png) no-repeat; }
			&:active { background: url(/assets/burnblade/sidemenu_push.png) no-repeat; }

			// Selected
			&[data-selected]:after {
				background:url(/assets/burnblade/sidemenu_selected.png);
				background-size:100%;
				width:141.5px;
				height:67.5px;
				content:"";
				display: inline-block;
				position: absolute;
				top: 0;
				right: -20px;
			}
		}
	}

	& > button.Create {
		border:none;
		display: block;
		margin-left: 10px;
		background:url(/assets/burnblade/create_button.png);
		background-size:100% 300%;
		width:255.5px;
		height:59px;
		&:hover { background-position:0 -59px; }
		&:active { background-position:0 -118px; }
		color:#4f4942;
		font-weight:bold;
		text-transform: uppercase;
	}
`;

function List({children, footer}) {

	// display
	return (
		<Wrapper>
			{
				!children ? false :
				!children.map ? <div>{children}</div> :
				children.map((child, childIndex) => <div key={childIndex}>{child}</div>)
			}
			{footer}
		</Wrapper>
	);
}

export default List;