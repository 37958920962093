import React, {Component} from 'react';
import AppWrapper from "./UI/Burnblade/AppWrapper";
import ThreadsModule from "./Adventure/ThreadsModule";
import AdventureData from "./Adventure/AdventureData";
import NavBar from "./UI/Burnblade/NavBar";
import CharactersModule from "./Adventure/CharactersModule";
import SaveLoad from "./Adventure/SaveLoad";
import AdventuresModule from "./Adventure/AdventuresModule";
import ScenesModule from "./Adventure/ScenesModule";
import DicePickerModule from "./UI/DicePickerModule";
import FateModule from "./Adventure/FateModule";
import Language from "./Language/Language";
import MapModule from "./Adventure/MapModule";
import CardsModule from "./UI/CardsModule";
import {setDiceTheme} from "./UI/Dice";
import SceneData from "./Adventure/SceneData";

window.g_diceLoaded = () => {
	const [theme, color] = SaveLoad.loadDiceThemeColor();
	setDiceTheme(theme, color);
};

class App extends Component {

	constructor(props) {
		super(props);
		const selectedAdventureIndex = SaveLoad.getSelectedAdventureIndex()-0;
		const adventures = SaveLoad.getAdventures();
		const selectedAdventure = adventures[selectedAdventureIndex];

		this.state = {
			selectedAdventureIndex:selectedAdventureIndex,
			selectedMapData:false,
			selectedScene:selectedAdventure?AdventureData.getScenes(selectedAdventure)[0]:false
		};

		//Bestiary.forEach(char => { if (char.export_generic_json.gear.length) console.log(char.export_generic_json.gear); })
	}

	render() {

		// input
		const language = Language.getCurrent();
		const {selectedAdventureIndex, selectedMapData, showCards, selectedScene} = this.state;
		const adventures = SaveLoad.getAdventures();
		const selectedAdventure = adventures[selectedAdventureIndex];

		// display
		return (
			<AppWrapper>
				<NavBar atTop={true}>
					<AdventuresModule
						adventures={adventures}
						onSaved={SaveLoad.saveAdventures}
						selectedAdventureIndex={selectedAdventureIndex}
						onChangeAdventureIndex={adventureIndex => {

							// Change Selected Adventure Index
							SaveLoad.saveSelectedAdventureIndex(adventureIndex);
							this.setState({selectedAdventureIndex:adventureIndex});
						}}
					/>
					{
						// Threads
						!selectedAdventure ? false :
						<ThreadsModule
							onSaved={SaveLoad.saveAdventures}
							threads={AdventureData.getThreads(selectedAdventure)}
						/>
					}
					{
						// Characters
						!selectedAdventure ? false :
						<CharactersModule
							onSaved={SaveLoad.saveAdventures}
							characters={AdventureData.getCharacters(selectedAdventure)}
						/>
					}

					{
						// Scenes
						!selectedAdventure ? false :
						<ScenesModule
							onSaved={SaveLoad.saveAdventures}
							scenes={AdventureData.getScenes(selectedAdventure)}
							characters={AdventureData.getCharacters(selectedAdventure)}
							onSelectScene={scene => { this.setState({selectedScene:scene}); }}
						/>
					}

					{
						// Map
						!selectedAdventure || !selectedScene ? false :
						<button onClick={()=>{
							const mapData = selectedMapData ? false : SceneData.getMapData(selectedScene);
							this.setState({selectedMapData:mapData});
						}}>
							{language.Map}
						</button>
					}

					<div />
					<FateModule />
					<DicePickerModule />
					<button onClick={()=>{this.setState({showCards:!showCards});}}>{language.Cards}</button>
				</NavBar>

				{
					// Map
					!selectedMapData ? false :
					<MapModule
						mapData={selectedMapData}
						onSaved={SaveLoad.saveAdventures}
						characters={AdventureData.getCharacters(selectedAdventure)}
						bestiaryStatuses={SceneData.getBestiaryStatuses(selectedScene)}
					/>
				}

				{
					// Cards
					!showCards ? false :
					<CardsModule />
				}

			</AppWrapper>
		);
	}
}

export default App;