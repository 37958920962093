import React from 'react';
import styled from "styled-components";
import WidgetH from "./WidgetH";

const Wrapper = styled.div`
	display:flex;
	flex-flow:column nowrap;

	// Middle Row
	& > div:nth-child(2) { 
		flex:1;
		
		// Middle Middle
		& > div:nth-child(2) {
			background-repeat: repeat;
			background-position: center center;
		}
	}
`;

function Widget9({children, widthByCol, heightByRow, backgroundImagesByRow, backgroundPositionsByRow}) {

	// input

	// display
	return (
		<Wrapper>
			<WidgetH widths={widthByCol} height={heightByRow[0]} backgroundImages={backgroundImagesByRow[0]} backgroundPositions={backgroundPositionsByRow[0]}></WidgetH>
			<WidgetH widths={widthByCol} height={heightByRow[1]} backgroundImages={backgroundImagesByRow[1]} backgroundPositions={backgroundPositionsByRow[1]}>{children}</WidgetH>
			<WidgetH widths={widthByCol} height={heightByRow[2]} backgroundImages={backgroundImagesByRow[2]} backgroundPositions={backgroundPositionsByRow[2]}></WidgetH>
		</Wrapper>
	);
}

export default Widget9;