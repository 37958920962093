import React from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
	background: url(/assets/burnblade/nav_bar.png);
	background-size:auto 200%;
	height:81px;
	max-width:1239px;
	margin:auto;
	position:absolute;
	left:0;
	right:0;
	bottom:0;
	display:flex;
	flex-flow:row nowrap;
	align-items: flex-end;
	overflow-x: auto;
	overflow-y:hidden;
	
	& > div {
		display:flex;
		flex-flow:row nowrap;
		align-items: center;
		& > button {
			background:none;
			border:none;
			height:62px;
			padding:0 20px;
			position: relative;
			z-index: 1;
			&:hover {  background:url(/assets/burnblade/nav_bar_hover.png); }
		}
		&:after,
		&:first-child:before {
			background: url(/assets/burnblade/nav_bar_seperator.png);
			width: 69px;
			height: 74px;
			content: "";
			display: block;
			margin-left: -32px;
			margin-right: -24px;
			background-size: 100%;
		}
		&:first-child:before { margin-left:-35px }
		&:last-child:after { display: none; }
		&[data-child-type='button'] { height:74px; }
	}
	
	&[data-top] {
		top:0;
		bottom:auto;
		background-position: 0 -81px;
		align-items: flex-start;
		& > div {
			&:before,
			&:last-child:after {
				background-position: 0 -72px;
			}
		}
	}
	
	& > div[data-child-type='div'] {
		flex:1;
		& > div { width:100%; }
		& + div:last-child:after { width:130px; }
	}
`;

function NavBar({children, atTop}) {

	// display
	return (
		<Wrapper data-top={atTop?1:undefined}>
			{
				!children?false:
				!children.map ? <div>{children}</div> :
				children.map((child, childIndex) => !child ? false : <div key={childIndex} data-child-type={child.type.toString()}>{child}</div>)
			}
		</Wrapper>
	);
}

export default NavBar;