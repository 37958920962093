import React, {useState} from 'react';
import Language from "../Language/Language";
import Dialog, {TitleWrapperWithButton} from "../UI/Burnblade/Dialog";
import Spinner from "../UI/Burnblade/Spinner";
import styled from "styled-components";
import HorizontalRule from "../UI/Burnblade/HorizontalRule";
import ButtonRegular, {ButtonRegularColor} from "../UI/Burnblade/ButtonRegular";
import {hideDice, rollDice} from "../UI/Dice";
import RandomEventModule from "./RandomEventModule";

const RangeTableRow = styled.div`
	display:flex;
	flex-flow:row nowrap;
	padding:0.5em 1em;
	& > span:first-child { flex:1; }
	&[data-selected] { background:rgba(255,255,0,0.25); }
`;

function StartSceneModule({}) {

	// input
	const language = Language.getCurrent();
	const [showDialog, setShowDialog] = useState(false);
	const [chaosFactor, setChaosFactor] = useState(5);
	const [total, setTotal] = useState("");
	const alteredValues=[], interruptedValues=[], noChangeValues=[];
	for(let value=1; value<=chaosFactor; value++) (value%2 ? alteredValues : interruptedValues).push(value);
	for(let value=chaosFactor+1; value<=10; value++) noChangeValues.push(value);

	// display
	return (
		<>
			<ButtonRegular onClick={()=>{setShowDialog(true);}}>{language.Start}</ButtonRegular>

			{
				// Start Scene Dialog
				!showDialog ? false :
				<Dialog
					title={

						<TitleWrapperWithButton>

							{/* Title Text */}
							<div>{language.Start_Scene}</div>

							{/* Random Event */}
							<RandomEventModule
								buttonRegularColor={-1 < alteredValues.indexOf(total) || -1 < interruptedValues.indexOf(total) ? ButtonRegularColor.Green : undefined}
							/>

						</TitleWrapperWithButton>
					}
					onClose={()=>{setShowDialog(false); hideDice(); }}
				>

					{/* Chaos Factor */}
					<Spinner
						min={1}
						max={10}
						step={1}
						inline={true}
						onChange={setChaosFactor}
						initialValue={chaosFactor}
						text={language.Chaos_Factor}
					/>

					<HorizontalRule />

					{/* Ranges */}
					<RangeTableRow data-selected={-1 < alteredValues.indexOf(total) ? 1 : undefined}><span>{alteredValues.join(", ")}</span><span>{language.Altered}</span></RangeTableRow>
					<RangeTableRow data-selected={-1 < interruptedValues.indexOf(total) ? 1 : undefined}><span>{interruptedValues.join(", ")}</span><span>{language.Interrupted}</span></RangeTableRow>
					<RangeTableRow data-selected={-1 < noChangeValues.indexOf(total) ? 1 : undefined}><span>{noChangeValues.join(", ")}</span><span>{language.No_Modification}</span></RangeTableRow>

					<HorizontalRule />

					{/* Roll */}
					<div style={{textAlign:"center"}}>
						<ButtonRegular
							buttonRegularColor={ButtonRegularColor.Green}
							onClick={()=>{
								setTotal();
								rollDice("1d10", false, results => { setTotal(results[0].value); });
							}}
						>{language.Roll}</ButtonRegular>

						{/* Clear */}
						<ButtonRegular
							onClick={() => {
								setTotal();
								hideDice();
							}}
						>{language.Clear}</ButtonRegular>
					</div>

				</Dialog>
			}
		</>
	);
}

export default StartSceneModule;