import styled from "styled-components";

const SideBySide = styled.div`
	justify-content:space-between;
	width:100%;
	display:flex;
	flex-flow:row wrap;
	& > * { 
		flex:1;
		input[type='text'] { width:30px; }
	}
`;

export default SideBySide;