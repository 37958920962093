import React from 'react';
import Language from "../Language/Language";
import Label from "../UI/Burnblade/Label";
import TextInput from "../UI/Burnblade/TextInput";
import AdventureData from "./AdventureData";
import TextArea from "../UI/Burnblade/TextArea";
import ButtonRegular, {ButtonRegularColor} from "../UI/Burnblade/ButtonRegular";
import HorizontalRule from "../UI/Burnblade/HorizontalRule";

function AdventureModule({adventure, onSaved}) {

	// input
	const language = Language.getCurrent();

	// display
	return (
		<form
			onSubmit={event => {
				event.preventDefault();
				AdventureData.setName(adventure, event.target.name.value);
				AdventureData.setNotes(adventure, event.target.notes.value);
				if (onSaved) onSaved();
				return false;
			}}
		>
			{/* Name */}
			<Label text={language.Name} stacked={true}>
				<TextInput name="name" defaultValue={AdventureData.getName(adventure)} />
			</Label>
			<br />

			{/* Notes */}
			<Label text={language.Notes} stacked={true}>
				<TextArea name="notes" defaultValue={AdventureData.getNotes(adventure)} />
			</Label>
			<br />

			<HorizontalRule />

			{/* Save */}
			<ButtonRegular
				type="submit"
				style={{margin:"auto",display:"block"}}
				buttonRegularColor={ButtonRegularColor.Green}
			>{language.Save}</ButtonRegular>

		</form>
	);
}

export default AdventureModule;