import React from 'react';
import styled from "styled-components";

const Wrapper = styled.select`
	background:#191411;
	border:solid 1px #453c36;
	border-radius: 1px;
	height:40px;
	line-height: 40px;
	padding: 0 0.25em;
	outline: none;

	appearance: none;
	padding-right:40px;
	vertical-align: middle;
	& + span {
		vertical-align: middle;
		display:inline-block;
		background:url(/assets/burnblade/arrow_button.png);
		background-size:300% 100%;
		width:30px;
		height:30px;
		margin-left:-35px;
	}
	&:hover + span { background-position: -30px 0; }
	&:active + span { background-position: -600px 0; }
`;

function SelectBox({children, ...props}) {

	// input

	// display
	return (
		<>
			<Wrapper {...props}>{children}</Wrapper>
			<span />
		</>
	);
}

export default SelectBox;