import React, {useState} from 'react';
import Language from "../Language/Language";
import SceneData from "./SceneData";
import ButtonRegular, {ButtonRegularColor} from "../UI/Burnblade/ButtonRegular";
import TextInput from "../UI/Burnblade/TextInput";
import Label from "../UI/Burnblade/Label";
import TextArea from "../UI/Burnblade/TextArea";
import HorizontalRule from "../UI/Burnblade/HorizontalRule";
import Spinner from "../UI/Burnblade/Spinner";
import TabBookHorizontal from "../UI/Burnblade/TabBookHorizontal";
import SceneModuleMap from "./SceneModuleMap";
import MapData from "./MapData";
import SceneModuleCharacters from "./SceneModuleCharacters";
import MapTokenData from "./MapTokenData";

function SceneModule({scene, onSaved, characters}) {

	// input
	const language = Language.getCurrent();
	const [bestiaryIds, setBestiaryIds] = useState(SceneData.getBestiaryIds(scene).slice());
	const [characterIds, setCharacterIds] = useState(SceneData.getCharacterIds(scene).slice());

	// display
	return (
		<form
			onSubmit={event => {
				event.preventDefault();

				// scene data
				SceneData.setSceneName(scene, event.target.name.value);
				SceneData.setSceneNotes(scene, event.target.notes.value);
				SceneData.setSceneLocation(scene, event.target.location.value);
				SceneData.setSceneSetup(scene, event.target.setup.value);
				SceneData.setSceneChaosFactor(scene, event.target.chaosFactor.value);
				SceneData.setSceneCharacterIds(scene, characterIds.slice());
				SceneData.setSceneBestiaryIds(scene, bestiaryIds.slice());

				// map data
				const mapData = SceneData.getMapData(scene);
				MapData.setMapImageUrl(mapData, event.target.mapImageUrl.value);
				MapData.setMapWidth(mapData, event.target.mapWidth.value-0);
				MapData.setMapHeight(mapData, event.target.mapHeight.value-0);

				// character tokens
				// keep original tokens with matching character
				const newCharacterTokens = MapData.getMapCharacterTokens(mapData).filter(characterToken => -1 < characterIds.indexOf(MapTokenData.getMapTokenCharacterId(characterToken)));
				characterIds.forEach(characterId => {
					// add new tokens
					if (!newCharacterTokens.find(characterToken => characterId === MapTokenData.getMapTokenCharacterId(characterToken)))
						newCharacterTokens["push"](MapTokenData.createMapToken({
							posX:500,
							posY:500,
							width:100,
							height:100,
							characterId:characterId
						}));
				});
				MapData.setMapCharacterTokens(mapData, newCharacterTokens);

				// bestiary tokens
				const newBestiaryTokens = [];
				bestiaryIds.forEach((bestiaryId, bestiaryIndex) => {
					const bestiaryToken = MapData.getMapBestiaryTokens(mapData)[bestiaryIndex] || MapTokenData.createMapToken({
						posX:500,
						posY:500,
						width:100,
						height:100,
						bestiaryId:bestiaryId
					});
					MapTokenData.setMapTokenBestiaryId(bestiaryToken, bestiaryId);
					newBestiaryTokens.push(bestiaryToken);
				});
				MapData.setMapBestiaryTokens(mapData, newBestiaryTokens);

				// done
				if (onSaved) onSaved();
				return false;
			}}
		>

			<TabBookHorizontal
				initialTabIndex={0}
				hiddenPages={true}
				tabLabels={[
					language.Scene,
					language.Description,
					language.Map,
					language.Characters,
				]}
				tabPages={[

					// Scene
					<div>

						{/* Name */}
						<Label text={language.Name} stacked={1}>
							<TextInput name="name" defaultValue={SceneData.getSceneName(scene)} />
						</Label>
						<br />

						{/* Notes */}
						<Label text={language.Notes} stacked={1}>
							<TextArea name="notes" defaultValue={SceneData.getSceneNotes(scene)} />
						</Label>

					</div>,

					// Description
					<div>

						{/* Location */}
						<Label text={language.Location} stacked={1}>
							<TextInput name="location" defaultValue={SceneData.getSceneLocation(scene)} />
						</Label>
						<br />

						{/* Chaos Factor */}
						<Spinner
							min={1}
							max={10}
							step={1}
							name="chaosFactor"
							text={language.Chaos_Factor}
							initialValue={SceneData.getSceneChaosFactor(scene)}
						/>
						<br />

						{/* Setup */}
						<Label text={language.Setup} stacked={1}>
							<TextArea name="setup" defaultValue={SceneData.getSceneSetup(scene)} />
						</Label>

					</div>,

					// Map
					<SceneModuleMap scene={scene} />,

					// Characters
					<SceneModuleCharacters
						scene={scene}
						characters={characters}
						setBestiaryIds={setBestiaryIds}
						setCharacterIds={setCharacterIds}
						bestiaryIds={bestiaryIds.slice()}
						characterIds={characterIds.slice()}
					/>
				]}
			/>

			<HorizontalRule />

			{/* Save */}
			<ButtonRegular
				type="submit"
				style={{margin:"auto",display:"block"}}
				buttonRegularColor={ButtonRegularColor.Green}
			>{language.Save}</ButtonRegular>

		</form>
	);
}

export default SceneModule;