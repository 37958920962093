import React, {useState} from 'react';
import Language from "../Language/Language";
import TextInput from "../UI/Burnblade/TextInput";
import styled from "styled-components";
import {ButtonPlus} from "../UI/Burnblade/ButtonSmall";
import Dialog from "../UI/Burnblade/Dialog";
import {isDefined} from "../Helpers";

const Wrapper = styled.div`
	display:flex;
	flex-flow:column nowrap;
	width:50vw;
	height:50vh;
	
	& > * {
		margin:1em;
		
		// Search
		&:first-child {
		}
		
		// Results
		&:nth-child(2) {
			flex:1;
			margin-top:0;
		}
	}
`;

function ListItemSelectModule({list, onSelect, getItemId, getItemName, getItemSummary, current}) {

	// input
	const language = Language.getCurrent();
	const [searchFor, setSearchFor] = useState("");
	const searchFormatted = searchFor.toLowerCase().trim();
	if (!getItemSummary) getItemSummary = item => item.summary;

	// display
	return (
		<Wrapper>

			{/* Search */}
			<label>
				<TextInput
					type="search"
					value={searchFor}
					placeholder={language.Search}
					onChange={event=>{setSearchFor(event.target.value);}}
				/>
			</label>

			{/* Results */}
			<div className="ScrollVertical">
				{
					!current ? false :
					<ListItemButton
						name={language.None}
						style={{fontStyle:"italic"}}
						description={language.Remove_Selected}
						onClick={() => { onSelect(); }}
					/>
				}
				{
					(""===searchFormatted?list:list.filter(item =>
						-1 < (getItemName(item)+" "+getItemSummary(item)).toLowerCase().indexOf(searchFormatted)
					)).map(item =>
						<ListItemButton
							type="button"
							key={getItemId(item)}
							name={getItemName(item)}
							description={getItemSummary(item)}
							onClick={() => {onSelect(getItemId(item)); }}
							data-current={current===item ? 1 : undefined}
						/>
					)
				}
			</div>
		</Wrapper>
	);
}


const ListItemWrapper = styled.button`
	padding:1em;
	background:none;
	border:none;
	display:block;
	text-align: left;
	width:100%;
	box-sizing: border-box;
	
	& > span {
		display:block;
		
		&:nth-child(2) {
			color:#746c65;
			font-size:0.9em;
		}
	}
	
	&[data-current] {
		color:White;
	}
	
	&:hover { background:rgba(0,0,0,0.25); }
`;

function ListItemButton({name, description, ...props})
{
	return (
		<ListItemWrapper type="button" {...props}>
			<span>{name}</span>
			<span>{description}</span>
		</ListItemWrapper>
	)
}



function ListDialog({title, setDialog, list, onSelect, getItemId, getItemName, getItemSummary, current})
{
	return (
		<Dialog title={title} onClose={() => { setDialog(); }}>
			<ListItemSelectModule
				list={list}
				current={current}
				onSelect={onSelect}
				getItemId={getItemId}
				getItemName={getItemName}
				getItemSummary={getItemSummary}
			/>
		</Dialog>
	);
}

const GroupWrapper = styled.div`
	text-align: left;
	
	// Section Header
	& > label:first-child {
		font-size: 1.25em;
		display: inline-flex;
		flex-flow: row nowrap;
		align-items: center;
		margin-bottom: 1rem;
		padding: 0.5rem;

		& > button {
			zoom: 0.5;
			margin-right: 1em;
			display: inline-block;
			vertical-align: middle;
		}
	}
`;

function ListItemGroupModule({title, allItems, currentItemIds, setCurrentItemIds, getItemId, getItemName, getItemSummary, readOnly})
{
	// input
	const [dialog, setDialog] = useState(undefined);
	if (!getItemSummary) getItemSummary = item => item.summary;

	// display
	return (
		<GroupWrapper>

			{/* Title, Add */}
			<label>
				{
					readOnly ? false :
					<ButtonPlus
						type="button"
						onClick={() => {

							// New
							setDialog(
								<ListDialog
									list={allItems}
									title={title}
									setDialog={setDialog}
									getItemId={getItemId}
									getItemName={getItemName}
									getItemSummary={getItemSummary}
									onSelect={newItemId => {
										if (isDefined(newItemId))
										{
											currentItemIds.push(newItemId);
											setCurrentItemIds(currentItemIds)
										}
										setDialog();
									}}
								/>
							);
						}}
					/>
				}
				{title}
			</label>

			{/* Current */}
			<div>
				{
					currentItemIds.map((itemId, itemIndex) => {
						const item = allItems.find(item => getItemId(item) === itemId);
						return (
							<ListItemButton
								key={itemIndex}
								name={getItemName(item)}
								description={getItemSummary(item)}
								onClick={readOnly ? undefined : () => {

									// Re-Select Item
									setDialog(
										<ListDialog
											title={title}
											list={allItems}
											current={item}
											setDialog={setDialog}
											getItemId={getItemId}
											getItemName={getItemName}
											getItemSummary={getItemSummary}
											onSelect={newItemId => {
												if (isDefined(newItemId)) currentItemIds[itemIndex] = newItemId;
												else currentItemIds.splice(itemIndex, 1);
												setCurrentItemIds(currentItemIds);
												setDialog();
											}}
										/>
									)
								}}
							/>
						);
					})
				}
			</div>
			{dialog}
		</GroupWrapper>
	);
}

export default ListItemGroupModule;