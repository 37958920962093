import character from "../UI/Burnblade/Character";
import Armor from "../Savaged/Armor";

const CharacterStatusData = new function() {
	const this_ = this;

	// create
	this_.createCharacterStatus = ({
		headshotUrl, notes, armorItemIds, weaponItemIds, gearItemIds,
		bennies, wounds, fatigue, isIncapacitated, isStunned, isBound, isShaken,
	}) => {
		return {
			bennies_:bennies||0,
			wounds_:wounds||0,
			fatigue_:fatigue||0,
			isIncapacitated_:isIncapacitated||0,
			isStunned_:isStunned||0,
			isBound_:isBound||0,
			isShaken_:isShaken||0,
			notes_:notes||"",
			armorItemIds_:armorItemIds||[],
			weaponItemIds_:weaponItemIds||[],
			gearItemIds_:gearItemIds||[],
			headshotUrl_:headshotUrl||"",
		}
	};

	// accessors
	this_.getCharacterNotes = character => character.notes_;
	this_.getCharacterArmorItemIds = character => character.armorItemIds_;
	this_.getCharacterWeaponItemIds = character => character.weaponItemIds_;
	this_.getCharacterGearItemIds = character => character.gearItemIds_;
	this_.getCharacterBennies = character => character.bennies_;
	this_.getCharacterWounds = character => character.wounds_;
	this_.getCharacterFatigue = character => character.fatigue_;
	this_.getCharacterIsIncapacitated = character => character.isIncapacitated_;
	this_.getCharacterIsStunned = character => character.isStunned_;
	this_.getCharacterIsBound = character => character.isBound_;
	this_.getCharacterIsShaken = character => character.isShaken_;
	this_.getCharacterHeadshotUrl = character => character.headshotUrl_;
	this_.getCharacterArmor = character => {
		let armor = 0;
		this_.getCharacterArmorItemIds(character).forEach(armorItemId => {
			Armor.find(({id, armor_value}) => {
				if (id === armorItemId) armor += armor_value;
			});
		});
		return armor;
	};

	// modifiers
	this_.setCharacterNotes = (character, value) => character.notes_ = value;
	this_.setCharacterArmorItemIds = (character, value) => character.armorItemIds_ = value;
	this_.setCharacterWeaponItemIds = (character, value) => character.weaponItemIds_ = value;
	this_.setCharacterGearItemIds = (character, value) => character.gearItemIds_ = value;
	this_.setCharacterBennies = (character, value) => character.bennies_ = value;
	this_.setCharacterWounds = (character, value) => character.wounds_ = value;
	this_.setCharacterFatigue = (character, value) => character.fatigue_ = value;
	this_.setCharacterIsIncapacitated = (character, value) => character.isIncapacitated_ = value;
	this_.setCharacterIsStunned = (character, value) => character.isStunned_ = value;
	this_.setCharacterIsBound = (character, value) => character.isBound_ = value;
	this_.setCharacterIsShaken = (character, value) => character.isShaken_ = value;
	this_.setCharacterHeadshotUrl = (character, value) => character.headshotUrl_ = value;

	// save data
	this_.getSaveData = character => [
		this_.getCharacterNotes(character),
		this_.getCharacterArmorItemIds(character),
		this_.getCharacterWeaponItemIds(character),
		this_.getCharacterGearItemIds(character),
		this_.getCharacterBennies(character),
		this_.getCharacterWounds(character),
		this_.getCharacterFatigue(character),
		this_.getCharacterIsIncapacitated(character),
		this_.getCharacterIsStunned(character),
		this_.getCharacterIsBound(character),
		this_.getCharacterIsShaken(character),
		this_.getCharacterHeadshotUrl(character),
	];

	// load data
	this_.loadData = data => this_.createCharacterStatus({
		notes:data[0],
		armorItemIds:data[1],
		weaponItemIds:data[2],
		gearItemIds:data[3],
		bennies:data[4],
		wounds:data[5],
		fatigue:data[6],
		isIncapacitated:data[7],
		isStunned:data[8],
		isBound:data[9],
		isShaken:data[10],
		headshotUrl:data[11]
	});
};

export default CharacterStatusData;