const Gear = [
	{
		"id": 277,
		"cost": 30,
		"name": "\"Bug\" (Micro Transmitter)",
		"type": "Surveillance",
		"uuid": "8dbc924b-23d4-4b62-9f28-e731c26afa9f",
		"active": true,
		"weight": 0,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p68",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "\"Bugs\" (Micro Transmitters)",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 1318,
		"cost": 0.5,
		"name": "Arrow",
		"type": "Ammunition",
		"uuid": "9a38fe79-548f-4362-ab27-c93fdc87d1db",
		"active": true,
		"weight": 0.2,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p64",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 1319,
		"cost": 1,
		"name": "Arrow, Man-Killer",
		"type": "Ammunition",
		"uuid": "656760a4-7d4e-4ad9-837a-3f2e82b115f7",
		"active": true,
		"weight": 0.2,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p64",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 1320,
		"cost": 5,
		"name": "Arrow, Teflon",
		"type": "Ammunition",
		"uuid": "f922d2c6-853d-435c-3c5b-8c1b34f4b2b0",
		"active": true,
		"weight": 0.2,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p64",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 285,
		"cost": 0.5,
		"name": "Arrow/Quarrel",
		"type": "Ammo",
		"uuid": "8a0266d1-d216-4fea-8b75-f3bf02a079c0",
		"active": true,
		"weight": 0.2,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p68",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Arrows/Quarrels",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 1,
		"cost": 50,
		"name": "Backpack",
		"type": "Adventuring Gear",
		"uuid": "8a8fc849-0437-4ffd-b550-f1fad1351a41",
		"active": true,
		"weight": 2,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p59",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": true,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Backpacks",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 222,
		"cost": 50,
		"name": "Backpack",
		"type": "Adventuring Gear",
		"uuid": "0b12702a-d25c-4036-3ebf-b4a41115c94a",
		"active": true,
		"weight": 2,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p67",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": true,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Backpacks",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 2,
		"cost": 25,
		"name": "Bedroll",
		"type": "Adventuring Gear",
		"uuid": "8159ecf2-bc89-442a-9c4b-6cfe469aec14",
		"active": true,
		"weight": 4,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p59",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Bedrolls",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 223,
		"cost": 25,
		"name": "Bedroll (sleeping bag; winterized)",
		"type": "Adventuring Gear",
		"uuid": "4332ee1f-1e4c-487b-3644-718759e5ab19",
		"active": true,
		"weight": 4,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p67",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Bedrolls (sleeping bags; winterized)",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 268,
		"cost": 100,
		"name": "Bipod/Tripod",
		"type": "Firearm Accessories",
		"uuid": "4e21c2ae-c069-4e61-89d0-0ed306fba33b",
		"active": true,
		"weight": 2,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p68",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Bipods/Tripods",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 3,
		"cost": 10,
		"name": "Blanket",
		"type": "Adventuring Gear",
		"uuid": "90422aed-4127-4a59-8e9a-641fc99d6bb4",
		"active": true,
		"weight": 4,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p59",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Blankets",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 224,
		"cost": 10,
		"name": "Blanket",
		"type": "Adventuring Gear",
		"uuid": "2834afbb-8db2-4a20-94c5-50cb0445112d",
		"active": true,
		"weight": 4,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p67",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Blankets",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 258,
		"cost": 100,
		"name": "Boots, Hiking",
		"type": "Clothing",
		"uuid": "b323996f-4398-49c4-35d5-46b47aae5bab",
		"active": true,
		"weight": 2,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p68",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Boots, Hiking",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 288,
		"cost": 1,
		"name": "Bullet, Large",
		"type": "Ammo",
		"uuid": "88da842a-7006-41b0-24de-bbcc33191a2e",
		"active": true,
		"weight": 0.3,
		"book_id": 9,
		"effects": [],
		"summary": ".50+ caliber",
		"bookPage": "p68",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": ".50+ caliber",
		"name_plural": "Bullets, Large",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 287,
		"cost": 0.4,
		"name": "Bullet, Medium ",
		"type": "Ammo",
		"uuid": "a26263e0-0674-4738-a0b4-4a3f1401d8f7",
		"active": true,
		"weight": 0.04,
		"book_id": 9,
		"effects": [],
		"summary": "9mm to .45 caliber",
		"bookPage": "p68",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "9mm to .45 caliber",
		"name_plural": "Bullets, Medium",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 286,
		"cost": 0.2,
		"name": "Bullet, Small ",
		"type": "Ammo",
		"uuid": "1ff4f6cd-d201-4ffd-bc45-02d4a608d3ac",
		"active": true,
		"weight": 0.02,
		"book_id": 9,
		"effects": [],
		"summary": ".22 to .32 caliber",
		"bookPage": "p68",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": ".22 to .32 caliber",
		"name_plural": "Bullets, Small",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 1323,
		"cost": 1,
		"name": "Bullets, Large",
		"type": "Ammunition",
		"uuid": "bbe0f08e-0104-4128-903a-f13f2575286f",
		"active": true,
		"weight": 0.16,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p64",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 1322,
		"cost": 0.5,
		"name": "Bullets, Medium",
		"type": "Ammunition",
		"uuid": "98198712-9ef5-4233-371c-781324f7f49a",
		"active": true,
		"weight": 0.1,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p64",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 1321,
		"cost": 0.2,
		"name": "Bullets, Small",
		"type": "Ammunition",
		"uuid": "e3d39b81-ced7-4846-2f87-f738734138e1",
		"active": true,
		"weight": 0.06,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p64",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 278,
		"cost": 50,
		"name": "Button Camera",
		"type": "Surveillance",
		"uuid": "d379e48d-8fd6-4810-2be5-c5f1459f3971",
		"active": true,
		"weight": 0,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p68",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Button Cameras",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 6,
		"cost": 300,
		"name": "Camera (digital)",
		"type": "Adventuring Gear",
		"uuid": "2549c397-824b-44aa-0814-d2bbf8aecd1d",
		"active": true,
		"weight": 1,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p59",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Cameras (digital)",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 4,
		"cost": 10,
		"name": "Camera (disposable)",
		"type": "Adventuring Gear",
		"uuid": "0b3567df-a090-4c8b-8255-145bbf90859c",
		"active": true,
		"weight": 1,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p59",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Cameras (disposable)",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 225,
		"cost": 10,
		"name": "Camera (disposable)",
		"type": "Adventuring Gear",
		"uuid": "0e228270-919d-48e8-103e-5adcce9268b0",
		"active": true,
		"weight": 1,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p67",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Cameras (disposable)",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 5,
		"cost": 75,
		"name": "Camera (regular)",
		"type": "Adventuring Gear",
		"uuid": "7d497e64-d6ca-44c1-0cd5-431450915923",
		"active": true,
		"weight": 2,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p59",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Camera2 (regular)",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 226,
		"cost": 75,
		"name": "Camera (regular)",
		"type": "Adventuring Gear",
		"uuid": "5acb6ce4-2926-49cf-ac60-d7d33812acd4",
		"active": true,
		"weight": 2,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p67",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Cameras (regular)",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 227,
		"cost": 300,
		"name": "Cameras (digital)",
		"type": "Adventuring Gear",
		"uuid": "4a09c4f9-6977-4727-86e9-9cac87de6afd",
		"active": true,
		"weight": 1,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p67",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Camera (digital)",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 30,
		"cost": 20,
		"name": "Camouflage Fatigues",
		"type": "Clothing",
		"uuid": "19a39a90-ad90-429e-28e5-4eda170cb60e",
		"active": true,
		"weight": 0,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p59",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Camouflage Fatigues",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 259,
		"cost": 20,
		"name": "Camouflage Fatigues",
		"type": "Clothing",
		"uuid": "c4c8a46f-1dd4-418c-2166-47b48f773f7b",
		"active": true,
		"weight": 3,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p68",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Camouflage Fatigues",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 7,
		"cost": 1,
		"name": "Candle",
		"type": "Adventuring Gear",
		"uuid": "797a4b6f-4443-43ca-3086-fffb2cd01618",
		"active": true,
		"weight": 1,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p59",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Candles",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 228,
		"cost": 1,
		"name": "Candle (one hour, 2” radius)",
		"type": "Adventuring Gear",
		"uuid": "fa494b38-fcd0-45ad-259a-300f2f5ccece",
		"active": true,
		"weight": 1,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p67",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Candles (one hour, 2” radius)",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 1008,
		"cost": 50,
		"name": "Cannon Canister Shot",
		"type": "Ammo",
		"uuid": "2e260af8-a6f2-4e30-87a9-49a4fcff3189",
		"active": true,
		"weight": 12,
		"book_id": 9,
		"effects": [],
		"summary": "24\" path, 2d6 damage, MBT",
		"bookPage": "p77",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 1010,
		"cost": 50,
		"name": "Cannon Shrapnel Shot",
		"type": "Ammo",
		"uuid": "e7af067f-aa2c-40d3-3770-998b64661a68",
		"active": true,
		"weight": 12,
		"book_id": 9,
		"effects": [],
		"summary": "50/100/200, 3d6, MBT",
		"bookPage": "p77",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 1009,
		"cost": 50,
		"name": "Cannon Solid Shot",
		"type": "Ammo",
		"uuid": "355ab660-f93f-4b66-a597-6e449b3332e7",
		"active": true,
		"weight": 12,
		"book_id": 9,
		"effects": [],
		"summary": "50/100/200, 3d6+1, AP 4",
		"bookPage": "p77",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 8,
		"cost": 1,
		"name": "Canteen (waterskin)",
		"type": "Adventuring Gear",
		"uuid": "7c6e36af-e417-4320-1913-68fa5576b8a3",
		"active": true,
		"weight": 5,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p59",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Canteens (waterskins)",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 229,
		"cost": 5,
		"name": "Canteen (waterskin)",
		"type": "Adventuring Gear",
		"uuid": "ffc0a789-4584-4a31-392d-524330c38649",
		"active": true,
		"weight": 1,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p67",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Canteens (waterskin)",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 3502,
		"cost": 10,
		"name": "Cape Killer Bullet",
		"type": "Ammo",
		"uuid": "92d96618-d172-4294-2692-00c1e69a828d",
		"active": true,
		"weight": 0.5,
		"book_id": 9,
		"effects": [],
		"summary": ".45+ caliber, AP4, Heavy Weapon",
		"bookPage": "18",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": ".45+ caliber",
		"name_plural": "Bullets, Large",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 48,
		"cost": 650,
		"name": "Cellular Interceptor",
		"type": "Surveillance",
		"uuid": "54a53e22-822f-4d12-2eb0-a77b33988eb4",
		"active": true,
		"weight": 5,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p59",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Cellular Interceptors",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 279,
		"cost": 650,
		"name": "Cellular Interceptor",
		"type": "Surveillance",
		"uuid": "d4702de4-e1f2-44ed-932e-a54a79af2be5",
		"active": true,
		"weight": 5,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p68",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Cellular Interceptors",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 9,
		"cost": 100,
		"name": "Cellular Phone",
		"type": "Adventuring Gear",
		"uuid": "f267f1d7-2652-447f-a429-1a4b57310f7b",
		"active": true,
		"weight": 0,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p59",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Cellular Phones",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 230,
		"cost": 100,
		"name": "Cellular Phone",
		"type": "Adventuring Gear",
		"uuid": "7e4def25-c62f-4ca6-8381-ec4c48ef754b",
		"active": true,
		"weight": 0,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p67",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Cellular Phones",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 260,
		"cost": 20,
		"name": "Clothing, Casual",
		"type": "Clothing",
		"uuid": "a218c770-972f-4afa-3673-43524975ac05",
		"active": true,
		"weight": 2,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p68",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Clothing, Casual",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 261,
		"cost": 200,
		"name": "Clothing, Formal",
		"type": "Clothing",
		"uuid": "5a84e06b-74fc-4f9b-1dc1-88c3a2679bc8",
		"active": true,
		"weight": 3,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p68",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Clothing, Formal",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 10,
		"cost": 10,
		"name": "Crowbar",
		"type": "Adventuring Gear",
		"uuid": "ab658ae7-4d12-4821-2519-444e8a53bd3b",
		"active": true,
		"weight": 2,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p59",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Crowbars",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 231,
		"cost": 10,
		"name": "Crowbar",
		"type": "Adventuring Gear",
		"uuid": "8bc85699-563f-455b-0801-ed199b6270df",
		"active": true,
		"weight": 2,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p67",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Crowbars",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 44,
		"cost": 800,
		"name": "Desktop Computer",
		"type": "Computers",
		"uuid": "6f21e065-61c4-46f3-a062-8069295db560",
		"active": true,
		"weight": 20,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p59",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Desktop Computers",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 264,
		"cost": 800,
		"name": "Desktop Computer",
		"type": "Computers and Electronics",
		"uuid": "8187d601-b2f8-4839-b255-bf3edea7bc0d",
		"active": true,
		"weight": 20,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p68",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Desktop Computers",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 257,
		"cost": 50,
		"name": "Elaborate Saddle",
		"type": "Animals and Tack",
		"uuid": "25ad9c90-9dec-41f6-099b-75be615fca1f",
		"active": true,
		"weight": 10,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p67",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Elaborate Saddles",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 271,
		"cost": 8,
		"name": "Fast Food Meal",
		"type": "Food",
		"uuid": "c0c21365-de04-4002-aec4-d92430578f5b",
		"active": true,
		"weight": 1,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p68",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Fast Food Meals",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 36,
		"cost": 5,
		"name": "Fast Food Meal (cheap meal)",
		"type": "Food",
		"uuid": "2131abf4-19b0-4fbb-a179-9b5c017ccf82",
		"active": true,
		"weight": 1,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p59",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Fast Food Meal (cheap meal)",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 232,
		"cost": 10,
		"name": "First Aid Kit (basic supplies)",
		"type": "Adventuring Gear",
		"uuid": "1d9daef9-04b1-4dc0-17bd-da16a2fbbe2c",
		"active": true,
		"weight": 1,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p67",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "First Aid Kits (basic supplies)",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 11,
		"cost": 20,
		"name": "Flashlight",
		"type": "Adventuring Gear",
		"uuid": "8142790b-a7a3-4fac-88ba-f07d4aa16cc4",
		"active": true,
		"weight": 3,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p59",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Flashlights",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 233,
		"cost": 20,
		"name": "Flashlight (10” beam)",
		"type": "Adventuring Gear",
		"uuid": "49d6ca14-b7be-4f8f-0617-aa7844577d53",
		"active": true,
		"weight": 3,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p67",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Flashlights (10” beam)",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 234,
		"cost": 5,
		"name": "Flask (ceramic)",
		"type": "Adventuring Gear",
		"uuid": "c070f51c-5c2c-4cf6-9ca5-3328f64045bc",
		"active": true,
		"weight": 1,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p67",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Flasks (ceramic)",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 12,
		"cost": 5,
		"name": "Flasks (ceramic)",
		"type": "Adventuring Gear",
		"uuid": "a9afc9ab-995d-4445-1691-195795fb3538",
		"active": true,
		"weight": 1,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p59",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Flask (ceramic)",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 13,
		"cost": 3,
		"name": "Flint and Steel",
		"type": "Adventuring Gear",
		"uuid": "9757f3db-9643-4163-b683-432f2fd60e9f",
		"active": true,
		"weight": 1,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p59",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Flint and Steel",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 235,
		"cost": 3,
		"name": "Flint and Steel",
		"type": "Adventuring Gear",
		"uuid": "ce6e2bef-33d7-4047-022c-c2d863a1015c",
		"active": true,
		"weight": 1,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p67",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Flint and Steel",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 33,
		"cost": 200,
		"name": "Formal Clothing",
		"type": "Clothing",
		"uuid": "d75be593-87ba-43f5-01c7-38640d84f488",
		"active": true,
		"weight": 0,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p59",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Formal Clothing",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 291,
		"cost": 50,
		"name": "Gatling Laser Power Pack",
		"type": "Ammo",
		"uuid": "461f0623-e2e6-41c5-3ac1-a0189f889e34",
		"active": true,
		"weight": 4,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p68",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Gatling Laser Power Packs",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 236,
		"cost": 20,
		"name": "Goggles",
		"type": "Adventuring Gear",
		"uuid": "742c0024-4010-4725-2c87-016c1ac25b27",
		"active": true,
		"weight": 1,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p67",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Goggles",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 37,
		"cost": 15,
		"name": "Good Meal (restaurant)",
		"type": "Food",
		"uuid": "0d5bb349-9451-4982-9c08-a4fe586b32f7",
		"active": true,
		"weight": 0,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p59",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Good Meal (restaurant)",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 272,
		"cost": 15,
		"name": "Good Meal (restaurant)",
		"type": "Food",
		"uuid": "c3f58f25-608b-4be1-232c-8a0b7fe5d1cc",
		"active": true,
		"weight": 0,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p68",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Good Meals (restaurant)",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 47,
		"cost": 250,
		"name": "GPS",
		"type": "Computers",
		"uuid": "a1d444f6-07be-4a50-2e25-c5dc0c8c8dac",
		"active": true,
		"weight": 1,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p59",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "GPS",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 265,
		"cost": 250,
		"name": "GPS",
		"type": "Computers and Electronics",
		"uuid": "1f4c256d-2988-4cb5-3782-28500ab1328b",
		"active": true,
		"weight": 1,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p68",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "GPSs",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 14,
		"cost": 100,
		"name": "Grappling Hook",
		"type": "Adventuring Gear",
		"uuid": "5b810d50-8ea7-434d-1857-87022b08db98",
		"active": true,
		"weight": 2,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p59",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Grappling Hooks",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 1808,
		"cost": 100,
		"name": "Grappling Hook",
		"type": "Adventuring Gear",
		"uuid": "35e6d648-4a2f-4640-927e-c6c681d75743",
		"active": true,
		"weight": 2,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p67",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 15,
		"cost": 10,
		"name": "Hammer",
		"type": "Adventuring Gear",
		"uuid": "3ef56664-d680-419e-1294-da34899c437c",
		"active": true,
		"weight": 1,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p59",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Hammers",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 237,
		"cost": 10,
		"name": "Hammer",
		"type": "Adventuring Gear",
		"uuid": "0ae2fa6a-d79e-4bbb-bf89-b00d6cfad843",
		"active": true,
		"weight": 1,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p67",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Hammers",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 266,
		"cost": 250,
		"name": "Hand held computer",
		"type": "Computers and Electronics",
		"uuid": "56050eb3-2580-460c-b2d4-a7b4aaa2278a",
		"active": true,
		"weight": 1,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p68",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Hand held computers",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 20,
		"cost": 15,
		"name": "Handcuffs (manacles)",
		"type": "Adventuring Gear",
		"uuid": "adfaec06-676b-4cc7-01b1-7a03d03ffd70",
		"active": true,
		"weight": 2,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p59",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Handcuffs (manacles)",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 238,
		"cost": 15,
		"name": "Handcuffs (manacles)",
		"type": "Adventuring Gear",
		"uuid": "8bc7c9a0-20cd-4bec-a66f-9bf5d0f970a9",
		"active": true,
		"weight": 2,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p67",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Handcuffs (manacles)",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 46,
		"cost": 250,
		"name": "Handheld Computer",
		"type": "Computers",
		"uuid": "c1dc730a-417a-40f9-3e2c-d566f95fd5e8",
		"active": true,
		"weight": 1,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p59",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Handheld Computers",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 31,
		"cost": 100,
		"name": "Hiking Boots",
		"type": "Clothing",
		"uuid": "8eacb9f3-41e7-450f-2735-9ed7637ddf95",
		"active": true,
		"weight": 0,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p59",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Hiking Boots",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 40,
		"cost": 300,
		"name": "Horse",
		"type": "Animals & Tack",
		"uuid": "3c219516-b579-4581-a77c-8e253ada9b3c",
		"active": true,
		"weight": 0,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p59",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Horses",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 254,
		"cost": 300,
		"name": "Horse",
		"type": "Animals and Tack",
		"uuid": "b4b0d6ce-e87c-4477-105c-fb68807f3cfe",
		"active": true,
		"weight": 0,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p67",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": true,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Horses",
		"container_no_weight": true,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 16,
		"cost": 25,
		"name": "Lantern",
		"type": "Adventuring Gear",
		"uuid": "673b0e2a-1b78-4938-87f5-c6f28c0378bd",
		"active": true,
		"weight": 3,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p59",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Lanterns",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 239,
		"cost": 25,
		"name": "Lantern (four hours, 4” radius)",
		"type": "Adventuring Gear",
		"uuid": "e0703ec5-d117-4e4b-bab4-f866f23f686b",
		"active": true,
		"weight": 3,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p67",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Lanterns (four hours, 4” radius)",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 45,
		"cost": 1200,
		"name": "Laptop Computer",
		"type": "Computers",
		"uuid": "3a6992ef-f80a-45c8-9121-b34ff6a3e521",
		"active": true,
		"weight": 5,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p59",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Laptop Computers",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 267,
		"cost": 1200,
		"name": "Laptop computer",
		"type": "Computers and Electronics",
		"uuid": "7d742f5d-cc29-436a-1af0-b70a34488241",
		"active": true,
		"weight": 5,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p68",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Laptop computers",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 1325,
		"cost": 25,
		"name": "Laser Battery",
		"type": "Ammunition",
		"uuid": "3439637d-c31b-437e-b1e1-c7573e3eb7a3",
		"active": true,
		"weight": 1,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p64",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 289,
		"cost": 20,
		"name": "Laser Pistol Battery",
		"type": "Ammo",
		"uuid": "688685c5-9ba9-40d1-0ed0-acb293b70ab1",
		"active": true,
		"weight": 0.25,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p68",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Laser Pistol Batteries",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 290,
		"cost": 20,
		"name": "Laser Rifle or SMG Battery",
		"type": "Ammo",
		"uuid": "f45d3b29-b885-49bc-38b0-5ab49f61636f",
		"active": true,
		"weight": 0.5,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p68",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Laser Rifle or SMG Batteries",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 269,
		"cost": 150,
		"name": "Laser Sight",
		"type": "Firearm Accessories",
		"uuid": "6a33817c-38f2-40a7-ab09-43913371f0e0",
		"active": true,
		"weight": 1,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p68",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Laser Sights",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 17,
		"cost": 2,
		"name": "Lighter",
		"type": "Adventuring Gear",
		"uuid": "3a9c1fd0-54f5-4e82-338f-b88b74b6afe8",
		"active": true,
		"weight": 0,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p59",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Lighters",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 240,
		"cost": 2,
		"name": "Lighter",
		"type": "Adventuring Gear",
		"uuid": "ad464bfc-b85c-4e24-8032-443da198648f",
		"active": true,
		"weight": 0,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p67",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Lighters",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 280,
		"cost": 150,
		"name": "Lineman's Telephone",
		"type": "Surveillance",
		"uuid": "45a922b6-fb3f-4c9a-a645-a2513b65fc3c",
		"active": true,
		"weight": 2,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p68",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Lineman's Telephones",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 49,
		"cost": 150,
		"name": "Lineman’s Telephone",
		"type": "Surveillance",
		"uuid": "14c724bd-0a64-4de9-a312-7183e8d67d1b",
		"active": true,
		"weight": 2,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p59",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Lineman’s Telephones",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 18,
		"cost": 200,
		"name": "Lockpicks",
		"type": "Adventuring Gear",
		"uuid": "2c5a3dba-cef2-4b6a-9602-863461577d1a",
		"active": true,
		"weight": 1,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p59",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Lockpicks",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 241,
		"cost": 200,
		"name": "Lockpicks",
		"type": "Adventuring Gear",
		"uuid": "d81ff80a-75ef-4f8f-8e27-ed0785e46625",
		"active": true,
		"weight": 1,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p67",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Lockpicks",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 242,
		"cost": 100,
		"name": "Medic Kit",
		"type": "Adventuring Gear",
		"uuid": "717bc71a-56c6-4493-25fe-864b53877a39",
		"active": true,
		"weight": 4,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p67",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Medic Kits",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 38,
		"cost": 10,
		"name": "MRE (Meal Ready to Eat)",
		"type": "Food",
		"uuid": "5705d5b5-cecd-422f-02ff-60303ab13d0c",
		"active": true,
		"weight": 1,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p59",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "MREs (Meals Ready to Eat)",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 273,
		"cost": 10,
		"name": "MRE (Meal Ready to Eat)",
		"type": "Food",
		"uuid": "5391d2b1-9eb6-487f-3e9c-e282bff0b0f5",
		"active": true,
		"weight": 1,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p68",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "MREs (Meals Ready to Eat)",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 281,
		"cost": 500,
		"name": "Night Vision Goggles",
		"type": "Surveillance",
		"uuid": "1829b8f7-4b9b-4d79-b835-8415ad28ce64",
		"active": true,
		"weight": 3,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p68",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Night Vision Goggles",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 51,
		"cost": 2500,
		"name": "Night Vision Goggles, Active",
		"type": "Surveillance",
		"uuid": "1e95c449-fcb5-4d92-316b-eb3c414ef5b5",
		"active": true,
		"weight": 4,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p59",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Night Vision Goggles, Active",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 50,
		"cost": 1000,
		"name": "Night Vision Goggles, Passive",
		"type": "Surveillance",
		"uuid": "5f24d50d-01f9-4d01-34b5-ce5f9cbbbea3",
		"active": true,
		"weight": 3,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p59",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Night Vision Goggles, Passive",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 32,
		"cost": 20,
		"name": "Normal Clothing",
		"type": "Clothing",
		"uuid": "bc17220a-3f53-4e75-a0a4-8d51bc8bc978",
		"active": true,
		"weight": 0,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p59",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Normal Clothing",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 21,
		"cost": 2,
		"name": "Oil",
		"type": "Adventuring Gear",
		"uuid": "f1b6adc8-18a4-45b7-8305-6e567bfd990a",
		"active": true,
		"weight": 1,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p59",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Oil",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 243,
		"cost": 2,
		"name": "Oil (for lantern; one pint)",
		"type": "Adventuring Gear",
		"uuid": "ecd65f57-19a4-49fc-1e78-df0afb49287b",
		"active": true,
		"weight": 1,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p67",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Oil (for lantern; one pint)",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 52,
		"cost": 750,
		"name": "Parabolic Microphone",
		"type": "Surveillance",
		"uuid": "f597e753-f511-42cc-8106-412a1d4e2e82",
		"active": true,
		"weight": 4,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p59",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Parabolic Microphones",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 282,
		"cost": 750,
		"name": "Parabolic Microphone",
		"type": "Surveillance",
		"uuid": "b74516b6-67ba-4a5f-8e21-df6de5ff1e49",
		"active": true,
		"weight": 4,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p68",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Parabolic Microphone",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 275,
		"cost": 15,
		"name": "Pepper Spray",
		"type": "Personal Defense",
		"uuid": "1c7190c5-51a2-45d3-8bf8-6d375bb052c2",
		"active": true,
		"weight": 0.5,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p68",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Pepper Spray",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 1324,
		"cost": 2,
		"name": "Quarrel",
		"type": "Ammunition",
		"uuid": "de929494-ee75-4204-278c-a16b5056a67b",
		"active": true,
		"weight": 0.2,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p64",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 22,
		"cost": 25,
		"name": "Quiver",
		"type": "Adventuring Gear",
		"uuid": "8a98b646-60f2-4da4-2194-7db191cb89c3",
		"active": true,
		"weight": 2,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p59",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Quivers",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 3040,
		"cost": 25,
		"name": "Quiver (holds 20 arrows/ bolts)",
		"type": "Adventuring Gear",
		"uuid": "56ed0f38-9191-4dce-bf2d-340fcdf41c45",
		"active": true,
		"weight": 2,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p67",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": true,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Quivers (holds 20 arrows/ bolts)",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 270,
		"cost": 100,
		"name": "Rifle Scope",
		"type": "Firearm Accessories",
		"uuid": "df5ba530-6834-4e7e-b945-e8f477d779df",
		"active": true,
		"weight": 2,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p68",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Rifle Scopes",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 19,
		"cost": 10,
		"name": "Rope",
		"type": "Adventuring Gear",
		"uuid": "e17a1367-7578-4ffd-8b69-a73dcbc7288d",
		"active": true,
		"weight": 15,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p59",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Ropes",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 245,
		"cost": 10,
		"name": "Rope, hemp (10”/20 yards)",
		"type": "Adventuring Gear",
		"uuid": "304575e5-ac5b-45f2-a14f-97054e3a4858",
		"active": true,
		"weight": 15,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p67",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Rope, hemp (10”/20 yards)",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 246,
		"cost": 10,
		"name": "Rope, nylon (10”/20 yards)",
		"type": "Adventuring Gear",
		"uuid": "473db361-12d4-49fc-391b-a35702d706b6",
		"active": true,
		"weight": 3,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p67",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Rope, nylon (10”/20 yards)",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 42,
		"cost": 10,
		"name": "Saddle",
		"type": "Animals & Tack",
		"uuid": "9b1c9d86-f869-4830-9980-f04dd7d561a2",
		"active": true,
		"weight": 10,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p59",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Saddles",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 256,
		"cost": 10,
		"name": "Saddle",
		"type": "Animals and Tack",
		"uuid": "c30584eb-0fd0-4251-a254-2664021ea23a",
		"active": true,
		"weight": 10,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p67",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Saddles",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 292,
		"cost": 0.1,
		"name": "Shot (w/ powder)",
		"type": "Ammo",
		"uuid": "0867ef2c-124f-43d0-2b9e-ce4ec17d3481",
		"active": true,
		"weight": 0.05,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p68",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Shot (w/ powder)",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 295,
		"cost": 0.6,
		"name": "Shotgun shell",
		"type": "Ammo",
		"uuid": "92fb36c3-0e88-4655-080c-874a78fc1996",
		"active": true,
		"weight": 0.06,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p68",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Shotgun shells",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 294,
		"cost": 0.8,
		"name": "Shotgun slug",
		"type": "Ammo",
		"uuid": "9cea4dae-8670-4ee0-1d9e-6e9204765365",
		"active": true,
		"weight": 0.06,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p68",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Shotgun slugs",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 23,
		"cost": 5,
		"name": "Shovel",
		"type": "Adventuring Gear",
		"uuid": "65db5927-e618-4ba9-ae18-db59904235b8",
		"active": true,
		"weight": 5,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p59",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Shovels",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 247,
		"cost": 5,
		"name": "Shovel",
		"type": "Adventuring Gear",
		"uuid": "f8e7adb5-3840-4d1b-86d9-21927da18440",
		"active": true,
		"weight": 5,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p67",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Shovels",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 1326,
		"cost": 3,
		"name": "Show (w/powder)",
		"type": "Ammunition",
		"uuid": "68d5ce6d-d562-4a03-86ed-87cfc7b05b39",
		"active": true,
		"weight": 0.1,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p64",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 293,
		"cost": 0.1,
		"name": "Sling stone",
		"type": "Ammo",
		"uuid": "f333a9da-46e2-44a2-9d9d-9e425fbba6a4",
		"active": true,
		"weight": 0.05,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p68",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Sling stones",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 1327,
		"cost": 0.1,
		"name": "Sling Stone",
		"type": "Ammunition",
		"uuid": "d7225ca6-51d2-4a98-0aa6-40a50d93045a",
		"active": true,
		"weight": 0.05,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p64",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 24,
		"cost": 1,
		"name": "Soap",
		"type": "Adventuring Gear",
		"uuid": "a9c760ed-4b55-42eb-3c59-9af3c305f8eb",
		"active": true,
		"weight": 0,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p59",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Soap",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 248,
		"cost": 1,
		"name": "Soap",
		"type": "Adventuring Gear",
		"uuid": "4068fa45-f6dc-4720-3333-8b5274ff0339",
		"active": true,
		"weight": 0.2,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p67",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Soap",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 276,
		"cost": 25,
		"name": "Stun Gun",
		"type": "Personal Defense",
		"uuid": "9408e3cd-5427-496d-1059-f290e13f96af",
		"active": true,
		"weight": 0.5,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p68",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Stun Guns",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 283,
		"cost": 250,
		"name": "Telephone Tap",
		"type": "Surveillance",
		"uuid": "9ddc3da3-cc70-43be-9387-825826f610c9",
		"active": true,
		"weight": 0,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p68",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Telephone Taps",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 53,
		"cost": 250,
		"name": "Telephone Tap (Bug)",
		"type": "Surveillance",
		"uuid": "21edcf7a-eb7b-4469-20d4-bc1b4ecf3b2a",
		"active": true,
		"weight": 0,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p59",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Telephone Tap (Bug)",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 25,
		"cost": 200,
		"name": "Tool Kit",
		"type": "Adventuring Gear",
		"uuid": "cd955d7c-4e1e-4e64-baeb-466a863a3928",
		"active": true,
		"weight": 5,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p59",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Tool Kits",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 249,
		"cost": 200,
		"name": "Tool Kit",
		"type": "Adventuring Gear",
		"uuid": "bb84e61f-a519-4061-9ccc-a2dd980afb53",
		"active": true,
		"weight": 5,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p67",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Tool Kits",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 26,
		"cost": 5,
		"name": "Torch",
		"type": "Adventuring Gear",
		"uuid": "f02260b2-7e75-4fae-271e-de9981821703",
		"active": true,
		"weight": 1,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p59",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Torches",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 250,
		"cost": 5,
		"name": "Torch (one hour, 4” radius)",
		"type": "Adventuring Gear",
		"uuid": "2629564d-32e2-468e-091b-cbb8ca8f10d0",
		"active": true,
		"weight": 1,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p67",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Torches (one hour, 4” radius)",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 39,
		"cost": 10,
		"name": "Trail Rations",
		"type": "Food",
		"uuid": "19d09172-0ad4-4188-2848-2707c6d272fd",
		"active": true,
		"weight": 5,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p59",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Trail Rations",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 274,
		"cost": 10,
		"name": "Trail Rations",
		"type": "Food",
		"uuid": "6596708f-1d3f-49e8-838a-89559a0ce7ea",
		"active": true,
		"weight": 5,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p68",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Trail Rations",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 54,
		"cost": 525,
		"name": "Transmitter Detector",
		"type": "Surveillance",
		"uuid": "dea75228-c457-4081-2dfb-ac35286d0d47",
		"active": true,
		"weight": 1,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p59",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Transmitter Detectors",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 284,
		"cost": 525,
		"name": "Transmitter Detector",
		"type": "Surveillance",
		"uuid": "6b8272fe-adcc-49ee-3d6d-73619bf779ca",
		"active": true,
		"weight": 1,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p68",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Transmitter Detectors",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 27,
		"cost": 5,
		"name": "Umbrella",
		"type": "Adventuring Gear",
		"uuid": "c796ae5e-3fa1-45be-b721-1023d8710e49",
		"active": true,
		"weight": 2,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p59",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Umbrellas",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 251,
		"cost": 5,
		"name": "Umbrella",
		"type": "Adventuring Gear",
		"uuid": "ca1b510d-b64f-47b3-14c5-5c5ba85f6cd4",
		"active": true,
		"weight": 2,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p67",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Umbrellas",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 41,
		"cost": 750,
		"name": "War Horse",
		"type": "Animals & Tack",
		"uuid": "95378c88-f2c9-477e-3fbf-13af96ac824b",
		"active": true,
		"weight": 0,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p59",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "War Horses",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 255,
		"cost": 750,
		"name": "War Horse",
		"type": "Animals and Tack",
		"uuid": "1e2ca297-a2b9-4f00-aeef-daa930413dab",
		"active": true,
		"weight": 0,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p67",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": true,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "War Horses",
		"container_no_weight": true,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 43,
		"cost": 1250,
		"name": "War Horse Barding",
		"type": "Animals & Tack",
		"uuid": "0ca7af22-21e1-400e-10be-972f7bed62d1",
		"active": true,
		"weight": 30,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p59",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "War Horse Barding",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 29,
		"cost": 5,
		"name": "Whetstone",
		"type": "Adventuring Gear",
		"uuid": "04ffad73-dbbe-4e96-8831-435c51a1eb97",
		"active": true,
		"weight": 1,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p59",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Whetstones",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 253,
		"cost": 5,
		"name": "Whetstone",
		"type": "Adventuring Gear",
		"uuid": "46aad11a-c170-432a-997b-d590119e4f59",
		"active": true,
		"weight": 1,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p67",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Whetstones",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 28,
		"cost": 2,
		"name": "Whistle",
		"type": "Adventuring Gear",
		"uuid": "ea686d36-d0eb-4ca2-3b31-5d1e1865245b",
		"active": true,
		"weight": 0,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p59",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Whistles",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 252,
		"cost": 2,
		"name": "Whistle",
		"type": "Adventuring Gear",
		"uuid": "dc772fb1-f416-45fe-33a7-90612f1114a6",
		"active": true,
		"weight": 0,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p67",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Whistles",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 35,
		"cost": 100,
		"name": "Winter Boots",
		"type": "Clothing",
		"uuid": "592b3146-e6ef-4287-8f92-36e36605d1ad",
		"active": true,
		"weight": 1,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p59",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Winter Boots",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 263,
		"cost": 100,
		"name": "Winter Boots",
		"type": "Clothing",
		"uuid": "458da6cb-536c-4e8b-1eda-811adbc13e9e",
		"active": true,
		"weight": 1,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p68",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Winter Boots",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 34,
		"cost": 200,
		"name": "Winter Gear (cloak/parka)",
		"type": "Clothing",
		"uuid": "d6d397a9-0655-4178-29f5-1c4876df79f7",
		"active": true,
		"weight": 3,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p59",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Winter Gear (cloak/parka)",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	},
	{
		"id": 262,
		"cost": 200,
		"name": "Winter Gear (cloak/parka)",
		"type": "Clothing",
		"uuid": "9875674c-b40e-441f-3db3-eed2eaf12305",
		"active": true,
		"weight": 3,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p68",
		"contains": [],
		"quantity": 1,
		"abilities": [],
		"container": false,
		"is_custom": false,
		"no_select": false,
		"read_only": false,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"number_per": 1,
		"updated_by": 0,
		"version_of": 0,
		"description": "",
		"name_plural": "Winter Gear (cloak/parka)",
		"container_no_weight": false,
		"droppable_in_combat": false,
		"rippers_reason_cost": 0,
		"weapon_gimble_weight": 0,
		"container_fractional_weight": 1
	}
];

export default Gear;