import DiceBox from '@3d-dice/dice-box';
import DiceParser from "@3d-dice/dice-parser-interface";
import DisplayResults from "@3d-dice/dice-ui/src/displayResults"; // fui index exports are messed up -> going to src

let g_showResults, g_onResults, g_theme="rust", g_color="#2e8555";

/*  --------------- DICE BOX -------------- */
// Note the dice-box assets in the public folder.
// Those files are all necessary for the web workers to function properly
// create new DiceBox class
const Dice = new DiceBox(
	"#dice-box", // target DOM element to inject the canvas for rendering
	{
		id: "dice-canvas", // canvas element id
		assetPath: "/assets/dice-box/",
		theme: g_theme,
		startingHeight: 8,
		throwForce: 6,
		spinForce: 5,
		lightIntensity: 0.9
	}
);

// create display overlay for final results
const DiceResults = new DisplayResults("#dice-box");

// initialize the Dice Box outside of the component
Dice.init().then(() => {

	if (window.g_diceLoaded) window.g_diceLoaded();

	/*
	// clear dice on click anywhere on the screen
	document.addEventListener("mousedown", () => {
		const diceBoxCanvas = document.getElementById("dice-canvas");
		if (window.getComputedStyle(diceBoxCanvas).display !== "none") {
			Dice.hide().clear();
			DiceResults.clear();
		}
	});
	 */
});


// create Dice Roll Parser to handle complex notations
const DRP = new DiceParser();

// This method is triggered whenever dice are finished rolling
Dice.onRollComplete = (results) => {

	// fix explode
	results.forEach(group => group.rolls.forEach(roll => roll.sides = roll.sides.replace("d", "")-0));

	// handle any rerolls
	const rerolls = DRP.handleRerolls(results);
	if (rerolls.length) {
		rerolls.forEach((roll) => Dice.add(roll, roll.groupId));
		return rerolls;
	}
	// if no rerolls needed then parse the final results
	const finalResults = DRP.parseFinalResults(results);

	// show the results
	if (g_showResults) DiceResults.showResults(finalResults);
	if (g_onResults) g_onResults(results, finalResults);
};

// trigger dice roll
const rollDice = (notation, showResults, onResults) => {
	g_onResults = onResults;
	g_showResults = showResults;
	// trigger the dice roll using the parser
	Dice.show().roll(DRP.parseNotation(notation));
};

const hideDice = () => { Dice.hide().clear(); }
const hideDiceDontClear = () => { Dice.hide(); }
const showDice = () => { Dice.show(); }
const setDiceTheme = (theme, color, onComplete) => {
	if (theme)
		Dice.loadTheme(theme || g_theme).then(config => {
		g_theme = theme;
		if (color) g_color = config.themeColor = color;
		Dice.updateConfig(config).then(onComplete);
	});
	else if (color) Dice.updateConfig({themeColor:g_color=color});
}
const getDiceTheme = () => g_theme;
const getDiceColor = () => g_color;

export {Dice, rollDice, hideDice, setDiceTheme, getDiceTheme, getDiceColor, hideDiceDontClear, showDice};