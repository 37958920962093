import React, {useState} from "react";
import ButtonRegular, {ButtonRegularColor} from "./Burnblade/ButtonRegular";
import Language from "../Language/Language";
import Dialog from "./Burnblade/Dialog";
import SelectBox from "./Burnblade/SelectBox";
import {getDiceColor, getDiceTheme, rollDice, setDiceTheme} from "./Dice";
import HorizontalRule from "./Burnblade/HorizontalRule";
import styled from "styled-components";
import Label from "./Burnblade/Label";
import SaveLoad from "../Adventure/SaveLoad";

const Wrapper = styled.div`
	text-align: center;
	padding:1em;
`;

function DiceCustomize({onClose})
{
	// input
	let currentTheme = getDiceTheme();
	let currentColor = getDiceColor();
	const language = Language.getCurrent();
	const [selectedTheme, setSelectedTheme] = useState(currentTheme);
	const [selectedColor, setSelectedColor] = useState(currentColor);

	// display
	return (
		<Dialog
			title={language.Custom}
			onClose={()=>{
				setDiceTheme(currentTheme, currentColor);
				onClose();
			}}
		>
			<Wrapper>

				{/* Theme */}
				<SelectBox
					value={selectedTheme}
					onChange={event => {
						const newTheme = event.target.value;
						setSelectedTheme(newTheme);
						setDiceTheme(newTheme, selectedColor, () => { rollDice("2d6"); });
					}}
				>
					<option value="default">{language.Default}</option>
					<option value="rock">{language.Rock}</option>
					<option value="rust">{language.Rust}</option>
					<option value="smooth">{language.Smooth}</option>
					<option value="gemstone">{language.Gemstone}</option>
					<option disabled={true}>---</option>
					<option value="wooden">{language.Wood}</option>
					<option value="gemstoneMarble">{language.Marble}</option>
					<option value="blueGreenMetal">{language.Blue_Green_Metal}</option>
					<option value="diceOfRolling">{language.Dice_of_Rolling}</option>
				</SelectBox>

				{/* Color */}
				<Label text={language.Color} inputOnLeft={true}>
					<input
						type="color"
						value={selectedColor}
						onInput={event => { setSelectedColor(event.target.value); }}
					/>
				</Label>

				<HorizontalRule />

				{/* Save, Test */}
				<ButtonRegular
					onClick={() => {
						SaveLoad.saveDice(selectedTheme, selectedColor);
						onClose();
					}}
					buttonRegularColor={ButtonRegularColor.Green}
				>{language.Save}</ButtonRegular>
				<ButtonRegular
					buttonRegularColor={ButtonRegularColor.Orange}
					onClick={() => { setDiceTheme(selectedTheme, selectedColor, () => { rollDice("2d6"); }); }}
				>{language.Test}</ButtonRegular>

			</Wrapper>

		</Dialog>
	);
}

export default DiceCustomize;