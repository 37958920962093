import React, {useState} from 'react';
import Language from "../Language/Language";
import Dialog, {TitleWrapperWithButton} from "./Burnblade/Dialog";
import styled from "styled-components";
import HorizontalRule from "./Burnblade/HorizontalRule";
import ButtonRegular, {ButtonRegularColor} from "./Burnblade/ButtonRegular";
import {CheckboxLabel} from "./Burnblade/Checkbox";
import {hideDice, hideDiceDontClear, rollDice, showDice} from "./Dice";
import DiceCustomize from "./DiceCustomize";

const Wrapper = styled.div`
	text-align: center;
`;

const DieWrapper = styled.button`
	background:none;
	border:none;
	margin:0.5em;
	& > img {
		filter:drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5));
		width: 75px;
		height: 75px;
	}
	opacity:0.8;
	&:hover { opacity: 1; }
`;

const NotationWrapper = styled.div`
	min-height:2em;
`;

function DicePickerModule({onResults}) {

	// input
	const language = Language.getCurrent();
	const [showDialog, setShowDialog] = useState(false);
	const [countBySide, setCountBySide] = useState({});
	const [isExploding, setIsExploding] = useState(false);
	const [showResults, setShowResults] = useState(false);
	const [showCustom, setShowCustom] = useState(false);

	// build notation
	let notations = [];
	Object.keys(countBySide).forEach(side => {
		const count = countBySide[side];
		if (count) notations.push(`${count}d${side}`);
	});
	const notation = !notations.length ? "" : (notations.join("+")+(isExploding?"!":""));

	// display
	return (
		<>
			<button onClick={()=>{ setShowDialog(true); showDice(); }}>{language.Dice}</button>

			{
				// Adventures Dialog
				!showDialog ? false :
				<Dialog
					onClose={()=>{
						setShowDialog(false);
						hideDiceDontClear();
					}}
					title={
						<TitleWrapperWithButton>

							{/* Title Text */}
							<div>{language.Dice}</div>

							{/* Change Dice */}
							<ButtonRegular onClick={()=>{setShowCustom(true);}}>{language.Custom}</ButtonRegular>

						</TitleWrapperWithButton>
					}
				>
					<Wrapper>

						{
							// Die Buttons
							[4,6,8,10,12,100].map(side =>
								<DieWrapper
									key={side}
									type="button"
									onClick={() => {
										const newCountBySide = Object.assign({}, countBySide);
										if (!newCountBySide[side]) newCountBySide[side] = 0;
										newCountBySide[side]++;
										setCountBySide(newCountBySide);
									}}
								>
									<img src={`/assets/dice-box/polyhedral_dice.svg#d${side}_die`} alt={`d${side}`} />
								</DieWrapper>
							)
						}
						<br />

						{/* Notation */}
						<NotationWrapper>{notation}</NotationWrapper>

						<HorizontalRule />

						{/* Exploding */}
						<CheckboxLabel
							checked={isExploding}
							onChange={()=>{setIsExploding(!isExploding);}}
						>{language.Exploding}</CheckboxLabel>

						{/* Show Results */}
						<CheckboxLabel
							checked={showResults}
							onChange={()=>{setShowResults(!showResults);}}
						>{language.Show_Results}</CheckboxLabel>
						<br />

						{/* Roll */}
						<ButtonRegular
							type="button"
							disabled={""===notation}
							buttonRegularColor={""===notation?undefined:ButtonRegularColor.Green}
							onClick={() => {rollDice(notation, showResults, onResults);}}
						>{language.Roll}</ButtonRegular>

						{/* Clear */}
						<ButtonRegular
							type="button"
							buttonRegularColor={ButtonRegularColor.Orange}
							onClick={() => {
								setCountBySide({});
								hideDice();
							}}
						>{language.Clear}</ButtonRegular>

					</Wrapper>
				</Dialog>
			}
			{
				// Custom
				!showCustom ? false :
				<DiceCustomize onClose={()=>{setShowCustom(false); }} />
			}
		</>
	);
}

export default DicePickerModule;