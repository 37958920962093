import TraitsData from "./TraitsData";
import Language from "../Language/Language";
import CharacterStatusData from "./CharacterStatusData";

const CharacterData = new function() {
	const this_ = this;

	// create
	this_.createCharacter = ({
		id, name, isPlayer, isWildCard, traits, prefillCoreSkills, adjective, noun, motivations, size, pace,
		edgeIds, hindranceIds, abilities, naturalArmor, characterStatus
	}) => {

		// default values
		if (!traits) traits = {};
		Object.values(TraitsData.Attributes).forEach(trait => { if (!traits[trait]) traits[trait] = 6; });

		// Core skills
		if (prefillCoreSkills) TraitsData.CoreSkills.forEach(trait => { if (!traits[trait]) traits[trait] = 4; });

		// TODO: armor, +parry (eg. shield), Bestiary Gear

		// done
		return {
			id_:id,
			name_:name||"",
			isPlayer_:isPlayer||0,
			isWildCard_:isWildCard||0,
			traits_:traits,
			//headshotUrl_:headshotUrl||"",
			adjective_:adjective||"",
			noun_:noun||"",
			motivations_:motivations||[],
			size_:size||0,
			pace_:pace||6,
			edgeIds_:edgeIds||[],
			hindranceIds_:hindranceIds||[],
			abilities_:abilities||[], // don't save, because there are no ids
			naturalArmor_:naturalArmor||0, // don't save, because it is currently only used for Bestiary
			status_:characterStatus||CharacterStatusData.createCharacterStatus({})
		};
	};

	// accessors
	this_.getCharacterId = character => character.id_;
	this_.getCharacterName = character => character.name_;
	this_.getCharacterIsPlayer = character => character.isPlayer_;
	this_.getCharacterIsWildCard = character => character.isWildCard_;
	this_.getCharacterTrait = (character, trait) => character.traits_[trait]||0;
	this_.getCharacterAdjective = character => character.adjective_;
	this_.getCharacterNoun = character => character.noun_;
	this_.getMotivations = character => character.motivations_;
	this_.getCharacterSize = character => character.size_;
	this_.getCharacterPace = character => character.pace_;
	this_.getCharacterEdgeIds = character => character.edgeIds_;
	this_.getCharacterHindranceIds = character => character.hindranceIds_;
	this_.getCharacterAbilities = character => character.abilities_;
	this_.getCharacterStatus = character => character.status_;

	// modifiers
	this_.setCharacterName = (character, value) => character.name_ = value;
	this_.setCharacterIsPlayer = (character, value) => character.isPlayer_ = value;
	this_.setCharacterIsWildCard = (character, value) => character.isWildCard_ = value;
	this_.setCharacterTrait = (character, trait, value) => character.traits_[trait] = value;
	this_.setCharacterAdjective = (character, value) => character.adjective_ = value;
	this_.setCharacterNoun = (character, value) => character.noun_ = value;
	this_.setMotivations = (character, value) => character.motivations_ = value;
	this_.setCharacterSize = (character, value) => character.size_ = value;
	this_.setCharacterPace = (character, value) => character.pace_ = value;
	this_.setCharacterEdgeIds = (character, value) => character.edgeIds_ = value;
	this_.setCharacterHindranceIds = (character, value) => character.hindranceIds_ = value;

	// calculated
	this_.getParry = character => this_.calculateParry(this_.getCharacterTrait(character, TraitsData.Skills.Fighting));
	this_.getToughness = character => this_.calculateToughness(this_.getCharacterTrait(character, TraitsData.Attributes.Vigor), this_.getCharacterSize(character));
	this_.calculateParry = fighting => fighting * 0.5 + 2;
	this_.calculateToughness = (vigor, size) => vigor * 0.5 + 2 + (size||0);
	this_.getCharacterNameExtended = character =>
		`${CharacterData.getCharacterName(character)||"-"}${
			CharacterData.getCharacterIsPlayer(character) ? `, ${Language.getCurrent().PlayerCharacterAbbr}` :
			CharacterData.getCharacterIsWildCard(character) ? `, ${Language.getCurrent().WildCardAbbr}` :
			""
		}`;
	this_.getCharacterSummary = character => `${CharacterData.getCharacterAdjective(character)} ${CharacterData.getCharacterNoun(character)}`;
	this_.getCharacterArmor = character => character.naturalArmor_ + CharacterStatusData.getCharacterArmor(this_.getCharacterStatus(character));

	// save data
	this_.getSaveData = character => [
		this_.getCharacterId(character),
		this_.getCharacterName(character),
		this_.getCharacterIsPlayer(character)?1:0,
		this_.getCharacterIsWildCard(character)?1:0,
		Object.values(TraitsData.Attributes).map(trait => this_.getCharacterTrait(character, trait)-0)["concat"](
			Object.values(TraitsData.Skills).map(trait => this_.getCharacterTrait(character, trait)-0)
		),
		this_.getCharacterAdjective(character),
		this_.getCharacterNoun(character),
		this_.getMotivations(character),
		this_.getCharacterSize(character),
		this_.getCharacterPace(character),
		this_.getCharacterEdgeIds(character),
		this_.getCharacterHindranceIds(character),
		CharacterStatusData.getSaveData(this_.getCharacterStatus(character))
	];

	// load data
	this_.loadData = data => this_.createCharacter({
		id:data[0],
		name:data[1],
		isPlayer:data[2],
		isWildCard:data[3],
		traits:data[4],
		adjective:data[5],
		noun:data[6],
		motivations:data[7],
		size:data[8],
		pace:data[9],
		edgeIds:data[10],
		hindranceIds:data[11],
		characterStatus:CharacterStatusData.loadData(data[12])
	});
};

export default CharacterData;