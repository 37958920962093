import React, {useState} from 'react';
import Dialog, {TitleWrapperWithButton} from "../UI/Burnblade/Dialog";
import TabBookVertical from "../UI/Burnblade/TabBookVertical";
import styled from "styled-components";
import ButtonRegular, {ButtonRegularColor} from "../UI/Burnblade/ButtonRegular";
import Language from "../Language/Language";
import {hideDice, rollDice} from "../UI/Dice";
import ButtonSmall from "../UI/Burnblade/ButtonSmall";

const PageWrapper = styled.div`
	width: 600px;
	height: 600px;
	display:flex;
	flex-flow:column wrap;
	& > button { ;
		padding:1em;
		background:none;
		border:none;
		text-align: left;
		box-sizing: border-box;
		&:hover { background:rgba(0,0,0,0.2); }
		&[data-selected] { background:rgba(255,255,0,0.25); }
	}
`;

function RandomSelect({titleText, optionsByLabel, optionsNoLabel, onSelect, ranges}) {

	// input
	const language = Language.getCurrent();
	const [showDialog, setShowDialog] = useState(false);
	const [rollResult, setRollResult] = useState(0);

	const OptionsPage = ({options}) =>
		<PageWrapper className="ScrollHorizontal">
			{
				// Options
				options.map((option, optionIndex) =>
					<button
						type="button"
						key={`${option}-${optionIndex}`}
						data-selected={rollResult===optionIndex+1?1:undefined}
						onClick={onSelect ? () => {
							onSelect(option);
							setShowDialog(false);
							hideDice();
						} : undefined}
						ref={rollResult===optionIndex+1 ? ref => { if (ref) ref.scrollIntoView(); } : undefined}
					>{!ranges?optionIndex+1:ranges[optionIndex].join("-")}. {option}</button>
				)
			}
		</PageWrapper>

	// display
	return (
		<>
			<ButtonSmall type="button"  onClick={()=>{setShowDialog(true);}} title={titleText}>
				<img
					alt={language.Random[0]}
					src="/assets/burnblade/icon_lab.png"
					style={{width:"31px", height:"31.5px", backgroundSize:"100%"}}
				/>
			</ButtonSmall>

			{
				!showDialog ? false :
				<Dialog
					onClose={() => { setShowDialog(false); hideDice(); }}
					title={
						<TitleWrapperWithButton>

							{/* Title Text */}
							<div>{titleText}</div>

							{/* Roll Button */}
							<ButtonRegular
								type="button"
								buttonRegularColor={ButtonRegularColor.Green}
								onClick={() => {
									rollDice("1d10+1d100", false, (results, finalResults) => {
										let newRollResult = 0;
										results.forEach(result => newRollResult += result.value);
										setRollResult(
										!ranges ? newRollResult :
											ranges.findIndex(range => newRollResult <= range[1])+1
										);
									});
								}}
							>{language.Roll}</ButtonRegular>

						</TitleWrapperWithButton>
					}
				>
					{
						// Pages
						!optionsByLabel ?
							<div style={{padding:"1em"}}><OptionsPage options={optionsNoLabel} /></div> :
							<TabBookVertical
								initialTabIndex={0}
								tabLabels={Object.keys(optionsByLabel).map(label => <div>{label}</div>)}
								tabPages={

									// Pages
									Object.keys(optionsByLabel).map(label =>
										<OptionsPage
											key={label}
											label={label}
											options={optionsByLabel[label]}
										/>
									)
								}
							/>
					}
				</Dialog>
			}
		</>
	);
}

export default RandomSelect;