import MapData from "./MapData";
import CharacterStatusData from "./CharacterStatusData";

const SceneData = new function() {
	const this_ = this;

	// create
	this_.createScene = ({name, notes, setup, location, chaosFactor, mapData, characterIds, bestiaryIds, bestiaryStatuses}) => {
		return {
			name_:name,
			notes_:notes,
			setup_:setup,
			location_:location,
			chaosFactor_:chaosFactor||5,
			mapData_:mapData||MapData.createMap({}),
			characterIds_:characterIds||[],
			bestiaryIds_:bestiaryIds||[],
			bestiaryStatuses_:bestiaryStatuses||[]
		};
	};

	// accessors
	this_.getSceneName = scene => scene.name_;
	this_.getSceneNotes = scene => scene.notes_;
	this_.getSceneSetup = scene => scene.setup_;
	this_.getSceneLocation = scene => scene.location_;
	this_.getSceneChaosFactor = scene => scene.chaosFactor_;
	this_.getMapData = scene => scene.mapData_;
	this_.getCharacterIds = scene => scene.characterIds_;
	this_.getBestiaryIds = scene => scene.bestiaryIds_;
	this_.getBestiaryStatuses = scene => scene.bestiaryStatuses_

	// modifiers
	this_.setSceneName = (scene, value) => scene.name_ = value;
	this_.setSceneNotes = (scene, value) => scene.notes_ = value;
	this_.setSceneSetup = (scene, value) => scene.setup_ = value;
	this_.setSceneLocation = (scene, value) => scene.location_ = value;
	this_.setSceneChaosFactor = (scene, value) => scene.chaosFactor_ = value;
	this_.setMapData = (scene, value) => scene.mapData_ = value;
	this_.setSceneCharacterIds = (scene, value) => scene.characterIds_ = value;
	this_.setSceneBestiaryIds = (scene, value) => scene.bestiaryIds_ = value;
	this_.setSceneBestiaryStatuses = (scene, value) => scene.bestiaryStatuses_ = value;

	// save data
	this_.getSaveData = scene => [
		this_.getSceneName(scene),
		this_.getSceneNotes(scene),
		this_.getSceneSetup(scene),
		this_.getSceneLocation(scene),
		this_.getSceneChaosFactor(scene),
		MapData.getSaveData(this_.getMapData(scene)),
		this_.getCharacterIds(scene),
		this_.getBestiaryIds(scene),
		this_.getBestiaryStatuses(scene).map(CharacterStatusData.getSaveData)
	];

	// load data
	this_.loadData = data => this_.createScene({
		name:data[0],
		notes:data[1],
		setup:data[2],
		location:data[3],
		chaosFactor:data[4],
		mapData:MapData.loadData(data[5]),
		characterIds:data[6],
		bestiaryIds:data[7],
		bestiaryStatuses:data[7].map((bestiaryId, bestiaryIndex) => data[8][bestiaryIndex] ? CharacterStatusData.loadData(data[8][bestiaryIndex]) : CharacterStatusData.createCharacterStatus({}))
	});
};

export default SceneData;