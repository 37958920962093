import React from 'react';
import styled from "styled-components";
import Language from "../Language/Language";
import TraitsData from "./TraitsData";
import Spinner from "../UI/Burnblade/Spinner";
import CharacterData from "./CharacterData";

const Wrapper = styled.div`
	display:flex;
	flex-flow:row nowrap;
	justify-content:flex-start;
	
	& > div {
		display:flex;
		flex-flow:column wrap;
		height:450px;
	}
`;

function CharacterModuleTraits({character, fighting, setFighting, vigor, setVigor, size, readOnly}) {

	// input
	const language = Language.getCurrent();
	const parry = CharacterData.calculateParry(fighting);
	const toughness = CharacterData.calculateToughness(vigor, size);
	const armor = CharacterData.getCharacterArmor(character);

	const DisplayTrait = (trait, min, onChange) =>
		readOnly && !CharacterData.getCharacterTrait(character, trait) ? false :
		<Spinner
			min={min}
			max={12}
			step={2}
			key={trait}
			skip2={true}
			name={`traits[${trait}]`}
			text={language.TraitById[trait]}
			initialValue={CharacterData.getCharacterTrait(character, trait)}
			onChange={onChange}
			readOnly={readOnly}
		/>

	const DisplayAttribute = trait => DisplayTrait(trait, 4, TraitsData.Attributes.Vigor === trait ? setVigor : undefined);
	const DisplaySkill = trait => DisplayTrait(trait, 2, TraitsData.Skills.Fighting === trait ? setFighting : undefined);

	// display
	return (
		<Wrapper className="ScrollHorizontal">

			{/* Attributes */}
			<div>{Object.values(TraitsData.Attributes).map(DisplayAttribute)}</div>

			{/* Core Skills */}
			<div>{TraitsData.CoreSkills.map(DisplaySkill)}</div>

			{/* Other Skills */}
			<div>

				<Spinner
					text={language.Parry}
					min={0} max={99} readOnly={true}
					key={`parry_${parry}`}
					initialValue={parry}
				/>
				<Spinner
					text={language.Toughness}
					min={0} max={99} readOnly={true}
					key={`toughness_${toughness}`}
					initialValue={toughness}
				/>
				<Spinner
					text={language.Armor}
					min={0} max={99} readOnly={true}
					key={`armor_${armor}`}
					initialValue={armor}
				/>

				{DisplaySkill(TraitsData.Skills.Fighting)}
				{DisplaySkill(TraitsData.Skills.Shooting)}

				{
					// Remaining Skills
					Object.values(TraitsData.Skills)
						.filter(trait =>
							trait !== TraitsData.Skills.Fighting &&
							trait !== TraitsData.Skills.Shooting &&
							-1 === TraitsData.CoreSkills.indexOf(trait)
						)
						.map(DisplaySkill)
				}
			</div>

		</Wrapper>
	);
}

export default CharacterModuleTraits;