import React, {useState} from 'react';
import Dialog from "../UI/Burnblade/Dialog";
import Language from "../Language/Language";
import AdventureData from "./AdventureData";
import TabBookVertical from "../UI/Burnblade/TabBookVertical";
import AdventureModule from "./AdventureModule";

function AdventuresModule({adventures, selectedAdventureIndex, onChangeAdventureIndex, onSaved}) {

	// input
	const language = Language.getCurrent();
	const [renderKey, setRenderKey] = useState(0);
	const [showDialog, setShowDialog] = useState(false);

	// display
	return (
		<>
			<button onClick={()=>{setShowDialog(true);}}>{language.Adventures}</button>

			{
				// Adventures Dialog
				!showDialog ? false :
				<Dialog
					title={language.Adventures}
					onClose={()=>{setShowDialog(false);}}
					key={`${selectedAdventureIndex}-${renderKey}`}
				>
					<TabBookVertical
						onSelectTabIndex={onChangeAdventureIndex}
						initialTabIndex={selectedAdventureIndex}
						tabLabels={

							// Adventure Labels
							adventures.map((adventure, adventureIndex) =>
								<div key={adventureIndex}>{AdventureData.getName(adventure)||<span>&nbsp;</span>}</div>
							)
						}
						tabPages={

							// Adventure Details
							adventures.map((adventure, adventureIndex) =>
								<AdventureModule
									key={adventureIndex}
									adventure={adventure}
									onSaved={() => {
										setRenderKey(renderKey+1);
										onSaved();
									}}
								/>
							)
						}
						tabLabelsFooter={

							// New Adventure
							<button
								className="Create"
								onClick={()=>{
									adventures["push"](AdventureData.createAdventure({}));
									onChangeAdventureIndex(adventures.length-1);
								}}
							>{language.Create_Adventure}</button>

						}
					/>
				</Dialog>
			}
		</>
	);
}

export default AdventuresModule;