import React from "react";
import Language from "../Language/Language";
import Label from "../UI/Burnblade/Label";
import TextInput from "../UI/Burnblade/TextInput";
import RandomSelect from "./RandomSelect";
import {ButtonPlus} from "../UI/Burnblade/ButtonSmall";
import HorizontalRule from "../UI/Burnblade/HorizontalRule";
import SideBySide from "../UI/Burnblade/SideBySide";
import ComboWrapper from "../UI/Burnblade/ComboWrapper";

function CharacterModuleMotivations({motivations, setMotivations, readOnly}) {

	// input
	const language = Language.getCurrent();

	// display
	return (
		<div>
			{
				// Motivations
				motivations.map((motivation, motivationIndex) =>

					<SideBySide key={motivationIndex}>

						{/* Verb */}
						<Label text={language.Motivation_Verb} stacked={true}>
							<ComboWrapper>
								<TextInput
									value={motivation[0]}
									readOnly={readOnly}
									name={`motivation[${motivationIndex}][0]`}
									onChange={event => {
										motivation[0] = event.target.value;
										setMotivations(motivations.slice());
									}}
								/>
								{
									readOnly ? false :
									<RandomSelect
										onSelect={motivationVerb => {
											motivation[0] = motivationVerb;
											setMotivations(motivations.slice());
										}}
										titleText={language.Motivation_Verb}
										optionsNoLabel={language.MotivationVerbs}
									/>
								}
							</ComboWrapper>
						</Label>

						{/* Noun */}
						<Label text={language.Motivation_Noun} stacked={true}>
							<ComboWrapper>
								<TextInput
									readOnly={readOnly}
									value={motivation[1]}
									name={`motivation[${motivationIndex}][1]`}
									onChange={event => {
										motivation[1] = event.target.value;
										setMotivations(motivations.slice());
									}}
								/>
								{
									readOnly ? false :
									<RandomSelect
										onSelect={motivationNoun => {
											motivation[1] = motivationNoun;
											setMotivations(motivations.slice());
										}}
										titleText={language.Motivation_Noun}
										optionsNoLabel={language.MotivationNouns}
									/>
								}
							</ComboWrapper>
						</Label>
					</SideBySide>
				)
			}

			{
				// Add Motivation
				readOnly ? false :
				<>
					<HorizontalRule />
					<ButtonPlus
						type="button"
						style={{margin:"auto"}}
						title={language.Add_Motivation}
						onClick={() => {
							motivations.push([]);
							setMotivations(motivations.slice());
						}}
					/>
				</>
			}

		</div>
	)
}

export default CharacterModuleMotivations;