import React, {useState} from "react";
import styled from "styled-components";
import Language from "../Language/Language";
import Dialog from "../UI/Burnblade/Dialog";
import MapSelectModule from "./MapSelectModule";
import SceneData from "./SceneData";
import MapData from "./MapData";


const ImageWrapper = styled.button`
	width:190px;
	height:190px;
	border:none;
	padding:0;
	box-sizing: border-box;
	background:no-repeat center center #222;
	background-size:cover;
`;

function SceneModuleMap({scene})
{
	// input
	const language = Language.getCurrent();
	const [showDialog, setShowDialog] = useState(false);
	const mapData = SceneData.getMapData(scene);
	const [mapWidth, setMapWidth] = useState(MapData.getMapWidth(mapData));
	const [mapHeight, setMapHeight] = useState(MapData.getMapHeight(mapData));
	const [mapImageUrl, setMapImageUrl] = useState(MapData.getMapImageUrl(mapData));

	// display
	return (
		<div style={{padding:"1em"}}>
			<input type="hidden" name="mapWidth" value={mapWidth} />
			<input type="hidden" name="mapHeight" value={mapHeight} />
			<input type="hidden" name="mapImageUrl" value={mapImageUrl} />
			<ImageWrapper
				type="button"
				onClick={()=>{setShowDialog(true);}}
				style={{backgroundImage:`url('${mapImageUrl}')`}}
			/>

			{
				// Select Dialog
				!showDialog ? false :
				<Dialog
					title={language.Map}
					onClose={()=>{setShowDialog(false);}}
				>
					<MapSelectModule
						onSelect={(url, width, height) => {
							setMapWidth(width);
							setMapHeight(height);
							setMapImageUrl(url);
							setShowDialog(false);
						}}
					/>
				</Dialog>
			}
		</div>
	);
}

export default SceneModuleMap;