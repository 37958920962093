const Bestiary = [
	{
		"id": 1,
		"gear": [],
		"name": "Air Elemental",
		"pace": "0",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "1f25f73a-3caa-4ff1-9536-30774b2fe598",
		"armor": "0",
		"climb": -10,
		"edges": [],
		"flies": 0,
		"image": "http://www.santharia.com/pictures/quellion/quellion_pics/air_elemental.jpg",
		"parry": "6",
		"active": true,
		"skills": [
			{
				"Name": "Fighting",
				"Value": "d8"
			},
			{
				"Name": "Notice",
				"Value": "d8"
			},
			{
				"Name": "Shooting",
				"Value": "d6"
			}
		],
		"book_id": 2,
		"summary": "",
		"bookPage": "p137",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Elemental",
				"Notes": " No additional damage from called shots;Fearless; Immune to disease and poison."
			},
			{
				"Name": "Ethereal",
				"Notes": " Air Elementals can maneuver through any non-solid surface. They can seep through the cracks in doors, bubble through water, and rush through sails."
			},
			{
				"Name": "Flight",
				"Notes": " Air Elementals fly at a rate of 6\" with a Climb of 3. They may not run."
			},
			{
				"Name": "Invulnerability",
				"Notes": ""
			},
			{
				"Name": "Push",
				"Notes": " The air elemental can use an action to push a single adjacent target 1d6\" directly away with a concentrated blast of air. The victim makes a Strength roll, with each success and raise reducing the amount moved by 1\"(to a minimum of 0)."
			},
			{
				"Name": "Wind Blast",
				"Notes": " Air Elementals can send directed blasts of air at foes using the Cone Template and a Shooting roll. Foes may make an opposed Agility roll to avoid the blast. The damage is 2d6 points of nonlethal damage."
			},
			{
				"Name": "Whirlwind",
				"Notes": " As long as the air elemental does not move that turn it may attempt to pick up a foe. Make an opposed Strength check and if the air elemental wins then its foe is pulled into the swirling maelstrom of its body. While trapped, the target is at -2 on all rolls including damage, to hit and Strength rolls to free himself. The air elemental cannot move as long as it wants to keep foes trapped inside its form."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "5",
		"attributes": {
			"Vigor": "d6",
			"Smarts": "d6",
			"Spirit": "d6",
			"Agility": "d12",
			"Strength": "d8"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "Air elementals manifest as sentient whirlwinds.",
		"flying_pace": -10,
		"heavy_armor": false,
		"name_plural": "",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 1,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Air Elemental",
			"race": "Air Elemental",
			"rank": 0,
			"size": 0,
			"uuid": "1f25f73a-3caa-4ff1-9536-30774b2fe598",
			"armor": [],
			"edges": [],
			"image": "https://savaged.ushttp://www.santharia.com/pictures/quellion/quellion_pics/air_elemental.jpg",
			"swade": false,
			"bookID": 2,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d8",
					"bookID": 2,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d8",
					"bookID": 2,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Shooting",
					"value": "d6",
					"bookID": 2,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": -6,
			"parryHR": "6",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Wind Blast",
					"uuid": "",
					"notes": " Foes may make an opposed Agility roll to avoid the blast",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "air",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Wind Blast",
							"notes": " Foes may make an opposed Agility roll to avoid the blast",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "air",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "",
							"parry_modifier": 0,
							"damage_original": "air",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": null,
							"damageWithBrackets": "air",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "",
					"descriptionHTML": "",
					"damageDiceBasePlus": null,
					"damageWithBrackets": "air"
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Deluxe (EE)",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Elemental",
					"note": "No additional damage from called shots;Fearless; Immune to disease and poison.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p137",
					"description": "No additional damage from called shots;Fearless; Immune to disease and poison."
				},
				{
					"from": "",
					"name": "Ethereal",
					"note": "Air Elementals can maneuver through any non-solid surface. They can seep through the cracks in doors, bubble through water, and rush through sails.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p137",
					"description": "Air Elementals can maneuver through any non-solid surface. They can seep through the cracks in doors, bubble through water, and rush through sails."
				},
				{
					"from": "",
					"name": "Flight",
					"note": "Air Elementals fly at a rate of 6\" with a Climb of 3. They may not run.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p137",
					"description": "Air Elementals fly at a rate of 6\" with a Climb of 3. They may not run."
				},
				{
					"from": "",
					"name": "Invulnerability",
					"note": "",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p137",
					"description": ""
				},
				{
					"from": "",
					"name": "Push",
					"note": "The air elemental can use an action to push a single adjacent target 1d6\" directly away with a concentrated blast of air. The victim makes a Strength roll, with each success and raise reducing the amount moved by 1\"(to a minimum of 0).",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p137",
					"description": "The air elemental can use an action to push a single adjacent target 1d6\" directly away with a concentrated blast of air. The victim makes a Strength roll, with each success and raise reducing the amount moved by 1\"(to a minimum of 0)."
				},
				{
					"from": "",
					"name": "Wind Blast",
					"note": "Air Elementals can send directed blasts of air at foes using the Cone Template and a Shooting roll. Foes may make an opposed Agility roll to avoid the blast. The damage is 2d6 points of nonlethal damage.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p137",
					"description": "Air Elementals can send directed blasts of air at foes using the Cone Template and a Shooting roll. Foes may make an opposed Agility roll to avoid the blast. The damage is 2d6 points of nonlethal damage."
				},
				{
					"from": "",
					"name": "Whirlwind",
					"note": "As long as the air elemental does not move that turn it may attempt to pick up a foe. Make an opposed Strength check and if the air elemental wins then its foe is pulled into the swirling maelstrom of its body. While trapped, the target is at -2 on all rolls including damage, to hit and Strength rolls to free himself. The air elemental cannot move as long as it wants to keep foes trapped inside its form.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p137",
					"description": "As long as the air elemental does not move that turn it may attempt to pick up a foe. Make an opposed Strength check and if the air elemental wins then its foe is pulled into the swirling maelstrom of its body. While trapped, the target is at -2 on all rolls including damage, to hit and Strength rolls to free himself. The air elemental cannot move as long as it wants to keep foes trapped inside its form."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 0,
			"parryBase": 6,
			"sizeLabel": "0 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 0,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d12",
					"dieValue": 12
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "strength",
					"label": "Strength",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d6",
					"dieValue": 6
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 6,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2018-05-03T19:05:27.000Z",
			"description": "Air elementals manifest as sentient whirlwinds.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2018-05-03T19:05:27.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 0,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2011",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 5,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 5,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "5",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/deluxe/air-elemental",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 5,
			"toughnessAsReadNoHeavy": "5",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 638,
		"gear": [],
		"name": "Air Elemental",
		"pace": "-",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "c94beb3b-26b3-11ec-a1cd-8f693d6a0a95",
		"armor": "0",
		"climb": 0,
		"edges": [
			"-"
		],
		"flies": 0,
		"image": "http://www.santharia.com/pictures/quellion/quellion_pics/air_elemental.jpg",
		"parry": "6",
		"active": true,
		"skills": [
			{
				"Name": "Athletics",
				"Value": " d10"
			},
			{
				"Name": "Fighting",
				"Value": " d8"
			},
			{
				"Name": "Notice",
				"Value": " d8"
			},
			{
				"Name": "Shooting",
				"Value": " d6"
			},
			{
				"Name": "Stealth",
				"Value": " d10"
			}
		],
		"book_id": 9,
		"summary": "",
		"bookPage": "p182",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Elemental",
				"Notes": " No additional damage from Called Shots, ignores 1 point of Wound penalties, doesn't breathe, immune to disease and poison."
			},
			{
				"Name": "Gaseous Form",
				"Notes": " Can maneuver through any non-solid surface, pass through cracks in doors or windows, bubble through water, etc."
			},
			{
				"Name": "Flight",
				"Notes": " Air elementals fly at Pace 12″."
			},
			{
				"Name": "Invulnerability",
				"Notes": " Immune to non-magical attacks."
			},
			{
				"Name": "Wind Blast",
				"Notes": " 2d4 range: Cone Air elementals can send directed blasts of air to knock their foes away. They may choose one target or a Cone Template, and use their Shooting skill for the roll. Those affected make a Strength roll (at −2 if the elemental gets a raise) or are hurled 2d6″. Anyone who strikes a hard object (such as a wall) takes 2d4 non-lethal damage."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "5",
		"attributes": {
			"Vigor": "d6",
			"Smarts": "d6",
			"Spirit": "d6",
			"Agility": "d12",
			"Strength": "d8"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "Air elementals manifest as sentient whirlwinds.",
		"flying_pace": 0,
		"heavy_armor": false,
		"name_plural": "Air Elementals",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 638,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Air Elemental",
			"race": "Air Elemental",
			"rank": 0,
			"size": 0,
			"uuid": "c94beb3b-26b3-11ec-a1cd-8f693d6a0a95",
			"armor": [],
			"edges": [
				{
					"id": 0,
					"name": "-",
					"note": "",
					"bookID": 9,
					"isHidden": false,
					"takenFrom": "",
					"description": "",
					"descriptionHTML": "",
					"customDescription": ""
				}
			],
			"image": "https://savaged.ushttp://www.santharia.com/pictures/quellion/quellion_pics/air_elemental.jpg",
			"swade": false,
			"bookID": 9,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Athletics",
					"value": "d10",
					"bookID": 9,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Shooting",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Stealth",
					"value": "d10",
					"bookID": 9,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": null,
			"parryHR": "6",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Wind Blast",
					"uuid": "",
					"notes": " They may choose one target or a Cone Template, and use their Shooting skill for the roll",
					"range": "cone",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "2d4",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Wind Blast",
							"notes": " They may choose one target or a Cone Template, and use their Shooting skill for the roll",
							"range": "cone",
							"reach": 0,
							"shots": 0,
							"damage": "2d4",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "2d4",
							"parry_modifier": 0,
							"damage_original": "2d4",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 0,
							"damageWithBrackets": "2d4",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "2d4",
					"descriptionHTML": "",
					"damageDiceBasePlus": 0,
					"damageWithBrackets": "2d4"
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Adventure Edition",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Elemental",
					"note": "No additional damage from Called Shots, ignores 1 point of Wound penalties, doesn't breathe, immune to disease and poison.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p182",
					"description": "No additional damage from Called Shots, ignores 1 point of Wound penalties, doesn't breathe, immune to disease and poison."
				},
				{
					"from": "",
					"name": "Gaseous Form",
					"note": "Can maneuver through any non-solid surface, pass through cracks in doors or windows, bubble through water, etc.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p182",
					"description": "Can maneuver through any non-solid surface, pass through cracks in doors or windows, bubble through water, etc."
				},
				{
					"from": "",
					"name": "Flight",
					"note": "Air elementals fly at Pace 12″.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p182",
					"description": "Air elementals fly at Pace 12″."
				},
				{
					"from": "",
					"name": "Invulnerability",
					"note": "Immune to non-magical attacks.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p182",
					"description": "Immune to non-magical attacks."
				},
				{
					"from": "",
					"name": "Wind Blast",
					"note": "2d4 range: Cone Air elementals can send directed blasts of air to knock their foes away. They may choose one target or a Cone Template, and use their Shooting skill for the roll. Those affected make a Strength roll (at -2 if the elemental gets a raise) or are hurled 2d6″. Anyone who strikes a hard object (such as a wall) takes 2d4 non-lethal damage.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p182",
					"description": "2d4 range: Cone Air elementals can send directed blasts of air to knock their foes away. They may choose one target or a Cone Template, and use their Shooting skill for the roll. Those affected make a Strength roll (at -2 if the elemental gets a raise) or are hurled 2d6″. Anyone who strikes a hard object (such as a wall) takes 2d4 non-lethal damage."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": null,
			"parryBase": 6,
			"sizeLabel": "0 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 0,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d12",
					"dieValue": 12
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "strength",
					"label": "Strength",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d6",
					"dieValue": 6
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 6,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2019-01-23T02:00:09.000Z",
			"description": "Air elementals manifest as sentient whirlwinds.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2021-10-06T14:59:45.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 0,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2018",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 5,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 5,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "5",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/swade/air-elemental",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 5,
			"toughnessAsReadNoHeavy": "5",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 2,
		"gear": [],
		"name": "Alligator/Crocodile",
		"pace": "3",
		"race": "",
		"tags": [
			"animal"
		],
		"type": "bestiary",
		"uuid": "0c7a67f8-f786-4d2f-b38a-4746751c7de6",
		"armor": "2",
		"climb": -10,
		"edges": [],
		"flies": 0,
		"image": "http://static.ddmcdn.com/gif/alligator-zigzag-1.jpg",
		"parry": "6",
		"active": true,
		"skills": [
			{
				"Name": "Fighting",
				"Value": "d8"
			},
			{
				"Name": "Notice",
				"Value": "d6"
			},
			{
				"Name": "Swimming",
				"Value": "d8"
			}
		],
		"book_id": 2,
		"summary": "",
		"bookPage": "p135",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Armor +2",
				"Notes": " Thick skin."
			},
			{
				"Name": "Aquatic",
				"Notes": " Pace 5"
			},
			{
				"Name": "Bite",
				"Notes": " Str+d6"
			},
			{
				"Name": "Rollover",
				"Notes": " Both gators and crocs are notorious for grasping their prey in their vice-like jaws and rolling over and over with their flailing victims in their mouth. If one of these large amphibians hits with a raise, it causes an extra 2d4 rollover damage to its prey in addition to its regular Strength damage."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "9",
		"attributes": {
			"Vigor": "d10",
			"Smarts": "d4 (A)",
			"Spirit": "d6",
			"Agility": "d4",
			"Strength": "d10"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "Alligators and crocs are staples of most pulp-genre adventure games. The statistics here represent an average specimen of either species. Much larger versions are often found in more remote areas.",
		"flying_pace": -10,
		"heavy_armor": false,
		"name_plural": "",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 2,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Alligator/Crocodile",
			"race": "Alligator/Crocodile",
			"rank": 0,
			"size": 0,
			"uuid": "0c7a67f8-f786-4d2f-b38a-4746751c7de6",
			"armor": [],
			"edges": [],
			"image": "https://savaged.ushttp://static.ddmcdn.com/gif/alligator-zigzag-1.jpg",
			"swade": false,
			"bookID": 2,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d8",
					"bookID": 2,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d6",
					"bookID": 2,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Swimming",
					"value": "d8",
					"bookID": 2,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": -3,
			"parryHR": "6",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Bite",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d6",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Bite",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d6",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d6+1d10",
							"parry_modifier": 0,
							"damage_original": "str+d6",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 0,
							"damageWithBrackets": "str+d6",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d6+1d10",
					"descriptionHTML": "",
					"damageDiceBasePlus": 0,
					"damageWithBrackets": "str+d6"
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Deluxe (EE)",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Armor +2",
					"note": "Thick skin.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p135",
					"description": "Thick skin."
				},
				{
					"from": "",
					"name": "Aquatic",
					"note": "Pace 5",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p135",
					"description": "Pace 5"
				},
				{
					"from": "",
					"name": "Bite",
					"note": "Str+d6",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p135",
					"description": "Str+d6"
				},
				{
					"from": "",
					"name": "Rollover",
					"note": "Both gators and crocs are notorious for grasping their prey in their vice-like jaws and rolling over and over with their flailing victims in their mouth. If one of these large amphibians hits with a raise, it causes an extra 2d4 rollover damage to its prey in addition to its regular Strength damage.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p135",
					"description": "Both gators and crocs are notorious for grasping their prey in their vice-like jaws and rolling over and over with their flailing victims in their mouth. If one of these large amphibians hits with a raise, it causes an extra 2d4 rollover damage to its prey in addition to its regular Strength damage."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 3,
			"parryBase": 6,
			"sizeLabel": "0 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 2,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d4",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d4 (A)",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "strength",
					"label": "Strength",
					"value": "d10",
					"dieValue": 10
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d10",
					"dieValue": 10
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 6,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2018-05-03T19:05:27.000Z",
			"description": "Alligators and crocs are staples of most pulp-genre adventure games. The statistics here represent an average specimen of either species. Much larger versions are often found in more remote areas.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2018-05-03T19:05:27.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 0,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2011",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 7,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 9,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "9 (2)",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/deluxe/alligator-crocodile",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 7,
			"toughnessAsReadNoHeavy": "9 (2)",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 523,
		"gear": [],
		"name": "Alligator/Crocodile",
		"pace": "3",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "da7d44ac-4db6-4154-9f3f-3fb001199a5c",
		"armor": "2",
		"climb": 0,
		"edges": [
			"-"
		],
		"flies": 0,
		"image": "http://static.ddmcdn.com/gif/alligator-zigzag-1.jpg",
		"parry": "6",
		"active": true,
		"skills": [
			{
				"Name": "Athletics",
				"Value": "d6"
			},
			{
				"Name": "Fighting",
				"Value": "d8"
			},
			{
				"Name": "Notice",
				"Value": "d8"
			},
			{
				"Name": "Stealth",
				"Value": "d8"
			}
		],
		"book_id": 9,
		"summary": "",
		"bookPage": "p180",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Armor +2",
				"Notes": "Thick skin."
			},
			{
				"Name": "Aquatic",
				"Notes": "Pace 5."
			},
			{
				"Name": "Bite",
				"Notes": "Str+d6."
			},
			{
				"Name": "Rollover",
				"Notes": "Gators and crocs grasp prey in heir vice-like jaws and roll with them. If one of these large reptiles hits with a raise, its bonus damage is a d10 instead of a d6."
			},
			{
				"Name": "Size 1",
				"Notes": "Common gators are about 6 to 7′ long, thick, heavy, and weigh just under 500 lbs."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "10",
		"attributes": {
			"Vigor": "d10",
			"Smarts": "d4 (A)",
			"Spirit": "d6",
			"Agility": "d4",
			"Strength": "d10"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "Alligators and crocodiles are staples of most pulp-genre adventure games. The statistics here represent an average specimen of either species. Much larger versions are often found in more remote areas.",
		"flying_pace": 0,
		"heavy_armor": false,
		"name_plural": "Alligator/Crocodiles",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 523,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Alligator/Crocodile",
			"race": "Alligator/Crocodile",
			"rank": 0,
			"size": 1,
			"uuid": "da7d44ac-4db6-4154-9f3f-3fb001199a5c",
			"armor": [],
			"edges": [
				{
					"id": 0,
					"name": "-",
					"note": "",
					"bookID": 9,
					"isHidden": false,
					"takenFrom": "",
					"description": "",
					"descriptionHTML": "",
					"customDescription": ""
				}
			],
			"image": "https://savaged.ushttp://static.ddmcdn.com/gif/alligator-zigzag-1.jpg",
			"swade": false,
			"bookID": 9,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Athletics",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Stealth",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": -3,
			"parryHR": "6",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Bite",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d6",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Bite",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d6",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d6+1d10",
							"parry_modifier": 0,
							"damage_original": "str+d6",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 0,
							"damageWithBrackets": "str+d6",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d6+1d10",
					"descriptionHTML": "",
					"damageDiceBasePlus": 0,
					"damageWithBrackets": "str+d6"
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Adventure Edition",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Armor +2",
					"note": "Thick skin.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p180",
					"description": "Thick skin."
				},
				{
					"from": "",
					"name": "Aquatic",
					"note": "Pace 5.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p180",
					"description": "Pace 5."
				},
				{
					"from": "",
					"name": "Bite",
					"note": "Str+d6.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p180",
					"description": "Str+d6."
				},
				{
					"from": "",
					"name": "Rollover",
					"note": "Gators and crocs grasp prey in heir vice-like jaws and roll with them. If one of these large reptiles hits with a raise, its bonus damage is a d10 instead of a d6.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p180",
					"description": "Gators and crocs grasp prey in heir vice-like jaws and roll with them. If one of these large reptiles hits with a raise, its bonus damage is a d10 instead of a d6."
				},
				{
					"from": "",
					"name": "Size 1",
					"note": "Common gators are about 6 to 7′ long, thick, heavy, and weigh just under 500 lbs.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p180",
					"description": "Common gators are about 6 to 7′ long, thick, heavy, and weigh just under 500 lbs."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 3,
			"parryBase": 6,
			"sizeLabel": "1 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 2,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d4",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d4 (A)",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "strength",
					"label": "Strength",
					"value": "d10",
					"dieValue": 10
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d10",
					"dieValue": 10
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 6,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2019-01-19T01:41:20.000Z",
			"description": "Alligators and crocodiles are staples of most pulp-genre adventure games. The statistics here represent an average specimen of either species. Much larger versions are often found in more remote areas.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2020-11-29T07:25:07.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 1,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2018",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 7,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 10,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "10 (2)",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/swade/alligator-crocodile",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 8,
			"toughnessAsReadNoHeavy": "10 (2)",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 674,
		"gear": [],
		"name": "Ancient Vampire",
		"pace": "6",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "0e40260d-99c7-4a22-829f-42e768cb2e8e",
		"armor": "0",
		"climb": 0,
		"edges": [
			"Frenzy (Imp)",
			"Level Headed"
		],
		"flies": 0,
		"image": "",
		"parry": "7",
		"active": true,
		"skills": [
			{
				"Name": "Athletics",
				"Value": "d8"
			},
			{
				"Name": "Common Knowledge",
				"Value": "d10"
			},
			{
				"Name": "Fighting",
				"Value": "d10"
			},
			{
				"Name": "Intimidation",
				"Value": "d12"
			},
			{
				"Name": "Notice",
				"Value": "d8"
			},
			{
				"Name": "Persuasion",
				"Value": "d12"
			},
			{
				"Name": "Shooting",
				"Value": "d8"
			},
			{
				"Name": "Stealth",
				"Value": "d10"
			}
		],
		"book_id": 9,
		"summary": "",
		"bookPage": "p190",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": 1,
		"abilities": [
			{
				"Name": "Bite/Claws",
				"Notes": "Str+d4."
			},
			{
				"Name": "Change Form",
				"Notes": "As an action, a vampire can change into a wolf or bat with a Smarts roll at −2. Changing back into humanoid form requires a Smarts roll."
			},
			{
				"Name": "Charm",
				"Notes": "Vampires can use the puppet power on those attracted to them (GM's call) using their Smarts as their arcane skill. They can cast and maintain the power indefinitely, but may only affect one target at a time."
			},
			{
				"Name": "Children of the Night",
				"Notes": "Ancient vampires have the ability to summon and control wolves or rats. This requires an action and a Smarts roll at −2. If successful, 1d6 wolves or 1d6 swarms of rats (see Swarm on page 189) come from the surrounding wilds in 1d6+2 rounds."
			},
			{
				"Name": "Invulnerability",
				"Notes": "Vampires can only be slain by sunlight or a stake through the heart (see those Weaknesses, below). They may be Shaken by other attacks, but never Wounded."
			},
			{
				"Name": "Mist",
				"Notes": "Ancient vampires have the ability to turn into mist. Doing so (or returning to human form) requires an action and a Smarts roll at −2."
			},
			{
				"Name": "Sire",
				"Notes": "Anyone slain by a vampire has a 50% chance of rising as a vampire in 1d4 days."
			},
			{
				"Name": "Undead",
				"Notes": "+2 Toughness; +2 to recover from being Shaken; no additional damage from Called Shots; ignores 1 point of Wound penalties; doesn't breathe; immune to disease and poison."
			},
			{
				"Name": "Weakness (Holy Symbol)",
				"Notes": "A character may keep a vampire at bay by displaying a holy symbol. A vampire who wants to directly attack the victim must beat her in an opposed Spirit roll."
			},
			{
				"Name": "Weakness (Holy Water)",
				"Notes": "A vampire sprinkled with holy water is Fatigued. If immersed, he combusts as if it were direct sunlight (see below)."
			},
			{
				"Name": "Weakness (Invitation Only)",
				"Notes": "Vampires cannot enter a private dwelling without being invited. They may enter public domains as they please."
			},
			{
				"Name": "Weakness (Stake Through the Heart)",
				"Notes": "A vampire hit with a Called Shot to the heart (−4) must make a Vigor roll versus the damage total. If successful, it takes damage normally. If it fails, it disintegrates to dust."
			},
			{
				"Name": "Weakness (Sunlight)",
				"Notes": "Vampires burn in sunlight. They take 2d4 damage per round until they are ash. Armor protects normally."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "10",
		"attributes": {
			"Vigor": "d12",
			"Smarts": "d10",
			"Spirit": "d10",
			"Agility": "d8",
			"Strength": "d12+3"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "These statistics are for a vampire somewhat below the legendary Dracula, but far above those bloodsuckers fresh from the grave. The abilities listed here are standard-the GM may want to add other Edges as befit the vampire’s previous lifestyle.",
		"flying_pace": 0,
		"heavy_armor": false,
		"name_plural": "Ancient Vampires",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 674,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Ancient Vampire",
			"race": "Ancient Vampire",
			"rank": 0,
			"size": 0,
			"uuid": "0e40260d-99c7-4a22-829f-42e768cb2e8e",
			"armor": [],
			"edges": [
				{
					"id": 0,
					"name": "Frenzy (Imp)",
					"note": "",
					"bookID": 9,
					"isHidden": false,
					"takenFrom": "",
					"description": "",
					"descriptionHTML": "",
					"customDescription": ""
				},
				{
					"id": 221,
					"name": "Level Headed",
					"note": "",
					"bookID": 9,
					"isHidden": false,
					"takenFrom": "",
					"description": "Draw an additional Action Card each round and choose which one to use.",
					"descriptionHTML": "<p><cite>Savage Worlds: Adventure Edition p.42</cite></p>",
					"customDescription": ""
				}
			],
			"image": "",
			"swade": false,
			"bookID": 9,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Athletics",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Common Knowledge",
					"value": "d10",
					"bookID": 9,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d10",
					"bookID": 9,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Intimidation",
					"value": "d12",
					"bookID": 9,
					"isCore": false,
					"dieValue": 12,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Persuasion",
					"value": "d12",
					"bookID": 9,
					"isCore": false,
					"dieValue": 12,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Shooting",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Stealth",
					"value": "d10",
					"bookID": 9,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 2,
			"fatigue": 0,
			"journal": [],
			"paceMod": 0,
			"parryHR": "7",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Bite/Claws",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d4",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Bite/Claws",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d4",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d4+1d12",
							"parry_modifier": 0,
							"damage_original": "str+d4",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 3,
							"damageWithBrackets": "str+d4",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d4+1d12",
					"descriptionHTML": "",
					"damageDiceBasePlus": 3,
					"damageWithBrackets": "str+d4"
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Adventure Edition",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": true,
			"abilities": [
				{
					"from": "",
					"name": "Bite/Claws",
					"note": "Str+d4.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p190",
					"description": "Str+d4."
				},
				{
					"from": "",
					"name": "Change Form",
					"note": "As an action, a vampire can change into a wolf or bat with a Smarts roll at -2. Changing back into humanoid form requires a Smarts roll.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p190",
					"description": "As an action, a vampire can change into a wolf or bat with a Smarts roll at -2. Changing back into humanoid form requires a Smarts roll."
				},
				{
					"from": "",
					"name": "Charm",
					"note": "Vampires can use the puppet power on those attracted to them (GM's call) using their Smarts as their arcane skill. They can cast and maintain the power indefinitely, but may only affect one target at a time.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p190",
					"description": "Vampires can use the puppet power on those attracted to them (GM's call) using their Smarts as their arcane skill. They can cast and maintain the power indefinitely, but may only affect one target at a time."
				},
				{
					"from": "",
					"name": "Children of the Night",
					"note": "Ancient vampires have the ability to summon and control wolves or rats. This requires an action and a Smarts roll at -2. If successful, 1d6 wolves or 1d6 swarms of rats (see Swarm on page 189) come from the surrounding wilds in 1d6+2 rounds.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p190",
					"description": "Ancient vampires have the ability to summon and control wolves or rats. This requires an action and a Smarts roll at -2. If successful, 1d6 wolves or 1d6 swarms of rats (see Swarm on page 189) come from the surrounding wilds in 1d6+2 rounds."
				},
				{
					"from": "",
					"name": "Invulnerability",
					"note": "Vampires can only be slain by sunlight or a stake through the heart (see those Weaknesses, below). They may be Shaken by other attacks, but never Wounded.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p190",
					"description": "Vampires can only be slain by sunlight or a stake through the heart (see those Weaknesses, below). They may be Shaken by other attacks, but never Wounded."
				},
				{
					"from": "",
					"name": "Mist",
					"note": "Ancient vampires have the ability to turn into mist. Doing so (or returning to human form) requires an action and a Smarts roll at -2.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p190",
					"description": "Ancient vampires have the ability to turn into mist. Doing so (or returning to human form) requires an action and a Smarts roll at -2."
				},
				{
					"from": "",
					"name": "Sire",
					"note": "Anyone slain by a vampire has a 50% chance of rising as a vampire in 1d4 days.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p190",
					"description": "Anyone slain by a vampire has a 50% chance of rising as a vampire in 1d4 days."
				},
				{
					"from": "",
					"name": "Undead",
					"note": "+2 Toughness; +2 to recover from being Shaken; no additional damage from Called Shots; ignores 1 point of Wound penalties; doesn't breathe; immune to disease and poison.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p190",
					"description": "+2 Toughness; +2 to recover from being Shaken; no additional damage from Called Shots; ignores 1 point of Wound penalties; doesn't breathe; immune to disease and poison."
				},
				{
					"from": "",
					"name": "Weakness (Holy Symbol)",
					"note": "A character may keep a vampire at bay by displaying a holy symbol. A vampire who wants to directly attack the victim must beat her in an opposed Spirit roll.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p190",
					"description": "A character may keep a vampire at bay by displaying a holy symbol. A vampire who wants to directly attack the victim must beat her in an opposed Spirit roll."
				},
				{
					"from": "",
					"name": "Weakness (Holy Water)",
					"note": "A vampire sprinkled with holy water is Fatigued. If immersed, he combusts as if it were direct sunlight (see below).",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p190",
					"description": "A vampire sprinkled with holy water is Fatigued. If immersed, he combusts as if it were direct sunlight (see below)."
				},
				{
					"from": "",
					"name": "Weakness (Invitation Only)",
					"note": "Vampires cannot enter a private dwelling without being invited. They may enter public domains as they please.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p190",
					"description": "Vampires cannot enter a private dwelling without being invited. They may enter public domains as they please."
				},
				{
					"from": "",
					"name": "Weakness (Stake Through the Heart)",
					"note": "A vampire hit with a Called Shot to the heart (-4) must make a Vigor roll versus the damage total. If successful, it takes damage normally. If it fails, it disintegrates to dust.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p190",
					"description": "A vampire hit with a Called Shot to the heart (-4) must make a Vigor roll versus the damage total. If successful, it takes damage normally. If it fails, it disintegrates to dust."
				},
				{
					"from": "",
					"name": "Weakness (Sunlight)",
					"note": "Vampires burn in sunlight. They take 2d4 damage per round until they are ash. Armor protects normally.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p190",
					"description": "Vampires burn in sunlight. They take 2d4 damage per round until they are ash. Armor protects normally."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 6,
			"parryBase": 7,
			"sizeLabel": "0 (Normal)",
			"strainMax": 0,
			"woundsMax": 3,
			"appVersion": "3.2.3b010603",
			"armorValue": 0,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d10",
					"dieValue": 10
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d10",
					"dieValue": 10
				},
				{
					"mod": 3,
					"name": "strength",
					"label": "Strength",
					"value": "d12+3",
					"dieValue": 12
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d12",
					"dieValue": 12
				}
			],
			"background": "",
			"benniesMax": 2,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 7,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 3,
			"bookPrimary": true,
			"createdDate": "2019-01-23T02:15:51.000Z",
			"description": "These statistics are for a vampire somewhat below the legendary Dracula, but far above those bloodsuckers fresh from the grave. The abilities listed here are standard-the GM may want to add other Edges as befit the vampire's previous lifestyle.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2019-01-23T02:15:51.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 2,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2018",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 8,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 10,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "10",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/swade/ancient-vampire",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 10,
			"toughnessAsReadNoHeavy": "10",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 524,
		"gear": [],
		"name": "Bear",
		"pace": "8",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "29e9ec20-199b-40a0-a7e6-66f0a4e05738",
		"armor": "0",
		"climb": 0,
		"edges": [
			"Brute"
		],
		"flies": 0,
		"image": "",
		"parry": "6",
		"active": true,
		"skills": [
			{
				"Name": "Athletics",
				"Value": "d8"
			},
			{
				"Name": "Fighting",
				"Value": "d8"
			},
			{
				"Name": "Notice",
				"Value": "d8"
			},
			{
				"Name": "Stealth",
				"Value": "d8"
			}
		],
		"book_id": 9,
		"summary": "",
		"bookPage": "p180",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Bite/Claws",
				"Notes": "Str+d6."
			},
			{
				"Name": "Size 2",
				"Notes": "These creatures can stand up to 8′ tall and weigh over 1,000 pounds."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "10",
		"attributes": {
			"Vigor": "d12",
			"Smarts": "d6 (A)",
			"Spirit": "d8",
			"Agility": "d6",
			"Strength": "d12+2"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "This entry covers the larger ursines, such as grizzlies, Kodiaks, and polar bears. A favored tactic of all these bears is to grapple and bite with their fierce fangs.",
		"flying_pace": 0,
		"heavy_armor": false,
		"name_plural": "Bears",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 524,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Bear",
			"race": "Bear",
			"rank": 0,
			"size": 2,
			"uuid": "29e9ec20-199b-40a0-a7e6-66f0a4e05738",
			"armor": [],
			"edges": [
				{
					"id": 182,
					"name": "Brute",
					"note": "",
					"bookID": 9,
					"isHidden": false,
					"takenFrom": "",
					"description": "Link Athletics to Strength instead of Agility. This includes resisting Athletics Tests as well.",
					"descriptionHTML": "<p><cite>Savage Worlds: Adventure Edition p.38</cite></p>",
					"customDescription": ""
				}
			],
			"image": "",
			"swade": false,
			"bookID": 9,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Athletics",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Stealth",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": 2,
			"parryHR": "6",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Bite/Claws",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d6",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Bite/Claws",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d6",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d6+1d12",
							"parry_modifier": 0,
							"damage_original": "str+d6",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 2,
							"damageWithBrackets": "str+d6",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d6+1d12",
					"descriptionHTML": "",
					"damageDiceBasePlus": 2,
					"damageWithBrackets": "str+d6"
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Adventure Edition",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Bite/Claws",
					"note": "Str+d6.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p180",
					"description": "Str+d6."
				},
				{
					"from": "",
					"name": "Size 2",
					"note": "These creatures can stand up to 8′ tall and weigh over 1,000 pounds.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p180",
					"description": "These creatures can stand up to 8′ tall and weigh over 1,000 pounds."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 8,
			"parryBase": 6,
			"sizeLabel": "2 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 0,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d6 (A)",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 2,
					"name": "strength",
					"label": "Strength",
					"value": "d12+2",
					"dieValue": 12
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d12",
					"dieValue": 12
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 6,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2019-01-19T01:41:36.000Z",
			"description": "This entry covers the larger ursines, such as grizzlies, Kodiaks, and polar bears. A favored tactic of all these bears is to grapple and bite with their fierce fangs.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2019-05-13T01:34:44.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 2,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2018",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 8,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 10,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "10",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/swade/bear",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 10,
			"toughnessAsReadNoHeavy": "10",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 4,
		"gear": [],
		"name": "Bear, Large",
		"pace": "8",
		"race": "",
		"tags": [
			"animal"
		],
		"type": "bestiary",
		"uuid": "86fc6c24-be66-410d-887a-3f75e7c50ba5",
		"armor": "0",
		"climb": -10,
		"edges": [],
		"flies": 0,
		"image": "http://static.comicvine.com/uploads/original/8/85165/1933110-grizzly_bear.jpg",
		"parry": "6",
		"active": true,
		"skills": [
			{
				"Name": "Fighting",
				"Value": "d8"
			},
			{
				"Name": "Notice",
				"Value": "d6"
			},
			{
				"Name": "Swimming",
				"Value": "d6"
			}
		],
		"book_id": 2,
		"summary": "",
		"bookPage": "p135",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Bear Hug",
				"Notes": " Bears don't actually &#x201c;hug&#x201d; their victims, but they do attempt to use their weight to pin their prey and rend it with their claws and teeth. A bear that hits with a raise has pinned his foe. The opponent may only attempt to escape the &#x201c;hug&#x201d; on his action, which requires a raise on an opposed Strength roll."
			},
			{
				"Name": "Claws",
				"Notes": " Str+d6"
			},
			{
				"Name": "Size +2",
				"Notes": " These creatures can standup to 8'tall and weigh over 1000 pounds."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "10",
		"attributes": {
			"Vigor": "d12",
			"Smarts": "d6 (A)",
			"Spirit": "d8",
			"Agility": "d6",
			"Strength": "d12+4"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "Large bears covers grizzlies, kodiaks, and massive polar bears.",
		"flying_pace": -10,
		"heavy_armor": false,
		"name_plural": "",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 4,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Bear, Large",
			"race": "Bear, Large",
			"rank": 0,
			"size": 2,
			"uuid": "86fc6c24-be66-410d-887a-3f75e7c50ba5",
			"armor": [],
			"edges": [],
			"image": "https://savaged.ushttp://static.comicvine.com/uploads/original/8/85165/1933110-grizzly_bear.jpg",
			"swade": false,
			"bookID": 2,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d8",
					"bookID": 2,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d6",
					"bookID": 2,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Swimming",
					"value": "d6",
					"bookID": 2,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": 2,
			"parryHR": "6",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Claws",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d6",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Claws",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d6",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d6+1d12",
							"parry_modifier": 0,
							"damage_original": "str+d6",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 4,
							"damageWithBrackets": "str+d6",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d6+1d12",
					"descriptionHTML": "",
					"damageDiceBasePlus": 4,
					"damageWithBrackets": "str+d6"
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Deluxe (EE)",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Bear Hug",
					"note": "Bears don't actually &#x201c;hug&#x201d; their victims, but they do attempt to use their weight to pin their prey and rend it with their claws and teeth. A bear that hits with a raise has pinned his foe. The opponent may only attempt to escape the &#x201c;hug&#x201d; on his action, which requires a raise on an opposed Strength roll.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p135",
					"description": "Bears don't actually &#x201c;hug&#x201d; their victims, but they do attempt to use their weight to pin their prey and rend it with their claws and teeth. A bear that hits with a raise has pinned his foe. The opponent may only attempt to escape the &#x201c;hug&#x201d; on his action, which requires a raise on an opposed Strength roll."
				},
				{
					"from": "",
					"name": "Claws",
					"note": "Str+d6",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p135",
					"description": "Str+d6"
				},
				{
					"from": "",
					"name": "Size +2",
					"note": "These creatures can standup to 8'tall and weigh over 1000 pounds.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p135",
					"description": "These creatures can standup to 8'tall and weigh over 1000 pounds."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 8,
			"parryBase": 6,
			"sizeLabel": "2 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 0,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d6 (A)",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 4,
					"name": "strength",
					"label": "Strength",
					"value": "d12+4",
					"dieValue": 12
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d12",
					"dieValue": 12
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 6,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2018-05-03T19:05:27.000Z",
			"description": "Large bears covers grizzlies, kodiaks, and massive polar bears.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2018-05-03T19:05:27.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 2,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2011",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 8,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 10,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "10",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/deluxe/bear-large",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 10,
			"toughnessAsReadNoHeavy": "10",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 5,
		"gear": [],
		"name": "Bull",
		"pace": "7",
		"race": "",
		"tags": [
			"animal"
		],
		"type": "bestiary",
		"uuid": "5b1607b5-c17c-41fa-b8ec-0eaf896b9b68",
		"armor": "0",
		"climb": -10,
		"edges": [],
		"flies": 0,
		"image": "http://www.shockingtimes.co.uk/wp-content/uploads/2010/03/Bull.jpg",
		"parry": "4",
		"active": true,
		"skills": [
			{
				"Name": "Fighting",
				"Value": "d4"
			},
			{
				"Name": "Notice",
				"Value": "d6"
			}
		],
		"book_id": 2,
		"summary": "",
		"bookPage": "p135",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Horns",
				"Notes": " Str+d6"
			},
			{
				"Name": "Gore",
				"Notes": " Bulls charge maneuver to gore their opponents with their long horns. If they can move at least 6&#x201d; before attacking, they add +4 to their damage total."
			},
			{
				"Name": "Size +2",
				"Notes": " Bulls are large creatures."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "10",
		"attributes": {
			"Vigor": "d12",
			"Smarts": "d4 (A)",
			"Spirit": "d8",
			"Agility": "d6",
			"Strength": "d12+2"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "Bulls are usually only aggressive toward humans when enraged. Of course, if you're looking up the statistics here, it's probably already seeing red.",
		"flying_pace": -10,
		"heavy_armor": false,
		"name_plural": "",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 5,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Bull",
			"race": "Bull",
			"rank": 0,
			"size": 2,
			"uuid": "5b1607b5-c17c-41fa-b8ec-0eaf896b9b68",
			"armor": [],
			"edges": [],
			"image": "https://savaged.ushttp://www.shockingtimes.co.uk/wp-content/uploads/2010/03/Bull.jpg",
			"swade": false,
			"bookID": 2,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d4",
					"bookID": 2,
					"isCore": false,
					"dieValue": 4,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d6",
					"bookID": 2,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": 1,
			"parryHR": "4",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Horns",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d6",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Horns",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d6",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d6+1d12",
							"parry_modifier": 0,
							"damage_original": "str+d6",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 2,
							"damageWithBrackets": "str+d6",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d6+1d12",
					"descriptionHTML": "",
					"damageDiceBasePlus": 2,
					"damageWithBrackets": "str+d6"
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Deluxe (EE)",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Horns",
					"note": "Str+d6",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p135",
					"description": "Str+d6"
				},
				{
					"from": "",
					"name": "Gore",
					"note": "Bulls charge maneuver to gore their opponents with their long horns. If they can move at least 6&#x201d; before attacking, they add +4 to their damage total.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p135",
					"description": "Bulls charge maneuver to gore their opponents with their long horns. If they can move at least 6&#x201d; before attacking, they add +4 to their damage total."
				},
				{
					"from": "",
					"name": "Size +2",
					"note": "Bulls are large creatures.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p135",
					"description": "Bulls are large creatures."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 7,
			"parryBase": 4,
			"sizeLabel": "2 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 0,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d4 (A)",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 2,
					"name": "strength",
					"label": "Strength",
					"value": "d12+2",
					"dieValue": 12
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d12",
					"dieValue": 12
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 4,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2018-05-03T19:05:27.000Z",
			"description": "Bulls are usually only aggressive toward humans when enraged. Of course, if you're looking up the statistics here, it's probably already seeing red.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2018-05-03T19:05:27.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 2,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2011",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 8,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 10,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "10",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/deluxe/bull",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 10,
			"toughnessAsReadNoHeavy": "10",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 525,
		"gear": [],
		"name": "Bull",
		"pace": "7",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "1c1adbfd-4825-429e-2c86-8beb92a91e5c",
		"armor": "0",
		"climb": 0,
		"edges": [
			"-"
		],
		"flies": 0,
		"image": "http://www.shockingtimes.co.uk/wp-content/uploads/2010/03/Bull.jpg",
		"parry": "4",
		"active": true,
		"skills": [
			{
				"Name": "Athletics",
				"Value": "d8"
			},
			{
				"Name": "Fighting",
				"Value": "d4"
			},
			{
				"Name": "Notice",
				"Value": "d6"
			}
		],
		"book_id": 9,
		"summary": "",
		"bookPage": "p180",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Horns",
				"Notes": "Str+d6."
			},
			{
				"Name": "Size 3",
				"Notes": "Most bulls weigh just under a ton."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "11",
		"attributes": {
			"Vigor": "d12",
			"Smarts": "d4 (A)",
			"Spirit": "d8",
			"Agility": "d6",
			"Strength": "d12+3"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "Bulls are usually only aggressive toward humans when enraged. Of course, if you’re looking up the statistics here, it’s probably already seeing red.",
		"flying_pace": 0,
		"heavy_armor": false,
		"name_plural": "Bulls",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 525,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Bull",
			"race": "Bull",
			"rank": 0,
			"size": 3,
			"uuid": "1c1adbfd-4825-429e-2c86-8beb92a91e5c",
			"armor": [],
			"edges": [
				{
					"id": 0,
					"name": "-",
					"note": "",
					"bookID": 9,
					"isHidden": false,
					"takenFrom": "",
					"description": "",
					"descriptionHTML": "",
					"customDescription": ""
				}
			],
			"image": "https://savaged.ushttp://www.shockingtimes.co.uk/wp-content/uploads/2010/03/Bull.jpg",
			"swade": false,
			"bookID": 9,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Athletics",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d4",
					"bookID": 9,
					"isCore": false,
					"dieValue": 4,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": 1,
			"parryHR": "4",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Horns",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d6",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Horns",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d6",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d6+1d12",
							"parry_modifier": 0,
							"damage_original": "str+d6",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 3,
							"damageWithBrackets": "str+d6",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d6+1d12",
					"descriptionHTML": "",
					"damageDiceBasePlus": 3,
					"damageWithBrackets": "str+d6"
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Adventure Edition",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Horns",
					"note": "Str+d6.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p180",
					"description": "Str+d6."
				},
				{
					"from": "",
					"name": "Size 3",
					"note": "Most bulls weigh just under a ton.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p180",
					"description": "Most bulls weigh just under a ton."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 7,
			"parryBase": 4,
			"sizeLabel": "3 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 0,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d4 (A)",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 3,
					"name": "strength",
					"label": "Strength",
					"value": "d12+3",
					"dieValue": 12
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d12",
					"dieValue": 12
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 4,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2019-01-19T01:42:21.000Z",
			"description": "Bulls are usually only aggressive toward humans when enraged. Of course, if you're looking up the statistics here, it's probably already seeing red.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2019-05-13T01:34:57.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 3,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2018",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 8,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 11,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "11",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/swade/bull",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 11,
			"toughnessAsReadNoHeavy": "11",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 6,
		"gear": [],
		"name": "Cat, Small",
		"pace": "6",
		"race": "",
		"tags": [
			"animal"
		],
		"type": "bestiary",
		"uuid": "f7265b26-e7ba-4042-849f-ac5b6159ee42",
		"armor": "0",
		"climb": -10,
		"edges": [],
		"flies": 0,
		"image": "http://www.southbham.cats.org.uk/uploads/images/pages/cat-stalking-crop.jpg",
		"parry": "3",
		"active": true,
		"skills": [
			{
				"Name": "Climbing",
				"Value": "d6"
			},
			{
				"Name": "Notice",
				"Value": "d6"
			},
			{
				"Name": "Stealth",
				"Value": "d8"
			}
		],
		"book_id": 2,
		"summary": "",
		"bookPage": "p135",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Acrobat",
				"Notes": " +2 to Agility rolls to perform acrobatic maneuvers; +1 to Parry if unencumbered."
			},
			{
				"Name": "Bite/Claw",
				"Notes": " Str."
			},
			{
				"Name": "Low Light Vision",
				"Notes": " Cats ignore penalties for Dim and Dark lighting."
			},
			{
				"Name": "Size -2",
				"Notes": " Cats are typically less than a foot high."
			},
			{
				"Name": "Small",
				"Notes": " Attackers subtract 2 from their attacks to hit."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "3",
		"attributes": {
			"Vigor": "d6",
			"Smarts": "d6 (A)",
			"Spirit": "d10",
			"Agility": "d8",
			"Strength": "d4"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "This is an ordinary house cat, the sort that might be a familiar for a spellcaster, a Beast Master's animal friend, or an alternate form for the shape change power.",
		"flying_pace": -10,
		"heavy_armor": false,
		"name_plural": "",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 6,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Cat, Small",
			"race": "Cat, Small",
			"rank": 0,
			"size": -2,
			"uuid": "f7265b26-e7ba-4042-849f-ac5b6159ee42",
			"armor": [],
			"edges": [],
			"image": "https://savaged.ushttp://www.southbham.cats.org.uk/uploads/images/pages/cat-stalking-crop.jpg",
			"swade": false,
			"bookID": 2,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Climbing",
					"value": "d6",
					"bookID": 2,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d6",
					"bookID": 2,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Stealth",
					"value": "d8",
					"bookID": 2,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": 0,
			"parryHR": "3",
			"shields": [],
			"weapons": [],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Deluxe (EE)",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Acrobat",
					"note": "+2 to Agility rolls to perform acrobatic maneuvers; +1 to Parry if unencumbered.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p135",
					"description": "+2 to Agility rolls to perform acrobatic maneuvers; +1 to Parry if unencumbered."
				},
				{
					"from": "",
					"name": "Bite/Claw",
					"note": "Str.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p135",
					"description": "Str."
				},
				{
					"from": "",
					"name": "Low Light Vision",
					"note": "Cats ignore penalties for Dim and Dark lighting.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p135",
					"description": "Cats ignore penalties for Dim and Dark lighting."
				},
				{
					"from": "",
					"name": "Size -2",
					"note": "Cats are typically less than a foot high.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p135",
					"description": "Cats are typically less than a foot high."
				},
				{
					"from": "",
					"name": "Small",
					"note": "Attackers subtract 2 from their attacks to hit.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p135",
					"description": "Attackers subtract 2 from their attacks to hit."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 6,
			"parryBase": 3,
			"sizeLabel": "-2 (Small)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 0,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d6 (A)",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d10",
					"dieValue": 10
				},
				{
					"mod": 0,
					"name": "strength",
					"label": "Strength",
					"value": "d4",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d6",
					"dieValue": 6
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 3,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2018-05-03T19:05:27.000Z",
			"description": "This is an ordinary house cat, the sort that might be a familiar for a spellcaster, a Beast Master's animal friend, or an alternate form for the shape change power.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2018-05-03T19:05:27.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": -2,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2011",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 5,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 3,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "3",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/deluxe/cat-small",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 3,
			"toughnessAsReadNoHeavy": "3",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 526,
		"gear": [],
		"name": "Cat, Small",
		"pace": "6",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "d2ba5a96-2813-4809-0590-869c34e5813c",
		"armor": "0",
		"climb": 0,
		"edges": [
			"Acrobat"
		],
		"flies": 0,
		"image": "http://www.southbham.cats.org.uk/uploads/images/pages/cat-stalking-crop.jpg",
		"parry": "2",
		"active": true,
		"skills": [
			{
				"Name": "Athletics",
				"Value": "d8"
			},
			{
				"Name": "Notice",
				"Value": "d6"
			},
			{
				"Name": "Stealth",
				"Value": "d8"
			}
		],
		"book_id": 9,
		"summary": "",
		"bookPage": "p180",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Bite/Claws",
				"Notes": "Str."
			},
			{
				"Name": "Low Light Vision",
				"Notes": "Cats ignore penalties for Dim and Dark Illumination."
			},
			{
				"Name": "Size −3 (Very Small)",
				"Notes": "Cats are typically less than a foot high."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "2",
		"attributes": {
			"Vigor": "d6",
			"Smarts": "d4 (A)",
			"Spirit": "d10",
			"Agility": "d8",
			"Strength": "d4-2"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "This is an ordinary house cat, the sort that might be a familiar for a spellcaster, a Beast Master’s animal friend, or an alternate form for the shape change power.",
		"flying_pace": 0,
		"heavy_armor": false,
		"name_plural": "Cat, Smalls",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 526,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Cat, Small",
			"race": "Cat, Small",
			"rank": 0,
			"size": -3,
			"uuid": "d2ba5a96-2813-4809-0590-869c34e5813c",
			"armor": [],
			"edges": [
				{
					"id": 294,
					"name": "Acrobat",
					"note": "",
					"bookID": 9,
					"isHidden": false,
					"takenFrom": "",
					"description": "May Reroll most Athletics.",
					"descriptionHTML": "<p><cite>Savage Worlds: Adventure Edition p.48</cite></p>",
					"customDescription": ""
				}
			],
			"image": "https://savaged.ushttp://www.southbham.cats.org.uk/uploads/images/pages/cat-stalking-crop.jpg",
			"swade": false,
			"bookID": 9,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Athletics",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Stealth",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": 0,
			"parryHR": "2",
			"shields": [],
			"weapons": [],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Adventure Edition",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Bite/Claws",
					"note": "Str.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p180",
					"description": "Str."
				},
				{
					"from": "",
					"name": "Low Light Vision",
					"note": "Cats ignore penalties for Dim and Dark Illumination.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p180",
					"description": "Cats ignore penalties for Dim and Dark Illumination."
				},
				{
					"from": "",
					"name": "Size -3 (Very Small)",
					"note": "Cats are typically less than a foot high.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p180",
					"description": "Cats are typically less than a foot high."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 6,
			"parryBase": 2,
			"sizeLabel": "-3 (Very Small)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 0,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d4 (A)",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d10",
					"dieValue": 10
				},
				{
					"mod": 0,
					"name": "strength",
					"label": "Strength",
					"value": "d4-2",
					"dieValue": 0
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d6",
					"dieValue": 6
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 2,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2019-01-19T01:44:35.000Z",
			"description": "This is an ordinary house cat, the sort that might be a familiar for a spellcaster, a Beast Master's animal friend, or an alternate form for the shape change power.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2019-03-07T03:56:23.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": -3,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2018",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 5,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 2,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "2",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/swade/cat-small",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 2,
			"toughnessAsReadNoHeavy": "2",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 527,
		"gear": [],
		"name": "Dire Wolf",
		"pace": "10",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "201e694d-8c0a-4008-a0c4-5ce2521f2880",
		"armor": "0",
		"climb": 0,
		"edges": [
			"Alertness"
		],
		"flies": 0,
		"image": "",
		"parry": "6",
		"active": true,
		"skills": [
			{
				"Name": "Athletics",
				"Value": "d8"
			},
			{
				"Name": "Fighting",
				"Value": "d8"
			},
			{
				"Name": "Intimidation",
				"Value": "d8"
			},
			{
				"Name": "Notice",
				"Value": "d6"
			},
			{
				"Name": "Stealth",
				"Value": "d8"
			}
		],
		"book_id": 9,
		"summary": "",
		"bookPage": "p181",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Bite",
				"Notes": "Str+d6."
			},
			{
				"Name": "Speed",
				"Notes": "d10 running die."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "6",
		"attributes": {
			"Vigor": "d8",
			"Smarts": "d4 (A)",
			"Spirit": "d6",
			"Agility": "d8",
			"Strength": "d8"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "Dire wolves are very large and feral canines often used by orcs as attack dogs. They may also be found roaming in packs in the deepest, darkest woods.",
		"flying_pace": 0,
		"heavy_armor": false,
		"name_plural": "Dire Wolfs",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 527,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Dire Wolf",
			"race": "Dire Wolf",
			"rank": 0,
			"size": 0,
			"uuid": "201e694d-8c0a-4008-a0c4-5ce2521f2880",
			"armor": [],
			"edges": [
				{
					"id": 171,
					"name": "Alertness",
					"note": "",
					"bookID": 9,
					"isHidden": false,
					"takenFrom": "",
					"description": "+2 to Notice tests",
					"descriptionHTML": "<p><cite>Savage Worlds: Adventure Edition p.37</cite></p>",
					"customDescription": ""
				}
			],
			"image": "",
			"swade": false,
			"bookID": 9,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Athletics",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Intimidation",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Stealth",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": 4,
			"parryHR": "6",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Bite",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d6",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Bite",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d6",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d6+1d8",
							"parry_modifier": 0,
							"damage_original": "str+d6",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 0,
							"damageWithBrackets": "str+d6",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d6+1d8",
					"descriptionHTML": "",
					"damageDiceBasePlus": 0,
					"damageWithBrackets": "str+d6"
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Adventure Edition",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Bite",
					"note": "Str+d6.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p181",
					"description": "Str+d6."
				},
				{
					"from": "",
					"name": "Speed",
					"note": "d10 running die.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p181",
					"description": "d10 running die."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 10,
			"parryBase": 6,
			"sizeLabel": "0 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 0,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d4 (A)",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "strength",
					"label": "Strength",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d8",
					"dieValue": 8
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 6,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2019-01-19T01:45:32.000Z",
			"description": "Dire wolves are very large and feral canines often used by orcs as attack dogs. They may also be found roaming in packs in the deepest, darkest woods.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2019-03-01T19:56:22.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 0,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2018",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 6,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 6,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "6",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/swade/dire-wolf",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 6,
			"toughnessAsReadNoHeavy": "6",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 528,
		"gear": [],
		"name": "Dog / Wolf",
		"pace": "8",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "8ad8ef49-6340-48dc-0e86-0f1598c03aae",
		"armor": "0",
		"climb": 0,
		"edges": [
			"Alertness"
		],
		"flies": 0,
		"image": "",
		"parry": "5",
		"active": true,
		"skills": [
			{
				"Name": "Athletics",
				"Value": "d8"
			},
			{
				"Name": "Fighting",
				"Value": "d6"
			},
			{
				"Name": "Notice",
				"Value": "d10"
			},
			{
				"Name": "Stealth",
				"Value": "d8"
			}
		],
		"book_id": 9,
		"summary": "",
		"bookPage": "p181",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Bite",
				"Notes": "Str+d4."
			},
			{
				"Name": "Size −1",
				"Notes": "The heads of these canines come to an average human's waist, and they weigh about 60 pounds."
			},
			{
				"Name": "Speed",
				"Notes": "d10 running die."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "4",
		"attributes": {
			"Vigor": "d6",
			"Smarts": "d6 (A)",
			"Spirit": "d6",
			"Agility": "d8",
			"Strength": "d6"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "The stats below are for large attack dogs, such as Rottweilers and Doberman Pinschers, as well as wolves, hyenas, and the like.",
		"flying_pace": 0,
		"heavy_armor": false,
		"name_plural": "Dogs / Wolves",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 528,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Dog / Wolf",
			"race": "Dog / Wolf",
			"rank": 0,
			"size": -1,
			"uuid": "8ad8ef49-6340-48dc-0e86-0f1598c03aae",
			"armor": [],
			"edges": [
				{
					"id": 171,
					"name": "Alertness",
					"note": "",
					"bookID": 9,
					"isHidden": false,
					"takenFrom": "",
					"description": "+2 to Notice tests",
					"descriptionHTML": "<p><cite>Savage Worlds: Adventure Edition p.37</cite></p>",
					"customDescription": ""
				}
			],
			"image": "",
			"swade": false,
			"bookID": 9,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Athletics",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d10",
					"bookID": 9,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Stealth",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": 2,
			"parryHR": "5",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Bite",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d4",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Bite",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d4",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d4+1d6",
							"parry_modifier": 0,
							"damage_original": "str+d4",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 0,
							"damageWithBrackets": "str+d4",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d4+1d6",
					"descriptionHTML": "",
					"damageDiceBasePlus": 0,
					"damageWithBrackets": "str+d4"
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Adventure Edition",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Bite",
					"note": "Str+d4.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p181",
					"description": "Str+d4."
				},
				{
					"from": "",
					"name": "Size -1",
					"note": "The heads of these canines come to an average human's waist, and they weigh about 60 pounds.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p181",
					"description": "The heads of these canines come to an average human's waist, and they weigh about 60 pounds."
				},
				{
					"from": "",
					"name": "Speed",
					"note": "d10 running die.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p181",
					"description": "d10 running die."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 8,
			"parryBase": 5,
			"sizeLabel": "-1 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 0,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d6 (A)",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "strength",
					"label": "Strength",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d6",
					"dieValue": 6
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 5,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2019-01-19T01:47:07.000Z",
			"description": "The stats below are for large attack dogs, such as Rottweilers and Doberman Pinschers, as well as wolves, hyenas, and the like.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2019-11-01T14:31:25.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": -1,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2018",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 5,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 4,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "4",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/swade/dog-- wolf",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 4,
			"toughnessAsReadNoHeavy": "4",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 7,
		"gear": [],
		"name": "Dog/Wolf",
		"pace": "8",
		"race": "",
		"tags": [
			"animal"
		],
		"type": "bestiary",
		"uuid": "69e976fc-7698-417e-b1a3-376ca255e4a7",
		"armor": "0",
		"climb": -10,
		"edges": [],
		"flies": 0,
		"image": "http://www.green-humanity.com/uploads/8/0/4/1/8041038/9168429_orig.jpg",
		"parry": "5",
		"active": true,
		"skills": [
			{
				"Name": "Fighting",
				"Value": "d6"
			},
			{
				"Name": "Notice",
				"Value": "d6"
			}
		],
		"book_id": 2,
		"summary": "",
		"bookPage": "p135",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Bite",
				"Notes": " Str+d4."
			},
			{
				"Name": "Fleet-Footed",
				"Notes": " Roll a d10 when running instead of a d6."
			},
			{
				"Name": "Go for the Throat",
				"Notes": " Dogs instinctively go for an opponent's soft spots. With a raise on its attack roll, it hits the target's most weakly armored location."
			},
			{
				"Name": "Size -1",
				"Notes": " Dogs are relatively small."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "4",
		"attributes": {
			"Vigor": "d6",
			"Smarts": "d6 (A)",
			"Spirit": "d6",
			"Agility": "d8",
			"Strength": "d6"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "The stats below are for large attack dogs, such as Rottweilers and Doberman Pinschers, as well as wolves, hyenas, and the like.",
		"flying_pace": -10,
		"heavy_armor": false,
		"name_plural": "",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 7,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Dog/Wolf",
			"race": "Dog/Wolf",
			"rank": 0,
			"size": -1,
			"uuid": "69e976fc-7698-417e-b1a3-376ca255e4a7",
			"armor": [],
			"edges": [],
			"image": "https://savaged.ushttp://www.green-humanity.com/uploads/8/0/4/1/8041038/9168429_orig.jpg",
			"swade": false,
			"bookID": 2,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d6",
					"bookID": 2,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d6",
					"bookID": 2,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": 2,
			"parryHR": "5",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Bite",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d4",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Bite",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d4",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d4+1d6",
							"parry_modifier": 0,
							"damage_original": "str+d4",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 0,
							"damageWithBrackets": "str+d4",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d4+1d6",
					"descriptionHTML": "",
					"damageDiceBasePlus": 0,
					"damageWithBrackets": "str+d4"
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Deluxe (EE)",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Bite",
					"note": "Str+d4.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p135",
					"description": "Str+d4."
				},
				{
					"from": "",
					"name": "Fleet-Footed",
					"note": "Roll a d10 when running instead of a d6.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p135",
					"description": "Roll a d10 when running instead of a d6."
				},
				{
					"from": "",
					"name": "Go for the Throat",
					"note": "Dogs instinctively go for an opponent's soft spots. With a raise on its attack roll, it hits the target's most weakly armored location.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p135",
					"description": "Dogs instinctively go for an opponent's soft spots. With a raise on its attack roll, it hits the target's most weakly armored location."
				},
				{
					"from": "",
					"name": "Size -1",
					"note": "Dogs are relatively small.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p135",
					"description": "Dogs are relatively small."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 8,
			"parryBase": 5,
			"sizeLabel": "-1 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 0,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d6 (A)",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "strength",
					"label": "Strength",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d6",
					"dieValue": 6
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 5,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2018-05-03T19:05:27.000Z",
			"description": "The stats below are for large attack dogs, such as Rottweilers and Doberman Pinschers, as well as wolves, hyenas, and the like.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2018-05-03T19:05:27.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": -1,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2011",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 5,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 4,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "4",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/deluxe/dog-wolf",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 4,
			"toughnessAsReadNoHeavy": "4",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 8,
		"gear": [],
		"name": "Dragon",
		"pace": "8",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "fc58cb01-88c8-47f0-a6de-03dc71781412",
		"armor": "4",
		"climb": -10,
		"edges": [],
		"flies": 0,
		"image": "https://orig00.deviantart.net/8eeb/f/2012/152/f/0/red_dragon__by_amisgaudi-d51yfws.jpg",
		"parry": "7",
		"active": true,
		"skills": [
			{
				"Name": "Fighting",
				"Value": "d10"
			},
			{
				"Name": "Intimidation",
				"Value": "d12"
			},
			{
				"Name": "Notice",
				"Value": "d12"
			}
		],
		"book_id": 2,
		"summary": "",
		"bookPage": "p136",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": 1,
		"abilities": [
			{
				"Name": "Armor+4",
				"Notes": " Scaly hide."
			},
			{
				"Name": "Claws/Bite",
				"Notes": " Str+d8."
			},
			{
				"Name": "Fear-2",
				"Notes": " Anyone who sees a mighty dragon must make a Fear check at &#x2013;2."
			},
			{
				"Name": "Fiery Breath",
				"Notes": " Dragons breathe fire using the Cone Template. Every target within this cone may make an Agility roll at &#x2013;2 to avoid the attack. Those who fail suffer 2d10 damage and must check to see if they catch fire. A dragon may not attack with its claws or bite in the round it breathes fire."
			},
			{
				"Name": "Flight",
				"Notes": " Dragons have a Flying Pace of 24&#x201d; and Climb 0."
			},
			{
				"Name": "Hardy",
				"Notes": " The creature does not suffer a wound from being Shaken twice."
			},
			{
				"Name": "Huge",
				"Notes": " Attackers add +4 to their Fighting or Shooting rolls when attacking a dragon due to its massive size."
			},
			{
				"Name": "Improved Frenzy",
				"Notes": " If a dragon does not use its Fiery Breath ability, it may make two Fighting attacks with no penalty."
			},
			{
				"Name": "Level Headed",
				"Notes": " Act on best of two cards."
			},
			{
				"Name": "Size +8",
				"Notes": " Dragons are massive creatures. This version is over 40' long from nose to tail, and weighs well over 30,000 pounds."
			},
			{
				"Name": "Tail Lash",
				"Notes": " The dragon can sweep all opponents in its rear facing in a 3&#x201d; long by 6&#x201d; wide square. This is a standard Fighting attack, and damage is equal to the dragon's Strength &#x2013;2."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "20",
		"attributes": {
			"Vigor": "d12",
			"Smarts": "d8",
			"Spirit": "d10",
			"Agility": "d8",
			"Strength": "d12+9"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "Dragons are fire-breathing monsters that bring doom and despair to the villages they ravage. Such creatures should not be fought lightly as they are more than a match for even a party of experienced adventurers. These beasts are quite intelligent as well, and use all of their advantages when confronted by would-be dragon- slayers.",
		"flying_pace": -10,
		"heavy_armor": false,
		"name_plural": "",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 8,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Dragon",
			"race": "Dragon",
			"rank": 0,
			"size": 8,
			"uuid": "fc58cb01-88c8-47f0-a6de-03dc71781412",
			"armor": [],
			"edges": [],
			"image": "https://savaged.ushttps://orig00.deviantart.net/8eeb/f/2012/152/f/0/red_dragon__by_amisgaudi-d51yfws.jpg",
			"swade": false,
			"bookID": 2,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d10",
					"bookID": 2,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Intimidation",
					"value": "d12",
					"bookID": 2,
					"isCore": false,
					"dieValue": 12,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d12",
					"bookID": 2,
					"isCore": false,
					"dieValue": 12,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 2,
			"fatigue": 0,
			"journal": [],
			"paceMod": 2,
			"parryHR": "7",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Claws/Bite",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d8",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Claws/Bite",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d8",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d8+1d12",
							"parry_modifier": 0,
							"damage_original": "str+d8",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 9,
							"damageWithBrackets": "str+d8",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d8+1d12",
					"descriptionHTML": "",
					"damageDiceBasePlus": 9,
					"damageWithBrackets": "str+d8"
				},
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Fiery Breath",
					"uuid": "",
					"notes": " Every target within this cone may make an Agility roll at &#x2013;2 to avoid the attack",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "dragons",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Fiery Breath",
							"notes": " Every target within this cone may make an Agility roll at &#x2013;2 to avoid the attack",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "dragons",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "",
							"parry_modifier": 0,
							"damage_original": "dragons",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 0,
							"damageWithBrackets": "dragons",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "",
					"descriptionHTML": "",
					"damageDiceBasePlus": 0,
					"damageWithBrackets": "dragons"
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Deluxe (EE)",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": true,
			"abilities": [
				{
					"from": "",
					"name": "Armor+4",
					"note": "Scaly hide.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p136",
					"description": "Scaly hide."
				},
				{
					"from": "",
					"name": "Claws/Bite",
					"note": "Str+d8.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p136",
					"description": "Str+d8."
				},
				{
					"from": "",
					"name": "Fear-2",
					"note": "Anyone who sees a mighty dragon must make a Fear check at &#x2013;2.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p136",
					"description": "Anyone who sees a mighty dragon must make a Fear check at &#x2013;2."
				},
				{
					"from": "",
					"name": "Fiery Breath",
					"note": "Dragons breathe fire using the Cone Template. Every target within this cone may make an Agility roll at &#x2013;2 to avoid the attack. Those who fail suffer 2d10 damage and must check to see if they catch fire. A dragon may not attack with its claws or bite in the round it breathes fire.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p136",
					"description": "Dragons breathe fire using the Cone Template. Every target within this cone may make an Agility roll at &#x2013;2 to avoid the attack. Those who fail suffer 2d10 damage and must check to see if they catch fire. A dragon may not attack with its claws or bite in the round it breathes fire."
				},
				{
					"from": "",
					"name": "Flight",
					"note": "Dragons have a Flying Pace of 24&#x201d; and Climb 0.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p136",
					"description": "Dragons have a Flying Pace of 24&#x201d; and Climb 0."
				},
				{
					"from": "",
					"name": "Hardy",
					"note": "The creature does not suffer a wound from being Shaken twice.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p136",
					"description": "The creature does not suffer a wound from being Shaken twice."
				},
				{
					"from": "",
					"name": "Huge",
					"note": "Attackers add +4 to their Fighting or Shooting rolls when attacking a dragon due to its massive size.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p136",
					"description": "Attackers add +4 to their Fighting or Shooting rolls when attacking a dragon due to its massive size."
				},
				{
					"from": "",
					"name": "Improved Frenzy",
					"note": "If a dragon does not use its Fiery Breath ability, it may make two Fighting attacks with no penalty.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p136",
					"description": "If a dragon does not use its Fiery Breath ability, it may make two Fighting attacks with no penalty."
				},
				{
					"from": "",
					"name": "Level Headed",
					"note": "Act on best of two cards.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p136",
					"description": "Act on best of two cards."
				},
				{
					"from": "",
					"name": "Size +8",
					"note": "Dragons are massive creatures. This version is over 40' long from nose to tail, and weighs well over 30,000 pounds.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p136",
					"description": "Dragons are massive creatures. This version is over 40' long from nose to tail, and weighs well over 30,000 pounds."
				},
				{
					"from": "",
					"name": "Tail Lash",
					"note": "The dragon can sweep all opponents in its rear facing in a 3&#x201d; long by 6&#x201d; wide square. This is a standard Fighting attack, and damage is equal to the dragon's Strength &#x2013;2.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p136",
					"description": "The dragon can sweep all opponents in its rear facing in a 3&#x201d; long by 6&#x201d; wide square. This is a standard Fighting attack, and damage is equal to the dragon's Strength &#x2013;2."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 8,
			"parryBase": 7,
			"sizeLabel": "8 (Huge)",
			"strainMax": 0,
			"woundsMax": 5,
			"appVersion": "3.2.3b010603",
			"armorValue": 4,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d10",
					"dieValue": 10
				},
				{
					"mod": 9,
					"name": "strength",
					"label": "Strength",
					"value": "d12+9",
					"dieValue": 12
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d12",
					"dieValue": 12
				}
			],
			"background": "",
			"benniesMax": 2,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 7,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 3,
			"bookPrimary": true,
			"createdDate": "2018-05-03T19:05:27.000Z",
			"description": "Dragons are fire-breathing monsters that bring doom and despair to the villages they ravage. Such creatures should not be fought lightly as they are more than a match for even a party of experienced adventurers. These beasts are quite intelligent as well, and use all of their advantages when confronted by would-be dragon- slayers.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2018-05-15T00:42:06.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 8,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2011",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 8,
			"unarmoredHero": false,
			"woundsSizeMod": 2,
			"naturalIsHeavy": false,
			"toughnessTotal": 20,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "20 (4)",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/deluxe/dragon",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 16,
			"toughnessAsReadNoHeavy": "20 (4)",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 529,
		"gear": [
			""
		],
		"name": "Dragon",
		"pace": "8",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "ee8c702b-dded-4ff9-0cc1-48fa9dd94ec7",
		"armor": "4",
		"climb": 0,
		"edges": [
			"Frenzy (Imp)",
			"Level Headed"
		],
		"flies": 0,
		"image": "https://orig00.deviantart.net/8eeb/f/2012/152/f/0/red_dragon__by_amisgaudi-d51yfws.jpg",
		"parry": "7",
		"active": true,
		"skills": [
			{
				"Name": "Athletics",
				"Value": "d10"
			},
			{
				"Name": "Common Knowledge",
				"Value": "d6"
			},
			{
				"Name": "Fighting",
				"Value": "d10"
			},
			{
				"Name": "Intimidation",
				"Value": "d12"
			},
			{
				"Name": "Notice",
				"Value": "d12"
			},
			{
				"Name": "Persuasion",
				"Value": "d10"
			},
			{
				"Name": "Stealth",
				"Value": "d8"
			}
		],
		"book_id": 9,
		"summary": "",
		"bookPage": "p181",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": 1,
		"abilities": [
			{
				"Name": "Armor +4",
				"Notes": "Scaly hide."
			},
			{
				"Name": "Bite/Claws",
				"Notes": "Str+d8."
			},
			{
				"Name": "Fear (−2)",
				"Notes": "Anyone who sees a mighty dragon must make a Fear check at −2."
			},
			{
				"Name": "Fiery Breath",
				"Notes": "Dragons breathe fire for 3d6 damage (see Breath Weapons, page 175)."
			},
			{
				"Name": "Flight",
				"Notes": "Dragons have a Flying Pace of 24′′."
			},
			{
				"Name": "Hardy",
				"Notes": "The creature does not suffer a Wound from being Shaken twice."
			},
			{
				"Name": "Size 8 (Huge)",
				"Notes": "Dragons are massive creatures, over 40′ long from nose to tail and weighing over 30,000 pounds."
			},
			{
				"Name": "Tail Lash",
				"Notes": "Str+d4. The creature may make a free attack against up to two foes to its side or rear at no penalty."
			},
			{
				"Name": "Swat",
				"Notes": "Dragons ignore up to 4 points of Scale penalties when attacking with their claws."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "20",
		"attributes": {
			"Vigor": "d12",
			"Smarts": "d8",
			"Spirit": "d10",
			"Agility": "d8",
			"Strength": "d12+8"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [
			""
		],
		"updated_by": 0,
		"version_of": 0,
		"description": "Dragons are fire-breathing monsters that bring doom and despair to the villages they ravage. Such creatures should not be fought lightly as they are more than a match for even a party of experienced adventurers. These beasts are quite intelligent as well, and use all of their advantages when confronted by would-be dragon-slayers.",
		"flying_pace": 0,
		"heavy_armor": false,
		"name_plural": "Dragons",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 529,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Dragon",
			"race": "Dragon",
			"rank": 0,
			"size": 8,
			"uuid": "ee8c702b-dded-4ff9-0cc1-48fa9dd94ec7",
			"armor": [],
			"edges": [
				{
					"id": 0,
					"name": "Frenzy (Imp)",
					"note": "",
					"bookID": 9,
					"isHidden": false,
					"takenFrom": "",
					"description": "",
					"descriptionHTML": "",
					"customDescription": ""
				},
				{
					"id": 221,
					"name": "Level Headed",
					"note": "",
					"bookID": 9,
					"isHidden": false,
					"takenFrom": "",
					"description": "Draw an additional Action Card each round and choose which one to use.",
					"descriptionHTML": "<p><cite>Savage Worlds: Adventure Edition p.42</cite></p>",
					"customDescription": ""
				}
			],
			"image": "https://savaged.ushttps://orig00.deviantart.net/8eeb/f/2012/152/f/0/red_dragon__by_amisgaudi-d51yfws.jpg",
			"swade": false,
			"bookID": 9,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Athletics",
					"value": "d10",
					"bookID": 9,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Common Knowledge",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d10",
					"bookID": 9,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Intimidation",
					"value": "d12",
					"bookID": 9,
					"isCore": false,
					"dieValue": 12,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d12",
					"bookID": 9,
					"isCore": false,
					"dieValue": 12,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Persuasion",
					"value": "d10",
					"bookID": 9,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Stealth",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 2,
			"fatigue": 0,
			"journal": [],
			"paceMod": 2,
			"parryHR": "7",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Bite/Claws",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d8",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Bite/Claws",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d8",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d8+1d12",
							"parry_modifier": 0,
							"damage_original": "str+d8",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 8,
							"damageWithBrackets": "str+d8",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d8+1d12",
					"descriptionHTML": "",
					"damageDiceBasePlus": 8,
					"damageWithBrackets": "str+d8"
				},
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Fiery Breath",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "dragons",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Fiery Breath",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "dragons",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "",
							"parry_modifier": 0,
							"damage_original": "dragons",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 0,
							"damageWithBrackets": "dragons",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "",
					"descriptionHTML": "",
					"damageDiceBasePlus": 0,
					"damageWithBrackets": "dragons"
				},
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Tail Lash",
					"uuid": "",
					"notes": " The creature may make a free attack against up to two foes to its side or rear at no penalty",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d4",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Tail Lash",
							"notes": " The creature may make a free attack against up to two foes to its side or rear at no penalty",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d4",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d4+1d12",
							"parry_modifier": 0,
							"damage_original": "str+d4",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 8,
							"damageWithBrackets": "str+d4",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d4+1d12",
					"descriptionHTML": "",
					"damageDiceBasePlus": 8,
					"damageWithBrackets": "str+d4"
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Adventure Edition",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": true,
			"abilities": [
				{
					"from": "",
					"name": "Armor +4",
					"note": "Scaly hide.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p181",
					"description": "Scaly hide."
				},
				{
					"from": "",
					"name": "Bite/Claws",
					"note": "Str+d8.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p181",
					"description": "Str+d8."
				},
				{
					"from": "",
					"name": "Fear (-2)",
					"note": "Anyone who sees a mighty dragon must make a Fear check at -2.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p181",
					"description": "Anyone who sees a mighty dragon must make a Fear check at -2."
				},
				{
					"from": "",
					"name": "Fiery Breath",
					"note": "Dragons breathe fire for 3d6 damage (see Breath Weapons, page 175).",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p181",
					"description": "Dragons breathe fire for 3d6 damage (see Breath Weapons, page 175)."
				},
				{
					"from": "",
					"name": "Flight",
					"note": "Dragons have a Flying Pace of 24′′.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p181",
					"description": "Dragons have a Flying Pace of 24′′."
				},
				{
					"from": "",
					"name": "Hardy",
					"note": "The creature does not suffer a Wound from being Shaken twice.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p181",
					"description": "The creature does not suffer a Wound from being Shaken twice."
				},
				{
					"from": "",
					"name": "Size 8 (Huge)",
					"note": "Dragons are massive creatures, over 40′ long from nose to tail and weighing over 30,000 pounds.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p181",
					"description": "Dragons are massive creatures, over 40′ long from nose to tail and weighing over 30,000 pounds."
				},
				{
					"from": "",
					"name": "Tail Lash",
					"note": "Str+d4. The creature may make a free attack against up to two foes to its side or rear at no penalty.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p181",
					"description": "Str+d4. The creature may make a free attack against up to two foes to its side or rear at no penalty."
				},
				{
					"from": "",
					"name": "Swat",
					"note": "Dragons ignore up to 4 points of Scale penalties when attacking with their claws.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p181",
					"description": "Dragons ignore up to 4 points of Scale penalties when attacking with their claws."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 8,
			"parryBase": 7,
			"sizeLabel": "8 (Huge)",
			"strainMax": 0,
			"woundsMax": 5,
			"appVersion": "3.2.3b010603",
			"armorValue": 4,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d10",
					"dieValue": 10
				},
				{
					"mod": 8,
					"name": "strength",
					"label": "Strength",
					"value": "d12+8",
					"dieValue": 12
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d12",
					"dieValue": 12
				}
			],
			"background": "",
			"benniesMax": 2,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 7,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 3,
			"bookPrimary": true,
			"createdDate": "2019-01-19T01:50:07.000Z",
			"description": "Dragons are fire-breathing monsters that bring doom and despair to the villages they ravage. Such creatures should not be fought lightly as they are more than a match for even a party of experienced adventurers. These beasts are quite intelligent as well, and use all of their advantages when confronted by would-be dragon-slayers.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2019-01-21T04:18:18.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 8,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2018",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 8,
			"unarmoredHero": false,
			"woundsSizeMod": 2,
			"naturalIsHeavy": false,
			"toughnessTotal": 20,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "20 (4)",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/swade/dragon",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 16,
			"toughnessAsReadNoHeavy": "20 (4)",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 9,
		"gear": [],
		"name": "Drake",
		"pace": "6",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "7f94f560-3233-4e0e-3a14-0df06c946ff3",
		"armor": "4",
		"climb": -10,
		"edges": [],
		"flies": 0,
		"image": "http://img2.wikia.nocookie.net/__cb20080714125534/finalfantasy/images/5/52/Greater_Drake_ffx-2.jpg",
		"parry": "7",
		"active": true,
		"skills": [
			{
				"Name": "Fighting",
				"Value": "d10"
			},
			{
				"Name": "Intimidation",
				"Value": "d12"
			},
			{
				"Name": "Notice",
				"Value": "d8"
			}
		],
		"book_id": 2,
		"summary": "",
		"bookPage": "p136",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": 1,
		"abilities": [
			{
				"Name": "Armor+4",
				"Notes": " Scaly hide."
			},
			{
				"Name": "Claws/Bite",
				"Notes": " Str+d8."
			},
			{
				"Name": "Fear",
				"Notes": " Drakes are frightening creatures to behold."
			},
			{
				"Name": "Fiery Breath",
				"Notes": "Drakes breathe fire using the Cone Template. Every target within this cone may make an Agility roll at &#x2013;2 to avoid the attack. Those who fail suffer 2d10 damage and must check to see if they catch fire (see Fire). A drake may not attack with its claws or bite in the round it breathes fire."
			},
			{
				"Name": "Large",
				"Notes": "Attackers add +2 to their attack rolls when attacking a drake due to its large size."
			},
			{
				"Name": "Size +5",
				"Notes": "Drakes are over 20' long from snout to tail, and weigh in at over 3000 pounds."
			},
			{
				"Name": "Tail Lash",
				"Notes": "A drake can sweep all opponents in its rear facing in a 3&#x201d; long by 6&#x201d; wide rectangle. This is a standard Fighting attack, and damage is equal to the creature's Strength &#x2013;2."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "17",
		"attributes": {
			"Vigor": "d12",
			"Smarts": "d6 (A)",
			"Spirit": "d10",
			"Agility": "d6",
			"Strength": "d12+6"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "Drakes are non-flying dragons with animal intelligence (rather than the more human-like sentience of true dragons). They are much more aggressive in direct combat than their distant cousins, however.",
		"flying_pace": -10,
		"heavy_armor": false,
		"name_plural": "",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 9,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Drake",
			"race": "Drake",
			"rank": 0,
			"size": 5,
			"uuid": "7f94f560-3233-4e0e-3a14-0df06c946ff3",
			"armor": [],
			"edges": [],
			"image": "https://savaged.ushttp://img2.wikia.nocookie.net/__cb20080714125534/finalfantasy/images/5/52/Greater_Drake_ffx-2.jpg",
			"swade": false,
			"bookID": 2,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d10",
					"bookID": 2,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Intimidation",
					"value": "d12",
					"bookID": 2,
					"isCore": false,
					"dieValue": 12,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d8",
					"bookID": 2,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 2,
			"fatigue": 0,
			"journal": [],
			"paceMod": 0,
			"parryHR": "7",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Claws/Bite",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d8",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Claws/Bite",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d8",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d8+1d12",
							"parry_modifier": 0,
							"damage_original": "str+d8",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 6,
							"damageWithBrackets": "str+d8",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d8+1d12",
					"descriptionHTML": "",
					"damageDiceBasePlus": 6,
					"damageWithBrackets": "str+d8"
				},
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Fiery Breath",
					"uuid": "",
					"notes": " Every target within this cone may make an Agility roll at &#x2013;2 to avoid the attack",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "drakes",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Fiery Breath",
							"notes": " Every target within this cone may make an Agility roll at &#x2013;2 to avoid the attack",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "drakes",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "",
							"parry_modifier": 0,
							"damage_original": "drakes",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 0,
							"damageWithBrackets": "drakes",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "",
					"descriptionHTML": "",
					"damageDiceBasePlus": 0,
					"damageWithBrackets": "drakes"
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Deluxe (EE)",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": true,
			"abilities": [
				{
					"from": "",
					"name": "Armor+4",
					"note": "Scaly hide.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p136",
					"description": "Scaly hide."
				},
				{
					"from": "",
					"name": "Claws/Bite",
					"note": "Str+d8.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p136",
					"description": "Str+d8."
				},
				{
					"from": "",
					"name": "Fear",
					"note": "Drakes are frightening creatures to behold.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p136",
					"description": "Drakes are frightening creatures to behold."
				},
				{
					"from": "",
					"name": "Fiery Breath",
					"note": "Drakes breathe fire using the Cone Template. Every target within this cone may make an Agility roll at &#x2013;2 to avoid the attack. Those who fail suffer 2d10 damage and must check to see if they catch fire (see Fire). A drake may not attack with its claws or bite in the round it breathes fire.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p136",
					"description": "Drakes breathe fire using the Cone Template. Every target within this cone may make an Agility roll at &#x2013;2 to avoid the attack. Those who fail suffer 2d10 damage and must check to see if they catch fire (see Fire). A drake may not attack with its claws or bite in the round it breathes fire."
				},
				{
					"from": "",
					"name": "Large",
					"note": "Attackers add +2 to their attack rolls when attacking a drake due to its large size.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p136",
					"description": "Attackers add +2 to their attack rolls when attacking a drake due to its large size."
				},
				{
					"from": "",
					"name": "Size +5",
					"note": "Drakes are over 20' long from snout to tail, and weigh in at over 3000 pounds.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p136",
					"description": "Drakes are over 20' long from snout to tail, and weigh in at over 3000 pounds."
				},
				{
					"from": "",
					"name": "Tail Lash",
					"note": "A drake can sweep all opponents in its rear facing in a 3&#x201d; long by 6&#x201d; wide rectangle. This is a standard Fighting attack, and damage is equal to the creature's Strength &#x2013;2.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p136",
					"description": "A drake can sweep all opponents in its rear facing in a 3&#x201d; long by 6&#x201d; wide rectangle. This is a standard Fighting attack, and damage is equal to the creature's Strength &#x2013;2."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 6,
			"parryBase": 7,
			"sizeLabel": "5 (Large)",
			"strainMax": 0,
			"woundsMax": 4,
			"appVersion": "3.2.3b010603",
			"armorValue": 4,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d6 (A)",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d10",
					"dieValue": 10
				},
				{
					"mod": 6,
					"name": "strength",
					"label": "Strength",
					"value": "d12+6",
					"dieValue": 12
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d12",
					"dieValue": 12
				}
			],
			"background": "",
			"benniesMax": 2,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 7,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 3,
			"bookPrimary": true,
			"createdDate": "2018-05-03T19:05:27.000Z",
			"description": "Drakes are non-flying dragons with animal intelligence (rather than the more human-like sentience of true dragons). They are much more aggressive in direct combat than their distant cousins, however.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2019-05-09T01:47:42.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 5,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2011",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 8,
			"unarmoredHero": false,
			"woundsSizeMod": 1,
			"naturalIsHeavy": false,
			"toughnessTotal": 17,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "17 (4)",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/deluxe/drake",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 13,
			"toughnessAsReadNoHeavy": "17 (4)",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 530,
		"gear": [],
		"name": "Drake",
		"pace": "6",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "ea680748-bfb0-4f51-a810-cf0dc5daeaf9",
		"armor": "4",
		"climb": 0,
		"edges": [
			"-"
		],
		"flies": 0,
		"image": "http://img2.wikia.nocookie.net/__cb20080714125534/finalfantasy/images/5/52/Greater_Drake_ffx-2.jpg",
		"parry": "7",
		"active": true,
		"skills": [
			{
				"Name": "Athletics",
				"Value": "d8"
			},
			{
				"Name": "Fighting",
				"Value": "d10"
			},
			{
				"Name": "Intimidation",
				"Value": "d12"
			},
			{
				"Name": "Notice",
				"Value": "d8"
			},
			{
				"Name": "Stealth",
				"Value": "d6"
			}
		],
		"book_id": 9,
		"summary": "",
		"bookPage": "p181",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Armor +4",
				"Notes": "Scaly hide."
			},
			{
				"Name": "Bite/Claws",
				"Notes": "Str+d8."
			},
			{
				"Name": "Fear",
				"Notes": "Drakes are frightening creatures to behold."
			},
			{
				"Name": "Fiery Breath",
				"Notes": "Drakes breathe fire for 3d6 damage (see Breath Weapons, page 175)."
			},
			{
				"Name": "Resilient",
				"Notes": "Drakes can take one Wound before they're Incapacitated."
			},
			{
				"Name": "Size 6 (Large)",
				"Notes": "Drakes are over 20′ long from snout to tail."
			},
			{
				"Name": "Tail Lash",
				"Notes": "Str. The creature may make a free attack against up to two foes to its side or rear at no penalty."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "18",
		"attributes": {
			"Vigor": "d12",
			"Smarts": "d6 (A)",
			"Spirit": "d10",
			"Agility": "d6",
			"Strength": "d12+6"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "Drakes are non-flying dragons with animal intelligence (rather than the more human-like sentience of true dragons). They are much more aggressive in direct combat than their distant cousins, however.",
		"flying_pace": 0,
		"heavy_armor": false,
		"name_plural": "Drakes",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 530,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Drake",
			"race": "Drake",
			"rank": 0,
			"size": 6,
			"uuid": "ea680748-bfb0-4f51-a810-cf0dc5daeaf9",
			"armor": [],
			"edges": [
				{
					"id": 0,
					"name": "-",
					"note": "",
					"bookID": 9,
					"isHidden": false,
					"takenFrom": "",
					"description": "",
					"descriptionHTML": "",
					"customDescription": ""
				}
			],
			"image": "https://savaged.ushttp://img2.wikia.nocookie.net/__cb20080714125534/finalfantasy/images/5/52/Greater_Drake_ffx-2.jpg",
			"swade": false,
			"bookID": 9,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Athletics",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d10",
					"bookID": 9,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Intimidation",
					"value": "d12",
					"bookID": 9,
					"isCore": false,
					"dieValue": 12,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Stealth",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": 0,
			"parryHR": "7",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Bite/Claws",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d8",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Bite/Claws",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d8",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d8+1d12",
							"parry_modifier": 0,
							"damage_original": "str+d8",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 6,
							"damageWithBrackets": "str+d8",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d8+1d12",
					"descriptionHTML": "",
					"damageDiceBasePlus": 6,
					"damageWithBrackets": "str+d8"
				},
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Fiery Breath",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "drakes",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Fiery Breath",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "drakes",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "",
							"parry_modifier": 0,
							"damage_original": "drakes",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 0,
							"damageWithBrackets": "drakes",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "",
					"descriptionHTML": "",
					"damageDiceBasePlus": 0,
					"damageWithBrackets": "drakes"
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Adventure Edition",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Armor +4",
					"note": "Scaly hide.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p181",
					"description": "Scaly hide."
				},
				{
					"from": "",
					"name": "Bite/Claws",
					"note": "Str+d8.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p181",
					"description": "Str+d8."
				},
				{
					"from": "",
					"name": "Fear",
					"note": "Drakes are frightening creatures to behold.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p181",
					"description": "Drakes are frightening creatures to behold."
				},
				{
					"from": "",
					"name": "Fiery Breath",
					"note": "Drakes breathe fire for 3d6 damage (see Breath Weapons, page 175).",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p181",
					"description": "Drakes breathe fire for 3d6 damage (see Breath Weapons, page 175)."
				},
				{
					"from": "",
					"name": "Resilient",
					"note": "Drakes can take one Wound before they're Incapacitated.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p181",
					"description": "Drakes can take one Wound before they're Incapacitated."
				},
				{
					"from": "",
					"name": "Size 6 (Large)",
					"note": "Drakes are over 20′ long from snout to tail.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p181",
					"description": "Drakes are over 20′ long from snout to tail."
				},
				{
					"from": "",
					"name": "Tail Lash",
					"note": "Str. The creature may make a free attack against up to two foes to its side or rear at no penalty.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p181",
					"description": "Str. The creature may make a free attack against up to two foes to its side or rear at no penalty."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 6,
			"parryBase": 7,
			"sizeLabel": "6 (Large)",
			"strainMax": 0,
			"woundsMax": 1,
			"appVersion": "3.2.3b010603",
			"armorValue": 4,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d6 (A)",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d10",
					"dieValue": 10
				},
				{
					"mod": 6,
					"name": "strength",
					"label": "Strength",
					"value": "d12+6",
					"dieValue": 12
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d12",
					"dieValue": 12
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 7,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2019-01-19T01:52:06.000Z",
			"description": "Drakes are non-flying dragons with animal intelligence (rather than the more human-like sentience of true dragons). They are much more aggressive in direct combat than their distant cousins, however.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2020-05-13T00:10:22.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 6,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2018",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 8,
			"unarmoredHero": false,
			"woundsSizeMod": 1,
			"naturalIsHeavy": false,
			"toughnessTotal": 18,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "18 (4)",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/swade/drake",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 14,
			"toughnessAsReadNoHeavy": "18 (4)",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 10,
		"gear": [],
		"name": "Earth Elemental",
		"pace": "4",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "ce9eaa60-8d69-4d10-34e9-93554bef0512",
		"armor": "4",
		"climb": -10,
		"edges": [],
		"flies": 0,
		"image": "http://www.elfwood.com/art/o/m/omarmorsy/earth_elemental.jpg",
		"parry": "6",
		"active": true,
		"skills": [
			{
				"Name": "Fighting",
				"Value": "d8"
			},
			{
				"Name": "Notice",
				"Value": "d4"
			}
		],
		"book_id": 2,
		"summary": "",
		"bookPage": "p136",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Armor +4",
				"Notes": " Rocky hide."
			},
			{
				"Name": "Bash",
				"Notes": " Str+d6"
			},
			{
				"Name": "Elemental",
				"Notes": " No additional damage from called shots;Fearless; Immune to disease and poison."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "11",
		"attributes": {
			"Vigor": "d10",
			"Smarts": "d4",
			"Spirit": "d6",
			"Agility": "d6",
			"Strength": "d12+3"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "Earth elementals manifest as five-foot tall, vaguely man-shaped collections of earth and stone. Though amazingly strong, they are also quite slow and ponderous.",
		"flying_pace": -10,
		"heavy_armor": false,
		"name_plural": "",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 10,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Earth Elemental",
			"race": "Earth Elemental",
			"rank": 0,
			"size": 0,
			"uuid": "ce9eaa60-8d69-4d10-34e9-93554bef0512",
			"armor": [],
			"edges": [],
			"image": "https://savaged.ushttp://www.elfwood.com/art/o/m/omarmorsy/earth_elemental.jpg",
			"swade": false,
			"bookID": 2,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d8",
					"bookID": 2,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d4",
					"bookID": 2,
					"isCore": false,
					"dieValue": 4,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": -2,
			"parryHR": "6",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Bash",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d6",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Bash",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d6",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d6+1d12",
							"parry_modifier": 0,
							"damage_original": "str+d6",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 3,
							"damageWithBrackets": "str+d6",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d6+1d12",
					"descriptionHTML": "",
					"damageDiceBasePlus": 3,
					"damageWithBrackets": "str+d6"
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Deluxe (EE)",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Armor +4",
					"note": "Rocky hide.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p136",
					"description": "Rocky hide."
				},
				{
					"from": "",
					"name": "Bash",
					"note": "Str+d6",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p136",
					"description": "Str+d6"
				},
				{
					"from": "",
					"name": "Elemental",
					"note": "No additional damage from called shots;Fearless; Immune to disease and poison.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p136",
					"description": "No additional damage from called shots;Fearless; Immune to disease and poison."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 4,
			"parryBase": 6,
			"sizeLabel": "0 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 4,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d4",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 3,
					"name": "strength",
					"label": "Strength",
					"value": "d12+3",
					"dieValue": 12
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d10",
					"dieValue": 10
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 6,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2018-05-03T19:05:27.000Z",
			"description": "Earth elementals manifest as five-foot tall, vaguely man-shaped collections of earth and stone. Though amazingly strong, they are also quite slow and ponderous.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2018-05-03T19:05:27.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 0,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2011",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 7,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 11,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "11 (4)",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/deluxe/earth-elemental",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 7,
			"toughnessAsReadNoHeavy": "11 (4)",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 639,
		"gear": [],
		"name": "Earth Elemental",
		"pace": "4",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "25da1fdb-768f-486a-bb64-819eb644d2d2",
		"armor": "4",
		"climb": 0,
		"edges": [
			"-"
		],
		"flies": 0,
		"image": "http://www.elfwood.com/art/o/m/omarmorsy/earth_elemental.jpg",
		"parry": "6",
		"active": true,
		"skills": [
			{
				"Name": "Athletics",
				"Value": "d6"
			},
			{
				"Name": "Fighting",
				"Value": "d8"
			},
			{
				"Name": "Notice",
				"Value": "d4"
			},
			{
				"Name": "Stealth",
				"Value": "d6"
			}
		],
		"book_id": 9,
		"summary": "",
		"bookPage": "p183",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Armor +4",
				"Notes": "Rocky hide."
			},
			{
				"Name": "Bash",
				"Notes": "Str+d6."
			},
			{
				"Name": "Burrow (10″)",
				"Notes": "Earth elementals can meld into and out of the ground."
			},
			{
				"Name": "Elemental",
				"Notes": "No additional damage from Called Shots, ignores 1 point of Wound penalties, doesn't breathe, immune to disease and poison."
			},
			{
				"Name": "Immunity",
				"Notes": "Immune to earth-based attacks (including thrown stones or powers with earth, mud, stone, or sand Trappings)."
			},
			{
				"Name": "Resilient",
				"Notes": "Earth elementals an take one Wound before they're Incapacitated."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "11",
		"attributes": {
			"Vigor": "d10",
			"Smarts": "d4",
			"Spirit": "d6",
			"Agility": "d6",
			"Strength": "d12"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "Earth elementals manifest as five-foot-tall, vaguely man-shaped collections of earth and stone. Though strong, they are also quite slow and ponderous.",
		"flying_pace": 0,
		"heavy_armor": false,
		"name_plural": "Earth Elementals",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 639,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Earth Elemental",
			"race": "Earth Elemental",
			"rank": 0,
			"size": 0,
			"uuid": "25da1fdb-768f-486a-bb64-819eb644d2d2",
			"armor": [],
			"edges": [
				{
					"id": 0,
					"name": "-",
					"note": "",
					"bookID": 9,
					"isHidden": false,
					"takenFrom": "",
					"description": "",
					"descriptionHTML": "",
					"customDescription": ""
				}
			],
			"image": "https://savaged.ushttp://www.elfwood.com/art/o/m/omarmorsy/earth_elemental.jpg",
			"swade": false,
			"bookID": 9,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Athletics",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d4",
					"bookID": 9,
					"isCore": false,
					"dieValue": 4,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Stealth",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": -2,
			"parryHR": "6",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Bash",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d6",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Bash",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d6",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d6+1d12",
							"parry_modifier": 0,
							"damage_original": "str+d6",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 0,
							"damageWithBrackets": "str+d6",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d6+1d12",
					"descriptionHTML": "",
					"damageDiceBasePlus": 0,
					"damageWithBrackets": "str+d6"
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Adventure Edition",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Armor +4",
					"note": "Rocky hide.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p183",
					"description": "Rocky hide."
				},
				{
					"from": "",
					"name": "Bash",
					"note": "Str+d6.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p183",
					"description": "Str+d6."
				},
				{
					"from": "",
					"name": "Burrow (10″)",
					"note": "Earth elementals can meld into and out of the ground.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p183",
					"description": "Earth elementals can meld into and out of the ground."
				},
				{
					"from": "",
					"name": "Elemental",
					"note": "No additional damage from Called Shots, ignores 1 point of Wound penalties, doesn't breathe, immune to disease and poison.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p183",
					"description": "No additional damage from Called Shots, ignores 1 point of Wound penalties, doesn't breathe, immune to disease and poison."
				},
				{
					"from": "",
					"name": "Immunity",
					"note": "Immune to earth-based attacks (including thrown stones or powers with earth, mud, stone, or sand Trappings).",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p183",
					"description": "Immune to earth-based attacks (including thrown stones or powers with earth, mud, stone, or sand Trappings)."
				},
				{
					"from": "",
					"name": "Resilient",
					"note": "Earth elementals an take one Wound before they're Incapacitated.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p183",
					"description": "Earth elementals an take one Wound before they're Incapacitated."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 4,
			"parryBase": 6,
			"sizeLabel": "0 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 4,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d4",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "strength",
					"label": "Strength",
					"value": "d12",
					"dieValue": 12
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d10",
					"dieValue": 10
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 6,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2019-01-23T02:00:26.000Z",
			"description": "Earth elementals manifest as five-foot-tall, vaguely man-shaped collections of earth and stone. Though strong, they are also quite slow and ponderous.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2019-01-23T02:03:11.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 0,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2018",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 7,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 11,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "11 (4)",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/swade/earth-elemental",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 7,
			"toughnessAsReadNoHeavy": "11 (4)",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 11,
		"gear": [],
		"name": "Fire Elemental",
		"pace": "6",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "14394f2a-0e3f-4bf9-b01e-a720cc98d861",
		"armor": "0",
		"climb": -10,
		"edges": [],
		"flies": 0,
		"image": "http://th08.deviantart.net/fs70/PRE/f/2010/036/7/d/Fire_elemental_by_javi_ure.jpg",
		"parry": "7",
		"active": true,
		"skills": [
			{
				"Name": "Climbing",
				"Value": "d8"
			},
			{
				"Name": "Fighting",
				"Value": "d10"
			},
			{
				"Name": "Notice",
				"Value": "d6"
			},
			{
				"Name": "Shooting",
				"Value": "d8"
			}
		],
		"book_id": 2,
		"summary": "",
		"bookPage": "p136",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Elemental",
				"Notes": " No additional damage from called shots;Fearless; Immune to disease and poison."
			},
			{
				"Name": "Invulnerability",
				"Notes": " Fire Elementals are immune to all non-magical attacks, but suffer 1d6 damage when doused in at least a gallon of water, +2 per additional gallon."
			},
			{
				"Name": "Fiery Touch",
				"Notes": " Str+d6; chance of catching fire."
			},
			{
				"Name": "Flame Strike",
				"Notes": " Fire elementals can project a searing blast of flame using the Cone Template. Characters within the cone must beat the spirit's Shooting roll with Agility or suffer 2d10 damage, plus the chance of catching fire."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "5",
		"attributes": {
			"Vigor": "d6",
			"Smarts": "d8",
			"Spirit": "d8",
			"Agility": "d12+1",
			"Strength": "d4"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "Fire elementals appear as man-shaped flame.",
		"flying_pace": -10,
		"heavy_armor": false,
		"name_plural": "",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 11,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Fire Elemental",
			"race": "Fire Elemental",
			"rank": 0,
			"size": 0,
			"uuid": "14394f2a-0e3f-4bf9-b01e-a720cc98d861",
			"armor": [],
			"edges": [],
			"image": "https://savaged.ushttp://th08.deviantart.net/fs70/PRE/f/2010/036/7/d/Fire_elemental_by_javi_ure.jpg",
			"swade": false,
			"bookID": 2,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Climbing",
					"value": "d8",
					"bookID": 2,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d10",
					"bookID": 2,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d6",
					"bookID": 2,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Shooting",
					"value": "d8",
					"bookID": 2,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": 0,
			"parryHR": "7",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Fiery Touch",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d6;",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Fiery Touch",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d6;",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d4",
							"parry_modifier": 0,
							"damage_original": "str+d6;",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 0,
							"damageWithBrackets": "str+d6;",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d4",
					"descriptionHTML": "",
					"damageDiceBasePlus": 0,
					"damageWithBrackets": "str+d6;"
				},
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Flame Strike",
					"uuid": "",
					"notes": " Characters within the cone must beat the spirit's Shooting roll with Agility or suffer 2d10 damage, plus the chance of catching fire",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "fire",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Flame Strike",
							"notes": " Characters within the cone must beat the spirit's Shooting roll with Agility or suffer 2d10 damage, plus the chance of catching fire",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "fire",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "",
							"parry_modifier": 0,
							"damage_original": "fire",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": null,
							"damageWithBrackets": "fire",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "",
					"descriptionHTML": "",
					"damageDiceBasePlus": null,
					"damageWithBrackets": "fire"
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Deluxe (EE)",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Elemental",
					"note": "No additional damage from called shots;Fearless; Immune to disease and poison.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p136",
					"description": "No additional damage from called shots;Fearless; Immune to disease and poison."
				},
				{
					"from": "",
					"name": "Invulnerability",
					"note": "Fire Elementals are immune to all non-magical attacks, but suffer 1d6 damage when doused in at least a gallon of water, +2 per additional gallon.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p136",
					"description": "Fire Elementals are immune to all non-magical attacks, but suffer 1d6 damage when doused in at least a gallon of water, +2 per additional gallon."
				},
				{
					"from": "",
					"name": "Fiery Touch",
					"note": "Str+d6; chance of catching fire.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p136",
					"description": "Str+d6; chance of catching fire."
				},
				{
					"from": "",
					"name": "Flame Strike",
					"note": "Fire elementals can project a searing blast of flame using the Cone Template. Characters within the cone must beat the spirit's Shooting roll with Agility or suffer 2d10 damage, plus the chance of catching fire.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p136",
					"description": "Fire elementals can project a searing blast of flame using the Cone Template. Characters within the cone must beat the spirit's Shooting roll with Agility or suffer 2d10 damage, plus the chance of catching fire."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 6,
			"parryBase": 7,
			"sizeLabel": "0 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 0,
			"attributes": [
				{
					"mod": 1,
					"name": "agility",
					"label": "Agility",
					"value": "d12+1",
					"dieValue": 12
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "strength",
					"label": "Strength",
					"value": "d4",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d6",
					"dieValue": 6
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 7,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2018-05-03T19:05:27.000Z",
			"description": "Fire elementals appear as man-shaped flame.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2018-05-03T19:05:27.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 0,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2011",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 5,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 5,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "5",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/deluxe/fire-elemental",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 5,
			"toughnessAsReadNoHeavy": "5",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 643,
		"gear": [],
		"name": "Fire Elemental",
		"pace": "6",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "0d9c8e9c-26b6-11ec-9078-bbf2fd7bd78a",
		"armor": "0",
		"climb": 0,
		"edges": [
			"-"
		],
		"flies": 0,
		"image": "http://th08.deviantart.net/fs70/PRE/f/2010/036/7/d/Fire_elemental_by_javi_ure.jpg",
		"parry": "7",
		"active": true,
		"skills": [
			{
				"Name": "Athletics",
				"Value": " d8"
			},
			{
				"Name": "Fighting",
				"Value": " d10"
			},
			{
				"Name": "Notice",
				"Value": " d6"
			},
			{
				"Name": "Shooting",
				"Value": " d8"
			},
			{
				"Name": "Stealth",
				"Value": " d6"
			}
		],
		"book_id": 9,
		"summary": "",
		"bookPage": "p183",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Elemental",
				"Notes": " No additional damage from Called Shots, ignores 1 point of Wound penalties, doesn't breathe, immune to disease and poison."
			},
			{
				"Name": "Fiery Touch",
				"Notes": " Str+d6, chance of catching Fire (see page 127)."
			},
			{
				"Name": "Flame Strike",
				"Notes": " 3d6 Range Cone Can project a searing blast of flame using the Cone Template and their Shooting skill. Characters within take 3d6 and may catch Fire (see page 127). May be Evaded."
			},
			{
				"Name": "Immunity",
				"Notes": " Fire elementals are immune to fire and heat-based attacks."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "5",
		"attributes": {
			"Vigor": "d6",
			"Smarts": "d8",
			"Spirit": "d8",
			"Agility": "d12+1",
			"Strength": "d4"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "Fire elementals appear as man-shaped flame.",
		"flying_pace": 0,
		"heavy_armor": false,
		"name_plural": "Fire Elementals",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 643,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Fire Elemental",
			"race": "Fire Elemental",
			"rank": 0,
			"size": 0,
			"uuid": "0d9c8e9c-26b6-11ec-9078-bbf2fd7bd78a",
			"armor": [],
			"edges": [
				{
					"id": 0,
					"name": "-",
					"note": "",
					"bookID": 9,
					"isHidden": false,
					"takenFrom": "",
					"description": "",
					"descriptionHTML": "",
					"customDescription": ""
				}
			],
			"image": "https://savaged.ushttp://th08.deviantart.net/fs70/PRE/f/2010/036/7/d/Fire_elemental_by_javi_ure.jpg",
			"swade": false,
			"bookID": 9,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Athletics",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d10",
					"bookID": 9,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Shooting",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Stealth",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": 0,
			"parryHR": "7",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Fiery Touch",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d6",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Fiery Touch",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d6",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d4+1d6",
							"parry_modifier": 0,
							"damage_original": "str+d6",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 0,
							"damageWithBrackets": "str+d6",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d4+1d6",
					"descriptionHTML": "",
					"damageDiceBasePlus": 0,
					"damageWithBrackets": "str+d6"
				},
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Flame Strike",
					"uuid": "",
					"notes": " Characters within take 3d6 and may catch Fire (see page 127)",
					"range": "cone",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "3d6",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Flame Strike",
							"notes": " Characters within take 3d6 and may catch Fire (see page 127)",
							"range": "cone",
							"reach": 0,
							"shots": 0,
							"damage": "3d6",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "3d6",
							"parry_modifier": 0,
							"damage_original": "3d6",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 0,
							"damageWithBrackets": "3d6",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "3d6",
					"descriptionHTML": "",
					"damageDiceBasePlus": 0,
					"damageWithBrackets": "3d6"
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Adventure Edition",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Elemental",
					"note": "No additional damage from Called Shots, ignores 1 point of Wound penalties, doesn't breathe, immune to disease and poison.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p183",
					"description": "No additional damage from Called Shots, ignores 1 point of Wound penalties, doesn't breathe, immune to disease and poison."
				},
				{
					"from": "",
					"name": "Fiery Touch",
					"note": "Str+d6, chance of catching Fire (see page 127).",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p183",
					"description": "Str+d6, chance of catching Fire (see page 127)."
				},
				{
					"from": "",
					"name": "Flame Strike",
					"note": "3d6 Range Cone Can project a searing blast of flame using the Cone Template and their Shooting skill. Characters within take 3d6 and may catch Fire (see page 127). May be Evaded.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p183",
					"description": "3d6 Range Cone Can project a searing blast of flame using the Cone Template and their Shooting skill. Characters within take 3d6 and may catch Fire (see page 127). May be Evaded."
				},
				{
					"from": "",
					"name": "Immunity",
					"note": "Fire elementals are immune to fire and heat-based attacks.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p183",
					"description": "Fire elementals are immune to fire and heat-based attacks."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 6,
			"parryBase": 7,
			"sizeLabel": "0 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 0,
			"attributes": [
				{
					"mod": 1,
					"name": "agility",
					"label": "Agility",
					"value": "d12+1",
					"dieValue": 12
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "strength",
					"label": "Strength",
					"value": "d4",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d6",
					"dieValue": 6
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 7,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2019-01-23T02:04:48.000Z",
			"description": "Fire elementals appear as man-shaped flame.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2021-10-06T15:00:31.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 0,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2018",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 5,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 5,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "5",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/swade/fire-elemental",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 5,
			"toughnessAsReadNoHeavy": "5",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 12,
		"gear": [],
		"name": "Ghost",
		"pace": "0",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "f8cbe0e5-0ed4-446d-1650-45c07407a231",
		"armor": "0",
		"climb": -10,
		"edges": [],
		"flies": 0,
		"image": "",
		"parry": "5",
		"active": true,
		"skills": [
			{
				"Name": "Fighting",
				"Value": "d8"
			},
			{
				"Name": "Intimidation",
				"Value": "d12+2"
			},
			{
				"Name": "Notice",
				"Value": "d12"
			},
			{
				"Name": "Taunt",
				"Value": "d10"
			},
			{
				"Name": "Stealth",
				"Value": "d12+4"
			},
			{
				"Name": "Throwing",
				"Value": "d12"
			}
		],
		"book_id": 2,
		"summary": "",
		"bookPage": "p137",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Ethereal",
				"Notes": " Ghosts are immaterial and can only be harmed by magical attacks."
			},
			{
				"Name": "Fear -2",
				"Notes": " Ghosts cause Fear checks at &#x2013;2 when they let themselves be seen."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "5",
		"attributes": {
			"Vigor": "d6",
			"Smarts": "d6",
			"Spirit": "d10",
			"Agility": "d6",
			"Strength": "d6"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "Spectres, shades, and phantoms sometimes return from death to haunt the living or fulfill some lost goal.",
		"flying_pace": -10,
		"heavy_armor": false,
		"name_plural": "",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 12,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Ghost",
			"race": "Ghost",
			"rank": 0,
			"size": 0,
			"uuid": "f8cbe0e5-0ed4-446d-1650-45c07407a231",
			"armor": [],
			"edges": [],
			"image": "",
			"swade": false,
			"bookID": 2,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d8",
					"bookID": 2,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 2,
					"name": "Intimidation",
					"value": "d12",
					"bookID": 2,
					"isCore": false,
					"dieValue": 12,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d12",
					"bookID": 2,
					"isCore": false,
					"dieValue": 12,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Taunt",
					"value": "d10",
					"bookID": 2,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				},
				{
					"mod": 4,
					"name": "Stealth",
					"value": "d12",
					"bookID": 2,
					"isCore": false,
					"dieValue": 12,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Throwing",
					"value": "d12",
					"bookID": 2,
					"isCore": false,
					"dieValue": 12,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": -6,
			"parryHR": "5",
			"shields": [],
			"weapons": [],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Deluxe (EE)",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Ethereal",
					"note": "Ghosts are immaterial and can only be harmed by magical attacks.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p137",
					"description": "Ghosts are immaterial and can only be harmed by magical attacks."
				},
				{
					"from": "",
					"name": "Fear -2",
					"note": "Ghosts cause Fear checks at &#x2013;2 when they let themselves be seen.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p137",
					"description": "Ghosts cause Fear checks at &#x2013;2 when they let themselves be seen."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 0,
			"parryBase": 5,
			"sizeLabel": "0 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 0,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d10",
					"dieValue": 10
				},
				{
					"mod": 0,
					"name": "strength",
					"label": "Strength",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d6",
					"dieValue": 6
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 5,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2018-05-03T19:05:27.000Z",
			"description": "Spectres, shades, and phantoms sometimes return from death to haunt the living or fulfill some lost goal.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2018-05-03T19:05:27.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 0,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2011",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 5,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 5,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "5",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/deluxe/ghost",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 5,
			"toughnessAsReadNoHeavy": "5",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 645,
		"gear": [
			"Thrown objects (Str+d4)."
		],
		"name": "Ghost",
		"pace": "6",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "2c63a946-73e7-4c62-a8a6-2a5840adba8a",
		"armor": "0",
		"climb": 0,
		"edges": [
			"-"
		],
		"flies": 0,
		"image": "",
		"parry": "5",
		"active": true,
		"skills": [
			{
				"Name": "Athletics",
				"Value": "d6"
			},
			{
				"Name": "Common Knowledge",
				"Value": "d8"
			},
			{
				"Name": "Fighting",
				"Value": "d6"
			},
			{
				"Name": "Intimidation",
				"Value": "d12"
			},
			{
				"Name": "Notice",
				"Value": "d12"
			},
			{
				"Name": "Stealth",
				"Value": "d12"
			},
			{
				"Name": "Taunt",
				"Value": "d10"
			}
		],
		"book_id": 9,
		"summary": "",
		"bookPage": "p183",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Ethereal",
				"Notes": "Ghosts are invisible and immaterial at will and can only be harmed by magical attacks."
			},
			{
				"Name": "Fear (−2)",
				"Notes": "Ghosts cause Fear checks at −2 when they let themselves be seen."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "5",
		"attributes": {
			"Vigor": "d6",
			"Smarts": "d6",
			"Spirit": "d10",
			"Agility": "d6",
			"Strength": "d6"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "Specters, shades, and phantoms sometimes return from death to haunt the living or fulfill some unfinished business.",
		"flying_pace": 0,
		"heavy_armor": false,
		"name_plural": "Ghosts",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 645,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Ghost",
			"race": "Ghost",
			"rank": 0,
			"size": 0,
			"uuid": "2c63a946-73e7-4c62-a8a6-2a5840adba8a",
			"armor": [],
			"edges": [
				{
					"id": 0,
					"name": "-",
					"note": "",
					"bookID": 9,
					"isHidden": false,
					"takenFrom": "",
					"description": "",
					"descriptionHTML": "",
					"customDescription": ""
				}
			],
			"image": "",
			"swade": false,
			"bookID": 9,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Athletics",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Common Knowledge",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Intimidation",
					"value": "d12",
					"bookID": 9,
					"isCore": false,
					"dieValue": 12,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d12",
					"bookID": 9,
					"isCore": false,
					"dieValue": 12,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Stealth",
					"value": "d12",
					"bookID": 9,
					"isCore": false,
					"dieValue": 12,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Taunt",
					"value": "d10",
					"bookID": 9,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": 0,
			"parryHR": "5",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Thrown objects",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d4.",
					"innate": false,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Thrown objects",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d4.",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d6",
							"parry_modifier": 0,
							"damage_original": "str+d4.",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 0,
							"damageWithBrackets": "str+d4.",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d6",
					"descriptionHTML": "",
					"damageDiceBasePlus": 0,
					"damageWithBrackets": "str+d4."
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Adventure Edition",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Ethereal",
					"note": "Ghosts are invisible and immaterial at will and can only be harmed by magical attacks.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p183",
					"description": "Ghosts are invisible and immaterial at will and can only be harmed by magical attacks."
				},
				{
					"from": "",
					"name": "Fear (-2)",
					"note": "Ghosts cause Fear checks at -2 when they let themselves be seen.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p183",
					"description": "Ghosts cause Fear checks at -2 when they let themselves be seen."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 6,
			"parryBase": 5,
			"sizeLabel": "0 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 0,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d10",
					"dieValue": 10
				},
				{
					"mod": 0,
					"name": "strength",
					"label": "Strength",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d6",
					"dieValue": 6
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 5,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2019-01-23T02:05:12.000Z",
			"description": "Specters, shades, and phantoms sometimes return from death to haunt the living or fulfill some unfinished business.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2019-01-23T02:05:12.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 0,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2018",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 5,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 5,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "5",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/swade/ghost",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 5,
			"toughnessAsReadNoHeavy": "5",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 13,
		"gear": [],
		"name": "Giant Worm",
		"pace": "6",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "a8947396-f002-4de4-b876-0ccde6058f3a",
		"armor": "4",
		"climb": -10,
		"edges": [],
		"flies": 0,
		"image": "",
		"parry": "5",
		"active": true,
		"skills": [
			{
				"Name": "Fighting",
				"Value": "d6"
			},
			{
				"Name": "Notice",
				"Value": "d10"
			},
			{
				"Name": "Stealth",
				"Value": "d10"
			}
		],
		"book_id": 2,
		"summary": "",
		"bookPage": "p137",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": 1,
		"abilities": [
			{
				"Name": "Armor +4",
				"Notes": " Scaly Hide."
			},
			{
				"Name": "Bite",
				"Notes": " Str+d8."
			},
			{
				"Name": "Burrow (20\")",
				"Notes": " Giant worms can disappear and reappear on the following action anywhere within 20\"."
			},
			{
				"Name": "Gargantuan",
				"Notes": " The worms are Huge and thus suffer +4 to ranged attacks against them. Their attacks count as Heavy Weapons, and they add their Size to Strength rolls."
			},
			{
				"Name": "Hardy",
				"Notes": " The creature does not suffer a wound from being Shaken twice."
			},
			{
				"Name": "Size +10",
				"Notes": " Giant worms are usually well over 50'long and 10' or more in diameter."
			},
			{
				"Name": "Slam",
				"Notes": " Giant worms attempt to rise up and crush their prey beneath their massive bodies. This is an opposed roll of the creature's Fighting versus the target's Agility. If the worm wins, the victim suffers 4d6 damage."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "22",
		"attributes": {
			"Vigor": "d12",
			"Smarts": "d6 (A)",
			"Spirit": "d10",
			"Agility": "d6",
			"Strength": "d12+10"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "Massive worms tunneling beneath the earth to gobble up unsuspecting adventurers are sometimes found in lonesome flatlands. The things sense vibrations through the earth, hearing a walking person at about 200 yards. The stats below are for a monster some 50' long.",
		"flying_pace": -10,
		"heavy_armor": false,
		"name_plural": "",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 13,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Giant Worm",
			"race": "Giant Worm",
			"rank": 0,
			"size": 10,
			"uuid": "a8947396-f002-4de4-b876-0ccde6058f3a",
			"armor": [],
			"edges": [],
			"image": "",
			"swade": false,
			"bookID": 2,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d6",
					"bookID": 2,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d10",
					"bookID": 2,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Stealth",
					"value": "d10",
					"bookID": 2,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 2,
			"fatigue": 0,
			"journal": [],
			"paceMod": 0,
			"parryHR": "5",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Bite",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d8",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Bite",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d8",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d8+1d12",
							"parry_modifier": 0,
							"damage_original": "str+d8",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 10,
							"damageWithBrackets": "str+d8",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d8+1d12",
					"descriptionHTML": "",
					"damageDiceBasePlus": 10,
					"damageWithBrackets": "str+d8"
				},
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Slam",
					"uuid": "",
					"notes": " This is an opposed roll of the creature's Fighting versus the target's Agility",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "giant",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Slam",
							"notes": " This is an opposed roll of the creature's Fighting versus the target's Agility",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "giant",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "",
							"parry_modifier": 0,
							"damage_original": "giant",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": null,
							"damageWithBrackets": "giant",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "",
					"descriptionHTML": "",
					"damageDiceBasePlus": null,
					"damageWithBrackets": "giant"
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Deluxe (EE)",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": true,
			"abilities": [
				{
					"from": "",
					"name": "Armor +4",
					"note": "Scaly Hide.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p137",
					"description": "Scaly Hide."
				},
				{
					"from": "",
					"name": "Bite",
					"note": "Str+d8.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p137",
					"description": "Str+d8."
				},
				{
					"from": "",
					"name": "Burrow (20\")",
					"note": "Giant worms can disappear and reappear on the following action anywhere within 20\".",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p137",
					"description": "Giant worms can disappear and reappear on the following action anywhere within 20\"."
				},
				{
					"from": "",
					"name": "Gargantuan",
					"note": "The worms are Huge and thus suffer +4 to ranged attacks against them. Their attacks count as Heavy Weapons, and they add their Size to Strength rolls.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p137",
					"description": "The worms are Huge and thus suffer +4 to ranged attacks against them. Their attacks count as Heavy Weapons, and they add their Size to Strength rolls."
				},
				{
					"from": "",
					"name": "Hardy",
					"note": "The creature does not suffer a wound from being Shaken twice.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p137",
					"description": "The creature does not suffer a wound from being Shaken twice."
				},
				{
					"from": "",
					"name": "Size +10",
					"note": "Giant worms are usually well over 50'long and 10' or more in diameter.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p137",
					"description": "Giant worms are usually well over 50'long and 10' or more in diameter."
				},
				{
					"from": "",
					"name": "Slam",
					"note": "Giant worms attempt to rise up and crush their prey beneath their massive bodies. This is an opposed roll of the creature's Fighting versus the target's Agility. If the worm wins, the victim suffers 4d6 damage.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p137",
					"description": "Giant worms attempt to rise up and crush their prey beneath their massive bodies. This is an opposed roll of the creature's Fighting versus the target's Agility. If the worm wins, the victim suffers 4d6 damage."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 6,
			"parryBase": 5,
			"sizeLabel": "10 (Huge)",
			"strainMax": 0,
			"woundsMax": 5,
			"appVersion": "3.2.3b010603",
			"armorValue": 4,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d6 (A)",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d10",
					"dieValue": 10
				},
				{
					"mod": 10,
					"name": "strength",
					"label": "Strength",
					"value": "d12+10",
					"dieValue": 12
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d12",
					"dieValue": 12
				}
			],
			"background": "",
			"benniesMax": 2,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 5,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 3,
			"bookPrimary": true,
			"createdDate": "2018-05-03T19:05:27.000Z",
			"description": "Massive worms tunneling beneath the earth to gobble up unsuspecting adventurers are sometimes found in lonesome flatlands. The things sense vibrations through the earth, hearing a walking person at about 200 yards. The stats below are for a monster some 50' long.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2018-05-03T19:05:27.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 10,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2011",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 8,
			"unarmoredHero": false,
			"woundsSizeMod": 2,
			"naturalIsHeavy": false,
			"toughnessTotal": 22,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "22 (4)",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/deluxe/giant-worm",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 18,
			"toughnessAsReadNoHeavy": "22 (4)",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 646,
		"gear": [],
		"name": "Giant Worm",
		"pace": "6",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "28dc3a67-6868-46f3-856b-66db1c06b725",
		"armor": "4",
		"climb": 0,
		"edges": [
			"-"
		],
		"flies": 0,
		"image": "",
		"parry": "5",
		"active": true,
		"skills": [
			{
				"Name": "Athletics",
				"Value": "d6"
			},
			{
				"Name": "Fighting",
				"Value": "d6"
			},
			{
				"Name": "Notice",
				"Value": "d10"
			},
			{
				"Name": "Stealth",
				"Value": "d10"
			}
		],
		"book_id": 9,
		"summary": "",
		"bookPage": "p184",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": 1,
		"abilities": [
			{
				"Name": "Armor +4",
				"Notes": "Scaly hide."
			},
			{
				"Name": "Bite",
				"Notes": "Str+d8."
			},
			{
				"Name": "Burrow (20″)",
				"Notes": "Giant worms tunnel through the ground, blocked only by solid bedrock."
			},
			{
				"Name": "Hardy",
				"Notes": "The creature does not suffer a Wound from being Shaken twice."
			},
			{
				"Name": "Size 9 (Huge)",
				"Notes": "Giant worms are usually over 50′ long and 10′ or more in diameter."
			},
			{
				"Name": "Slam",
				"Notes": "Giant worms crush prey beneath their massive bodies. Lay three Small Blast Templates adjacent to one another and in a straight line to represent the portion of the creature's body used to crush. Everything within must beat the worm in an opposed Athletics versus Agility roll or take its Str as damage. Ignore Scale modifiers when making a slam attack."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "21",
		"attributes": {
			"Vigor": "d12",
			"Smarts": "d6 (A)",
			"Spirit": "d10",
			"Agility": "d6",
			"Strength": "d12+9"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "Massive worms tunneling beneath the earth to gobble up unsuspecting adventurers are sometimes found in lonesome flatlands. The things sense vibrations through the earth, hearing a walking person at about 200 yards. The stats below are for a monster some 50 feet long.",
		"flying_pace": 0,
		"heavy_armor": false,
		"name_plural": "Giant Worms",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 646,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Giant Worm",
			"race": "Giant Worm",
			"rank": 0,
			"size": 9,
			"uuid": "28dc3a67-6868-46f3-856b-66db1c06b725",
			"armor": [],
			"edges": [
				{
					"id": 0,
					"name": "-",
					"note": "",
					"bookID": 9,
					"isHidden": false,
					"takenFrom": "",
					"description": "",
					"descriptionHTML": "",
					"customDescription": ""
				}
			],
			"image": "",
			"swade": false,
			"bookID": 9,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Athletics",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d10",
					"bookID": 9,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Stealth",
					"value": "d10",
					"bookID": 9,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 2,
			"fatigue": 0,
			"journal": [],
			"paceMod": 0,
			"parryHR": "5",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Bite",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d8",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Bite",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d8",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d8+1d12",
							"parry_modifier": 0,
							"damage_original": "str+d8",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 9,
							"damageWithBrackets": "str+d8",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d8+1d12",
					"descriptionHTML": "",
					"damageDiceBasePlus": 9,
					"damageWithBrackets": "str+d8"
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Adventure Edition",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": true,
			"abilities": [
				{
					"from": "",
					"name": "Armor +4",
					"note": "Scaly hide.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p184",
					"description": "Scaly hide."
				},
				{
					"from": "",
					"name": "Bite",
					"note": "Str+d8.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p184",
					"description": "Str+d8."
				},
				{
					"from": "",
					"name": "Burrow (20″)",
					"note": "Giant worms tunnel through the ground, blocked only by solid bedrock.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p184",
					"description": "Giant worms tunnel through the ground, blocked only by solid bedrock."
				},
				{
					"from": "",
					"name": "Hardy",
					"note": "The creature does not suffer a Wound from being Shaken twice.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p184",
					"description": "The creature does not suffer a Wound from being Shaken twice."
				},
				{
					"from": "",
					"name": "Size 9 (Huge)",
					"note": "Giant worms are usually over 50′ long and 10′ or more in diameter.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p184",
					"description": "Giant worms are usually over 50′ long and 10′ or more in diameter."
				},
				{
					"from": "",
					"name": "Slam",
					"note": "Giant worms crush prey beneath their massive bodies. Lay three Small Blast Templates adjacent to one another and in a straight line to represent the portion of the creature's body used to crush. Everything within must beat the worm in an opposed Athletics versus Agility roll or take its Str as damage. Ignore Scale modifiers when making a slam attack.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p184",
					"description": "Giant worms crush prey beneath their massive bodies. Lay three Small Blast Templates adjacent to one another and in a straight line to represent the portion of the creature's body used to crush. Everything within must beat the worm in an opposed Athletics versus Agility roll or take its Str as damage. Ignore Scale modifiers when making a slam attack."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 6,
			"parryBase": 5,
			"sizeLabel": "9 (Huge)",
			"strainMax": 0,
			"woundsMax": 5,
			"appVersion": "3.2.3b010603",
			"armorValue": 4,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d6 (A)",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d10",
					"dieValue": 10
				},
				{
					"mod": 9,
					"name": "strength",
					"label": "Strength",
					"value": "d12+9",
					"dieValue": 12
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d12",
					"dieValue": 12
				}
			],
			"background": "",
			"benniesMax": 2,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 5,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 3,
			"bookPrimary": true,
			"createdDate": "2019-01-23T02:05:35.000Z",
			"description": "Massive worms tunneling beneath the earth to gobble up unsuspecting adventurers are sometimes found in lonesome flatlands. The things sense vibrations through the earth, hearing a walking person at about 200 yards. The stats below are for a monster some 50 feet long.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2019-01-23T02:09:12.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 9,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2018",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 8,
			"unarmoredHero": false,
			"woundsSizeMod": 2,
			"naturalIsHeavy": false,
			"toughnessTotal": 21,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "21 (4)",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/swade/giant-worm",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 17,
			"toughnessAsReadNoHeavy": "21 (4)",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 14,
		"gear": [],
		"name": "Goblin",
		"pace": "5",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "b5d5644a-d9de-4aac-a517-37347bc29832",
		"armor": "0",
		"climb": -10,
		"edges": [],
		"flies": 0,
		"image": "https://img00.deviantart.net/1c21/i/2014/210/7/2/goblin_by_aaronflorento-d7srkrg.jpg",
		"parry": "5",
		"active": true,
		"skills": [
			{
				"Name": "Climbing",
				"Value": "d6"
			},
			{
				"Name": "Fighting",
				"Value": "d6"
			},
			{
				"Name": "Notice",
				"Value": "d6"
			},
			{
				"Name": "Taunt",
				"Value": "d6"
			},
			{
				"Name": "Shooting",
				"Value": "d8"
			},
			{
				"Name": "Stealth",
				"Value": "d10"
			},
			{
				"Name": "Throwing",
				"Value": "d6"
			},
			{
				"Name": "Swimming",
				"Value": "d6"
			}
		],
		"book_id": 2,
		"summary": "",
		"bookPage": "p137-p138",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Infravision",
				"Notes": " Goblins halve penalties for dark lighting against living targets (round down)."
			},
			{
				"Name": "Size -1",
				"Notes": " Goblins stand 3-4' tall."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "4",
		"attributes": {
			"Vigor": "d6",
			"Smarts": "d6",
			"Spirit": "d6",
			"Agility": "d8",
			"Strength": "d4"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "Goblins of myth and legend are far more sinister creatures than some games and fiction portray. In the original tales, they were terrifying creatures that stole into homes in the middle of the night to steal and eat unruly children. The statistics here work for both dark &#x201c;fairy tale&#x201d; goblins as well as those found alongside orcs in contemporary roleplaying games.",
		"flying_pace": -10,
		"heavy_armor": false,
		"name_plural": "",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 14,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Goblin",
			"race": "Goblin",
			"rank": 0,
			"size": -1,
			"uuid": "b5d5644a-d9de-4aac-a517-37347bc29832",
			"armor": [],
			"edges": [],
			"image": "https://savaged.ushttps://img00.deviantart.net/1c21/i/2014/210/7/2/goblin_by_aaronflorento-d7srkrg.jpg",
			"swade": false,
			"bookID": 2,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Climbing",
					"value": "d6",
					"bookID": 2,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d6",
					"bookID": 2,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d6",
					"bookID": 2,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Taunt",
					"value": "d6",
					"bookID": 2,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Shooting",
					"value": "d8",
					"bookID": 2,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Stealth",
					"value": "d10",
					"bookID": 2,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Throwing",
					"value": "d6",
					"bookID": 2,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Swimming",
					"value": "d6",
					"bookID": 2,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": -1,
			"parryHR": "5",
			"shields": [],
			"weapons": [],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Deluxe (EE)",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Infravision",
					"note": "Goblins halve penalties for dark lighting against living targets (round down).",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p137-p138",
					"description": "Goblins halve penalties for dark lighting against living targets (round down)."
				},
				{
					"from": "",
					"name": "Size -1",
					"note": "Goblins stand 3-4' tall.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p137-p138",
					"description": "Goblins stand 3-4' tall."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 5,
			"parryBase": 5,
			"sizeLabel": "-1 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 0,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "strength",
					"label": "Strength",
					"value": "d4",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d6",
					"dieValue": 6
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 5,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2018-05-03T19:05:27.000Z",
			"description": "Goblins of myth and legend are far more sinister creatures than some games and fiction portray. In the original tales, they were terrifying creatures that stole into homes in the middle of the night to steal and eat unruly children. The statistics here work for both dark &#x201c;fairy tale&#x201d; goblins as well as those found alongside orcs in contemporary roleplaying games.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2018-05-15T00:40:56.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": -1,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2011",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 5,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 4,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "4",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/deluxe/goblin",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 4,
			"toughnessAsReadNoHeavy": "4",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 649,
		"gear": [
			"Short spears (Str+d4)",
			"bows (Range 12/24/48, Damage 2d6)."
		],
		"name": "Goblin",
		"pace": "5",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "73507cff-1c25-46d4-b0fb-bf9f7d217cfa",
		"armor": "0",
		"climb": 0,
		"edges": [
			"-"
		],
		"flies": 0,
		"image": "https://img00.deviantart.net/1c21/i/2014/210/7/2/goblin_by_aaronflorento-d7srkrg.jpg",
		"parry": "5",
		"active": true,
		"skills": [
			{
				"Name": "Athletics",
				"Value": "d6"
			},
			{
				"Name": "Common Knowledge",
				"Value": "d10"
			},
			{
				"Name": "Fighting",
				"Value": "d6"
			},
			{
				"Name": "Notice",
				"Value": "d6"
			},
			{
				"Name": "Persuasion",
				"Value": "d4"
			},
			{
				"Name": "Shooting",
				"Value": "d8"
			},
			{
				"Name": "Stealth",
				"Value": "d10"
			},
			{
				"Name": "Taunt",
				"Value": "d6"
			}
		],
		"book_id": 9,
		"summary": "",
		"bookPage": "p184",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Infravision",
				"Notes": "Halve penalties for Illumination when attacking warm targets."
			},
			{
				"Name": "Size −1",
				"Notes": "Goblins stand 3–4′ tall."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "4",
		"attributes": {
			"Vigor": "d6",
			"Smarts": "d6",
			"Spirit": "d6",
			"Agility": "d8",
			"Strength": "d4"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [
			"Greedy (Major)"
		],
		"updated_by": 0,
		"version_of": 0,
		"description": "Goblins of myth and legend are far more sinister creatures than some games and fiction portray. In the original tales, they were terrifying creatures that stole into homes in the middle of the night to steal and eat unruly children. The statistics here work for both dark “fairy tale” goblins as well as those found alongside orcs in contemporary roleplaying games.",
		"flying_pace": 0,
		"heavy_armor": false,
		"name_plural": "Goblins",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 649,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Goblin",
			"race": "Goblin",
			"rank": 0,
			"size": -1,
			"uuid": "73507cff-1c25-46d4-b0fb-bf9f7d217cfa",
			"armor": [],
			"edges": [
				{
					"id": 0,
					"name": "-",
					"note": "",
					"bookID": 9,
					"isHidden": false,
					"takenFrom": "",
					"description": "",
					"descriptionHTML": "",
					"customDescription": ""
				}
			],
			"image": "https://savaged.ushttps://img00.deviantart.net/1c21/i/2014/210/7/2/goblin_by_aaronflorento-d7srkrg.jpg",
			"swade": false,
			"bookID": 9,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Athletics",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Common Knowledge",
					"value": "d10",
					"bookID": 9,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Persuasion",
					"value": "d4",
					"bookID": 9,
					"isCore": false,
					"dieValue": 4,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Shooting",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Stealth",
					"value": "d10",
					"bookID": 9,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Taunt",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": -1,
			"parryHR": "5",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Short spears",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d4",
					"innate": false,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Short spears",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d4",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "2d4",
							"parry_modifier": 0,
							"damage_original": "str+d4",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 0,
							"damageWithBrackets": "str+d4",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "2d4",
					"descriptionHTML": "",
					"damageDiceBasePlus": 0,
					"damageWithBrackets": "str+d4"
				},
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "bows",
					"uuid": "",
					"notes": "",
					"range": "12/24/48",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "2d6.",
					"innate": false,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "bows",
							"notes": "",
							"range": "12/24/48",
							"reach": 0,
							"shots": 0,
							"damage": "2d6.",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "",
							"parry_modifier": 0,
							"damage_original": "2d6.",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 0,
							"damageWithBrackets": "2d6.",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "",
					"descriptionHTML": "",
					"damageDiceBasePlus": 0,
					"damageWithBrackets": "2d6."
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Adventure Edition",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Infravision",
					"note": "Halve penalties for Illumination when attacking warm targets.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p184",
					"description": "Halve penalties for Illumination when attacking warm targets."
				},
				{
					"from": "",
					"name": "Size -1",
					"note": "Goblins stand 3–4′ tall.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p184",
					"description": "Goblins stand 3–4′ tall."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 5,
			"parryBase": 5,
			"sizeLabel": "-1 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 0,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "strength",
					"label": "Strength",
					"value": "d4",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d6",
					"dieValue": 6
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [
				{
					"id": 122,
					"name": "Greedy (Major)",
					"note": "",
					"major": false,
					"bookID": 9,
					"isHidden": false,
					"takenFrom": "",
					"description": "",
					"descriptionHTML": "",
					"customDescription": ""
				}
			],
			"imageToken": "",
			"parryTotal": 5,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2019-01-23T02:06:09.000Z",
			"description": "Goblins of myth and legend are far more sinister creatures than some games and fiction portray. In the original tales, they were terrifying creatures that stole into homes in the middle of the night to steal and eat unruly children. The statistics here work for both dark \"fairy tale\" goblins as well as those found alongside orcs in contemporary roleplaying games.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2019-01-23T02:06:09.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": -1,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2018",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 5,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 4,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "4",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/swade/goblin",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 4,
			"toughnessAsReadNoHeavy": "4",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 650,
		"gear": [],
		"name": "Horse",
		"pace": "12",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "75e2150b-8440-4d94-8cf0-297269466553",
		"armor": "0",
		"climb": 0,
		"edges": [
			"Fleet-Footed."
		],
		"flies": 0,
		"image": "",
		"parry": "4",
		"active": true,
		"skills": [
			{
				"Name": "Athletics",
				"Value": "d8"
			},
			{
				"Name": "Fighting",
				"Value": "d4"
			},
			{
				"Name": "Notice",
				"Value": "d6"
			}
		],
		"book_id": 9,
		"summary": "",
		"bookPage": "p184",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Kick",
				"Notes": "Str+d4, to the front or rear as desired."
			},
			{
				"Name": "Size 2",
				"Notes": "Typical quarter horse of about 1000 lbs."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "8",
		"attributes": {
			"Vigor": "d8",
			"Smarts": "d4 (A)",
			"Spirit": "d6",
			"Agility": "d8",
			"Strength": "d12"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "Riding horses compromise speed with carrying capacity.",
		"flying_pace": 0,
		"heavy_armor": false,
		"name_plural": "Horses",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 650,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Horse",
			"race": "Horse",
			"rank": 0,
			"size": 2,
			"uuid": "75e2150b-8440-4d94-8cf0-297269466553",
			"armor": [],
			"edges": [
				{
					"id": 0,
					"name": "Fleet-Footed.",
					"note": "",
					"bookID": 9,
					"isHidden": false,
					"takenFrom": "",
					"description": "",
					"descriptionHTML": "",
					"customDescription": ""
				}
			],
			"image": "",
			"swade": false,
			"bookID": 9,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Athletics",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d4",
					"bookID": 9,
					"isCore": false,
					"dieValue": 4,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": 6,
			"parryHR": "4",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Kick",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d4",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Kick",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d4",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d4+1d12",
							"parry_modifier": 0,
							"damage_original": "str+d4",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 0,
							"damageWithBrackets": "str+d4",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d4+1d12",
					"descriptionHTML": "",
					"damageDiceBasePlus": 0,
					"damageWithBrackets": "str+d4"
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Adventure Edition",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Kick",
					"note": "Str+d4, to the front or rear as desired.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p184",
					"description": "Str+d4, to the front or rear as desired."
				},
				{
					"from": "",
					"name": "Size 2",
					"note": "Typical quarter horse of about 1000 lbs.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p184",
					"description": "Typical quarter horse of about 1000 lbs."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 12,
			"parryBase": 4,
			"sizeLabel": "2 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 0,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d4 (A)",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "strength",
					"label": "Strength",
					"value": "d12",
					"dieValue": 12
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d8",
					"dieValue": 8
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 4,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2019-01-23T02:06:18.000Z",
			"description": "Riding horses compromise speed with carrying capacity.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2019-01-23T02:08:25.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 2,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2018",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 6,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 8,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "8",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/swade/horse",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 8,
			"toughnessAsReadNoHeavy": "8",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 3,
		"gear": [],
		"name": "Horse, Riding",
		"pace": "10",
		"race": "",
		"tags": [
			"animal"
		],
		"type": "bestiary",
		"uuid": "be007c4a-daa9-4adb-3337-02a01191f3e6",
		"armor": "0",
		"climb": -10,
		"edges": [],
		"flies": 0,
		"image": "http://horsebreedsinfo.com/images/brown_horse.jpg",
		"parry": "4",
		"active": true,
		"skills": [
			{
				"Name": "Fighting",
				"Value": "d6"
			},
			{
				"Name": "Notice",
				"Value": "d6"
			}
		],
		"book_id": 2,
		"summary": "",
		"bookPage": "p138",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Fleet-Footed",
				"Notes": " Horses roll a d8 when running instead of a d6."
			},
			{
				"Name": "Kick",
				"Notes": " Str."
			},
			{
				"Name": "Size +2",
				"Notes": " Riding horses weigh between 800 and 1000 pounds."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "8",
		"attributes": {
			"Vigor": "d8",
			"Smarts": "d4 (A)",
			"Spirit": "d6",
			"Agility": "d8",
			"Strength": "d12"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "Riding horses are medium-sized animals that manage a good compromise between speed and carrying capacity.",
		"flying_pace": -10,
		"heavy_armor": false,
		"name_plural": "",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 3,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Horse, Riding",
			"race": "Horse, Riding",
			"rank": 0,
			"size": 2,
			"uuid": "be007c4a-daa9-4adb-3337-02a01191f3e6",
			"armor": [],
			"edges": [],
			"image": "https://savaged.ushttp://horsebreedsinfo.com/images/brown_horse.jpg",
			"swade": false,
			"bookID": 2,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d6",
					"bookID": 2,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d6",
					"bookID": 2,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": 4,
			"parryHR": "4",
			"shields": [],
			"weapons": [],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Deluxe (EE)",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Fleet-Footed",
					"note": "Horses roll a d8 when running instead of a d6.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p138",
					"description": "Horses roll a d8 when running instead of a d6."
				},
				{
					"from": "",
					"name": "Kick",
					"note": "Str.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p138",
					"description": "Str."
				},
				{
					"from": "",
					"name": "Size +2",
					"note": "Riding horses weigh between 800 and 1000 pounds.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p138",
					"description": "Riding horses weigh between 800 and 1000 pounds."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 10,
			"parryBase": 4,
			"sizeLabel": "2 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 0,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d4 (A)",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "strength",
					"label": "Strength",
					"value": "d12",
					"dieValue": 12
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d8",
					"dieValue": 8
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 4,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2018-05-03T19:05:27.000Z",
			"description": "Riding horses are medium-sized animals that manage a good compromise between speed and carrying capacity.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2018-05-03T19:05:27.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 2,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2011",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 6,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 8,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "8",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/deluxe/horse-riding",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 8,
			"toughnessAsReadNoHeavy": "8",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 15,
		"gear": [],
		"name": "Horse, War",
		"pace": "10",
		"race": "",
		"tags": [
			"animal"
		],
		"type": "bestiary",
		"uuid": "3396945b-73a0-4e3c-1c67-7fa06a4eb536",
		"armor": "0",
		"climb": -10,
		"edges": [],
		"flies": 0,
		"image": "http://fc09.deviantart.net/fs44/f/2009/156/0/b/War_Horse_by_BenWootten.jpg",
		"parry": "4",
		"active": true,
		"skills": [
			{
				"Name": "Fighting",
				"Value": "d8"
			},
			{
				"Name": "Notice",
				"Value": "d6"
			}
		],
		"book_id": 2,
		"summary": "",
		"bookPage": "p138",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Fleet-Footed",
				"Notes": " Horses roll a d8 when running instead of a d6."
			},
			{
				"Name": "Kick",
				"Notes": " Str+d4."
			},
			{
				"Name": "Size +3",
				"Notes": " Warhorses are large creatures bred for their power and stature."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "8",
		"attributes": {
			"Vigor": "d10",
			"Smarts": "d4 (A)",
			"Spirit": "d6",
			"Agility": "d6",
			"Strength": "d12+2"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "War horses are large beasts trained for aggression. They are trained to fight with both hooves, either to their front or their rear. In combat, the animal attacks any round its rider doesn't make a trick maneuver of some kind.",
		"flying_pace": -10,
		"heavy_armor": false,
		"name_plural": "",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 15,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Horse, War",
			"race": "Horse, War",
			"rank": 0,
			"size": 3,
			"uuid": "3396945b-73a0-4e3c-1c67-7fa06a4eb536",
			"armor": [],
			"edges": [],
			"image": "https://savaged.ushttp://fc09.deviantart.net/fs44/f/2009/156/0/b/War_Horse_by_BenWootten.jpg",
			"swade": false,
			"bookID": 2,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d8",
					"bookID": 2,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d6",
					"bookID": 2,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": 4,
			"parryHR": "4",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Kick",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d4",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Kick",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d4",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d4+1d12",
							"parry_modifier": 0,
							"damage_original": "str+d4",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 2,
							"damageWithBrackets": "str+d4",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d4+1d12",
					"descriptionHTML": "",
					"damageDiceBasePlus": 2,
					"damageWithBrackets": "str+d4"
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Deluxe (EE)",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Fleet-Footed",
					"note": "Horses roll a d8 when running instead of a d6.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p138",
					"description": "Horses roll a d8 when running instead of a d6."
				},
				{
					"from": "",
					"name": "Kick",
					"note": "Str+d4.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p138",
					"description": "Str+d4."
				},
				{
					"from": "",
					"name": "Size +3",
					"note": "Warhorses are large creatures bred for their power and stature.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p138",
					"description": "Warhorses are large creatures bred for their power and stature."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 10,
			"parryBase": 4,
			"sizeLabel": "3 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 0,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d4 (A)",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 2,
					"name": "strength",
					"label": "Strength",
					"value": "d12+2",
					"dieValue": 12
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d10",
					"dieValue": 10
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 4,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2018-05-03T19:05:27.000Z",
			"description": "War horses are large beasts trained for aggression. They are trained to fight with both hooves, either to their front or their rear. In combat, the animal attacks any round its rider doesn't make a trick maneuver of some kind.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2018-05-03T19:05:27.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 1,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2011",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 7,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 8,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "8",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/deluxe/horse-war",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 8,
			"toughnessAsReadNoHeavy": "8",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 651,
		"gear": [],
		"name": "Horse, War",
		"pace": "8",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "698ea14c-dca2-434d-b6b0-ad9a012e7b4a",
		"armor": "0",
		"climb": 0,
		"edges": [
			"Fleet-Footed."
		],
		"flies": 0,
		"image": "http://fc09.deviantart.net/fs44/f/2009/156/0/b/War_Horse_by_BenWootten.jpg",
		"parry": "6",
		"active": true,
		"skills": [
			{
				"Name": "Athletics",
				"Value": "d6"
			},
			{
				"Name": "Fighting",
				"Value": "d8"
			},
			{
				"Name": "Notice",
				"Value": "d6"
			}
		],
		"book_id": 9,
		"summary": "",
		"bookPage": "p185",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Kick",
				"Notes": "Str+d4, to the front or rear as desired."
			},
			{
				"Name": "Size 3",
				"Notes": "Warhorses are large creatures bred for their power and stature."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "10",
		"attributes": {
			"Vigor": "d10",
			"Smarts": "d4 (A)",
			"Spirit": "d6",
			"Agility": "d6",
			"Strength": "d12+2"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "War horses are large beasts trained for aggression. They are trained to fight with both hooves, either to their front or their rear.",
		"flying_pace": 0,
		"heavy_armor": false,
		"name_plural": "Horse, Wars",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 651,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Horse, War",
			"race": "Horse, War",
			"rank": 0,
			"size": 3,
			"uuid": "698ea14c-dca2-434d-b6b0-ad9a012e7b4a",
			"armor": [],
			"edges": [
				{
					"id": 0,
					"name": "Fleet-Footed.",
					"note": "",
					"bookID": 9,
					"isHidden": false,
					"takenFrom": "",
					"description": "",
					"descriptionHTML": "",
					"customDescription": ""
				}
			],
			"image": "https://savaged.ushttp://fc09.deviantart.net/fs44/f/2009/156/0/b/War_Horse_by_BenWootten.jpg",
			"swade": false,
			"bookID": 9,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Athletics",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": 2,
			"parryHR": "6",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Kick",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d4",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Kick",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d4",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d4+1d12",
							"parry_modifier": 0,
							"damage_original": "str+d4",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 2,
							"damageWithBrackets": "str+d4",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d4+1d12",
					"descriptionHTML": "",
					"damageDiceBasePlus": 2,
					"damageWithBrackets": "str+d4"
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Adventure Edition",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Kick",
					"note": "Str+d4, to the front or rear as desired.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p185",
					"description": "Str+d4, to the front or rear as desired."
				},
				{
					"from": "",
					"name": "Size 3",
					"note": "Warhorses are large creatures bred for their power and stature.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p185",
					"description": "Warhorses are large creatures bred for their power and stature."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 8,
			"parryBase": 6,
			"sizeLabel": "3 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 0,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d4 (A)",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 2,
					"name": "strength",
					"label": "Strength",
					"value": "d12+2",
					"dieValue": 12
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d10",
					"dieValue": 10
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 6,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2019-01-23T02:06:37.000Z",
			"description": "War horses are large beasts trained for aggression. They are trained to fight with both hooves, either to their front or their rear.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2019-01-23T02:06:37.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 3,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2018",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 7,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 10,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "10",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/swade/horse-war",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 10,
			"toughnessAsReadNoHeavy": "10",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 16,
		"gear": [
			"Magical armor(+6)",
			"other magical items"
		],
		"name": "Lich",
		"pace": "6",
		"race": "",
		"tags": [
			"undead"
		],
		"type": "bestiary",
		"uuid": "09629097-75f7-40e1-370d-a7ae6e1dca2f",
		"armor": "6",
		"climb": -10,
		"edges": [],
		"flies": 0,
		"image": "",
		"parry": "6",
		"active": true,
		"skills": [
			{
				"Name": "Fighting",
				"Value": "d8"
			},
			{
				"Name": "Intimidation",
				"Value": "d12"
			},
			{
				"Name": "Knowledge (Occult)",
				"Value": "d12+2"
			},
			{
				"Name": "Notice",
				"Value": "d10"
			},
			{
				"Name": "Spellcasting",
				"Value": "d12"
			}
		],
		"book_id": 2,
		"summary": "",
		"bookPage": "p138",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": 1,
		"abilities": [
			{
				"Name": "Death Touch",
				"Notes": " Liches drain the lives of those around them with a touch. Instead of a normal attack, a lich may make a touch attack. Every raise on its Fighting roll automatically inflicts one wound to its target."
			},
			{
				"Name": "Spells",
				"Notes": " Liches have 50 Power Points and know most every spell available."
			},
			{
				"Name": "Undead",
				"Notes": " +2 Toughness; +2 to recover from being Shaken; called shots do no extra damage; ignores wound penalties."
			},
			{
				"Name": "Zombie",
				"Notes": " Liches are necromancers first and foremost. The undead they raise through the zombie spell are permanent, so they are usually surrounded by 4d10 skeletons or zombies as they choose. Some liches have entire armies of the undead at their disposal."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "15",
		"attributes": {
			"Vigor": "d10",
			"Smarts": "d12+2",
			"Spirit": "d10",
			"Agility": "d6",
			"Strength": "d10"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "Perhaps the most diabolical creature in any fantasy land is the lich&#x2014;a necromancer so consumed with the black arts that he eventually becomes undead himself.",
		"flying_pace": -10,
		"heavy_armor": false,
		"name_plural": "",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 16,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [
				{
					"id": 0,
					"cost": 0,
					"name": "Magical armor(+6)",
					"uuid": "",
					"notes": "",
					"bookID": 0,
					"weight": 0,
					"costBuy": 0,
					"summary": "",
					"contains": {
						"gear": [],
						"armor": [],
						"shields": [],
						"weapons": []
					},
					"equipped": true,
					"quantity": 1,
					"container": false,
					"takenFrom": "",
					"bookPublished": "",
					"bookPublisher": "",
					"descriptionHTML": ""
				},
				{
					"id": 0,
					"cost": 0,
					"name": "other magical items",
					"uuid": "",
					"notes": "",
					"bookID": 0,
					"weight": 0,
					"costBuy": 0,
					"summary": "",
					"contains": {
						"gear": [],
						"armor": [],
						"shields": [],
						"weapons": []
					},
					"equipped": true,
					"quantity": 1,
					"container": false,
					"takenFrom": "",
					"bookPublished": "",
					"bookPublisher": "",
					"descriptionHTML": ""
				}
			],
			"load": 0,
			"name": "Lich",
			"race": "Lich",
			"rank": 0,
			"size": 0,
			"uuid": "09629097-75f7-40e1-370d-a7ae6e1dca2f",
			"armor": [
				{
					"id": 0,
					"cost": 0,
					"name": "Magical armor",
					"uuid": "",
					"armor": 6,
					"notes": "Magical armor",
					"bookID": 0,
					"minStr": "Magical armor",
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"isShield": false,
					"quantity": 1,
					"takenFrom": "",
					"coversArms": false,
					"coversFace": false,
					"coversHead": false,
					"coversLegs": false,
					"heavyArmor": false,
					"coversTorso": true,
					"bookPublished": "",
					"bookPublisher": "",
					"descriptionHTML": "",
					"equippedStrength": "d10",
					"equippedToughness": "15 (6)"
				}
			],
			"edges": [],
			"image": "",
			"swade": false,
			"bookID": 2,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d8",
					"bookID": 2,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Intimidation",
					"value": "d12",
					"bookID": 2,
					"isCore": false,
					"dieValue": 12,
					"attribute": ""
				},
				{
					"mod": 2,
					"name": "Knowledge (Occult)",
					"value": "d12",
					"bookID": 2,
					"isCore": false,
					"dieValue": 12,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d10",
					"bookID": 2,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Spellcasting",
					"value": "d12",
					"bookID": 9,
					"isCore": false,
					"dieValue": 12,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 2,
			"fatigue": 0,
			"journal": [],
			"paceMod": 0,
			"parryHR": "6",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Zombie",
					"uuid": "",
					"notes": " The undead they raise through the zombie spell are permanent, so they are usually surrounded by 4d10 skeletons or zombies as they choose",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "liches",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Zombie",
							"notes": " The undead they raise through the zombie spell are permanent, so they are usually surrounded by 4d10 skeletons or zombies as they choose",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "liches",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "",
							"parry_modifier": 0,
							"damage_original": "liches",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": null,
							"damageWithBrackets": "liches",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "",
					"descriptionHTML": "",
					"damageDiceBasePlus": null,
					"damageWithBrackets": "liches"
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Deluxe (EE)",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": true,
			"abilities": [
				{
					"from": "",
					"name": "Death Touch",
					"note": "Liches drain the lives of those around them with a touch. Instead of a normal attack, a lich may make a touch attack. Every raise on its Fighting roll automatically inflicts one wound to its target.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p138",
					"description": "Liches drain the lives of those around them with a touch. Instead of a normal attack, a lich may make a touch attack. Every raise on its Fighting roll automatically inflicts one wound to its target."
				},
				{
					"from": "",
					"name": "Spells",
					"note": "Liches have 50 Power Points and know most every spell available.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p138",
					"description": "Liches have 50 Power Points and know most every spell available."
				},
				{
					"from": "",
					"name": "Undead",
					"note": "+2 Toughness; +2 to recover from being Shaken; called shots do no extra damage; ignores wound penalties.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p138",
					"description": "+2 Toughness; +2 to recover from being Shaken; called shots do no extra damage; ignores wound penalties."
				},
				{
					"from": "",
					"name": "Zombie",
					"note": "Liches are necromancers first and foremost. The undead they raise through the zombie spell are permanent, so they are usually surrounded by 4d10 skeletons or zombies as they choose. Some liches have entire armies of the undead at their disposal.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p138",
					"description": "Liches are necromancers first and foremost. The undead they raise through the zombie spell are permanent, so they are usually surrounded by 4d10 skeletons or zombies as they choose. Some liches have entire armies of the undead at their disposal."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 6,
			"parryBase": 6,
			"sizeLabel": "0 (Normal)",
			"strainMax": 0,
			"woundsMax": 3,
			"appVersion": "3.2.3b010603",
			"armorValue": 6,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 2,
					"name": "smarts",
					"label": "Smarts",
					"value": "d12+2",
					"dieValue": 12
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d10",
					"dieValue": 10
				},
				{
					"mod": 0,
					"name": "strength",
					"label": "Strength",
					"value": "d10",
					"dieValue": 10
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d10",
					"dieValue": 10
				}
			],
			"background": "",
			"benniesMax": 2,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 6,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 3,
			"bookPrimary": true,
			"createdDate": "2018-05-03T19:05:27.000Z",
			"description": "Perhaps the most diabolical creature in any fantasy land is the lich&#x2014;a necromancer so consumed with the black arts that he eventually becomes undead himself.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2018-05-03T19:05:27.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 2,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2011",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 7,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 15,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "15 (6)",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/deluxe/lich",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 9,
			"toughnessAsReadNoHeavy": "15 (6)",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 654,
		"gear": [
			"Magical armor (+6)",
			"other magical items."
		],
		"name": "Lich",
		"pace": "6",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "883de6d3-ed1a-491c-8bcc-ed62bd26ef5e",
		"armor": "6",
		"climb": 0,
		"edges": [
			"Concentration",
			"Level Headed (Imp)",
			"Wizard"
		],
		"flies": 0,
		"image": "",
		"parry": "6",
		"active": true,
		"skills": [
			{
				"Name": "Athletics",
				"Value": "d6"
			},
			{
				"Name": "Common Knowledge",
				"Value": "d8"
			},
			{
				"Name": "Fighting",
				"Value": "d8"
			},
			{
				"Name": "Intimidation",
				"Value": "d12"
			},
			{
				"Name": "Notice",
				"Value": "d10"
			},
			{
				"Name": "Occult",
				"Value": "d12+2"
			},
			{
				"Name": "Persuasion",
				"Value": "d8"
			},
			{
				"Name": "Spellcasting",
				"Value": "d12"
			}
		],
		"book_id": 9,
		"summary": "",
		"bookPage": "p185",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": 1,
		"abilities": [
			{
				"Name": "Death Touch",
				"Notes": "Liches drain the lives of those around them with a touch. Instead of a normal attack, a lich may make a Touch Attack (page 108). Every raise on its Fighting roll automatically inflicts one Wound to its target."
			},
			{
				"Name": "Spells",
				"Notes": "Liches have 50 Power Points and know most every spell available."
			},
			{
				"Name": "Undead",
				"Notes": "+2 Toughness; +2 to recover from being Shaken; no additional damage from Called Shots; ignores 1 point of Wound penalties; doesn't breathe; immune to disease and poison."
			},
			{
				"Name": "Zombie",
				"Notes": "Liches are necromancers first and foremost. The undead they raise through the zombie spell are permanent, and they may raise skeletons just like zombies as they choose. Some liches have built up entire armies of the undead at their disposal."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "15",
		"attributes": {
			"Vigor": "d10",
			"Smarts": "d12+2",
			"Spirit": "d10",
			"Agility": "d6",
			"Strength": "d10"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "Perhaps the most diabolical creature in any fantasy land is the lich-a necromancer so consumed with the black arts that he eventually becomes undead himself.",
		"flying_pace": 0,
		"heavy_armor": false,
		"name_plural": "Lichs",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 654,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [
				{
					"id": 0,
					"cost": 0,
					"name": "Magical armor (+6)",
					"uuid": "",
					"notes": "",
					"bookID": 0,
					"weight": 0,
					"costBuy": 0,
					"summary": "",
					"contains": {
						"gear": [],
						"armor": [],
						"shields": [],
						"weapons": []
					},
					"equipped": true,
					"quantity": 1,
					"container": false,
					"takenFrom": "",
					"bookPublished": "",
					"bookPublisher": "",
					"descriptionHTML": ""
				},
				{
					"id": 0,
					"cost": 0,
					"name": "other magical items.",
					"uuid": "",
					"notes": "",
					"bookID": 0,
					"weight": 0,
					"costBuy": 0,
					"summary": "",
					"contains": {
						"gear": [],
						"armor": [],
						"shields": [],
						"weapons": []
					},
					"equipped": true,
					"quantity": 1,
					"container": false,
					"takenFrom": "",
					"bookPublished": "",
					"bookPublisher": "",
					"descriptionHTML": ""
				}
			],
			"load": 0,
			"name": "Lich",
			"race": "Lich",
			"rank": 0,
			"size": 0,
			"uuid": "883de6d3-ed1a-491c-8bcc-ed62bd26ef5e",
			"armor": [
				{
					"id": 0,
					"cost": 0,
					"name": "Magical armor ",
					"uuid": "",
					"armor": 6,
					"notes": "Magical armor ",
					"bookID": 0,
					"minStr": "Magical armor ",
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"isShield": false,
					"quantity": 1,
					"takenFrom": "",
					"coversArms": false,
					"coversFace": false,
					"coversHead": false,
					"coversLegs": false,
					"heavyArmor": false,
					"coversTorso": true,
					"bookPublished": "",
					"bookPublisher": "",
					"descriptionHTML": "",
					"equippedStrength": "d10",
					"equippedToughness": "15 (6)"
				}
			],
			"edges": [
				{
					"id": 252,
					"name": "Concentration",
					"note": "",
					"bookID": 9,
					"isHidden": false,
					"takenFrom": "",
					"description": "Double Duration of non-Instant powers.",
					"descriptionHTML": "<p><cite>Savage Worlds: Adventure Edition p.46</cite></p>",
					"customDescription": ""
				},
				{
					"id": 0,
					"name": "Level Headed (Imp)",
					"note": "",
					"bookID": 9,
					"isHidden": false,
					"takenFrom": "",
					"description": "",
					"descriptionHTML": "",
					"customDescription": ""
				},
				{
					"id": 263,
					"name": "Wizard",
					"note": "",
					"bookID": 9,
					"isHidden": false,
					"takenFrom": "",
					"description": "Spend 1 extra Power Point to change a spell’s Trapping.",
					"descriptionHTML": "<p><cite>Savage Worlds: Adventure Edition p.47</cite></p>",
					"customDescription": ""
				}
			],
			"image": "",
			"swade": false,
			"bookID": 9,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Athletics",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Common Knowledge",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Intimidation",
					"value": "d12",
					"bookID": 9,
					"isCore": false,
					"dieValue": 12,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d10",
					"bookID": 9,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				},
				{
					"mod": 2,
					"name": "Occult",
					"value": "d12",
					"bookID": 9,
					"isCore": false,
					"dieValue": 12,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Persuasion",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Spellcasting",
					"value": "d12",
					"bookID": 9,
					"isCore": false,
					"dieValue": 12,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 2,
			"fatigue": 0,
			"journal": [],
			"paceMod": 0,
			"parryHR": "6",
			"shields": [],
			"weapons": [],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Adventure Edition",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": true,
			"abilities": [
				{
					"from": "",
					"name": "Death Touch",
					"note": "Liches drain the lives of those around them with a touch. Instead of a normal attack, a lich may make a Touch Attack (page 108). Every raise on its Fighting roll automatically inflicts one Wound to its target.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p185",
					"description": "Liches drain the lives of those around them with a touch. Instead of a normal attack, a lich may make a Touch Attack (page 108). Every raise on its Fighting roll automatically inflicts one Wound to its target."
				},
				{
					"from": "",
					"name": "Spells",
					"note": "Liches have 50 Power Points and know most every spell available.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p185",
					"description": "Liches have 50 Power Points and know most every spell available."
				},
				{
					"from": "",
					"name": "Undead",
					"note": "+2 Toughness; +2 to recover from being Shaken; no additional damage from Called Shots; ignores 1 point of Wound penalties; doesn't breathe; immune to disease and poison.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p185",
					"description": "+2 Toughness; +2 to recover from being Shaken; no additional damage from Called Shots; ignores 1 point of Wound penalties; doesn't breathe; immune to disease and poison."
				},
				{
					"from": "",
					"name": "Zombie",
					"note": "Liches are necromancers first and foremost. The undead they raise through the zombie spell are permanent, and they may raise skeletons just like zombies as they choose. Some liches have built up entire armies of the undead at their disposal.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p185",
					"description": "Liches are necromancers first and foremost. The undead they raise through the zombie spell are permanent, and they may raise skeletons just like zombies as they choose. Some liches have built up entire armies of the undead at their disposal."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 6,
			"parryBase": 6,
			"sizeLabel": "0 (Normal)",
			"strainMax": 0,
			"woundsMax": 3,
			"appVersion": "3.2.3b010603",
			"armorValue": 6,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 2,
					"name": "smarts",
					"label": "Smarts",
					"value": "d12+2",
					"dieValue": 12
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d10",
					"dieValue": 10
				},
				{
					"mod": 0,
					"name": "strength",
					"label": "Strength",
					"value": "d10",
					"dieValue": 10
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d10",
					"dieValue": 10
				}
			],
			"background": "",
			"benniesMax": 2,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 6,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 3,
			"bookPrimary": true,
			"createdDate": "2019-01-23T02:09:38.000Z",
			"description": "Perhaps the most diabolical creature in any fantasy land is the lich-a necromancer so consumed with the black arts that he eventually becomes undead himself.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2019-01-23T02:11:11.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 2,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2018",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 7,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 15,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "15 (6)",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/swade/lich",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 9,
			"toughnessAsReadNoHeavy": "15 (6)",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 17,
		"gear": [],
		"name": "Lion",
		"pace": "8",
		"race": "",
		"tags": [
			"animal"
		],
		"type": "bestiary",
		"uuid": "d748c8d3-dc2e-4b1f-b0b0-9ab5cfa4b678",
		"armor": "0",
		"climb": -10,
		"edges": [],
		"flies": 0,
		"image": "http://news.bbcimg.co.uk/media/images/66231000/jpg/_66231792_z9340587-male_african_lion-spl.jpg",
		"parry": "6",
		"active": true,
		"skills": [
			{
				"Name": "Fighting",
				"Value": "d8"
			},
			{
				"Name": "Notice",
				"Value": "d8"
			}
		],
		"book_id": 2,
		"summary": "",
		"bookPage": "p138",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Bite or Claw",
				"Notes": " Str+d6."
			},
			{
				"Name": "Improved Frenzy",
				"Notes": " Lions may make two Fighting attacks each action at no penalty."
			},
			{
				"Name": "Low Light Vision",
				"Notes": " Lions ignore penalties for Dim and Dark lighting."
			},
			{
				"Name": "Pounce",
				"Notes": " Lions often pounce on their prey to best bring their mass and claws to bear. It can leap 1d6&#x201d; to gain +4 to its attack and damage. Its Parry is reduced by &#x2013;2 until its next action when performing the maneuver however."
			},
			{
				"Name": "Size +2",
				"Notes": " Male lions can weigh over 500 pounds."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "8",
		"attributes": {
			"Vigor": "d8",
			"Smarts": "d6 (A)",
			"Spirit": "d10",
			"Agility": "d8",
			"Strength": "d12"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "The kings of the jungle are fierce predators, particularly in open grassland where their prey cannot seek refuge.",
		"flying_pace": -10,
		"heavy_armor": false,
		"name_plural": "",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 17,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Lion",
			"race": "Lion",
			"rank": 0,
			"size": 2,
			"uuid": "d748c8d3-dc2e-4b1f-b0b0-9ab5cfa4b678",
			"armor": [],
			"edges": [],
			"image": "https://savaged.ushttp://news.bbcimg.co.uk/media/images/66231000/jpg/_66231792_z9340587-male_african_lion-spl.jpg",
			"swade": false,
			"bookID": 2,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d8",
					"bookID": 2,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d8",
					"bookID": 2,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": 2,
			"parryHR": "6",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Bite or Claw",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d6",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Bite or Claw",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d6",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d6+1d12",
							"parry_modifier": 0,
							"damage_original": "str+d6",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 0,
							"damageWithBrackets": "str+d6",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d6+1d12",
					"descriptionHTML": "",
					"damageDiceBasePlus": 0,
					"damageWithBrackets": "str+d6"
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Deluxe (EE)",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Bite or Claw",
					"note": "Str+d6.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p138",
					"description": "Str+d6."
				},
				{
					"from": "",
					"name": "Improved Frenzy",
					"note": "Lions may make two Fighting attacks each action at no penalty.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p138",
					"description": "Lions may make two Fighting attacks each action at no penalty."
				},
				{
					"from": "",
					"name": "Low Light Vision",
					"note": "Lions ignore penalties for Dim and Dark lighting.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p138",
					"description": "Lions ignore penalties for Dim and Dark lighting."
				},
				{
					"from": "",
					"name": "Pounce",
					"note": "Lions often pounce on their prey to best bring their mass and claws to bear. It can leap 1d6&#x201d; to gain +4 to its attack and damage. Its Parry is reduced by &#x2013;2 until its next action when performing the maneuver however.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p138",
					"description": "Lions often pounce on their prey to best bring their mass and claws to bear. It can leap 1d6&#x201d; to gain +4 to its attack and damage. Its Parry is reduced by &#x2013;2 until its next action when performing the maneuver however."
				},
				{
					"from": "",
					"name": "Size +2",
					"note": "Male lions can weigh over 500 pounds.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p138",
					"description": "Male lions can weigh over 500 pounds."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 8,
			"parryBase": 6,
			"sizeLabel": "2 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 0,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d6 (A)",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d10",
					"dieValue": 10
				},
				{
					"mod": 0,
					"name": "strength",
					"label": "Strength",
					"value": "d12",
					"dieValue": 12
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d8",
					"dieValue": 8
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 6,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2018-05-03T19:05:27.000Z",
			"description": "The kings of the jungle are fierce predators, particularly in open grassland where their prey cannot seek refuge.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2018-05-03T19:05:27.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 2,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2011",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 6,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 8,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "8",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/deluxe/lion",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 8,
			"toughnessAsReadNoHeavy": "8",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 655,
		"gear": [],
		"name": "Lion",
		"pace": "8",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "c652bbe6-19d5-431e-b517-daa1921e51b3",
		"armor": "0",
		"climb": 0,
		"edges": [
			"Frenzy (Imp)"
		],
		"flies": 0,
		"image": "http://news.bbcimg.co.uk/media/images/66231000/jpg/_66231792_z9340587-male_african_lion-spl.jpg",
		"parry": "6",
		"active": true,
		"skills": [
			{
				"Name": "Athletics",
				"Value": "d10"
			},
			{
				"Name": "Fighting",
				"Value": "d8"
			},
			{
				"Name": "Notice",
				"Value": "d8"
			},
			{
				"Name": "Stealth",
				"Value": "d10"
			}
		],
		"book_id": 9,
		"summary": "",
		"bookPage": "p185",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Bite/Claws",
				"Notes": "Str+d6."
			},
			{
				"Name": "Low Light Vision",
				"Notes": "Lions ignore penalties for Dim and Dark Illumination."
			},
			{
				"Name": "Pounce",
				"Notes": "Lions pounce on their prey to best bring their mass and claws to bear. If a lion can leap at least a few feet (1″ on the tabletop) and makes a Wild Attack, it adds +4 to its damage instead of +2."
			},
			{
				"Name": "Size 1",
				"Notes": "Typical males are about 400 pounds."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "7",
		"attributes": {
			"Vigor": "d8",
			"Smarts": "d6 (A)",
			"Spirit": "d10",
			"Agility": "d8",
			"Strength": "d12"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "The kings of the jungle are fierce predators, particularly in open grassland where their prey cannot seek refuge.",
		"flying_pace": 0,
		"heavy_armor": false,
		"name_plural": "Lions",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 655,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Lion",
			"race": "Lion",
			"rank": 0,
			"size": 1,
			"uuid": "c652bbe6-19d5-431e-b517-daa1921e51b3",
			"armor": [],
			"edges": [
				{
					"id": 0,
					"name": "Frenzy (Imp)",
					"note": "",
					"bookID": 9,
					"isHidden": false,
					"takenFrom": "",
					"description": "",
					"descriptionHTML": "",
					"customDescription": ""
				}
			],
			"image": "https://savaged.ushttp://news.bbcimg.co.uk/media/images/66231000/jpg/_66231792_z9340587-male_african_lion-spl.jpg",
			"swade": false,
			"bookID": 9,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Athletics",
					"value": "d10",
					"bookID": 9,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Stealth",
					"value": "d10",
					"bookID": 9,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": 2,
			"parryHR": "6",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Bite/Claws",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d6",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Bite/Claws",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d6",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d6+1d12",
							"parry_modifier": 0,
							"damage_original": "str+d6",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 0,
							"damageWithBrackets": "str+d6",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d6+1d12",
					"descriptionHTML": "",
					"damageDiceBasePlus": 0,
					"damageWithBrackets": "str+d6"
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Adventure Edition",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Bite/Claws",
					"note": "Str+d6.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p185",
					"description": "Str+d6."
				},
				{
					"from": "",
					"name": "Low Light Vision",
					"note": "Lions ignore penalties for Dim and Dark Illumination.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p185",
					"description": "Lions ignore penalties for Dim and Dark Illumination."
				},
				{
					"from": "",
					"name": "Pounce",
					"note": "Lions pounce on their prey to best bring their mass and claws to bear. If a lion can leap at least a few feet (1″ on the tabletop) and makes a Wild Attack, it adds +4 to its damage instead of +2.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p185",
					"description": "Lions pounce on their prey to best bring their mass and claws to bear. If a lion can leap at least a few feet (1″ on the tabletop) and makes a Wild Attack, it adds +4 to its damage instead of +2."
				},
				{
					"from": "",
					"name": "Size 1",
					"note": "Typical males are about 400 pounds.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p185",
					"description": "Typical males are about 400 pounds."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 8,
			"parryBase": 6,
			"sizeLabel": "1 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 0,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d6 (A)",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d10",
					"dieValue": 10
				},
				{
					"mod": 0,
					"name": "strength",
					"label": "Strength",
					"value": "d12",
					"dieValue": 12
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d8",
					"dieValue": 8
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 6,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2019-01-23T02:09:50.000Z",
			"description": "The kings of the jungle are fierce predators, particularly in open grassland where their prey cannot seek refuge.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2019-01-23T02:09:50.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 1,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2018",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 6,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 7,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "7",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/swade/lion",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 7,
			"toughnessAsReadNoHeavy": "7",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 656,
		"gear": [
			"Typically a machine gun or flamethrower."
		],
		"name": "Mech",
		"pace": "10",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "520e5768-92bf-47ca-853c-f907353545c5",
		"armor": "4",
		"climb": 0,
		"edges": [
			"Alertness (Sensors)."
		],
		"flies": 0,
		"image": "",
		"parry": "5",
		"active": true,
		"skills": [
			{
				"Name": "Fighting",
				"Value": "d6"
			},
			{
				"Name": "Notice",
				"Value": "d10"
			},
			{
				"Name": "Shooting",
				"Value": "d8"
			}
		],
		"book_id": 9,
		"summary": "",
		"bookPage": "p185",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Construct",
				"Notes": "+2 to recover from being Shaken; ignores 1 point of Wound penalties; does not breathe or suffer from disease or poison."
			},
			{
				"Name": "Fearless",
				"Notes": "Mechs are immune to Fear and Intimidation, but may be smart enough to react to fear-causing situations aptly."
			},
			{
				"Name": "Night Vision",
				"Notes": "Sensor packages ignore penalties for Illumination."
			},
			{
				"Name": "Size 4 (Large)",
				"Notes": "The mech stands 15′ tall."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "14",
		"attributes": {
			"Vigor": "d8",
			"Smarts": "d6",
			"Spirit": "d4",
			"Agility": "d4",
			"Strength": "d12+4"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "These stats are for a 12-foot-tall mechanized sentinel, as found in a hard scifi campaign. This is a light patrol-style platform with reasonable intelligence, a sensor package, and high maneuverability. Larger mechs outfitted for battle have substantially more armor, are larger, and have more specialized weaponry.",
		"flying_pace": 0,
		"heavy_armor": false,
		"name_plural": "Mechs",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 656,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [
				{
					"id": 0,
					"cost": 0,
					"name": "Typically a machine gun or flamethrower.",
					"uuid": "",
					"notes": "",
					"bookID": 0,
					"weight": 0,
					"costBuy": 0,
					"summary": "",
					"contains": {
						"gear": [],
						"armor": [],
						"shields": [],
						"weapons": []
					},
					"equipped": true,
					"quantity": 1,
					"container": false,
					"takenFrom": "",
					"bookPublished": "",
					"bookPublisher": "",
					"descriptionHTML": ""
				}
			],
			"load": 0,
			"name": "Mech",
			"race": "Mech",
			"rank": 0,
			"size": 4,
			"uuid": "520e5768-92bf-47ca-853c-f907353545c5",
			"armor": [],
			"edges": [
				{
					"id": 0,
					"name": "Alertness (Sensors).",
					"note": "",
					"bookID": 9,
					"isHidden": false,
					"takenFrom": "",
					"description": "",
					"descriptionHTML": "",
					"customDescription": ""
				}
			],
			"image": "",
			"swade": false,
			"bookID": 9,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d10",
					"bookID": 9,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Shooting",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": 4,
			"parryHR": "5",
			"shields": [],
			"weapons": [],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Adventure Edition",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Construct",
					"note": "+2 to recover from being Shaken; ignores 1 point of Wound penalties; does not breathe or suffer from disease or poison.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p185",
					"description": "+2 to recover from being Shaken; ignores 1 point of Wound penalties; does not breathe or suffer from disease or poison."
				},
				{
					"from": "",
					"name": "Fearless",
					"note": "Mechs are immune to Fear and Intimidation, but may be smart enough to react to fear-causing situations aptly.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p185",
					"description": "Mechs are immune to Fear and Intimidation, but may be smart enough to react to fear-causing situations aptly."
				},
				{
					"from": "",
					"name": "Night Vision",
					"note": "Sensor packages ignore penalties for Illumination.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p185",
					"description": "Sensor packages ignore penalties for Illumination."
				},
				{
					"from": "",
					"name": "Size 4 (Large)",
					"note": "The mech stands 15′ tall.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p185",
					"description": "The mech stands 15′ tall."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 10,
			"parryBase": 5,
			"sizeLabel": "4 (Large)",
			"strainMax": 0,
			"woundsMax": 1,
			"appVersion": "3.2.3b010603",
			"armorValue": 4,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d4",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d4",
					"dieValue": 4
				},
				{
					"mod": 4,
					"name": "strength",
					"label": "Strength",
					"value": "d12+4",
					"dieValue": 12
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d8",
					"dieValue": 8
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 5,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2019-01-23T02:09:59.000Z",
			"description": "These stats are for a 12-foot-tall mechanized sentinel, as found in a hard scifi campaign. This is a light patrol-style platform with reasonable intelligence, a sensor package, and high maneuverability. Larger mechs outfitted for battle have substantially more armor, are larger, and have more specialized weaponry.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2019-01-23T02:09:59.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 4,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2018",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 6,
			"unarmoredHero": false,
			"woundsSizeMod": 1,
			"naturalIsHeavy": false,
			"toughnessTotal": 14,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "14 (4)",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/swade/mech",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 10,
			"toughnessAsReadNoHeavy": "14 (4)",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 19,
		"gear": [
			"Typically a machine gun or flamethrower."
		],
		"name": "Mech (Sentinel)",
		"pace": "10",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "b805e12e-6dea-4bda-2951-76d8820f812a",
		"armor": "4",
		"climb": -10,
		"edges": [],
		"flies": 0,
		"image": "",
		"parry": "5",
		"active": true,
		"skills": [
			{
				"Name": "Fighting",
				"Value": "d6"
			},
			{
				"Name": "Notice",
				"Value": "d10"
			},
			{
				"Name": "Shooting",
				"Value": "d8"
			}
		],
		"book_id": 2,
		"summary": "",
		"bookPage": "p138",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Armor+4",
				"Notes": " "
			},
			{
				"Name": "Construct",
				"Notes": " +2 to recover from being Shaken; called shots do no extra damage; does not suffer from disease or poison."
			},
			{
				"Name": "Fearless",
				"Notes": " Mechs are immune to fear and Intimidation, but may be smart enough to react to fear-causing situations appropriately."
			},
			{
				"Name": "Sensors",
				"Notes": " Sentinel mechs are equipped with sensor packages that halve penalties for darkness, can detect sounds, or record conversations via directional microphones."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "10",
		"attributes": {
			"Vigor": "d8",
			"Smarts": "d6",
			"Spirit": "d4",
			"Agility": "d4",
			"Strength": "d6"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "The stats below are for a 12' high mechanized sentinel such as might be found in a typical hard sci-fi campaign. This is a light patrol-style platform with reasonable intelligence, a sensor package, and high maneuverability.",
		"flying_pace": -10,
		"heavy_armor": false,
		"name_plural": "",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 19,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [
				{
					"id": 0,
					"cost": 0,
					"name": "Typically a machine gun or flamethrower.",
					"uuid": "",
					"notes": "",
					"bookID": 0,
					"weight": 0,
					"costBuy": 0,
					"summary": "",
					"contains": {
						"gear": [],
						"armor": [],
						"shields": [],
						"weapons": []
					},
					"equipped": true,
					"quantity": 1,
					"container": false,
					"takenFrom": "",
					"bookPublished": "",
					"bookPublisher": "",
					"descriptionHTML": ""
				}
			],
			"load": 0,
			"name": "Mech (Sentinel)",
			"race": "Mech (Sentinel)",
			"rank": 0,
			"size": 0,
			"uuid": "b805e12e-6dea-4bda-2951-76d8820f812a",
			"armor": [],
			"edges": [],
			"image": "",
			"swade": false,
			"bookID": 2,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d6",
					"bookID": 2,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d10",
					"bookID": 2,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Shooting",
					"value": "d8",
					"bookID": 2,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": 4,
			"parryHR": "5",
			"shields": [],
			"weapons": [],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Deluxe (EE)",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Armor+4",
					"note": "",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p138",
					"description": ""
				},
				{
					"from": "",
					"name": "Construct",
					"note": "+2 to recover from being Shaken; called shots do no extra damage; does not suffer from disease or poison.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p138",
					"description": "+2 to recover from being Shaken; called shots do no extra damage; does not suffer from disease or poison."
				},
				{
					"from": "",
					"name": "Fearless",
					"note": "Mechs are immune to fear and Intimidation, but may be smart enough to react to fear-causing situations appropriately.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p138",
					"description": "Mechs are immune to fear and Intimidation, but may be smart enough to react to fear-causing situations appropriately."
				},
				{
					"from": "",
					"name": "Sensors",
					"note": "Sentinel mechs are equipped with sensor packages that halve penalties for darkness, can detect sounds, or record conversations via directional microphones.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p138",
					"description": "Sentinel mechs are equipped with sensor packages that halve penalties for darkness, can detect sounds, or record conversations via directional microphones."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 10,
			"parryBase": 5,
			"sizeLabel": "0 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 4,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d4",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d4",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "strength",
					"label": "Strength",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d8",
					"dieValue": 8
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 5,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2018-05-03T19:05:27.000Z",
			"description": "The stats below are for a 12' high mechanized sentinel such as might be found in a typical hard sci-fi campaign. This is a light patrol-style platform with reasonable intelligence, a sensor package, and high maneuverability.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2018-05-03T19:05:27.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 0,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2011",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 6,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 10,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "10 (4)",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/deluxe/mech-(sentinel)",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 6,
			"toughnessAsReadNoHeavy": "10 (4)",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 20,
		"gear": [
			"Leather armor (+1)",
			"spear (Str+d6",
			"Reach 1",
			"Parry+1)."
		],
		"name": "Minotaur",
		"pace": "8",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "99076f3d-2eae-4f76-2081-7aabab357fcf",
		"armor": "1",
		"climb": -10,
		"edges": [],
		"flies": 0,
		"image": "",
		"parry": "8",
		"active": true,
		"skills": [
			{
				"Name": "Fighting",
				"Value": "d10"
			},
			{
				"Name": "Intimidation",
				"Value": "d12"
			},
			{
				"Name": "Notice",
				"Value": "d10"
			},
			{
				"Name": "Throwing",
				"Value": "d6"
			}
		],
		"book_id": 2,
		"summary": "",
		"bookPage": "p139",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Horns",
				"Notes": " Str+d4"
			},
			{
				"Name": "Fleet-Footed",
				"Notes": " Minotaurs roll d10s instead of d6s when running."
			},
			{
				"Name": "Gore",
				"Notes": " Minotaurs use this maneuver to gore their opponents with their horns. If they can charge at least 6&#x201d; before attacking, they add +4 to their damage total."
			},
			{
				"Name": "Size +2",
				"Notes": " Minotaurs stand over 7' tall."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "11",
		"attributes": {
			"Vigor": "d12",
			"Smarts": "d6",
			"Spirit": "d8",
			"Agility": "d8",
			"Strength": "d12+2"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "Minotaurs stand over 7' tall and have massive, bull-like heads and horns. In many fantasy worlds, they are used as guardians of labyrinths. In others, they are simply another race of creatures occupying a fantastically savage setting. In all cases, they are fierce beasts eager for battle and the taste of their opponents' flesh.",
		"flying_pace": -10,
		"heavy_armor": false,
		"name_plural": "",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 20,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [
				{
					"id": 0,
					"cost": 0,
					"name": "Leather armor (+1)",
					"uuid": "",
					"notes": "",
					"bookID": 0,
					"weight": 0,
					"costBuy": 0,
					"summary": "",
					"contains": {
						"gear": [],
						"armor": [],
						"shields": [],
						"weapons": []
					},
					"equipped": true,
					"quantity": 1,
					"container": false,
					"takenFrom": "",
					"bookPublished": "",
					"bookPublisher": "",
					"descriptionHTML": ""
				},
				{
					"id": 0,
					"cost": 0,
					"name": "Reach 1",
					"uuid": "",
					"notes": "",
					"bookID": 0,
					"weight": 0,
					"costBuy": 0,
					"summary": "",
					"contains": {
						"gear": [],
						"armor": [],
						"shields": [],
						"weapons": []
					},
					"equipped": true,
					"quantity": 1,
					"container": false,
					"takenFrom": "",
					"bookPublished": "",
					"bookPublisher": "",
					"descriptionHTML": ""
				},
				{
					"id": 0,
					"cost": 0,
					"name": "Parry+1).",
					"uuid": "",
					"notes": "",
					"bookID": 0,
					"weight": 0,
					"costBuy": 0,
					"summary": "",
					"contains": {
						"gear": [],
						"armor": [],
						"shields": [],
						"weapons": []
					},
					"equipped": true,
					"quantity": 1,
					"container": false,
					"takenFrom": "",
					"bookPublished": "",
					"bookPublisher": "",
					"descriptionHTML": ""
				}
			],
			"load": 0,
			"name": "Minotaur",
			"race": "Minotaur",
			"rank": 0,
			"size": 2,
			"uuid": "99076f3d-2eae-4f76-2081-7aabab357fcf",
			"armor": [
				{
					"id": 0,
					"cost": 0,
					"name": "Leather Armor",
					"uuid": "",
					"armor": 1,
					"notes": "Leather Armor",
					"bookID": 0,
					"minStr": "Leather Armor",
					"weight": 2,
					"costBuy": 0,
					"equipped": true,
					"isShield": false,
					"quantity": 1,
					"takenFrom": "",
					"coversArms": false,
					"coversFace": false,
					"coversHead": false,
					"coversLegs": false,
					"heavyArmor": false,
					"coversTorso": true,
					"bookPublished": "",
					"bookPublisher": "",
					"descriptionHTML": "",
					"equippedStrength": "d12+2",
					"equippedToughness": "11 (1)"
				}
			],
			"edges": [],
			"image": "",
			"swade": false,
			"bookID": 2,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d10",
					"bookID": 2,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Intimidation",
					"value": "d12",
					"bookID": 2,
					"isCore": false,
					"dieValue": 12,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d10",
					"bookID": 2,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Throwing",
					"value": "d6",
					"bookID": 2,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": 2,
			"parryHR": "8",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Horns",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d4",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Horns",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d4",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d4+1d12",
							"parry_modifier": 0,
							"damage_original": "str+d4",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 2,
							"damageWithBrackets": "str+d4",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d4+1d12",
					"descriptionHTML": "",
					"damageDiceBasePlus": 2,
					"damageWithBrackets": "str+d4"
				},
				{
					"ap": 1,
					"id": 20,
					"rof": 1,
					"cost": 0,
					"name": "Spear",
					"uuid": "",
					"notes": "+1 Parry, Reach 1, Two Hands",
					"range": "3/6/12",
					"reach": 1,
					"shots": 0,
					"bookID": 0,
					"damage": "Str+d6",
					"innate": false,
					"minStr": "",
					"thrown": 1,
					"weight": 5,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 1,
							"rof": 1,
							"name": "Spear",
							"notes": "+1 Parry, Reach 1, Two Hands",
							"range": "3/6/12",
							"reach": 1,
							"shots": 0,
							"damage": "Str+d6",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d6+1d12",
							"parry_modifier": 0,
							"damage_original": "Str+d6",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 2,
							"damageWithBrackets": "Str+d6",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d6+1d12",
					"descriptionHTML": "",
					"damageDiceBasePlus": 2,
					"damageWithBrackets": "Str+d6"
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Deluxe (EE)",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Horns",
					"note": "Str+d4",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p139",
					"description": "Str+d4"
				},
				{
					"from": "",
					"name": "Fleet-Footed",
					"note": "Minotaurs roll d10s instead of d6s when running.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p139",
					"description": "Minotaurs roll d10s instead of d6s when running."
				},
				{
					"from": "",
					"name": "Gore",
					"note": "Minotaurs use this maneuver to gore their opponents with their horns. If they can charge at least 6&#x201d; before attacking, they add +4 to their damage total.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p139",
					"description": "Minotaurs use this maneuver to gore their opponents with their horns. If they can charge at least 6&#x201d; before attacking, they add +4 to their damage total."
				},
				{
					"from": "",
					"name": "Size +2",
					"note": "Minotaurs stand over 7' tall.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p139",
					"description": "Minotaurs stand over 7' tall."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 8,
			"parryBase": 8,
			"sizeLabel": "2 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 1,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 2,
					"name": "strength",
					"label": "Strength",
					"value": "d12+2",
					"dieValue": 12
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d12",
					"dieValue": 12
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 8,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2018-05-03T19:05:27.000Z",
			"description": "Minotaurs stand over 7' tall and have massive, bull-like heads and horns. In many fantasy worlds, they are used as guardians of labyrinths. In others, they are simply another race of creatures occupying a fantastically savage setting. In all cases, they are fierce beasts eager for battle and the taste of their opponents' flesh.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2018-05-03T19:05:27.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 2,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2011",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 8,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 11,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "11 (1)",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/deluxe/minotaur",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 10,
			"toughnessAsReadNoHeavy": "11 (1)",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 657,
		"gear": [
			"Leather armor (+1)",
			"spear (Str+d6, Reach 1, Parry+1)."
		],
		"name": "Minotaur",
		"pace": "8",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "56d23aa2-a0ef-4efa-19f1-fc00332543da",
		"armor": "1",
		"climb": 0,
		"edges": [
			"Fleet-Footed"
		],
		"flies": 0,
		"image": "",
		"parry": "7 or 8 (spe",
		"active": true,
		"skills": [
			{
				"Name": "Athletics",
				"Value": "d8"
			},
			{
				"Name": "Common Knowledge",
				"Value": "d6"
			},
			{
				"Name": "Fighting",
				"Value": "d10"
			},
			{
				"Name": "Intimidation",
				"Value": "d12"
			},
			{
				"Name": "Notice",
				"Value": "d10"
			},
			{
				"Name": "Persuasion",
				"Value": "d4"
			},
			{
				"Name": "Stealth",
				"Value": "d8"
			}
		],
		"book_id": 9,
		"summary": "",
		"bookPage": "p185",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Horns",
				"Notes": "Str+d4."
			},
			{
				"Name": "Resilient",
				"Notes": "Minotaurs can take one Wound before they're Incapacitated."
			},
			{
				"Name": "Size 3",
				"Notes": "Minotaurs stand over 7′ tall and have the mass of bulls."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "12",
		"attributes": {
			"Vigor": "d12",
			"Smarts": "d6",
			"Spirit": "d8",
			"Agility": "d8",
			"Strength": "d12+3"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "Minotaurs stand over seven feet tall and have massive, bull-like heads and horns. In many fantasy worlds, they are used as guardians of labyrinths. In others, they are simply another race of creatures occupying a fantastically savage land. In all cases, they are fierce beasts eager for battle and the taste of their opponents’ flesh.",
		"flying_pace": 0,
		"heavy_armor": false,
		"name_plural": "Minotaurs",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 657,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [
				{
					"id": 0,
					"cost": 0,
					"name": "Leather armor (+1)",
					"uuid": "",
					"notes": "",
					"bookID": 0,
					"weight": 0,
					"costBuy": 0,
					"summary": "",
					"contains": {
						"gear": [],
						"armor": [],
						"shields": [],
						"weapons": []
					},
					"equipped": true,
					"quantity": 1,
					"container": false,
					"takenFrom": "",
					"bookPublished": "",
					"bookPublisher": "",
					"descriptionHTML": ""
				}
			],
			"load": 0,
			"name": "Minotaur",
			"race": "Minotaur",
			"rank": 0,
			"size": 3,
			"uuid": "56d23aa2-a0ef-4efa-19f1-fc00332543da",
			"armor": [
				{
					"id": 0,
					"cost": 0,
					"name": "Leather Armor",
					"uuid": "",
					"armor": 1,
					"notes": "Leather Armor",
					"bookID": 0,
					"minStr": "Leather Armor",
					"weight": 2,
					"costBuy": 0,
					"equipped": true,
					"isShield": false,
					"quantity": 1,
					"takenFrom": "",
					"coversArms": false,
					"coversFace": false,
					"coversHead": false,
					"coversLegs": false,
					"heavyArmor": false,
					"coversTorso": true,
					"bookPublished": "",
					"bookPublisher": "",
					"descriptionHTML": "",
					"equippedStrength": "d12+3",
					"equippedToughness": "12 (1)"
				}
			],
			"edges": [
				{
					"id": 187,
					"name": "Fleet-Footed",
					"note": "",
					"bookID": 9,
					"isHidden": false,
					"takenFrom": "",
					"description": "Pace +2, increase running die one step",
					"descriptionHTML": "<p><cite>Savage Worlds: Adventure Edition p.39</cite></p>",
					"customDescription": ""
				}
			],
			"image": "",
			"swade": false,
			"bookID": 9,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Athletics",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Common Knowledge",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d10",
					"bookID": 9,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Intimidation",
					"value": "d12",
					"bookID": 9,
					"isCore": false,
					"dieValue": 12,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d10",
					"bookID": 9,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Persuasion",
					"value": "d4",
					"bookID": 9,
					"isCore": false,
					"dieValue": 4,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Stealth",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": 2,
			"parryHR": "7 or 8 (spe",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Horns",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d4",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Horns",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d4",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d4+1d12",
							"parry_modifier": 0,
							"damage_original": "str+d4",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 3,
							"damageWithBrackets": "str+d4",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d4+1d12",
					"descriptionHTML": "",
					"damageDiceBasePlus": 3,
					"damageWithBrackets": "str+d4"
				},
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "spear",
					"uuid": "",
					"notes": "parry+1.",
					"range": "Melee",
					"reach": 1,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d6",
					"innate": false,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "spear",
							"notes": "parry+1.",
							"range": "Melee",
							"reach": 1,
							"shots": 0,
							"damage": "str+d6",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d6+1d12",
							"parry_modifier": 0,
							"damage_original": "str+d6",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 3,
							"damageWithBrackets": "str+d6",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d6+1d12",
					"descriptionHTML": "",
					"damageDiceBasePlus": 3,
					"damageWithBrackets": "str+d6"
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Adventure Edition",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Horns",
					"note": "Str+d4.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p185",
					"description": "Str+d4."
				},
				{
					"from": "",
					"name": "Resilient",
					"note": "Minotaurs can take one Wound before they're Incapacitated.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p185",
					"description": "Minotaurs can take one Wound before they're Incapacitated."
				},
				{
					"from": "",
					"name": "Size 3",
					"note": "Minotaurs stand over 7′ tall and have the mass of bulls.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p185",
					"description": "Minotaurs stand over 7′ tall and have the mass of bulls."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 8,
			"parryBase": null,
			"sizeLabel": "3 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 1,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 3,
					"name": "strength",
					"label": "Strength",
					"value": "d12+3",
					"dieValue": 12
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d12",
					"dieValue": 12
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": null,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2019-01-23T02:10:07.000Z",
			"description": "Minotaurs stand over seven feet tall and have massive, bull-like heads and horns. In many fantasy worlds, they are used as guardians of labyrinths. In others, they are simply another race of creatures occupying a fantastically savage land. In all cases, they are fierce beasts eager for battle and the taste of their opponents' flesh.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2019-01-23T02:10:07.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 3,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2018",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 8,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 12,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "12 (1)",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/swade/minotaur",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 11,
			"toughnessAsReadNoHeavy": "12 (1)",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 21,
		"gear": [],
		"name": "Mule",
		"pace": "6",
		"race": "",
		"tags": [
			"animal"
		],
		"type": "bestiary",
		"uuid": "ba1b03ea-cf5d-4a5a-874f-e542dd11b9db",
		"armor": "0",
		"climb": -10,
		"edges": [],
		"flies": 0,
		"image": "http://www.lovelongears.com/curly_spring05.jpg",
		"parry": "2",
		"active": true,
		"skills": [
			{
				"Name": "Notice",
				"Value": "d4"
			}
		],
		"book_id": 2,
		"summary": "",
		"bookPage": "p139",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Fleet-Footed",
				"Notes": " Mules roll d10s instead of d6s when running."
			},
			{
				"Name": "Kick",
				"Notes": " Str."
			},
			{
				"Name": "Ornery",
				"Notes": " Mules are contrary creatures. Characters must subtract 1 from their Riding rolls when riding them."
			},
			{
				"Name": "Size +2",
				"Notes": " Mule sare stocky creatures weighing up to 1000 pounds."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "8",
		"attributes": {
			"Vigor": "d8",
			"Smarts": "d4 (A)",
			"Spirit": "d6",
			"Agility": "d4",
			"Strength": "d8"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "Mules are a cross between a donkey and a horse, and are usually used to haul heavy goods or pull wagons.",
		"flying_pace": -10,
		"heavy_armor": false,
		"name_plural": "",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 21,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Mule",
			"race": "Mule",
			"rank": 0,
			"size": 2,
			"uuid": "ba1b03ea-cf5d-4a5a-874f-e542dd11b9db",
			"armor": [],
			"edges": [],
			"image": "https://savaged.ushttp://www.lovelongears.com/curly_spring05.jpg",
			"swade": false,
			"bookID": 2,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Notice",
					"value": "d4",
					"bookID": 2,
					"isCore": false,
					"dieValue": 4,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": 0,
			"parryHR": "2",
			"shields": [],
			"weapons": [],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Deluxe (EE)",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Fleet-Footed",
					"note": "Mules roll d10s instead of d6s when running.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p139",
					"description": "Mules roll d10s instead of d6s when running."
				},
				{
					"from": "",
					"name": "Kick",
					"note": "Str.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p139",
					"description": "Str."
				},
				{
					"from": "",
					"name": "Ornery",
					"note": "Mules are contrary creatures. Characters must subtract 1 from their Riding rolls when riding them.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p139",
					"description": "Mules are contrary creatures. Characters must subtract 1 from their Riding rolls when riding them."
				},
				{
					"from": "",
					"name": "Size +2",
					"note": "Mule sare stocky creatures weighing up to 1000 pounds.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p139",
					"description": "Mule sare stocky creatures weighing up to 1000 pounds."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 6,
			"parryBase": 2,
			"sizeLabel": "2 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 0,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d4",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d4 (A)",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "strength",
					"label": "Strength",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d8",
					"dieValue": 8
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 2,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2018-05-03T19:05:27.000Z",
			"description": "Mules are a cross between a donkey and a horse, and are usually used to haul heavy goods or pull wagons.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2018-05-03T19:05:27.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 2,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2011",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 6,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 8,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "8",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/deluxe/mule",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 8,
			"toughnessAsReadNoHeavy": "8",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 658,
		"gear": [],
		"name": "Mule",
		"pace": "6",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "9ff40722-b538-404b-1836-097a2525af72",
		"armor": "0",
		"climb": 0,
		"edges": [
			"-"
		],
		"flies": 0,
		"image": "http://www.lovelongears.com/curly_spring05.jpg",
		"parry": "2",
		"active": true,
		"skills": [
			{
				"Name": "Athletics",
				"Value": "d4"
			},
			{
				"Name": "Notice",
				"Value": "d4"
			}
		],
		"book_id": 9,
		"summary": "",
		"bookPage": "p185",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Kick",
				"Notes": "Str."
			},
			{
				"Name": "Ornery",
				"Notes": "Mules are contrary creatures. Characters must subtract 1 from their Riding rolls when riding them."
			},
			{
				"Name": "Size 2",
				"Notes": "Mules are stocky creatures usually weighing under 1000 pounds."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "8",
		"attributes": {
			"Vigor": "d8",
			"Smarts": "d4 (A)",
			"Spirit": "d6",
			"Agility": "d4",
			"Strength": "d8"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "Mules are a cross between a donkey and a horse, and are usually used to haul heavy goods or pull wagons. Like any good pet, the GM should give the mule a little personality. The expression “stubborn as a mule” certainly comes to mind.",
		"flying_pace": 0,
		"heavy_armor": false,
		"name_plural": "Mules",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 658,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Mule",
			"race": "Mule",
			"rank": 0,
			"size": 2,
			"uuid": "9ff40722-b538-404b-1836-097a2525af72",
			"armor": [],
			"edges": [
				{
					"id": 0,
					"name": "-",
					"note": "",
					"bookID": 9,
					"isHidden": false,
					"takenFrom": "",
					"description": "",
					"descriptionHTML": "",
					"customDescription": ""
				}
			],
			"image": "https://savaged.ushttp://www.lovelongears.com/curly_spring05.jpg",
			"swade": false,
			"bookID": 9,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Athletics",
					"value": "d4",
					"bookID": 9,
					"isCore": false,
					"dieValue": 4,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d4",
					"bookID": 9,
					"isCore": false,
					"dieValue": 4,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": 0,
			"parryHR": "2",
			"shields": [],
			"weapons": [],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Adventure Edition",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Kick",
					"note": "Str.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p185",
					"description": "Str."
				},
				{
					"from": "",
					"name": "Ornery",
					"note": "Mules are contrary creatures. Characters must subtract 1 from their Riding rolls when riding them.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p185",
					"description": "Mules are contrary creatures. Characters must subtract 1 from their Riding rolls when riding them."
				},
				{
					"from": "",
					"name": "Size 2",
					"note": "Mules are stocky creatures usually weighing under 1000 pounds.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p185",
					"description": "Mules are stocky creatures usually weighing under 1000 pounds."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 6,
			"parryBase": 2,
			"sizeLabel": "2 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 0,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d4",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d4 (A)",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "strength",
					"label": "Strength",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d8",
					"dieValue": 8
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 2,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2019-01-23T02:10:23.000Z",
			"description": "Mules are a cross between a donkey and a horse, and are usually used to haul heavy goods or pull wagons. Like any good pet, the GM should give the mule a little personality. The expression \"stubborn as a mule\" certainly comes to mind.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2019-01-23T02:10:23.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 2,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2018",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 6,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 8,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "8",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/swade/mule",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 8,
			"toughnessAsReadNoHeavy": "8",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 22,
		"gear": [
			"Thick hides (+1)",
			"massive club (Str+d8)."
		],
		"name": "Ogre",
		"pace": "7",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "aa060ece-a955-4109-0c12-7ad0e1a34d76",
		"armor": "1",
		"climb": -10,
		"edges": [],
		"flies": 0,
		"image": "http://cdn.obsidianportal.com/assets/161371/Ogre_Warlord.jpg",
		"parry": "6",
		"active": true,
		"skills": [
			{
				"Name": "Fighting",
				"Value": "d8"
			},
			{
				"Name": "Intimidation",
				"Value": "d8"
			},
			{
				"Name": "Notice",
				"Value": "d4"
			},
			{
				"Name": "Throwing",
				"Value": "d6"
			}
		],
		"book_id": 2,
		"summary": "",
		"bookPage": "p139",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Size +3",
				"Notes": " Most ogres are over 8' tall with pot-bellies and massive arms and legs."
			},
			{
				"Name": "Sweep",
				"Notes": " May attack all adjacent characters at &#x2013;2."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "12",
		"attributes": {
			"Vigor": "d12",
			"Smarts": "d4",
			"Spirit": "d6",
			"Agility": "d6",
			"Strength": "d12+3"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "Ogres are kin to orcs and lesser giants. They are often taken in by orc clans, who respect the dumb brutes for their savagery and strength. Orcs often pit their &#x201c;pet&#x201d; ogres in savage combats against their rivals' ogres.",
		"flying_pace": -10,
		"heavy_armor": false,
		"name_plural": "",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 22,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [
				{
					"id": 0,
					"cost": 0,
					"name": "Thick hides (+1)",
					"uuid": "",
					"notes": "",
					"bookID": 0,
					"weight": 0,
					"costBuy": 0,
					"summary": "",
					"contains": {
						"gear": [],
						"armor": [],
						"shields": [],
						"weapons": []
					},
					"equipped": true,
					"quantity": 1,
					"container": false,
					"takenFrom": "",
					"bookPublished": "",
					"bookPublisher": "",
					"descriptionHTML": ""
				}
			],
			"load": 0,
			"name": "Ogre",
			"race": "Ogre",
			"rank": 0,
			"size": 3,
			"uuid": "aa060ece-a955-4109-0c12-7ad0e1a34d76",
			"armor": [
				{
					"id": 0,
					"cost": 0,
					"name": "Thick hides ",
					"uuid": "",
					"armor": 1,
					"notes": "Thick hides ",
					"bookID": 0,
					"minStr": "Thick hides ",
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"isShield": false,
					"quantity": 1,
					"takenFrom": "",
					"coversArms": false,
					"coversFace": false,
					"coversHead": false,
					"coversLegs": false,
					"heavyArmor": false,
					"coversTorso": true,
					"bookPublished": "",
					"bookPublisher": "",
					"descriptionHTML": "",
					"equippedStrength": "d12+3",
					"equippedToughness": "12 (1)"
				}
			],
			"edges": [],
			"image": "https://savaged.ushttp://cdn.obsidianportal.com/assets/161371/Ogre_Warlord.jpg",
			"swade": false,
			"bookID": 2,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d8",
					"bookID": 2,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Intimidation",
					"value": "d8",
					"bookID": 2,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d4",
					"bookID": 2,
					"isCore": false,
					"dieValue": 4,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Throwing",
					"value": "d6",
					"bookID": 2,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": 1,
			"parryHR": "6",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "massive club",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d8.",
					"innate": false,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "massive club",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d8.",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d12",
							"parry_modifier": 0,
							"damage_original": "str+d8.",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 3,
							"damageWithBrackets": "str+d8.",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d12",
					"descriptionHTML": "",
					"damageDiceBasePlus": 3,
					"damageWithBrackets": "str+d8."
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Deluxe (EE)",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Size +3",
					"note": "Most ogres are over 8' tall with pot-bellies and massive arms and legs.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p139",
					"description": "Most ogres are over 8' tall with pot-bellies and massive arms and legs."
				},
				{
					"from": "",
					"name": "Sweep",
					"note": "May attack all adjacent characters at &#x2013;2.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p139",
					"description": "May attack all adjacent characters at &#x2013;2."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 7,
			"parryBase": 6,
			"sizeLabel": "3 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 1,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d4",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 3,
					"name": "strength",
					"label": "Strength",
					"value": "d12+3",
					"dieValue": 12
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d12",
					"dieValue": 12
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 6,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2018-05-03T19:05:27.000Z",
			"description": "Ogres are kin to orcs and lesser giants. They are often taken in by orc clans, who respect the dumb brutes for their savagery and strength. Orcs often pit their &#x201c;pet&#x201d; ogres in savage combats against their rivals' ogres.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2018-05-03T19:05:27.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 3,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2011",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 8,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 12,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "12 (1)",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/deluxe/ogre",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 11,
			"toughnessAsReadNoHeavy": "12 (1)",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 659,
		"gear": [
			"Thick hides (+1)",
			"massive club (Str+d8)."
		],
		"name": "Ogre",
		"pace": "7",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "c3a22597-b61f-48f1-060d-48d6b6d5ca5e",
		"armor": "1",
		"climb": 0,
		"edges": [
			"Brute",
			"Sweep"
		],
		"flies": 0,
		"image": "http://cdn.obsidianportal.com/assets/161371/Ogre_Warlord.jpg",
		"parry": "6",
		"active": true,
		"skills": [
			{
				"Name": "Athletics",
				"Value": "d6"
			},
			{
				"Name": "Common Knowledge",
				"Value": "d6"
			},
			{
				"Name": "Fighting",
				"Value": "d8"
			},
			{
				"Name": "Intimidation",
				"Value": "d8"
			},
			{
				"Name": "Notice",
				"Value": "d4"
			},
			{
				"Name": "Persuasion",
				"Value": "d4"
			}
		],
		"book_id": 9,
		"summary": "",
		"bookPage": "p186",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Resilient",
				"Notes": "Ogres can take one Wound before they're Incapacitated."
			},
			{
				"Name": "Size 2",
				"Notes": "Most ogres are over 8′ tall with potbellies and massive arms and legs."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "11",
		"attributes": {
			"Vigor": "d12",
			"Smarts": "d4",
			"Spirit": "d6",
			"Agility": "d6",
			"Strength": "d12+2"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "Ogres are kin to orcs and lesser giants. They are often taken in by orc clans, who respect the dumb brutes for their savagery and strength. Orcs often pit their “pet” ogres in savage combats against their rivals’.",
		"flying_pace": 0,
		"heavy_armor": false,
		"name_plural": "Ogres",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 659,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [
				{
					"id": 0,
					"cost": 0,
					"name": "Thick hides (+1)",
					"uuid": "",
					"notes": "",
					"bookID": 0,
					"weight": 0,
					"costBuy": 0,
					"summary": "",
					"contains": {
						"gear": [],
						"armor": [],
						"shields": [],
						"weapons": []
					},
					"equipped": true,
					"quantity": 1,
					"container": false,
					"takenFrom": "",
					"bookPublished": "",
					"bookPublisher": "",
					"descriptionHTML": ""
				}
			],
			"load": 0,
			"name": "Ogre",
			"race": "Ogre",
			"rank": 0,
			"size": 2,
			"uuid": "c3a22597-b61f-48f1-060d-48d6b6d5ca5e",
			"armor": [
				{
					"id": 0,
					"cost": 0,
					"name": "Thick hides ",
					"uuid": "",
					"armor": 1,
					"notes": "Thick hides ",
					"bookID": 0,
					"minStr": "Thick hides ",
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"isShield": false,
					"quantity": 1,
					"takenFrom": "",
					"coversArms": false,
					"coversFace": false,
					"coversHead": false,
					"coversLegs": false,
					"heavyArmor": false,
					"coversTorso": true,
					"bookPublished": "",
					"bookPublisher": "",
					"descriptionHTML": "",
					"equippedStrength": "d12+2",
					"equippedToughness": "11 (1)"
				}
			],
			"edges": [
				{
					"id": 182,
					"name": "Brute",
					"note": "",
					"bookID": 9,
					"isHidden": false,
					"takenFrom": "",
					"description": "Link Athletics to Strength instead of Agility. This includes resisting Athletics Tests as well.",
					"descriptionHTML": "<p><cite>Savage Worlds: Adventure Edition p.38</cite></p>",
					"customDescription": ""
				},
				{
					"id": 236,
					"name": "Sweep",
					"note": "",
					"bookID": 9,
					"isHidden": false,
					"takenFrom": "",
					"description": "Fighting roll at –2 to hit all adjacent targets in weapon’s Reach, no more than once per turn.",
					"descriptionHTML": "<p><cite>Savage Worlds: Adventure Edition p.44</cite></p>",
					"customDescription": ""
				}
			],
			"image": "https://savaged.ushttp://cdn.obsidianportal.com/assets/161371/Ogre_Warlord.jpg",
			"swade": false,
			"bookID": 9,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Athletics",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Common Knowledge",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Intimidation",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d4",
					"bookID": 9,
					"isCore": false,
					"dieValue": 4,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Persuasion",
					"value": "d4",
					"bookID": 9,
					"isCore": false,
					"dieValue": 4,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": 1,
			"parryHR": "6",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "massive club",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d8.",
					"innate": false,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "massive club",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d8.",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d12",
							"parry_modifier": 0,
							"damage_original": "str+d8.",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 2,
							"damageWithBrackets": "str+d8.",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d12",
					"descriptionHTML": "",
					"damageDiceBasePlus": 2,
					"damageWithBrackets": "str+d8."
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Adventure Edition",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Resilient",
					"note": "Ogres can take one Wound before they're Incapacitated.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p186",
					"description": "Ogres can take one Wound before they're Incapacitated."
				},
				{
					"from": "",
					"name": "Size 2",
					"note": "Most ogres are over 8′ tall with potbellies and massive arms and legs.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p186",
					"description": "Most ogres are over 8′ tall with potbellies and massive arms and legs."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 7,
			"parryBase": 6,
			"sizeLabel": "2 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 1,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d4",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 2,
					"name": "strength",
					"label": "Strength",
					"value": "d12+2",
					"dieValue": 12
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d12",
					"dieValue": 12
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 6,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2019-01-23T02:10:40.000Z",
			"description": "Ogres are kin to orcs and lesser giants. They are often taken in by orc clans, who respect the dumb brutes for their savagery and strength. Orcs often pit their \"pet\" ogres in savage combats against their rivals'.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2019-01-23T02:10:40.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 2,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2018",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 8,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 11,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "11 (1)",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/swade/ogre",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 10,
			"toughnessAsReadNoHeavy": "11 (1)",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 24,
		"gear": [
			"Leather armor (+1)",
			"scimitar (Str+d8)."
		],
		"name": "Orc",
		"pace": "6",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "9b30285c-5a08-4c11-b6ae-9d97fb616d51",
		"armor": "1",
		"climb": -10,
		"edges": [],
		"flies": 0,
		"image": "https://pre00.deviantart.net/d675/th/pre/f/2015/068/7/9/orc_soldier_by_seraph777-d8l1wzc.jpg",
		"parry": "5",
		"active": true,
		"skills": [
			{
				"Name": "Fighting",
				"Value": "d6"
			},
			{
				"Name": "Intimidation",
				"Value": "d8"
			},
			{
				"Name": "Notice",
				"Value": "d6"
			},
			{
				"Name": "Shooting",
				"Value": "d6"
			},
			{
				"Name": "Stealth",
				"Value": "d6"
			},
			{
				"Name": "Throwing",
				"Value": "d6"
			}
		],
		"book_id": 2,
		"summary": "",
		"bookPage": "p139",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Infravision",
				"Notes": " Halves penalties for poor light vs. warm targets."
			},
			{
				"Name": "Size +1",
				"Notes": " Orcs are slightly larger than humans."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "8",
		"attributes": {
			"Vigor": "d8",
			"Smarts": "d4",
			"Spirit": "d6",
			"Agility": "d6",
			"Strength": "d8"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "Orcs are savage, green-skinned humanoids with pig-like features, including snouts and sometimes even tusks. They have foul temperaments, and rarely take prisoners.",
		"flying_pace": -10,
		"heavy_armor": false,
		"name_plural": "",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 24,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [
				{
					"id": 0,
					"cost": 0,
					"name": "Leather armor (+1)",
					"uuid": "",
					"notes": "",
					"bookID": 0,
					"weight": 0,
					"costBuy": 0,
					"summary": "",
					"contains": {
						"gear": [],
						"armor": [],
						"shields": [],
						"weapons": []
					},
					"equipped": true,
					"quantity": 1,
					"container": false,
					"takenFrom": "",
					"bookPublished": "",
					"bookPublisher": "",
					"descriptionHTML": ""
				}
			],
			"load": 0,
			"name": "Orc",
			"race": "Orc",
			"rank": 0,
			"size": 1,
			"uuid": "9b30285c-5a08-4c11-b6ae-9d97fb616d51",
			"armor": [
				{
					"id": 0,
					"cost": 0,
					"name": "Leather Armor",
					"uuid": "",
					"armor": 1,
					"notes": "Leather Armor",
					"bookID": 0,
					"minStr": "Leather Armor",
					"weight": 2,
					"costBuy": 0,
					"equipped": true,
					"isShield": false,
					"quantity": 1,
					"takenFrom": "",
					"coversArms": false,
					"coversFace": false,
					"coversHead": false,
					"coversLegs": false,
					"heavyArmor": false,
					"coversTorso": true,
					"bookPublished": "",
					"bookPublisher": "",
					"descriptionHTML": "",
					"equippedStrength": "d8",
					"equippedToughness": "8 (1)"
				}
			],
			"edges": [],
			"image": "https://savaged.ushttps://pre00.deviantart.net/d675/th/pre/f/2015/068/7/9/orc_soldier_by_seraph777-d8l1wzc.jpg",
			"swade": false,
			"bookID": 2,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d6",
					"bookID": 2,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Intimidation",
					"value": "d8",
					"bookID": 2,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d6",
					"bookID": 2,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Shooting",
					"value": "d6",
					"bookID": 2,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Stealth",
					"value": "d6",
					"bookID": 2,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Throwing",
					"value": "d6",
					"bookID": 2,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": 0,
			"parryHR": "5",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "scimitar",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d8.",
					"innate": false,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "scimitar",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d8.",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d8",
							"parry_modifier": 0,
							"damage_original": "str+d8.",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 0,
							"damageWithBrackets": "str+d8.",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d8",
					"descriptionHTML": "",
					"damageDiceBasePlus": 0,
					"damageWithBrackets": "str+d8."
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Deluxe (EE)",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Infravision",
					"note": "Halves penalties for poor light vs. warm targets.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p139",
					"description": "Halves penalties for poor light vs. warm targets."
				},
				{
					"from": "",
					"name": "Size +1",
					"note": "Orcs are slightly larger than humans.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p139",
					"description": "Orcs are slightly larger than humans."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 6,
			"parryBase": 5,
			"sizeLabel": "1 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 1,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d4",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "strength",
					"label": "Strength",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d8",
					"dieValue": 8
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 5,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2018-05-03T19:05:27.000Z",
			"description": "Orcs are savage, green-skinned humanoids with pig-like features, including snouts and sometimes even tusks. They have foul temperaments, and rarely take prisoners.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2018-05-17T01:58:22.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 1,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2011",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 6,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 8,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "8 (1)",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/deluxe/orc",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 7,
			"toughnessAsReadNoHeavy": "8 (1)",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 660,
		"gear": [
			"Leather armor (+1)",
			"scimitar (Str+d8)."
		],
		"name": "Orc",
		"pace": "6",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "271d6da2-6746-41de-b3db-b309cb98e830",
		"armor": "1",
		"climb": 0,
		"edges": [
			"Brute."
		],
		"flies": 0,
		"image": "https://pre00.deviantart.net/d675/th/pre/f/2015/068/7/9/orc_soldier_by_seraph777-d8l1wzc.jpg",
		"parry": "5",
		"active": true,
		"skills": [
			{
				"Name": "Athletics",
				"Value": "d6"
			},
			{
				"Name": "Common Knowledge",
				"Value": "d6"
			},
			{
				"Name": "Fighting",
				"Value": "d6"
			},
			{
				"Name": "Intimidation",
				"Value": "d8"
			},
			{
				"Name": "Notice",
				"Value": "d6"
			},
			{
				"Name": "Persuasion",
				"Value": "d4"
			},
			{
				"Name": "Shooting",
				"Value": "d6"
			},
			{
				"Name": "Stealth",
				"Value": "d6"
			}
		],
		"book_id": 9,
		"summary": "",
		"bookPage": "p186",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Infravision",
				"Notes": "Halve penalties for Illumination when attacking warm targets."
			},
			{
				"Name": "Size 1",
				"Notes": "Orcs are slightly larger than humans."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "8",
		"attributes": {
			"Vigor": "d8",
			"Smarts": "d4",
			"Spirit": "d6",
			"Agility": "d6",
			"Strength": "d8"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "Orcs are savage, green-skinned humanoids with pig-like features, including snouts and sometimes even tusks. They have foul temperaments and rarely take prisoners.",
		"flying_pace": 0,
		"heavy_armor": false,
		"name_plural": "Orcs",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 660,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [
				{
					"id": 0,
					"cost": 0,
					"name": "Leather armor (+1)",
					"uuid": "",
					"notes": "",
					"bookID": 0,
					"weight": 0,
					"costBuy": 0,
					"summary": "",
					"contains": {
						"gear": [],
						"armor": [],
						"shields": [],
						"weapons": []
					},
					"equipped": true,
					"quantity": 1,
					"container": false,
					"takenFrom": "",
					"bookPublished": "",
					"bookPublisher": "",
					"descriptionHTML": ""
				}
			],
			"load": 0,
			"name": "Orc",
			"race": "Orc",
			"rank": 0,
			"size": 1,
			"uuid": "271d6da2-6746-41de-b3db-b309cb98e830",
			"armor": [
				{
					"id": 0,
					"cost": 0,
					"name": "Leather Armor",
					"uuid": "",
					"armor": 1,
					"notes": "Leather Armor",
					"bookID": 0,
					"minStr": "Leather Armor",
					"weight": 2,
					"costBuy": 0,
					"equipped": true,
					"isShield": false,
					"quantity": 1,
					"takenFrom": "",
					"coversArms": false,
					"coversFace": false,
					"coversHead": false,
					"coversLegs": false,
					"heavyArmor": false,
					"coversTorso": true,
					"bookPublished": "",
					"bookPublisher": "",
					"descriptionHTML": "",
					"equippedStrength": "d8",
					"equippedToughness": "8 (1)"
				}
			],
			"edges": [
				{
					"id": 0,
					"name": "Brute.",
					"note": "",
					"bookID": 9,
					"isHidden": false,
					"takenFrom": "",
					"description": "",
					"descriptionHTML": "",
					"customDescription": ""
				}
			],
			"image": "https://savaged.ushttps://pre00.deviantart.net/d675/th/pre/f/2015/068/7/9/orc_soldier_by_seraph777-d8l1wzc.jpg",
			"swade": false,
			"bookID": 9,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Athletics",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Common Knowledge",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Intimidation",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Persuasion",
					"value": "d4",
					"bookID": 9,
					"isCore": false,
					"dieValue": 4,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Shooting",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Stealth",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": 0,
			"parryHR": "5",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "scimitar",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d8.",
					"innate": false,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "scimitar",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d8.",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d8",
							"parry_modifier": 0,
							"damage_original": "str+d8.",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 0,
							"damageWithBrackets": "str+d8.",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d8",
					"descriptionHTML": "",
					"damageDiceBasePlus": 0,
					"damageWithBrackets": "str+d8."
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Adventure Edition",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Infravision",
					"note": "Halve penalties for Illumination when attacking warm targets.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p186",
					"description": "Halve penalties for Illumination when attacking warm targets."
				},
				{
					"from": "",
					"name": "Size 1",
					"note": "Orcs are slightly larger than humans.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p186",
					"description": "Orcs are slightly larger than humans."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 6,
			"parryBase": 5,
			"sizeLabel": "1 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 1,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d4",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "strength",
					"label": "Strength",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d8",
					"dieValue": 8
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 5,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2019-01-23T02:10:48.000Z",
			"description": "Orcs are savage, green-skinned humanoids with pig-like features, including snouts and sometimes even tusks. They have foul temperaments and rarely take prisoners.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2019-01-23T02:10:48.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 1,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2018",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 6,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 8,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "8 (1)",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/swade/orc",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 7,
			"toughnessAsReadNoHeavy": "8 (1)",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 23,
		"gear": [
			"Plate chest plate (+3)",
			"chain arms and legs (+2)",
			"battle axe (Str+d10)."
		],
		"name": "Orc Chieftan",
		"pace": "6",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "898e64db-ec97-47f7-9024-2c5c77e20dbf",
		"armor": "3",
		"climb": -10,
		"edges": [],
		"flies": 0,
		"image": "http://fc09.deviantart.net/fs71/f/2013/098/0/0/orc_chieftain_final_by_director_16-d60veug.jpg",
		"parry": "8",
		"active": true,
		"skills": [
			{
				"Name": "Fighting",
				"Value": "d12"
			},
			{
				"Name": "Intimidation",
				"Value": "d10"
			},
			{
				"Name": "Notice",
				"Value": "d6"
			},
			{
				"Name": "Shooting",
				"Value": "d8"
			},
			{
				"Name": "Stealth",
				"Value": "d6"
			},
			{
				"Name": "Throwing",
				"Value": "d8"
			}
		],
		"book_id": 2,
		"summary": "",
		"bookPage": "p139-p140",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": 1,
		"abilities": [
			{
				"Name": "Infravision",
				"Notes": " Halves penalties for poor light vs. warm targets."
			},
			{
				"Name": "Size +1",
				"Notes": " Orcs are slightly larger than humans."
			},
			{
				"Name": "Sweep",
				"Notes": " May attack all adjacent characters at &#x2013;2 penalty."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "11",
		"attributes": {
			"Vigor": "d10",
			"Smarts": "d6",
			"Spirit": "d6",
			"Agility": "d8",
			"Strength": "d10"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "The leader of small orc clans is always the most deadly brute in the bunch. Orc chieftains generally have a magical item or two in settings where such things are relatively common (most &#x201c;swords and sorcery&#x201d; worlds).",
		"flying_pace": -10,
		"heavy_armor": false,
		"name_plural": "",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 23,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [
				{
					"id": 0,
					"cost": 0,
					"name": "Plate chest plate (+3)",
					"uuid": "",
					"notes": "",
					"bookID": 0,
					"weight": 0,
					"costBuy": 0,
					"summary": "",
					"contains": {
						"gear": [],
						"armor": [],
						"shields": [],
						"weapons": []
					},
					"equipped": true,
					"quantity": 1,
					"container": false,
					"takenFrom": "",
					"bookPublished": "",
					"bookPublisher": "",
					"descriptionHTML": ""
				},
				{
					"id": 0,
					"cost": 0,
					"name": "chain arms and legs (+2)",
					"uuid": "",
					"notes": "",
					"bookID": 0,
					"weight": 0,
					"costBuy": 0,
					"summary": "",
					"contains": {
						"gear": [],
						"armor": [],
						"shields": [],
						"weapons": []
					},
					"equipped": true,
					"quantity": 1,
					"container": false,
					"takenFrom": "",
					"bookPublished": "",
					"bookPublisher": "",
					"descriptionHTML": ""
				}
			],
			"load": 0,
			"name": "Orc Chieftan",
			"race": "Orc Chieftan",
			"rank": 0,
			"size": 1,
			"uuid": "898e64db-ec97-47f7-9024-2c5c77e20dbf",
			"armor": [
				{
					"id": 0,
					"cost": 0,
					"name": "Plate chest plate ",
					"uuid": "",
					"armor": 3,
					"notes": "Plate chest plate ",
					"bookID": 0,
					"minStr": "Plate chest plate ",
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"isShield": false,
					"quantity": 1,
					"takenFrom": "",
					"coversArms": false,
					"coversFace": false,
					"coversHead": false,
					"coversLegs": false,
					"heavyArmor": false,
					"coversTorso": true,
					"bookPublished": "",
					"bookPublisher": "",
					"descriptionHTML": "",
					"equippedStrength": "d10",
					"equippedToughness": "11 (3)"
				},
				{
					"id": 0,
					"cost": 0,
					"name": "chain arms and legs ",
					"uuid": "",
					"armor": 2,
					"notes": "chain arms and legs ",
					"bookID": 0,
					"minStr": "chain arms and legs ",
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"isShield": false,
					"quantity": 1,
					"takenFrom": "",
					"coversArms": false,
					"coversFace": false,
					"coversHead": false,
					"coversLegs": false,
					"heavyArmor": false,
					"coversTorso": true,
					"bookPublished": "",
					"bookPublisher": "",
					"descriptionHTML": "",
					"equippedStrength": "d10",
					"equippedToughness": "11 (3)"
				}
			],
			"edges": [],
			"image": "https://savaged.ushttp://fc09.deviantart.net/fs71/f/2013/098/0/0/orc_chieftain_final_by_director_16-d60veug.jpg",
			"swade": false,
			"bookID": 2,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d12",
					"bookID": 2,
					"isCore": false,
					"dieValue": 12,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Intimidation",
					"value": "d10",
					"bookID": 2,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d6",
					"bookID": 2,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Shooting",
					"value": "d8",
					"bookID": 2,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Stealth",
					"value": "d6",
					"bookID": 2,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Throwing",
					"value": "d8",
					"bookID": 2,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 2,
			"fatigue": 0,
			"journal": [],
			"paceMod": 0,
			"parryHR": "8",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 13,
					"rof": 0,
					"cost": 0,
					"name": "Battle Axe",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "Str+d8",
					"innate": false,
					"minStr": "",
					"thrown": false,
					"weight": 10,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Battle Axe",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "Str+d8",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d8+1d10",
							"parry_modifier": 0,
							"damage_original": "Str+d8",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 0,
							"damageWithBrackets": "Str+d8",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d8+1d10",
					"descriptionHTML": "",
					"damageDiceBasePlus": 0,
					"damageWithBrackets": "Str+d8"
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Deluxe (EE)",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": true,
			"abilities": [
				{
					"from": "",
					"name": "Infravision",
					"note": "Halves penalties for poor light vs. warm targets.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p139-p140",
					"description": "Halves penalties for poor light vs. warm targets."
				},
				{
					"from": "",
					"name": "Size +1",
					"note": "Orcs are slightly larger than humans.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p139-p140",
					"description": "Orcs are slightly larger than humans."
				},
				{
					"from": "",
					"name": "Sweep",
					"note": "May attack all adjacent characters at &#x2013;2 penalty.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p139-p140",
					"description": "May attack all adjacent characters at &#x2013;2 penalty."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 6,
			"parryBase": 8,
			"sizeLabel": "1 (Normal)",
			"strainMax": 0,
			"woundsMax": 3,
			"appVersion": "3.2.3b010603",
			"armorValue": 3,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "strength",
					"label": "Strength",
					"value": "d10",
					"dieValue": 10
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d10",
					"dieValue": 10
				}
			],
			"background": "",
			"benniesMax": 2,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 8,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 3,
			"bookPrimary": true,
			"createdDate": "2018-05-03T19:05:27.000Z",
			"description": "The leader of small orc clans is always the most deadly brute in the bunch. Orc chieftains generally have a magical item or two in settings where such things are relatively common (most &#x201c;swords and sorcery&#x201d; worlds).",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2018-05-03T19:05:27.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 1,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2011",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 7,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 11,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "11 (3)",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/deluxe/orc-chieftan",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 8,
			"toughnessAsReadNoHeavy": "11 (3)",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 661,
		"gear": [
			"Plate corselet (+4)",
			"chain arms and legs (+3)",
			"great axe (Str+d10)."
		],
		"name": "Orc, Chieftain",
		"pace": "6",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "b4c537c7-e54d-45f9-3a2f-970c9001bed4",
		"armor": "4",
		"climb": 0,
		"edges": [
			"Brute",
			"Sweep"
		],
		"flies": 0,
		"image": "",
		"parry": "8",
		"active": true,
		"skills": [
			{
				"Name": "Athletics",
				"Value": "d8"
			},
			{
				"Name": "Common Knowledge",
				"Value": "d8"
			},
			{
				"Name": "Fighting",
				"Value": "d12"
			},
			{
				"Name": "Intimidation",
				"Value": "d10"
			},
			{
				"Name": "Notice",
				"Value": "d6"
			},
			{
				"Name": "Persuasion",
				"Value": "d6"
			},
			{
				"Name": "Shooting",
				"Value": "d8"
			},
			{
				"Name": "Stealth",
				"Value": "d6"
			}
		],
		"book_id": 9,
		"summary": "",
		"bookPage": "p186",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": 1,
		"abilities": [
			{
				"Name": "Infravision",
				"Notes": "Halve penalties for Illumination when attacking warm targets."
			},
			{
				"Name": "Size 1",
				"Notes": "Orcs are slightly larger than humans."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "12",
		"attributes": {
			"Vigor": "d10",
			"Smarts": "d6",
			"Spirit": "d6",
			"Agility": "d8",
			"Strength": "d10"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "The leader of a small orc clan is always the most deadly brute in the bunch. Orc chieftains generally have a magical item or two in settings where such things are relatively common (most “swords & sorcery” worlds).",
		"flying_pace": 0,
		"heavy_armor": false,
		"name_plural": "Orc, Chieftains",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 661,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [
				{
					"id": 0,
					"cost": 0,
					"name": "Plate corselet (+4)",
					"uuid": "",
					"notes": "",
					"bookID": 0,
					"weight": 0,
					"costBuy": 0,
					"summary": "",
					"contains": {
						"gear": [],
						"armor": [],
						"shields": [],
						"weapons": []
					},
					"equipped": true,
					"quantity": 1,
					"container": false,
					"takenFrom": "",
					"bookPublished": "",
					"bookPublisher": "",
					"descriptionHTML": ""
				},
				{
					"id": 0,
					"cost": 0,
					"name": "chain arms and legs (+3)",
					"uuid": "",
					"notes": "",
					"bookID": 0,
					"weight": 0,
					"costBuy": 0,
					"summary": "",
					"contains": {
						"gear": [],
						"armor": [],
						"shields": [],
						"weapons": []
					},
					"equipped": true,
					"quantity": 1,
					"container": false,
					"takenFrom": "",
					"bookPublished": "",
					"bookPublisher": "",
					"descriptionHTML": ""
				}
			],
			"load": 0,
			"name": "Orc, Chieftain",
			"race": "Orc, Chieftain",
			"rank": 0,
			"size": 1,
			"uuid": "b4c537c7-e54d-45f9-3a2f-970c9001bed4",
			"armor": [
				{
					"id": 0,
					"cost": 0,
					"name": "Plate Corselet",
					"uuid": "",
					"armor": 4,
					"notes": "Plate Corselet",
					"bookID": 0,
					"minStr": "Plate Corselet",
					"weight": 30,
					"costBuy": 0,
					"equipped": true,
					"isShield": false,
					"quantity": 1,
					"takenFrom": "",
					"coversArms": false,
					"coversFace": false,
					"coversHead": false,
					"coversLegs": false,
					"heavyArmor": false,
					"coversTorso": true,
					"bookPublished": "",
					"bookPublisher": "",
					"descriptionHTML": "",
					"equippedStrength": "d10",
					"equippedToughness": "12 (4)"
				},
				{
					"id": 0,
					"cost": 0,
					"name": "chain arms and legs ",
					"uuid": "",
					"armor": 3,
					"notes": "chain arms and legs ",
					"bookID": 0,
					"minStr": "chain arms and legs ",
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"isShield": false,
					"quantity": 1,
					"takenFrom": "",
					"coversArms": false,
					"coversFace": false,
					"coversHead": false,
					"coversLegs": false,
					"heavyArmor": false,
					"coversTorso": true,
					"bookPublished": "",
					"bookPublisher": "",
					"descriptionHTML": "",
					"equippedStrength": "d10",
					"equippedToughness": "12 (4)"
				}
			],
			"edges": [
				{
					"id": 182,
					"name": "Brute",
					"note": "",
					"bookID": 9,
					"isHidden": false,
					"takenFrom": "",
					"description": "Link Athletics to Strength instead of Agility. This includes resisting Athletics Tests as well.",
					"descriptionHTML": "<p><cite>Savage Worlds: Adventure Edition p.38</cite></p>",
					"customDescription": ""
				},
				{
					"id": 236,
					"name": "Sweep",
					"note": "",
					"bookID": 9,
					"isHidden": false,
					"takenFrom": "",
					"description": "Fighting roll at –2 to hit all adjacent targets in weapon’s Reach, no more than once per turn.",
					"descriptionHTML": "<p><cite>Savage Worlds: Adventure Edition p.44</cite></p>",
					"customDescription": ""
				}
			],
			"image": "",
			"swade": false,
			"bookID": 9,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Athletics",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Common Knowledge",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d12",
					"bookID": 9,
					"isCore": false,
					"dieValue": 12,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Intimidation",
					"value": "d10",
					"bookID": 9,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Persuasion",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Shooting",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Stealth",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 2,
			"fatigue": 0,
			"journal": [],
			"paceMod": 0,
			"parryHR": "8",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "great axe",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d10.",
					"innate": false,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "great axe",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d10.",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d10",
							"parry_modifier": 0,
							"damage_original": "str+d10.",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 0,
							"damageWithBrackets": "str+d10.",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d10",
					"descriptionHTML": "",
					"damageDiceBasePlus": 0,
					"damageWithBrackets": "str+d10."
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Adventure Edition",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": true,
			"abilities": [
				{
					"from": "",
					"name": "Infravision",
					"note": "Halve penalties for Illumination when attacking warm targets.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p186",
					"description": "Halve penalties for Illumination when attacking warm targets."
				},
				{
					"from": "",
					"name": "Size 1",
					"note": "Orcs are slightly larger than humans.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p186",
					"description": "Orcs are slightly larger than humans."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 6,
			"parryBase": 8,
			"sizeLabel": "1 (Normal)",
			"strainMax": 0,
			"woundsMax": 3,
			"appVersion": "3.2.3b010603",
			"armorValue": 4,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "strength",
					"label": "Strength",
					"value": "d10",
					"dieValue": 10
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d10",
					"dieValue": 10
				}
			],
			"background": "",
			"benniesMax": 2,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 8,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 3,
			"bookPrimary": true,
			"createdDate": "2019-01-23T02:10:58.000Z",
			"description": "The leader of a small orc clan is always the most deadly brute in the bunch. Orc chieftains generally have a magical item or two in settings where such things are relatively common (most \"swords & sorcery\" worlds).",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2019-01-23T02:10:58.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 1,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2018",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 7,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 12,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "12 (4)",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/swade/orc-chieftain",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 8,
			"toughnessAsReadNoHeavy": "12 (4)",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 663,
		"gear": [],
		"name": "Raptor (Birds of Prey)",
		"pace": "3",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "a8a795a8-7d4a-4784-3d5c-53c56bdbeaa2",
		"armor": "0",
		"climb": 0,
		"edges": [
			"-"
		],
		"flies": 0,
		"image": "",
		"parry": "5",
		"active": true,
		"skills": [
			{
				"Name": "Athletics",
				"Value": "d8"
			},
			{
				"Name": "Fighting",
				"Value": "d6"
			},
			{
				"Name": "Notice",
				"Value": "d10"
			},
			{
				"Name": "Stealth",
				"Value": "d8"
			}
		],
		"book_id": 9,
		"summary": "",
		"bookPage": "p186",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Bite/Claws",
				"Notes": "Str+d4."
			},
			{
				"Name": "Flight",
				"Notes": "Raptors fly at a Pace of 48″."
			},
			{
				"Name": "Size −3 (Very Small)",
				"Notes": "Raptors are lightweight and about two feet tall."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "2",
		"attributes": {
			"Vigor": "d6",
			"Smarts": "d4 (A)",
			"Spirit": "d6",
			"Agility": "d8",
			"Strength": "d4-2"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "Eagles, hawks, and similar large birds of prey are collectively termed “raptors” (not to be confused with the dinosaur variety).",
		"flying_pace": 0,
		"heavy_armor": false,
		"name_plural": "Raptor (Birds of Prey)s",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 663,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Raptor (Birds of Prey)",
			"race": "Raptor (Birds of Prey)",
			"rank": 0,
			"size": -3,
			"uuid": "a8a795a8-7d4a-4784-3d5c-53c56bdbeaa2",
			"armor": [],
			"edges": [
				{
					"id": 0,
					"name": "-",
					"note": "",
					"bookID": 9,
					"isHidden": false,
					"takenFrom": "",
					"description": "",
					"descriptionHTML": "",
					"customDescription": ""
				}
			],
			"image": "",
			"swade": false,
			"bookID": 9,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Athletics",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d10",
					"bookID": 9,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Stealth",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": -3,
			"parryHR": "5",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Bite/Claws",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d4",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Bite/Claws",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d4",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "2d4",
							"parry_modifier": 0,
							"damage_original": "str+d4",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": -2,
							"damageWithBrackets": "str+d4",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "2d4",
					"descriptionHTML": "",
					"damageDiceBasePlus": -2,
					"damageWithBrackets": "str+d4"
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Adventure Edition",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Bite/Claws",
					"note": "Str+d4.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p186",
					"description": "Str+d4."
				},
				{
					"from": "",
					"name": "Flight",
					"note": "Raptors fly at a Pace of 48″.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p186",
					"description": "Raptors fly at a Pace of 48″."
				},
				{
					"from": "",
					"name": "Size -3 (Very Small)",
					"note": "Raptors are lightweight and about two feet tall.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p186",
					"description": "Raptors are lightweight and about two feet tall."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 3,
			"parryBase": 5,
			"sizeLabel": "-3 (Very Small)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 0,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d4 (A)",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "strength",
					"label": "Strength",
					"value": "d4-2",
					"dieValue": 0
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d6",
					"dieValue": 6
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 5,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2019-01-23T02:11:30.000Z",
			"description": "Eagles, hawks, and similar large birds of prey are collectively termed \"raptors\" (not to be confused with the dinosaur variety).",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2019-01-23T02:11:30.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": -3,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2018",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 5,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 2,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "2",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/swade/raptor-(birds of prey)",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 2,
			"toughnessAsReadNoHeavy": "2",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 664,
		"gear": [],
		"name": "Shark",
		"pace": "-",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "1845ea0f-479e-4775-3e7d-eb5c5ad54365",
		"armor": "0",
		"climb": 0,
		"edges": [
			"-"
		],
		"flies": 0,
		"image": "",
		"parry": "6",
		"active": true,
		"skills": [
			{
				"Name": "Athletics",
				"Value": "d8"
			},
			{
				"Name": "Fighting",
				"Value": "d8"
			},
			{
				"Name": "Notice",
				"Value": "d12"
			},
			{
				"Name": "Stealth",
				"Value": "d8"
			}
		],
		"book_id": 9,
		"summary": "",
		"bookPage": "p186",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Aquatic",
				"Notes": "Pace 10."
			},
			{
				"Name": "Bite",
				"Notes": "Str+d6."
			},
			{
				"Name": "Size 1",
				"Notes": "Sharks up to 500 pounds."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "7",
		"attributes": {
			"Vigor": "d8",
			"Smarts": "d4 (A)",
			"Spirit": "d6",
			"Agility": "d8",
			"Strength": "d8"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "These statistics cover bull sharks and white tips.",
		"flying_pace": 0,
		"heavy_armor": false,
		"name_plural": "Sharks",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 664,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Shark",
			"race": "Shark",
			"rank": 0,
			"size": 1,
			"uuid": "1845ea0f-479e-4775-3e7d-eb5c5ad54365",
			"armor": [],
			"edges": [
				{
					"id": 0,
					"name": "-",
					"note": "",
					"bookID": 9,
					"isHidden": false,
					"takenFrom": "",
					"description": "",
					"descriptionHTML": "",
					"customDescription": ""
				}
			],
			"image": "",
			"swade": false,
			"bookID": 9,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Athletics",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d12",
					"bookID": 9,
					"isCore": false,
					"dieValue": 12,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Stealth",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": null,
			"parryHR": "6",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Bite",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d6",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Bite",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d6",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d6+1d8",
							"parry_modifier": 0,
							"damage_original": "str+d6",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 0,
							"damageWithBrackets": "str+d6",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d6+1d8",
					"descriptionHTML": "",
					"damageDiceBasePlus": 0,
					"damageWithBrackets": "str+d6"
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Adventure Edition",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Aquatic",
					"note": "Pace 10.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p186",
					"description": "Pace 10."
				},
				{
					"from": "",
					"name": "Bite",
					"note": "Str+d6.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p186",
					"description": "Str+d6."
				},
				{
					"from": "",
					"name": "Size 1",
					"note": "Sharks up to 500 pounds.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p186",
					"description": "Sharks up to 500 pounds."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": null,
			"parryBase": 6,
			"sizeLabel": "1 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 0,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d4 (A)",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "strength",
					"label": "Strength",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d8",
					"dieValue": 8
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 6,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2019-01-23T02:12:05.000Z",
			"description": "These statistics cover bull sharks and white tips.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2019-01-23T02:12:05.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 1,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2018",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 6,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 7,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "7",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/swade/shark",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 7,
			"toughnessAsReadNoHeavy": "7",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 25,
		"gear": [],
		"name": "Shark, Great White",
		"pace": "0",
		"race": "",
		"tags": [
			"animal"
		],
		"type": "bestiary",
		"uuid": "4243cd85-3ffb-413e-3dc1-749c75c3e644",
		"armor": "0",
		"climb": -10,
		"edges": [],
		"flies": 0,
		"image": "http://24.media.tumblr.com/b2fb55d2fcc1a2c4a79e86b092ef2724/tumblr_mqogn2Sind1r15h5mo1_500.jpg",
		"parry": "7",
		"active": true,
		"skills": [
			{
				"Name": "Fighting",
				"Value": "d8"
			},
			{
				"Name": "Notice",
				"Value": "d12"
			},
			{
				"Name": "Swimming",
				"Value": "d10"
			}
		],
		"book_id": 2,
		"summary": "",
		"bookPage": "p140",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Aquatic",
				"Notes": " Pace 10."
			},
			{
				"Name": "Bite",
				"Notes": " Str+d8."
			},
			{
				"Name": "Hardy",
				"Notes": " The creature does not suffer a wound from being Shaken twice."
			},
			{
				"Name": "Large",
				"Notes": " Attackers add +2 to their attack rolls when attacking a great white due to its large size."
			},
			{
				"Name": "Size+4",
				"Notes": " Great whites can grow up to 25' in length."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "12",
		"attributes": {
			"Vigor": "d12",
			"Smarts": "d4 (A)",
			"Spirit": "d6",
			"Agility": "d8",
			"Strength": "d12+4"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "These statistics cover great whites, 18 to 25 feet long. Larger specimens surely exist.",
		"flying_pace": -10,
		"heavy_armor": false,
		"name_plural": "",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 25,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Shark, Great White",
			"race": "Shark, Great White",
			"rank": 0,
			"size": 0,
			"uuid": "4243cd85-3ffb-413e-3dc1-749c75c3e644",
			"armor": [],
			"edges": [],
			"image": "https://savaged.ushttp://24.media.tumblr.com/b2fb55d2fcc1a2c4a79e86b092ef2724/tumblr_mqogn2Sind1r15h5mo1_500.jpg",
			"swade": false,
			"bookID": 2,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d8",
					"bookID": 2,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d12",
					"bookID": 2,
					"isCore": false,
					"dieValue": 12,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Swimming",
					"value": "d10",
					"bookID": 2,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": -6,
			"parryHR": "7",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Bite",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d8",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Bite",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d8",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d8+1d12",
							"parry_modifier": 0,
							"damage_original": "str+d8",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 4,
							"damageWithBrackets": "str+d8",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d8+1d12",
					"descriptionHTML": "",
					"damageDiceBasePlus": 4,
					"damageWithBrackets": "str+d8"
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Deluxe (EE)",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Aquatic",
					"note": "Pace 10.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p140",
					"description": "Pace 10."
				},
				{
					"from": "",
					"name": "Bite",
					"note": "Str+d8.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p140",
					"description": "Str+d8."
				},
				{
					"from": "",
					"name": "Hardy",
					"note": "The creature does not suffer a wound from being Shaken twice.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p140",
					"description": "The creature does not suffer a wound from being Shaken twice."
				},
				{
					"from": "",
					"name": "Large",
					"note": "Attackers add +2 to their attack rolls when attacking a great white due to its large size.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p140",
					"description": "Attackers add +2 to their attack rolls when attacking a great white due to its large size."
				},
				{
					"from": "",
					"name": "Size+4",
					"note": "Great whites can grow up to 25' in length.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p140",
					"description": "Great whites can grow up to 25' in length."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 0,
			"parryBase": 7,
			"sizeLabel": "0 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 0,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d4 (A)",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 4,
					"name": "strength",
					"label": "Strength",
					"value": "d12+4",
					"dieValue": 12
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d12",
					"dieValue": 12
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 7,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2018-05-03T19:05:27.000Z",
			"description": "These statistics cover great whites, 18 to 25 feet long. Larger specimens surely exist.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2018-05-03T19:05:27.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 4,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2011",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 8,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 12,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "12",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/deluxe/shark-great white",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 12,
			"toughnessAsReadNoHeavy": "12",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 665,
		"gear": [],
		"name": "Shark, Great White",
		"pace": "-",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "7ae9022e-56aa-4d84-1101-8c48a50a509c",
		"armor": "0",
		"climb": 0,
		"edges": [
			"-"
		],
		"flies": 0,
		"image": "http://24.media.tumblr.com/b2fb55d2fcc1a2c4a79e86b092ef2724/tumblr_mqogn2Sind1r15h5mo1_500.jpg",
		"parry": "7",
		"active": true,
		"skills": [
			{
				"Name": "Athletics",
				"Value": "d8"
			},
			{
				"Name": "Fighting",
				"Value": "d10"
			},
			{
				"Name": "Notice",
				"Value": "d12"
			},
			{
				"Name": "Stealth",
				"Value": "d12"
			}
		],
		"book_id": 9,
		"summary": "",
		"bookPage": "p186",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Aquatic",
				"Notes": "Pace 10."
			},
			{
				"Name": "Bite",
				"Notes": "Str+d8."
			},
			{
				"Name": "Hardy",
				"Notes": "The creature does not suffer a Wound from being Shaken twice."
			},
			{
				"Name": "Size 4 (Large)",
				"Notes": "Great whites can grow up to 25′ in length."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "12",
		"attributes": {
			"Vigor": "d12",
			"Smarts": "d4 (A)",
			"Spirit": "d8",
			"Agility": "d8",
			"Strength": "d12+4"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "These statistics cover great whites, 18 to 25 feet long.",
		"flying_pace": 0,
		"heavy_armor": false,
		"name_plural": "Sharks, Great White",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 665,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Shark, Great White",
			"race": "Shark, Great White",
			"rank": 0,
			"size": 4,
			"uuid": "7ae9022e-56aa-4d84-1101-8c48a50a509c",
			"armor": [],
			"edges": [
				{
					"id": 0,
					"name": "-",
					"note": "",
					"bookID": 9,
					"isHidden": false,
					"takenFrom": "",
					"description": "",
					"descriptionHTML": "",
					"customDescription": ""
				}
			],
			"image": "https://savaged.ushttp://24.media.tumblr.com/b2fb55d2fcc1a2c4a79e86b092ef2724/tumblr_mqogn2Sind1r15h5mo1_500.jpg",
			"swade": false,
			"bookID": 9,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Athletics",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d10",
					"bookID": 9,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d12",
					"bookID": 9,
					"isCore": false,
					"dieValue": 12,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Stealth",
					"value": "d12",
					"bookID": 9,
					"isCore": false,
					"dieValue": 12,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": null,
			"parryHR": "7",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Bite",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d8",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Bite",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d8",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d8+1d12",
							"parry_modifier": 0,
							"damage_original": "str+d8",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 4,
							"damageWithBrackets": "str+d8",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d8+1d12",
					"descriptionHTML": "",
					"damageDiceBasePlus": 4,
					"damageWithBrackets": "str+d8"
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Adventure Edition",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Aquatic",
					"note": "Pace 10.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p186",
					"description": "Pace 10."
				},
				{
					"from": "",
					"name": "Bite",
					"note": "Str+d8.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p186",
					"description": "Str+d8."
				},
				{
					"from": "",
					"name": "Hardy",
					"note": "The creature does not suffer a Wound from being Shaken twice.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p186",
					"description": "The creature does not suffer a Wound from being Shaken twice."
				},
				{
					"from": "",
					"name": "Size 4 (Large)",
					"note": "Great whites can grow up to 25′ in length.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p186",
					"description": "Great whites can grow up to 25′ in length."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": null,
			"parryBase": 7,
			"sizeLabel": "4 (Large)",
			"strainMax": 0,
			"woundsMax": 1,
			"appVersion": "3.2.3b010603",
			"armorValue": 0,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d4 (A)",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 4,
					"name": "strength",
					"label": "Strength",
					"value": "d12+4",
					"dieValue": 12
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d12",
					"dieValue": 12
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 7,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2019-01-23T02:12:23.000Z",
			"description": "These statistics cover great whites, 18 to 25 feet long.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2019-01-23T02:12:23.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 4,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2018",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 8,
			"unarmoredHero": false,
			"woundsSizeMod": 1,
			"naturalIsHeavy": false,
			"toughnessTotal": 12,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "12",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/swade/shark-great white",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 12,
			"toughnessAsReadNoHeavy": "12",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 18,
		"gear": [],
		"name": "Shark, Medium Maneater",
		"pace": "0",
		"race": "",
		"tags": [
			"animal"
		],
		"type": "bestiary",
		"uuid": "90dbce44-1016-4730-08d9-dbae59f7ef07",
		"armor": "0",
		"climb": -10,
		"edges": [],
		"flies": 0,
		"image": "http://www.fearbeneath.com/wp-content/gallery/tiger-sharks/Tiger-Shark-Roger-Horrocks.jpg",
		"parry": "6",
		"active": true,
		"skills": [
			{
				"Name": "Fighting",
				"Value": "d8"
			},
			{
				"Name": "Notice",
				"Value": "d12"
			},
			{
				"Name": "Swimming",
				"Value": "d10"
			}
		],
		"book_id": 2,
		"summary": "",
		"bookPage": "p140",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Aquatic",
				"Notes": " Pace 10."
			},
			{
				"Name": "Bite",
				"Notes": " Str+d6."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "5",
		"attributes": {
			"Vigor": "d6",
			"Smarts": "d4 (A)",
			"Spirit": "d6",
			"Agility": "d8",
			"Strength": "d8"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "These statistics cover most medium sized mankillers, such as tiger sharks an bulls.",
		"flying_pace": -10,
		"heavy_armor": false,
		"name_plural": "",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 18,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Shark, Medium Maneater",
			"race": "Shark, Medium Maneater",
			"rank": 0,
			"size": 0,
			"uuid": "90dbce44-1016-4730-08d9-dbae59f7ef07",
			"armor": [],
			"edges": [],
			"image": "https://savaged.ushttp://www.fearbeneath.com/wp-content/gallery/tiger-sharks/Tiger-Shark-Roger-Horrocks.jpg",
			"swade": false,
			"bookID": 2,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d8",
					"bookID": 2,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d12",
					"bookID": 2,
					"isCore": false,
					"dieValue": 12,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Swimming",
					"value": "d10",
					"bookID": 2,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": -6,
			"parryHR": "6",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Bite",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d6",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Bite",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d6",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d6+1d8",
							"parry_modifier": 0,
							"damage_original": "str+d6",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 0,
							"damageWithBrackets": "str+d6",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d6+1d8",
					"descriptionHTML": "",
					"damageDiceBasePlus": 0,
					"damageWithBrackets": "str+d6"
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Deluxe (EE)",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Aquatic",
					"note": "Pace 10.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p140",
					"description": "Pace 10."
				},
				{
					"from": "",
					"name": "Bite",
					"note": "Str+d6.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p140",
					"description": "Str+d6."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 0,
			"parryBase": 6,
			"sizeLabel": "0 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 0,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d4 (A)",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "strength",
					"label": "Strength",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d6",
					"dieValue": 6
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 6,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2018-05-03T19:05:27.000Z",
			"description": "These statistics cover most medium sized mankillers, such as tiger sharks an bulls.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2018-05-03T19:05:27.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 0,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2011",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 5,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 5,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "5",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/deluxe/shark-medium maneater",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 5,
			"toughnessAsReadNoHeavy": "5",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 26,
		"gear": [
			"Varies"
		],
		"name": "Skeleton",
		"pace": "7",
		"race": "",
		"tags": [
			"undead"
		],
		"type": "bestiary",
		"uuid": "85d1d10c-a0e5-463e-16ac-fbe655d77969",
		"armor": "0",
		"climb": -10,
		"edges": [],
		"flies": 0,
		"image": "http://wiki.chronicles-of-blood.com/images/thumb/Creatures-Skeleton.jpg/350px-Creatures-Skeleton.jpg",
		"parry": "5",
		"active": true,
		"skills": [
			{
				"Name": "Fighting",
				"Value": "d6"
			},
			{
				"Name": "Intimidation",
				"Value": "d6"
			},
			{
				"Name": "Notice",
				"Value": "d4"
			},
			{
				"Name": "Swimming",
				"Value": "d6"
			}
		],
		"book_id": 2,
		"summary": "",
		"bookPage": "p140",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Bony Claws",
				"Notes": " Str+d4."
			},
			{
				"Name": "Fearless",
				"Notes": " Skeletons are immune to Fear and Intimidation."
			},
			{
				"Name": "Undead",
				"Notes": " +2 toughness; +2 to recover from being Shaken; called shots do no extra damage."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "7",
		"attributes": {
			"Vigor": "d6",
			"Smarts": "d4",
			"Spirit": "d4",
			"Agility": "d8",
			"Strength": "d6"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "The skin has already rotted from these risen dead, leaving them slightly quicker than their flesh laden zombie counterparts. They are often found swarming in vile necromancer legions.",
		"flying_pace": -10,
		"heavy_armor": false,
		"name_plural": "",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 26,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [
				{
					"id": 0,
					"cost": 0,
					"name": "Varies",
					"uuid": "",
					"notes": "",
					"bookID": 0,
					"weight": 0,
					"costBuy": 0,
					"summary": "",
					"contains": {
						"gear": [],
						"armor": [],
						"shields": [],
						"weapons": []
					},
					"equipped": true,
					"quantity": 1,
					"container": false,
					"takenFrom": "",
					"bookPublished": "",
					"bookPublisher": "",
					"descriptionHTML": ""
				}
			],
			"load": 0,
			"name": "Skeleton",
			"race": "Skeleton",
			"rank": 0,
			"size": 0,
			"uuid": "85d1d10c-a0e5-463e-16ac-fbe655d77969",
			"armor": [],
			"edges": [],
			"image": "https://savaged.ushttp://wiki.chronicles-of-blood.com/images/thumb/Creatures-Skeleton.jpg/350px-Creatures-Skeleton.jpg",
			"swade": false,
			"bookID": 2,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d6",
					"bookID": 2,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Intimidation",
					"value": "d6",
					"bookID": 2,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d4",
					"bookID": 2,
					"isCore": false,
					"dieValue": 4,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Swimming",
					"value": "d6",
					"bookID": 2,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": 1,
			"parryHR": "5",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Bony Claws",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d4",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Bony Claws",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d4",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d4+1d6",
							"parry_modifier": 0,
							"damage_original": "str+d4",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 0,
							"damageWithBrackets": "str+d4",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d4+1d6",
					"descriptionHTML": "",
					"damageDiceBasePlus": 0,
					"damageWithBrackets": "str+d4"
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Deluxe (EE)",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Bony Claws",
					"note": "Str+d4.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p140",
					"description": "Str+d4."
				},
				{
					"from": "",
					"name": "Fearless",
					"note": "Skeletons are immune to Fear and Intimidation.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p140",
					"description": "Skeletons are immune to Fear and Intimidation."
				},
				{
					"from": "",
					"name": "Undead",
					"note": "+2 toughness; +2 to recover from being Shaken; called shots do no extra damage.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p140",
					"description": "+2 toughness; +2 to recover from being Shaken; called shots do no extra damage."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 7,
			"parryBase": 5,
			"sizeLabel": "0 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 0,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d4",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d4",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "strength",
					"label": "Strength",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d6",
					"dieValue": 6
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 5,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2018-05-03T19:05:27.000Z",
			"description": "The skin has already rotted from these risen dead, leaving them slightly quicker than their flesh laden zombie counterparts. They are often found swarming in vile necromancer legions.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2018-05-03T19:05:27.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 2,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2011",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 5,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 7,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "7",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/deluxe/skeleton",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 7,
			"toughnessAsReadNoHeavy": "7",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 666,
		"gear": [
			"Varies."
		],
		"name": "Skeleton",
		"pace": "7",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "16738578-c4a8-4cd8-a225-36f23c4ec874",
		"armor": "0",
		"climb": 0,
		"edges": [
			"-"
		],
		"flies": 0,
		"image": "http://wiki.chronicles-of-blood.com/images/thumb/Creatures-Skeleton.jpg/350px-Creatures-Skeleton.jpg",
		"parry": "5",
		"active": true,
		"skills": [
			{
				"Name": "Athletics",
				"Value": "d6"
			},
			{
				"Name": "Fighting",
				"Value": "d6"
			},
			{
				"Name": "Intimidation",
				"Value": "d6"
			},
			{
				"Name": "Notice",
				"Value": "d4"
			},
			{
				"Name": "Shooting",
				"Value": "d6"
			}
		],
		"book_id": 9,
		"summary": "",
		"bookPage": "p187",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Claws",
				"Notes": "Str+d4."
			},
			{
				"Name": "Fearless",
				"Notes": "Skeletons are immune to Fear and Intimidation."
			},
			{
				"Name": "Undead",
				"Notes": "+2 Toughness; +2 to recover from being Shaken; no additional damage from Called Shots; ignores 1 point of Wound penalties; doesn't breathe; immune to disease and poison."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "7",
		"attributes": {
			"Vigor": "d6",
			"Smarts": "d4",
			"Spirit": "d4",
			"Agility": "d8",
			"Strength": "d6"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "The skin has already rotted from these risen dead, leaving them slightly quicker than their flesh-laden zombie counterparts. They are often found swarming in vile necromancers’ legions.",
		"flying_pace": 0,
		"heavy_armor": false,
		"name_plural": "Skeletons",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 666,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [
				{
					"id": 0,
					"cost": 0,
					"name": "Varies.",
					"uuid": "",
					"notes": "",
					"bookID": 0,
					"weight": 0,
					"costBuy": 0,
					"summary": "",
					"contains": {
						"gear": [],
						"armor": [],
						"shields": [],
						"weapons": []
					},
					"equipped": true,
					"quantity": 1,
					"container": false,
					"takenFrom": "",
					"bookPublished": "",
					"bookPublisher": "",
					"descriptionHTML": ""
				}
			],
			"load": 0,
			"name": "Skeleton",
			"race": "Skeleton",
			"rank": 0,
			"size": 0,
			"uuid": "16738578-c4a8-4cd8-a225-36f23c4ec874",
			"armor": [],
			"edges": [
				{
					"id": 0,
					"name": "-",
					"note": "",
					"bookID": 9,
					"isHidden": false,
					"takenFrom": "",
					"description": "",
					"descriptionHTML": "",
					"customDescription": ""
				}
			],
			"image": "https://savaged.ushttp://wiki.chronicles-of-blood.com/images/thumb/Creatures-Skeleton.jpg/350px-Creatures-Skeleton.jpg",
			"swade": false,
			"bookID": 9,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Athletics",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Intimidation",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d4",
					"bookID": 9,
					"isCore": false,
					"dieValue": 4,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Shooting",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": 1,
			"parryHR": "5",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Claws",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d4",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Claws",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d4",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d4+1d6",
							"parry_modifier": 0,
							"damage_original": "str+d4",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 0,
							"damageWithBrackets": "str+d4",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d4+1d6",
					"descriptionHTML": "",
					"damageDiceBasePlus": 0,
					"damageWithBrackets": "str+d4"
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Adventure Edition",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Claws",
					"note": "Str+d4.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p187",
					"description": "Str+d4."
				},
				{
					"from": "",
					"name": "Fearless",
					"note": "Skeletons are immune to Fear and Intimidation.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p187",
					"description": "Skeletons are immune to Fear and Intimidation."
				},
				{
					"from": "",
					"name": "Undead",
					"note": "+2 Toughness; +2 to recover from being Shaken; no additional damage from Called Shots; ignores 1 point of Wound penalties; doesn't breathe; immune to disease and poison.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p187",
					"description": "+2 Toughness; +2 to recover from being Shaken; no additional damage from Called Shots; ignores 1 point of Wound penalties; doesn't breathe; immune to disease and poison."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 7,
			"parryBase": 5,
			"sizeLabel": "0 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 0,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d4",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d4",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "strength",
					"label": "Strength",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d6",
					"dieValue": 6
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 5,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2019-01-23T02:12:43.000Z",
			"description": "The skin has already rotted from these risen dead, leaving them slightly quicker than their flesh-laden zombie counterparts. They are often found swarming in vile necromancers' legions.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2019-01-23T02:12:43.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 2,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2018",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 5,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 7,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "7",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/swade/skeleton",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 7,
			"toughnessAsReadNoHeavy": "7",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 27,
		"gear": [],
		"name": "Snake, Constrictor",
		"pace": "4",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "3e69ec42-c477-41c8-ae34-1302aa3bff4b",
		"armor": "0",
		"climb": -10,
		"edges": [],
		"flies": 0,
		"image": "",
		"parry": "5",
		"active": true,
		"skills": [
			{
				"Name": "Fighting",
				"Value": "d6"
			},
			{
				"Name": "Notice",
				"Value": "d10"
			}
		],
		"book_id": 2,
		"summary": "",
		"bookPage": "p140",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Bite",
				"Notes": " Str."
			},
			{
				"Name": "Constrict",
				"Notes": " Large constrictors have very little chance of entangling active man-sized prey in the real world&#x2014; they must attack while their victim is sleeping, stunned, paralyzed, and so on. Constrictors in pulp and other fantastic genres might be far more deadly. These creatures bite when they succeed at a Fighting roll, and entangle when they succeed with a raise. The round they entangle and each round thereafter, they cause damage to their prey equal to Str+d6. The prey may attempt to escape on his action by getting a raise on an opposed Strength roll."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "5",
		"attributes": {
			"Vigor": "d6",
			"Smarts": "d4 (A)",
			"Spirit": "d8",
			"Agility": "d4",
			"Strength": "d6"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "Pythons, boa constrictors, and other snakes over 15' long are rarely deadly to man in the real world because they aren't particularly aggressive toward such large prey. In games, however, such snakes might be provoked, drugged, or just plain mean.",
		"flying_pace": -10,
		"heavy_armor": false,
		"name_plural": "",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 27,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Snake, Constrictor",
			"race": "Snake, Constrictor",
			"rank": 0,
			"size": 0,
			"uuid": "3e69ec42-c477-41c8-ae34-1302aa3bff4b",
			"armor": [],
			"edges": [],
			"image": "",
			"swade": false,
			"bookID": 2,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d6",
					"bookID": 2,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d10",
					"bookID": 2,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": -2,
			"parryHR": "5",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Constrict",
					"uuid": "",
					"notes": " Constrictors in pulp and other fantastic genres might be far more deadly",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "large",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Constrict",
							"notes": " Constrictors in pulp and other fantastic genres might be far more deadly",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "large",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "",
							"parry_modifier": 0,
							"damage_original": "large",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": null,
							"damageWithBrackets": "large",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "",
					"descriptionHTML": "",
					"damageDiceBasePlus": null,
					"damageWithBrackets": "large"
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Deluxe (EE)",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Bite",
					"note": "Str.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p140",
					"description": "Str."
				},
				{
					"from": "",
					"name": "Constrict",
					"note": "Large constrictors have very little chance of entangling active man-sized prey in the real world&#x2014; they must attack while their victim is sleeping, stunned, paralyzed, and so on. Constrictors in pulp and other fantastic genres might be far more deadly. These creatures bite when they succeed at a Fighting roll, and entangle when they succeed with a raise. The round they entangle and each round thereafter, they cause damage to their prey equal to Str+d6. The prey may attempt to escape on his action by getting a raise on an opposed Strength roll.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p140",
					"description": "Large constrictors have very little chance of entangling active man-sized prey in the real world&#x2014; they must attack while their victim is sleeping, stunned, paralyzed, and so on. Constrictors in pulp and other fantastic genres might be far more deadly. These creatures bite when they succeed at a Fighting roll, and entangle when they succeed with a raise. The round they entangle and each round thereafter, they cause damage to their prey equal to Str+d6. The prey may attempt to escape on his action by getting a raise on an opposed Strength roll."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 4,
			"parryBase": 5,
			"sizeLabel": "0 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 0,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d4",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d4 (A)",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "strength",
					"label": "Strength",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d6",
					"dieValue": 6
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 5,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2018-05-03T19:05:27.000Z",
			"description": "Pythons, boa constrictors, and other snakes over 15' long are rarely deadly to man in the real world because they aren't particularly aggressive toward such large prey. In games, however, such snakes might be provoked, drugged, or just plain mean.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2018-05-03T19:05:27.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 0,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2011",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 5,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 5,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "5",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/deluxe/snake-constrictor",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 5,
			"toughnessAsReadNoHeavy": "5",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 667,
		"gear": [],
		"name": "Snake, Constrictor",
		"pace": "4",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "929ff81b-0a87-411e-08d5-b7b4245e20a0",
		"armor": "0",
		"climb": 0,
		"edges": [
			"-"
		],
		"flies": 0,
		"image": "",
		"parry": "5",
		"active": true,
		"skills": [
			{
				"Name": "Athletics",
				"Value": "d6"
			},
			{
				"Name": "Fighting",
				"Value": "d6"
			},
			{
				"Name": "Notice",
				"Value": "d10"
			},
			{
				"Name": "Stealth",
				"Value": "d6"
			}
		],
		"book_id": 9,
		"summary": "",
		"bookPage": "p188",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Bite",
				"Notes": "Str."
			},
			{
				"Name": "Constrict",
				"Notes": "+2 to Athletics and Strength rolls made to grapple."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "5",
		"attributes": {
			"Vigor": "d6",
			"Smarts": "d4 (A)",
			"Spirit": "d8",
			"Agility": "d4",
			"Strength": "d6"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "Pythons, boa constrictors, and other snakes over 15′ long are rarely deadly to man in the real world because they aren’t particularly aggressive. In games, however, such snakes might be provoked, drugged, or just plain mean.",
		"flying_pace": 0,
		"heavy_armor": false,
		"name_plural": "Snake, Constrictors",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 667,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Snake, Constrictor",
			"race": "Snake, Constrictor",
			"rank": 0,
			"size": 0,
			"uuid": "929ff81b-0a87-411e-08d5-b7b4245e20a0",
			"armor": [],
			"edges": [
				{
					"id": 0,
					"name": "-",
					"note": "",
					"bookID": 9,
					"isHidden": false,
					"takenFrom": "",
					"description": "",
					"descriptionHTML": "",
					"customDescription": ""
				}
			],
			"image": "",
			"swade": false,
			"bookID": 9,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Athletics",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d10",
					"bookID": 9,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Stealth",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": -2,
			"parryHR": "5",
			"shields": [],
			"weapons": [],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Adventure Edition",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Bite",
					"note": "Str.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p188",
					"description": "Str."
				},
				{
					"from": "",
					"name": "Constrict",
					"note": "+2 to Athletics and Strength rolls made to grapple.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p188",
					"description": "+2 to Athletics and Strength rolls made to grapple."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 4,
			"parryBase": 5,
			"sizeLabel": "0 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 0,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d4",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d4 (A)",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "strength",
					"label": "Strength",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d6",
					"dieValue": 6
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 5,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2019-01-23T02:13:21.000Z",
			"description": "Pythons, boa constrictors, and other snakes over 15′ long are rarely deadly to man in the real world because they aren't particularly aggressive. In games, however, such snakes might be provoked, drugged, or just plain mean.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2019-01-23T02:13:21.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 0,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2018",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 5,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 5,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "5",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/swade/snake-constrictor",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 5,
			"toughnessAsReadNoHeavy": "5",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 28,
		"gear": [],
		"name": "Snake, Venomous",
		"pace": "4",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "dc93eb56-9365-4a2c-8f93-f695d574cd68",
		"armor": "0",
		"climb": -10,
		"edges": [],
		"flies": 0,
		"image": "",
		"parry": "6",
		"active": true,
		"skills": [
			{
				"Name": "Fighting",
				"Value": "d8"
			},
			{
				"Name": "Notice",
				"Value": "d12"
			}
		],
		"book_id": 2,
		"summary": "",
		"bookPage": "p140",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Bite",
				"Notes": " Str."
			},
			{
				"Name": "Poison",
				"Notes": " See the Poison rules on [object Object] page 89."
			},
			{
				"Name": "Quick",
				"Notes": " Snakes are notoriously fast. They may discard Action Cards of 5 or lower and draw another. They must keep the replacement card, however."
			},
			{
				"Name": "Size-2",
				"Notes": " Most venomous snakes are 4-6' in length, but only a few inches thick."
			},
			{
				"Name": "Small",
				"Notes": " Anyone attacking a snake must subtract 2 from his attack rolls."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "2",
		"attributes": {
			"Vigor": "d4",
			"Smarts": "d4 (A)",
			"Spirit": "d6",
			"Agility": "d8",
			"Strength": "d4"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "Here are the stats for Taipans (Australian brown snakes), cobras, and similar medium- sized snakes with extremely deadly poison.",
		"flying_pace": -10,
		"heavy_armor": false,
		"name_plural": "",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 28,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Snake, Venomous",
			"race": "Snake, Venomous",
			"rank": 0,
			"size": 0,
			"uuid": "dc93eb56-9365-4a2c-8f93-f695d574cd68",
			"armor": [],
			"edges": [],
			"image": "",
			"swade": false,
			"bookID": 2,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d8",
					"bookID": 2,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d12",
					"bookID": 2,
					"isCore": false,
					"dieValue": 12,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": -2,
			"parryHR": "6",
			"shields": [],
			"weapons": [],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Deluxe (EE)",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Bite",
					"note": "Str.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p140",
					"description": "Str."
				},
				{
					"from": "",
					"name": "Poison",
					"note": "See the Poison rules on [object Object] page 89.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p140",
					"description": "See the Poison rules on [object Object] page 89."
				},
				{
					"from": "",
					"name": "Quick",
					"note": "Snakes are notoriously fast. They may discard Action Cards of 5 or lower and draw another. They must keep the replacement card, however.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p140",
					"description": "Snakes are notoriously fast. They may discard Action Cards of 5 or lower and draw another. They must keep the replacement card, however."
				},
				{
					"from": "",
					"name": "Size-2",
					"note": "Most venomous snakes are 4-6' in length, but only a few inches thick.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p140",
					"description": "Most venomous snakes are 4-6' in length, but only a few inches thick."
				},
				{
					"from": "",
					"name": "Small",
					"note": "Anyone attacking a snake must subtract 2 from his attack rolls.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p140",
					"description": "Anyone attacking a snake must subtract 2 from his attack rolls."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 4,
			"parryBase": 6,
			"sizeLabel": "0 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 0,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d4 (A)",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "strength",
					"label": "Strength",
					"value": "d4",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d4",
					"dieValue": 4
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 6,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2018-05-03T19:05:27.000Z",
			"description": "Here are the stats for Taipans (Australian brown snakes), cobras, and similar medium- sized snakes with extremely deadly poison.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2018-05-03T19:05:27.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": -2,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2011",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 4,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 2,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "2",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/deluxe/snake-venomous",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 2,
			"toughnessAsReadNoHeavy": "2",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 668,
		"gear": [],
		"name": "Snake, Venomous",
		"pace": "4",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "89b60557-bf40-46a4-a65e-d5ee416c531f",
		"armor": "0",
		"climb": 0,
		"edges": [
			"Quick"
		],
		"flies": 0,
		"image": "",
		"parry": "6",
		"active": true,
		"skills": [
			{
				"Name": "Athletics",
				"Value": "d6"
			},
			{
				"Name": "Fighting",
				"Value": "d8"
			},
			{
				"Name": "Notice",
				"Value": "d12"
			},
			{
				"Name": "Stealth",
				"Value": "d8"
			}
		],
		"book_id": 9,
		"summary": "",
		"bookPage": "p188",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Bite",
				"Notes": "Str+2."
			},
			{
				"Name": "Poison",
				"Notes": "The GM chooses what kind of poison the viper injects (see Poison, page 128)."
			},
			{
				"Name": "Size −3 (Very Small)",
				"Notes": "Most venomous snakes are 4–6′ in length, but only a few inches thick."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "2",
		"attributes": {
			"Vigor": "d6",
			"Smarts": "d4 (A)",
			"Spirit": "d6",
			"Agility": "d8",
			"Strength": "d4-2"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "Here are the stats for Taipans (Australian brown snakes), cobras, and similar mediumsized snakes with extremely deadly poison.",
		"flying_pace": 0,
		"heavy_armor": false,
		"name_plural": "Snakes, Venomous",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 668,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Snake, Venomous",
			"race": "Snake, Venomous",
			"rank": 0,
			"size": -3,
			"uuid": "89b60557-bf40-46a4-a65e-d5ee416c531f",
			"armor": [],
			"edges": [
				{
					"id": 191,
					"name": "Quick",
					"note": "",
					"bookID": 9,
					"isHidden": false,
					"takenFrom": "",
					"description": "Action Card must be higher than a Five.",
					"descriptionHTML": "<p><cite>Savage Worlds: Adventure Edition p.40</cite></p>",
					"customDescription": ""
				}
			],
			"image": "",
			"swade": false,
			"bookID": 9,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Athletics",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d12",
					"bookID": 9,
					"isCore": false,
					"dieValue": 12,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Stealth",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": -2,
			"parryHR": "6",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Bite",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+2",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Bite",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+2",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d4",
							"parry_modifier": 0,
							"damage_original": "str+2",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 0,
							"damageWithBrackets": "str+2",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d4",
					"descriptionHTML": "",
					"damageDiceBasePlus": 0,
					"damageWithBrackets": "str+2"
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Adventure Edition",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Bite",
					"note": "Str+2.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p188",
					"description": "Str+2."
				},
				{
					"from": "",
					"name": "Poison",
					"note": "The GM chooses what kind of poison the viper injects (see Poison, page 128).",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p188",
					"description": "The GM chooses what kind of poison the viper injects (see Poison, page 128)."
				},
				{
					"from": "",
					"name": "Size -3 (Very Small)",
					"note": "Most venomous snakes are 4–6′ in length, but only a few inches thick.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p188",
					"description": "Most venomous snakes are 4–6′ in length, but only a few inches thick."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 4,
			"parryBase": 6,
			"sizeLabel": "-3 (Very Small)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 0,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d4 (A)",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "strength",
					"label": "Strength",
					"value": "d4-2",
					"dieValue": 0
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d6",
					"dieValue": 6
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 6,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2019-01-23T02:13:49.000Z",
			"description": "Here are the stats for Taipans (Australian brown snakes), cobras, and similar mediumsized snakes with extremely deadly poison.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2019-01-23T02:13:49.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": -3,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2018",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 5,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 2,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "2",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/swade/snake-venomous",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 2,
			"toughnessAsReadNoHeavy": "2",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 669,
		"gear": [],
		"name": "Soldier",
		"pace": "6",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "a5b5d9f9-8a66-4258-0c9a-a8c8a73f9ea6",
		"armor": "0",
		"climb": 0,
		"edges": [
			"Soldier"
		],
		"flies": 0,
		"image": "",
		"parry": "5",
		"active": true,
		"skills": [
			{
				"Name": "Athletics",
				"Value": "d6"
			},
			{
				"Name": "Common Knowledge",
				"Value": "d6"
			},
			{
				"Name": "Fighting",
				"Value": "d6"
			},
			{
				"Name": "Intimidation",
				"Value": "d6"
			},
			{
				"Name": "Notice",
				"Value": "d6"
			},
			{
				"Name": "Persuasion",
				"Value": "d6"
			},
			{
				"Name": "Shooting",
				"Value": "d6"
			},
			{
				"Name": "Stealth",
				"Value": "d4"
			}
		],
		"book_id": 9,
		"summary": "",
		"bookPage": "p188",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "5",
		"attributes": {
			"Vigor": "d6",
			"Smarts": "d4",
			"Spirit": "d6",
			"Agility": "d6",
			"Strength": "d6"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [
			"-"
		],
		"updated_by": 0,
		"version_of": 0,
		"description": "Goons, grunts, soldiers, or others with basic training serve as foot soldiers for more powerful characters. They should be customized and outfitted to fit their particular role.",
		"flying_pace": 0,
		"heavy_armor": false,
		"name_plural": "Soldiers",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 669,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Soldier",
			"race": "Soldier",
			"rank": 0,
			"size": 0,
			"uuid": "a5b5d9f9-8a66-4258-0c9a-a8c8a73f9ea6",
			"armor": [],
			"edges": [
				{
					"id": 303,
					"name": "Soldier",
					"note": "",
					"bookID": 9,
					"isHidden": false,
					"takenFrom": "",
					"description": "Treat Minimum Strength as one die type higher for Encumbrance and Min Str. May Retest Vigor rolls when resisting environmental Hazards.",
					"descriptionHTML": "<p><cite>Savage Worlds: Adventure Edition p.49</cite></p>",
					"customDescription": ""
				}
			],
			"image": "",
			"swade": false,
			"bookID": 9,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Athletics",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Common Knowledge",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Intimidation",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Persuasion",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Shooting",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Stealth",
					"value": "d4",
					"bookID": 9,
					"isCore": false,
					"dieValue": 4,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": 0,
			"parryHR": "5",
			"shields": [],
			"weapons": [],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Adventure Edition",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 6,
			"parryBase": 5,
			"sizeLabel": "0 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 0,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d4",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "strength",
					"label": "Strength",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d6",
					"dieValue": 6
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [
				{
					"id": 0,
					"name": "-",
					"note": "",
					"major": false,
					"bookID": 9,
					"isHidden": false,
					"takenFrom": "",
					"description": "",
					"descriptionHTML": "",
					"customDescription": ""
				}
			],
			"imageToken": "",
			"parryTotal": 5,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2019-01-23T02:13:57.000Z",
			"description": "Goons, grunts, soldiers, or others with basic training serve as foot soldiers for more powerful characters. They should be customized and outfitted to fit their particular role.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2019-01-23T02:13:57.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 0,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2018",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 5,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 5,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "5",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/swade/soldier",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 5,
			"toughnessAsReadNoHeavy": "5",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 29,
		"gear": [],
		"name": "Spider, Giant",
		"pace": "8",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "52049aba-4ab5-4ce8-21f2-e47611fa78dc",
		"armor": "0",
		"climb": -10,
		"edges": [],
		"flies": 0,
		"image": "",
		"parry": "6",
		"active": true,
		"skills": [
			{
				"Name": "Climbing",
				"Value": "d12+2"
			},
			{
				"Name": "Fighting",
				"Value": "d8"
			},
			{
				"Name": "Intimidation",
				"Value": "d10"
			},
			{
				"Name": "Notice",
				"Value": "d8"
			},
			{
				"Name": "Shooting",
				"Value": "d10"
			},
			{
				"Name": "Stealth",
				"Value": "d10"
			}
		],
		"book_id": 2,
		"summary": "",
		"bookPage": "p140",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Bite",
				"Notes": " Str+d4."
			},
			{
				"Name": "Poison (-4)",
				"Notes": " See the Poison rules on [object Object] page 89."
			},
			{
				"Name": "Wall Walker",
				"Notes": " Can walk on vertical surfaces at Pace 8."
			},
			{
				"Name": "Webbing",
				"Notes": " The spiders can cast webs from their thorax that are the size of Small Burst Templates. This is a Shooting roll with a range of 3/6/12. Anything in the web must cut or break their way free (Toughness 7). Webbed characters can still fight, but all physical actions are at &#x2013;4."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "5",
		"attributes": {
			"Vigor": "d6",
			"Smarts": "d4 (A)",
			"Spirit": "d6",
			"Agility": "d10",
			"Strength": "d10"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "Giant spiders are about the size of large dogs and live in nests of 1d6+2 arachnids. They frequently go hunting in these packs when prey is scarce in their home lair.",
		"flying_pace": -10,
		"heavy_armor": false,
		"name_plural": "",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 29,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Spider, Giant",
			"race": "Spider, Giant",
			"rank": 0,
			"size": 0,
			"uuid": "52049aba-4ab5-4ce8-21f2-e47611fa78dc",
			"armor": [],
			"edges": [],
			"image": "",
			"swade": false,
			"bookID": 2,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 2,
					"name": "Climbing",
					"value": "d12",
					"bookID": 2,
					"isCore": false,
					"dieValue": 12,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d8",
					"bookID": 2,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Intimidation",
					"value": "d10",
					"bookID": 2,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d8",
					"bookID": 2,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Shooting",
					"value": "d10",
					"bookID": 2,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Stealth",
					"value": "d10",
					"bookID": 2,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": 2,
			"parryHR": "6",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Bite",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d4",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Bite",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d4",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d4+1d10",
							"parry_modifier": 0,
							"damage_original": "str+d4",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 0,
							"damageWithBrackets": "str+d4",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d4+1d10",
					"descriptionHTML": "",
					"damageDiceBasePlus": 0,
					"damageWithBrackets": "str+d4"
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Deluxe (EE)",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Bite",
					"note": "Str+d4.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p140",
					"description": "Str+d4."
				},
				{
					"from": "",
					"name": "Poison (-4)",
					"note": "See the Poison rules on [object Object] page 89.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p140",
					"description": "See the Poison rules on [object Object] page 89."
				},
				{
					"from": "",
					"name": "Wall Walker",
					"note": "Can walk on vertical surfaces at Pace 8.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p140",
					"description": "Can walk on vertical surfaces at Pace 8."
				},
				{
					"from": "",
					"name": "Webbing",
					"note": "The spiders can cast webs from their thorax that are the size of Small Burst Templates. This is a Shooting roll with a range of 3/6/12. Anything in the web must cut or break their way free (Toughness 7). Webbed characters can still fight, but all physical actions are at &#x2013;4.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p140",
					"description": "The spiders can cast webs from their thorax that are the size of Small Burst Templates. This is a Shooting roll with a range of 3/6/12. Anything in the web must cut or break their way free (Toughness 7). Webbed characters can still fight, but all physical actions are at &#x2013;4."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 8,
			"parryBase": 6,
			"sizeLabel": "0 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 0,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d10",
					"dieValue": 10
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d4 (A)",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "strength",
					"label": "Strength",
					"value": "d10",
					"dieValue": 10
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d6",
					"dieValue": 6
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 6,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2018-05-03T19:05:27.000Z",
			"description": "Giant spiders are about the size of large dogs and live in nests of 1d6+2 arachnids. They frequently go hunting in these packs when prey is scarce in their home lair.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2018-05-03T19:05:27.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 0,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2011",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 5,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 5,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "5",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/deluxe/spider-giant",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 5,
			"toughnessAsReadNoHeavy": "5",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 670,
		"gear": [],
		"name": "Spider, Giant",
		"pace": "8",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "8835d050-c1e8-4c76-b4db-7cecff401b9b",
		"armor": "0",
		"climb": 0,
		"edges": [
			"-"
		],
		"flies": 0,
		"image": "",
		"parry": "6",
		"active": true,
		"skills": [
			{
				"Name": "Athletics",
				"Value": "d10"
			},
			{
				"Name": "Fighting",
				"Value": "d8"
			},
			{
				"Name": "Intimidation",
				"Value": "d10"
			},
			{
				"Name": "Notice",
				"Value": "d8"
			},
			{
				"Name": "Shooting",
				"Value": "d10"
			},
			{
				"Name": "Stealth",
				"Value": "d10"
			}
		],
		"book_id": 9,
		"summary": "",
		"bookPage": "p188",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Bite",
				"Notes": "Str+d4."
			},
			{
				"Name": "Poison (−4)",
				"Notes": "Usually Mild or Lethal. See page 128."
			},
			{
				"Name": "Size −1",
				"Notes": "These spiders are dog-sized."
			},
			{
				"Name": "Wall Walker",
				"Notes": "The creatures move at their full Pace on walls and ceilings! Creepy!"
			},
			{
				"Name": "Webbing",
				"Notes": "The spiders can cast webs from their thorax that are the size of Small Blast Templates. This is a Shooting roll with a Range of 6″. A hit means the victim is Entangled, or Bound with a raise (see Bound & Entangled, page 98)."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "4",
		"attributes": {
			"Vigor": "d6",
			"Smarts": "d4 (A)",
			"Spirit": "d6",
			"Agility": "d10",
			"Strength": "d10"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "Giant spiders are about the size of large dogs and live in nests of 1d6 + 2 arachnids. They frequently go hunting in packs when prey is scarce in their home lair. Their dens are littered with the bones and treasures of their victims, often providing ripe pickings for those brave enough to venture within.",
		"flying_pace": 0,
		"heavy_armor": false,
		"name_plural": "Spiders, Giant",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 670,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Spider, Giant",
			"race": "Spider, Giant",
			"rank": 0,
			"size": -1,
			"uuid": "8835d050-c1e8-4c76-b4db-7cecff401b9b",
			"armor": [],
			"edges": [
				{
					"id": 0,
					"name": "-",
					"note": "",
					"bookID": 9,
					"isHidden": false,
					"takenFrom": "",
					"description": "",
					"descriptionHTML": "",
					"customDescription": ""
				}
			],
			"image": "",
			"swade": false,
			"bookID": 9,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Athletics",
					"value": "d10",
					"bookID": 9,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Intimidation",
					"value": "d10",
					"bookID": 9,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Shooting",
					"value": "d10",
					"bookID": 9,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Stealth",
					"value": "d10",
					"bookID": 9,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": 2,
			"parryHR": "6",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Bite",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d4",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Bite",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d4",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d4+1d10",
							"parry_modifier": 0,
							"damage_original": "str+d4",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 0,
							"damageWithBrackets": "str+d4",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d4+1d10",
					"descriptionHTML": "",
					"damageDiceBasePlus": 0,
					"damageWithBrackets": "str+d4"
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Adventure Edition",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Bite",
					"note": "Str+d4.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p188",
					"description": "Str+d4."
				},
				{
					"from": "",
					"name": "Poison (-4)",
					"note": "Usually Mild or Lethal. See page 128.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p188",
					"description": "Usually Mild or Lethal. See page 128."
				},
				{
					"from": "",
					"name": "Size -1",
					"note": "These spiders are dog-sized.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p188",
					"description": "These spiders are dog-sized."
				},
				{
					"from": "",
					"name": "Wall Walker",
					"note": "The creatures move at their full Pace on walls and ceilings! Creepy!",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p188",
					"description": "The creatures move at their full Pace on walls and ceilings! Creepy!"
				},
				{
					"from": "",
					"name": "Webbing",
					"note": "The spiders can cast webs from their thorax that are the size of Small Blast Templates. This is a Shooting roll with a Range of 6″. A hit means the victim is Entangled, or Bound with a raise (see Bound & Entangled, page 98).",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p188",
					"description": "The spiders can cast webs from their thorax that are the size of Small Blast Templates. This is a Shooting roll with a Range of 6″. A hit means the victim is Entangled, or Bound with a raise (see Bound & Entangled, page 98)."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 8,
			"parryBase": 6,
			"sizeLabel": "-1 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 0,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d10",
					"dieValue": 10
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d4 (A)",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "strength",
					"label": "Strength",
					"value": "d10",
					"dieValue": 10
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d6",
					"dieValue": 6
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 6,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2019-01-23T02:14:26.000Z",
			"description": "Giant spiders are about the size of large dogs and live in nests of 1d6 + 2 arachnids. They frequently go hunting in packs when prey is scarce in their home lair. Their dens are littered with the bones and treasures of their victims, often providing ripe pickings for those brave enough to venture within.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2019-01-23T02:14:26.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": -1,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2018",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 5,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 4,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "4",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/swade/spider-giant",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 4,
			"toughnessAsReadNoHeavy": "4",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 681,
		"gear": [],
		"name": "Summon Ally: Attendant",
		"pace": "4",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "e07de730-1718-4b3b-22f2-40d995de9763",
		"armor": "0",
		"climb": 0,
		"edges": [],
		"flies": 0,
		"image": "",
		"parry": "4",
		"active": true,
		"skills": [
			{
				"Name": "Athletics",
				"Value": "d4"
			},
			{
				"Name": "Fighting",
				"Value": "d4"
			},
			{
				"Name": "Notice",
				"Value": "d4"
			},
			{
				"Name": "Shooting",
				"Value": "d4"
			},
			{
				"Name": "Stealth",
				"Value": "d6"
			}
		],
		"book_id": 9,
		"summary": "",
		"bookPage": "p167",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Claw",
				"Notes": "Str+d4."
			},
			{
				"Name": "Construct",
				"Notes": "+2 to recover from being Shaken; ignore 1 point of Wound penalties; doesn't breathe, immune to poison and disease."
			},
			{
				"Name": "Fearless",
				"Notes": "Immune to fear and Intimidation."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "4",
		"attributes": {
			"Vigor": "d4",
			"Smarts": "d4",
			"Spirit": "d4",
			"Agility": "d4",
			"Strength": "d4"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "From the Summon Ally power",
		"flying_pace": 0,
		"heavy_armor": false,
		"name_plural": "Summon Ally: Attendants",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 681,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Summon Ally: Attendant",
			"race": "Summon Ally: Attendant",
			"rank": 0,
			"size": 0,
			"uuid": "e07de730-1718-4b3b-22f2-40d995de9763",
			"armor": [],
			"edges": [],
			"image": "",
			"swade": false,
			"bookID": 9,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Athletics",
					"value": "d4",
					"bookID": 9,
					"isCore": false,
					"dieValue": 4,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d4",
					"bookID": 9,
					"isCore": false,
					"dieValue": 4,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d4",
					"bookID": 9,
					"isCore": false,
					"dieValue": 4,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Shooting",
					"value": "d4",
					"bookID": 9,
					"isCore": false,
					"dieValue": 4,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Stealth",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": -2,
			"parryHR": "4",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Claw",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d4",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Claw",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d4",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "2d4",
							"parry_modifier": 0,
							"damage_original": "str+d4",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 0,
							"damageWithBrackets": "str+d4",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "2d4",
					"descriptionHTML": "",
					"damageDiceBasePlus": 0,
					"damageWithBrackets": "str+d4"
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Adventure Edition",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Claw",
					"note": "Str+d4.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p167",
					"description": "Str+d4."
				},
				{
					"from": "",
					"name": "Construct",
					"note": "+2 to recover from being Shaken; ignore 1 point of Wound penalties; doesn't breathe, immune to poison and disease.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p167",
					"description": "+2 to recover from being Shaken; ignore 1 point of Wound penalties; doesn't breathe, immune to poison and disease."
				},
				{
					"from": "",
					"name": "Fearless",
					"note": "Immune to fear and Intimidation.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p167",
					"description": "Immune to fear and Intimidation."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 4,
			"parryBase": 4,
			"sizeLabel": "0 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 0,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d4",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d4",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d4",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "strength",
					"label": "Strength",
					"value": "d4",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d4",
					"dieValue": 4
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 4,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2019-03-01T20:00:50.000Z",
			"description": "From the Summon Ally power",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2019-03-01T20:02:09.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 0,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2018",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 4,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 4,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "4",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/swade/summon-ally: attendant",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 4,
			"toughnessAsReadNoHeavy": "4",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 682,
		"gear": [
			"Melee attack (Str+d6)."
		],
		"name": "Summon Ally: Bodyguard",
		"pace": "6",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "d772ad40-9c04-426b-3e44-e61937ab4da8",
		"armor": "2",
		"climb": 0,
		"edges": [
			"First Strike"
		],
		"flies": 0,
		"image": "",
		"parry": "5",
		"active": true,
		"skills": [
			{
				"Name": "Athletics",
				"Value": "d6"
			},
			{
				"Name": "Fighting",
				"Value": "d6"
			},
			{
				"Name": "Intimidation",
				"Value": "d6"
			},
			{
				"Name": "Notice",
				"Value": "d4"
			},
			{
				"Name": "Shooting",
				"Value": "d4"
			},
			{
				"Name": "Stealth",
				"Value": "d4"
			}
		],
		"book_id": 9,
		"summary": "",
		"bookPage": "p167",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Armor +2",
				"Notes": "Hardened skin."
			},
			{
				"Name": "Construct",
				"Notes": "See Attendant."
			},
			{
				"Name": "Fearless",
				"Notes": "Immune to fear and Intimidation."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "7",
		"attributes": {
			"Vigor": "d6",
			"Smarts": "d4",
			"Spirit": "d6",
			"Agility": "d6",
			"Strength": "d6"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "From the Summon Ally Power",
		"flying_pace": 0,
		"heavy_armor": false,
		"name_plural": "Summon Ally: Bodyguards",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 682,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Summon Ally: Bodyguard",
			"race": "Summon Ally: Bodyguard",
			"rank": 0,
			"size": 0,
			"uuid": "d772ad40-9c04-426b-3e44-e61937ab4da8",
			"armor": [],
			"edges": [
				{
					"id": 210,
					"name": "First Strike",
					"note": "",
					"bookID": 9,
					"isHidden": false,
					"takenFrom": "",
					"description": "Free Fighting attack once per round when foe moves adjacent to you.",
					"descriptionHTML": "<p><cite>Savage Worlds: Adventure Edition p.42</cite></p>",
					"customDescription": ""
				}
			],
			"image": "",
			"swade": false,
			"bookID": 9,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Athletics",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Intimidation",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d4",
					"bookID": 9,
					"isCore": false,
					"dieValue": 4,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Shooting",
					"value": "d4",
					"bookID": 9,
					"isCore": false,
					"dieValue": 4,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Stealth",
					"value": "d4",
					"bookID": 9,
					"isCore": false,
					"dieValue": 4,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": 0,
			"parryHR": "5",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Melee attack",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d6.",
					"innate": false,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Melee attack",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d6.",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d6",
							"parry_modifier": 0,
							"damage_original": "str+d6.",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 0,
							"damageWithBrackets": "str+d6.",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d6",
					"descriptionHTML": "",
					"damageDiceBasePlus": 0,
					"damageWithBrackets": "str+d6."
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Adventure Edition",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Armor +2",
					"note": "Hardened skin.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p167",
					"description": "Hardened skin."
				},
				{
					"from": "",
					"name": "Construct",
					"note": "See Attendant.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p167",
					"description": "See Attendant."
				},
				{
					"from": "",
					"name": "Fearless",
					"note": "Immune to fear and Intimidation.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p167",
					"description": "Immune to fear and Intimidation."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 6,
			"parryBase": 5,
			"sizeLabel": "0 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 2,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d4",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "strength",
					"label": "Strength",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d6",
					"dieValue": 6
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 5,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2019-03-01T20:01:27.000Z",
			"description": "From the Summon Ally Power",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2019-03-01T20:01:27.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 0,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2018",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 5,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 7,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "7 (2)",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/swade/summon-ally: bodyguard",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 5,
			"toughnessAsReadNoHeavy": "7 (2)",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 683,
		"gear": [
			"Melee attack (Str+d8)."
		],
		"name": "Summon Ally: Sentinel",
		"pace": "6",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "e06fdaba-8e8b-45bb-0535-93d30836b7c9",
		"armor": "4",
		"climb": 0,
		"edges": [
			"Arcane Resistance",
			"Sweep (Imp)."
		],
		"flies": 0,
		"image": "",
		"parry": "7",
		"active": true,
		"skills": [
			{
				"Name": "Athletics",
				"Value": "d6"
			},
			{
				"Name": "Fighting",
				"Value": "d10"
			},
			{
				"Name": "Intimidation",
				"Value": "d10"
			},
			{
				"Name": "Notice",
				"Value": "d8"
			}
		],
		"book_id": 9,
		"summary": "",
		"bookPage": "p167",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Armor +4",
				"Notes": "Stone skin."
			},
			{
				"Name": "Construct",
				"Notes": "See Attendant."
			},
			{
				"Name": "Fearless",
				"Notes": "Immune to fear and Intimidation."
			},
			{
				"Name": "Size 2",
				"Notes": "Sentinels are 8' tall and very dense."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "13",
		"attributes": {
			"Vigor": "d10",
			"Smarts": "d6",
			"Spirit": "d8",
			"Agility": "d6",
			"Strength": "d12+2"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "From the Summon Ally Power",
		"flying_pace": 0,
		"heavy_armor": false,
		"name_plural": "Summon Ally: Sentinels",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 683,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Summon Ally: Sentinel",
			"race": "Summon Ally: Sentinel",
			"rank": 0,
			"size": 2,
			"uuid": "e06fdaba-8e8b-45bb-0535-93d30836b7c9",
			"armor": [],
			"edges": [
				{
					"id": 174,
					"name": "Arcane Resistance",
					"note": "",
					"bookID": 9,
					"isHidden": false,
					"takenFrom": "",
					"description": "+2 to Trait rolls to resist magical effects; magical damage is reduced by 2.",
					"descriptionHTML": "<p><cite>Savage Worlds: Adventure Edition p.37</cite></p>",
					"customDescription": ""
				},
				{
					"id": 0,
					"name": "Sweep (Imp).",
					"note": "",
					"bookID": 9,
					"isHidden": false,
					"takenFrom": "",
					"description": "",
					"descriptionHTML": "",
					"customDescription": ""
				}
			],
			"image": "",
			"swade": false,
			"bookID": 9,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Athletics",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d10",
					"bookID": 9,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Intimidation",
					"value": "d10",
					"bookID": 9,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": 0,
			"parryHR": "7",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Melee attack",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d8.",
					"innate": false,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Melee attack",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d8.",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d12",
							"parry_modifier": 0,
							"damage_original": "str+d8.",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 2,
							"damageWithBrackets": "str+d8.",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d12",
					"descriptionHTML": "",
					"damageDiceBasePlus": 2,
					"damageWithBrackets": "str+d8."
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Adventure Edition",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Armor +4",
					"note": "Stone skin.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p167",
					"description": "Stone skin."
				},
				{
					"from": "",
					"name": "Construct",
					"note": "See Attendant.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p167",
					"description": "See Attendant."
				},
				{
					"from": "",
					"name": "Fearless",
					"note": "Immune to fear and Intimidation.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p167",
					"description": "Immune to fear and Intimidation."
				},
				{
					"from": "",
					"name": "Size 2",
					"note": "Sentinels are 8' tall and very dense.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p167",
					"description": "Sentinels are 8' tall and very dense."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 6,
			"parryBase": 7,
			"sizeLabel": "2 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 4,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 2,
					"name": "strength",
					"label": "Strength",
					"value": "d12+2",
					"dieValue": 12
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d10",
					"dieValue": 10
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 7,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2019-03-01T20:01:56.000Z",
			"description": "From the Summon Ally Power",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2019-03-01T20:01:56.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 2,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2018",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 7,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 13,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "13 (4)",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/swade/summon-ally: sentinel",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 9,
			"toughnessAsReadNoHeavy": "13 (4)",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 30,
		"gear": [],
		"name": "Swarm",
		"pace": "10",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "af90754f-b25f-420c-2dcc-932a5dbfbd2f",
		"armor": "0",
		"climb": -10,
		"edges": [],
		"flies": 0,
		"image": "",
		"parry": "4",
		"active": true,
		"skills": [
			{
				"Name": "Notice",
				"Value": "d6"
			}
		],
		"book_id": 2,
		"summary": "",
		"bookPage": "p141",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Bite or Sting",
				"Notes": " Swarms inflict hundreds of tiny bites every round to their victims, hitting automatically and causing 2d4 damage to everyone in the template. Damage is applied to the least armored location (victims in completely sealed suits are immune)."
			},
			{
				"Name": "Split",
				"Notes": " Some swarms are clever enough to split into two smaller swarms (Small Burst Templates) should their foes split up. The Toughness of these smaller swarms is lowered by &#x2013;2 (to 5 each)."
			},
			{
				"Name": "Swarm",
				"Notes": " Parry +2; Because the swarm is composed of scores, hundreds, or thousands of creatures, cutting and piercing weapons do no real damage. Area-effect weapons work normally, and a character can stomp to inflict his damage in Strength each round. Swarms are usually foiled by jumping in water (unless they are aquatic pests, such as piranha)."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "7",
		"attributes": {
			"Vigor": "d10",
			"Smarts": "d4 (A)",
			"Spirit": "d12",
			"Agility": "d10",
			"Strength": "d8"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "Sometimes the most deadly foes come in the smallest packages. The swarm described below can be of most anything&#x2014; from biting ants to stinging wasps to filthy rats.",
		"flying_pace": -10,
		"heavy_armor": false,
		"name_plural": "",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 30,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Swarm",
			"race": "Swarm",
			"rank": 0,
			"size": 0,
			"uuid": "af90754f-b25f-420c-2dcc-932a5dbfbd2f",
			"armor": [],
			"edges": [],
			"image": "",
			"swade": false,
			"bookID": 2,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Notice",
					"value": "d6",
					"bookID": 2,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": 4,
			"parryHR": "4",
			"shields": [],
			"weapons": [],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Deluxe (EE)",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Bite or Sting",
					"note": "Swarms inflict hundreds of tiny bites every round to their victims, hitting automatically and causing 2d4 damage to everyone in the template. Damage is applied to the least armored location (victims in completely sealed suits are immune).",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p141",
					"description": "Swarms inflict hundreds of tiny bites every round to their victims, hitting automatically and causing 2d4 damage to everyone in the template. Damage is applied to the least armored location (victims in completely sealed suits are immune)."
				},
				{
					"from": "",
					"name": "Split",
					"note": "Some swarms are clever enough to split into two smaller swarms (Small Burst Templates) should their foes split up. The Toughness of these smaller swarms is lowered by &#x2013;2 (to 5 each).",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p141",
					"description": "Some swarms are clever enough to split into two smaller swarms (Small Burst Templates) should their foes split up. The Toughness of these smaller swarms is lowered by &#x2013;2 (to 5 each)."
				},
				{
					"from": "",
					"name": "Swarm",
					"note": "Parry +2; Because the swarm is composed of scores, hundreds, or thousands of creatures, cutting and piercing weapons do no real damage. Area-effect weapons work normally, and a character can stomp to inflict his damage in Strength each round. Swarms are usually foiled by jumping in water (unless they are aquatic pests, such as piranha).",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p141",
					"description": "Parry +2; Because the swarm is composed of scores, hundreds, or thousands of creatures, cutting and piercing weapons do no real damage. Area-effect weapons work normally, and a character can stomp to inflict his damage in Strength each round. Swarms are usually foiled by jumping in water (unless they are aquatic pests, such as piranha)."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 10,
			"parryBase": 4,
			"sizeLabel": "0 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 0,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d10",
					"dieValue": 10
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d4 (A)",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d12",
					"dieValue": 12
				},
				{
					"mod": 0,
					"name": "strength",
					"label": "Strength",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d10",
					"dieValue": 10
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 4,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2018-05-03T19:05:27.000Z",
			"description": "Sometimes the most deadly foes come in the smallest packages. The swarm described below can be of most anything&#x2014; from biting ants to stinging wasps to filthy rats.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2018-05-03T19:05:27.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 0,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2011",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 7,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 7,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "7",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/deluxe/swarm",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 7,
			"toughnessAsReadNoHeavy": "7",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 671,
		"gear": [],
		"name": "Swarm",
		"pace": "10",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "4bccf5c1-935c-4294-0e4f-bab554b3c6c0",
		"armor": "0",
		"climb": 0,
		"edges": [
			"-"
		],
		"flies": 0,
		"image": "",
		"parry": "4",
		"active": true,
		"skills": [
			{
				"Name": "Notice",
				"Value": "d6"
			}
		],
		"book_id": 9,
		"summary": "",
		"bookPage": "p188",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Bite or Sting",
				"Notes": "Swarms inflict hundreds of tiny bites every round to their victims, hitting automatically and causing 2d4 damage to everyone in the template. Damage is applied to the least armored location (victims in completely sealed suits are immune)."
			},
			{
				"Name": "Split",
				"Notes": "Some swarms split into two smaller swarms when Wounded (GM's call). Reduce the Blast Template one size after a Wound; Small swarms are destroyed."
			},
			{
				"Name": "Swarm",
				"Notes": "Parry +2. Because the swarm is composed of scores, hundreds, or thousands of creatures, cutting and piercing weapons do no real damage. Area effect weapons work normally, and a character can stomp to inflict his damage in Strength each round. Some swarms (bees, hornets, birds) may be foiled by total immersion in water."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "7",
		"attributes": {
			"Vigor": "d10",
			"Smarts": "d4 (A)",
			"Spirit": "d12",
			"Agility": "d10",
			"Strength": "d8"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "Sometimes the most deadly foes come in the smallest packages. The swarm described here can be of most anything-from biting ants to stinging wasps to filthy rats. They cover an area equal to a Large, Medium, or Small Blast Template and attack everyone within it every round. When a swarm is Incapacitated it’s effectively dispersed.",
		"flying_pace": 0,
		"heavy_armor": false,
		"name_plural": "Swarms",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 671,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Swarm",
			"race": "Swarm",
			"rank": 0,
			"size": 0,
			"uuid": "4bccf5c1-935c-4294-0e4f-bab554b3c6c0",
			"armor": [],
			"edges": [
				{
					"id": 0,
					"name": "-",
					"note": "",
					"bookID": 9,
					"isHidden": false,
					"takenFrom": "",
					"description": "",
					"descriptionHTML": "",
					"customDescription": ""
				}
			],
			"image": "",
			"swade": false,
			"bookID": 9,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Notice",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": 4,
			"parryHR": "4",
			"shields": [],
			"weapons": [],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Adventure Edition",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Bite or Sting",
					"note": "Swarms inflict hundreds of tiny bites every round to their victims, hitting automatically and causing 2d4 damage to everyone in the template. Damage is applied to the least armored location (victims in completely sealed suits are immune).",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p188",
					"description": "Swarms inflict hundreds of tiny bites every round to their victims, hitting automatically and causing 2d4 damage to everyone in the template. Damage is applied to the least armored location (victims in completely sealed suits are immune)."
				},
				{
					"from": "",
					"name": "Split",
					"note": "Some swarms split into two smaller swarms when Wounded (GM's call). Reduce the Blast Template one size after a Wound; Small swarms are destroyed.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p188",
					"description": "Some swarms split into two smaller swarms when Wounded (GM's call). Reduce the Blast Template one size after a Wound; Small swarms are destroyed."
				},
				{
					"from": "",
					"name": "Swarm",
					"note": "Parry +2. Because the swarm is composed of scores, hundreds, or thousands of creatures, cutting and piercing weapons do no real damage. Area effect weapons work normally, and a character can stomp to inflict his damage in Strength each round. Some swarms (bees, hornets, birds) may be foiled by total immersion in water.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p188",
					"description": "Parry +2. Because the swarm is composed of scores, hundreds, or thousands of creatures, cutting and piercing weapons do no real damage. Area effect weapons work normally, and a character can stomp to inflict his damage in Strength each round. Some swarms (bees, hornets, birds) may be foiled by total immersion in water."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 10,
			"parryBase": 4,
			"sizeLabel": "0 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 0,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d10",
					"dieValue": 10
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d4 (A)",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d12",
					"dieValue": 12
				},
				{
					"mod": 0,
					"name": "strength",
					"label": "Strength",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d10",
					"dieValue": 10
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 4,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2019-01-23T02:14:39.000Z",
			"description": "Sometimes the most deadly foes come in the smallest packages. The swarm described here can be of most anything-from biting ants to stinging wasps to filthy rats. They cover an area equal to a Large, Medium, or Small Blast Template and attack everyone within it every round. When a swarm is Incapacitated it's effectively dispersed.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2019-01-23T02:14:39.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 0,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2018",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 7,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 7,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "7",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/swade/swarm",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 7,
			"toughnessAsReadNoHeavy": "7",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 31,
		"gear": [
			"Spiked Club (Str+d8)"
		],
		"name": "Troll",
		"pace": "7",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "b0450e6c-c8aa-413a-1019-9df6ce672a8d",
		"armor": "1",
		"climb": -10,
		"edges": [],
		"flies": 0,
		"image": "",
		"parry": "6",
		"active": true,
		"skills": [
			{
				"Name": "Fighting",
				"Value": "d8"
			},
			{
				"Name": "Intimidation",
				"Value": "d10"
			},
			{
				"Name": "Notice",
				"Value": "d6"
			},
			{
				"Name": "Swimming",
				"Value": "d6"
			},
			{
				"Name": "Throwing",
				"Value": "d6"
			}
		],
		"book_id": 2,
		"summary": "",
		"bookPage": "p141",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Armor+1",
				"Notes": " Rubbery hide."
			},
			{
				"Name": "Claws",
				"Notes": " Str+d4."
			},
			{
				"Name": "Improved Sweep",
				"Notes": " May attack all adjacent foes at no penalty."
			},
			{
				"Name": "Fast Regeneration",
				"Notes": " Trollsmayattemptanaturalhealing roll every round unless their wounds were caused by fire or flame. This occurs whether the troll is a Wild Card leader or an Extra. If the latter, a downed troll actually returns to action if it heals itself (and is not Shaken&#x2014;even if it was before being Incapacitated)."
			},
			{
				"Name": "Size+2",
				"Notes": " Trolls are tall, lanky creatures over 8' tall."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "10",
		"attributes": {
			"Vigor": "d10",
			"Smarts": "d4",
			"Spirit": "d6",
			"Agility": "d6",
			"Strength": "d12+2"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "Trolls in myths and legends are horrid, flesh-eating creatures who live in deep woods, beneath bridges, or in hidden mountain caves. In modern games and fiction, trolls are monsters with the ability to regenerate damage and a weakness to fire. These statistics reflect both backgrounds.",
		"flying_pace": -10,
		"heavy_armor": false,
		"name_plural": "",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 31,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Troll",
			"race": "Troll",
			"rank": 0,
			"size": 0,
			"uuid": "b0450e6c-c8aa-413a-1019-9df6ce672a8d",
			"armor": [],
			"edges": [],
			"image": "",
			"swade": false,
			"bookID": 2,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d8",
					"bookID": 2,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Intimidation",
					"value": "d10",
					"bookID": 2,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d6",
					"bookID": 2,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Swimming",
					"value": "d6",
					"bookID": 2,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Throwing",
					"value": "d6",
					"bookID": 2,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": 1,
			"parryHR": "6",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Claws",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d4",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Claws",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d4",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d4+1d12",
							"parry_modifier": 0,
							"damage_original": "str+d4",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 2,
							"damageWithBrackets": "str+d4",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d4+1d12",
					"descriptionHTML": "",
					"damageDiceBasePlus": 2,
					"damageWithBrackets": "str+d4"
				},
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Spiked Club",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d8",
					"innate": false,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Spiked Club",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d8",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d8+1d12",
							"parry_modifier": 0,
							"damage_original": "str+d8",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 2,
							"damageWithBrackets": "str+d8",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d8+1d12",
					"descriptionHTML": "",
					"damageDiceBasePlus": 2,
					"damageWithBrackets": "str+d8"
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Deluxe (EE)",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Armor+1",
					"note": "Rubbery hide.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p141",
					"description": "Rubbery hide."
				},
				{
					"from": "",
					"name": "Claws",
					"note": "Str+d4.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p141",
					"description": "Str+d4."
				},
				{
					"from": "",
					"name": "Improved Sweep",
					"note": "May attack all adjacent foes at no penalty.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p141",
					"description": "May attack all adjacent foes at no penalty."
				},
				{
					"from": "",
					"name": "Fast Regeneration",
					"note": "Trollsmayattemptanaturalhealing roll every round unless their wounds were caused by fire or flame. This occurs whether the troll is a Wild Card leader or an Extra. If the latter, a downed troll actually returns to action if it heals itself (and is not Shaken&#x2014;even if it was before being Incapacitated).",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p141",
					"description": "Trollsmayattemptanaturalhealing roll every round unless their wounds were caused by fire or flame. This occurs whether the troll is a Wild Card leader or an Extra. If the latter, a downed troll actually returns to action if it heals itself (and is not Shaken&#x2014;even if it was before being Incapacitated)."
				},
				{
					"from": "",
					"name": "Size+2",
					"note": "Trolls are tall, lanky creatures over 8' tall.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p141",
					"description": "Trolls are tall, lanky creatures over 8' tall."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 7,
			"parryBase": 6,
			"sizeLabel": "0 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 1,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d4",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 2,
					"name": "strength",
					"label": "Strength",
					"value": "d12+2",
					"dieValue": 12
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d10",
					"dieValue": 10
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 6,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2018-05-03T19:05:27.000Z",
			"description": "Trolls in myths and legends are horrid, flesh-eating creatures who live in deep woods, beneath bridges, or in hidden mountain caves. In modern games and fiction, trolls are monsters with the ability to regenerate damage and a weakness to fire. These statistics reflect both backgrounds.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2018-05-03T19:05:27.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 2,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2011",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 7,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 10,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "10 (1)",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/deluxe/troll",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 9,
			"toughnessAsReadNoHeavy": "10 (1)",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 672,
		"gear": [
			"Spiked club (Str+d8)."
		],
		"name": "Troll",
		"pace": "7",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "d3815219-77a1-4bd8-bccc-caabc682c82b",
		"armor": "1",
		"climb": 0,
		"edges": [
			"Brute",
			"Sweep (Imp)"
		],
		"flies": 0,
		"image": "",
		"parry": "6",
		"active": true,
		"skills": [
			{
				"Name": "Athletics",
				"Value": "d6"
			},
			{
				"Name": "Common Knowledge",
				"Value": "d8"
			},
			{
				"Name": "Fighting",
				"Value": "d8"
			},
			{
				"Name": "Intimidation",
				"Value": "d10"
			},
			{
				"Name": "Notice",
				"Value": "d6"
			},
			{
				"Name": "Stealth",
				"Value": "d4"
			}
		],
		"book_id": 9,
		"summary": "",
		"bookPage": "p189",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Armor +1",
				"Notes": "Rubbery hide."
			},
			{
				"Name": "Bite/Claws",
				"Notes": "Str+d4."
			},
			{
				"Name": "Fast Regeneration",
				"Notes": "Trolls may attempt a natural healing roll every round, even if Incapacitated, unless their Wounds were caused by fire or flame or they're put to the torch afterward."
			},
			{
				"Name": "Resilient",
				"Notes": "Trolls can take one Wound before they're Incapacitated."
			},
			{
				"Name": "Size 1",
				"Notes": "Trolls are tall, lanky creatures over 8′ tall."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "9",
		"attributes": {
			"Vigor": "d10",
			"Smarts": "d4",
			"Spirit": "d6",
			"Agility": "d6",
			"Strength": "d12+2"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "Trolls in myths and legends are horrid, flesheating creatures who live in deep woods, beneath bridges, or in hidden mountain caves. In modern games and fiction, trolls are monsters with the ability to regenerate damage other than that caused by fire. These statistics reflect both backgrounds.",
		"flying_pace": 0,
		"heavy_armor": false,
		"name_plural": "Trolls",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 672,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Troll",
			"race": "Troll",
			"rank": 0,
			"size": 1,
			"uuid": "d3815219-77a1-4bd8-bccc-caabc682c82b",
			"armor": [],
			"edges": [
				{
					"id": 182,
					"name": "Brute",
					"note": "",
					"bookID": 9,
					"isHidden": false,
					"takenFrom": "",
					"description": "Link Athletics to Strength instead of Agility. This includes resisting Athletics Tests as well.",
					"descriptionHTML": "<p><cite>Savage Worlds: Adventure Edition p.38</cite></p>",
					"customDescription": ""
				},
				{
					"id": 0,
					"name": "Sweep (Imp)",
					"note": "",
					"bookID": 9,
					"isHidden": false,
					"takenFrom": "",
					"description": "",
					"descriptionHTML": "",
					"customDescription": ""
				}
			],
			"image": "",
			"swade": false,
			"bookID": 9,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Athletics",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Common Knowledge",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Intimidation",
					"value": "d10",
					"bookID": 9,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Stealth",
					"value": "d4",
					"bookID": 9,
					"isCore": false,
					"dieValue": 4,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": 1,
			"parryHR": "6",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Bite/Claws",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d4",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Bite/Claws",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d4",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d4+1d12",
							"parry_modifier": 0,
							"damage_original": "str+d4",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 2,
							"damageWithBrackets": "str+d4",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d4+1d12",
					"descriptionHTML": "",
					"damageDiceBasePlus": 2,
					"damageWithBrackets": "str+d4"
				},
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Spiked club",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d8.",
					"innate": false,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Spiked club",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d8.",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d12",
							"parry_modifier": 0,
							"damage_original": "str+d8.",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 2,
							"damageWithBrackets": "str+d8.",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d12",
					"descriptionHTML": "",
					"damageDiceBasePlus": 2,
					"damageWithBrackets": "str+d8."
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Adventure Edition",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Armor +1",
					"note": "Rubbery hide.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p189",
					"description": "Rubbery hide."
				},
				{
					"from": "",
					"name": "Bite/Claws",
					"note": "Str+d4.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p189",
					"description": "Str+d4."
				},
				{
					"from": "",
					"name": "Fast Regeneration",
					"note": "Trolls may attempt a natural healing roll every round, even if Incapacitated, unless their Wounds were caused by fire or flame or they're put to the torch afterward.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p189",
					"description": "Trolls may attempt a natural healing roll every round, even if Incapacitated, unless their Wounds were caused by fire or flame or they're put to the torch afterward."
				},
				{
					"from": "",
					"name": "Resilient",
					"note": "Trolls can take one Wound before they're Incapacitated.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p189",
					"description": "Trolls can take one Wound before they're Incapacitated."
				},
				{
					"from": "",
					"name": "Size 1",
					"note": "Trolls are tall, lanky creatures over 8′ tall.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p189",
					"description": "Trolls are tall, lanky creatures over 8′ tall."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 7,
			"parryBase": 6,
			"sizeLabel": "1 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 1,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d4",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 2,
					"name": "strength",
					"label": "Strength",
					"value": "d12+2",
					"dieValue": 12
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d10",
					"dieValue": 10
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 6,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2019-01-23T02:15:00.000Z",
			"description": "Trolls in myths and legends are horrid, flesheating creatures who live in deep woods, beneath bridges, or in hidden mountain caves. In modern games and fiction, trolls are monsters with the ability to regenerate damage other than that caused by fire. These statistics reflect both backgrounds.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2019-01-23T02:15:00.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 1,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2018",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 7,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 9,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "9 (1)",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/swade/troll",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 8,
			"toughnessAsReadNoHeavy": "9 (1)",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 673,
		"gear": [],
		"name": "Vampire",
		"pace": "6",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "a05bae02-8e8b-4dc9-029b-c6a0e6a87ad0",
		"armor": "0",
		"climb": 0,
		"edges": [
			"Frenzy",
			"Level Headed"
		],
		"flies": 0,
		"image": "",
		"parry": "6",
		"active": true,
		"skills": [
			{
				"Name": "Athletics",
				"Value": "d8"
			},
			{
				"Name": "Common Knowledge",
				"Value": "d8"
			},
			{
				"Name": "Fighting",
				"Value": "d8"
			},
			{
				"Name": "Intimidation",
				"Value": "d8"
			},
			{
				"Name": "Notice",
				"Value": "d6"
			},
			{
				"Name": "Persuasion",
				"Value": "d8"
			},
			{
				"Name": "Shooting",
				"Value": "d6"
			},
			{
				"Name": "Stealth",
				"Value": "d10"
			}
		],
		"book_id": 9,
		"summary": "",
		"bookPage": "p189",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": 1,
		"abilities": [
			{
				"Name": "Bite/Claws",
				"Notes": "Str+d4."
			},
			{
				"Name": "Invulnerability",
				"Notes": "Vampires can only be slain by sunlight or a stake through the heart (see those Weaknesses, below). They may be Shaken by other attacks, but never Wounded."
			},
			{
				"Name": "Sire",
				"Notes": "Anyone slain by a vampire has a 50% chance of rising as a vampire in 1d4 days."
			},
			{
				"Name": "Undead",
				"Notes": "+2 Toughness; +2 to recover from being Shaken; no additional damage from Called Shots; ignores 1 point of Wound penalties; doesn't breathe; immune to disease and poison."
			},
			{
				"Name": "Weakness (Holy Symbol)",
				"Notes": "A character with a holy symbol may keep a vampire at bay by displaying a holy symbol. A vampire who wants to directly attack the victim must beat her in an opposed Spirit roll."
			},
			{
				"Name": "Weakness (Holy Water)",
				"Notes": "A vampire sprinkled with holy water is Fatigued. If immersed, he combusts as if it were direct sunlight (see below)."
			},
			{
				"Name": "Weakness (Invitation Only)",
				"Notes": "Vampires cannot enter a private dwelling without being invited. They may enter public domains as they please."
			},
			{
				"Name": "Weakness (Stake Through the Heart)",
				"Notes": "A vampire hit with a Called Shot to the heart (−4) must make a Vigor roll versus the damage. If successful, it takes damage normally. If it fails, it disintegrates to dust."
			},
			{
				"Name": "Weakness (Sunlight)",
				"Notes": "Vampires burn in sunlight. They take 2d4 damage per round until they are ash. Armor protects normally."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "9",
		"attributes": {
			"Vigor": "d10",
			"Smarts": "d8",
			"Spirit": "d8",
			"Agility": "d8",
			"Strength": "d12+1"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "Blood-drinkers of lore are common in many fantasy games. This is a relatively young vampire minion. For weaker vampires, ignore the Invulnerability Special Ability.",
		"flying_pace": 0,
		"heavy_armor": false,
		"name_plural": "Vampires",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 673,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Vampire",
			"race": "Vampire",
			"rank": 0,
			"size": 0,
			"uuid": "a05bae02-8e8b-4dc9-029b-c6a0e6a87ad0",
			"armor": [],
			"edges": [
				{
					"id": 213,
					"name": "Frenzy",
					"note": "",
					"bookID": 9,
					"isHidden": false,
					"takenFrom": "",
					"description": "Roll a second Fighting die with one melee attack per turn.",
					"descriptionHTML": "<p><cite>Savage Worlds: Adventure Edition p.42</cite></p>",
					"customDescription": ""
				},
				{
					"id": 221,
					"name": "Level Headed",
					"note": "",
					"bookID": 9,
					"isHidden": false,
					"takenFrom": "",
					"description": "Draw an additional Action Card each round and choose which one to use.",
					"descriptionHTML": "<p><cite>Savage Worlds: Adventure Edition p.42</cite></p>",
					"customDescription": ""
				}
			],
			"image": "",
			"swade": false,
			"bookID": 9,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Athletics",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Common Knowledge",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Intimidation",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Persuasion",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Shooting",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Stealth",
					"value": "d10",
					"bookID": 9,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 2,
			"fatigue": 0,
			"journal": [],
			"paceMod": 0,
			"parryHR": "6",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Bite/Claws",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d4",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Bite/Claws",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d4",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d4+1d12",
							"parry_modifier": 0,
							"damage_original": "str+d4",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 1,
							"damageWithBrackets": "str+d4",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d4+1d12",
					"descriptionHTML": "",
					"damageDiceBasePlus": 1,
					"damageWithBrackets": "str+d4"
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Adventure Edition",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": true,
			"abilities": [
				{
					"from": "",
					"name": "Bite/Claws",
					"note": "Str+d4.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p189",
					"description": "Str+d4."
				},
				{
					"from": "",
					"name": "Invulnerability",
					"note": "Vampires can only be slain by sunlight or a stake through the heart (see those Weaknesses, below). They may be Shaken by other attacks, but never Wounded.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p189",
					"description": "Vampires can only be slain by sunlight or a stake through the heart (see those Weaknesses, below). They may be Shaken by other attacks, but never Wounded."
				},
				{
					"from": "",
					"name": "Sire",
					"note": "Anyone slain by a vampire has a 50% chance of rising as a vampire in 1d4 days.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p189",
					"description": "Anyone slain by a vampire has a 50% chance of rising as a vampire in 1d4 days."
				},
				{
					"from": "",
					"name": "Undead",
					"note": "+2 Toughness; +2 to recover from being Shaken; no additional damage from Called Shots; ignores 1 point of Wound penalties; doesn't breathe; immune to disease and poison.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p189",
					"description": "+2 Toughness; +2 to recover from being Shaken; no additional damage from Called Shots; ignores 1 point of Wound penalties; doesn't breathe; immune to disease and poison."
				},
				{
					"from": "",
					"name": "Weakness (Holy Symbol)",
					"note": "A character with a holy symbol may keep a vampire at bay by displaying a holy symbol. A vampire who wants to directly attack the victim must beat her in an opposed Spirit roll.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p189",
					"description": "A character with a holy symbol may keep a vampire at bay by displaying a holy symbol. A vampire who wants to directly attack the victim must beat her in an opposed Spirit roll."
				},
				{
					"from": "",
					"name": "Weakness (Holy Water)",
					"note": "A vampire sprinkled with holy water is Fatigued. If immersed, he combusts as if it were direct sunlight (see below).",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p189",
					"description": "A vampire sprinkled with holy water is Fatigued. If immersed, he combusts as if it were direct sunlight (see below)."
				},
				{
					"from": "",
					"name": "Weakness (Invitation Only)",
					"note": "Vampires cannot enter a private dwelling without being invited. They may enter public domains as they please.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p189",
					"description": "Vampires cannot enter a private dwelling without being invited. They may enter public domains as they please."
				},
				{
					"from": "",
					"name": "Weakness (Stake Through the Heart)",
					"note": "A vampire hit with a Called Shot to the heart (-4) must make a Vigor roll versus the damage. If successful, it takes damage normally. If it fails, it disintegrates to dust.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p189",
					"description": "A vampire hit with a Called Shot to the heart (-4) must make a Vigor roll versus the damage. If successful, it takes damage normally. If it fails, it disintegrates to dust."
				},
				{
					"from": "",
					"name": "Weakness (Sunlight)",
					"note": "Vampires burn in sunlight. They take 2d4 damage per round until they are ash. Armor protects normally.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p189",
					"description": "Vampires burn in sunlight. They take 2d4 damage per round until they are ash. Armor protects normally."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 6,
			"parryBase": 6,
			"sizeLabel": "0 (Normal)",
			"strainMax": 0,
			"woundsMax": 3,
			"appVersion": "3.2.3b010603",
			"armorValue": 0,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 1,
					"name": "strength",
					"label": "Strength",
					"value": "d12+1",
					"dieValue": 12
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d10",
					"dieValue": 10
				}
			],
			"background": "",
			"benniesMax": 2,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 6,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 3,
			"bookPrimary": true,
			"createdDate": "2019-01-23T02:15:25.000Z",
			"description": "Blood-drinkers of lore are common in many fantasy games. This is a relatively young vampire minion. For weaker vampires, ignore the Invulnerability Special Ability.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2019-01-23T02:15:25.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 2,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2018",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 7,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 9,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "9",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/swade/vampire",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 9,
			"toughnessAsReadNoHeavy": "9",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 32,
		"gear": [],
		"name": "Vampire, Ancient",
		"pace": "6",
		"race": "",
		"tags": [
			"undead"
		],
		"type": "bestiary",
		"uuid": "18492b52-e5bc-4d7b-0591-9c0ed3f6505c",
		"armor": "0",
		"climb": -10,
		"edges": [],
		"flies": 0,
		"image": "http://static.ddmcdn.com/gif/vampire-power-1.jpg",
		"parry": "7",
		"active": true,
		"skills": [
			{
				"Name": "Fighting",
				"Value": "d10"
			},
			{
				"Name": "Intimidation",
				"Value": "d12"
			},
			{
				"Name": "Notice",
				"Value": "d8"
			},
			{
				"Name": "Shooting",
				"Value": "d8"
			},
			{
				"Name": "Swimming",
				"Value": "d8"
			},
			{
				"Name": "Throwing",
				"Value": "d8"
			}
		],
		"book_id": 2,
		"summary": "",
		"bookPage": "p141",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": 1,
		"abilities": [
			{
				"Name": "Change Form",
				"Notes": " As an action, a vampire can change into a wolf or bat with a Smarts roll at &#x2013;2. Changing back into humanoid form requires a Smarts roll."
			},
			{
				"Name": "Charm",
				"Notes": " Vampires can use the puppet power on the opposite sex using their Smarts as their arcane skill. They can cast and maintain the power indefinitely, but may only affect one target at a time."
			},
			{
				"Name": "Children of the Night",
				"Notes": " Ancient vampires have the ability to summon and control wolves or rats. This requires an action and a Smarts roll at &#x2013;2. If successful, 1d6 wolves or 1d6 swarms of rats (see Swarm) come from the surrounding wilds in 1d6+2 rounds."
			},
			{
				"Name": "Claws",
				"Notes": " Str +d4."
			},
			{
				"Name": "Improved Frenzy",
				"Notes": " Vampires may make two attacks per round without penalty."
			},
			{
				"Name": "Invulnerability",
				"Notes": " Vampires can only be harmed by their Weaknesses. They may be Shaken by other attacks, but never wounded."
			},
			{
				"Name": "Level Headed",
				"Notes": " Vampires act on the best of two cards."
			},
			{
				"Name": "Mist",
				"Notes": " Greater vampires have the ability to turn into mist. This requires an action and a Smarts roll at &#x2013;2."
			},
			{
				"Name": "Sire",
				"Notes": " Anyone slain by a vampire has a 50% chance of rising as a vampire themselves in 1d4 days."
			},
			{
				"Name": "Undead",
				"Notes": " +2 Toughness; +2 to recover from being Shaken; called shots do no extra damage (except to the heart&#x2014;see below). No wound penalties."
			},
			{
				"Name": "Weakness (Sunlight)",
				"Notes": " Vampires catch fire if any part of their skin is exposed to direct sunlight. After that they suffer 2d10 damage per round until they are dust. Armor does not protect."
			},
			{
				"Name": "Weakness (Holy Symbol)",
				"Notes": " A character may keep a vampire at bay by displaying a holy symbol. A vampire who wants to directly attack the victim must beat her in an opposed test of Spirit."
			},
			{
				"Name": "Weakness (Holy Water)",
				"Notes": " A vampire sprinkled with holy water is Fatigued. If immersed, he combusts as if it were direct sunlight (see above)."
			},
			{
				"Name": "Weakness (Invitation Only)",
				"Notes": " Vampires cannot enter a private dwelling without being invited. They may enter public domains as they please."
			},
			{
				"Name": "Weakness (Stake Through the Heart)",
				"Notes": " A vampire hit with a called shot to the heart (&#x2013;4) must make a Vigor roll versus the damage total. If successful, it takes damage normally. If it fails, it disintegrates to dust."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "10",
		"attributes": {
			"Vigor": "d12",
			"Smarts": "d10",
			"Spirit": "d10",
			"Agility": "d8",
			"Strength": "d12+3"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "Blood-drinkers of lore are common in many fantasy games. The statistics below are for a vampire somewhat below the legendary Dracula, but far above those bloodsuckers fresh from the grave (detailed next). The abilities listed below are standard&#x2014;the GM may want to add other Edges as befits the vampire's previous lifestyle.",
		"flying_pace": -10,
		"heavy_armor": false,
		"name_plural": "",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 32,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Vampire, Ancient",
			"race": "Vampire, Ancient",
			"rank": 0,
			"size": 0,
			"uuid": "18492b52-e5bc-4d7b-0591-9c0ed3f6505c",
			"armor": [],
			"edges": [],
			"image": "https://savaged.ushttp://static.ddmcdn.com/gif/vampire-power-1.jpg",
			"swade": false,
			"bookID": 2,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d10",
					"bookID": 2,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Intimidation",
					"value": "d12",
					"bookID": 2,
					"isCore": false,
					"dieValue": 12,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d8",
					"bookID": 2,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Shooting",
					"value": "d8",
					"bookID": 2,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Swimming",
					"value": "d8",
					"bookID": 2,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Throwing",
					"value": "d8",
					"bookID": 2,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 2,
			"fatigue": 0,
			"journal": [],
			"paceMod": 0,
			"parryHR": "7",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Weakness (Sunlight)",
					"uuid": "",
					"notes": " After that they suffer 2d10 damage per round until they are dust",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "vampires",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Weakness (Sunlight)",
							"notes": " After that they suffer 2d10 damage per round until they are dust",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "vampires",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "",
							"parry_modifier": 0,
							"damage_original": "vampires",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": null,
							"damageWithBrackets": "vampires",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "",
					"descriptionHTML": "",
					"damageDiceBasePlus": null,
					"damageWithBrackets": "vampires"
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Deluxe (EE)",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": true,
			"abilities": [
				{
					"from": "",
					"name": "Change Form",
					"note": "As an action, a vampire can change into a wolf or bat with a Smarts roll at &#x2013;2. Changing back into humanoid form requires a Smarts roll.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p141",
					"description": "As an action, a vampire can change into a wolf or bat with a Smarts roll at &#x2013;2. Changing back into humanoid form requires a Smarts roll."
				},
				{
					"from": "",
					"name": "Charm",
					"note": "Vampires can use the puppet power on the opposite sex using their Smarts as their arcane skill. They can cast and maintain the power indefinitely, but may only affect one target at a time.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p141",
					"description": "Vampires can use the puppet power on the opposite sex using their Smarts as their arcane skill. They can cast and maintain the power indefinitely, but may only affect one target at a time."
				},
				{
					"from": "",
					"name": "Children of the Night",
					"note": "Ancient vampires have the ability to summon and control wolves or rats. This requires an action and a Smarts roll at &#x2013;2. If successful, 1d6 wolves or 1d6 swarms of rats (see Swarm) come from the surrounding wilds in 1d6+2 rounds.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p141",
					"description": "Ancient vampires have the ability to summon and control wolves or rats. This requires an action and a Smarts roll at &#x2013;2. If successful, 1d6 wolves or 1d6 swarms of rats (see Swarm) come from the surrounding wilds in 1d6+2 rounds."
				},
				{
					"from": "",
					"name": "Claws",
					"note": "Str +d4.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p141",
					"description": "Str +d4."
				},
				{
					"from": "",
					"name": "Improved Frenzy",
					"note": "Vampires may make two attacks per round without penalty.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p141",
					"description": "Vampires may make two attacks per round without penalty."
				},
				{
					"from": "",
					"name": "Invulnerability",
					"note": "Vampires can only be harmed by their Weaknesses. They may be Shaken by other attacks, but never wounded.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p141",
					"description": "Vampires can only be harmed by their Weaknesses. They may be Shaken by other attacks, but never wounded."
				},
				{
					"from": "",
					"name": "Level Headed",
					"note": "Vampires act on the best of two cards.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p141",
					"description": "Vampires act on the best of two cards."
				},
				{
					"from": "",
					"name": "Mist",
					"note": "Greater vampires have the ability to turn into mist. This requires an action and a Smarts roll at &#x2013;2.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p141",
					"description": "Greater vampires have the ability to turn into mist. This requires an action and a Smarts roll at &#x2013;2."
				},
				{
					"from": "",
					"name": "Sire",
					"note": "Anyone slain by a vampire has a 50% chance of rising as a vampire themselves in 1d4 days.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p141",
					"description": "Anyone slain by a vampire has a 50% chance of rising as a vampire themselves in 1d4 days."
				},
				{
					"from": "",
					"name": "Undead",
					"note": "+2 Toughness; +2 to recover from being Shaken; called shots do no extra damage (except to the heart&#x2014;see below). No wound penalties.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p141",
					"description": "+2 Toughness; +2 to recover from being Shaken; called shots do no extra damage (except to the heart&#x2014;see below). No wound penalties."
				},
				{
					"from": "",
					"name": "Weakness (Sunlight)",
					"note": "Vampires catch fire if any part of their skin is exposed to direct sunlight. After that they suffer 2d10 damage per round until they are dust. Armor does not protect.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p141",
					"description": "Vampires catch fire if any part of their skin is exposed to direct sunlight. After that they suffer 2d10 damage per round until they are dust. Armor does not protect."
				},
				{
					"from": "",
					"name": "Weakness (Holy Symbol)",
					"note": "A character may keep a vampire at bay by displaying a holy symbol. A vampire who wants to directly attack the victim must beat her in an opposed test of Spirit.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p141",
					"description": "A character may keep a vampire at bay by displaying a holy symbol. A vampire who wants to directly attack the victim must beat her in an opposed test of Spirit."
				},
				{
					"from": "",
					"name": "Weakness (Holy Water)",
					"note": "A vampire sprinkled with holy water is Fatigued. If immersed, he combusts as if it were direct sunlight (see above).",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p141",
					"description": "A vampire sprinkled with holy water is Fatigued. If immersed, he combusts as if it were direct sunlight (see above)."
				},
				{
					"from": "",
					"name": "Weakness (Invitation Only)",
					"note": "Vampires cannot enter a private dwelling without being invited. They may enter public domains as they please.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p141",
					"description": "Vampires cannot enter a private dwelling without being invited. They may enter public domains as they please."
				},
				{
					"from": "",
					"name": "Weakness (Stake Through the Heart)",
					"note": "A vampire hit with a called shot to the heart (&#x2013;4) must make a Vigor roll versus the damage total. If successful, it takes damage normally. If it fails, it disintegrates to dust.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p141",
					"description": "A vampire hit with a called shot to the heart (&#x2013;4) must make a Vigor roll versus the damage total. If successful, it takes damage normally. If it fails, it disintegrates to dust."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 6,
			"parryBase": 7,
			"sizeLabel": "0 (Normal)",
			"strainMax": 0,
			"woundsMax": 3,
			"appVersion": "3.2.3b010603",
			"armorValue": 0,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d10",
					"dieValue": 10
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d10",
					"dieValue": 10
				},
				{
					"mod": 3,
					"name": "strength",
					"label": "Strength",
					"value": "d12+3",
					"dieValue": 12
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d12",
					"dieValue": 12
				}
			],
			"background": "",
			"benniesMax": 2,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 7,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 3,
			"bookPrimary": true,
			"createdDate": "2018-05-03T19:05:27.000Z",
			"description": "Blood-drinkers of lore are common in many fantasy games. The statistics below are for a vampire somewhat below the legendary Dracula, but far above those bloodsuckers fresh from the grave (detailed next). The abilities listed below are standard&#x2014;the GM may want to add other Edges as befits the vampire's previous lifestyle.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2018-05-03T19:05:27.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 2,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2011",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 8,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 10,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "10",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/deluxe/vampire-ancient",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 10,
			"toughnessAsReadNoHeavy": "10",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 33,
		"gear": [],
		"name": "Vampire, Young",
		"pace": "6",
		"race": "",
		"tags": [
			"undead"
		],
		"type": "bestiary",
		"uuid": "da8967e9-1538-4927-07d7-d302392fd985",
		"armor": "0",
		"climb": -10,
		"edges": [],
		"flies": 0,
		"image": "http://upload.wikimedia.org/wikipedia/commons/f/f8/VampireE3.jpg",
		"parry": "6",
		"active": true,
		"skills": [
			{
				"Name": "Fighting",
				"Value": "d8"
			},
			{
				"Name": "Intimidation",
				"Value": "d8"
			},
			{
				"Name": "Notice",
				"Value": "d6"
			},
			{
				"Name": "Shooting",
				"Value": "d6"
			},
			{
				"Name": "Swimming",
				"Value": "d8"
			},
			{
				"Name": "Throwing",
				"Value": "d6"
			}
		],
		"book_id": 2,
		"summary": "",
		"bookPage": "p142",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Claws",
				"Notes": " Str +d4."
			},
			{
				"Name": "Frenzy",
				"Notes": " Vampires can make two attacks per round with a &#x2013;2 penalty to each attack."
			},
			{
				"Name": "Level Headed",
				"Notes": " Vampires act on the best of two cards."
			},
			{
				"Name": "Invulnerability",
				"Notes": " Vampires can only be harmed by their Weaknesses. They may be Shaken by other attacks, but never wounded."
			},
			{
				"Name": "Sire",
				"Notes": " Anyone slain by a vampire has a 50% chance of rising as a vampire themselves in 1d4 days."
			},
			{
				"Name": "Undead",
				"Notes": " +2 Toughness; +2 to recover from being Shaken; called shots do no extra damage (except to the heart&#x2014;see below). No wound penalties."
			},
			{
				"Name": "Weakness (Sunlight)",
				"Notes": " Vampires catch fire if any part of their skin is exposed to direct sunlight. After that they suffer 2d10 damage per round until they are dust. Armor does not protect."
			},
			{
				"Name": "Weakness (Holy Symbol)",
				"Notes": " A character may keep a vampire at bay by displaying a holy symbol. A vampire who wants to directly attack the victim must beat her in an opposed test of Spirit."
			},
			{
				"Name": "Weakness (Holy Water)",
				"Notes": " A vampire sprinkled with holy water is Fatigued. If immersed, he combusts as if it were direct sunlight (see above)."
			},
			{
				"Name": "Weakness (Invitation Only)",
				"Notes": " Vampires cannot enter a private dwelling without being invited. They may enter public domains as they please."
			},
			{
				"Name": "Weakness (Stake Through the Heart)",
				"Notes": " A vampire hit with a called shot to the heart (&#x2013;4) must make a Vigor roll versus the damage total. If successful, it takes damage normally. If it fails, it disintegrates to dust."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "9",
		"attributes": {
			"Vigor": "d10",
			"Smarts": "d8",
			"Spirit": "d8",
			"Agility": "d8",
			"Strength": "d12+1"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "Blood-drinkers of lore are common in many fantasy games. This is a relatively young vampire minion.",
		"flying_pace": -10,
		"heavy_armor": false,
		"name_plural": "",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 33,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Vampire, Young",
			"race": "Vampire, Young",
			"rank": 0,
			"size": 0,
			"uuid": "da8967e9-1538-4927-07d7-d302392fd985",
			"armor": [],
			"edges": [],
			"image": "https://savaged.ushttp://upload.wikimedia.org/wikipedia/commons/f/f8/VampireE3.jpg",
			"swade": false,
			"bookID": 2,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d8",
					"bookID": 2,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Intimidation",
					"value": "d8",
					"bookID": 2,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d6",
					"bookID": 2,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Shooting",
					"value": "d6",
					"bookID": 2,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Swimming",
					"value": "d8",
					"bookID": 2,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Throwing",
					"value": "d6",
					"bookID": 2,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": 0,
			"parryHR": "6",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Weakness (Sunlight)",
					"uuid": "",
					"notes": " After that they suffer 2d10 damage per round until they are dust",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "vampires",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Weakness (Sunlight)",
							"notes": " After that they suffer 2d10 damage per round until they are dust",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "vampires",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "",
							"parry_modifier": 0,
							"damage_original": "vampires",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": null,
							"damageWithBrackets": "vampires",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "",
					"descriptionHTML": "",
					"damageDiceBasePlus": null,
					"damageWithBrackets": "vampires"
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Deluxe (EE)",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Claws",
					"note": "Str +d4.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p142",
					"description": "Str +d4."
				},
				{
					"from": "",
					"name": "Frenzy",
					"note": "Vampires can make two attacks per round with a &#x2013;2 penalty to each attack.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p142",
					"description": "Vampires can make two attacks per round with a &#x2013;2 penalty to each attack."
				},
				{
					"from": "",
					"name": "Level Headed",
					"note": "Vampires act on the best of two cards.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p142",
					"description": "Vampires act on the best of two cards."
				},
				{
					"from": "",
					"name": "Invulnerability",
					"note": "Vampires can only be harmed by their Weaknesses. They may be Shaken by other attacks, but never wounded.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p142",
					"description": "Vampires can only be harmed by their Weaknesses. They may be Shaken by other attacks, but never wounded."
				},
				{
					"from": "",
					"name": "Sire",
					"note": "Anyone slain by a vampire has a 50% chance of rising as a vampire themselves in 1d4 days.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p142",
					"description": "Anyone slain by a vampire has a 50% chance of rising as a vampire themselves in 1d4 days."
				},
				{
					"from": "",
					"name": "Undead",
					"note": "+2 Toughness; +2 to recover from being Shaken; called shots do no extra damage (except to the heart&#x2014;see below). No wound penalties.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p142",
					"description": "+2 Toughness; +2 to recover from being Shaken; called shots do no extra damage (except to the heart&#x2014;see below). No wound penalties."
				},
				{
					"from": "",
					"name": "Weakness (Sunlight)",
					"note": "Vampires catch fire if any part of their skin is exposed to direct sunlight. After that they suffer 2d10 damage per round until they are dust. Armor does not protect.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p142",
					"description": "Vampires catch fire if any part of their skin is exposed to direct sunlight. After that they suffer 2d10 damage per round until they are dust. Armor does not protect."
				},
				{
					"from": "",
					"name": "Weakness (Holy Symbol)",
					"note": "A character may keep a vampire at bay by displaying a holy symbol. A vampire who wants to directly attack the victim must beat her in an opposed test of Spirit.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p142",
					"description": "A character may keep a vampire at bay by displaying a holy symbol. A vampire who wants to directly attack the victim must beat her in an opposed test of Spirit."
				},
				{
					"from": "",
					"name": "Weakness (Holy Water)",
					"note": "A vampire sprinkled with holy water is Fatigued. If immersed, he combusts as if it were direct sunlight (see above).",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p142",
					"description": "A vampire sprinkled with holy water is Fatigued. If immersed, he combusts as if it were direct sunlight (see above)."
				},
				{
					"from": "",
					"name": "Weakness (Invitation Only)",
					"note": "Vampires cannot enter a private dwelling without being invited. They may enter public domains as they please.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p142",
					"description": "Vampires cannot enter a private dwelling without being invited. They may enter public domains as they please."
				},
				{
					"from": "",
					"name": "Weakness (Stake Through the Heart)",
					"note": "A vampire hit with a called shot to the heart (&#x2013;4) must make a Vigor roll versus the damage total. If successful, it takes damage normally. If it fails, it disintegrates to dust.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p142",
					"description": "A vampire hit with a called shot to the heart (&#x2013;4) must make a Vigor roll versus the damage total. If successful, it takes damage normally. If it fails, it disintegrates to dust."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 6,
			"parryBase": 6,
			"sizeLabel": "0 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 0,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 1,
					"name": "strength",
					"label": "Strength",
					"value": "d12+1",
					"dieValue": 12
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d10",
					"dieValue": 10
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 6,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2018-05-03T19:05:27.000Z",
			"description": "Blood-drinkers of lore are common in many fantasy games. This is a relatively young vampire minion.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2018-05-03T19:05:27.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 2,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2011",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 7,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 9,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "9",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/deluxe/vampire-young",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 9,
			"toughnessAsReadNoHeavy": "9",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 34,
		"gear": [],
		"name": "Water Elemental",
		"pace": "6",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "033b8047-c06a-44e3-a7d6-1cf75c88aa16",
		"armor": "0",
		"climb": -10,
		"edges": [],
		"flies": 0,
		"image": "http://www.santharia.com/pictures/quellion/quellion_pics/water_elemental.jpg",
		"parry": "6",
		"active": true,
		"skills": [
			{
				"Name": "Fighting",
				"Value": "d8"
			},
			{
				"Name": "Notice",
				"Value": "d6"
			},
			{
				"Name": "Shooting",
				"Value": "d8"
			},
			{
				"Name": "Swimming",
				"Value": "d12+2"
			}
		],
		"book_id": 2,
		"summary": "",
		"bookPage": "p137",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Aquatic",
				"Notes": " Pace 12"
			},
			{
				"Name": "Elemental",
				"Notes": " No additional damage from called shots;Fearless; Immune to disease and poison."
			},
			{
				"Name": "Invulnerability",
				"Notes": " Water elementals are immune to all non-magical attacks except fire. A torch or lantern causes them 1d6 damage but is instantly put out if it hits."
			},
			{
				"Name": "Seep",
				"Notes": " Water elementals can squeeze through any porous gap as if it were Difficult Ground."
			},
			{
				"Name": "Slam",
				"Notes": " Str+d6; nonleathal damage."
			},
			{
				"Name": "Waterspout",
				"Notes": " Water spirits can project a torrent of water using the Cone Template. Those in the area may make an Agility roll opposed by the spirit's Shooting to avoid it or suffer 2d8 nonlethal damage. This puts out any normal fires."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "7",
		"attributes": {
			"Vigor": "d10",
			"Smarts": "d6",
			"Spirit": "d6",
			"Agility": "d8",
			"Strength": "d10"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "Water spirits are frothing, man-shaped creatures of water and sea-foam.",
		"flying_pace": -10,
		"heavy_armor": false,
		"name_plural": "",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 34,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Water Elemental",
			"race": "Water Elemental",
			"rank": 0,
			"size": 0,
			"uuid": "033b8047-c06a-44e3-a7d6-1cf75c88aa16",
			"armor": [],
			"edges": [],
			"image": "https://savaged.ushttp://www.santharia.com/pictures/quellion/quellion_pics/water_elemental.jpg",
			"swade": false,
			"bookID": 2,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d8",
					"bookID": 2,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d6",
					"bookID": 2,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Shooting",
					"value": "d8",
					"bookID": 2,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 2,
					"name": "Swimming",
					"value": "d12",
					"bookID": 2,
					"isCore": false,
					"dieValue": 12,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": 0,
			"parryHR": "6",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Slam",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d6;",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Slam",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d6;",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d10",
							"parry_modifier": 0,
							"damage_original": "str+d6;",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 0,
							"damageWithBrackets": "str+d6;",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d10",
					"descriptionHTML": "",
					"damageDiceBasePlus": 0,
					"damageWithBrackets": "str+d6;"
				},
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Waterspout",
					"uuid": "",
					"notes": " Those in the area may make an Agility roll opposed by the spirit's Shooting to avoid it or suffer 2d8 nonlethal damage",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "water",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Waterspout",
							"notes": " Those in the area may make an Agility roll opposed by the spirit's Shooting to avoid it or suffer 2d8 nonlethal damage",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "water",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "",
							"parry_modifier": 0,
							"damage_original": "water",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": null,
							"damageWithBrackets": "water",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "",
					"descriptionHTML": "",
					"damageDiceBasePlus": null,
					"damageWithBrackets": "water"
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Deluxe (EE)",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Aquatic",
					"note": "Pace 12",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p137",
					"description": "Pace 12"
				},
				{
					"from": "",
					"name": "Elemental",
					"note": "No additional damage from called shots;Fearless; Immune to disease and poison.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p137",
					"description": "No additional damage from called shots;Fearless; Immune to disease and poison."
				},
				{
					"from": "",
					"name": "Invulnerability",
					"note": "Water elementals are immune to all non-magical attacks except fire. A torch or lantern causes them 1d6 damage but is instantly put out if it hits.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p137",
					"description": "Water elementals are immune to all non-magical attacks except fire. A torch or lantern causes them 1d6 damage but is instantly put out if it hits."
				},
				{
					"from": "",
					"name": "Seep",
					"note": "Water elementals can squeeze through any porous gap as if it were Difficult Ground.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p137",
					"description": "Water elementals can squeeze through any porous gap as if it were Difficult Ground."
				},
				{
					"from": "",
					"name": "Slam",
					"note": "Str+d6; nonleathal damage.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p137",
					"description": "Str+d6; nonleathal damage."
				},
				{
					"from": "",
					"name": "Waterspout",
					"note": "Water spirits can project a torrent of water using the Cone Template. Those in the area may make an Agility roll opposed by the spirit's Shooting to avoid it or suffer 2d8 nonlethal damage. This puts out any normal fires.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p137",
					"description": "Water spirits can project a torrent of water using the Cone Template. Those in the area may make an Agility roll opposed by the spirit's Shooting to avoid it or suffer 2d8 nonlethal damage. This puts out any normal fires."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 6,
			"parryBase": 6,
			"sizeLabel": "0 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 0,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "strength",
					"label": "Strength",
					"value": "d10",
					"dieValue": 10
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d10",
					"dieValue": 10
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 6,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2018-05-03T19:05:27.000Z",
			"description": "Water spirits are frothing, man-shaped creatures of water and sea-foam.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2018-05-03T19:05:27.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 0,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2011",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 7,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 7,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "7",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/deluxe/water-elemental",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 7,
			"toughnessAsReadNoHeavy": "7",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 644,
		"gear": [],
		"name": "Water Elemental",
		"pace": "6",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "28d36a7b-26b6-11ec-9078-bbf2fd7bd78a",
		"armor": "0",
		"climb": 0,
		"edges": [
			"-"
		],
		"flies": 0,
		"image": "http://www.santharia.com/pictures/quellion/quellion_pics/water_elemental.jpg",
		"parry": "6",
		"active": true,
		"skills": [
			{
				"Name": "Athletics",
				"Value": " d6"
			},
			{
				"Name": "Fighting",
				"Value": " d8"
			},
			{
				"Name": "Notice",
				"Value": " d6"
			},
			{
				"Name": "Shooting",
				"Value": " d8"
			},
			{
				"Name": "Stealth",
				"Value": " d8"
			}
		],
		"book_id": 9,
		"summary": "",
		"bookPage": "p183",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Aquatic",
				"Notes": " Pace 12″."
			},
			{
				"Name": "Elemental",
				"Notes": " No additional damage from Called Shots, ignores 1 point of Wound penalties, doesn't breathe, immune to disease and poison."
			},
			{
				"Name": "Immunity",
				"Notes": " Immune to water-based attacks."
			},
			{
				"Name": "Seep",
				"Notes": " Can squeeze through any porous gap as if it were Difficult Ground."
			},
			{
				"Name": "Slam",
				"Notes": " Str+d6, nonlethal damage."
			},
			{
				"Name": "Waterspout",
				"Notes": "2d6 range cone Can project a torrent of water using the Cone Template and their Shooting skill. Those within take 2d6 nonlethal damage. This may be Evaded."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "7",
		"attributes": {
			"Vigor": "d10",
			"Smarts": "d6",
			"Spirit": "d6",
			"Agility": "d8",
			"Strength": "d10"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "These spirits are frothing, man-shaped beings of water.",
		"flying_pace": 0,
		"heavy_armor": false,
		"name_plural": "Water Elementals",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 644,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Water Elemental",
			"race": "Water Elemental",
			"rank": 0,
			"size": 0,
			"uuid": "28d36a7b-26b6-11ec-9078-bbf2fd7bd78a",
			"armor": [],
			"edges": [
				{
					"id": 0,
					"name": "-",
					"note": "",
					"bookID": 9,
					"isHidden": false,
					"takenFrom": "",
					"description": "",
					"descriptionHTML": "",
					"customDescription": ""
				}
			],
			"image": "https://savaged.ushttp://www.santharia.com/pictures/quellion/quellion_pics/water_elemental.jpg",
			"swade": false,
			"bookID": 9,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Athletics",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Shooting",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Stealth",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": 0,
			"parryHR": "6",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Slam",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d6",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Slam",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d6",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d6+1d10",
							"parry_modifier": 0,
							"damage_original": "str+d6",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 0,
							"damageWithBrackets": "str+d6",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d6+1d10",
					"descriptionHTML": "",
					"damageDiceBasePlus": 0,
					"damageWithBrackets": "str+d6"
				},
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Waterspout",
					"uuid": "",
					"notes": " Those within take 2d6 nonlethal damage",
					"range": "cone",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "2d6",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Waterspout",
							"notes": " Those within take 2d6 nonlethal damage",
							"range": "cone",
							"reach": 0,
							"shots": 0,
							"damage": "2d6",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "2d6",
							"parry_modifier": 0,
							"damage_original": "2d6",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 0,
							"damageWithBrackets": "2d6",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "2d6",
					"descriptionHTML": "",
					"damageDiceBasePlus": 0,
					"damageWithBrackets": "2d6"
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Adventure Edition",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Aquatic",
					"note": "Pace 12″.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p183",
					"description": "Pace 12″."
				},
				{
					"from": "",
					"name": "Elemental",
					"note": "No additional damage from Called Shots, ignores 1 point of Wound penalties, doesn't breathe, immune to disease and poison.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p183",
					"description": "No additional damage from Called Shots, ignores 1 point of Wound penalties, doesn't breathe, immune to disease and poison."
				},
				{
					"from": "",
					"name": "Immunity",
					"note": "Immune to water-based attacks.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p183",
					"description": "Immune to water-based attacks."
				},
				{
					"from": "",
					"name": "Seep",
					"note": "Can squeeze through any porous gap as if it were Difficult Ground.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p183",
					"description": "Can squeeze through any porous gap as if it were Difficult Ground."
				},
				{
					"from": "",
					"name": "Slam",
					"note": "Str+d6, nonlethal damage.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p183",
					"description": "Str+d6, nonlethal damage."
				},
				{
					"from": "",
					"name": "Waterspout",
					"note": "2d6 range cone Can project a torrent of water using the Cone Template and their Shooting skill. Those within take 2d6 nonlethal damage. This may be Evaded.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p183",
					"description": "2d6 range cone Can project a torrent of water using the Cone Template and their Shooting skill. Those within take 2d6 nonlethal damage. This may be Evaded."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 6,
			"parryBase": 6,
			"sizeLabel": "0 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 0,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "strength",
					"label": "Strength",
					"value": "d10",
					"dieValue": 10
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d10",
					"dieValue": 10
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 6,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2019-01-23T02:04:57.000Z",
			"description": "These spirits are frothing, man-shaped beings of water.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2021-10-06T15:01:49.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 0,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2018",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 7,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 7,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "7",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/swade/water-elemental",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 7,
			"toughnessAsReadNoHeavy": "7",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 35,
		"gear": [],
		"name": "Werewolf",
		"pace": "8",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "aed99374-0724-4797-2a9c-3b913fdf7eac",
		"armor": "0",
		"climb": -10,
		"edges": [],
		"flies": 0,
		"image": "",
		"parry": "9",
		"active": true,
		"skills": [
			{
				"Name": "Climbing",
				"Value": "d8"
			},
			{
				"Name": "Fighting",
				"Value": "d12+2"
			},
			{
				"Name": "Intimidation",
				"Value": "d10"
			},
			{
				"Name": "Notice",
				"Value": "d12"
			},
			{
				"Name": "Swimming",
				"Value": "d10"
			},
			{
				"Name": "Stealth",
				"Value": "d10"
			},
			{
				"Name": "Tracking",
				"Value": "d10"
			}
		],
		"book_id": 2,
		"summary": "",
		"bookPage": "p142",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Claws",
				"Notes": " Str +d8."
			},
			{
				"Name": "Infection",
				"Notes": " Anyone slain by a werewolf has a 50% chance of rising as a werewolf themselves. The character involuntarily transforms every full moon. He gains control of his lycanthropy only after 1d6 years as a werewolf."
			},
			{
				"Name": "Invulnerability",
				"Notes": " Werewolves can only be Shaken by weapons that are not silver&#x2014;not wounded."
			},
			{
				"Name": "Infravision",
				"Notes": " Werewolves can see heat and halve penalties for bad lighting when attacking living targets."
			},
			{
				"Name": "Weakness",
				"Notes": " Werewolves suffer normal damage from silver weapons."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "7",
		"attributes": {
			"Vigor": "d10",
			"Smarts": "d6",
			"Spirit": "d6",
			"Agility": "d8",
			"Strength": "d12+2"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "When a full moon emerges, humans infected with lycanthropy lose control and become snarling creatures bent on murder. Some embrace their cursed state and revel in the destruction they cause.",
		"flying_pace": -10,
		"heavy_armor": false,
		"name_plural": "",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 35,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Werewolf",
			"race": "Werewolf",
			"rank": 0,
			"size": 0,
			"uuid": "aed99374-0724-4797-2a9c-3b913fdf7eac",
			"armor": [],
			"edges": [],
			"image": "",
			"swade": false,
			"bookID": 2,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Climbing",
					"value": "d8",
					"bookID": 2,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 2,
					"name": "Fighting",
					"value": "d12",
					"bookID": 2,
					"isCore": false,
					"dieValue": 12,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Intimidation",
					"value": "d10",
					"bookID": 2,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d12",
					"bookID": 2,
					"isCore": false,
					"dieValue": 12,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Swimming",
					"value": "d10",
					"bookID": 2,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Stealth",
					"value": "d10",
					"bookID": 2,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Tracking",
					"value": "d10",
					"bookID": 2,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": 2,
			"parryHR": "9",
			"shields": [],
			"weapons": [],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Deluxe (EE)",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Claws",
					"note": "Str +d8.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p142",
					"description": "Str +d8."
				},
				{
					"from": "",
					"name": "Infection",
					"note": "Anyone slain by a werewolf has a 50% chance of rising as a werewolf themselves. The character involuntarily transforms every full moon. He gains control of his lycanthropy only after 1d6 years as a werewolf.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p142",
					"description": "Anyone slain by a werewolf has a 50% chance of rising as a werewolf themselves. The character involuntarily transforms every full moon. He gains control of his lycanthropy only after 1d6 years as a werewolf."
				},
				{
					"from": "",
					"name": "Invulnerability",
					"note": "Werewolves can only be Shaken by weapons that are not silver&#x2014;not wounded.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p142",
					"description": "Werewolves can only be Shaken by weapons that are not silver&#x2014;not wounded."
				},
				{
					"from": "",
					"name": "Infravision",
					"note": "Werewolves can see heat and halve penalties for bad lighting when attacking living targets.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p142",
					"description": "Werewolves can see heat and halve penalties for bad lighting when attacking living targets."
				},
				{
					"from": "",
					"name": "Weakness",
					"note": "Werewolves suffer normal damage from silver weapons.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p142",
					"description": "Werewolves suffer normal damage from silver weapons."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 8,
			"parryBase": 9,
			"sizeLabel": "0 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 0,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 2,
					"name": "strength",
					"label": "Strength",
					"value": "d12+2",
					"dieValue": 12
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d10",
					"dieValue": 10
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 9,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2018-05-03T19:05:27.000Z",
			"description": "When a full moon emerges, humans infected with lycanthropy lose control and become snarling creatures bent on murder. Some embrace their cursed state and revel in the destruction they cause.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2018-05-03T19:05:27.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 0,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2011",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 7,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 7,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "7",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/deluxe/werewolf",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 7,
			"toughnessAsReadNoHeavy": "7",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 675,
		"gear": [],
		"name": "Werewolf",
		"pace": "8",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "438cd895-30f6-4226-29d9-026d6bd08d09",
		"armor": "0",
		"climb": 0,
		"edges": [
			"-"
		],
		"flies": 0,
		"image": "",
		"parry": "9",
		"active": true,
		"skills": [
			{
				"Name": "Athletics",
				"Value": "d8"
			},
			{
				"Name": "Common Knowledge",
				"Value": "d8"
			},
			{
				"Name": "Fighting",
				"Value": "d12+2"
			},
			{
				"Name": "Intimidation",
				"Value": "d10"
			},
			{
				"Name": "Notice",
				"Value": "d12"
			},
			{
				"Name": "Stealth",
				"Value": "d10"
			},
			{
				"Name": "Survival",
				"Value": "d10"
			}
		],
		"book_id": 9,
		"summary": "",
		"bookPage": "p190",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Bite/Claws",
				"Notes": "Str+d8."
			},
			{
				"Name": "Fast Regeneration",
				"Notes": "Werewolves may attempt a natural healing roll every round unless the Wounds were caused by silvered objects."
			},
			{
				"Name": "Fear (−2)",
				"Notes": "Werewolves chill the blood of all who see them."
			},
			{
				"Name": "Infection",
				"Notes": "Anyone slain by a werewolf has a 50% chance of rising as a werewolf themselves. The character involuntarily transforms every full moon. He gains control of his lycanthropy only after 1d6 years as a werewolf."
			},
			{
				"Name": "Infravision",
				"Notes": "Halve penalties for Illumination when attacking warm targets."
			},
			{
				"Name": "Size 1",
				"Notes": "Werewolves are stout creatures."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "8",
		"attributes": {
			"Vigor": "d10",
			"Smarts": "d6",
			"Spirit": "d6",
			"Agility": "d8",
			"Strength": "d12+2"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "When a full moon emerges, humans infected with lycanthropy lose control and become snarling creatures bent on murder. Some embrace their cursed state and revel in the destruction they cause. Customize the Special Abilities that make sense for your particular flesh-ripper. Older or “pure-blood” lycanthropes may be Invulnerable to all but silver or magic, for example.",
		"flying_pace": 0,
		"heavy_armor": false,
		"name_plural": "Werewolves",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 675,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Werewolf",
			"race": "Werewolf",
			"rank": 0,
			"size": 1,
			"uuid": "438cd895-30f6-4226-29d9-026d6bd08d09",
			"armor": [],
			"edges": [
				{
					"id": 0,
					"name": "-",
					"note": "",
					"bookID": 9,
					"isHidden": false,
					"takenFrom": "",
					"description": "",
					"descriptionHTML": "",
					"customDescription": ""
				}
			],
			"image": "",
			"swade": false,
			"bookID": 9,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Athletics",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Common Knowledge",
					"value": "d8",
					"bookID": 9,
					"isCore": false,
					"dieValue": 8,
					"attribute": ""
				},
				{
					"mod": 2,
					"name": "Fighting",
					"value": "d12",
					"bookID": 9,
					"isCore": false,
					"dieValue": 12,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Intimidation",
					"value": "d10",
					"bookID": 9,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d12",
					"bookID": 9,
					"isCore": false,
					"dieValue": 12,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Stealth",
					"value": "d10",
					"bookID": 9,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Survival",
					"value": "d10",
					"bookID": 9,
					"isCore": false,
					"dieValue": 10,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": 2,
			"parryHR": "9",
			"shields": [],
			"weapons": [
				{
					"ap": 0,
					"id": 0,
					"rof": 0,
					"cost": 0,
					"name": "Bite/Claws",
					"uuid": "",
					"notes": "",
					"range": "Melee",
					"reach": 0,
					"shots": 0,
					"bookID": 0,
					"damage": "str+d8",
					"innate": true,
					"minStr": "",
					"thrown": false,
					"weight": 0,
					"costBuy": 0,
					"equipped": true,
					"profiles": [
						{
							"ap": 0,
							"rof": 0,
							"name": "Bite/Claws",
							"notes": "",
							"range": "Melee",
							"reach": 0,
							"shots": 0,
							"damage": "str+d8",
							"equipped": true,
							"toHitMod": 0,
							"vtt_only": false,
							"is_shield": false,
							"skillName": "",
							"melee_only": false,
							"skillValue": "",
							"currentShots": 0,
							"heavy_weapon": false,
							"thrown_weapon": false,
							"damageDiceBase": "1d8+1d12",
							"parry_modifier": 0,
							"damage_original": "str+d8",
							"usable_in_melee": false,
							"counts_as_innate": false,
							"requires_2_hands": true,
							"damageDiceBasePlus": 2,
							"damageWithBrackets": "str+d8",
							"add_strength_to_damage": false,
							"ap_vs_rigid_armor_only": 0
						}
					],
					"quantity": 1,
					"takenFrom": "",
					"equippedAs": "primary",
					"activeProfile": 0,
					"bookPublished": "",
					"bookPublisher": "",
					"damageDiceBase": "1d8+1d12",
					"descriptionHTML": "",
					"damageDiceBasePlus": 2,
					"damageWithBrackets": "str+d8"
				}
			],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Adventure Edition",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Bite/Claws",
					"note": "Str+d8.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p190",
					"description": "Str+d8."
				},
				{
					"from": "",
					"name": "Fast Regeneration",
					"note": "Werewolves may attempt a natural healing roll every round unless the Wounds were caused by silvered objects.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p190",
					"description": "Werewolves may attempt a natural healing roll every round unless the Wounds were caused by silvered objects."
				},
				{
					"from": "",
					"name": "Fear (-2)",
					"note": "Werewolves chill the blood of all who see them.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p190",
					"description": "Werewolves chill the blood of all who see them."
				},
				{
					"from": "",
					"name": "Infection",
					"note": "Anyone slain by a werewolf has a 50% chance of rising as a werewolf themselves. The character involuntarily transforms every full moon. He gains control of his lycanthropy only after 1d6 years as a werewolf.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p190",
					"description": "Anyone slain by a werewolf has a 50% chance of rising as a werewolf themselves. The character involuntarily transforms every full moon. He gains control of his lycanthropy only after 1d6 years as a werewolf."
				},
				{
					"from": "",
					"name": "Infravision",
					"note": "Halve penalties for Illumination when attacking warm targets.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p190",
					"description": "Halve penalties for Illumination when attacking warm targets."
				},
				{
					"from": "",
					"name": "Size 1",
					"note": "Werewolves are stout creatures.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p190",
					"description": "Werewolves are stout creatures."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 8,
			"parryBase": 9,
			"sizeLabel": "1 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 0,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d8",
					"dieValue": 8
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 2,
					"name": "strength",
					"label": "Strength",
					"value": "d12+2",
					"dieValue": 12
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d10",
					"dieValue": 10
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 9,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2019-01-23T02:16:10.000Z",
			"description": "When a full moon emerges, humans infected with lycanthropy lose control and become snarling creatures bent on murder. Some embrace their cursed state and revel in the destruction they cause. Customize the Special Abilities that make sense for your particular flesh-ripper. Older or \"pure-blood\" lycanthropes may be Invulnerable to all but silver or magic, for example.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2019-01-23T02:16:10.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 1,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2018",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 7,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 8,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "8",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/swade/werewolf",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 8,
			"toughnessAsReadNoHeavy": "8",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 36,
		"gear": [],
		"name": "Zombie",
		"pace": "4",
		"race": "",
		"tags": [
			"undead"
		],
		"type": "bestiary",
		"uuid": "613b8a61-4507-469a-af4d-6b3c887b01ff",
		"armor": "0",
		"climb": -10,
		"edges": [],
		"flies": 0,
		"image": "http://cdn.foodbeast.com.s3.amazonaws.com/content/wp-content/uploads/2013/02/zombies-620x412.jpeg",
		"parry": "5",
		"active": true,
		"skills": [
			{
				"Name": "Fighting",
				"Value": "d6"
			},
			{
				"Name": "Intimidation",
				"Value": "d6"
			},
			{
				"Name": "Notice",
				"Value": "d4"
			},
			{
				"Name": "Shooting",
				"Value": "d6"
			}
		],
		"book_id": 2,
		"summary": "",
		"bookPage": "p142",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Claws",
				"Notes": " Str."
			},
			{
				"Name": "Fearless",
				"Notes": " Zombies are immune to Fear and Intimidation."
			},
			{
				"Name": "Undead",
				"Notes": " +2 Toughness; +2 to recover from being Shaken; called shots do no extra damage (except to the head)."
			},
			{
				"Name": "Weakness (Head)",
				"Notes": ""
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "7",
		"attributes": {
			"Vigor": "d6",
			"Smarts": "d4",
			"Spirit": "d4",
			"Agility": "d6",
			"Strength": "d6"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "These walking dead are typical groaning fiends looking for fresh meat.",
		"flying_pace": -10,
		"heavy_armor": false,
		"name_plural": "",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 36,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Zombie",
			"race": "Zombie",
			"rank": 0,
			"size": 0,
			"uuid": "613b8a61-4507-469a-af4d-6b3c887b01ff",
			"armor": [],
			"edges": [],
			"image": "https://savaged.ushttp://cdn.foodbeast.com.s3.amazonaws.com/content/wp-content/uploads/2013/02/zombies-620x412.jpeg",
			"swade": false,
			"bookID": 2,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d6",
					"bookID": 2,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Intimidation",
					"value": "d6",
					"bookID": 2,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d4",
					"bookID": 2,
					"isCore": false,
					"dieValue": 4,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Shooting",
					"value": "d6",
					"bookID": 2,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": -2,
			"parryHR": "5",
			"shields": [],
			"weapons": [],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Deluxe (EE)",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Claws",
					"note": "Str.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p142",
					"description": "Str."
				},
				{
					"from": "",
					"name": "Fearless",
					"note": "Zombies are immune to Fear and Intimidation.",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p142",
					"description": "Zombies are immune to Fear and Intimidation."
				},
				{
					"from": "",
					"name": "Undead",
					"note": "+2 Toughness; +2 to recover from being Shaken; called shots do no extra damage (except to the head).",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p142",
					"description": "+2 Toughness; +2 to recover from being Shaken; called shots do no extra damage (except to the head)."
				},
				{
					"from": "",
					"name": "Weakness (Head)",
					"note": "",
					"bookID": 2,
					"positive": true,
					"takenFrom": "Deluxe p.p142",
					"description": ""
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 4,
			"parryBase": 5,
			"sizeLabel": "0 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 0,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d4",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d4",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "strength",
					"label": "Strength",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d6",
					"dieValue": 6
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 5,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2018-05-03T19:05:27.000Z",
			"description": "These walking dead are typical groaning fiends looking for fresh meat.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2018-05-03T19:05:27.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 2,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2011",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 5,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 7,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "7",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/deluxe/zombie",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 7,
			"toughnessAsReadNoHeavy": "7",
			"raceGenderAndProfession": ""
		}
	},
	{
		"id": 676,
		"gear": [],
		"name": "Zombie",
		"pace": "4",
		"race": "",
		"tags": [],
		"type": "bestiary",
		"uuid": "5fe60c5e-ee5f-493d-98ac-b87b25c32ab8",
		"armor": "0",
		"climb": 0,
		"edges": [
			"-"
		],
		"flies": 0,
		"image": "http://cdn.foodbeast.com.s3.amazonaws.com/content/wp-content/uploads/2013/02/zombies-620x412.jpeg",
		"parry": "5",
		"active": true,
		"skills": [
			{
				"Name": "Athletics",
				"Value": "d4"
			},
			{
				"Name": "Fighting",
				"Value": "d6"
			},
			{
				"Name": "Intimidation",
				"Value": "d6"
			},
			{
				"Name": "Notice",
				"Value": "d4"
			},
			{
				"Name": "Shooting",
				"Value": "d6"
			}
		],
		"book_id": 9,
		"summary": "",
		"bookPage": "p191",
		"charisma": 0,
		"imageURL": "",
		"treasure": "",
		"wildcard": false,
		"abilities": [
			{
				"Name": "Bite/Claws",
				"Notes": "Str."
			},
			{
				"Name": "Fearless",
				"Notes": "Zombies are immune to Fear and Intimidation."
			},
			{
				"Name": "Undead",
				"Notes": "+2 Toughness; +2 to recover from being Shaken; no additional damage from Called Shots; ignores 1 point of Wound penalties; doesn't breathe; immune to disease and poison."
			},
			{
				"Name": "Weakness (Head)",
				"Notes": "Called Shots to a zombie's head do the usual +4 damage."
			}
		],
		"cyberware": [],
		"is_custom": false,
		"read_only": false,
		"toughness": "7",
		"attributes": {
			"Vigor": "d6",
			"Smarts": "d4",
			"Spirit": "d4",
			"Agility": "d6",
			"Strength": "d6"
		},
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"hindrances": [],
		"updated_by": 0,
		"version_of": 0,
		"description": "These walking dead are typical groaning fiends looking for fresh meat.",
		"flying_pace": 0,
		"heavy_armor": false,
		"name_plural": "Zombies",
		"customSaveId": 0,
		"swimming_pace": 0,
		"export_generic_json": {
			"id": 676,
			"xp": 0,
			"abs": [],
			"age": "",
			"gear": [],
			"load": 0,
			"name": "Zombie",
			"race": "Zombie",
			"rank": 0,
			"size": 0,
			"uuid": "5fe60c5e-ee5f-493d-98ac-b87b25c32ab8",
			"armor": [],
			"edges": [
				{
					"id": 0,
					"name": "-",
					"note": "",
					"bookID": 9,
					"isHidden": false,
					"takenFrom": "",
					"description": "",
					"descriptionHTML": "",
					"customDescription": ""
				}
			],
			"image": "https://savaged.ushttp://cdn.foodbeast.com.s3.amazonaws.com/content/wp-content/uploads/2013/02/zombies-620x412.jpeg",
			"swade": false,
			"bookID": 9,
			"gender": "",
			"sanity": 0,
			"saveID": 0,
			"skills": [
				{
					"mod": 0,
					"name": "Athletics",
					"value": "d4",
					"bookID": 9,
					"isCore": false,
					"dieValue": 4,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Fighting",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Intimidation",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Notice",
					"value": "d4",
					"bookID": 9,
					"isCore": false,
					"dieValue": 4,
					"attribute": ""
				},
				{
					"mod": 0,
					"name": "Shooting",
					"value": "d6",
					"bookID": 9,
					"isCore": false,
					"dieValue": 6,
					"attribute": ""
				}
			],
			"usesXP": false,
			"wealth": 0,
			"wounds": 0,
			"bennies": 0,
			"fatigue": 0,
			"journal": [],
			"paceMod": -2,
			"parryHR": "5",
			"shields": [],
			"weapons": [],
			"advances": [],
			"bookCore": true,
			"bookName": "Savage Worlds: Adventure Edition",
			"charisma": 0,
			"paceBase": 6,
			"parryMod": 0,
			"rankName": "Bestiary",
			"vehicles": [],
			"wildcard": false,
			"abilities": [
				{
					"from": "",
					"name": "Bite/Claws",
					"note": "Str.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p191",
					"description": "Str."
				},
				{
					"from": "",
					"name": "Fearless",
					"note": "Zombies are immune to Fear and Intimidation.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p191",
					"description": "Zombies are immune to Fear and Intimidation."
				},
				{
					"from": "",
					"name": "Undead",
					"note": "+2 Toughness; +2 to recover from being Shaken; no additional damage from Called Shots; ignores 1 point of Wound penalties; doesn't breathe; immune to disease and poison.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p191",
					"description": "+2 Toughness; +2 to recover from being Shaken; no additional damage from Called Shots; ignores 1 point of Wound penalties; doesn't breathe; immune to disease and poison."
				},
				{
					"from": "",
					"name": "Weakness (Head)",
					"note": "Called Shots to a zombie's head do the usual +4 damage.",
					"bookID": 9,
					"positive": true,
					"takenFrom": "SWADE p.p191",
					"description": "Called Shots to a zombie's head do the usual +4 damage."
				}
			],
			"allSkills": [],
			"cyberware": [],
			"languages": [],
			"loadLimit": 0,
			"paceTotal": 4,
			"parryBase": 5,
			"sizeLabel": "0 (Normal)",
			"strainMax": 0,
			"woundsMax": 0,
			"appVersion": "3.2.3b010603",
			"armorValue": 0,
			"attributes": [
				{
					"mod": 0,
					"name": "agility",
					"label": "Agility",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "smarts",
					"label": "Smarts",
					"value": "d4",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "spirit",
					"label": "Spirit",
					"value": "d4",
					"dieValue": 4
				},
				{
					"mod": 0,
					"name": "strength",
					"label": "Strength",
					"value": "d6",
					"dieValue": 6
				},
				{
					"mod": 0,
					"name": "vigor",
					"label": "Vigor",
					"value": "d6",
					"dieValue": 6
				}
			],
			"background": "",
			"benniesMax": 0,
			"fatigueMax": 2,
			"heavyArmor": false,
			"hindrances": [],
			"imageToken": "",
			"parryTotal": 5,
			"playerName": "",
			"runningDie": "d6",
			"usesSanity": false,
			"usesStrain": false,
			"woundsBase": 0,
			"bookPrimary": true,
			"createdDate": "2019-01-23T02:16:19.000Z",
			"description": "These walking dead are typical groaning fiends looking for fresh meat.",
			"parryShield": 0,
			"scholarship": 0,
			"updatedDate": "2019-01-23T02:16:19.000Z",
			"alliedExtras": [],
			"naturalArmor": [],
			"otherAttacks": [],
			"ripperReason": 0,
			"ripperStatus": 0,
			"settingImage": "",
			"toughnessMod": 2,
			"usesCharisma": true,
			"advancesCount": 0,
			"bookPublished": "2018",
			"bookPublisher": "Pinnacle Entertainment Group",
			"loadLimitBase": 0,
			"noPowerPoints": false,
			"strainCurrent": 0,
			"toughnessBase": 5,
			"unarmoredHero": false,
			"woundsSizeMod": 0,
			"naturalIsHeavy": false,
			"toughnessTotal": 7,
			"wealthDieBonus": 0,
			"wealthDieValue": "",
			"woundsOtherMod": 0,
			"iconicFramework": "",
			"playerCharacter": false,
			"riftsDisclaimer": false,
			"toughnessAsRead": "7",
			"wealthDieActive": false,
			"wealthFormatted": "0",
			"loadLimitModifier": 0,
			"professionOrTitle": "",
			"savagedUsShareURL": "https://savaged.us/bestiary/swade/zombie",
			"usesRippersReason": false,
			"usesRippersStatus": false,
			"toughnessModifiers": [],
			"usesETUScholarship": false,
			"toughnessHeavyLabel": "",
			"wealthDieCalculated": "",
			"hideHumanRaceDisplay": false,
			"toughnessTotalNoArmor": 7,
			"toughnessAsReadNoHeavy": "7",
			"raceGenderAndProfession": ""
		}
	}
];

export default Bestiary;