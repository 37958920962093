import styled from "styled-components";

const ButtonSmall = styled.button`
	background:url(/assets/burnblade/button_small.png);
	background-size:300% 100%;
	width:65px;
	height:65px;
	line-height:65px;
	border:none;
	box-sizing: border-box;
	padding:0;
	display:flex;
	align-items: center;
	justify-content: center;
	
	&[disabled] { cursor: pointer; }
	&:not([disabled]) {
		&:hover { background-position: -65px 0; }
		&:active { background-position: -130px 0; }
	}
`;

const ButtonPlus = styled.button`
	background:url(/assets/burnblade/button_plus.png);
	background-size:300% 100%;
	width:73px;
	height:73px;
	border:none;
	box-sizing: border-box;
	padding:0;
	display:flex;
	align-items: center;
	justify-content: center;
	
	&[disabled] { cursor: pointer; }
	&:not([disabled]) {
		&:hover { background-position: -73px 0; }
		&:active { background-position: -146px 0; }
	}
`;

export default ButtonSmall;
export {ButtonPlus};