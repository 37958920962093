const Armor = [
	{
		"id": 95,
		"run": "",
		"cost": 250,
		"name": "Ballistic Shield",
		"pace": 0,
		"size": 0,
		"type": "Shields",
		"uuid": "53ebcb4a-508a-4371-1b69-046a86366680",
		"heavy": false,
		"active": true,
		"weight": 9,
		"book_id": 9,
		"effects": [],
		"summary": "Reduces damage from firearms by 4 when an attacker tries to shoot through it.",
		"bookPage": "p71",
		"hardness": 0,
		"quantity": 1,
		"abilities": [
			"Ballistic Shield:Reduces damage from firearms by 4 when an attacker tries to shoot through it."
		],
		"is_custom": false,
		"is_shield": true,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 0,
		"covers_arms": false,
		"covers_face": false,
		"covers_head": false,
		"covers_legs": false,
		"description": "",
		"name_plural": "Ballistic Shields",
		"negate_4_ap": true,
		"rigid_armor": false,
		"ap_vs_lasers": 0,
		"covers_torso": false,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "d4",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 3,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": -4,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 58,
		"run": "",
		"cost": 1500,
		"name": "Barding (horse)",
		"pace": 0,
		"size": 0,
		"type": "Ancient Armor",
		"uuid": "dd0d8fef-01f7-490d-33aa-f57f80939343",
		"heavy": false,
		"active": true,
		"weight": 50,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p69",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 3,
		"covers_arms": false,
		"covers_face": false,
		"covers_head": false,
		"covers_legs": false,
		"description": "",
		"name_plural": "Barding (horse)",
		"negate_4_ap": false,
		"rigid_armor": false,
		"ap_vs_lasers": 0,
		"covers_torso": false,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "d8",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 89,
		"run": "",
		"cost": 100,
		"name": "Battle Helmet",
		"pace": 0,
		"size": 0,
		"type": "Futuristic Armor",
		"uuid": "b089a760-178f-49e5-900f-bf123d9f1ea2",
		"heavy": false,
		"active": true,
		"weight": 2,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p70",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 6,
		"covers_arms": false,
		"covers_face": true,
		"covers_head": true,
		"covers_legs": false,
		"description": "",
		"name_plural": "Battle Helmets",
		"negate_4_ap": true,
		"rigid_armor": false,
		"ap_vs_lasers": 0,
		"covers_torso": false,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "d6",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 90,
		"run": "",
		"cost": 150,
		"name": "Battle Helmet w/ Energy Skin",
		"pace": 0,
		"size": 0,
		"type": "Futuristic Armor",
		"uuid": "de68175f-9012-41ed-0dfa-c17299d3192a",
		"heavy": false,
		"active": true,
		"weight": 2,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p70",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 6,
		"covers_arms": false,
		"covers_face": true,
		"covers_head": true,
		"covers_legs": false,
		"description": "",
		"name_plural": "Battle Helmets w/ Energy Skin",
		"negate_4_ap": true,
		"rigid_armor": false,
		"ap_vs_lasers": 4,
		"covers_torso": false,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "d6",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 78,
		"run": "",
		"cost": 50,
		"name": "Bike helmet",
		"pace": 0,
		"size": 0,
		"type": "Modern Armor",
		"uuid": "37a792c4-f4d0-4b98-08cf-33cb8314962b",
		"heavy": false,
		"active": true,
		"weight": 1,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p70",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 2,
		"covers_arms": false,
		"covers_face": false,
		"covers_head": true,
		"covers_legs": false,
		"description": "",
		"name_plural": "Bike helmets",
		"negate_4_ap": false,
		"rigid_armor": false,
		"ap_vs_lasers": 0,
		"covers_torso": false,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "d4",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 85,
		"run": "",
		"cost": 200,
		"name": "Body Armor",
		"pace": 0,
		"size": 0,
		"type": "Futuristic Armor",
		"uuid": "13a3feac-295d-4d39-8933-1ef7e3c64a05",
		"heavy": false,
		"active": true,
		"weight": 4,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p70",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 4,
		"covers_arms": true,
		"covers_face": false,
		"covers_head": false,
		"covers_legs": true,
		"description": "",
		"name_plural": "Body Armors",
		"negate_4_ap": true,
		"rigid_armor": false,
		"ap_vs_lasers": 0,
		"covers_torso": true,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "d4",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 86,
		"run": "",
		"cost": 300,
		"name": "Body Armor w/Energy Skin",
		"pace": 0,
		"size": 0,
		"type": "Futuristic Armor",
		"uuid": "6ff6bb1f-4f00-45e7-bd93-58cb488ce8e2",
		"heavy": false,
		"active": true,
		"weight": 4,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p70",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 4,
		"covers_arms": true,
		"covers_face": false,
		"covers_head": false,
		"covers_legs": true,
		"description": "",
		"name_plural": "Body Armors w/Energy Skin",
		"negate_4_ap": true,
		"rigid_armor": false,
		"ap_vs_lasers": 4,
		"covers_torso": true,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "d4",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 84,
		"run": "",
		"cost": 25000,
		"name": "Bombproof suit",
		"pace": 0,
		"size": 0,
		"type": "Modern Armor",
		"uuid": "ad40eafb-c9b4-431b-b228-72324e77f0b8",
		"heavy": false,
		"active": true,
		"weight": 80,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p70",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 10,
		"covers_arms": true,
		"covers_face": true,
		"covers_head": true,
		"covers_legs": true,
		"description": "",
		"name_plural": "Bombproof suits",
		"negate_4_ap": false,
		"rigid_armor": false,
		"ap_vs_lasers": 0,
		"covers_torso": true,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "d12",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 55,
		"run": "",
		"cost": 40,
		"name": "Bronze Arm Guards (vambraces)",
		"pace": 0,
		"size": 0,
		"type": "Ancient Armor",
		"uuid": "8f23fefd-e50f-46ee-9ff6-3a2247c6ee00",
		"heavy": false,
		"active": true,
		"weight": 5,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p69",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 3,
		"covers_arms": true,
		"covers_face": false,
		"covers_head": false,
		"covers_legs": false,
		"description": "",
		"name_plural": "Bronze Arm Guards (vambraces)",
		"negate_4_ap": false,
		"rigid_armor": false,
		"ap_vs_lasers": 0,
		"covers_torso": false,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "d8",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 54,
		"run": "",
		"cost": 80,
		"name": "Bronze Corselet",
		"pace": 0,
		"size": 0,
		"type": "Ancient Armor",
		"uuid": "525e3519-4824-49d8-a70e-507f3efe3805",
		"heavy": false,
		"active": true,
		"weight": 13,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p69",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 3,
		"covers_arms": false,
		"covers_face": false,
		"covers_head": false,
		"covers_legs": false,
		"description": "",
		"name_plural": "Bronze Corselets",
		"negate_4_ap": false,
		"rigid_armor": false,
		"ap_vs_lasers": 0,
		"covers_torso": true,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "d8",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 57,
		"run": "",
		"cost": 25,
		"name": "Bronze Helmet (head)",
		"pace": 0,
		"size": 0,
		"type": "Ancient Armor",
		"uuid": "f1c91cb5-6951-4b89-bff6-ed2f61e72e84",
		"heavy": false,
		"active": true,
		"weight": 6,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p69",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 3,
		"covers_arms": false,
		"covers_face": false,
		"covers_head": true,
		"covers_legs": false,
		"description": "",
		"name_plural": "Bronze Helmet (head)",
		"negate_4_ap": false,
		"rigid_armor": false,
		"ap_vs_lasers": 0,
		"covers_torso": false,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "d8",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 56,
		"run": "",
		"cost": 50,
		"name": "Bronze Leg Guards (greaves)",
		"pace": 0,
		"size": 0,
		"type": "Ancient Armor",
		"uuid": "90ea7f28-8d9e-4915-2438-ea7754f4527c",
		"heavy": false,
		"active": true,
		"weight": 6,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p69",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 3,
		"covers_arms": false,
		"covers_face": false,
		"covers_head": false,
		"covers_legs": true,
		"description": "",
		"name_plural": "Bronze Leg Guards (greaves)",
		"negate_4_ap": false,
		"rigid_armor": false,
		"ap_vs_lasers": 0,
		"covers_torso": false,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "d6",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 3,
		"run": "",
		"cost": 300,
		"name": "Chain Hauberk (long coat)",
		"pace": 0,
		"size": 0,
		"type": "Medieval Armor",
		"uuid": "ea1b12c2-f616-448c-b7d3-1a880a194a48",
		"heavy": false,
		"active": true,
		"weight": 25,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p61",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 2,
		"covers_arms": true,
		"covers_face": false,
		"covers_head": false,
		"covers_legs": true,
		"description": "",
		"name_plural": "Chain Hauberk (long coat)",
		"negate_4_ap": false,
		"rigid_armor": false,
		"ap_vs_lasers": 0,
		"covers_torso": true,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 68,
		"run": "",
		"cost": 25,
		"name": "Chain Hood",
		"pace": 0,
		"size": 0,
		"type": "Medieval & Ancient Armor",
		"uuid": "d99773d7-7102-45b4-29d4-6e5f18fa0b06",
		"heavy": false,
		"active": true,
		"weight": 4,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p69",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 3,
		"covers_arms": false,
		"covers_face": false,
		"covers_head": true,
		"covers_legs": false,
		"description": "",
		"name_plural": "Chain Hoods",
		"negate_4_ap": false,
		"rigid_armor": false,
		"ap_vs_lasers": 0,
		"covers_torso": false,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "d6",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 146,
		"run": "",
		"cost": 25,
		"name": "Chain Hood or Helm",
		"pace": 0,
		"size": 0,
		"type": "Ancient Armor",
		"uuid": "9f5b03e7-47f5-402e-8b4b-bf5abd418945",
		"heavy": false,
		"active": true,
		"weight": 4,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p69",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 3,
		"covers_arms": false,
		"covers_face": false,
		"covers_head": true,
		"covers_legs": false,
		"description": "",
		"name_plural": "Chain Hood or Helm",
		"negate_4_ap": false,
		"rigid_armor": false,
		"ap_vs_lasers": 0,
		"covers_torso": false,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "d8",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 67,
		"run": "",
		"cost": 150,
		"name": "Chain Leggings",
		"pace": 0,
		"size": 0,
		"type": "Medieval & Ancient Armor",
		"uuid": "7a6d3b52-b6ab-4eba-87dd-e2b903455973",
		"heavy": false,
		"active": true,
		"weight": 10,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p69",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 3,
		"covers_arms": false,
		"covers_face": false,
		"covers_head": false,
		"covers_legs": true,
		"description": "",
		"name_plural": "Chain Leggings",
		"negate_4_ap": false,
		"rigid_armor": false,
		"ap_vs_lasers": 0,
		"covers_torso": false,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "d6",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 66,
		"run": "",
		"cost": 300,
		"name": "Chain Shirt",
		"pace": 0,
		"size": 0,
		"type": "Medieval & Ancient Armor",
		"uuid": "92cfd929-a3de-4b8e-3898-e2b5d5698ebc",
		"heavy": false,
		"active": true,
		"weight": 25,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p69",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 3,
		"covers_arms": true,
		"covers_face": false,
		"covers_head": false,
		"covers_legs": false,
		"description": "",
		"name_plural": "Chain Shirts",
		"negate_4_ap": false,
		"rigid_armor": false,
		"ap_vs_lasers": 0,
		"covers_torso": true,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "d6",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 62,
		"run": "",
		"cost": 5,
		"name": "Cloth Cap",
		"pace": 0,
		"size": 0,
		"type": "Medieval & Ancient Armor",
		"uuid": "e8c76c9d-3c15-47a9-8983-a96bbe9c07c2",
		"heavy": false,
		"active": true,
		"weight": 1,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p69",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 1,
		"covers_arms": false,
		"covers_face": false,
		"covers_head": true,
		"covers_legs": false,
		"description": "",
		"name_plural": "Cloth Cap",
		"negate_4_ap": false,
		"rigid_armor": false,
		"ap_vs_lasers": 0,
		"covers_torso": false,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "d4",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 59,
		"run": "",
		"cost": 20,
		"name": "Cloth Jacket",
		"pace": 0,
		"size": 0,
		"type": "Medieval & Ancient Armor",
		"uuid": "60e876a4-185d-499f-bb54-4e9d8a4a1608",
		"heavy": false,
		"active": true,
		"weight": 5,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p69",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 1,
		"covers_arms": true,
		"covers_face": false,
		"covers_head": false,
		"covers_legs": false,
		"description": "",
		"name_plural": "Cloth Jackets",
		"negate_4_ap": false,
		"rigid_armor": false,
		"ap_vs_lasers": 0,
		"covers_torso": true,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "d4",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 61,
		"run": "",
		"cost": 20,
		"name": "Cloth Leggings",
		"pace": 0,
		"size": 0,
		"type": "Medieval & Ancient Armor",
		"uuid": "06828221-9e12-4609-86a0-4ab1ea19010c",
		"heavy": false,
		"active": true,
		"weight": 5,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p69",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 1,
		"covers_arms": false,
		"covers_face": false,
		"covers_head": false,
		"covers_legs": true,
		"description": "",
		"name_plural": "Cloth Leggings",
		"negate_4_ap": false,
		"rigid_armor": false,
		"ap_vs_lasers": 0,
		"covers_torso": false,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "d4",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 60,
		"run": "",
		"cost": 30,
		"name": "Cloth Robes",
		"pace": 0,
		"size": 0,
		"type": "Medieval & Ancient Armor",
		"uuid": "1a7e8e39-13bf-4cd3-37c0-f28f595ab39a",
		"heavy": false,
		"active": true,
		"weight": 8,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p69",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 1,
		"covers_arms": true,
		"covers_face": false,
		"covers_head": false,
		"covers_legs": true,
		"description": "",
		"name_plural": "Cloth Robes",
		"negate_4_ap": false,
		"rigid_armor": false,
		"ap_vs_lasers": 0,
		"covers_torso": true,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "d4",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 12,
		"run": "",
		"cost": 80,
		"name": "Flak Jacket",
		"pace": 0,
		"size": 0,
		"type": "Modern Armor",
		"uuid": "27266223-16ab-433c-07b8-24d935b2097d",
		"heavy": false,
		"active": true,
		"weight": 12,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p61",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 2,
		"covers_arms": false,
		"covers_face": false,
		"covers_head": false,
		"covers_legs": false,
		"description": "",
		"name_plural": "Flak Jackets",
		"negate_4_ap": false,
		"rigid_armor": false,
		"ap_vs_lasers": 0,
		"covers_torso": true,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 4,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 80,
		"run": "",
		"cost": 40,
		"name": "Flak Jacket (Vietnam-era)",
		"pace": 0,
		"size": 0,
		"type": "Modern Armor",
		"uuid": "8a2faa5e-7f7e-4cf4-8249-e4974ec8030f",
		"heavy": false,
		"active": true,
		"weight": 10,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p70",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 3,
		"covers_arms": false,
		"covers_face": false,
		"covers_head": false,
		"covers_legs": false,
		"description": "",
		"name_plural": "Flak Jackets (Vietnam-era)",
		"negate_4_ap": false,
		"rigid_armor": false,
		"ap_vs_lasers": 0,
		"covers_torso": true,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "d6",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 18,
		"run": "",
		"cost": 1000000,
		"name": "Hard Armor",
		"pace": 0,
		"size": 0,
		"type": "Futuristic Armor",
		"uuid": "dc8e8567-7c40-4d80-8ad7-7cff8d4100a6",
		"heavy": false,
		"active": true,
		"weight": 30,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p61",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 8,
		"covers_arms": true,
		"covers_face": true,
		"covers_head": true,
		"covers_legs": true,
		"description": "",
		"name_plural": "Hard Armor",
		"negate_4_ap": false,
		"rigid_armor": true,
		"ap_vs_lasers": 0,
		"covers_torso": true,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 72,
		"run": "",
		"cost": 100,
		"name": "Heavy Helm (Plate)",
		"pace": 0,
		"size": 0,
		"type": "Medieval & Ancient Armor",
		"uuid": "85fdbb8d-5dc6-4d7f-93de-85fdd67261fc",
		"heavy": false,
		"active": true,
		"weight": 4,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p69",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 4,
		"covers_arms": false,
		"covers_face": false,
		"covers_head": true,
		"covers_legs": false,
		"description": "",
		"name_plural": "Heavy Helms (Plate)",
		"negate_4_ap": false,
		"rigid_armor": false,
		"ap_vs_lasers": 0,
		"covers_torso": false,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "d10",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 73,
		"run": "",
		"cost": 200,
		"name": "Heavy Helm, Enclosed (Plate)",
		"pace": 0,
		"size": 0,
		"type": "Medieval & Ancient Armor",
		"uuid": "1acfc2d1-52c6-4b1b-3854-050a05f4373e",
		"heavy": false,
		"active": true,
		"weight": 8,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p69",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 4,
		"covers_arms": false,
		"covers_face": true,
		"covers_head": true,
		"covers_legs": false,
		"description": "",
		"name_plural": "Heavy Helms, Enclosed (Plate)",
		"negate_4_ap": false,
		"rigid_armor": false,
		"ap_vs_lasers": 0,
		"covers_torso": false,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "d10",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 17,
		"run": "",
		"cost": 1000000,
		"name": "Infantry Battle Suit",
		"pace": 0,
		"size": 0,
		"type": "Futuristic Armor",
		"uuid": "c6419366-5e3b-4146-b0c7-9b14f1146259",
		"heavy": false,
		"active": true,
		"weight": 20,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p61",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 6,
		"covers_arms": true,
		"covers_face": true,
		"covers_head": true,
		"covers_legs": true,
		"description": "",
		"name_plural": "Infantry Battle Suits",
		"negate_4_ap": false,
		"rigid_armor": true,
		"ap_vs_lasers": 0,
		"covers_torso": true,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 87,
		"run": "",
		"cost": 800,
		"name": "Infantry Battle Suit",
		"pace": 0,
		"size": 0,
		"type": "Futuristic Armor",
		"uuid": "6f72e9bc-9187-4977-9791-3e7e33f29bee",
		"heavy": false,
		"active": true,
		"weight": 12,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p70",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 6,
		"covers_arms": true,
		"covers_face": false,
		"covers_head": false,
		"covers_legs": true,
		"description": "",
		"name_plural": "Infantry Battle Suits",
		"negate_4_ap": true,
		"rigid_armor": false,
		"ap_vs_lasers": 0,
		"covers_torso": true,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "d6",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 88,
		"run": "",
		"cost": 1200,
		"name": "Infantry Battle Suit w/ Energy Skin",
		"pace": 0,
		"size": 0,
		"type": "Futuristic Armor",
		"uuid": "5b9f7b1f-49f6-4c87-8952-4e4615175172",
		"heavy": false,
		"active": true,
		"weight": 12,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p70",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 6,
		"covers_arms": true,
		"covers_face": false,
		"covers_head": false,
		"covers_legs": true,
		"description": "",
		"name_plural": "Infantry Battle Suits w/ Energy Skin",
		"negate_4_ap": true,
		"rigid_armor": false,
		"ap_vs_lasers": 4,
		"covers_torso": true,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "d6",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 83,
		"run": "",
		"cost": 80,
		"name": "Kevlar helmet",
		"pace": 0,
		"size": 0,
		"type": "Modern Armor",
		"uuid": "f8bf5c5d-d945-46fd-0562-7bf4935e64ca",
		"heavy": false,
		"active": true,
		"weight": 5,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p70",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 4,
		"covers_arms": false,
		"covers_face": false,
		"covers_head": true,
		"covers_legs": false,
		"description": "",
		"name_plural": "Kevlar helmets",
		"negate_4_ap": true,
		"rigid_armor": false,
		"ap_vs_lasers": 0,
		"covers_torso": false,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "d4",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 76,
		"run": "",
		"cost": 350,
		"name": "Kevlar Riding Jacket",
		"pace": 0,
		"size": 0,
		"type": "Modern Armor",
		"uuid": "0f847887-791e-4d58-81f9-a40c723f5ee0",
		"heavy": false,
		"active": true,
		"weight": 8,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p70",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 2,
		"covers_arms": true,
		"covers_face": false,
		"covers_head": false,
		"covers_legs": false,
		"description": "",
		"name_plural": "Kevlar Riding Jackets",
		"negate_4_ap": true,
		"rigid_armor": false,
		"ap_vs_lasers": 0,
		"covers_torso": true,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "d4",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 77,
		"run": "",
		"cost": 175,
		"name": "Kevlar Riding Jeans",
		"pace": 0,
		"size": 0,
		"type": "Modern Armor",
		"uuid": "531bf846-7596-464c-9d5c-b1bc6e047cb6",
		"heavy": false,
		"active": true,
		"weight": 4,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p70",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 2,
		"covers_arms": false,
		"covers_face": false,
		"covers_head": false,
		"covers_legs": true,
		"description": "",
		"name_plural": "Kevlar Riding Jeans",
		"negate_4_ap": false,
		"rigid_armor": false,
		"ap_vs_lasers": 0,
		"covers_torso": false,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "d4",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 13,
		"run": "",
		"cost": 250,
		"name": "Kevlar Vest",
		"pace": 0,
		"size": 0,
		"type": "Modern Armor",
		"uuid": "6c81969d-ac10-4878-275c-6e81bbc51469",
		"heavy": false,
		"active": true,
		"weight": 8,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p61",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 2,
		"covers_arms": false,
		"covers_face": false,
		"covers_head": false,
		"covers_legs": false,
		"description": "",
		"name_plural": "Kevlar Vests",
		"negate_4_ap": true,
		"rigid_armor": false,
		"ap_vs_lasers": 0,
		"covers_torso": true,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 4,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 81,
		"run": "",
		"cost": 200,
		"name": "Kevlar Vest (police issue)",
		"pace": 0,
		"size": 0,
		"type": "Modern Armor",
		"uuid": "f5203c13-f2b9-4471-8a5b-fe5e95e25dbf",
		"heavy": false,
		"active": true,
		"weight": 5,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p70",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 2,
		"covers_arms": false,
		"covers_face": false,
		"covers_head": false,
		"covers_legs": false,
		"description": "",
		"name_plural": "Kevlar Vests (police issue)",
		"negate_4_ap": false,
		"rigid_armor": false,
		"ap_vs_lasers": 0,
		"covers_torso": true,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "d6",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 14,
		"run": "",
		"cost": 2500,
		"name": "Kevlar Vest w/inserts",
		"pace": 0,
		"size": 0,
		"type": "Modern Armor",
		"uuid": "cb186ed9-b083-46b9-14b7-dda090a929a9",
		"heavy": false,
		"active": true,
		"weight": 12,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p61",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 4,
		"covers_arms": false,
		"covers_face": false,
		"covers_head": false,
		"covers_legs": false,
		"description": "",
		"name_plural": "Kevlar Vests w/inserts",
		"negate_4_ap": true,
		"rigid_armor": true,
		"ap_vs_lasers": 0,
		"covers_torso": true,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 8,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 82,
		"run": "",
		"cost": 500,
		"name": "Kevlar vest with ceramic inserts (2008+ military issue)",
		"pace": 0,
		"size": 0,
		"type": "Modern Armor",
		"uuid": "f29f186f-83c3-49a1-baf6-4890061cdc7c",
		"heavy": false,
		"active": true,
		"weight": 17,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p70",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 6,
		"covers_arms": false,
		"covers_face": false,
		"covers_head": false,
		"covers_legs": false,
		"description": "",
		"name_plural": "Kevlar vests with ceramic inserts (2008+ military issue)",
		"negate_4_ap": true,
		"rigid_armor": false,
		"ap_vs_lasers": 0,
		"covers_torso": true,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "d8",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 93,
		"run": "",
		"cost": 200,
		"name": "Large Shield",
		"pace": 0,
		"size": 0,
		"type": "Shields",
		"uuid": "343a8cec-aab3-4fce-931a-7cbc13e5556f",
		"heavy": false,
		"active": true,
		"weight": 12,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p71",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": true,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 0,
		"covers_arms": false,
		"covers_face": false,
		"covers_head": false,
		"covers_legs": false,
		"description": "",
		"name_plural": "Large Shields",
		"negate_4_ap": false,
		"rigid_armor": false,
		"ap_vs_lasers": 0,
		"covers_torso": false,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "d4",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 3,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": -4,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 11,
		"run": "",
		"cost": 200,
		"name": "Large Shield (kite, pavise)",
		"pace": 0,
		"size": 0,
		"type": "Medieval Armor",
		"uuid": "1479ee72-d8bc-4c0d-97cd-657a203a18a9",
		"heavy": false,
		"active": true,
		"weight": 20,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p61",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": true,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 2,
		"covers_arms": false,
		"covers_face": false,
		"covers_head": false,
		"covers_legs": false,
		"description": "",
		"name_plural": "Large Shields (kite, pavise)",
		"negate_4_ap": false,
		"rigid_armor": false,
		"ap_vs_lasers": 0,
		"covers_torso": false,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 2,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 2,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 2,
		"run": "",
		"cost": 50,
		"name": "Leather",
		"pace": 0,
		"size": 0,
		"type": "Medieval Armor",
		"uuid": "2ae6cc68-e11a-4549-8df1-58ef57d48d45",
		"heavy": false,
		"active": true,
		"weight": 15,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p61",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 1,
		"covers_arms": true,
		"covers_face": false,
		"covers_head": false,
		"covers_legs": true,
		"description": "",
		"name_plural": "Leather",
		"negate_4_ap": false,
		"rigid_armor": false,
		"ap_vs_lasers": 0,
		"covers_torso": true,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 65,
		"run": "",
		"cost": 20,
		"name": "Leather Cap",
		"pace": 0,
		"size": 0,
		"type": "Medieval & Ancient Armor",
		"uuid": "202e5316-d531-4a1c-1ce1-bbc172966a5b",
		"heavy": false,
		"active": true,
		"weight": 1,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p69",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 2,
		"covers_arms": false,
		"covers_face": false,
		"covers_head": true,
		"covers_legs": false,
		"description": "",
		"name_plural": "Leather Caps",
		"negate_4_ap": false,
		"rigid_armor": false,
		"ap_vs_lasers": 0,
		"covers_torso": false,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "d6",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 63,
		"run": "",
		"cost": 80,
		"name": "Leather Jacket",
		"pace": 0,
		"size": 0,
		"type": "Medieval & Ancient Armor",
		"uuid": "a76186dc-0309-49eb-3a6d-9a2bce96a47e",
		"heavy": false,
		"active": true,
		"weight": 8,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p69",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 2,
		"covers_arms": true,
		"covers_face": false,
		"covers_head": false,
		"covers_legs": false,
		"description": "",
		"name_plural": "Leather Jackets",
		"negate_4_ap": false,
		"rigid_armor": false,
		"ap_vs_lasers": 0,
		"covers_torso": true,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "d6",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 64,
		"run": "",
		"cost": 40,
		"name": "Leather Leggings",
		"pace": 0,
		"size": 0,
		"type": "Medieval & Ancient Armor",
		"uuid": "d82f66af-e416-4fa9-ac9d-40f76de046c9",
		"heavy": false,
		"active": true,
		"weight": 7,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p69",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 2,
		"covers_arms": false,
		"covers_face": false,
		"covers_head": false,
		"covers_legs": true,
		"description": "",
		"name_plural": "Leather Leggings",
		"negate_4_ap": false,
		"rigid_armor": false,
		"ap_vs_lasers": 0,
		"covers_torso": false,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "d6",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 75,
		"run": "",
		"cost": 150,
		"name": "Leather Riding Chaps",
		"pace": 0,
		"size": 0,
		"type": "Modern Armor",
		"uuid": "b91cade7-ca46-4daf-30c2-9b328b311d2c",
		"heavy": false,
		"active": true,
		"weight": 10,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p70",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 1,
		"covers_arms": false,
		"covers_face": false,
		"covers_head": false,
		"covers_legs": true,
		"description": "",
		"name_plural": "Leather Riding Chaps",
		"negate_4_ap": false,
		"rigid_armor": false,
		"ap_vs_lasers": 0,
		"covers_torso": false,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "d4",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 10,
		"run": "",
		"cost": 50,
		"name": "Medium Shield",
		"pace": 0,
		"size": 0,
		"type": "Medieval Armor",
		"uuid": "24b0c8a2-3dfb-4583-b201-f956c29333c6",
		"heavy": false,
		"active": true,
		"weight": 12,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p61",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": true,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 0,
		"covers_arms": false,
		"covers_face": false,
		"covers_head": false,
		"covers_legs": false,
		"description": "",
		"name_plural": "Medium Shields",
		"negate_4_ap": false,
		"rigid_armor": false,
		"ap_vs_lasers": 0,
		"covers_torso": false,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 1,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 2,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 92,
		"run": "",
		"cost": 100,
		"name": "Medium Shield",
		"pace": 0,
		"size": 0,
		"type": "Shields",
		"uuid": "f8db1329-f0e7-4907-20f5-49d6491fb5f9",
		"heavy": false,
		"active": true,
		"weight": 8,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p71",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": true,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 0,
		"covers_arms": false,
		"covers_face": false,
		"covers_head": false,
		"covers_legs": false,
		"description": "",
		"name_plural": "Medium Shields",
		"negate_4_ap": false,
		"rigid_armor": false,
		"ap_vs_lasers": 0,
		"covers_torso": false,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "d4",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 2,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": -2,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 15,
		"run": "",
		"cost": 75,
		"name": "Motorcycle Helmet",
		"pace": 0,
		"size": 0,
		"type": "Modern Armor",
		"uuid": "c807dadb-49cf-48c2-3564-f873ffe26619",
		"heavy": false,
		"active": true,
		"weight": 5,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p61",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 3,
		"covers_arms": false,
		"covers_face": false,
		"covers_head": true,
		"covers_legs": false,
		"description": "",
		"name_plural": "Motorcycle Helmets",
		"negate_4_ap": false,
		"rigid_armor": true,
		"ap_vs_lasers": 0,
		"covers_torso": false,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 79,
		"run": "",
		"cost": 100,
		"name": "Motorcycle Helmet",
		"pace": 0,
		"size": 0,
		"type": "Modern Armor",
		"uuid": "31c8a6fd-4704-47ec-9f5b-739a0a49a045",
		"heavy": false,
		"active": true,
		"weight": 3,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p70",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 3,
		"covers_arms": false,
		"covers_face": false,
		"covers_head": true,
		"covers_legs": false,
		"description": "",
		"name_plural": "Motorcycle Helmets",
		"negate_4_ap": false,
		"rigid_armor": false,
		"ap_vs_lasers": 0,
		"covers_torso": false,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "d4",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 70,
		"run": "",
		"cost": 200,
		"name": "Plate Arms",
		"pace": 0,
		"size": 0,
		"type": "Medieval & Ancient Armor",
		"uuid": "67b5f082-0e0e-4f2a-8af1-9cb31eba1d6d",
		"heavy": false,
		"active": true,
		"weight": 10,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p69",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 4,
		"covers_arms": true,
		"covers_face": false,
		"covers_head": false,
		"covers_legs": false,
		"description": "",
		"name_plural": "Plate Arms",
		"negate_4_ap": false,
		"rigid_armor": false,
		"ap_vs_lasers": 0,
		"covers_torso": false,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "d10",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 5,
		"run": "",
		"cost": 200,
		"name": "Plate Arms (vambrace)",
		"pace": 0,
		"size": 0,
		"type": "Medieval Armor",
		"uuid": "05986075-eeda-4e50-0813-76caa33a7122",
		"heavy": false,
		"active": true,
		"weight": 10,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p61",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 3,
		"covers_arms": true,
		"covers_face": false,
		"covers_head": false,
		"covers_legs": false,
		"description": "",
		"name_plural": "Plate Arms (vambraces)",
		"negate_4_ap": false,
		"rigid_armor": true,
		"ap_vs_lasers": 0,
		"covers_torso": false,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 4,
		"run": "",
		"cost": 400,
		"name": "Plate Corselet",
		"pace": 0,
		"size": 0,
		"type": "Medieval Armor",
		"uuid": "2b9ba243-636e-4074-8ea5-85d11f4611bb",
		"heavy": false,
		"active": true,
		"weight": 25,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p61",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 3,
		"covers_arms": false,
		"covers_face": false,
		"covers_head": false,
		"covers_legs": false,
		"description": "",
		"name_plural": "Plate Corselets",
		"negate_4_ap": false,
		"rigid_armor": true,
		"ap_vs_lasers": 0,
		"covers_torso": true,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 69,
		"run": "",
		"cost": 500,
		"name": "Plate Corselet",
		"pace": 0,
		"size": 0,
		"type": "Medieval & Ancient Armor",
		"uuid": "7186e1c2-081c-47c6-9702-0f4efa26010e",
		"heavy": false,
		"active": true,
		"weight": 30,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p69",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 4,
		"covers_arms": false,
		"covers_face": false,
		"covers_head": false,
		"covers_legs": false,
		"description": "",
		"name_plural": "Plate Corselets",
		"negate_4_ap": false,
		"rigid_armor": false,
		"ap_vs_lasers": 0,
		"covers_torso": true,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "d10",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 71,
		"run": "",
		"cost": 200,
		"name": "Plate Leggings",
		"pace": 0,
		"size": 0,
		"type": "Medieval & Ancient Armor",
		"uuid": "3df74d05-a563-4f3f-b9d6-cb099bf3b508",
		"heavy": false,
		"active": true,
		"weight": 10,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p69",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 4,
		"covers_arms": false,
		"covers_face": false,
		"covers_head": false,
		"covers_legs": true,
		"description": "",
		"name_plural": "Plate Leggings",
		"negate_4_ap": false,
		"rigid_armor": false,
		"ap_vs_lasers": 0,
		"covers_torso": false,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "d10",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 6,
		"run": "",
		"cost": 300,
		"name": "Plate Leggings (greaves)",
		"pace": 0,
		"size": 0,
		"type": "Medieval Armor",
		"uuid": "c969ef54-6060-45bc-1969-e92b7827c30f",
		"heavy": false,
		"active": true,
		"weight": 15,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p61",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 3,
		"covers_arms": false,
		"covers_face": false,
		"covers_head": false,
		"covers_legs": true,
		"description": "",
		"name_plural": "Plate Leggings (greaves)",
		"negate_4_ap": false,
		"rigid_armor": true,
		"ap_vs_lasers": 0,
		"covers_torso": false,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 98,
		"run": "",
		"cost": 400,
		"name": "Polymer Shield, large",
		"pace": 0,
		"size": 0,
		"type": "Shields",
		"uuid": "03e7f917-29b4-431b-1a3d-82b81a356ea0",
		"heavy": false,
		"active": true,
		"weight": 6,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p71",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": true,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 0,
		"covers_arms": false,
		"covers_face": false,
		"covers_head": false,
		"covers_legs": false,
		"description": "",
		"name_plural": "Polymer Shields, large",
		"negate_4_ap": false,
		"rigid_armor": false,
		"ap_vs_lasers": 0,
		"covers_torso": false,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "d4",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 3,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": -4,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 97,
		"run": "",
		"cost": 300,
		"name": "Polymer Shield, medium",
		"pace": 0,
		"size": 0,
		"type": "Shields",
		"uuid": "e716844e-855c-464f-b8f4-d4cfa9fb3d16",
		"heavy": false,
		"active": true,
		"weight": 4,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p71",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": true,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 0,
		"covers_arms": false,
		"covers_face": false,
		"covers_head": false,
		"covers_legs": false,
		"description": "",
		"name_plural": "Polymer Shields, medium",
		"negate_4_ap": false,
		"rigid_armor": false,
		"ap_vs_lasers": 0,
		"covers_torso": false,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "d4",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 2,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": -2,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 96,
		"run": "",
		"cost": 200,
		"name": "Polymer Shield, small",
		"pace": 0,
		"size": 0,
		"type": "Shields",
		"uuid": "6e3e7cd4-ef78-4227-939e-d1dfe5acf80a",
		"heavy": false,
		"active": true,
		"weight": 2,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p71",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": true,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 0,
		"covers_arms": false,
		"covers_face": false,
		"covers_head": false,
		"covers_legs": false,
		"description": "",
		"name_plural": "Polymer Shields, small",
		"negate_4_ap": false,
		"rigid_armor": false,
		"ap_vs_lasers": 0,
		"covers_torso": false,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "d4",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 1,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 7,
		"run": "",
		"cost": 75,
		"name": "Pot Helm",
		"pace": 0,
		"size": 0,
		"type": "Medieval Armor",
		"uuid": "ed22d9c6-f28f-461c-a205-46888eac5839",
		"heavy": false,
		"active": true,
		"weight": 4,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p61",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 3,
		"covers_arms": false,
		"covers_face": false,
		"covers_head": true,
		"covers_legs": false,
		"description": "",
		"name_plural": "Pot Helms",
		"negate_4_ap": false,
		"rigid_armor": true,
		"ap_vs_lasers": 0,
		"covers_torso": false,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 20,
		"run": "",
		"cost": 1000000,
		"name": "Powered Armor (battle suit)",
		"pace": 0,
		"size": 0,
		"type": "Futuristic Armor",
		"uuid": "c747ab40-6861-49ee-ba5f-c05fa982bf87",
		"heavy": false,
		"active": true,
		"weight": 0,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p61",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 12,
		"covers_arms": true,
		"covers_face": true,
		"covers_head": true,
		"covers_legs": true,
		"description": "",
		"name_plural": "Powered Armor (battle suits)",
		"negate_4_ap": false,
		"rigid_armor": true,
		"ap_vs_lasers": 0,
		"covers_torso": true,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 21,
		"run": "",
		"cost": 1000000,
		"name": "Powered Armor (heavy suit)",
		"pace": 0,
		"size": 0,
		"type": "Futuristic Armor",
		"uuid": "e0f2698d-8985-4262-a341-11874a865941",
		"heavy": false,
		"active": true,
		"weight": 0,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p61",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 14,
		"covers_arms": true,
		"covers_face": true,
		"covers_head": true,
		"covers_legs": true,
		"description": "",
		"name_plural": "Powered Armor (heavy suits)",
		"negate_4_ap": false,
		"rigid_armor": true,
		"ap_vs_lasers": 0,
		"covers_torso": true,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 19,
		"run": "",
		"cost": 1000000,
		"name": "Powered Armor (scout suit)",
		"pace": 0,
		"size": 0,
		"type": "Futuristic Armor",
		"uuid": "34677403-63eb-40bd-2c25-5d50449838b7",
		"heavy": false,
		"active": true,
		"weight": 0,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p61",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 10,
		"covers_arms": true,
		"covers_face": true,
		"covers_head": true,
		"covers_legs": true,
		"description": "",
		"name_plural": "Powered Armor (scout suits)",
		"negate_4_ap": false,
		"rigid_armor": true,
		"ap_vs_lasers": 0,
		"covers_torso": true,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 22,
		"run": "",
		"cost": 200,
		"name": "Reflective Vest",
		"pace": 0,
		"size": 0,
		"type": "Futuristic Armor",
		"uuid": "11ccdcdf-44fd-44b9-1a62-64a87bb1358f",
		"heavy": false,
		"active": true,
		"weight": 5,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p61",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 10,
		"covers_arms": false,
		"covers_face": false,
		"covers_head": false,
		"covers_legs": false,
		"description": "",
		"name_plural": "Reflective Vests",
		"negate_4_ap": false,
		"rigid_armor": false,
		"ap_vs_lasers": 1,
		"covers_torso": true,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 94,
		"run": "",
		"cost": 80,
		"name": "Riot Shield",
		"pace": 0,
		"size": 0,
		"type": "Shields",
		"uuid": "bd2cb0e7-0ef2-4ce1-9fab-5a28a96959a0",
		"heavy": false,
		"active": true,
		"weight": 5,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p71",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": true,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 0,
		"covers_arms": false,
		"covers_face": false,
		"covers_head": false,
		"covers_legs": false,
		"description": "",
		"name_plural": "Riot Shields",
		"negate_4_ap": false,
		"rigid_armor": false,
		"ap_vs_lasers": 0,
		"covers_torso": false,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "d4",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 3,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": -4,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 91,
		"run": "",
		"cost": 50,
		"name": "Small Shield",
		"pace": 0,
		"size": 0,
		"type": "Shields",
		"uuid": "1cbd8980-960b-4aaf-1134-5217c15b14de",
		"heavy": false,
		"active": true,
		"weight": 4,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p71",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": true,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 0,
		"covers_arms": false,
		"covers_face": false,
		"covers_head": false,
		"covers_legs": false,
		"description": "",
		"name_plural": "Small Shields",
		"negate_4_ap": false,
		"rigid_armor": false,
		"ap_vs_lasers": 0,
		"covers_torso": false,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "d4",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 1,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 9,
		"run": "",
		"cost": 25,
		"name": "Small Shield (buckler)",
		"pace": 0,
		"size": 0,
		"type": "Medieval Armor",
		"uuid": "e8a2308a-3a2f-46bd-3c21-9ba2629fd471",
		"heavy": false,
		"active": true,
		"weight": 8,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p61",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": true,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 0,
		"covers_arms": false,
		"covers_face": false,
		"covers_head": false,
		"covers_legs": false,
		"description": "",
		"name_plural": "Small Shields (bucklers)",
		"negate_4_ap": false,
		"rigid_armor": false,
		"ap_vs_lasers": 0,
		"covers_torso": false,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 1,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 8,
		"run": "",
		"cost": 150,
		"name": "Steel Helmet (enclosed)",
		"pace": 0,
		"size": 0,
		"type": "Medieval Armor",
		"uuid": "71b493f4-1569-4f4c-9974-3a3192f31415",
		"heavy": false,
		"active": true,
		"weight": 8,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p61",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 3,
		"covers_arms": false,
		"covers_face": true,
		"covers_head": true,
		"covers_legs": false,
		"description": "",
		"name_plural": "Steel Helmets (enclosed)",
		"negate_4_ap": false,
		"rigid_armor": true,
		"ap_vs_lasers": 0,
		"covers_torso": false,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 16,
		"run": "",
		"cost": 80,
		"name": "Steel Pot (helmet)",
		"pace": 0,
		"size": 0,
		"type": "Modern Armor",
		"uuid": "f3fa7cda-692e-49fe-aee9-b135416d8f38",
		"heavy": false,
		"active": true,
		"weight": 5,
		"book_id": 2,
		"effects": [],
		"summary": "",
		"bookPage": "p61",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 4,
		"covers_arms": false,
		"covers_face": false,
		"covers_head": true,
		"covers_legs": false,
		"description": "",
		"name_plural": "Steel Pots (helmet)",
		"negate_4_ap": false,
		"rigid_armor": true,
		"ap_vs_lasers": 0,
		"covers_torso": false,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	},
	{
		"id": 74,
		"run": "",
		"cost": 100,
		"name": "Thick coat, Leather Jacket",
		"pace": 0,
		"size": 0,
		"type": "Modern Armor",
		"uuid": "713b5f4d-e4d1-4991-1714-23603a80de0a",
		"heavy": false,
		"active": true,
		"weight": 5,
		"book_id": 9,
		"effects": [],
		"summary": "",
		"bookPage": "p70",
		"hardness": 0,
		"quantity": 1,
		"abilities": [],
		"is_custom": false,
		"is_shield": false,
		"no_select": false,
		"read_only": false,
		"toughness": 0,
		"created_by": 0,
		"created_on": null,
		"deleted_by": 0,
		"updated_by": 0,
		"version_of": 0,
		"armor_value": 1,
		"covers_arms": true,
		"covers_face": false,
		"covers_head": false,
		"covers_legs": false,
		"description": "",
		"name_plural": "Thick coats, Leather Jackets",
		"negate_4_ap": false,
		"rigid_armor": false,
		"ap_vs_lasers": 0,
		"covers_torso": true,
		"set_strength": "",
		"pf_armor_type": 0,
		"alternate_modes": [],
		"is_energy_screen": false,
		"minimum_strength": "d4",
		"requires_2_hands": false,
		"integrated_weapons": [],
		"shield_parry_bonus": 0,
		"default_model_label": "Normal",
		"secondary_armor_value": 0,
		"shield_armor_vs_ranged": 0,
		"shield_cover_vs_ranged": 0,
		"stacks_with_other_armor": false,
		"zero_weight_when_equipped": false
	}
];

export default Armor;