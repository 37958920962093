import React from 'react';
import styled from "styled-components";

const Wrapper = styled.input`
	background: #191411;
	border: solid 2px #453c36;
	border-radius: 3px;
	height: 40px;
	line-height: 40px;
	padding: 0 0.5em;
	outline: none;
	width:100%;
	box-sizing: border-box;
`;

function TextInput({...props}) {

	// input

	// display
	return (
		<Wrapper type="text" autoComplete="off" {...props} />
	);
}

export default TextInput;