import MapTokenData from "./MapTokenData";

const MapData = new function() {
	const this_ = this;

	// create
	this_.createMap = ({width, height, imageUrl, characterTokens, bestiaryTokens}) => {
		return {
			width_:width||0,
			height_:height||0,
			imageUrl_:imageUrl||"",
			characterTokens_:characterTokens||[],
			bestiaryTokens_:bestiaryTokens||[]
		};
	};

	// accessors
	this_.getMapWidth = map => map.width_;
	this_.getMapHeight = map => map.height_;
	this_.getMapImageUrl = map => map.imageUrl_;
	this_.getMapBestiaryTokens = map => map.bestiaryTokens_;
	this_.getMapCharacterTokens = map => map.characterTokens_;

	// modifiers
	this_.setMapWidth = (map, value) => map.width_ = value;
	this_.setMapHeight = (map, value) => map.height_ = value;
	this_.setMapImageUrl = (map, value) => map.imageUrl_ = value;
	this_.setMapBestiaryTokens = (map, value) => map.bestiaryTokens_ = value;
	this_.setMapCharacterTokens = (map, value) => map.characterTokens_ = value;

	// save
	this_.getSaveData = map => [
		this_.getMapWidth(map),
		this_.getMapHeight(map),
		this_.getMapImageUrl(map),
		this_.getMapCharacterTokens(map).map(MapTokenData.getSaveData),
		this_.getMapBestiaryTokens(map).map(MapTokenData.getSaveData)
	];

	// load
	this_.loadData = data => this_.createMap({
		width:data[0],
		height:data[1],
		imageUrl:data[2],
		characterTokens:data[3].map(MapTokenData.loadData),
		bestiaryTokens:data[4].map(MapTokenData.loadData)
	});
};

export default MapData;