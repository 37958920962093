import React, {useState} from "react";
import styled from "styled-components";
import DragManager from "./DragManager";
import Language from "../Language/Language";
import ButtonRegular from "./Burnblade/ButtonRegular";

const CardData = new function() {
	const this_ = this;
	this_.Suit = {
		Clubs:0,
		Diamonds:1,
		Hearts:2,
		Spades:3,
		Joker:4
	};

	// maxZ
	let maxZ_ = 0;
	this_.getMaxZ = () => maxZ_;
	this_.resetMaxZ = () => maxZ_ = 0;

	// create
	this_.createCard = ({key, suit, rank, down, posX, posY, posZ}) => {

		// number <-> suit,rank
		if (undefined === typeof key) key = suit * 13 + rank;
		else
		{
			suit = Math["floor"](key / 13);
			rank = key % 13;
		}

		// maxZ
		if (!posZ) posZ = 0;
		if (posZ > maxZ_) maxZ_ = posZ;

		// done
		return {
			key_:key,
			suit_:suit,
			rank_:rank,
			down_:down,
			posX_:posX||0,
			posY_:posY||0,
			posZ_:posZ||0
		};
	};

	// create deck
	this_.createDeck = jokerCount => {
		const cards = [];
		const cardCount = 52 + (jokerCount || 0);
		for(let key = 0; key < cardCount; key++) cards["push"](this_.createCard({key:key, down:true, posZ:key}));
		return cards;
	};

	// accessors
	this_.getKey = card => card.key_;
	this_.getSuit = card => card.suit_;
	this_.getRank = card => card.rank_;
	this_.getDown = card => card.down_;
	this_.getPosX = card => card.posX_;
	this_.getPosY = card => card.posY_;
	this_.getPosZ = card => card.posZ_;

	// modifiers
	this_.setDown = (card, value) => card.down_ = value;
	this_.setPosX = (card, value) => card.posX_ = value;
	this_.setPosY = (card, value) => card.posY_ = value;
	this_.setPosZ = (card, value) => { card.posZ_ = value; if (value > maxZ_) maxZ_ = value; }
}

const cards_ = CardData.createDeck(2);

const Wrapper = styled.div`
	position: absolute;
	top:50%;
	left:50%;
	user-select: none;
	
	& > button {
		position: absolute;
		bottom:0;
	}
`;

function CardsModule({})
{
	// input
	const language = Language.getCurrent();
	const [renderKey, setRenderKey] = useState(0);

	// display
	return (
		<Wrapper>
			<ButtonRegular onClick={() => {

				// Shuffle
				cards_["sort"](() => Math["random"]() - 0.5);
				CardData.resetMaxZ();

				// All Cards Down and moved to the center
				cards_.forEach((card, cardIndex) => {
					CardData.setDown(card, true);
					CardData.setPosX(card, 0);
					CardData.setPosY(card, 0);
					CardData.setPosZ(card, cardIndex);
				});

				// re-render
				setRenderKey(renderKey+1);

			}}>{language.Shuffle}</ButtonRegular>

			<div key={renderKey}>
				{
					cards_.map(card =>
						<CardModule
							card={card}
							onMoved={()=>{setRenderKey(renderKey+1);}}
							key={`${CardData.getKey(card)}-${CardData.getPosX(card)},${CardData.getPosY(card)},${CardData.getPosZ(card)}`}
						/>
					)
				}
			</div>
		</Wrapper>
	);
}



const CardWrapper = styled.div`
	background:White;
	border-radius: 0.5em;
	width:75px;
	height:100px;
	position: absolute;
	box-shadow: 0 0 2px Black;
	--suit:"";
	--rank:"";
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;
	text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
	
	&[data-down] { 
		box-sizing: border-box;
		border:solid 4px White;
		background:repeating-linear-gradient(
				45deg,
				#606dbc,
				#606dbc 5px,
				#465298 5px,
				#465298 10px
		);
	}
	&:not([data-down]) {
		&[data-rank='0'] { --rank:"A"; }
		&[data-rank='1'] { --rank:"2"; }
		&[data-rank='2'] { --rank:"3"; }
		&[data-rank='3'] { --rank:"4"; }
		&[data-rank='4'] { --rank:"5"; }
		&[data-rank='5'] { --rank:"6"; }
		&[data-rank='6'] { --rank:"7"; }
		&[data-rank='7'] { --rank:"8"; }
		&[data-rank='8'] { --rank:"9"; }
		&[data-rank='9'] { --rank:"10"; }
		&[data-rank='10'] { --rank:"J"; }
		&[data-rank='11'] { --rank:"Q"; }
		&[data-rank='12'] { --rank:"K"; }
		&[data-suit='${CardData.Suit.Hearts}'] { color:Red; --suit:"♥"; }
		&[data-suit='${CardData.Suit.Diamonds}'] { color:Red; --suit:"♦"; }
		&[data-suit='${CardData.Suit.Clubs}'] { color:Black; --suit:"♣"; }
		&[data-suit='${CardData.Suit.Spades}'] { color:Black; --suit:"♠"; }
		&[data-suit='${CardData.Suit.Joker}'] { 
			--rank:"Joker";
			--suit:"★";
			&[data-rank='0'] { color:Red; }
			&[data-rank='1'] { color:Black; }
			&:after {
				margin-top:18px; 
				font-size:3em;
			}
		}
		&:before { 
			content:var(--rank);
			font-size: 1.5em;
			position: absolute;
			top:8px;
			left:8px;
		}
		&:after { 
			content:var(--suit);
			font-size: 4em;
		}
	}
`;

function CardModule({card, onMoved})
{
	// input
	const initialPosX = CardData.getPosX(card);
	const initialPosY = CardData.getPosY(card);
	const [posX, setPosX] = useState(initialPosX);
	const [posY, setPosY] = useState(initialPosY);
	const [posZ, setPosZ] = useState(CardData.getPosZ(card));

	// display
	return (
		<CardWrapper
			data-rank={CardData.getRank(card)}
			data-suit={CardData.getSuit(card)}
			data-down={CardData.getDown(card)?1:undefined}
			style={{
				zIndex:posZ,
				transform:`translate(${posX}px, ${posY}px`
			}}
			onMouseDown={event => {

				// move to top
				const newZ = CardData.getMaxZ()+1;
				setPosZ(newZ);
				CardData.setPosZ(card, newZ);

				// Draggable
				DragManager.dragStart(event, {
					onDrag:(changeX, changeY) => {
						setPosX(changeX + initialPosX);
						setPosY(changeY + initialPosY);
					},
					onDragStop:(changeX, changeY) => {
						CardData.setPosX(card, changeX + initialPosX);
						CardData.setPosY(card, changeY + initialPosY);
						requestAnimationFrame(() => {onMoved();});
					},
					onClick:() => {
						CardData.setDown(card, !CardData.getDown(card));
						requestAnimationFrame(() => {onMoved();});
					}
				});
			}}
		/>
	);
}

export default CardsModule;