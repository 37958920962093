import React from 'react';
import styled from "styled-components";

const Wrapper = styled.input`
	background: #191411;
	border: solid 1px #453c36;
	border-radius: 1px;
	height: 40px;
	line-height: 40px;
	padding: 0 0.25em;
	outline: none;
`;

function NumberInput({...props}) {

	// input

	// display
	return (<Wrapper {...props} type="number" />);
}

export default NumberInput;