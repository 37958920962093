import React, {useState} from 'react';
import styled from "styled-components";
import List from "./List";

const Wrapper = styled.div`
	display:flex;
	flex-flow:row nowrap;
	& > div {

		// Tabs
		&:first-child {
			width:400px;
			
			& > div > label > label {
				padding:0;
			}
		}

		// Pages
		&:nth-child(2) > * {
			padding: 1em;
			padding-top:2em;
			margin-left: -100px;
		}
	}
`;

function TabBookVertical({tabLabels, tabPages, initialTabIndex, tabLabelsFooter, onSelectTabIndex}) {

	// input
	const [selectedTabIndex, setSelectedTabIndex] = useState(initialTabIndex);

	// display
	return (
		<Wrapper>
			<List footer={tabLabelsFooter}>
				{
					tabLabels.map((tabLabel, tabIndex) =>
						<label
							key={tabIndex}
							onClick={() => {
								setSelectedTabIndex(tabIndex);
								if (onSelectTabIndex) onSelectTabIndex(tabIndex)
							}}
							data-selected={selectedTabIndex === tabIndex ? 1 : undefined}
						>
							{tabLabel}
						</label>
					)
				}
			</List>
			<div>
				{tabPages[selectedTabIndex]}
			</div>

		</Wrapper>
	);
}

export default TabBookVertical;