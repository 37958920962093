const TraitsData = {
	Attributes:{
		Agility:0,
		Smarts:1,
		Spirit:2,
		Strength:3,
		Vigor:4,
	},
	Skills:{

		// Core Skills
		Athletics:5,
		Common_Knowledge:6,
		Notice:7,
		Persuasion:8,
		Stealth:9,

		// Other Skills
		Academics:10,
		Battle:11,
		Boating:12,
		Climbing:38,
		Driving:13,
		Electronics:14,
		Faith:15,
		Fighting:16,
		Focus:17,
		Gambling:18,
		Hacking:19,
		Healing:20,
		Intimidation:21,
		Knowledge_Occult:40,
		Language:22,
		Occult:23,
		Performance:24,
		Piloting:25,
		Psionics:26,
		Repair:27,
		Research:28,
		Riding:29,
		Science:30,
		Shooting:31,
		Spellcasting:32,
		Survival:33,
		Swimming:37,
		Taunt:34,
		Tracking:41,
		Thievery:35,
		Throwing:39,
		Weird_Science:36,
	}
};
TraitsData.CoreSkills = [
	TraitsData.Skills.Athletics,
	TraitsData.Skills.Common_Knowledge,
	TraitsData.Skills.Notice,
	TraitsData.Skills.Persuasion,
	TraitsData.Skills.Stealth
];

export default TraitsData;