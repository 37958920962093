import React, {useState} from 'react';
import Dialog, {TitleWrapperWithButton} from "../UI/Burnblade/Dialog";
import Language from "../Language/Language";
import SceneData from "./SceneData";
import TabBookVertical from "../UI/Burnblade/TabBookVertical";
import SceneModule from "./SceneModule";
import StartSceneModule from "./StartSceneModule";
import styled from "styled-components";

const SceneTabWrapper = styled.div`
	line-height: initial;
	height:64px;
	display:flex;
	flex-flow:column nowrap;
	justify-content: center;
	
	// Location
	& > div:nth-child(2) {
		color:#746c65;
		font-size:0.8em;
		margin-top:0.25em;
		text-transform: capitalize;
		
		& > span:first-child:not(:empty):after {
			content:", ";
		}
	}
`

function ScenesModule({scenes, onSaved, characters, onSelectScene}) {

	// input
	const language = Language.getCurrent();
	const [showDialog, setShowDialog] = useState(false);
	const [renderKey, setRenderKey] = useState(0);
	const [initialTabIndex, setInitialTabIndex] = useState(0);

	// display
	return (
		<>
			<button onClick={()=>{setShowDialog(true);}}>{language.Scenes}</button>

			{
				// Scenes Dialog
				!showDialog ? false :
				<Dialog
					key={renderKey}
					title={

						<TitleWrapperWithButton>

							{/* Title Text */}
							<div>{language.Scenes}</div>

							{/* Start Scene Module */}
							<StartSceneModule />

						</TitleWrapperWithButton>
					}
					onClose={()=>{setShowDialog(false);}}
				>
					<TabBookVertical
						initialTabIndex={initialTabIndex}
						onSelectTabIndex={tabIndex => { onSelectScene(scenes[tabIndex]); }}
						tabLabels={

							// Scene Labels
							scenes.map((scene, sceneIndex) =>
								<SceneTabWrapper key={sceneIndex}>
									<div>{SceneData.getSceneName(scene)||<span>&nbsp;</span>}</div>
									<div>
										<span>{SceneData.getSceneLocation(scene)}</span>
										<span>{language.ChaosFactorAbbrev} {SceneData.getSceneChaosFactor(scene)}</span>
									</div>
								</SceneTabWrapper>
							)
						}
						tabPages={

							// Scene Details
							scenes.map((scene, sceneIndex) =>
								<SceneModule
									key={sceneIndex}
									scene={scene}
									characters={characters}
									onSaved={() => {
										setRenderKey(renderKey+1);
										setInitialTabIndex(sceneIndex);
										onSaved();
									}}
								/>
							)
						}
						tabLabelsFooter={

							// New Scene
							<button
								className="Create"
								onClick={()=>{
									scenes["push"](SceneData.createScene({}));
									setRenderKey(renderKey+1);
									setInitialTabIndex(scenes.length-1)
								}}
							>{language.Create_Scene}</button>

						}
					/>
				</Dialog>
			}
		</>
	);
}

export default ScenesModule;