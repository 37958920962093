import React from 'react';
import styled from "styled-components";

const Wrapper = styled.button`
	border:none;
	background:url(/assets/burnblade/avatar_circle.png);
	width:147px;
	height:147px;
	display:inline-flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	position: relative;
	zoom:0.5;
	
	&:hover {
		background:
			url(/assets/burnblade/avatar_circle.png),
			url(/assets/burnblade/avatar_circle.png);
		background-position: right center, left center;
	}
	
	&:before {
		position:absolute;
		background:url(${props => props.url}) no-repeat center center;
		content:"";
		display:block;
		background-size:cover;
		width: 115px;
		height: 115px;
		border-radius: 50%;
	}
	
	&:after {
		position: absolute;
		content:"";
		display: block;
		background:url(/assets/burnblade/avatar_circle.png) center center;
		width:147px;
		height:147px;
	}
`;

function Avatar({onClick, url}) {

	// input

	// display
	return (
		// TODO: Too many classes
		<Wrapper type="button" onClick={onClick} url={url} />
	);
}

export default Avatar;